import { createVeriffFrame } from "@veriff/incontext-sdk";
import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignerApi } from "../../../api";
import { signerPaths } from "../../../routes/routes";
import { ISignerData } from "../../../store/zustandType";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";

const VerificationData = ({
  from,
  onKbaModalClose,
  onSetResult,
  signerData,
  isBiometric,
  sessionId,
}: {
  isBiometric: boolean;
  from?: string;
  onKbaModalClose?: () => void;
  onSetResult?: (result: string) => void;
  signerData: ISignerData;
  sessionId?: number;
}) => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [veriffVerificationUrl, setVeriffVerificationUrl] = useState<
    string | null
  >(null);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const retryVeriffOnErrorRef = useRef(0);

  const startPolling = useCallback((kbalogId: number) => {
    setIsVerifying(true);
    new SignerApi(configuration)
      .signerControllerVeriffSessionAttempts({
        kbalogId: kbalogId,
      })
      .then((res) => {
        if (res?.status === "approved") {
          setIsVerifying(false);
          // clearInterval(interval);
          onSetResult && onSetResult("approved");
          console.log("veriff approved", res);
          if (from === "page") {
            navigate(`${signerPaths.createSession}/signer-session-connecting`);
          } else {
            navigate(`/signer/on-demand/meeting?session-id=${sessionId}`);
          }
        } else {
          startPolling(kbalogId);
        }
      })
      .catch((err) => {
        console.log(err);
        notification("fail", "Failed to create Session");
        // setLoading(false);
        if (retryVeriffOnErrorRef.current < 3) {
          retryVeriffOnErrorRef.current += 1;
          startPolling(kbalogId);
        }
      });
  }, [onSetResult, retryVeriffOnErrorRef, sessionId, signerData]);

  const getVerificationUrl= useCallback(async() => {
    try {
      setLoading(true);
      const result = await new SignerApi(
        configuration
      ).signerControllerVeriffSession({
        lastSsn4: signerData.lastSsn,
      });
      console.log("veriff session api response", result);
      setVeriffVerificationUrl(result.verificationUrl);
      createVeriffFrame({ url: result.verificationUrl });
      startPolling(result.kbaLogId);
    } catch (err) {
      console.log("veriff session api error", err);
    } finally {
      setLoading(false);
    }
  },[signerData])

  useEffect(() => {
    // #NOTE : call veriff session api, then show verif url to verify signer
    // also after this start long polling for attempts api
    // if attempts api return status "approved", then navigate to next step

    getVerificationUrl();
  }, []);

  return (
    <Stack px={4}>
      {loading || veriffVerificationUrl === null ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <>
          <Flex
            direction={"column"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Text pt={3} fontSize={"18px"}>
              Verify your profile with Veriff
            </Text>

            {isVerifying && (
              <Box>
                <Text>
                  Please wait, verifying your ID. It will take couple of minutes
                </Text>
                <Center>
                  <Spinner size="xl" />
                </Center>
              </Box>
            )}

            <Button
              onClick={() => getVerificationUrl()}
              sx={{
                cursor: "pointer",
                backgroundColor: "gray.100",
                paddingX: 8,
                paddingY: 2,
                borderRadius: 5,
              }}
            >
              continue verification
            </Button>

            {/* <Flex
              alignItems={"center"}
              style={{
                color: `${minutes === 0 && seconds < 10 ? "red" : "green"}`,
                fontWeight: 500,
              }}
            >
              <TimeIcon size={20} />
              <Box ml={1}>
                {minutes === 0 && seconds === 0 ? (
                  <h1>Time's up!</h1>
                ) : (
                  <h1>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h1>
                )}
              </Box>
            </Flex> */}
          </Flex>
        </>
      )}

      <Flex alignSelf={"end"} marginTop={5}>
        {from === "page" && (
          <Button
            mx={2}
            style={{ padding: "10px 30px" }}
            width={"fit-content"}
            onClick={() => {
              navigate(`${signerPaths.createSession}/notary-detail`);
            }}
          >
            Prev
          </Button>
        )}

        {/* <Button
          mx={2}
          width={"fit-content"}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          type="submit"
          onClick={handleSubmit}
          isDisabled={
            (questions?.length === 0 ||
              questions?.length === undefined ||
              isSubmit) &&
            quesErr?.status !== 404
          }
        >
          {quesErr?.status === 404 ? "Next" : "Submit"}
        </Button> */}
      </Flex>

      {/* <KbaInfoModal
        isOpen={isInfoOpen}
        onOpen={onInfoOpen}
        onClose={onInfoClose}
      /> */}
    </Stack>
  );
};

export default VerificationData;
