import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { validateBirthday } from "../../../utils/utils";
import { ISignerData } from "../../../store/zustandType";
import { getSessionDetails } from "../../../utils/utils-apis";
import { useAuth } from "../../../utils/use-auth";
import { SessionApi } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useKBAVerification } from "../../../store/store";

const SignerInfo = ({
  sessionId,
  signerData,
  onSetSignerData,
}: {
  sessionId?: number;
  signerData: ISignerData;
  onSetSignerData: (value: ISignerData) => void;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isValidDOB, setIsValidDOB] = useState<boolean>(true);
  const {setWasKBACompleted} = useKBAVerification()
  const formik = useFormik({
    initialValues: signerData,
    validationSchema: Yup.object({
      dob: Yup.string().required("DOB is required"),
      lastSsn: Yup.string().required(
        "Last Four Digits of SSN Number is required"
      ),
    }),
    onSubmit: (values) => {
      onSetSignerData(values);
    },
    enableReinitialize: true,
  });

  const joinSession = (kbaLogId: string) => {
    setIsSubmit(true);
    getSessionDetails(configuration, Number(sessionId))
      .then((session) => {
        const kbaLogs = session?.kbaLogs;
        const logData = kbaLogs?.find(
          (el) => Number(el.userId) === Number(localStorage.getItem("UserID"))
        );
        if (logData === undefined) {
          kbaLogs?.push({
            userId: Number(localStorage.getItem("UserID")),
            kbaLogId: kbaLogId,
          });
        } else {
          logData.kbaLogId = kbaLogId;
        }
        new SessionApi(configuration)
          .sessionControllerUpdate({
            sessionId: Number(sessionId),
            sessionUpdateDTO: {
              kbaLogs: kbaLogs,
            },
          })
          .then((res) => {
            setWasKBACompleted(true);
            navigate(
              `/signer/on-demand/waiting-for-notary/?session-id=${sessionId}`
            );
            setIsSubmit(false);
          })
          .catch(() => {
            setIsSubmit(false);
          });
      })
      .catch(() => {
        setIsSubmit(false);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text>
        Please enter the following details in order to get KBA questions from
        Idology.
      </Text>
      <Flex flexWrap="wrap" mt={5}>
        <FormControl id="dob" w={"50%"} minW={"220px"} p={2}>
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            DOB*
          </FormLabel>
          <Input
            name="dob"
            type="date"
            placeholder=""
            {...(formik.touched.dob && formik.errors.dob
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            onChange={(e) => {
              formik.handleChange(e);
              if (e.target.name === "dob") {
                if (validateBirthday(e.target.value) === false) {
                  setIsValidDOB(false);
                } else {
                  setIsValidDOB(true);
                }
              }
            }}
            value={formik.values.dob}
            onBlur={formik.handleBlur}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.dob && formik.errors.dob ? formik.errors.dob : ""}
            {!isValidDOB && (
              <Text color="red" fontSize={"14px"} fontWeight="500">
                Minimum age required is above 18
              </Text>
            )}
          </Text>
        </FormControl>
        <FormControl id="lastSsn" w={"50%"} minW={"220px"} p={2}>
          <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
            Last Four Digits of SSN*
          </FormLabel>
          <Flex w={"100%"} justifyContent={"flex-start"}>
            <Input
              id="1"
              value={"XXX"}
              readOnly
              disabled
              maxW={"80px"}
              mr={2}
            />
            <Input id="2" value={"XX"} readOnly disabled maxW={"80px"} mr={2} />
            <Input
              name="lastSsn"
              type="text"
              placeholder=""
              {...(formik.touched.lastSsn && formik.errors.lastSsn
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.lastSsn}
              onBlur={formik.handleBlur}
            />
          </Flex>

          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.lastSsn && formik.errors.lastSsn
              ? formik.errors.lastSsn
              : ""}
          </Text>
        </FormControl>
      </Flex>

      <Flex justify={"end"} mt={8}>
        <Button
          type="submit"
          ml={4}
          style={{
            minWidth: "80px",
            background:
              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
          }}
          isDisabled={!isValidDOB}
        >
          Confirm & Next
        </Button>

        {process.env.REACT_APP_KBA_SKIP === "1" && (
          <Button
            ml={2}
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            width={"fit-content"}
            onClick={() => {
              joinSession("approved");
            }}
            isDisabled={isSubmit}
          >
            Skip KBA & Join Session
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default SignerInfo;
