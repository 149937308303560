import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FileUploadApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import ScribbleBoard from "../../commonComponents/ScribbleBoard";

const ScribblePopover = ({
  onSetFile,
  from,
}: {
  from: string;
  onSetFile: (path: string) => void;
}) => {
  const { configuration } = useAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [draw, setDraw] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const uploadSignature = (base64String: string) => {
    setIsDisable(true);
    new FileUploadApi(configuration)
      .fileControllerUpload({
        b64FileUploadDto: {
          base64: base64String?.split(",")[1],
          extension: "png",
        },
      })
      .then((res) => {
        onSetFile(res?.path);
        onClose();
        setDraw(false);
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });
  };

  return (
    <Popover onClose={onClose} closeOnBlur isOpen={isOpen} onOpen={onOpen}>
      <PopoverTrigger>
        <Button onClick={() => setDraw(true)}>Create {from}</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Confirmation!</PopoverHeader>
        <PopoverBody>
          {draw && (
            <ScribbleBoard
              canvasWidth={300}
              canvasHeight={150}
              onSendCanvas={(file) => uploadSignature(file)}
              isDisable={isDisable}
              onClose={onClose}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ScribblePopover;
