import { Box, Text } from "@chakra-ui/react";
import PaymentData from "../../component/commonComponents/PaymentCard/PaymentData";
import BaseLayout from "../../layout/BaseLayout";

const PaymentInfo = () => {
  return (
    <BaseLayout>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={4}>
        Payment Details
      </Text>
      <Text textAlign="center" fontSize="14px" mb={4}>
        <span
          style={{
            fontWeight: 600,
            textDecoration: "underline",
            fontSize: "15px",
          }}
        >
          Note:
        </span>{" "}
        Default card will be used when charging for subscriptions or invoices
      </Text>
      <Box
        style={{ margin: "auto", maxWidth: "400px", width: "90%" }}
        fontFamily="Lato"
        fontWeight={600}
        // px={18}
      >
        <PaymentData />
      </Box>
    </BaseLayout>
  );
};

export default PaymentInfo;
