import { useEffect } from "react";
import SignerDocumentUpload from "../../../../component/Sessions/Signer/SignerDocumentUpload";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SessionDocumentUploadPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  return <SignerDocumentUpload />;
};

export default SessionDocumentUploadPage;
