import { Box } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoArrowBackOutline as BackIcon } from "react-icons/io5";
import { PropsWithChildren, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AccessErrorScreen from "../component/AccessErrorScreen";
import Navbar from "../component/Navbar/Navbar";

const BaseLayout = ({
  children,
  showBack = true,
}: PropsWithChildren<{
  showBack?: boolean;
}>) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isShowErrScreen, setIsShowErrScreen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access-token");
  const token = localStorage.getItem("accessToken");
  // const [token, setToken] = useState(
  //   localStorage.getItem("accessToken") !== null
  //     ? localStorage.getItem("accessToken")
  //     : accessToken
  // );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width < 1000 && localStorage.getItem("role") === "notary") {
      setIsShowErrScreen(true);
    } else {
      setIsShowErrScreen(false);
    }
  }, [width]);

  const navigate = useNavigate();

  // useEffect(() => {
  if (accessToken !== null) {
    localStorage.setItem("accessToken", accessToken);
    // setToken(accessToken);
  }
  // }, [accessToken]);

  return (
    <>
      {(token !== null || accessToken !== null) && (
        <>
          {isShowErrScreen ? (
            <AccessErrorScreen />
          ) : (
            <Box
              height={"50vh"}
              width={"100%"}
              bg="linear-gradient(180deg, rgba(0, 0, 128) 0%, #000080 100%)"
            >
              <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
              />
              <Navbar isShowMenu={false} />
              <Box
                p={5}
                height={{ base: "93vh", md: "91vh", lg: "91vh" }}
                width={{ base: "100vw", md: "90vw", lg: "90vw" }}
                margin={"auto"}
                backgroundColor={"#fff"}
                boxShadow={"xl"}
                borderRadius={12}
                overflowY="auto"
                fontFamily={"Lato"}
              >
                {showBack && (
                  <Box
                    onClick={() => navigate("/")}
                    backgroundColor="#D5AD36"
                    position={"sticky"}
                    p={2}
                    borderRadius={100}
                    top={0}
                    left={0}
                    width={8}
                  >
                    <BackIcon color="#fff" />
                  </Box>
                )}

                {children}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default BaseLayout;
