import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { getMonth } from "../../utils/utils";
import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import TableData from "./components/TableData";
import { Session, SessionApi } from "../../api";
import { auditFormCount, LogData } from "../../utils/utils-apis";
import { TiExport as ExportIcon } from "react-icons/ti";
import useNotification from "../../utils/useNotification";
import NoDataFound from "../commonComponents/LoadingComponents/NoDataFound";
import { LimitOptions } from "../../utils/options";
import { IFilter, INewSession, IReactSelect } from "../../store/zustandType";
import ListHeader from "../commonComponents/ListComponents/ListHeader";
import "./NotaryJournal.css";

const tableHeaderData = [
  "ID",
  "Status",
  "Time of Notarial Act",
  "Type of Notarial Act",
  "Type or Title of Document",
  "Name of Signer",
  "Address of Signer",
  "Identification Method",
  "Form of ID",
  "ID Date of Issuance",
  "ID Expiration Date",
  "Transaction ID",
  "Description of Online System",
  "Notes",
  "Tamper Check",
  "Video Link",
  "Details",
];

const NotaryJournalNew = () => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [data, setData] = useState<Session[]>([]);
  const [callApi, setCallApi] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [exporting, setExporting] = useState<boolean>(false);
  const [sessions, setSessions] = useState<INewSession[]>([]);
  const [selectEntries, setSelectEntries] = useState<number[]>([]);
  const [filters, setFilters] = useState<IFilter>({
    searchTerm: "",
    date: "",
  });
  const [limit, setLimit] = useState<IReactSelect>({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState<IReactSelect>({
    label: "All",
    value: "all",
  });

  useEffect(() => {
    if (callApi) {
      setLoading(true);
      new SessionApi(configuration)
        .sessionControllerGetByNotaryId({
          page: 1,
          limit: Number(limit?.value),
          search: filters?.searchTerm,
          status: status?.value === "all" ? "" : (status?.value as string),
        })
        .then((res) => {
          setData(res?.items);
          setLoading(false);
          // @ts-ignore
          setTotalPages(res.meta?.totalPages);
          // @ts-ignore
          setCurrentPage(res.meta?.currentPage);
          setSelectEntries([]);
          setCallApi(false);
        })
        .catch(async (err) => {
          const message = await handleError(err);
          notification("fail", message.errorMessage);
          setLoading(false);
          setCallApi(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, filters?.searchTerm, status?.value, callApi]);

  const handlePageChange = (event: { selected: number }) => {
    new SessionApi(configuration)
      .sessionControllerGetByNotaryId({
        page: event.selected + 1,
        limit: Number(limit?.value),
        search: filters?.searchTerm,
        status: status?.value === "all" ? "" : (status?.value as string),
      })
      .then((res) => {
        setData(res?.items);
        // @ts-ignore
        setCurrentPage(res.meta?.currentPage);
        setSelectEntries([]);
      });
  };

  const handleExportCheckbox = (idx: number) => {
    setChecked(true);
    const index = selectEntries.findIndex((a) => a === idx);
    if (index === -1) {
      setSelectEntries([...selectEntries, idx]);
    } else {
      setSelectEntries([
        ...selectEntries.slice(0, index),
        ...selectEntries.slice(index + 1),
      ]);
    }
  };

  useEffect(() => {
    let newData: any = [];
    data?.forEach((session) => {
      if (session?.isNotaryExemplarSheet) {
        newData.push(session);
      } else {
        newData.push(
          session.signers.map((signer) => ({
            ...session,
            signer: signer,
          }))
        );
      }
    });
    const newArr = newData.flat();
    setSessions(newArr);
  }, [data]);

  const getRowsData = async (session: INewSession) => {
    const kbaLogId = session?.kbaLogs?.find(
      (kba) => Number(kba?.userId) === Number(session?.primarySignerUserId)
    )?.kbaLogId;

    const kbaDetail =
      kbaLogId === "demo" ||
      kbaLogId === "knownByNotary" ||
      kbaLogId === undefined ||
      kbaLogId === "pending" ||
      kbaLogId === ""
        ? null
        : await LogData(configuration, Number(kbaLogId));

    const formData =
      session?.status !== "pending"
        ? await auditFormCount(configuration, Number(session?.sessionId))
        : null;

    const newArr = [
      session?.sessionId,
      session?.status?.toUpperCase(),
      moment(
        session?.scheduleDate !== ""
          ? session?.scheduleDate
          : session?.createdDate
      ).format("MM/DD/YYYY h:mm:ss A"),
      `${
        formData === null
          ? ""
          : formData?.totalJurat !== 0 || formData?.totalAcknowlegement !== 0
          ? `Jurat(${formData?.totalJurat}) Acknowlegement(${formData?.totalAcknowlegement})`
          : ""
      }`,
      // "Acknowlegement",
      session?.documents?.[0]?.name,
      `${session?.signers[0]?.firstName} ${session?.signers[0]?.lastName}`,
      `${
        session?.signers[0]?.addressLine1 !== undefined
          ? `${session?.signers[0]?.addressLine1} ${session?.signers[0]?.addressLine2} ${session?.signers[0]?.state} ${session?.signers[0]?.city} ${session?.signers[0]?.zip}`
          : "No address"
      }`,
      `${
        kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : "KBA + Credetial Analysis"
      }`,
      `${
        kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : kbaDetail === null
          ? "" // @ts-ignore
          : kbaDetail?.scanResponse?.response?.["located-id-scan-record"]?.[
              "id-scan-document-type"
            ]
      }`,
      `${
        kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : kbaDetail === null
          ? ""
          : `${getMonth(
              // @ts-ignore
              kbaDetail?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-date-of-issuance"
              ]?.month
            )} ${
              // @ts-ignore
              kbaDetail?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-date-of-issuance"
              ]?.year
            }`
      }`,
      `${
        kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : kbaDetail === null
          ? ""
          : `${getMonth(
              // @ts-ignore
              kbaDetail?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-expiration-date"
              ]?.month
            )} ${
              // @ts-ignore
              kbaDetail?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-expiration-date"
              ]?.year
            }`
      }`,
      session?.invoiceNumber,
      "Notarized online via Notarize Genie",
      session?.notaryNote,
      session?.status === "completed" ? "✅ " : "❌",
    ];

    return newArr;
  };

  const exportSessions = async () => {
    setExporting(true);
    const selectedSessions =
      selectEntries.length !== 0
        ? sessions?.filter((el, idx) => selectEntries.includes(idx))
        : sessions;
    const promises = selectedSessions.map((s) => getRowsData(s));
    const myExportedArr = await Promise.all(promises);

    const rows = [
      [
        "Session Id",
        "Status",
        "Time of Notarial Act",
        "Type of Notarial Act",
        "Type or Title of Document",
        "Name of Signer",
        "Address of Signer",
        "Identification Method",
        "Form of ID",
        "ID Date of Issuance",
        "ID Expiration Date",
        "Transaction ID",
        "Description of Online System",
        "Notes",
        "Tamper Check",
      ],
      ...myExportedArr,
    ];

    try {
      const csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      const url = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `NotarizeGeine-Sessions-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      setExporting(false);
      setSelectEntries([]);
      link.remove();
    } catch (error) {
      setExporting(false);
      setSelectEntries([]);
    }
  };

  return (
    <Stack height={"100%"}>
      <>
        <Text
          textAlign={"center"}
          fontSize={"24px"}
          fontFamily="Oswald"
          fontWeight={"700"}
          mb={6}
        >
          Notary Journal
        </Text>

        <Flex
          style={{ gap: "20px" }}
          px={3}
          pb={6}
          justifyContent="space-between"
        >
          <Box width={"70%"}>
            <ListHeader
              status={status}
              filters={filters}
              onSetFilters={(value) => {
                setFilters(value);
                setCallApi(true);
              }}
              onSetStatus={(value) => {
                setStatus(value);
                setCallApi(true);
              }}
            />
          </Box>

          <Button
            _hover={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#fff",
            }}
            border={"1px solid #D5AD36"}
            backgroundColor={"#fff"}
            color={"#D5AD36"}
            onClick={exportSessions}
            isDisabled={exporting}
          >
            <ExportIcon size={20} /> <Text ml={2}>Export</Text>
          </Button>
        </Flex>
      </>

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : data?.length === 0 ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer
            className="notary-table"
            style={{ overflowY: "auto" }}
          >
            <Table size={"sm"}>
              <Thead>
                <Th>
                  <Checkbox
                    isChecked={
                      selectEntries.length !== sessions.length ? false : checked
                    }
                    onChange={(e) => {
                      setChecked(e.target.checked);
                      if (e.target.checked) {
                        setSelectEntries(sessions.map((el, idx) => idx));
                      } else {
                        setSelectEntries([]);
                      }
                    }}
                  />
                </Th>
                {tableHeaderData.map((el, idx) => (
                  <Th key={idx} style={{ color: "#000" }}>
                    {el}
                  </Th>
                ))}
              </Thead>
              <Tbody>
                {sessions?.map((item, idx) => (
                  <Tr key={idx} style={{ fontWeight: 500 }}>
                    <Td>
                      <Checkbox
                        isChecked={selectEntries?.includes(idx)}
                        onChange={() => handleExportCheckbox(idx)}
                      />
                    </Td>

                    <TableData
                      signer={item?.signer}
                      session={item}
                      onSetCallApi={() => setCallApi(true)}
                    />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex justifyContent={"space-between"}>
            <Box></Box>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next >"
              onPageChange={handlePageChange}
              pageRangeDisplayed={1}
              pageCount={totalPages}
              marginPagesDisplayed={1}
              previousLabel="< Prev"
              containerClassName={"pagination"}
              activeClassName={"active"}
            />

            <Box width={"90px"} mt={3}>
              <Select
                placeholder="Select"
                menuPlacement="top"
                options={LimitOptions}
                value={limit}
                onChange={(option) => {
                  setLimit(option);
                  setCallApi(true);
                }}
              />
            </Box>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default NotaryJournalNew;
