import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  ModalHeader,
  Divider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import FlatList from "flatlist-react";
import { useState } from "react";
import { Notary } from "../../../api";
import { getStateName } from "../../../utils/utils";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";

const ArizonaDetailModal = ({
  isOpen,
  onOpen,
  onClose,
  arizonaNotaryList,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  arizonaNotaryList: Notary[];
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const listData = (item: Notary) => (
    <Tr style={{ fontWeight: 500 }}>
      <Td style={{ color: "#9A9595" }}>{item?.notaryId}</Td>
      <Td style={{ color: "#9A9595", textTransform: "capitalize" }}>
        {item?.firstName + " "} {item?.middleName + " "} {item?.lastName + " "}
        {item?.suffix}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {item.address1} {item.address2}, {item.city}
      </Td>
      <Td style={{ color: "#9A9595" }}>{item.phoneNumber}</Td>
    </Tr>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight="700"
            fontFamily="Lato"
          >
            Arizona Notaries
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <Divider />
        <ModalBody mx={5} mb={7} p={4}>
          <Text fontWeight={500}>
            Here is the list of Arizona Notaries. You can connect personally to
            a notary and request him/her for create a session(BYOT) for you.
          </Text>

          <Box mt={5}>
            <InputGroup width={"50%"} mb={8}>
              <InputLeftElement
                pointerEvents="none"
                children={<Search color="gray.300" />}
              />
              <Input
                type="text"
                autoFocus
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder="Search by Notary Id or Name"
              />
            </InputGroup>

            <TableContainer
              height={{ base: "392px", md: "unset", lg: "unset" }}
              style={{ overflow: "auto" }}
            >
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th style={{ color: "#000" }}>NOTARY ID</Th>
                    <Th style={{ color: "#000" }}>NAME</Th>
                    <Th style={{ color: "#000" }}>ADDRESS</Th>
                    <Th style={{ color: "#000" }}>PHONE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <FlatList
                    keyExtractor={(item: Notary, index: number) =>
                      index.toString()
                    }
                    list={arizonaNotaryList}
                    renderItem={listData}
                    renderWhenEmpty={() => (
                      <div style={{ marginTop: "10px" }}>No Items</div>
                    )}
                    searchTerm={searchTerm}
                    searchableMinCharactersCount={1}
                    searchBy={["notaryId", "firstName"]}
                    searchCaseInsensitive
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ArizonaDetailModal;
