/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NNAIdVerificationDTO
 */
export interface NNAIdVerificationDTO {
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    background: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    certificateExamPassed: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    educationCompleted: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    validThrough: string;
    /**
     * 
     * @type {object}
     * @memberof NNAIdVerificationDTO
     */
    state: object;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    expiration: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    insuranceCoverage: string;
    /**
     * 
     * @type {string}
     * @memberof NNAIdVerificationDTO
     */
    insuranceExpiration: string;
}

/**
 * Check if a given object implements the NNAIdVerificationDTO interface.
 */
export function instanceOfNNAIdVerificationDTO(value: object): value is NNAIdVerificationDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('background' in value) || value['background'] === undefined) return false;
    if (!('certificateExamPassed' in value) || value['certificateExamPassed'] === undefined) return false;
    if (!('educationCompleted' in value) || value['educationCompleted'] === undefined) return false;
    if (!('validThrough' in value) || value['validThrough'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('number' in value) || value['number'] === undefined) return false;
    if (!('expiration' in value) || value['expiration'] === undefined) return false;
    if (!('insuranceCoverage' in value) || value['insuranceCoverage'] === undefined) return false;
    if (!('insuranceExpiration' in value) || value['insuranceExpiration'] === undefined) return false;
    return true;
}

export function NNAIdVerificationDTOFromJSON(json: any): NNAIdVerificationDTO {
    return NNAIdVerificationDTOFromJSONTyped(json, false);
}

export function NNAIdVerificationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NNAIdVerificationDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'background': json['background'],
        'certificateExamPassed': json['certificateExamPassed'],
        'educationCompleted': json['educationCompleted'],
        'validThrough': json['validThrough'],
        'state': json['state'],
        'number': json['number'],
        'expiration': json['expiration'],
        'insuranceCoverage': json['insuranceCoverage'],
        'insuranceExpiration': json['insuranceExpiration'],
    };
}

export function NNAIdVerificationDTOToJSON(value?: NNAIdVerificationDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'background': value['background'],
        'certificateExamPassed': value['certificateExamPassed'],
        'educationCompleted': value['educationCompleted'],
        'validThrough': value['validThrough'],
        'state': value['state'],
        'number': value['number'],
        'expiration': value['expiration'],
        'insuranceCoverage': value['insuranceCoverage'],
        'insuranceExpiration': value['insuranceExpiration'],
    };
}

