/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CertificateResponseDTO
 */
export interface CertificateResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CertificateResponseDTO
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateResponseDTO
     */
    validTo: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateResponseDTO
     */
    path: string;
}

/**
 * Check if a given object implements the CertificateResponseDTO interface.
 */
export function instanceOfCertificateResponseDTO(value: object): value is CertificateResponseDTO {
    if (!('validFrom' in value) || value['validFrom'] === undefined) return false;
    if (!('validTo' in value) || value['validTo'] === undefined) return false;
    if (!('path' in value) || value['path'] === undefined) return false;
    return true;
}

export function CertificateResponseDTOFromJSON(json: any): CertificateResponseDTO {
    return CertificateResponseDTOFromJSONTyped(json, false);
}

export function CertificateResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'validFrom': json['validFrom'],
        'validTo': json['validTo'],
        'path': json['path'],
    };
}

export function CertificateResponseDTOToJSON(value?: CertificateResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'validFrom': value['validFrom'],
        'validTo': value['validTo'],
        'path': value['path'],
    };
}

