import { Stack, Text } from "@chakra-ui/react";
import Participants from "../components/SessionParticipants/Participants";
import { businessPaths } from "../../../routes/routes";

const BusinessSessionParticipants = () => {
  return (
    <Stack px={2}>
      <Text
        fontWeight={600}
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        textAlign={"center"}
        fontFamily={"oswald"}
      >
        Participants
      </Text>
      <Participants
        nextNavigationPath={`${businessPaths.createSession}/details`}
        prevNavigationPath={`${businessPaths.createSession}/document-upload`}
        from="business"
      />
    </Stack>
  );
};

export default BusinessSessionParticipants;
