import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Text,
  ModalBody,
  Box,
  Button,
  Modal,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  FormControl,
  FormLabel,
  ModalHeader,
  Divider,
} from "@chakra-ui/react";
import { useState } from "react";
import { AuthApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { BsPatchCheckFill as TickIcon } from "react-icons/bs";

const CouponModal = ({
  isOpen,
  onOpen,
  onClose,
  onApply,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onApply: () => void;
}) => {
  const { configuration } = useAuth();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [couponMsg, setCouponMsg] = useState<string>("");
  const [coupon, setCoupon] = useState<string>("");

  const handleClose = () => {
    setIsVerifying(false);
    setCouponMsg("");
    setIsValid(false);
    setCoupon("");
    onClose();
  };

  const handleVerifyCoupon = () => {
    setIsVerifying(true);
    new AuthApi(configuration)
      .authControllerCouponCheck({
        couponCode: coupon,
        couponVerifyBody: {
          by: "notary",
        },
      })
      .then((res) => {
        setCouponMsg(res?.status);
        if (res?.status === "Coupon verified!") {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
        setIsVerifying(false);
      })
      .catch((err) => {
        console.log(err);
        handleClose();
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    new AuthApi(configuration)
      .authControllerUpdate({
        userId: Number(localStorage.getItem("UserID")),
        userUpdate: {
          coupon: coupon,
        },
      })
      .then((res) => {
        if (
          res?.status === "200" ||
          res?.message === "Coupon Code Updated in user Successfully!"
        ) {
          handleClose();
          onApply();
        }
      });
  };

  return (
    <Modal closeOnEsc isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Apply Coupon</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />

        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody py={8} textAlign="center">
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="600"
                fontFamily="Lato"
              >
                Coupon
              </FormLabel>
              <InputGroup size="md" alignItems="center">
                <Input
                  variant="auth"
                  fontSize="sm"
                  type="text"
                  placeholder="Enter code"
                  name="coupon"
                  size="lg"
                  border="1px solid #E2E8F0"
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <InputRightElement
                  background="#EDF2F7"
                  borderLeft="1px solid #f7f7f8"
                  width={"80px"}
                  height={"48px"}
                  borderRadius="0px 10px 10px 0px"
                >
                  {isVerifying ? (
                    <Box>
                      <Spinner />
                    </Box>
                  ) : isValid ? (
                    <TickIcon size={20} color="green" />
                  ) : (
                    <Box role={"button"} onClick={handleVerifyCoupon}>
                      Verify
                    </Box>
                  )}
                </InputRightElement>
              </InputGroup>

              {couponMsg !== "" && (
                <Text
                  fontSize={"12px"}
                  color={isValid ? "green" : "red"}
                  px="2"
                  mt={1}
                  textAlign="left"
                >
                  {couponMsg}
                </Text>
              )}
            </FormControl>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              fontSize="14px"
              variant="dark"
              fontWeight="900"
              fontFamily="Lato"
              h="45"
              bgGradient="linear(to-t,#D5AD3680,#D5AD36)"
              type="submit"
              isDisabled={!isValid}
            >
              Apply Coupon
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CouponModal;
