import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import Select from "react-select";
import { SessionStatusOptions } from "../../../utils/options";
import { IFilter, IReactSelect } from "../../../store/zustandType";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";

const ListHeader = ({
  filters,
  status,
  onSetFilters,
  onSetStatus,
}: {
  filters: IFilter;
  status: IReactSelect;
  onSetFilters: (value: IFilter) => void;
  onSetStatus: (value: IReactSelect) => void;
}) => {
  return (
    <Flex gap="20px" px={3}>
      <InputGroup width={"50%"}>
        <InputLeftElement
          pointerEvents="none"
          children={<Search color="gray.300" />}
        />
        <Input
          type="text"
          autoFocus
          value={filters.searchTerm}
          onChange={(e) => {
            onSetFilters({ ...filters, searchTerm: e.target.value });
          }}
          placeholder="Search by Session ID"
        />
      </InputGroup>

      <Box width={"190px"}>
        <Select
          placeholder="Select"
          options={SessionStatusOptions}
          value={status}
          onChange={(option) => onSetStatus(option)}
        />
      </Box>

      {/* <Input
      width={"30%"}
      placeholder="Date"
      size="md"
      type="date"
      style={{ color: "#9A9595" }}
      value={filters.date}
      onChange={(e) => setFilters({ ...filters, date: e.target.value })}
    /> */}
    </Flex>
  );
};

export default ListHeader;
