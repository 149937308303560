/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConferenceJoinDTO
 */
export interface ConferenceJoinDTO {
    /**
     * 
     * @type {string}
     * @memberof ConferenceJoinDTO
     */
    sessionId: string;
}

/**
 * Check if a given object implements the ConferenceJoinDTO interface.
 */
export function instanceOfConferenceJoinDTO(value: object): value is ConferenceJoinDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    return true;
}

export function ConferenceJoinDTOFromJSON(json: any): ConferenceJoinDTO {
    return ConferenceJoinDTOFromJSONTyped(json, false);
}

export function ConferenceJoinDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConferenceJoinDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
    };
}

export function ConferenceJoinDTOToJSON(value?: ConferenceJoinDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
    };
}

