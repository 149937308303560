import {
  Box,
  Button,
  Text,
  useDisclosure,
  Flex,
  Heading,
  Divider,
  Center,
  Spinner,
} from "@chakra-ui/react";
import Plans from "./Components/Plans";
import PlansModal from "./Components/PlansModal";
import { espTransform } from "../../../utils/utils";
import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";
import { useEffect, useState } from "react";
import {
  RequestedSubscriptionApi,
  Subscription,
  SubscriptionApi,
} from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import BaseLayout from "../../../layout/BaseLayout";
import moment from "moment";

const BusinessPlans = () => {
  const { configuration } = useAuth();
  const [activePlan, setActivePlan] = useState<Subscription>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestedPlanData, setRequestedPlanData] = useState<{
    planName: string;
    activeDate: Date | null;
  }>({ planName: "", activeDate: null });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isDisablePlans = activePlan?.plan?.planName !== "Sands";

  useEffect(() => {
    setLoading(true);
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        setActivePlan(res);
        setLoading(false);
        new RequestedSubscriptionApi(configuration)
          .reqSubscriptionControllerGetPending()
          .then((result) => {
            if (result.status === 200) {
              setRequestedPlanData({
                planName: result?.planName,
                activeDate: result?.activePlanCreatedDate,
              });
            } else {
              setRequestedPlanData({
                planName: "",
                activeDate: null,
              });
            }
          })
          .catch((error) => {
            setRequestedPlanData({
              planName: "",
              activeDate: null,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : activePlan !== undefined ? (
        <div>
          <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={6}>
            Your Active Plan
          </Text>

          {requestedPlanData?.activeDate !== null && (
            <Text textAlign="center" fontSize="13px" mb={6}>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Note
              </span>{" "}
              - Your requested plan ({requestedPlanData?.planName}) will be
              active on{" "}
              {moment(requestedPlanData?.activeDate).format("MM-DD-YYYY")}
            </Text>
          )}

          <Center>
            <Box
              style={{ boxShadow: "3px 5px 20px 0px #ddd" }}
              w={{ md: "40%", lg: "40%", base: "90%" }}
              maxWidth={"300px"}
              px={5}
              py={10}
              borderRadius="10px"
            >
              <Heading
                fontSize="24px"
                fontFamily="Oswald"
                mb="6px"
                textAlign="center"
                color="#000080"
              >
                {activePlan?.plan?.planName}{" "}
                <span style={{ color: "#D5AD36" }}>Package</span>
              </Heading>
              <Divider my={4} mr={4} />
              <Text
                fontSize={"45px"}
                color="#000080"
                fontFamily="Oswald"
                textAlign="center"
                fontWeight={500}
              >
                {espTransform(Number(activePlan?.plan?.planAmount ?? 0))}/
                <span style={{ fontSize: "16px", color: "#000" }}>
                  Per month
                </span>
              </Text>

              <div>
                <Divider my={4} mr={4} />
                {activePlan.plan != null &&
                  JSON.parse(activePlan.plan!.planFeatures)?.map(
                    (item: any, idx: number) => (
                      <Flex key={idx} alignItems="flex-start" mb={3}>
                        <Box mt={1}>
                          <CheckIcon size={18} color="#D5AD36" />
                        </Box>
                        <Text ml={3} fontSize="16px">
                          {item}{" "}
                          {idx === 0 && (
                            <span style={{ fontSize: "10px" }}>
                              (Remaining Stamp Count - {activePlan?.freeStamps})
                            </span>
                          )}
                        </Text>
                      </Flex>
                    )
                  )}
              </div>

              <Divider my={4} mr={4} />
              <Center>
                <Button
                  px={10}
                  fontSize={"14px"}
                  fontWeight={600}
                  borderRadius={"34px"}
                  style={{
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                    color: "#fff",
                  }}
                  onClick={onOpen}
                >
                  Switch Plan
                </Button>
              </Center>
            </Box>
          </Center>
        </div>
      ) : (
        <Plans />
      )}

      <PlansModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isDisablePlans={isDisablePlans}
        activePlan={activePlan}
        requestedPlanData={requestedPlanData}
      />
    </BaseLayout>
  );
};

export default BusinessPlans;
