/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentROI
 */
export interface DocumentROI {
    /**
     * 
     * @type {number}
     * @memberof DocumentROI
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentROI
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentROI
     */
    document: number;
    /**
     * 
     * @type {object}
     * @memberof DocumentROI
     */
    data: object;
    /**
     * 
     * @type {Date}
     * @memberof DocumentROI
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof DocumentROI
     */
    updatedDate: Date;
}

/**
 * Check if a given object implements the DocumentROI interface.
 */
export function instanceOfDocumentROI(value: object): value is DocumentROI {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('document' in value) || value['document'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function DocumentROIFromJSON(json: any): DocumentROI {
    return DocumentROIFromJSONTyped(json, false);
}

export function DocumentROIFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentROI {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'document': json['document'],
        'data': json['data'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function DocumentROIToJSON(value?: DocumentROI | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'document': value['document'],
        'data': value['data'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
    };
}

