import { Text } from "@chakra-ui/react";
import { useSessionCreate } from "../../../store/store";
import VerificationData from "../../commonComponents/KbaVerificationModal/VerificationData";
import DemoVerificationData from "../../commonComponents/KbaVerificationModal/DemoKba/DemoVerificationData";

const SignerVerification = () => {
  const { sessionDetail } = useSessionCreate();

  return (
    <>
      <Text
        fontWeight={600}
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        textAlign={"center"}
        fontFamily={"oswald"}
      >
        Verification
      </Text>
      {sessionDetail.isDemoSession ? (
        <DemoVerificationData
          from="page"
          signerData={{
            dob: sessionDetail?.signers?.[0]?.dob as string,
            lastSsn: sessionDetail?.signers?.[0]
              ?.lastFourDigitsSsnNumber as string,
          }}
        />
      ) : (
        <VerificationData
          from="page"
          signerData={{
            dob: sessionDetail?.signers?.[0]?.dob as string,
            lastSsn: sessionDetail?.signers?.[0]
              ?.lastFourDigitsSsnNumber as string,
          }}
          isBiometric={
            sessionDetail?.specificNotaryState === "NJ" ||
            sessionDetail?.specificNotaryState === "NY"
          }
        />
      )}
    </>
  );
};

export default SignerVerification;
