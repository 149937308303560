/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdologyDocDTO
 */
export interface IdologyDocDTO {
    /**
     * 
     * @type {string}
     * @memberof IdologyDocDTO
     */
    first: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyDocDTO
     */
    firstBack: string;
}

/**
 * Check if a given object implements the IdologyDocDTO interface.
 */
export function instanceOfIdologyDocDTO(value: object): value is IdologyDocDTO {
    if (!('first' in value) || value['first'] === undefined) return false;
    if (!('firstBack' in value) || value['firstBack'] === undefined) return false;
    return true;
}

export function IdologyDocDTOFromJSON(json: any): IdologyDocDTO {
    return IdologyDocDTOFromJSONTyped(json, false);
}

export function IdologyDocDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdologyDocDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'first': json['first'],
        'firstBack': json['firstBack'],
    };
}

export function IdologyDocDTOToJSON(value?: IdologyDocDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first': value['first'],
        'firstBack': value['firstBack'],
    };
}

