import { useEffect } from "react";
import SessionTypeDetails from "../../../../component/Sessions/components/SessionTypeDetails";
import { useSignerSessionStepStore } from "../../../../store/signerStore";
import { signerPaths } from "../../../../routes/routes";

const SignerSessionTypePage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(0);
  }, [setStep]);

  return (
    <SessionTypeDetails
      nextButtonPath={`${signerPaths.createSession}/document-upload`}
    />
  );
};

export default SignerSessionTypePage;
