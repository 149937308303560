import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";
import { FeedbacksApi } from "../../api";
import { FeedbackTypeOptions } from "../../utils/options";
import { useAuth } from "../../utils/use-auth";
import useNotification from "../../utils/useNotification";

const FeedbackForm = forwardRef<HTMLFormElement, { onClose: () => void }>(
  ({ onClose }, ref) => {
    const navigate = useNavigate();
    const { configuration } = useAuth();
    const { notification } = useNotification();
    const [status, setStatus] = useState<
      SingleValue<{
        label: string;
        value: string;
      }>
    >();

    const formik = useFormik({
      initialValues: {
        title: "",
        status: "",
        description: "",
      },
      validationSchema: Yup.object({
        title: Yup.string()
          .required("Title is required")
          .max(300, "Title must be 300 characters or less."),
        description: Yup.string()
          .required("Description is required")
          .max(300, "Description must not exceed 300 characters"),
      }),

      onSubmit: (values) => {
        new FeedbacksApi(configuration)
          .feedBackControllerCreate({
            feedBackDTO: {
              title: values.title,
              description: values?.description,
              type: status?.value as string,
            },
          })
          .then((res) => {
            notification("success", "Feedback Sent Successfully");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            notification("fail", "Failed to send feedback");
          });
        navigate("/");
        onClose();
      },
      enableReinitialize: true,
    });

    return (
      <form onSubmit={formik.handleSubmit} ref={ref} onTouchCancel={onClose}>
        <Stack spacing={"4"} style={{ fontWeight: "600", fontFamily: "Lato" }}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              name="title"
              value={formik.values.title}
              placeholder="Enter Title"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              {...(formik.touched.title && formik.errors.title
                ? { isInvalid: true }
                : "")}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ""}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Type</FormLabel>
            {/* <Select
              placeholder="Select Type"
              options={FeedbackTypeOptions}
              value={status}
              onChange={(option) => setStatus(option)}
            /> */}
            <ChakraSelect
              placeholder="Select Type"
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue("status", e.target.value)}
            >
              {FeedbackTypeOptions?.flatMap((el) => (
                <option value={el.value}>{el.label}</option>
              ))}
            </ChakraSelect>
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              size="md"
              name="description"
              value={formik.values.description}
              placeholder="Enter Description"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              {...(formik.touched.description && formik.errors.description
                ? { isInvalid: true }
                : "")}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ""}
            </Text>
          </FormControl>
        </Stack>
        <Flex justifyContent={"flex-end"} mt={"20px"}>
          <Button
            w={"70px"}
            p="4"
            background="white"
            border={"1px solid rgba(0, 0, 128, 1)"}
            borderRadius="10px"
            fontSize={"16px"}
            color="rgba(0, 0, 128, 1)"
            onClick={() => onClose()}
            mr={3}
          >
            Cancel
          </Button>
          <Button
            // w={"70px"}
            p="4"
            background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
            borderRadius="10px"
            fontSize={"16px"}
            _hover={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
            }}
            type="submit"
          >
            Send
          </Button>
        </Flex>
      </form>
    );
  }
);

export default FeedbackForm;
