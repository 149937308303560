/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Notary } from './Notary';
import {
    NotaryFromJSON,
    NotaryFromJSONTyped,
    NotaryToJSON,
} from './Notary';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';

/**
 * 
 * @export
 * @interface Witness
 */
export interface Witness {
    /**
     * 
     * @type {number}
     * @memberof Witness
     */
    witnessId: number;
    /**
     * 
     * @type {string}
     * @memberof Witness
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Witness
     */
    lastName: string;
    /**
     * 
     * @type {number}
     * @memberof Witness
     */
    witnessPhone: number;
    /**
     * 
     * @type {string}
     * @memberof Witness
     */
    witnessEmail: string;
    /**
     * 
     * @type {string}
     * @memberof Witness
     */
    witnessAddress?: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Witness
     */
    user?: OmitTypeClass | null;
    /**
     * 
     * @type {Notary}
     * @memberof Witness
     */
    notary?: Notary | null;
}

/**
 * Check if a given object implements the Witness interface.
 */
export function instanceOfWitness(value: object): value is Witness {
    if (!('witnessId' in value) || value['witnessId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('witnessPhone' in value) || value['witnessPhone'] === undefined) return false;
    if (!('witnessEmail' in value) || value['witnessEmail'] === undefined) return false;
    return true;
}

export function WitnessFromJSON(json: any): Witness {
    return WitnessFromJSONTyped(json, false);
}

export function WitnessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Witness {
    if (json == null) {
        return json;
    }
    return {
        
        'witnessId': json['witnessId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'witnessPhone': json['witnessPhone'],
        'witnessEmail': json['witnessEmail'],
        'witnessAddress': json['witnessAddress'] == null ? undefined : json['witnessAddress'],
        'user': json['user'] == null ? undefined : OmitTypeClassFromJSON(json['user']),
        'notary': json['notary'] == null ? undefined : NotaryFromJSON(json['notary']),
    };
}

export function WitnessToJSON(value?: Witness | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'witnessId': value['witnessId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'witnessPhone': value['witnessPhone'],
        'witnessEmail': value['witnessEmail'],
        'witnessAddress': value['witnessAddress'],
        'user': OmitTypeClassToJSON(value['user']),
        'notary': NotaryToJSON(value['notary']),
    };
}

