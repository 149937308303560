import { useState } from "react";
import { useAuth } from "../../../utils/use-auth";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { DocumentApi, SessionApi } from "../../../api";
import { ISessionDoc } from "../../../store/zustandType";
import { useSessionDocStore } from "../../../store/sessionDocStore";
import SessionDocUpload from "../../commonComponents/UploadComponents/SessionDocUpload";
import {
  EventAction,
  EventType,
  useAuditLogsStore,
} from "../../../store/auditLogs";

const UploadAdditionalDocument = ({ onClose }: { onClose: () => void }) => {
  const { configuration } = useAuth();
  const sessionDocs = useSessionDocStore((state) => state.sessionDocs);
  const [socket, sessionId] = useAuditLogsStore((state) => [
    state.socket,
    state.sessionId,
  ]);
  const [newDocumentsCount, setNewDocumentsCount] = useState<number>(-1);
  const [oldDocumentsCount, setOldDocumentsCount] = useState<number>(
    sessionDocs?.length
  );

  const uploadDocument = (files: ISessionDoc[]) => {
    files?.forEach((file) => {
      new DocumentApi(configuration)
        .documentControllerCreate({
          documentDTO: {
            name: file?.filename ?? "",
            url: file?.path,
          },
        })
        .then((doc) => {
          new SessionApi(configuration).sessionControllerUpdateSession({
            sessionId: Number(sessionId),
            sessionUpdateDTO: {
              documents: [doc?.docId?.toString()],
            },
          });

          emitEvent({
            action: EventAction.newDocAdded,
            event: EventType.document,
            pageNumber: undefined,
            payload: { doc: doc },
            sessionId,
            socket,
          });
        });
    });
    if (oldDocumentsCount + newDocumentsCount === sessionDocs?.length) {
      onClose();
    }
  };

  return (
    <SessionDocUpload
      file={[]}
      onUpload={(files) => {
        setNewDocumentsCount(files?.length);
        uploadDocument(files);
      }}
      from="addDoc"
    />
  );
};

export default UploadAdditionalDocument;
