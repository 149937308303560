import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Flex,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NNAIdVerificationDTO, NotaryApi } from "../../api";
import { useProfileDetailsStore } from "../../store/notaryStore";
import { useAuth } from "../../utils/use-auth";
import { notaryPaths } from "../../routes/routes";

const NNaDetailsForm = () => {
  const { configuration } = useAuth();
  const navigate = useNavigate();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [docVerfiyData, setDocVerifyData] = useState<NNAIdVerificationDTO>();
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotaryProfile({
      ...notaryProfile,
      [e.target.name]: e.target.value,
    });
  };

  const updateNotaryProfile = () => {
    new NotaryApi(configuration)
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          nnaIdNumber: notaryProfile?.nnaIdNumber,
          notarySigningAgentExamExpirationDate: new Date(
            notaryProfile?.notarySigningAgentExamExpirationDate
          ).toUTCString(),
          backgroundCheckExpirationDate: new Date(
            notaryProfile?.backgroundCheckExpirationDate
          ).toUTCString(),
        },
      })
      .then(() => {
        navigate(`${notaryPaths.profileSetting}/commission-details`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateNotaryProfile();
  };

  const handleVerify = () => {
    setIsVerifying(true);
    new NotaryApi(configuration)
      .notaryControllerNnaVeriFication({
        nnaId: notaryProfile?.nnaIdNumber,
      })
      .then((res) => {
        setDocVerifyData(res);
        setIsVerifying(false);
        setNotaryProfile({
          ...notaryProfile,
          notarySigningAgentExamExpirationDate: res?.certificateExamPassed,
          backgroundCheckExpirationDate: res?.background,
          commissionNumber: res.number,
          commissionExpirationDate: res.expiration,
          insuranceAmount: res.insuranceCoverage?.replace(/[^0-9.-]+/g, ""),
          policyExpiryDate: res.insuranceExpiration,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsVerifying(false);
      });
  };

  return (
    <Stack px={6}>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        NNA Details
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Optional. However, only those Notaries with a NNA Signing Agent Profile
        & Background Check will be approved to notarize for Notarize Genie’s
        business partners via the On-Demand queue. All notaries once approved,
        will be permitted to bring their own transactions.
      </Text>

      <form onSubmit={(e) => handleSubmit(e)}>
        <Flex flexWrap={"wrap"} fontFamily="Lato" fontWeight={600} mt={3}>
          <FormControl id={`E&OInsurance Provider`} w={"100%"} p={2}>
            <FormLabel p="1" fontFamily="Lato" fontWeight={600}>
              NNA Signing Agent Profile Number
            </FormLabel>
            <InputGroup size="md" alignItems="center">
              <Input
                name="nnaIdNumber"
                value={notaryProfile.nnaIdNumber}
                type="number"
                placeholder={`NNA Id Number`}
                onChange={(e) => onChangeHandler(e)}
              />
              <InputRightElement
                background="#EDF2F7"
                borderLeft="1px solid #f7f7f8"
                width={"80px"}
                borderRadius="0px 10px 10px 0px"
              >
                {isVerifying ? (
                  <Box>
                    <Spinner />
                  </Box>
                ) : docVerfiyData != undefined ? (
                  <Box>Verified</Box>
                ) : (
                  <Box role={"button"} onClick={handleVerify}>
                    Verify
                  </Box>
                )}
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl
            id="notary_signing_agent_exam_expiration_date"
            maxW={"340px"}
            minW={"300px"}
            p={2}
          >
            <FormLabel p="1" fontFamily="Lato" fontWeight={600}>
              NNA NSA Certification Exam Passed
            </FormLabel>
            <Input
              name="notarySigningAgentExamExpirationDate"
              value={moment(
                notaryProfile.notarySigningAgentExamExpirationDate
              ).format("YYYY-MM-DD")}
              type="date"
              color={"grey"}
              onChange={(e) => onChangeHandler(e)}
            />
          </FormControl>

          <FormControl
            id="background_check_expirations_date "
            maxW={"340px"}
            minW={"300px"}
            p={2}
          >
            <FormLabel p="1" fontFamily="Lato" fontWeight={600}>
              NNA Background Screening Passed:
            </FormLabel>
            <Input
              name="backgroundCheckExpirationDate"
              value={moment(notaryProfile.backgroundCheckExpirationDate).format(
                "YYYY-MM-DD"
              )}
              type="date"
              color={"grey"}
              onChange={(e) => onChangeHandler(e)}
            />
          </FormControl>
        </Flex>

        <Box ml="auto" p="2">
          <Flex justifyContent={"space-between"}>
            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              onClick={() =>
                navigate(`${notaryPaths.profileSetting}/personal-information`)
              }
            >
              PREV
            </Button>

            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              type="submit"
              isDisabled={isVerifying}
            >
              SAVE & NEXT
            </Button>
          </Flex>
        </Box>
      </form>
    </Stack>
  );
};

export default NNaDetailsForm;
