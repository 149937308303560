import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { BsEye as ViewIcon } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import { RiDeleteBinLine as DeleteIcon } from "react-icons/ri";
import PreviewModal from "../PreviewModal";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl } from "../../../utils/utils-apis";
import { DocumentApi, FileUploadApi } from "../../../api";
import useNotification from "../../../utils/useNotification";
import UploadIcon1 from "../../../assets/icons/uploadIcon1.png";
import { IFileInfo, ISessionDoc } from "../../../store/zustandType";
import { imageToPdf } from "../../../utils/utils-functions";

const SessionDocUpload = ({
  file,
  onUpload,
  from,
}: {
  from?: string;
  file: ISessionDoc[];
  onUpload: (file: ISessionDoc[]) => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState<string>("");
  const [docs, setDocs] = useState<ISessionDoc[]>(file);
  const [fileuploading, setFileUploading] = useState(false);
  const [acceptedFilesCount, setAcceptedFilesCount] = useState<number>(0);

  const handleUpload = (fileToSend: IFileInfo | Blob, filename?: string) => {
    new FileUploadApi(configuration)
      .fileControllerUploadS3File({
        file: fileToSend,
      })
      .then((res) => {
        new DocumentApi(configuration)
          .documentControllerCreate({
            documentDTO: {
              name: filename ?? "",
              url: res?.path,
            },
          })
          .then((result) => {
            if (from === "sessionUpload") {
              onUpload([
                {
                  docId: result?.docId,
                  filename: result?.name as string,
                  path: res?.path,
                },
              ]);
            }

            setDocs((prev) => [
              ...(prev || []),
              {
                docId: result?.docId,
                filename: result?.name as string,
                path: res?.path,
              },
            ]);
            notification("success", "Document Uploaded Successfully");
            setFileUploading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("err", err);
        setFileUploading(false);
        notification("fail", "Failed to Upload Document");
      });
  };

  const onDrop = useCallback(
    (acceptedFiles: IFileInfo[], fileRejections: any) => {
      setAcceptedFilesCount(acceptedFiles?.length);
      setFileUploading(true);

      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            notification("fail", err.message);
          }
          if (err.code === "file-invalid-type") {
            notification("fail", err.message);
          }
          setFileUploading(false);
        });
      });

      acceptedFiles.forEach((file: IFileInfo) => {
        // handleUpload(file, file.name);
        if (file.type === "application/pdf") {
          handleUpload(file, file.name);
        } else if (
          ["image/png", "image/jpg", "image/jpeg"].includes(file.type)
        ) {
          const reader = new FileReader();
          reader.onloadend = () => {
            imageToPdf(reader.result as string).then((pdf) => {
              console.log("imgpdf", pdf);
              handleUpload(pdf, file.name);
            });
          };
          reader.readAsDataURL(file);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    // maxSize: 625000,
    multiple: true,
  });

  const handleView = (item: ISessionDoc) => {
    getFileUrl(configuration, item?.path).then((url) => {
      setUrl(url);
    });
    onOpen();
  };

  useEffect(() => {
    if (from !== "sessionUpload" && from !== "addDoc") {
      if (docs?.length !== 0) {
        onUpload(docs);
      }
    } else if (from === "addDoc") {
      if (docs?.length === acceptedFilesCount) {
        onUpload(docs);
      }
    }
  }, [docs, from, acceptedFilesCount]);

  return (
    <>
      <Flex
        align="center"
        justify="center"
        bg={useColorModeValue("none", "navy.900")}
        border={useColorModeValue("1px dashed #000", "none")}
        borderRadius={8}
        w="60%"
        minH="130px"
        cursor="pointer"
        {...getRootProps({ className: "dropzone" })}
      >
        {/* @ts-ignore */}
        <Input type="image" variant="main" {...getInputProps()} />
        <Button
          variant="no-effects"
          display={"flex"}
          flexDirection="column"
          alignItems="center"
        >
          {fileuploading ? (
            <Box width={"100%"}>
              <Text mb={2}>Uploading....</Text>
              <Progress size="xs" isIndeterminate />
            </Box>
          ) : (
            <>
              <Image src={UploadIcon1} />
              <Text color="gray.400" fontWeight="normal">
                Upload File
              </Text>
            </>
          )}
        </Button>
      </Flex>

      {docs.length !== 0 && (
        <Flex direction={"column"} gap={4} pb={4}>
          {docs?.map((item, idx) => (
            <Flex gap={2} alignItems="center">
              {from !== "sessionUpload" && from !== "addDoc" && (
                <>
                  <Button
                    onClick={() => {
                      handleView(item);
                    }}
                  >
                    <ViewIcon />
                  </Button>

                  <Button
                    onClick={() => {
                      setDocs([...docs.slice(0, idx), ...docs.slice(idx + 1)]);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </>
              )}

              <Text fontSize={14}>
                {item.filename ?? `Document ${idx + 1}`}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}

      <PreviewModal
        isOpen={isOpen}
        onClose={() => {
          setUrl("");
          onClose();
        }}
        onOpen={onOpen}
        url={url}
        type={[".pdf"]}
      />
    </>
  );
};

export default SessionDocUpload;
