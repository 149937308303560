import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/use-auth";
import { notaryPaths } from "../../../routes/routes";
import useCreateUser from "../../../hooks/useCreateUser";
import { createSession } from "../../../utils/utils-apis";
import useNotification from "../../../utils/useNotification";
import { useProfileDetailsStore } from "../../../store/notaryStore";
import DemoSessionComponent from "../components/DemoSessionComponent";
import PaymentData from "../../commonComponents/PaymentCard/PaymentData";
import { initialSessionDetails, useSessionCreate } from "../../../store/store";

const Payment = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const sendSynchronously = useCreateUser();
  const { notification } = useNotification();
  const { notaryProfile } = useProfileDetailsStore();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  const createNotaySession = async () => {
    setIsSubmit(true);
    const users = await sendSynchronously();
    createSession(
      sessionDetail,
      users,
      configuration,
      "notary",
      notaryProfile.isInhouseNotary
    )
      .then((res) => {
        notification("success", "Session Created Successfully");
        navigate("/notary/sessions/active");
        setSessionDetails(initialSessionDetails);
        setIsSubmit(false);
      })
      .catch((err) => {
        notification("fail", "Failed to Create Session");
        setIsSubmit(false);
      });
  };

  useEffect(() => {
    if (notaryProfile.isInhouseNotary) {
      setSessionDetails({
        ...sessionDetail,
        paymentBy: "business",
        documentFees: 0,
      });
    } else if (notaryProfile.state === "AZ") {
      setSessionDetails({
        ...sessionDetail,
        paymentBy: "notary",
        documentFees: 0,
      });
    }
  }, [notaryProfile.state, notaryProfile.isInhouseNotary]);

  return (
    <>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign="center"
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Payment
      </Heading>

      {!sessionDetail.isTitleLenderSession && <DemoSessionComponent />}

      {!sessionDetail.isDemoSession && (
        <>
          {notaryProfile.isInhouseNotary ? (
            <Alert status="info" mb={4} mt={8}>
              <AlertIcon />
              <AlertDescription fontSize={"14px"} lineHeight={"5"}>
                <AlertTitle>Note!</AlertTitle>
                Your business will pay for this session.
              </AlertDescription>
            </Alert>
          ) : (
            <>
              <Text fontWeight={600}>
                Who will be paying for this transaction?
              </Text>
              <RadioGroup
                value={sessionDetail?.paymentBy}
                onChange={(value) => {
                  if (value === "signer") {
                    setSessionDetails({ ...sessionDetail, paymentBy: value });
                  } else {
                    setSessionDetails({
                      ...sessionDetail,
                      paymentBy: value,
                      documentFees: 0,
                    });
                  }
                }}
              >
                <Stack direction="row" gap={3}>
                  {notaryProfile.state !== "AZ" && (
                    <Radio value="signer">Signer(s) will pay</Radio>
                  )}
                  <Radio value="notary">Notary will pay</Radio>
                </Stack>
              </RadioGroup>

              {sessionDetail?.paymentBy === "notary" ? (
                <PaymentData onDisable={(value) => setIsDisable(value)} />
              ) : (
                <Box>
                  <Box mt={4}>
                    <Text mb={2} fontWeight={600}>
                      Please enter your Document Preparation & Technology Fee
                    </Text>
                    <InputGroup width="40%">
                      <InputLeftAddon children="$" />
                      <Input
                        type="number"
                        value={
                          sessionDetail?.documentFees === 0
                            ? ""
                            : sessionDetail?.documentFees
                        }
                        placeholder="Enter Amount"
                        onChange={(e) =>
                          setSessionDetails({
                            ...sessionDetail,
                            documentFees: Number(e.target.value),
                          })
                        }
                      />
                    </InputGroup>
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}

      <Flex justifyContent="flex-end">
        <Button
          marginTop={5}
          width="fit-content"
          alignSelf="end"
          style={{ padding: "10px 30px" }}
          onClick={() => {
            navigate(`${notaryPaths.createSession}/notary-note`);
          }}
        >
          Prev
        </Button>
        <Button
          marginTop={5}
          ml={2}
          style={{ background: "#2D3748", color: "#fff", padding: "10px 30px" }}
          width="fit-content"
          alignSelf="end"
          onClick={() => createNotaySession()}
          isDisabled={
            (notaryProfile.isInhouseNotary
              ? false
              : sessionDetail.paymentBy === "notary"
              ? isDisable
              : false) || isSubmit
          }
        >
          Create Session
        </Button>
      </Flex>
    </>
  );
};

export default Payment;
