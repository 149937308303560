//@ts-nocheck
import {
  Box,
  Button,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useState } from "react";
import Select, { SingleValue } from "react-select";
import { FieldArray, Form, Formik } from "formik";
import FormData from "./FormData";
import { useAuth } from "../../../../utils/use-auth";
import { IWitness } from "../../../../store/zustandType";
import useNotification from "../../../../utils/useNotification";
import { MeetingMediatorApi, SessionApi } from "../../../../api";
import { NoOfWitnessOptions } from "../../../../utils/options";
import { fourFieldsValidations } from "../../../../utils/validations";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";

const InviteWitness = ({ onSuccess }: { onSuccess: () => void }) => {
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const [errMsg, setErrMsg] = useState<string>("");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("notaryWitness");
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: number;
      value: number;
    }>
  >({
    label: 1,
    value: 1,
  });

  const createWitnesses = (participants: IWitness[]) => {
    const promises: any[] = [];
    return participants.map((el) => {
      promises.push(
        new MeetingMediatorApi(configuration).mediatorControllerWitnessPost({
          witnessDTO: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email?.toLowerCase(),
            witnessPhone: Number(el?.phoneNumber?.replace(/[^\d]/g, "")),
          },
        })
      );
      return Promise.allSettled(promises);
    });
  };

  const handleInviteOwnWitness = (values: { participants: IWitness[] }) => {
    const totalParticipants = values?.participants?.length;
    if (totalParticipants > 5) {
      setErrMsg(
        "You can add maximum 5 participants in a session including additional signers and witnesses"
      );
    } else {
      setErrMsg("");
      if (sessionInfo?.sessionId) {
        setIsDisable(true);
        createWitnesses(values.participants).forEach((el) => {
          el.then((each) => {
            // @ts-ignore
            const participants = each.map((signer) => signer.value);
            const dataToSend = {
              sessionId: Number(sessionInfo?.sessionId),
              witnessId: participants.map((data) => data.witnessId),
            };
            if (participants?.length === values.participants.length) {
              new SessionApi(configuration)
                .sessionControllerInviteOwnWitnesss({
                  inviteWitnessDTO: dataToSend,
                })
                .then((res) => {
                  notification("success", "Invited Sucessfully!");
                  setIsDisable(false);
                  onSuccess();
                })
                .catch(() => {
                  setIsDisable(false);
                });
            } else {
              setIsDisable(false);
            }
          });
        });
      }
    }
  };

  const handleInvite = () => {
    setIsDisable(true);
    new SessionApi(configuration)
      .sessionControllerUpdate({
        sessionId: Number(sessionInfo?.sessionId),
        sessionUpdateDTO: {
          witnessesCount: selectedOption?.value,
        },
      })
      .then((res) => {
        notification("success", "Invited Sucessfully!");
        setIsDisable(false);
        onSuccess();
      })
      .catch(() => {
        setIsDisable(false);
      });
  };

  return (
    <>
      <Text
        fontWeight={600}
        fontSize={{ base: "17px", md: "20px", lg: "20px" }}
        color={"#000080"}
        pt={2}
      >
        Witness
      </Text>

      <RadioGroup
        mb={5}
        value={selectedValue}
        onChange={(value) => {
          setSelectedValue(value);
        }}
      >
        <Stack direction="row" style={{ marginTop: "10px" }}>
          <Radio value="notaryWitness" pl={3}>
            Need notarize genie witness
          </Radio>
          <Radio value="ownWitness" pl={3}>
            Bring your own witness
          </Radio>
        </Stack>
      </RadioGroup>

      {selectedValue === "notaryWitness" ? (
        <>
          <Text fontWeight={500} mt={5}>
            Select number of witness you need
          </Text>
          <Box width={"90px"} mt={3} ml={2}>
            <Select
              placeholder="Select"
              options={NoOfWitnessOptions}
              value={selectedOption}
              onChange={(option) => setSelectedOption(option)}
            />
          </Box>

          <Divider pt={"150px"} />

          <Flex pt={3} justifyContent={"flex-end"}>
            <Button
              background="white"
              border={"1px solid rgba(0, 0, 128, 1)"}
              p="22px"
              fontSize={"16px"}
              color="rgba(0, 0, 128, 1)"
              onClick={onSuccess}
              width="130px"
              mr={4}
            >
              Cancel
            </Button>

            <Button
              onClick={handleInvite}
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "23px",
                fontSize: "18px",
              }}
              width="130px"
              isDisabled={isDisable}
            >
              Invite
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <Text
            fontWeight={600}
            fontSize={{ base: "17px", md: "20px", lg: "20px" }}
            color={"#000080"}
            pb={"15px"}
            pt={2}
          >
            Witness
          </Text>
          <Formik
            initialValues={{
              participants: [
                {
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                },
              ],
            }}
            enableReinitialize
            validationSchema={Yup.object({
              participants: fourFieldsValidations,
            })}
            onSubmit={(values) => {
              handleInviteOwnWitness(values);
            }}
            render={({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FieldArray
                  name="participants"
                  render={(arrayHelpers) => {
                    const participants = values.participants;
                    return (
                      <div>
                        {participants && participants.length > 0
                          ? participants.map((participant, index) => (
                              <FormData
                                key={index}
                                from="witness"
                                index={index}
                                arrayHelpers={arrayHelpers}
                                participant={participant}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                              />
                            ))
                          : null}

                        <Button
                          mt={3}
                          width="fit-content"
                          background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                          _hover={{
                            background:
                              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                          }}
                          isDisabled={
                            arrayHelpers.form.values?.signers?.length >= 5
                          }
                          onClick={() => {
                            arrayHelpers.push({
                              email: "",
                              firstName: "",
                              lastName: "",
                              phoneNumber: "",
                            });
                          }}
                        >
                          + Add Witness
                        </Button>
                      </div>
                    );
                  }}
                />

                {errMsg !== "" && (
                  <Text color={"red"} fontSize={14}>
                    *{errMsg}
                  </Text>
                )}

                <Divider pt={5} />

                <Flex pt={3} justifyContent={"flex-end"}>
                  <Button
                    background="white"
                    border={"1px solid rgba(0, 0, 128, 1)"}
                    p="22px"
                    fontSize={"16px"}
                    color="rgba(0, 0, 128, 1)"
                    onClick={onSuccess}
                    width="130px"
                    mr={4}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    style={{
                      background: "#2D3748",
                      color: "#fff",
                      padding: "23px",
                      fontSize: "18px",
                    }}
                    width="130px"
                    isDisabled={isDisable}
                  >
                    Invite
                  </Button>
                </Flex>
              </Form>
            )}
          />
        </>
      )}
    </>
  );
};

export default InviteWitness;
