import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiRectangle } from "react-icons/bi";
import { AiOutlineFileText } from "react-icons/ai";
import { TfiStamp as StampIcon } from "react-icons/tfi";
import { HiOutlineIdentification } from "react-icons/hi2";
import { TbScribble as ScribbleIcon } from "react-icons/tb";
import { BsCardText, BsCheckSquare, BsTextareaT } from "react-icons/bs";
import { FaFileSignature, FaSignature as SignatureIcon } from "react-icons/fa";
import { RiDeleteBinLine as DeleteIcon, RiFileList3Line } from "react-icons/ri";
import {
  MdDateRange,
  MdKeyboardArrowDown,
  MdOutlinePermIdentity,
  MdOutlineKeyboardArrowUp as UpArrow,
} from "react-icons/md";
import NAIcon from "../../../assets/images/NA.png";
import DateTool from "./DateTool";
import { AuditLogsApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { getStateName } from "../../../utils/utils";
import { addForm } from "../../../utils/pdf-utils/addForm";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { useDocumentStore } from "../../../store/documentsStore";
import { useCurrentDocStore } from "../../../store/sessionDocStore";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import {
  EventAction,
  EventType,
  useAuditLogsStore,
} from "../../../store/auditLogs";

const NotaryTools = ({ from }: { from: string }) => {
  const { configuration } = useAuth();
  const socket = useAuditLogsStore((state) => state.socket);
  const sessionId = useAuditLogsStore((state) => state.sessionId);
  const currentDocId = useDocumentStore((state) => state.currentDocId);
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const currentDocUrl = useCurrentDocStore((state) => state.currentDocUrl);
  const [addedForm, setAddedForms] = useState<String[]>([]);
  const [expandNotary, setExpandNotary] = useState<boolean>(false);
  const [expandWitness, setExpandWitness] = useState<boolean>(false);
  const [expandSigners, setExpandSigners] = useState<boolean>(false);
  const [expandSignersName, setExpandSignersName] = useState<boolean>(false);
  const [expandWitnessName, setExpandWitnessName] = useState<boolean>(false);
  const [expandSignersInitial, setExpandSignersInitial] =
    useState<boolean>(false);

  const notaryData = [
    {
      title: "State",
      data: `${getStateName(sessionInfo?.notary?.state as string)}`,
      icon: <BsTextareaT />,
    },
    {
      title: "County",
      data: `${
        sessionInfo?.notary?.county === undefined
          ? "Enter County"
          : sessionInfo?.notary?.county
      }`,
      icon: <BsTextareaT />,
    },
    {
      title: "Name",
      data: `${sessionInfo?.notary?.firstName} ${sessionInfo?.notary?.middleName} ${sessionInfo?.notary?.lastName} ${sessionInfo?.notary?.suffix}`,
      icon: <BsTextareaT />,
    },
    {
      title: "Commission #",
      data: `${sessionInfo?.notary?.commissionNumber}`,
      icon: <HiOutlineIdentification />,
    },
    {
      title: "Commission Exp. Date",
      data: `${moment(sessionInfo?.notary?.commissionExpirationDate).format(
        "MM-DD-YYYY"
      )}`,
      icon: <MdDateRange />,
    },
  ];

  const toolsData = [
    {
      title: "WhiteOut",
      icon: <BiRectangle />,
      data: {
        action: "placeRectangleObject",
        payload: {
          x: 0,
          y: 0,
          type: EventType.rectangle,
        },
      },
    },
    {
      title: "Checkbox",
      icon: <BsCheckSquare />,
      data: {
        action: "placeCheckboxObject",
        payload: {
          x: 0,
          y: 0,
          type: EventType.checkbox,
        },
      },
    },
    {
      title: "N/A",
      icon: (
        <Image
          src={NAIcon}
          height="18px"
          width={"19px"}
          border={"1px solid"}
          borderRadius="4px"
        />
      ),
      data: {
        action: "placeTextObject",
        payload: {
          x: 0,
          y: 0,
          text: "N/A",
          type: EventType.text,
        },
      },
    },
  ];

  useEffect(() => {
    if (sessionId) {
      new AuditLogsApi(configuration)
        .auditLogsControllerGetAudiLogsForm({
          sessionId: Number(sessionId),
          docId: currentDocId.toString(),
        })
        .then((response) => {
          const addedFormAuditLogs = response.filter(
            (audit) => audit.action === EventAction.addedForm
          );
          setAddedForms(
            // @ts-ignore
            addedFormAuditLogs.map((audit) => audit?.payload?.typeOfForm)
          );
        });
    }
  }, [currentDocUrl, sessionId]);

  const handleFormAddition = async (type: string) => {
    // # TODO : handle this from sessionPdfEditor
    if (type === "Jurat") {
      addForm(currentDocUrl, "Jurat").then((res) => {
        emitEvent({
          action: EventAction.addedForm,
          event: "formAdded",
          pageNumber: res.pageCount,
          payload: { typeOfForm: "Jurat" },
          docId: currentDocId,
          sessionId,
          socket,
        });
      });
    } else if (type === "Acknowlegement") {
      addForm(currentDocUrl, "Acknowlegement").then((res) => {
        emitEvent({
          action: EventAction.addedForm,
          event: "formAdded",
          pageNumber: res.pageCount,
          payload: { typeOfForm: "Acknowlegement" },
          docId: currentDocId,
          sessionId,
          socket,
        });
      });
    } else {
      console.log("no Form Selected");
    }
  };

  const handleFormRemoval = async (type: string) => {
    if (type === "Jurat") {
      new AuditLogsApi(configuration)
        .auditLogsControllerRemovePage({
          removeAuditLogDTO: {
            sessionId: Number(sessionId),
            typeOfForm: "Jurat",
          },
        })
        .then((res) => {
          emitEvent({
            action: EventAction.remove,
            event: EventType.formRemoved,
            pageNumber: res.pageNumber,
            payload: { typeOfForm: "Jurat" },
            docId: currentDocId,
            sessionId,
            socket,
          });
        });
    } else if (type === "Acknowlegement") {
      new AuditLogsApi(configuration)
        .auditLogsControllerRemovePage({
          removeAuditLogDTO: {
            sessionId: Number(sessionId),
            typeOfForm: "Acknowlegement",
          },
        })
        .then((res) => {
          emitEvent({
            action: EventAction.remove,
            event: EventType.formRemoved,
            pageNumber: res.pageNumber,
            payload: { typeOfForm: "Acknowlegement" },
            docId: currentDocId,
            sessionId,
            socket,
          });
        });
    }
  };

  return (
    <>
      <Button
        justifyContent="left"
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
              action: "placeTextObject",
              payload: {
                x: 0,
                y: 0,
                text: "Free Text",
                type: EventType.text,
              },
            })
          );
        }}
        type="button"
      >
        <BsTextareaT />
        <Text className="tools-button-text">Free Text</Text>
      </Button>

      <Button
        justifyContent="left"
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
              action: "placeDateObject",
              payload: {
                x: 0,
                y: 0,
                type: EventType.date,
                text: new Intl.DateTimeFormat("en-US")
                  .format(new Date())
                  .toString(),
              },
            })
          );
        }}
        type="button"
      >
        <MdDateRange />
        <Text className="tools-button-text">Full Date</Text>
      </Button>

      <DateTool />

      <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
        <Button
          justifyContent="space-between"
          width={"100%"}
          style={{
            backgroundColor: "white",
          }}
          onClick={() => {
            setExpandNotary((prev) => !prev);
          }}
        >
          <Flex alignItems={"center"}>
            <RiFileList3Line />
            <Text className="tools-button-text">
              {from === "edit" && localStorage.getItem("role") === "business"
                ? "Other Tools"
                : "Notary Credentials"}
            </Text>
          </Flex>

          <Text className="tools-button-text">
            {expandNotary ? <UpArrow /> : <MdKeyboardArrowDown />}
          </Text>
        </Button>

        {expandNotary && (
          <>
            {from === "edit" &&
            localStorage.getItem("role") === "business" ? null : (
              <>
                {notaryData?.map((item, idx) => (
                  <Button
                    key={idx}
                    width={"100%"}
                    mt={2}
                    justifyContent="left"
                    draggable={true}
                    onDragStart={(e) => {
                      e.dataTransfer.setData(
                        "application/json",
                        JSON.stringify({
                          action: "placeTextObject",
                          payload: {
                            x: 0,
                            y: 0,
                            text: `${item?.data}`,
                            type: EventType.text,
                          },
                        })
                      );
                    }}
                  >
                    {item.icon}
                    <Text className="tools-button-text">{item.title}</Text>
                  </Button>
                ))}
              </>
            )}

            {from !== "edit" && (
              <>
                <Button
                  mt={2}
                  width={"100%"}
                  justifyContent="left"
                  draggable={true}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "placeImageObject",
                        payload: {
                          x: 0,
                          y: 0,
                          type: EventType.notaryInitials,
                        },
                      })
                    );
                  }}
                  type="button"
                >
                  <FaFileSignature />
                  <Text className="tools-button-text">Initials</Text>
                </Button>

                <Button
                  mt={0.5}
                  justifyContent="left"
                  width={"100%"}
                  draggable={true}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "placeImageObject",
                        payload: {
                          x: 0,
                          y: 0,
                          type: EventType.notarySignature,
                        },
                      })
                    );
                  }}
                  type="button"
                >
                  <SignatureIcon />
                  <Text className="tools-button-text">Signature</Text>
                </Button>

                <Button
                  mt={2}
                  width={"100%"}
                  justifyContent="left"
                  draggable={true}
                  // onDragEnd={()=>{
                  //   onDocumentTypeModalOpen();
                  // }}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "placeImageObject",
                        payload: {
                          x: 0,
                          y: 0,
                          type: EventType.notaryStamp,
                        },
                      })
                    );
                  }}
                  type="button"
                >
                  <StampIcon />
                  <Text className="tools-button-text">Stamp</Text>
                </Button>
              </>
            )}

            {toolsData?.flatMap((tool, idx) => (
              <Button
                key={idx}
                width={"100%"}
                mt={2}
                justifyContent="left"
                draggable={true}
                onDragStart={(e) => {
                  e.dataTransfer.setData(
                    "application/json",
                    JSON.stringify(tool.data)
                  );
                }}
                type="button"
              >
                {tool.icon}
                <Text className="tools-button-text">{tool.title}</Text>
              </Button>
            ))}

            <Button
              width={"100%"}
              mt={2}
              justifyContent="left"
              draggable={true}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "application/json",
                  JSON.stringify({
                    action: "placeDisclosureObject",
                    payload: {
                      x: 0,
                      y: 0,
                      text: "Notarized online using two-way audio/video technology and multi-factor authentication.",
                      type: EventType.disclosure,
                    },
                  })
                );
              }}
            >
              <BsCardText />
              <Text className="tools-button-text">Disclosure</Text>
            </Button>
          </>
        )}
      </Box>

      {!sessionInfo?.isNotaryExemplarSheet && (
        <>
          <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
            <Button
              justifyContent="space-between"
              width={"100%"}
              style={{
                backgroundColor: "white",
              }}
              onClick={() => {
                setExpandSignersName((prev) => !prev);
              }}
            >
              <Flex alignItems={"center"}>
                <BsTextareaT />
                <Text className="tools-button-text">Signer Name</Text>
              </Flex>

              <Text className="tools-button-text">
                {expandSignersName ? <UpArrow /> : <MdKeyboardArrowDown />}
              </Text>
            </Button>

            {expandSignersName &&
              sessionInfo?.signers?.map((signer, idx) => (
                <Button
                  key={idx}
                  width={"100%"}
                  mt={idx === 0 ? 0.5 : 2}
                  justifyContent="left"
                  draggable={true}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "placeTextObject",
                        payload: {
                          x: 0,
                          y: 0,
                          text: `${signer.firstName} ${signer.lastName}`,
                          type: EventType.text,
                        },
                      })
                    );
                  }}
                >
                  <MdOutlinePermIdentity />
                  <Text className="tools-button-text">{`${signer.firstName} ${signer.lastName}`}</Text>
                </Button>
              ))}
          </Box>

          <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
            <Button
              justifyContent="space-between"
              width={"100%"}
              style={{
                backgroundColor: "white",
              }}
              onClick={() => {
                setExpandSigners((prev) => !prev);
              }}
            >
              <Flex alignItems={"center"}>
                <SignatureIcon />
                <Text className="tools-button-text">Signer Signature</Text>
              </Flex>

              <Text className="tools-button-text">
                {expandSigners ? <UpArrow /> : <MdKeyboardArrowDown />}
              </Text>
            </Button>

            {expandSigners &&
              sessionInfo?.signers?.map((signer, idx) => (
                <Button
                  key={idx}
                  width={"100%"}
                  mt={idx === 0 ? 0.5 : 2}
                  justifyContent="left"
                  draggable={true}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "getSignatureObject",
                        payload: {
                          x: 0,
                          y: 0,
                          type: EventType.signerSignature,
                          toBeSignedBy: `${signer.firstName} ${signer.lastName}`,
                          toBeSignedByUserId: signer?.user?.id,
                        },
                      })
                    );
                  }}
                  textTransform="capitalize"
                >
                  <MdOutlinePermIdentity />
                  <Text className="tools-button-text">{`${signer.firstName} ${signer.lastName}`}</Text>
                </Button>
              ))}
          </Box>

          <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
            <Button
              justifyContent="space-between"
              width={"100%"}
              style={{
                backgroundColor: "white",
              }}
              onClick={() => {
                setExpandSignersInitial((prev) => !prev);
              }}
            >
              <Flex alignItems={"center"}>
                <FaFileSignature />
                <Text className="tools-button-text">Signer Initials</Text>
              </Flex>

              <Text className="tools-button-text">
                {expandSignersInitial ? <UpArrow /> : <MdKeyboardArrowDown />}
              </Text>
            </Button>

            {expandSignersInitial &&
              sessionInfo?.signers?.map((signer, idx) => (
                <Button
                  key={idx}
                  width={"100%"}
                  mt={idx === 0 ? 0.5 : 2}
                  justifyContent="left"
                  draggable={true}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      JSON.stringify({
                        action: "getSignatureObject",
                        payload: {
                          x: 0,
                          y: 0,
                          type: EventType.signerInitials,
                          toBeSignedBy: `${signer.firstName} ${signer.lastName}`,
                          toBeSignedByUserId: signer?.user?.id,
                        },
                      })
                    );
                  }}
                  textTransform="capitalize"
                >
                  <MdOutlinePermIdentity />
                  <Text className="tools-button-text">{`${signer.firstName} ${signer.lastName}`}</Text>
                </Button>
              ))}
          </Box>

          {sessionInfo?.witness?.length !== 0 && (
            <>
              <Box
                border={"1px solid"}
                borderRadius="6px"
                backgroundColor="white"
              >
                <Button
                  justifyContent="space-between"
                  width={"100%"}
                  style={{
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setExpandWitnessName((prev) => !prev);
                  }}
                >
                  <Flex alignItems={"center"}>
                    <BsTextareaT />
                    <Text className="tools-button-text">Witness Name</Text>
                  </Flex>

                  <Text className="tools-button-text">
                    {expandWitnessName ? <UpArrow /> : <MdKeyboardArrowDown />}
                  </Text>
                </Button>

                {expandWitnessName &&
                  sessionInfo?.witness?.map((witness, idx) => (
                    <Button
                      key={idx}
                      width={"100%"}
                      mt={idx === 0 ? 0.5 : 2}
                      justifyContent="left"
                      draggable={true}
                      onDragStart={(e) => {
                        e.dataTransfer.setData(
                          "application/json",
                          JSON.stringify({
                            action: "placeTextObject",
                            payload: {
                              x: 0,
                              y: 0,
                              text: `${witness.firstName} ${witness.lastName}`,
                              type: EventType.text,
                            },
                          })
                        );
                      }}
                    >
                      <MdOutlinePermIdentity />
                      <Text className="tools-button-text">{`${witness.firstName} ${witness.lastName}`}</Text>
                    </Button>
                  ))}
              </Box>

              <Box
                border={"1px solid"}
                borderRadius="6px"
                backgroundColor="white"
              >
                <Button
                  justifyContent="space-between"
                  width={"100%"}
                  style={{
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    setExpandWitness((prev) => !prev);
                  }}
                >
                  <Flex alignItems={"center"}>
                    <SignatureIcon />
                    <Text className="tools-button-text">Witness Signature</Text>
                  </Flex>

                  <Text className="tools-button-text">
                    {expandWitness ? <UpArrow /> : <MdKeyboardArrowDown />}
                  </Text>
                </Button>
                {expandWitness &&
                  sessionInfo?.witness?.map((witness, idx) => (
                    <Button
                      key={idx}
                      width={"100%"}
                      mt={idx === 0 ? 0.5 : 2}
                      justifyContent="left"
                      textTransform="capitalize"
                      draggable={true}
                      onDragStart={(e) => {
                        e.dataTransfer.setData(
                          "application/json",
                          JSON.stringify({
                            action: "getSignatureObject",
                            payload: {
                              x: 0,
                              y: 0,
                              type: EventType.witnessSignature,
                              toBeSignedBy: witness.firstName,
                            },
                          })
                        );
                      }}
                    >
                      <MdOutlinePermIdentity />
                      <Text className="tools-button-text">{`${witness.firstName} ${witness.lastName}`}</Text>
                    </Button>
                  ))}
              </Box>
            </>
          )}

          {from !== "edit" && (
            <>
              {addedForm.includes("Jurat") ? (
                <Button
                  justifyContent="left"
                  onClick={() => {
                    handleFormRemoval("Jurat").then(() => {
                      setAddedForms((prev) => [
                        ...prev.filter((el) => el !== "Jurat"),
                      ]);
                    });
                  }}
                >
                  <DeleteIcon />{" "}
                  <Text className="tools-button-text">Remove Jurat</Text>
                </Button>
              ) : (
                <Button
                  justifyContent="left"
                  onClick={() => {
                    handleFormAddition("Jurat").then(() => {
                      setAddedForms((prev) => [...prev, "Jurat"]);
                    });
                  }}
                >
                  <AiOutlineFileText />
                  <Text className="tools-button-text">Add Jurat</Text>
                </Button>
              )}

              {addedForm.includes("Acknowlegement") ? (
                <Button
                  justifyContent="left"
                  onClick={() => {
                    handleFormRemoval("Acknowlegement").then(() => {
                      setAddedForms((prev) => [
                        ...prev.filter((el) => el !== "Acknowlegement"),
                      ]);
                    });
                  }}
                >
                  <DeleteIcon />
                  <Text className="tools-button-text">
                    Remove Acknowlegement
                  </Text>
                </Button>
              ) : (
                <Button
                  justifyContent="left"
                  onClick={() => {
                    handleFormAddition("Acknowlegement").then(() => {
                      setAddedForms((prev) => [...prev, "Acknowlegement"]);
                    });
                  }}
                >
                  <AiOutlineFileText />
                  <Text className="tools-button-text">Add Acknowlegement</Text>
                </Button>
              )}
            </>
          )}
        </>
      )}

      <Button
        justifyContent="left"
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
              action: "placeScribble",
              payload: {
                x: 0,
                y: 0,
                type: EventType.scribble,
              },
            })
          );
        }}
        type="button"
      >
        <ScribbleIcon /> <Text className="tools-button-text">Scribble</Text>
      </Button>
    </>
  );
};

export default NotaryTools;
