import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import Select from "react-select";
import { useEffect, useState } from "react";
import { GrDocumentText as ActionIcon } from "react-icons/gr";
import FlatList from "flatlist-react";
import ReactPaginate from "react-paginate";
import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import useNotification from "../../utils/useNotification";
import { getStatusBgColor } from "../../utils/utils";
import DetailsModal from "../commonComponents/NotaryModals/DetailsModal";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../commonComponents/LoadingComponents/NoDataFound";
import { EventAction } from "../../store/auditLogs";
import { LimitOptions } from "../../utils/options";
import ListHeader from "../commonComponents/ListComponents/ListHeader";
import { IFilter, IReactSelect } from "../../store/zustandType";
import {
  ActiveSessionApi,
  AuditLogs,
  AuditLogsApi,
  Session,
  SessionApi,
  SignedDocumentApi,
  SignedPdfDTO,
  Signer,
} from "../../api";
import { notaryPaths } from "../../routes/routes";

const NotaryJournal = ({ from }: { from?: string }) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Session[]>([]);
  const [recordingKey, setRecordingKey] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showSession, setshowSession] = useState<Session>();
  const [notarialActDate, setNotarialActDate] = useState<Date>();
  const [stampDetails, setStampDetails] = useState<AuditLogs[]>([]);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [auditData, setAuditData] = useState<AuditLogs[]>([]);
  const [signedDocPath, setSignedDocPath] = useState<SignedPdfDTO[]>([]);
  const [meetingInfo, setMeetingReview] = useState<{
    notaryNote: string;
    documentType: string;
  }>({
    notaryNote: "",
    documentType: "",
  });

  const [filters, setFilters] = useState<IFilter>({
    searchTerm: "",
    date: "",
  });

  const [limit, setLimit] = useState<IReactSelect>({
    label: 10,
    value: 10,
  });

  const [status, setStatus] = useState<IReactSelect>({
    label: "All",
    value: "all",
  });

  const getSignedDocPath = (sessionId: number) => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerGetPdf({
        sessionId: sessionId,
      })
      .then((res) => {
        setSignedDocPath(res);
      })
      .catch((err) => {
        setSignedDocPath([]);
      });
  };

  useEffect(() => {
    setLoading(true);
    new SessionApi(configuration)
      .sessionControllerGetByNotaryId({
        page: 1,
        limit: from === "dashboard" ? 5 : Number(limit?.value),
        search: filters?.searchTerm,
        status: status?.value === "all" ? "" : (status?.value as string),
      })
      .then((res) => {
        setData(res?.items);
        setLoading(false);
        // @ts-ignore
        setTotalPages(res.meta?.totalPages);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        notification("fail", message.errorMessage);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, filters?.searchTerm, status?.value]);

  const handlePageChange = (event: { selected: number }) => {
    new SessionApi(configuration)
      .sessionControllerGetByNotaryId({
        page: event.selected + 1,
        limit: Number(limit?.value),
        search: filters?.searchTerm,
        status: status?.value === "all" ? "" : (status?.value as string),
      })
      .then((res) => {
        setData(res?.items);
      });
  };

  const getAuditLogs = (sessionId: number) => {
    new AuditLogsApi(configuration)
      .auditLogsControllerGetAudiLogsBySessionId({
        sessionId: sessionId,
      })
      .then((audits) => {
        setAuditData(audits);
        const stampEvent = audits?.filter(
          (el) =>
            el.event === "notaryStamp" &&
            (el.action === EventAction.add || el.action === EventAction.delete)
        );
        if (stampEvent.length !== 0) {
          setStampDetails(stampEvent);
        } else {
          setStampDetails([]);
        }
      })
      .catch((err) => {
        setStampDetails([]);
      });
  };

  const getRecording = (sessionId: number) => {
    new ActiveSessionApi(configuration)
      .activeSessionControllerGetMeeting({
        sessionId: sessionId,
      })
      .then((res) => {
        setMeetingReview({
          notaryNote: res?.notaryNote,
          documentType: res?.typeOfDocumnet,
        });
      })
      .catch((err) => {
        console.log(err);
        setMeetingReview({
          notaryNote: "",
          documentType: "",
        });
      });
    new ActiveSessionApi(configuration)
      .activeSessionControllerGetRecordingbySessionId({
        sessionId: sessionId,
      })
      .then((res) => {
        setNotarialActDate(res.createdDate);
        setRecordingKey(res.recordingKey);
        onOpen();
      })
      .catch((err) => {
        console.log(err);
        setRecordingKey("");
        onOpen();
      });
  };

  const getSigners = (sessionId: number) => {
    new SessionApi(configuration)
      .sessionControllerGetJournalSessions({
        sessionId: sessionId,
      })
      .then((res) => {
        setSigners(res?.signers);
      });
  };

  return (
    <Stack height={"100%"}>
      {from !== "dashboard" && (
        <>
          <Text
            textAlign={"center"}
            fontSize={"24px"}
            fontFamily="Oswald"
            fontWeight={"700"}
            mb={6}
          >
            Notary Journal
          </Text>

          <ListHeader
            status={status}
            filters={filters}
            onSetFilters={(value) => setFilters(value)}
            onSetStatus={(value) => setStatus(value)}
          />
        </>
      )}

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : data?.length === 0 ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer
            maxHeight={{ base: "70vh", md: "unset", lg: "unset" }}
            style={{ overflow: "auto" }}
          >
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th style={{ color: "#000" }}>SESSION ID</Th>
                  <Th style={{ color: "#000" }}>STATUS</Th>
                  <Th style={{ color: "#000" }}>PRIMARY SIGNER</Th>
                  <Th style={{ color: "#000" }}>DATE</Th>
                  <Th style={{ color: "#000" }}>ACTIONS</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.flatMap((item, idx) => (
                  <Tr key={idx} style={{ fontWeight: 500 }}>
                    <Td style={{ color: "#9A9595" }}>{item?.sessionId}</Td>
                    <Td>
                      <Text
                        py={"4px"}
                        fontSize="12px"
                        textAlign="center"
                        textTransform="capitalize"
                        borderRadius="20px"
                        width="80px"
                        color="#fff"
                        style={{
                          background: getStatusBgColor(item?.status),
                        }}
                      >
                        {item?.status ?? "Pending"}
                      </Text>
                    </Td>
                    <Td
                      style={{ color: "#9A9595", textTransform: "capitalize" }}
                    >
                      {item.isNotaryExemplarSheet
                        ? "Exemplar"
                        : `${item?.signers[0]?.firstName} ${item?.signers[0]?.lastName}`}
                    </Td>
                    <Td style={{ color: "#9A9595" }}>
                      {moment(
                        item?.scheduleDate !== ""
                          ? item?.scheduleDate
                          : item?.createdDate
                      ).format("MM-DD-YYYY")}
                    </Td>
                    <Td>
                      <Flex>
                        <Box
                          ml={2.5}
                          role="button"
                          onClick={() => {
                            setshowSession(item);
                            getSigners(item?.sessionId);
                            if (item.status === "completed") {
                              getRecording(item?.sessionId);
                              getSignedDocPath(item?.sessionId);
                              getAuditLogs(item?.sessionId);
                            } else {
                              onOpen();
                            }
                          }}
                        >
                          <ActionIcon size={20} color="#5856d6" />
                        </Box>
                        {item?.status === "booked" && (
                          <Button
                            ml={3}
                            width={"fit-content"}
                            style={{
                              background:
                                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                              color: "#000",
                              padding: "0px 10px",
                              height: "25px",
                            }}
                            onClick={() =>
                              navigate(
                                `${notaryPaths.notaryOndemand}/notary-session?session-id=${item?.sessionId}`
                              )
                            }
                          >
                            Join
                          </Button>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {from !== "dashboard" && (
            <Flex justifyContent={"space-between"}>
              <Box></Box>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageChange}
                pageRangeDisplayed={1}
                pageCount={totalPages}
                marginPagesDisplayed={1}
                previousLabel="< Prev"
                containerClassName={"pagination"}
                activeClassName={"active"}
              />

              <Box width={"90px"} mt={3}>
                <Select
                  placeholder="Select"
                  menuPlacement="top"
                  options={LimitOptions}
                  value={limit}
                  onChange={(option) => setLimit(option)}
                />
              </Box>
            </Flex>
          )}
        </>
      )}

      <DetailsModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        session={showSession as Session}
        recordingKey={recordingKey}
        meetingInfo={meetingInfo}
        actDate={notarialActDate}
        signedDocPath={signedDocPath}
        signers={signers}
        stampDetails={stampDetails}
        auditData={auditData}
      />
    </Stack>
  );
};

export default NotaryJournal;
