/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KbaType } from './KbaType';
import {
    KbaTypeFromJSON,
    KbaTypeFromJSONTyped,
    KbaTypeToJSON,
} from './KbaType';

/**
 * 
 * @export
 * @interface SessionDTO
 */
export interface SessionDTO {
    /**
     * 
     * @type {number}
     * @memberof SessionDTO
     */
    witnessesCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    scheduleDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionDTO
     */
    notary?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionDTO
     */
    documents?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionDTO
     */
    signers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionDTO
     */
    witness?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    notaryNote?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    paymentBy?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionDTO
     */
    documentFees?: number;
    /**
     * 
     * @type {Array<KbaType>}
     * @memberof SessionDTO
     */
    kbaLogs?: Array<KbaType>;
    /**
     * 
     * @type {number}
     * @memberof SessionDTO
     */
    primarySignerUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    primarySignerName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionDTO
     */
    mediators?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    specificNotaryState?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDTO
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDTO
     */
    isDemoSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDTO
     */
    isTitleLenderSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDTO
     */
    isNotaryExemplarSheet?: boolean;
}

/**
 * Check if a given object implements the SessionDTO interface.
 */
export function instanceOfSessionDTO(value: object): value is SessionDTO {
    if (!('signers' in value) || value['signers'] === undefined) return false;
    return true;
}

export function SessionDTOFromJSON(json: any): SessionDTO {
    return SessionDTOFromJSONTyped(json, false);
}

export function SessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'witnessesCount': json['witnessesCount'] == null ? undefined : json['witnessesCount'],
        'scheduleDate': json['scheduleDate'] == null ? undefined : json['scheduleDate'],
        'notary': json['notary'] == null ? undefined : json['notary'],
        'documents': json['documents'] == null ? undefined : json['documents'],
        'signers': json['signers'],
        'witness': json['witness'] == null ? undefined : json['witness'],
        'notaryNote': json['notaryNote'] == null ? undefined : json['notaryNote'],
        'paymentBy': json['paymentBy'] == null ? undefined : json['paymentBy'],
        'documentFees': json['documentFees'] == null ? undefined : json['documentFees'],
        'kbaLogs': json['kbaLogs'] == null ? undefined : ((json['kbaLogs'] as Array<any>).map(KbaTypeFromJSON)),
        'primarySignerUserId': json['primarySignerUserId'] == null ? undefined : json['primarySignerUserId'],
        'primarySignerName': json['primarySignerName'] == null ? undefined : json['primarySignerName'],
        'mediators': json['mediators'] == null ? undefined : json['mediators'],
        'specificNotaryState': json['specificNotaryState'] == null ? undefined : json['specificNotaryState'],
        'invoiceNumber': json['invoiceNumber'] == null ? undefined : json['invoiceNumber'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'isDemoSession': json['isDemoSession'] == null ? undefined : json['isDemoSession'],
        'isTitleLenderSession': json['isTitleLenderSession'] == null ? undefined : json['isTitleLenderSession'],
        'isNotaryExemplarSheet': json['isNotaryExemplarSheet'] == null ? undefined : json['isNotaryExemplarSheet'],
    };
}

export function SessionDTOToJSON(value?: SessionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'witnessesCount': value['witnessesCount'],
        'scheduleDate': value['scheduleDate'],
        'notary': value['notary'],
        'documents': value['documents'],
        'signers': value['signers'],
        'witness': value['witness'],
        'notaryNote': value['notaryNote'],
        'paymentBy': value['paymentBy'],
        'documentFees': value['documentFees'],
        'kbaLogs': value['kbaLogs'] == null ? undefined : ((value['kbaLogs'] as Array<any>).map(KbaTypeToJSON)),
        'primarySignerUserId': value['primarySignerUserId'],
        'primarySignerName': value['primarySignerName'],
        'mediators': value['mediators'],
        'specificNotaryState': value['specificNotaryState'],
        'invoiceNumber': value['invoiceNumber'],
        'isInhouseNotary': value['isInhouseNotary'],
        'isDemoSession': value['isDemoSession'],
        'isTitleLenderSession': value['isTitleLenderSession'],
        'isNotaryExemplarSheet': value['isNotaryExemplarSheet'],
    };
}

