import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import {
  IProfileStepStore,
  ISignerProfileDetails,
  ISignerProfileStore,
} from "./zustandType";

const primarySignerEmail = localStorage.getItem("email");

const initialSignerProfileData = {
  notifyViaText: false,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  dob: "",
  lastFourDigitsSsnNumber: "",
  country: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  email: primarySignerEmail ? primarySignerEmail : "",
  signerId: -1,
  kbaStatus: "pending",
  firstScanImage: "",
  firstBackScanImage: "",
  wantSmsNotification:false
}

export const useSignerProfileStore = create<ISignerProfileStore>()(
  devtools(
    OpenReplay.createLogger("signerProfile")(
      // @ts-ignore
      (set) => ({
        signerProfileDetails: initialSignerProfileData,
        setSignerProfileDetails: (newDetails: object) =>
          set((state: { signerProfileDetails: ISignerProfileDetails }) => ({
            signerProfileDetails: {
              ...state.signerProfileDetails,
              ...newDetails,
            },
          })),
      }),
      {
        name: "signerProfile",
      }
    )
  )
);

export const useSignerSessionStepStore = create<IProfileStepStore>((set) => ({
  step: 0,
  setStep: (newDetails: number) =>
    set((state: { step: number }) => ({
      step: newDetails,
    })),
}));
