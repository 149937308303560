import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import BaseLayout from "../../layout/BaseLayout";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useNotification from "../../utils/useNotification";
import { useAuth } from "../../utils/use-auth";
import { useState } from "react";
import { AuthApi } from "../../api";
import { regexPassword } from "../../utils/utils";
import { handleError } from "../../utils/utils-functions";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const [error, setError] = useState({
    newPassErr: "",
    conirmPassErr: "",
  });

  const errorMsg =
    "Must contain atleast 1 uppercase letter, 1 lowercase letter, and 1 number & 8 characters long";

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .trim()
        // .matches(regexPassword, errorMsg)
        .required("Old Password is required"),
      newPassword: Yup.string()
        .trim()
        .matches(regexPassword, errorMsg)
        .required("New Password is required"),
    }),

    onSubmit: (values) => {
      new AuthApi(configuration)
        .authControllerChangePassword({
          changePassswordDTO: {
            oldPassword: values.oldPassword,
            password: values.newPassword,
          },
        })
        .then((res) => {
          notification("success", "Password Changed Successfully!");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch(async (err) => {
          const message = await handleError(err);
          notification(
            "fail",
            message.errorMessage ?? "Failed to Update Profile"
          );
        });
    },
    enableReinitialize: true,
  });

  return (
    <BaseLayout>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={16}>
        Change Password
      </Text>

      <Box
        style={{ margin: "auto", maxWidth: "400px", width: "90%" }}
        fontFamily="Lato"
        fontWeight={600}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormControl id="oldPassword" w={"100%"} p={2}>
            <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
              Old Password
            </FormLabel>
            <Input
              {...(formik.touched.oldPassword && formik.errors.oldPassword
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="oldPassword"
              value={formik.values.oldPassword}
              type="password"
              placeholder="Old Password"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.oldPassword && formik.errors.oldPassword
                ? formik.errors.oldPassword
                : ""}
            </Text>
          </FormControl>

          <FormControl id="newPassword" w={"100%"} p={2}>
            <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
              New Password
            </FormLabel>
            <Input
              {...(formik.touched.newPassword && formik.errors.newPassword
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="newPassword"
              value={formik.values.newPassword}
              type="password"
              placeholder="New Password"
              onChange={(e) => {
                if (formik.values.oldPassword === e.target.value) {
                  setError({
                    ...error,
                    newPassErr: "Password is same as your old password",
                  });
                } else {
                  setError({ ...error, newPassErr: "" });
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.newPassword && formik.errors.newPassword
                ? formik.errors.newPassword
                : error.newPassErr !== ""
                ? error.newPassErr
                : ""}
            </Text>
          </FormControl>

          <FormControl id="confirmPassword" w={"100%"} p={2}>
            <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
              Confirm Password
            </FormLabel>
            <Input
              errorBorderColor="red.300"
              name="newPassword"
              //   value={formik.values.newPassword}
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => {
                if (formik.values.newPassword !== e.target.value) {
                  setError({
                    ...error,
                    conirmPassErr:
                      "Password and confirm password doesnot match",
                  });
                } else {
                  setError({ ...error, conirmPassErr: "" });
                }
              }}
              onBlur={formik.handleBlur}
            />
            {error.conirmPassErr !== "" && (
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {error.conirmPassErr}
              </Text>
            )}
          </FormControl>

          <Flex justify={"end"} mt={8}>
            <Button
              type="button"
              width="90px"
              style={{
                background: "#2D3748",
                color: "#fff",
              }}
              onClick={() => navigate("/")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              width="90px"
              ml={4}
              style={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
              }}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Box>
    </BaseLayout>
  );
};

export default ChangePassword;
