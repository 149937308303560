/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManuallInvoice
 */
export interface ManuallInvoice {
    /**
     * 
     * @type {string}
     * @memberof ManuallInvoice
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof ManuallInvoice
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ManuallInvoice
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ManuallInvoice
     */
    sessionId: number;
}

/**
 * Check if a given object implements the ManuallInvoice interface.
 */
export function instanceOfManuallInvoice(value: object): value is ManuallInvoice {
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    return true;
}

export function ManuallInvoiceFromJSON(json: any): ManuallInvoice {
    return ManuallInvoiceFromJSONTyped(json, false);
}

export function ManuallInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManuallInvoice {
    if (json == null) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'description': json['description'],
        'amount': json['amount'],
        'sessionId': json['sessionId'],
    };
}

export function ManuallInvoiceToJSON(value?: ManuallInvoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerId': value['customerId'],
        'description': value['description'],
        'amount': value['amount'],
        'sessionId': value['sessionId'],
    };
}

