import {
  ParticipantTile,
  TrackLoop,
  TrackReference,
  VideoTrack,
} from "@livekit/components-react";
import { useEffect, useMemo, useState } from "react";
import { Notary, NotaryApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../../utils/utils";
import { Box, Text } from "@chakra-ui/react";
import { COLORS } from "../../../../utils/constants";

function NotaryParticipant({ tracks }: { tracks: TrackReference[] }) {
  const { configuration } = useAuth();
  const [notaryData, setNotaryData] = useState<Notary | null>(null);

  const userId = useMemo(() => {
    if (tracks.length === 0) return null;
    const [_, id] = tracks[0].participant.identity.split(
      LIVEKIT_SPLIT_IDENTIFIER
    );
    return id;
  }, [tracks]);

  useEffect(() => {
    if (!userId) return;
    new NotaryApi(configuration)
      .notaryControllerFindOne({ userId })
      .then((res) => {
        setNotaryData(res);
      });
  }, [userId]);

  return (
    <Box
      sx={{
        margin: "20px 0px",
        backgroundColor: COLORS.gold+"99",
        padding: "10px",
        width: "fit-content",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Text fontWeight={"semibold"} textTransform={"capitalize"}>
          Notary
        </Text>
      </Box>
      <TrackLoop tracks={tracks}>
        <ParticipantTile style={{ height: "100%", width: "100%" }}>
          <VideoTrack
            style={{
              maxHeight: "300px",
              maxWidth: "300px",
              height: "100%",
              width: "100%",
              aspectRatio: "16 / 12",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          {/* <ParticipantName /> */}
          {notaryData !== null ? (
            <p>{notaryData?.firstName + " " + notaryData?.lastName}</p>
          ) : (
            "Notary"
          )}
        </ParticipantTile>
      </TrackLoop>
    </Box>
  );
}

export default NotaryParticipant;
