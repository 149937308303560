import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdFileDownload as DownloadIcon } from "react-icons/md";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import NoDataFound from "./LoadingComponents/NoDataFound";

const PreviewModal = ({
  isOpen,
  onClose,
  onOpen,
  url,
  type,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  url?: string;
  type?: string[];
}) => {
  const handleDownload = async () => {
    window.open(url, "_blank");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"5xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader pt={6} borderBottom="2px solid #aaa">
          <Flex alignItems="center" justifyContent="space-between">
            <Text
              fontSize={{ base: "18px", md: "22px", lg: "22px" }}
              fontWeight={"700"}
              fontFamily="Lato"
            >
              Previewer
            </Text>

            {url !== "" && url !== undefined && (
              <Flex alignItems="center">
                <Flex
                  role="button"
                  alignItems={"center"}
                  onClick={() => handleDownload()}
                  color="#000080"
                >
                  <DownloadIcon />
                  <Text ml={2}>Download</Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </ModalHeader>

        <ModalCloseButton />

        <div style={{ height: "72vh", overflowY: "auto" }}>
          <ModalBody mb={4}>
            {url === "" || url === undefined ? (
              <Box mt={5}>
                <NoDataFound text="No document to show" />
              </Box>
            ) : (
              <div style={{ overflow: "hidden" }}>
                {type?.includes(".pdf") ? (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                    <Viewer fileUrl={url} />
                  </Worker>
                ) : (
                  <Image src={url} height={"100%"} />
                )}
              </div>
            )}
          </ModalBody>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default PreviewModal;
