import { Stack, Text, Button, Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSessionCreate } from "../../../store/store";
import SessionDocUpload from "../../commonComponents/UploadComponents/SessionDocUpload";
import { businessPaths } from "../../../routes/routes";

const BusinessDocUpload = () => {
  const navigate = useNavigate();
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  return (
    <Stack spacing={8}>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Document Upload
      </Heading>
      <Text fontSize={16} mt={"0"}>
        Upload your file here. Pdf file preferred. Size limit is 30 MB.
      </Text>

      <SessionDocUpload
        file={sessionDetail?.documents}
        onUpload={(file) => {
          setSessionDetails({
            ...sessionDetail,
            documents: file,
          });
        }}
      />

      <Flex justifyContent={"flex-end"}>
        <Button
          marginTop={5}
          width={"fit-content"}
          alignSelf={"end"}
          style={{ padding: "10px 30px" }}
          onClick={() => {
            navigate(`${businessPaths.createSession}/type`);
          }}
        >
          Prev
        </Button>
        <Button
          marginTop={5}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
            marginTop: "30px",
          }}
          width={"fit-content"}
          alignSelf={"end"}
          isDisabled={sessionDetail.documents === undefined}
          onClick={() => {
            navigate(`${businessPaths.createSession}/participants`);
          }}
        >
          Next
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessDocUpload;
