import { PropsWithChildren } from "react";
import styles from "./Loader.module.css";

interface ILoaderProps extends PropsWithChildren {
  isLoading: boolean;
}
const Loader = ({ isLoading, children }: ILoaderProps) => {
  return (
    <>{isLoading ? <div className={styles.loadingIcon}></div> : children}</>
  );
};
export default Loader;
