import { useEffect } from "react";
import DigitalCertificateDetails from "../../../component/Details/DigitalCertificateDetails";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const DigitalCertificate = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(4);
  }, [setStep]);

  return <DigitalCertificateDetails />;
};

export default DigitalCertificate;
