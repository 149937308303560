import { create } from "zustand";

interface ICamera {
  permissionGiven: boolean;
  cameraDevices: MediaDeviceInfo[];
  selectedCamera: MediaDeviceInfo | null;
  getCameraPermissions: () => Promise<boolean>;
  getCameras: () => Promise<MediaDeviceInfo[]>;
  selectCamera: (device: MediaDeviceInfo["deviceId"]) => void;
}

export const useCamera = create<ICamera>((set, get) => ({
  permissionGiven: false,
  cameraDevices: [],
  selectedCamera: null,
  selectCamera: (device: MediaDeviceInfo["deviceId"]) => {
    const selectedCamera = get().cameraDevices.find(
      (camera) => camera.deviceId === device
    );
    set({ selectedCamera });
  },
  getCameras: async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameraDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    set({
      cameraDevices: cameraDevices,
    });
    return cameraDevices;
  },
  getCameraPermissions: async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameraDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      set({
        cameraDevices: cameraDevices,
        permissionGiven: true,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
}));