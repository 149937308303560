import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SubscriptionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import ActiveProfileModal from "../../Notary/Sessions/Modals/ActiveProfileModal";

const BusinessSession = () => {
  const { configuration } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        onOpen();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ActiveProfileModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        showDataFor={"activePlan"}
        path="/business/plans"
      />
      <Outlet />
    </>
  );
};

export default BusinessSession;
