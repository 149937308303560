import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
  ModalFooter,
  Button,
  Box,
  Divider,
} from "@chakra-ui/react";
import { io } from "socket.io-client";
import { BiEditAlt as EditIcon } from "react-icons/bi";
import { useEffect, useMemo, useReducer } from "react";
import { useAuth } from "../../utils/use-auth";
import { WS_URL } from "../../utils/constants";
import SessionPdfEditor from "../SessionPdfEditor/SessionPdfEditor";
import { useActiveSessionStore } from "../../store/activeSessionStore";
import { getFileUrl, getSessionDetails } from "../../utils/utils-apis";

const EditModal = ({
  sessionId,
  isOpen,
  onClose,
  onOpen,
}: {
  sessionId?: number;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const { configuration } = useAuth();
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const setSessionInfo = useActiveSessionStore((state) => state.setSessionInfo);
  const clearSessionInfo = useActiveSessionStore(
    (state) => state.clearSessionInfo
  );

  const handleClose = () => {
    onClose();
    clearSessionInfo();
  };

  const socket = useMemo(() => {
    return io(WS_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
    });
  }, []);

  const [notaryUrlData, dispatch] = useReducer(
    (state: any, { type, payload }: any) => {
      if (type === "initialize") {
        return {
          ...payload,
        };
      }
    },
    {
      notarySignatureUrl: "",
      notaryInitialsUrl: "",
      notaryStampUrl: "",
    }
  );

  useEffect(() => {
    if (sessionInfo?.notary) {
      Promise.all([
        getFileUrl(configuration, sessionInfo?.notary.signature!),
        getFileUrl(configuration, sessionInfo?.notary.initials!),
        getFileUrl(configuration, sessionInfo?.notary.seal!),
      ]).then((r) => {
        dispatch({
          type: "initialize",
          payload: {
            notarySignatureUrl: r[0],
            notaryInitialsUrl: r[1],
            notaryStampUrl: r[2],
          },
        });
      });
    }
  }, [sessionInfo?.notary]);

  useEffect(() => {
    if (sessionId) {
      getSessionDetails(configuration, Number(sessionId)).then((res) => {
        setSessionInfo(res);
      });
    }
  }, [sessionId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={"6xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />

      <ModalContent px={4}>
        <ModalHeader>
          <Flex
            alignItems={"center"}
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            <EditIcon /> <Text ml={2}>Prepare Document</Text>
          </Flex>
        </ModalHeader>

        <ModalCloseButton />

        <Divider />

        <ModalBody mb={4}>
          <Box height={"90vh"} overflow={"auto"}>
            {sessionId ? (
              <SessionPdfEditor
                from={"edit"}
                socket={socket}
                sessionId={sessionId?.toString()}
                notaryUrlData={notaryUrlData}
              />
            ) : null}
          </Box>
        </ModalBody>

        <Divider />

        <ModalFooter>
          <Button
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            width="fit-content"
            onClick={handleClose}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
