import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import { ProSidebarProvider } from "react-pro-sidebar";
import { OpenReplay } from "./utils/OpenReplay";
import { GlobalWorkerOptions } from "pdfjs-dist";

GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.3.122/build/pdf.worker.min.js";
// "//mozilla.github.io/pdf.js/build/pdf.worker.js"

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  let [resource, config] = args;

  const response = await originalFetch(resource, config);
  if (response.status === 401) {
    localStorage.clear();
    window.location.reload();
  }
  return response;
};

if (process.env.REACT_APP_ENV !== "dev") {
  OpenReplay.init();
}

root.render(
  <ChakraProvider>
    <ProSidebarProvider>
      {/*<React.StrictMode>*/}
      <ColorModeScript />
      <App />
      {/*</React.StrictMode>*/}
    </ProSidebarProvider>
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
