/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';
import type { Business } from './Business';
import {
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
} from './Business';

/**
 * 
 * @export
 * @interface Notary
 */
export interface Notary {
    /**
     * 
     * @type {number}
     * @memberof Notary
     */
    notaryId: number;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    middleName: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    commissionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    commissionExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    copyOfNotaryCommission?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    nnaIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    notarySigningAgentExamExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    backgroundCheckExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    eoInsuranceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    insuranceAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    policyExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    copyOfEoPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    digitalCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    initials?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    seal?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof Notary
     */
    zip: number;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    digitalStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    digitalEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    payoutFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    notaryStatus: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    paymentDetails?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    digitalCertificatePasscode?: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Notary
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    notaryQuizResult: string;
    /**
     * 
     * @type {string}
     * @memberof Notary
     */
    arizonaNotaryStatus: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    onDemandStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    wantSmsNotification?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Notary
     */
    quizAttempts?: number;
    /**
     * 
     * @type {Business}
     * @memberof Notary
     */
    business?: Business | null;
    /**
     * 
     * @type {Subscription}
     * @memberof Notary
     */
    activeSubscription?: Subscription | null;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    titleLenderStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Notary
     */
    businessStatus?: boolean;
}

/**
 * Check if a given object implements the Notary interface.
 */
export function instanceOfNotary(value: object): value is Notary {
    if (!('notaryId' in value) || value['notaryId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('middleName' in value) || value['middleName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('notaryStatus' in value) || value['notaryStatus'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('notaryQuizResult' in value) || value['notaryQuizResult'] === undefined) return false;
    if (!('arizonaNotaryStatus' in value) || value['arizonaNotaryStatus'] === undefined) return false;
    return true;
}

export function NotaryFromJSON(json: any): Notary {
    return NotaryFromJSONTyped(json, false);
}

export function NotaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notary {
    if (json == null) {
        return json;
    }
    return {
        
        'notaryId': json['notaryId'],
        'firstName': json['firstName'],
        'middleName': json['middleName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'commissionNumber': json['commissionNumber'] == null ? undefined : json['commissionNumber'],
        'commissionExpirationDate': json['commissionExpirationDate'] == null ? undefined : json['commissionExpirationDate'],
        'copyOfNotaryCommission': json['copyOfNotaryCommission'] == null ? undefined : json['copyOfNotaryCommission'],
        'nnaIdNumber': json['nnaIdNumber'] == null ? undefined : json['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': json['notarySigningAgentExamExpirationDate'] == null ? undefined : json['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': json['backgroundCheckExpirationDate'] == null ? undefined : json['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': json['eoInsuranceProvider'] == null ? undefined : json['eoInsuranceProvider'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'policyExpiryDate': json['policyExpiryDate'] == null ? undefined : json['policyExpiryDate'],
        'copyOfEoPolicy': json['copyOfEoPolicy'] == null ? undefined : json['copyOfEoPolicy'],
        'digitalCertificate': json['digitalCertificate'] == null ? undefined : json['digitalCertificate'],
        'signature': json['signature'] == null ? undefined : json['signature'],
        'initials': json['initials'] == null ? undefined : json['initials'],
        'seal': json['seal'] == null ? undefined : json['seal'],
        'suffix': json['suffix'] == null ? undefined : json['suffix'],
        'policyNumber': json['policyNumber'] == null ? undefined : json['policyNumber'],
        'country': json['country'] == null ? undefined : json['country'],
        'zip': json['zip'],
        'state': json['state'],
        'city': json['city'],
        'county': json['county'] == null ? undefined : json['county'],
        'digitalStartDate': json['digitalStartDate'] == null ? undefined : json['digitalStartDate'],
        'digitalEndDate': json['digitalEndDate'] == null ? undefined : json['digitalEndDate'],
        'payoutFrequency': json['payoutFrequency'] == null ? undefined : json['payoutFrequency'],
        'notaryStatus': json['notaryStatus'],
        'paymentDetails': json['paymentDetails'] == null ? undefined : json['paymentDetails'],
        'digitalCertificatePasscode': json['digitalCertificatePasscode'] == null ? undefined : json['digitalCertificatePasscode'],
        'user': OmitTypeClassFromJSON(json['user']),
        'notaryQuizResult': json['notaryQuizResult'],
        'arizonaNotaryStatus': json['arizonaNotaryStatus'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'onDemandStatus': json['onDemandStatus'] == null ? undefined : json['onDemandStatus'],
        'wantSmsNotification': json['wantSmsNotification'] == null ? undefined : json['wantSmsNotification'],
        'quizAttempts': json['quizAttempts'] == null ? undefined : json['quizAttempts'],
        'business': json['business'] == null ? undefined : BusinessFromJSON(json['business']),
        'activeSubscription': json['activeSubscription'] == null ? undefined : SubscriptionFromJSON(json['activeSubscription']),
        'titleLenderStatus': json['titleLenderStatus'] == null ? undefined : json['titleLenderStatus'],
        'businessStatus': json['businessStatus'] == null ? undefined : json['businessStatus'],
    };
}

export function NotaryToJSON(value?: Notary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notaryId': value['notaryId'],
        'firstName': value['firstName'],
        'middleName': value['middleName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'address1': value['address1'],
        'address2': value['address2'],
        'commissionNumber': value['commissionNumber'],
        'commissionExpirationDate': value['commissionExpirationDate'],
        'copyOfNotaryCommission': value['copyOfNotaryCommission'],
        'nnaIdNumber': value['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': value['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': value['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': value['eoInsuranceProvider'],
        'insuranceAmount': value['insuranceAmount'],
        'policyExpiryDate': value['policyExpiryDate'],
        'copyOfEoPolicy': value['copyOfEoPolicy'],
        'digitalCertificate': value['digitalCertificate'],
        'signature': value['signature'],
        'initials': value['initials'],
        'seal': value['seal'],
        'suffix': value['suffix'],
        'policyNumber': value['policyNumber'],
        'country': value['country'],
        'zip': value['zip'],
        'state': value['state'],
        'city': value['city'],
        'county': value['county'],
        'digitalStartDate': value['digitalStartDate'],
        'digitalEndDate': value['digitalEndDate'],
        'payoutFrequency': value['payoutFrequency'],
        'notaryStatus': value['notaryStatus'],
        'paymentDetails': value['paymentDetails'],
        'digitalCertificatePasscode': value['digitalCertificatePasscode'],
        'user': OmitTypeClassToJSON(value['user']),
        'notaryQuizResult': value['notaryQuizResult'],
        'arizonaNotaryStatus': value['arizonaNotaryStatus'],
        'isInhouseNotary': value['isInhouseNotary'],
        'onDemandStatus': value['onDemandStatus'],
        'wantSmsNotification': value['wantSmsNotification'],
        'quizAttempts': value['quizAttempts'],
        'business': BusinessToJSON(value['business']),
        'activeSubscription': SubscriptionToJSON(value['activeSubscription']),
        'titleLenderStatus': value['titleLenderStatus'],
        'businessStatus': value['businessStatus'],
    };
}

