/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactUsResponseDTO
 */
export interface ContactUsResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ContactUsResponseDTO
     */
    comment: string;
    /**
     * 
     * @type {Date}
     * @memberof ContactUsResponseDTO
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ContactUsResponseDTO
     */
    updatedDate: Date;
}

/**
 * Check if a given object implements the ContactUsResponseDTO interface.
 */
export function instanceOfContactUsResponseDTO(value: object): value is ContactUsResponseDTO {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('comment' in value) || value['comment'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function ContactUsResponseDTOFromJSON(json: any): ContactUsResponseDTO {
    return ContactUsResponseDTOFromJSONTyped(json, false);
}

export function ContactUsResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactUsResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'companyName': json['company_name'] == null ? undefined : json['company_name'],
        'phoneNumber': json['phone_number'],
        'email': json['email'],
        'role': json['role'],
        'comment': json['comment'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function ContactUsResponseDTOToJSON(value?: ContactUsResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'company_name': value['companyName'],
        'phone_number': value['phoneNumber'],
        'email': value['email'],
        'role': value['role'],
        'comment': value['comment'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
    };
}

