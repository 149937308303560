import {
  Box,
  Button,
  Center,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoReload } from "react-icons/io5";
import { BsCheckCircleFill } from "react-icons/bs";
import { useAuth } from "../../../../utils/use-auth";
import { notaryPaths } from "../../../../routes/routes";
import monitor from "../../../../assets/images/monitor.png";
import { handleError } from "../../../../utils/utils-functions";
import { useMessagesStore } from "../../../../store/mesagesStore";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";
import {
  ActiveSessionApi,
  AuditLogs,
  SignedDocumentApi,
} from "../../../../api";

type PropsType = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  auditLogsData: AuditLogs[];
};

const CompleteMeetingModal = ({
  isOpen,
  onClose,
  onOpen,
  auditLogsData,
}: PropsType) => {
  const { configuration } = useAuth();
  const { clearMessages } = useMessagesStore();
  const [errMsg, setErrMsg] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [passcode, setPasscode] = useState<string>("");
  const [isPasscodeErr, setIsPasscode] = useState<boolean>(false);
  const [isPaymentErr, setIsPaymentErr] = useState<boolean>(false);
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const activeSessionId = useActiveSessionStore(
    (state) => state.activeSessionId
  );

  const SignerSignatureEvent = auditLogsData
    ?.filter(
      (el) =>
        el?.event === "signerSignature" || el?.event === "signerSignatureRoi"
    )
    ?.map((el) => el?.action);

  const addCount = SignerSignatureEvent?.filter((x) => x === "add")?.length;

  const deleteCount = SignerSignatureEvent?.filter(
    (x) => x === "delete"
  )?.length;

  const notarySignatureAndStamp = auditLogsData
    ?.filter(
      (el) => el?.event === "notarySignature" || el?.event === "notaryStamp"
    )
    ?.map((el) => el?.action);

  const notaryAddCount = notarySignatureAndStamp?.filter(
    (x) => x === "add"
  )?.length;

  const notaryDeleteCount = notarySignatureAndStamp?.filter(
    (x) => x === "delete"
  )?.length;

  const finalNotaryAddCount = notaryAddCount - notaryDeleteCount;

  const handleEndMeeting = () => {
    if (activeSessionId) {
      new ActiveSessionApi(configuration)
        .activeSessionControllerEndSession({
          uploadRecordingDTO: {
            activeSessionId: activeSessionId,
            notaryId: Number(sessionInfo?.notary?.notaryId),
          },
        })
        .then((res) => {
          setIsSubmit(false);
          window.location.href = `${notaryPaths.notaryOndemand}/meeting-complete/${activeSessionId}`;
        });
        // #TODO : error handling
    }
  };

  const handlePayment = () => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerSessionPayment({
        manualPaymentDTO: {
          sessionId: Number(sessionInfo?.sessionId),
        },
      })
      .then((res) => {
        setIsPaymentErr(false);
        handleEndMeeting();
      })
      .catch(async (err) => {
        const message = await handleError(err);
        setErrMsg(message.errorMessage);
        setIsSubmit(false);
        setIsPaymentErr(true);
      });
  };

  const handleTryAgain = () => {
    setIsSubmit(true);
    setErrMsg("");
    handlePayment();
  };

  const signedDocApi = () => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerSigDocument({
        signDocDTO: {
          sessionId: Number(sessionInfo?.sessionId),
          passCode: isPasscodeErr ? (passcode as string) : "",
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setErrMsg("");
          handlePayment();
        }
      })
      .catch(async (err) => {
        const message = await handleError(err);
        setErrMsg(message.errorMessage);
        setIsSubmit(false);
        setIsPaymentErr(false);
      });
  };

  const handleSubmit = () => {
    // clear current Session messages
    clearMessages();
    if (activeSessionId !== undefined) {
      setIsSubmit(true);
      setErrMsg("");

      //Call api to check digital certificate passcode
      if (passcode === "") {
        new SignedDocumentApi(configuration)
          .signedDocumentControllerCheckPasscode()
          .then((res) => {
            if (res.status === 200) {
              setIsPasscode(false);
              signedDocApi();
            } else {
              setIsPasscode(true);
              setIsSubmit(false);
            }
          })
          .catch((err) => {
            // setIsPasscode(true);
            setIsSubmit(false);
          });
      } else {
        signedDocApi();
      }

      // This Api is required because it post the final edits made to the document
      // if we think of getting the auditLogs in backend by sessionId
      // then it will be hard to handle add ,delete,update to create the final Document
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsSubmit(false);
          setErrMsg("");
          setIsPasscode(false);
          onClose();
        }}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalCloseButton
            style={{
              background: "#D5AD36",
              color: "#fff",
              height: "35px",
              width: "35px",
              borderRadius: "50%",
              position: "absolute",
              right: "-9px",
              top: "-14px",
            }}
          />
          <ModalBody>
            <Stack>
              <Box textAlign={"center"} mt={5}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    src={monitor}
                    width="100px"
                    height="100px"
                    textAlign={"center"}
                    margin={"auto"}
                  />
                </div>
                <Text
                  fontSize={"22px"}
                  fontWeight={"400"}
                  fontFamily="Lato"
                  textAlign={"center"}
                >
                  All meeting requirements have been fufilled
                </Text>

                {isPasscodeErr && (
                  <Box my={3}>
                    <Text
                      textAlign={"left"}
                      fontSize={"13px"}
                      mb={1}
                      fontWeight={500}
                    >
                      Enter passcode of your digital certificate:
                    </Text>
                    <InputGroup>
                      <Input
                        type="text"
                        onChange={(e) => {
                          setPasscode(e.target.value);
                        }}
                        placeholder="Enter passcode of your digital certificate."
                      />
                    </InputGroup>
                  </Box>
                )}
              </Box>
            </Stack>

            <Stack mt={2}>
              {finalNotaryAddCount === 0 && (
                <Text textAlign={"center"} color="red" fontSize={"12px"}>
                  Notary Signature or Notary Stamp not added in the document.
                  Required to add one of it in the document
                </Text>
              )}

              {/* {!isNotaryExemplarSheet && addCount > deleteCount && (
                <Text textAlign={"center"} color="red" fontSize={"13px"}>
                  Signer Signature not added in the document. You want to
                  complete session without signer signature?
                </Text>
              )} */}

              {isSubmit && (
                <Text textAlign={"center"} color="red" fontSize={"13px"}>
                  Please wait a while. It will take some time to complete the
                  process...
                </Text>
              )}

              {errMsg !== "" && (
                <Text textAlign={"center"} color="red" fontSize={"13px"}>
                  {errMsg}
                </Text>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter
            justifyContent={isPaymentErr ? "space-between" : "center"}
          >
            {isPaymentErr ? (
              <>
                <Button
                  w={"170px"}
                  background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                  borderRadius="10px"
                  p="6"
                  fontSize={"16px"}
                  _hover={{
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  }}
                  onClick={handleTryAgain}
                  isDisabled={isSubmit}
                >
                  <IoReload /> <Text ml={2}>Try Again</Text>
                </Button>
                <Button
                  w={"170px"}
                  borderRadius="10px"
                  p="6"
                  fontSize={"16px"}
                  _hover={{
                    opacity: "0.9",
                  }}
                  backgroundColor="#09773C"
                  color="#fff"
                  disabled={true}
                  onClick={handleEndMeeting}
                >
                  <BsCheckCircleFill /> <Text ml={2}>Complete</Text>
                </Button>
              </>
            ) : (
              <Button
                w={"420px"}
                background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                borderRadius="10px"
                p="6"
                fontSize={"16px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                }}
                onClick={handleSubmit}
                isDisabled={
                  isSubmit ||
                  (isPasscodeErr && passcode === "") ||
                  finalNotaryAddCount === 0
                  // auditLogs?.[0]?.length === 0 ||
                }
              >
                {isSubmit ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  <>
                    <BsCheckCircleFill /> <Text ml={2}>Complete Meeting</Text>
                  </>
                )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CompleteMeetingModal;
