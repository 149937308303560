import {
  ParticipantName,
  ParticipantTile,
  TrackLoop,
  TrackRefContext,
  TrackReference,
  VideoTrack,
} from "@livekit/components-react";
import { useEffect, useMemo, useState } from "react";
import { Notary, NotaryApi, Signer, SignerApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../../utils/utils";
import { Box, Text } from "@chakra-ui/react";
import { COLORS } from "../../../../utils/constants";

function RemoteParticipants({ tracks }: { tracks: TrackReference[] }) {
  const { configuration } = useAuth();
  const [userData, setUserData] = useState<Notary | Signer | null>(null);

  const [role, userId] = useMemo(() => {
    if (tracks.length === 0) return;
    const [role, id] = tracks[0].participant.identity.split(
      LIVEKIT_SPLIT_IDENTIFIER
    );
    return [role, id];
  }, [tracks]);

  useEffect(() => {
    if (role === "notary") {
      new NotaryApi(configuration)
        .notaryControllerFindOne({ userId })
        .then((res) => {
          setUserData(res);
        });
    } else if (role === "signer") {
      new SignerApi(configuration)
        .signerControllerGetProfileUser({ userId: Number(userId) })
        .then((res) => {
          setUserData(res);
        });
    }
  }, [role, userId]);

  return (
    <TrackLoop tracks={tracks}>
      <Box
        sx={{
          margin: "20px 0px",
          backgroundColor: COLORS.blue,
          padding: "10px",
          width: "fit-content",
          borderRadius: "8px",
        }}
      >
        <Box>
          <Text fontWeight={"semibold"} textTransform={"capitalize"}>
            <TrackRefContext.Consumer>
              {(trackRef) => (
                <Text fontWeight={"semibold"} textTransform={"capitalize"}>
                  {
                    trackRef?.participant?.identity?.split(
                      LIVEKIT_SPLIT_IDENTIFIER
                    )[0]
                  }
                </Text>
              )}
            </TrackRefContext.Consumer>
          </Text>
        </Box>
        <ParticipantTile style={{ height: "100%", width: "100%" }}>
          <VideoTrack
            style={{
              maxHeight: "300px",
              maxWidth: "300px",
              height: "100%",
              width: "100%",
              aspectRatio: "16 / 12",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          {userData !== null ? (
            <p>{userData?.firstName + " " + userData?.lastName}</p>
          ) : null}
        </ParticipantTile>
      </Box>
    </TrackLoop>
  );
}

export default RemoteParticipants;
