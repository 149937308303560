import { useEffect } from "react";
import CommissionDetailsForm from "../../../component/Details/CommissionDetailsForm";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const CommissionDetails = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  return <CommissionDetailsForm />;
};

export default CommissionDetails;
