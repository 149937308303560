import { Image as ImageComponent } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import deleteIcon from "../../../assets/icons/trash.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { useDimensionsStore } from "../../../store/dimensions";
import { useDocumentStore } from "../../../store/documentsStore";
import { EventAction, useAuditLogsStore } from "../../../store/auditLogs";
import { SessionToolsProps } from "../../../store/zustandType";

const ImageCanvas = ({ object, page, from, scale }: SessionToolsProps) => {
  const ratio = useDimensionsStore((state) => state.ratio);
  const [imageSource, setImageSource] = useState<HTMLImageElement>();
  const docId = useDocumentStore((state) => state.currentDocId);
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  useEffect(() => {
    const loadImage = async (
      url: string | undefined
    ): Promise<HTMLImageElement | undefined> => {
      if (url == undefined) return;
      let blob = await fetch(url + "&cacheblock=true").then((r) => r.blob());
      // const blob = await (await fetch(url + "&cacheblock=true")).blob();
      return new Promise((resolve, reject) => {
        const img = new Image();
        if (blob instanceof Blob) {
          const url = window.URL.createObjectURL(blob);
          img.src = url;
          img.onload = () => resolve(img);
        } else {
          img.src = blob;
          img.onerror = reject;
        }

        return img;
      });
    };

    loadImage(object.url).then((res) => {
      if (res !== undefined) {
        setImageSource(res);
      }
    });
  }, []);

  useEffect(() => {
    if (from !== "notary") return;
    updateAuditLog({
      id: object.id!,
      selectedPage: page,
      type: object.type!,
      auditLog: {
        ...object,
        width: object?.width ? object.width : imageSource?.width,
        height: object?.height ? object?.height : imageSource?.height,
      },
    });
    emitEvent({
      action: EventAction.update,
      event: object.type!,
      pageNumber: page,
      payload: {
        ...object,
        width: object?.width ? object.width : imageSource?.width,
        height: object?.height ? object?.height : imageSource?.height,
      },
      sessionId: sessionId,
      socket: socket,
      docId,
    });
  }, [imageSource]);

  return (
    <Rnd
      disableDragging={
        from !== "notary" && from !== "business" && from !== "edit"
      }
      enableResizing={
        from === "notary" || from === "business" || from === "edit"
      }
      bounds="parent"
      scale={scale}
      style={{
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        margin: 0,
        padding: 0,
        border: "1px dashed gray",
      }}
      size={{
        height: object.height
          ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 150,
        width: object.width
          ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 250,
      }}
      position={{
        x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
        y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
      }}
      onDragStop={(e, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
      onResizeStop={(e, direction, ref, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
    >
      {/* {from === "notary" && (
        <div style={{ cursor: "pointer", marginRight: "4px" }}>
          <ImageComponent src={anchor} alt="move icon" height={5} width={5} />
        </div>
      )} */}
      {imageSource !== undefined && (
        <ImageComponent
          draggable="false"
          src={imageSource.src}
          height={"100%"}
          width={"100%"}
          margin={0}
          padding={0}
        />
      )}

      {(from === "notary" || from === "business" || from === "edit") && (
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            height: "30px",
            width: "30px",
            left: "100%",
          }}
          onClick={() => {
            deleteAuditLog({
              id: object.id!,
              selectedPage: page,
              type: object.type!,
              payload: object,
            });
            emitEvent({
              action: EventAction.delete,
              event: object.type!,
              pageNumber: page,
              payload: object,
              sessionId: sessionId,
              socket: socket,
              docId,
            });
          }}
        >
          <ImageComponent
            src={deleteIcon}
            alt="delete icon"
            height={6}
            width={6}
          />
        </div>
      )}
    </Rnd>
  );
};

export default ImageCanvas;
