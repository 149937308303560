/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  KBALogs,
  KBALogsDTO,
} from '../models/index';
import {
    KBALogsFromJSON,
    KBALogsToJSON,
    KBALogsDTOFromJSON,
    KBALogsDTOToJSON,
} from '../models/index';

export interface KBAControllerCreateRequest {
    kBALogsDTO: KBALogsDTO;
}

export interface KBAControllerGetKbaByIdRequest {
    kbaId: number;
}

/**
 * 
 */
export class KbaLogsApi extends runtime.BaseAPI {

    /**
     */
    async kBAControllerCreateRaw(requestParameters: KBAControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KBALogs>> {
        if (requestParameters['kBALogsDTO'] == null) {
            throw new runtime.RequiredError(
                'kBALogsDTO',
                'Required parameter "kBALogsDTO" was null or undefined when calling kBAControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/kba-logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KBALogsDTOToJSON(requestParameters['kBALogsDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KBALogsFromJSON(jsonValue));
    }

    /**
     */
    async kBAControllerCreate(requestParameters: KBAControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KBALogs> {
        const response = await this.kBAControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async kBAControllerGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KBALogs>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/kba-logs/kbaLogs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KBALogsFromJSON(jsonValue));
    }

    /**
     */
    async kBAControllerGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KBALogs> {
        const response = await this.kBAControllerGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async kBAControllerGetKbaByIdRaw(requestParameters: KBAControllerGetKbaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KBALogs>> {
        if (requestParameters['kbaId'] == null) {
            throw new runtime.RequiredError(
                'kbaId',
                'Required parameter "kbaId" was null or undefined when calling kBAControllerGetKbaById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/kba-logs/kbaLogs/{kbaId}`.replace(`{${"kbaId"}}`, encodeURIComponent(String(requestParameters['kbaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KBALogsFromJSON(jsonValue));
    }

    /**
     */
    async kBAControllerGetKbaById(requestParameters: KBAControllerGetKbaByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KBALogs> {
        const response = await this.kBAControllerGetKbaByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
