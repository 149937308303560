/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateResponse
 */
export interface UpdateResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateResponse
     */
    inhouseNotary?: Array<string>;
}

/**
 * Check if a given object implements the UpdateResponse interface.
 */
export function instanceOfUpdateResponse(value: object): value is UpdateResponse {
    return true;
}

export function UpdateResponseFromJSON(json: any): UpdateResponse {
    return UpdateResponseFromJSONTyped(json, false);
}

export function UpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'inhouseNotary': json['inhouseNotary'] == null ? undefined : json['inhouseNotary'],
    };
}

export function UpdateResponseToJSON(value?: UpdateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'inhouseNotary': value['inhouseNotary'],
    };
}

