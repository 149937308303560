//@ts-nocheck
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import {
  useNotaryProfileStepDisableStore,
  useProfileDetailsStore,
} from "../../store/notaryStore";
import { StateFullnameOptions } from "../../utils/options";
import Select, { SingleValue } from "react-select";
import { useState } from "react";
import { useAuth } from "../../utils/use-auth";
import { NotaryApi } from "../../api";
import { NotaryProfileDetails } from "../../store/zustandType";
import useNotification from "../../utils/useNotification";
import { notaryPaths } from "../../routes/routes";

const PersonalDetailsForm = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const UserId = localStorage.getItem("UserID");
  const [disable, setDisable] = useState<boolean>(false);
  const { setIsDisableStep } = useNotaryProfileStepDisableStore();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >(
    StateFullnameOptions.find((item) => item.value === notaryProfile.state) || {
      label: "",
      value: "",
    }
  );

  const createNotaryProfile = (values: NotaryProfileDetails) => {
    new NotaryApi(configuration)
      .notaryControllerCreate({
        notaryDTO: {
          ...values,
          zip: Number(values.zip),
          user: Number(UserId),
          phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
          state: selectedOption?.value as string,
          wantSmsNotification: notaryProfile?.wantSmsNotification,
        },
      })
      .then((res) => {
        setNotaryProfile({
          ...values,
          zip: values.zip,
          phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
          state: selectedOption?.value as string,
          notaryId: res?.notaryId?.toString(),
        });
        setDisable(false);
        setIsDisableStep(false);
        notification("success", "Profile created Successfully");
        navigate(`${notaryPaths.profileSetting}/nna-details`);
      })
      .catch((error) => {
        console.log(error);
        setDisable(false);
        notification("fail", "Failed to create profile");
      });
  };

  const updateNotaryProfile = (values: NotaryProfileDetails) => {
    new NotaryApi(configuration)
      // .notaryControllerUpdateNotary({
      //   id: Number(notaryProfile?.notaryId),
      //   updateNotaryDTO: {
      //     ...values,
      //     zip: Number(notaryProfile.zip),
      //     phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
      //     state: selectedOption?.value as string,
      //   },
      // })
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          firstName: values?.firstName,
          lastName: values?.lastName,
          middleName: values?.middleName,
          suffix: values?.suffix,
          address1: values?.address1,
          address2: values?.address2,
          city: values?.city,
          county: values?.county,
          zip: Number(values.zip),
          phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
          state: selectedOption?.value as string,
          wantSmsNotification: notaryProfile?.wantSmsNotification,
        },
      })
      .then((res) => {
        setDisable(false);
        navigate(`${notaryPaths.profileSetting}/nna-details`);
      })
      .catch((error) => {
        setDisable(false);
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...notaryProfile,
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(50, "First Name must be 50 characters or less.")
        .required("First Name is required"),
      middleName: Yup.string().max(
        50,
        "Middle Name must be 50 characters or less."
      ),
      lastName: Yup.string()
        .max(50, "Last Name must be 50 characters or less.")
        .required("Last Name is required"),
      zip: Yup.string()
        .max(8, "Zip Code must be of maximum 8 digits")
        .required("Zip Code is required"),
      city: Yup.string()
        .max(100, "City Name must not exceed 100 characters")
        .required("City Name is required"),
      phoneNumber: Yup.string().min(10).required("Phone Number is required"),
      address1: Yup.string()
        .max(200, "Address Line 1 must be 200 characters or less.")
        .required("Address Line 1 is required"),
    }),

    onSubmit: (values) => {
      setNotaryProfile({
        ...values,
        phoneNumber: values?.phoneNumber?.replace(/[^\d]/g, ""),
        state: selectedOption?.value as string,
      });
      setDisable(true);
      if (notaryProfile?.notaryId !== "") {
        updateNotaryProfile(values);
      } else {
        createNotaryProfile(values);
      }
    },
    enableReinitialize: false,
  });

  return (
    <Stack px={6}>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={4}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Personal Information
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Please enter your personal information exactly as it appears on your
        notary commission as well as on file with your commissioning state.
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <Flex flexWrap={"wrap"}>
          <FormControl id="firstName" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">First Name*</FormLabel>
            <Input
              name="firstName"
              type="text"
              placeholder="First Name"
              {...(formik.touched.firstName && formik.errors.firstName
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""}
            </Text>
          </FormControl>

          <FormControl id="Name" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">Middle Name</FormLabel>
            <Input
              {...(formik.touched.middleName && formik.errors.middleName
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="middleName"
              value={formik.values.middleName}
              type="text"
              placeholder="Middle Name"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.middleName && formik.errors.middleName
                ? formik.errors.middleName
                : ""}
            </Text>
          </FormControl>

          <FormControl id="Name" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">Last Name*</FormLabel>
            <Input
              name="lastName"
              value={formik.values.lastName}
              type="text"
              placeholder="Last Name"
              {...(formik.touched.lastName && formik.errors.lastName
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""}
            </Text>
          </FormControl>

          <FormControl id="suffix" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">Suffix</FormLabel>
            <Input
              name="suffix"
              type="text"
              placeholder="Jr."
              {...(formik.touched.suffix && formik.errors.suffix
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.suffix}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.suffix && formik.errors.suffix
                ? formik.errors.suffix
                : ""}
            </Text>
          </FormControl>

          <FormControl id="Email" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">Email*</FormLabel>
            <Input
              required
              name="email"
              value={
                notaryProfile.email !== ""
                  ? notaryProfile.email
                  : localStorage.getItem("email") ?? ""
              }
              type="email"
              pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
              placeholder="Email"
              readOnly
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
          </FormControl>

          <FormControl id="phoneNumber" w={"50%"} minW={"220px"} p={2}>
            <FormLabel p="1">Phone Number*</FormLabel>
            <ReactInputMask
              mask="(999)-999-9999"
              value={formik.values.phoneNumber?.replace(
                /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                "$1$2$3-$4$5$6-$7$8$9$10"
              )}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            >
              {/* @ts-ignore */}
              {() => <Input required placeholder="(___)-___-____" />}
            </ReactInputMask>
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : ""}
            </Text>

            <Checkbox
              mt={2}
              fontSize={"12px"}
              isChecked={notaryProfile.wantSmsNotification}
              onChange={(e) =>
                setNotaryProfile({
                  ...notaryProfile,
                  wantSmsNotification: e.target.checked,
                })
              }
            >
              I would like to receive the SMS reminders.
            </Checkbox>
          </FormControl>

          <FormControl id="address1" w={"100%"} minW={"220px"} p={2}>
            <FormLabel p="1">Address Line 1*</FormLabel>
            <Input
              {...(formik.touched.address1 && formik.errors.address1
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="address1"
              value={formik.values.address1}
              type="text"
              placeholder="Address Line 1"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.address1 && formik.errors.address1
                ? formik.errors.address1
                : ""}
            </Text>
          </FormControl>

          <FormControl id="address2" w={"100%"} minW={"220px"} p={2}>
            <FormLabel p="1">Address Line 2</FormLabel>
            <Input
              {...(formik.touched.address2 && formik.errors.address2
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="address2"
              value={formik.values.address2}
              type="text"
              placeholder="Address Line 2"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.address2 && formik.errors.address2
                ? formik.errors.address2
                : ""}
            </Text>
          </FormControl>

          <FormControl id="city" w={"25%"} minW={"250px"} p={2}>
            <FormLabel p={1}>City*</FormLabel>
            <Input
              placeholder="City"
              name="city"
              type={"text"}
              {...(formik.touched.city && formik.errors.city
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.city}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.city && formik.errors.city
                ? formik.errors.city
                : ""}
            </Text>
          </FormControl>

          <FormControl id="state" w={"25%"} minW={"200px"} p={2}>
            <FormLabel p={1}>State*</FormLabel>
            <Select
              placeholder="Select State"
              options={StateFullnameOptions}
              value={selectedOption}
              menuPlacement="top"
              onChange={(option) => {
                setSelectedOption(option);
                setNotaryProfile({
                  ...notaryProfile,
                  state: option?.value,
                });
              }}
            />
          </FormControl>

          <FormControl id="county" w={"25%"} minW={"250px"} p={2}>
            <FormLabel p={1}>County</FormLabel>
            <Input
              placeholder="County"
              name="county"
              type={"text"}
              {...(formik.touched.county && formik.errors.county
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.county}
              onBlur={formik.handleBlur}
              style={{ textTransform: "capitalize" }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.county && formik.errors.county
                ? formik.errors.county
                : ""}
            </Text>
          </FormControl>

          <FormControl id="zip" w={"25%"} minW={"250px"} p={2}>
            <FormLabel p={1}>Zip*</FormLabel>
            <Input
              minLength={5}
              maxLength={5}
              placeholder="eg. 234156"
              name="zip"
              type={"number"}
              {...(formik.touched.zip && formik.errors.zip
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              value={formik.values.zip}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.zip && formik.errors.zip ? formik.errors.zip : ""}
            </Text>
          </FormControl>
        </Flex>

        <Box w={"fit-content"} ml="auto" p="2">
          <Button
            type="submit"
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
              marginTop: "30px",
            }}
            isDisabled={disable}
          >
            SAVE & NEXT
          </Button>
        </Box>
      </form>
    </Stack>
  );
};

export default PersonalDetailsForm;
