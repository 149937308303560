import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../utils/use-auth";
import { Rating } from "react-simple-star-rating";
import BaseLayout from "../../../layout/BaseLayout";
import { PlatformRatingApi } from "../../../api";
import { signerPaths } from "../../../routes/routes";

const Feedback = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { configuration } = useAuth();
  const [note, setNote] = useState<string>("");
  const [appRating, setAppRating] = useState<number>(0);
  const [notaryRating, setNotaryRating] = useState<number>(0);

  const handleSubmit = () => {
    if (sessionId) {
      new PlatformRatingApi(configuration)
        .platformRatingControllerCreate({
          platformRatingDTO: {
            sessionId: Number(sessionId),
            feedback: note,
            platformStarRating: appRating,
            notaryStarRating: notaryRating,
          },
        })
        .then((res) => {
          setNote("");
          setAppRating(0);
          setNotaryRating(0);
          navigate("/");
        });
    }
  };

  return (
    <BaseLayout showBack={false}>
      <Heading
        pt={4}
        fontSize="20px"
        fontWeight={600}
        textAlign="center"
        fontFamily="Oswald"
      >
        Your Feedback
      </Heading>

      <Box width={["90%", "70%", "70%"]} margin={"auto"} pt={6}>
        <Text fontWeight={500}>
          We would like your feedback to improve our web app.
        </Text>

        <Text py={4} fontWeight={600}>
          Please rate your experience with notary
        </Text>

        <Rating
          size={60}
          onClick={(rate: number) => {
            setNotaryRating(rate);
          }}
        />

        <Divider mt={5} />

        <Text py={4} pt={6} fontWeight={600}>
          How satisfied are you with our web app?
        </Text>

        <Rating
          size={60}
          onClick={(rate: number) => {
            setAppRating(rate);
          }}
        />

        <Divider mt={5} />

        <Text py={4} pt={6} fontWeight={600}>
          Do you want to inform something else?
        </Text>
        <Textarea
          py={4}
          placeholder="Enter your feedback here"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        <Flex justifyContent={"space-between"}>
          <Button
            mt={10}
            px={12}
            background="white"
            border={"1px solid rgba(0, 0, 128, 1)"}
            borderRadius="10px"
            fontSize={"16px"}
            color="rgba(0, 0, 128, 1)"
            onClick={() => navigate(`${signerPaths.list}`)}
          >
            Skip
          </Button>
          <Button
            mt={10}
            px={10}
            bgGradient="linear(to-t, yellow.500, yellow.300)"
            _hover={{
              background: "yellow.400",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Flex>
      </Box>
    </BaseLayout>
  );
};

export default Feedback;
