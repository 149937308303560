import {
  Button,
  Flex,
  Text,
  Box,
  useDisclosure,
  Spinner,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { AiFillCheckCircle } from "react-icons/ai";
import PaymentCard from "./PaymentCard";
import { getMonth } from "../../../utils/utils";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { CardDTO, CustomerApi, TransactionApi } from "../../../api";

const PaymentData = ({
  onDisable,
  from,
}: {
  from?: string;
  onDisable?: (value: boolean) => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const token = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [cardDetails, setCardDetails] = useState<CardDTO[]>([]);
  const [defaultCardId, setDefaultCardId] = useState<string>("");

  const getCard = () => {
    setLoading(true);
    new CustomerApi(configuration)
      .userControllerGetCutomerByUserId()
      .then((res) => {
        setCustomerId(res.customerId);
        new TransactionApi(configuration)
          .transactionControllerGetPaymentMethod({
            customerId: res.customerId,
          })
          .then((result) => {
            setCardDetails(result.cards);
            setDefaultCardId(result?.defaultPaymentMethodId);
            if (
              result?.defaultPaymentMethodId === null &&
              result.cards?.length !== 0
            ) {
              new TransactionApi(configuration)
                .transactionControllerSetdefault({
                  setDTO: {
                    paymentMethodId: result.cards?.[0]
                      ?.paymentMethodId as string,
                    customerId: res.customerId,
                  },
                })
                .then((res) => {
                  if (res?.status === 200) {
                    setDefaultCardId(
                      result.cards?.[0]?.paymentMethodId as string
                    );
                  }
                });
            }
            setLoading(false);
          })
          .catch((err) => {
            setCardDetails([]);
            setDefaultCardId("");
            setLoading(false);
          });
      });
  };

  useEffect(() => {
    if (token !== null) {
      getCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (onDisable) {
      if (cardDetails?.length === 0) {
        onDisable(true);
      } else {
        onDisable(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetails]);

  const handleSaveCard = () => {
    getCard();
    onClose();
  };

  const handleRemoveCard = (paymentMethodId: string) => {
    new TransactionApi(configuration)
      .transactionControllerRemoveCard({ paymentMethodId })
      .then((res) => {
        getCard();
      })
      .catch((err) => {
        console.log(err);
        notification("fail", "Failed to remove card");
      });
  };

  const handleDefault = (paymentMethodId: string) => {
    new TransactionApi(configuration)
      .transactionControllerSetdefault({
        setDTO: {
          paymentMethodId: paymentMethodId,
          customerId: customerId,
        },
      })
      .then((res) => {
        getCard();
      });
  };

  return (
    <>
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : cardDetails.length !== 0 ? (
        <>
          {from === "profile" ? (
            <Flex alignItems={"center"}>
              <AiFillCheckCircle color="green" />
              <Text ml={1}>Card Added Successfully!</Text>
            </Flex>
          ) : (
            <>
              {cardDetails?.flatMap((card, idx) => (
                <Flex marginTop={2} key={idx}>
                  <Box
                    style={{
                      maxWidth: "420px",
                      width: "100%",
                      margin: "10px",
                      padding: "20px",
                      boxShadow: "-1px 5px 15px 0px #ddd",
                    }}
                  >
                    <Flex justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Text fontWeight={600}>Card Number</Text>
                          <Text fontSize={14}>{card?.cardNumber}</Text>

                          <Text mt={4} fontWeight={600}>
                            Expiry Date
                          </Text>
                          <Text fontSize={14}>
                            {getMonth(card?.cardExpMonth)} {card?.cardExpYear}
                          </Text>
                        </Box>
                        <Box ml={12}>
                          <Text fontWeight={600}>CVC</Text>
                          <Text fontSize={14}>{card?.cardCvc}</Text>

                          <Text fontWeight={600} mt={4}>
                            Card Type
                          </Text>
                          <Text fontSize={14} textTransform="capitalize">
                            {card?.brand}
                          </Text>
                        </Box>
                      </Flex>

                      {cardDetails?.length > 1 && (
                        <div
                          role="button"
                          onClick={() =>
                            handleRemoveCard(card?.paymentMethodId as string)
                          }
                        >
                          <DeleteIcon w={6} h={6} color="red.500" />
                        </div>
                      )}
                    </Flex>

                    <Flex justifyContent={"flex-end"} mt={3}>
                      {defaultCardId === card?.paymentMethodId?.toString() ? (
                        <Tooltip label="This will be used when charging for subscriptions or invoices. You can always override this default.">
                          <Text fontStyle="italic" color={"green"}>
                            (Default)
                          </Text>
                        </Tooltip>
                      ) : (
                        <Button
                          onClick={() =>
                            handleDefault(card?.paymentMethodId as string)
                          }
                        >
                          Set as Default
                        </Button>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              ))}
            </>
          )}
          {from !== "profile" && (
            <Flex justifyContent={"center"}>
              <Button
                type="button"
                width={"150px"}
                style={{
                  background:
                    "linear-gradient(rgb(213, 173, 54) 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#000",
                  marginTop: "15px",
                  padding: "14px 20px",
                  height: "50px",
                }}
                onClick={onOpen}
              >
                + Add New Card
              </Button>
            </Flex>
          )}
        </>
      ) : (
        <Flex
          justifyContent={from !== "profile" ? "center" : "flex-start"}
          direction={"column"}
        >
          <Button
            type="button"
            width={"150px"}
            style={{
              background:
                "linear-gradient(rgb(213, 173, 54) 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#000",
              marginTop: "15px",
              padding: "14px 20px",
              height: "50px",
            }}
            onClick={onOpen}
          >
            + Add New Card
          </Button>
        </Flex>
      )}

      <PaymentCard
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        customerId={customerId}
        onSave={handleSaveCard}
        isFirst={cardDetails.length === 0 ? true : false}
      />
    </>
  );
};

export default PaymentData;
