import { Box, useDisclosure } from "@chakra-ui/react";
import { LocalParticipant, RemoteParticipant } from "livekit-client";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import {
  ActiveSessionApi,
  AuditLogs,
  AuditLogsApi,
  KbaType,
  Session,
  Signer,
} from "../../../api";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import { IMembers } from "../../../store/zustandType";
import { WS_URL } from "../../../utils/constants";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { getSessionDetails } from "../../../utils/utils-apis";
import LoadingContent from "../../commonComponents/LoadingComponents/LoadingContent";
import ButtonsComponent from "../CommonMeeting/ButtonsComponent";
import CommonMeeting from "../CommonMeeting/CommonMeeting";
import DeviceInfoModal from "../CommonMeeting/DeviceModal/DeviceInfoModal";
import MeetingRoom from "../CommonMeeting/MeetingRoom";
import CompleteMeetingModal from "./modals/CompleteMeetingModal";
import InviteParticipantsModal from "./modals/InviteParticipantsModal";
import TerminateMeetingModal from "./modals/TerminateMeetingModal";
import VerifyDocModal from "./modals/VerifyDocModal";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../utils/utils";
import {useVerifyMembersStore} from "../../../store/verifyMembers";

const NotaryMeeting = () => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [searchParams] = useSearchParams();
  const sessionID = searchParams.get("session-id");
  const [token, setToken] = useState<string | undefined>();
  const members = useVerifyMembersStore((state) => state.members);
  const [allVerified, setAllVerified] = useState(false);
  const [active, setActive] = useState<string>("signer");
  const [isPreferencesSet, setIsPreferencesSet] = useState(false);
  // const [verifiedMembers, setVerifiedMembers] = useState<string[]>();
  const [kbaLogsDetail, setKbaLogsDetail] = useState<KbaType[]>([]);
  const [auditLogsData, setAuditLogsData] = useState<AuditLogs[]>([]);
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const setSessionInfo = useActiveSessionStore((state) => state.setSessionInfo);
  const setActiveSessionId = useActiveSessionStore(
    (state) => state.setActiveSessionId
  );
  const [sessionParticipants, setSessionParticipants] = useState<
    RemoteParticipant[] | LocalParticipant[]
  >([]);
  const setVerifiedMembers = useVerifyMembersStore((state) => state.setVerifiedMembers);

  const verifyData = members?.map((item) => item?.isVerify);

  const {
    isOpen: isAllMeetingModalOpen,
    onOpen: onAllMeetingModalOpen,
    onClose: onAllMeetingModalClose,
  } = useDisclosure();

  const {
    isOpen: isRejectDocumentModalOpen,
    onOpen: onRejectDocumentModalOpen,
    onClose: onRejectDocumentModalClose,
  } = useDisclosure();

  const {
    isOpen: isVerifyOpen,
    onClose: onVerifyClose,
    onOpen: onVerifyOpen,
  } = useDisclosure();

  const {
    isOpen: isInviteModalOpen,
    onOpen: onInviteModalOpen,
    onClose: onInviteModalClose,
  } = useDisclosure();

  const socket = useMemo(() => {
    return io(WS_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
    });
  }, []);

  useEffect(() => {
    if (verifyData?.length !== 0) {
      if (verifyData?.includes(false)) {
        setAllVerified(false);
      } else {
        setAllVerified(true);
      }
    } else {
      setAllVerified(false);
    }
  }, [verifyData]);

  // useEffect(() => {
  //   console.log("sessionInfo",sessionInfo)
  //   if (sessionParticipants?.length !== 0) {
  //     const joinedUsersId = sessionParticipants.map(
  //       (el: RemoteParticipant | LocalParticipant) =>
  //         el?.identity?.split(LIVEKIT_SPLIT_IDENTIFIER)[1]
  //     );

  //     let membersList: Signer[] = [];
  //     joinedUsersId?.forEach((el) => {
  //       const findSigner = sessionInfo?.signers?.find(
  //         // @ts-ignore, idk
  //         (signer) => signer?.user?.id === Number(el)
  //       );
  //       if (findSigner !== undefined) {
  //         membersList?.push(findSigner);
  //       }
  //     });

  //     if (membersList?.length !== 0) {
  //       setMembers([
  //         ...membersList?.map((member, idx) => ({
  //           label: `${member?.firstName?.toUpperCase()} ${member?.lastName?.toUpperCase()}`,
  //           value: member?.signerId,
  //           role:
  //             member?.user?.id === sessionInfo?.primarySignerUserId
  //               ? "primarySigner"
  //               : "signer",
  //           isVerify: Boolean(
  //             verifiedMembers?.find((el) => el === member.email)
  //           ),
  //         })),
  //       ]);

  //     }
  //   }
  // }, [sessionInfo?.signers, sessionParticipants, sessionID, verifiedMembers]);

  useEffect(()=>{
    getSessionDetails(configuration, Number(sessionID)).then((res) => {
      setKbaLogsDetail(res?.kbaLogs as KbaType[]);
    });
  },[sessionID])

  useEffect(() => {
    if (!!sessionID === false) {
      console.log("sessionID not found");
      return;
    }
    new AuditLogsApi(configuration)
      .auditLogsControllerGetAudiLogsBySessionId({
        sessionId: Number(sessionID),
      })
      .then((audits) => {
        const res = audits
          ?.filter((el) => el.action === "verified" && el?.event === "documentVerify")
          // @ts-ignore
          ?.map((audit) => audit?.payload?.email);
          console.log("verified members",res)
        setVerifiedMembers(res);
      });

    new ActiveSessionApi(configuration)
      .activeSessionControllerFindOne({
        sessionId: sessionID,
      })
      .then((res) => {
        setActiveSessionId(res?.activeSessionId);
        setSessionInfo(res?.session as Session);
        // debugger
        console.log("active session data", res);
        console.log("active session data", res?.data);
        // #TODO : get all the paricipants and witnesses here
        // also get the meeting token here for livekit
        // then set the token to join the livekit meeting
        // we know here that the token is for notary as this file is for notary
        // @ts-ignore
        const data = res?.data?.participants;
        if (!data) {
          console.log("participants not found");
          return;
        }
        const tokens = data?.reduce((acc, cur) => {
          if (typeof cur !== "object") {
            return acc;
          }
          if (Object.keys(cur)[0].startsWith("notary")) {
            acc["notary"] = {
              accessToken: Object.values(cur)[0],
            };
          } else if (Object.keys(cur)[0].startsWith("primarySigner")) {
            acc["primarySigner"] = {
              accessToken: Object.values(cur)[0],
            };
          }
          return acc;
        }, {});
        setToken(tokens?.notary?.accessToken as string);
      });
  }, [sessionID]);

  const handleInviteClose = () => {
    new ActiveSessionApi(configuration)
      .activeSessionControllerFindOne({
        sessionId: sessionID ?? "",
      })
      .then((res) => {
        // startPolling();
        setSessionInfo(res?.session as Session);
        setKbaLogsDetail(res?.session.kbaLogs as KbaType[]);
        onInviteModalClose();
      });
  };

  return (
    <Box height="100vh" backgroundColor={"#fff"}>
      {/* <div>Notary</div> */}
      {token === undefined ? (
        <LoadingContent />
      ) : (
        <>
          <DeviceInfoModal
            from="notary"
            sessionId={sessionID ?? ""}
            onSetKbaLogsDetail={(details) => setKbaLogsDetail(details)}
            onCloseComplete={() => {
              setTimeout(() => {
                setIsPreferencesSet(true);
              }, 1000);
            }}
          />

          <CompleteMeetingModal
            auditLogsData={auditLogsData}
            isOpen={isAllMeetingModalOpen}
            onOpen={onAllMeetingModalOpen}
            onClose={onAllMeetingModalClose}
          />

          <TerminateMeetingModal
            isOpen={isRejectDocumentModalOpen}
            onOpen={onRejectDocumentModalOpen}
            onClose={onRejectDocumentModalClose}
          />

          <VerifyDocModal
            socket={socket}
            isOpen={isVerifyOpen}
            onOpen={onVerifyOpen}
            onClose={onVerifyClose}
            allVerified={allVerified}
            kbaLogsDetail={kbaLogsDetail}
            onSetVerifiedMembers={(data) => setVerifiedMembers(data)}
            sessionID={Number(sessionID)}
          />

          <InviteParticipantsModal
            active={active}
            onClose={handleInviteClose}
            isOpen={isInviteModalOpen}
            onOpen={onInviteModalOpen}
          />

          <MeetingRoom token={token}>
            <CommonMeeting
              from="notary"
              socket={socket}
              sessionID={sessionID}
              // onSetSessionParticipants={(value) =>
              //   setSessionParticipants(value)
              // }
            >
              <ButtonsComponent
                allVerified={allVerified}
                onVerifyOpen={onVerifyOpen}
                onSetActive={(value) => setActive(value)}
                onInviteModalOpen={onInviteModalOpen}
                onRejectDocumentModalOpen={onRejectDocumentModalOpen}
                onAllMeetingModalOpen={() => {
                  new AuditLogsApi(configuration)
                    .auditLogsControllerGetAudiLogsBySessionId({
                      sessionId: Number(sessionID),
                    })
                    .then((res) => {
                      setAuditLogsData(res);
                    })
                    .catch((err) => console.log(err));
                  onAllMeetingModalOpen();
                }}
              />
            </CommonMeeting>
          </MeetingRoom>
        </>
      )}
    </Box>
  );
};

export default NotaryMeeting;
