/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SMSDTO
 */
export interface SMSDTO {
    /**
     * 
     * @type {number}
     * @memberof SMSDTO
     */
    phoneNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof SMSDTO
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof SMSDTO
     */
    email?: string;
}

/**
 * Check if a given object implements the SMSDTO interface.
 */
export function instanceOfSMSDTO(value: object): value is SMSDTO {
    return true;
}

export function SMSDTOFromJSON(json: any): SMSDTO {
    return SMSDTOFromJSONTyped(json, false);
}

export function SMSDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SMSDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'link': json['link'] == null ? undefined : json['link'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function SMSDTOToJSON(value?: SMSDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'phoneNumber': value['phoneNumber'],
        'link': value['link'],
        'email': value['email'],
    };
}

