/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteWitnessDTO
 */
export interface InviteWitnessDTO {
    /**
     * 
     * @type {number}
     * @memberof InviteWitnessDTO
     */
    sessionId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteWitnessDTO
     */
    witnessId: Array<string>;
}

/**
 * Check if a given object implements the InviteWitnessDTO interface.
 */
export function instanceOfInviteWitnessDTO(value: object): value is InviteWitnessDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('witnessId' in value) || value['witnessId'] === undefined) return false;
    return true;
}

export function InviteWitnessDTOFromJSON(json: any): InviteWitnessDTO {
    return InviteWitnessDTOFromJSONTyped(json, false);
}

export function InviteWitnessDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteWitnessDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'witnessId': json['witnessId'],
    };
}

export function InviteWitnessDTOToJSON(value?: InviteWitnessDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'witnessId': value['witnessId'],
    };
}

