/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VeriffResPost
 */
export interface VeriffResPost {
    /**
     * 
     * @type {string}
     * @memberof VeriffResPost
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof VeriffResPost
     */
    data: object;
    /**
     * 
     * @type {string}
     * @memberof VeriffResPost
     */
    verificationUrl: string;
    /**
     * 
     * @type {number}
     * @memberof VeriffResPost
     */
    kbaLogId: number;
}

/**
 * Check if a given object implements the VeriffResPost interface.
 */
export function instanceOfVeriffResPost(value: object): value is VeriffResPost {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('verificationUrl' in value) || value['verificationUrl'] === undefined) return false;
    if (!('kbaLogId' in value) || value['kbaLogId'] === undefined) return false;
    return true;
}

export function VeriffResPostFromJSON(json: any): VeriffResPost {
    return VeriffResPostFromJSONTyped(json, false);
}

export function VeriffResPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): VeriffResPost {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'data': json['data'],
        'verificationUrl': json['verificationUrl'],
        'kbaLogId': json['kbaLogId'],
    };
}

export function VeriffResPostToJSON(value?: VeriffResPost | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'data': value['data'],
        'verificationUrl': value['verificationUrl'],
        'kbaLogId': value['kbaLogId'],
    };
}

