import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Icons
import { FiEdit } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { TbFileInvoice } from "react-icons/tb";
import { AiOutlineHistory } from "react-icons/ai";
import { HiOutlineDocumentText as PlanIcon } from "react-icons/hi";

import Card from "../../component/Card";
import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import { businessPaths } from "../../routes/routes";
import useNotification from "../../utils/useNotification";
import { getBusinessDetails } from "../../utils/utils-apis";
import SupportModal from "../../component/commonComponents/SupportModal";
import SessionInfoList from "../../component/SessionsInfo/SessionInfoList";
import { useBusinessProfileDetailsStore } from "../../store/businessStore";
import DashboardCards from "../../component/commonComponents/DashboradData/DashboradCards/DashboardCards";
import DashboardGraphs from "../../component/commonComponents/DashboradData/DashboardGraphs/DashboardGraphs";

const BusinessDashboard = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState<number>(0);
  const { businessProfile, setBusinessProfile } =
    useBusinessProfileDetailsStore();

  useEffect(() => {
    if (localStorage.getItem("role") === null) {
      navigate("/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    getBusinessDetails(configuration)
      .then((result) => {
        setBusinessProfile({
          ...businessProfile,
          businessName:
            result.businessName ?? localStorage.getItem("businessName"),
          businessCity: result?.businessCity,
          businessPhone: result?.businessPhone?.toString(),
          businessWebsite: result?.businessWebsite,
          businessZip: result?.businessZip?.toString(),
          position: result?.position,
          state: result?.state,
          email: result?.email ?? localStorage.getItem("email"),
          businessId: result?.businessId,
        });
      })
      .catch(async (err) => {
        const message = await handleError(err);
        if (
          message.errorMessage === "Business not Found" ||
          message?.statusCode === 500
        ) {
          notification("warn", "Complete your Profile");
          setTimeout(() => {
            navigate("/business/profile");
          }, 1000);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flexButtonData = (title: string, link: string, icon: JSX.Element) => {
    return (
      <Button
        fontSize={"16px"}
        fontWeight={"normal"}
        color={"#D5AD36"}
        height={"40px"}
        _hover={{
          background:
            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
          color: "#fff",
        }}
        border={"1px solid #D5AD36"}
        backgroundColor={"white"}
        onClick={() => {
          if (link === "support") {
            onOpen();
          } else {
            navigate(link);
          }
        }}
      >
        {icon} <span style={{ marginLeft: "5px" }}>{title}</span>
      </Button>
    );
  };

  return (
    <>
      <Box style={{ padding: "0 1rem" }}>
        <Heading
          fontSize={{ base: "18px", md: "22px", lg: "22px" }}
          lineHeight="30px"
          textAlign={"center"}
          mb={6}
          fontFamily="Oswald"
          fontWeight={600}
        >
          Business Dashboard
        </Heading>

        <Card>
          <Text fontSize="20px" fontWeight={"bold"} mb={5}>
            My Shortcuts
          </Text>
          <Flex flexWrap={"wrap"} justifyContent={"flex-start"} gap={3}>
            {flexButtonData(
              "Invite a Client",
              `${businessPaths.createSession}/type`,
              <FiEdit />
            )}
            {flexButtonData(
              "Update Profile",
              "/business/profile",
              <CgProfile />
            )}
            {flexButtonData("Manage Plan", "/business/plans", <PlanIcon />)}
            {flexButtonData(
              "My Sessions",
              `${businessPaths.businessDocuments}`,
              <AiOutlineHistory />
            )}
            {flexButtonData(
              "Invoices",
              `${businessPaths.businessInvoices}`,
              <TbFileInvoice />
            )}
          </Flex>
        </Card>

        <DashboardCards
          totalInvoicesAmount={totalInvoicesAmount}
          from="business"
        />

        <DashboardGraphs
          onSetTotalInvoicesAmount={(value) => setTotalInvoicesAmount(value)}
        />

        <Box
          mt={8}
          style={{
            width: "100%",
            padding: "20px",
            boxShadow: "-1px 5px 15px 0px #ddd",
          }}
        >
          <Text fontSize="20px" fontWeight={"bold"} mb={6}>
            Most Recent Sessions
          </Text>
          <SessionInfoList from="dashboard" />
          <Text
            color={"#000080"}
            fontWeight={600}
            fontSize={"14px"}
            textAlign="right"
            textDecoration={"underline"}
            mt={3}
            role="button"
            onClick={() => navigate(`${businessPaths.businessDocuments}`)}
          >
            View More &gt;
          </Text>
        </Box>
      </Box>

      <SupportModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        name={businessProfile?.businessName}
        phoneNumber={businessProfile?.businessPhone}
      />
    </>
  );
};

export default BusinessDashboard;
