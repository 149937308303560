import { useEffect } from "react";
import BusinessSessionParticipants from "../../../../component/Sessions/Business/BusinessSessionParticipants";
import { useBusinessSessionStepStore } from "../../../../store/businessStore";

const BusinessSessionParticipantsPage = () => {
  const { setStep } = useBusinessSessionStepStore();

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  return <BusinessSessionParticipants />;
};

export default BusinessSessionParticipantsPage;
