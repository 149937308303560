/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  B64FileUploadDto,
  B64FileUploadResponseDto,
  FileS3UploadResponseDto,
  FileUploadResponseDto,
  S3UrlResponseDto,
} from '../models/index';
import {
    B64FileUploadDtoFromJSON,
    B64FileUploadDtoToJSON,
    B64FileUploadResponseDtoFromJSON,
    B64FileUploadResponseDtoToJSON,
    FileS3UploadResponseDtoFromJSON,
    FileS3UploadResponseDtoToJSON,
    FileUploadResponseDtoFromJSON,
    FileUploadResponseDtoToJSON,
    S3UrlResponseDtoFromJSON,
    S3UrlResponseDtoToJSON,
} from '../models/index';

export interface FileControllerUploadRequest {
    b64FileUploadDto: B64FileUploadDto;
}

export interface FileControllerUploadFileRequest {
    file: Blob;
}

export interface FileControllerUploadS3AnyFileToPdfRequest {
    file: Blob;
    blogId?: number;
}

export interface FileControllerUploadS3FileRequest {
    file: Blob;
    blogId?: number;
}

export interface FileControllerUrlS3Request {
    key: string;
}

/**
 * 
 */
export class FileUploadApi extends runtime.BaseAPI {

    /**
     */
    async fileControllerUploadRaw(requestParameters: FileControllerUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<B64FileUploadResponseDto>> {
        if (requestParameters['b64FileUploadDto'] == null) {
            throw new runtime.RequiredError(
                'b64FileUploadDto',
                'Required parameter "b64FileUploadDto" was null or undefined when calling fileControllerUpload().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/file-upload/uploadb64`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: B64FileUploadDtoToJSON(requestParameters['b64FileUploadDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => B64FileUploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerUpload(requestParameters: FileControllerUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<B64FileUploadResponseDto> {
        const response = await this.fileControllerUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fileControllerUploadFileRaw(requestParameters: FileControllerUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileUploadResponseDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling fileControllerUploadFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/file-upload/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileUploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerUploadFile(requestParameters: FileControllerUploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileUploadResponseDto> {
        const response = await this.fileControllerUploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fileControllerUploadS3AnyFileToPdfRaw(requestParameters: FileControllerUploadS3AnyFileToPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileS3UploadResponseDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling fileControllerUploadS3AnyFileToPdf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['blogId'] != null) {
            formParams.append('blogId', requestParameters['blogId'] as any);
        }

        const response = await this.request({
            path: `/file-upload/anyFileToPdf/uploadS3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileS3UploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerUploadS3AnyFileToPdf(requestParameters: FileControllerUploadS3AnyFileToPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileS3UploadResponseDto> {
        const response = await this.fileControllerUploadS3AnyFileToPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fileControllerUploadS3FileRaw(requestParameters: FileControllerUploadS3FileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileS3UploadResponseDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling fileControllerUploadS3File().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['blogId'] != null) {
            formParams.append('blogId', requestParameters['blogId'] as any);
        }

        const response = await this.request({
            path: `/file-upload/uploadS3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileS3UploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerUploadS3File(requestParameters: FileControllerUploadS3FileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileS3UploadResponseDto> {
        const response = await this.fileControllerUploadS3FileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fileControllerUrlS3Raw(requestParameters: FileControllerUrlS3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<S3UrlResponseDto>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling fileControllerUrlS3().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/file-upload/url-S3`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => S3UrlResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async fileControllerUrlS3(requestParameters: FileControllerUrlS3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<S3UrlResponseDto> {
        const response = await this.fileControllerUrlS3Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
