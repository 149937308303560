import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import html2canvas from "html2canvas";
import { FileUploadApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import OhioStamp from "../../SvgComponents/OhioStamp";
import TexasStamp from "../../SvgComponents/TexasStamp";
import FloridaStamp from "../../SvgComponents/FloridaStamp";
import NewYorkStamp from "../../SvgComponents/NewYorkStamp";
import ArizonaStamp from "../../SvgComponents/ArizonaStamp";
import VirginiaStamp from "../../SvgComponents/VirginiaStamp";
import OklahomaStamp from "../../SvgComponents/OklahomaStamp";
import TennesseStamp from "../../SvgComponents/TennesseStamp";
import NewJerseyStamp from "../../SvgComponents/NewJerseyStamp";
import WashingtonStamp from "../../SvgComponents/WashingtonStamp";
import PennsylvaniaStamp from "../../SvgComponents/PennsylvaniaStamp";
import { useProfileDetailsStore } from "../../../store/notaryStore";

const SealModal = ({
  isOpen,
  onClose,
  onOpen,
  onSetSealFile,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSetSealFile: (path: string) => void;
}) => {
  const { configuration } = useAuth();
  const [isSealUploading, setIsSealUploading] = useState(false);
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();

  const uploadStamp = (base64String: string) => {
    new FileUploadApi(configuration)
      .fileControllerUpload({
        b64FileUploadDto: {
          base64: base64String.split(",")[1],
          extension: "png",
        },
      })
      .then((res) => {
        setNotaryProfile({
          ...notaryProfile,
          seal: res?.path,
        });
        onSetSealFile(res?.path);
        onClose();
        setIsSealUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSealUploading(false);
      });
  };

  const handleUpload = async () => {
    setIsSealUploading(true);
    const options = {
      backgroundColor: "transparent",
    };
    const element = document.getElementById("stamp"),
      // @ts-ignore
      canvas = await html2canvas(element, options),
      data = canvas.toDataURL("image/png");
    uploadStamp(data);
  };

  const notaryName = `${notaryProfile?.firstName?.toUpperCase()} ${notaryProfile?.middleName?.toUpperCase()} ${notaryProfile?.lastName?.toUpperCase()} ${notaryProfile?.suffix?.toUpperCase()}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Create Seal
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <Divider />
        <ModalBody mt={2}>
          <Text mb={3} fontSize={"13px"}>
            We require to use a standard seal design on our platform.{" "}
            <span style={{ fontWeight: "bolder" }}>
              Please confirm the information in the seal below in correct. If
              the information is incorrect, go back and update your detais.
            </span>
          </Text>

          <Box id="stamp" width="-webkit-fit-content" mx={"auto"}>
            {notaryProfile.state === "FL" && (
              <FloridaStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "TX" && (
              <TexasStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "OH" && (
              <OhioStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "WA" && (
              <WashingtonStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "VA" && (
              <VirginiaStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "OK" && (
              <OklahomaStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "NY" && (
              <NewYorkStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "PA" && (
              <PennsylvaniaStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "NJ" && (
              <NewJerseyStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "TN" && (
              <TennesseStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}

            {notaryProfile.state === "AZ" && (
              <ArizonaStamp
                name={notaryName}
                commissionNumber={notaryProfile?.commissionNumber}
                expires={notaryProfile?.commissionExpirationDate}
                width="240"
                height="100"
              />
            )}
          </Box>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#D5AD36", color: "#fff" }}
            onClick={() => {
              handleUpload();
            }}
            isDisabled={isSealUploading}
          >
            Confirm and Apply
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SealModal;
