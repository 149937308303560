import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/use-auth";
import useCreateUser from "../../../hooks/useCreateUser";
import { createSession } from "../../../utils/utils-apis";
import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import useNotification from "../../../utils/useNotification";
import { initialSessionDetails, useKBAVerification, useSessionCreate } from "../../../store/store";
import StateSpecificNotary from "../../commonComponents/StateSpecificNotary/StateSpecificNotary";
import { signerPaths } from "../../../routes/routes";

const NotaryDetail = () => {
  const { configuration } = useAuth();
  const navigate = useNavigate();
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [showErr, setShowErr] = useState<boolean>(false);
  const sendSynchronously = useCreateUser();
  const { notification } = useNotification();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const {setWasKBACompleted} = useKBAVerification()
  const registerSigner = async (kbaLogId: string) => {
    setIsSubmit(true);
    const users = await sendSynchronously();
    createSession(
      sessionDetail,
      users,
      configuration,
      "signer",
      false,
      kbaLogId
    )
      .then((res) => {
        setSessionDetails(initialSessionDetails);
        notification("success", "Session Created Successfully");
        setWasKBACompleted(true);
        navigate(
          `/signer/on-demand/waiting-for-notary/?session-id=${res.sessionId}`
        );
        setIsSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmit(false);
        notification("fail", "Failed to create Session");
      });
  };

  return (
    <Stack spacing={2}>
      <Text
        fontSize={"22px"}
        textAlign={"center"}
        mb={"20px"}
        fontFamily={"oswald"}
        fontWeight={"600"}
        mt={"10px"}
      >
        Notary Details
      </Text>

      <StateSpecificNotary />

      {showErr && (
        <Text pt={5} color="red.500" fontSize={"14px"}>
          *You are not able to create a seesion with Arizona's Notary
        </Text>
      )}

      <Flex marginTop={5} justifyContent={"flex-end"}>
        <Button
          mx={2}
          style={{ padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            setShowErr(false);
            navigate(`${signerPaths.createSession}/payment`);
          }}
        >
          Prev
        </Button>

        <Button
          mx={2}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          width={"fit-content"}
          onClick={() => {
            if (sessionDetail?.specificNotaryState === "AZ") {
              setShowErr(true);
            } else {
              navigate(`${signerPaths.createSession}/signer-verification`);
              setShowErr(false);
            }
          }}
        >
          Next
        </Button>

        {process.env.REACT_APP_KBA_SKIP === "1" && (
          <Button
            mx={2}
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            width={"fit-content"}
            onClick={() => {
              if (sessionDetail?.specificNotaryState === "AZ") {
                setShowErr(true);
              } else {
                registerSigner("405");
                setShowErr(false);
              }
            }}
            isDisabled={isSubmit}
          >
            Skip KBA & Create Session
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

export default NotaryDetail;
