import {
  Button,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { CertificateResponseDTO, NotaryApi } from "../../../api";
import { IFileInfo } from "../../../store/zustandType";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { BsEye as EyeIcon, BsEyeSlash as EyeCloseIcon } from "react-icons/bs";

const PasscodeModal = ({
  isOpen,
  onClose,
  onOpen,
  file,
  onUpload,
  url,
  onHandleClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  file: IFileInfo;
  url?: string;
  onUpload: (res: CertificateResponseDTO, passcode: string) => void;
  onHandleClose: () => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [passcode, setPasscode] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const handleUpload = () => {
    new NotaryApi(configuration)
      .notaryControllerUploadCertificate({
        file: file,
        passcode: passcode,
      })
      .then((res) => {
        onUpload(res, passcode);
        onClose();
        setPasscode("");
        notification("success", "Uploaded Successfully");
      })
      .catch((err) => {
        console.log(err);
        onHandleClose();
        notification("fail", "Failed to Upload File");
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Document Passcode
          </Text>
        </ModalHeader>

        <ModalCloseButton onClick={onHandleClose} />
        <Divider />
        <ModalBody mt={2}>
          <Text mb={3} fontSize={"14px"}>
            Please enter passcode of your document.
          </Text>
          <InputGroup size="md" alignItems="center" mb="15px">
            <Input
              fontSize="sm"
              size="lg"
              type={show ? "text" : "password"}
              placeholder="Enter passcode"
              value={passcode}
              onChange={(e) => {
                setPasscode(e.target.value);
              }}
            />
            <InputRightElement pt={2}>
              {show ? (
                <EyeCloseIcon
                  size={20}
                  onClick={() => setShow(!show)}
                  role="button"
                />
              ) : (
                <EyeIcon
                  size={20}
                  onClick={() => setShow(!show)}
                  role="button"
                />
              )}
            </InputRightElement>
          </InputGroup>
        </ModalBody>

        <ModalFooter mb={3}>
          <Button
            style={{ backgroundColor: "#D5AD36", color: "#fff" }}
            onClick={() => {
              handleUpload();
            }}
            isDisabled={passcode === ""}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PasscodeModal;
