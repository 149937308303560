import { Box, Button, Center, Image, Text } from "@chakra-ui/react";
import BaseLayout from "../layout/BaseLayout";
import img from "../assets/images/payment.png";
import { useNavigate } from "react-router-dom";

const RequestSuccessPage = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  return (
    <BaseLayout>
      <Box textAlign={"center"} mt={40}>
        <Center>
          <Image src={img} height={"150px"} />
        </Center>
        <Text mt={6} fontWeight={600} fontSize="4xl">
          Thank you!
        </Text>

        <Text mt={4} fontWeight={600} fontSize="xl">
          Your request submitted successfully.
        </Text>

        <Button
          mt={5}
          fontSize={"16px"}
          fontWeight={"normal"}
          height={"40px"}
          _hover={{
            background:
              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
            color: "#fff",
          }}
          border={"1px solid #D5AD36"}
          backgroundColor={"#fff"}
          color={"#D5AD36"}
          onClick={() => {
            if (role === "notary") {
              navigate("/notary/plans");
            } else if (role === "business") {
              navigate("/business/plans");
            } else {
              console.log("role");
            }
          }}
        >
          Check Subscription
        </Button>
      </Box>
    </BaseLayout>
  );
};

export default RequestSuccessPage;
