/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDTO
 */
export interface SetDTO {
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    paymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof SetDTO
     */
    customerId: string;
}

/**
 * Check if a given object implements the SetDTO interface.
 */
export function instanceOfSetDTO(value: object): value is SetDTO {
    if (!('paymentMethodId' in value) || value['paymentMethodId'] === undefined) return false;
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    return true;
}

export function SetDTOFromJSON(json: any): SetDTO {
    return SetDTOFromJSONTyped(json, false);
}

export function SetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentMethodId': json['paymentMethodId'],
        'customerId': json['customerId'],
    };
}

export function SetDTOToJSON(value?: SetDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentMethodId': value['paymentMethodId'],
        'customerId': value['customerId'],
    };
}

