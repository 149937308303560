/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeCustomersResponseDTO
 */
export interface StripeCustomersResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof StripeCustomersResponseDTO
     */
    stripeCustomerId: number;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomersResponseDTO
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof StripeCustomersResponseDTO
     */
    email: string;
    /**
     * 
     * @type {object}
     * @memberof StripeCustomersResponseDTO
     */
    data: object;
    /**
     * 
     * @type {object}
     * @memberof StripeCustomersResponseDTO
     */
    paymentMethodId: object;
    /**
     * 
     * @type {object}
     * @memberof StripeCustomersResponseDTO
     */
    connectedAccountId: object;
    /**
     * 
     * @type {object}
     * @memberof StripeCustomersResponseDTO
     */
    notaryBankAccountId: object;
}

/**
 * Check if a given object implements the StripeCustomersResponseDTO interface.
 */
export function instanceOfStripeCustomersResponseDTO(value: object): value is StripeCustomersResponseDTO {
    if (!('stripeCustomerId' in value) || value['stripeCustomerId'] === undefined) return false;
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('paymentMethodId' in value) || value['paymentMethodId'] === undefined) return false;
    if (!('connectedAccountId' in value) || value['connectedAccountId'] === undefined) return false;
    if (!('notaryBankAccountId' in value) || value['notaryBankAccountId'] === undefined) return false;
    return true;
}

export function StripeCustomersResponseDTOFromJSON(json: any): StripeCustomersResponseDTO {
    return StripeCustomersResponseDTOFromJSONTyped(json, false);
}

export function StripeCustomersResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeCustomersResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'stripeCustomerId': json['stripeCustomerId'],
        'customerId': json['customerId'],
        'email': json['email'],
        'data': json['data'],
        'paymentMethodId': json['paymentMethodId'],
        'connectedAccountId': json['connectedAccountId'],
        'notaryBankAccountId': json['notaryBankAccountId'],
    };
}

export function StripeCustomersResponseDTOToJSON(value?: StripeCustomersResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripeCustomerId': value['stripeCustomerId'],
        'customerId': value['customerId'],
        'email': value['email'],
        'data': value['data'],
        'paymentMethodId': value['paymentMethodId'],
        'connectedAccountId': value['connectedAccountId'],
        'notaryBankAccountId': value['notaryBankAccountId'],
    };
}

