import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { ChangeEvent, useState } from "react";
import { AuthApi, ForgotPassswordDTO } from "../../api";
import { useAuth } from "../../utils/use-auth";
import { Link as RouterLink } from "react-router-dom";
import useNotification from "../../utils/useNotification";
import AuthBasic from "../../layout/AuthBasic";

const ForgotPassword = () => {
  const { notification } = useNotification();
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const [dataToSend, setDataToSend] = useState<ForgotPassswordDTO>({
    email: "",
    redirectUrl: "reset-password",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataToSend((prev) => ({
      ...prev,
      email: e.target.value,
    }));
  };

  const { configuration } = useAuth();
  const handleReset = () => {
    new AuthApi(configuration)
      .authControllerForgotPassword({
        forgotPassswordDTO: dataToSend,
      })
      .then((res) => {
        notification("success", "Email Sent Successfully!");
      })
      .catch((err) => {
        notification("fail", "Email not registerd");
      });
  };

  return (
    <AuthBasic title="" description="">
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <Text
            fontWeight="700"
            fontFamily="Oswald"
            color={textColor}
            textAlign="center"
            mb="10px"
            fontSize={{ base: "3xl", md: "4xl" }}
          >
            Reset password
          </Text>
          <Text
            fontWeight="400"
            fontFamily="Lato"
            textAlign="center"
            color="gray.400"
            mb="35px"
          >
            You will receive an email in maximum 60 seconds.
          </Text>
          <FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="400"
              fontFamily="Lato"
            >
              Email
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Your email address"
              mb="24px"
              size="lg"
              border="1px solid #E2E8F0"
              fontWeight="400"
              fontFamily="Lato"
              onChange={(e) => handleChange(e)}
            />
            <Button
              fontSize="14px"
              variant="dark"
              fontWeight="900"
              fontFamily="Lato"
              w="100%"
              h="45"
              mb="24px"
              bgGradient="linear(to-t,#D5AD3680,#D5AD36)"
              onClick={() => handleReset()}
            >
              SEND
            </Button>
          </FormControl>

          <RouterLink to="/sign-in">
            <Text
              textAlign="center"
              color="gray.400"
              fontWeight="400"
              fontFamily="Lato"
              textDecoration="underline"
            >
              Back to sign in page
            </Text>
          </RouterLink>
        </Flex>
      </Flex>
    </AuthBasic>
  );
};

export default ForgotPassword;
