import { Center, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSignerSessionStepStore } from "../../../store/signerStore";
import { getSignerDetails } from "../../../utils/utils-apis";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { useSessionCreate } from "../../../store/store";
import Steppers from "../../../component/commonComponents/Steppers";
import { signerPaths } from "../../../routes/routes";

const stepData = [
  { label: "Session Type", path: "type" },
  { label: "Document Upload", path: "document-upload" },
  { label: "Participants", path: "participants" },
  { label: "Payment Details", path: "payment" },
  { label: "Notary Detail", path: "notary-detail" },
  { label: "Verification", path: "signer-verification" },
  { label: "Session Connection", path: "signer-session-connecting" },
];

const SignerSessionCreate = () => {
  const role = localStorage.getItem("role");
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { step, setStep } = useSignerSessionStepStore();
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  useEffect(() => {
    if (role === "signer") {
      setLoading(true);
      getSignerDetails(configuration)
        .then((res) => {
          setSessionDetails({
            ...sessionDetail,
            signers: [
              {
                firstName: res?.firstName,
                lastName: res?.lastName,
                email: res?.email,
                phoneNumber: res?.phoneNumber as string,
                dob: res?.dob,
                addressLine1: res?.addressLine1 as string,
                addressLine2: res?.addressLine2,
                city: res?.city,
                lastFourDigitsSsnNumber: res?.lastFourDigitsSsnNumber,
                state: res?.state,
                zip: res?.zip?.toString(),
                kbaStatus: res?.kbaStatus,
              },
            ],
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          notification("warn", "Complete your Profile");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <>
      <Steppers
        from={`${signerPaths.createSession}`}
        step={step}
        stepData={stepData}
        onSetStep={(step) => setStep(step)}
      />
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default SignerSessionCreate;
