/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VeriffRes
 */
export interface VeriffRes {
    /**
     * 
     * @type {string}
     * @memberof VeriffRes
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof VeriffRes
     */
    data: object;
}

/**
 * Check if a given object implements the VeriffRes interface.
 */
export function instanceOfVeriffRes(value: object): value is VeriffRes {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function VeriffResFromJSON(json: any): VeriffRes {
    return VeriffResFromJSONTyped(json, false);
}

export function VeriffResFromJSONTyped(json: any, ignoreDiscriminator: boolean): VeriffRes {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'data': json['data'],
    };
}

export function VeriffResToJSON(value?: VeriffRes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'data': value['data'],
    };
}

