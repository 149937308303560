import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import { IMessageType } from "./zustandType";

interface IMessagesStore {
  messages: IMessageType[];
  newMsgCount: number;
  clearMessages: () => void;
  addCount: (count: number) => void;
  setCount: (count: number) => void;
  setMessages: (docs: IMessageType[]) => void;
  addMessage: (newDoc: IMessageType) => void;
  // removeMessage: (msgIndex: number) => void;
}

export const useMessagesStore = create<IMessagesStore>()(
  devtools(
    OpenReplay.createLogger("sessionMessages")(
      // @ts-ignore
      (set) => ({
        messages: [],
        newMsgCount: 0,
        setMessages: (messages: IMessageType[]) =>
          set((state: any) => ({
            ...state,
            messages,
          })),
        addMessage: (newMsg: IMessageType) =>
          set((state: any) => ({
            ...state,
            messages: [...state.messages, newMsg],
          })),
        clearMessages: () =>
          set({
            messages: [],
            newMsgCount: 0,
          }),
        addCount: (count: number) => {
          set((state: any) => ({
            ...state,
            newMsgCount: state.newMsgCount + count,
          }));
        },
        setCount: (count: number) => {
          set((state: any) => ({
            ...state,
            newMsgCount: count,
          }));
        },
        // removeMessage: (msgIndex: number) =>
        //   set((state:any) => ({
        //     ...state,
        //     messages: [
        //       ...state.messages.slice(0, msgIndex),
        //       ...state.messages.slice(msgIndex + 1),
        //     ],
        //   })),
      }),
      {
        name: "sessionMessages",
      }
    )
  )
);
