import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import { jwtDecoder } from "../utils/utils-functions";

const PrivateRoutes = () => {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access-token");
  const token = localStorage.getItem("accessToken");
  // const role = searchParams.get("role");
  if (token !== null) {
    const { userId, role } = jwtDecoder(token);
    localStorage.setItem("UserID", userId);
    localStorage.setItem("role", role);
  }
  if (accessToken !== null) {
    localStorage.setItem("accessToken", accessToken);
  }

  return token !== null || accessToken !== null ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateRoutes;
