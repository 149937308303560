import NotaryJournalNew from "../../../component/NotaryJournal/NotaryJournalNew";

const NotaryJournalPage = () => {
  return (
    <>
      <NotaryJournalNew />
    </>
  );
};

export default NotaryJournalPage;
