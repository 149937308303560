/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KbaType } from './KbaType';
import {
    KbaTypeFromJSON,
    KbaTypeFromJSONTyped,
    KbaTypeToJSON,
} from './KbaType';

/**
 * 
 * @export
 * @interface SessionUpdateDTO
 */
export interface SessionUpdateDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionUpdateDTO
     */
    documents?: Array<string>;
    /**
     * 
     * @type {Array<KbaType>}
     * @memberof SessionUpdateDTO
     */
    kbaLogs?: Array<KbaType>;
    /**
     * 
     * @type {number}
     * @memberof SessionUpdateDTO
     */
    witnessesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionUpdateDTO
     */
    mediators?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionUpdateDTO
     */
    notaryNote?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionUpdateDTO
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionUpdateDTO
     */
    terminateReason?: string;
}

/**
 * Check if a given object implements the SessionUpdateDTO interface.
 */
export function instanceOfSessionUpdateDTO(value: object): value is SessionUpdateDTO {
    return true;
}

export function SessionUpdateDTOFromJSON(json: any): SessionUpdateDTO {
    return SessionUpdateDTOFromJSONTyped(json, false);
}

export function SessionUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': json['documents'] == null ? undefined : json['documents'],
        'kbaLogs': json['kbaLogs'] == null ? undefined : ((json['kbaLogs'] as Array<any>).map(KbaTypeFromJSON)),
        'witnessesCount': json['witnessesCount'] == null ? undefined : json['witnessesCount'],
        'mediators': json['mediators'] == null ? undefined : json['mediators'],
        'notaryNote': json['notaryNote'] == null ? undefined : json['notaryNote'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'terminateReason': json['terminateReason'] == null ? undefined : json['terminateReason'],
    };
}

export function SessionUpdateDTOToJSON(value?: SessionUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': value['documents'],
        'kbaLogs': value['kbaLogs'] == null ? undefined : ((value['kbaLogs'] as Array<any>).map(KbaTypeToJSON)),
        'witnessesCount': value['witnessesCount'],
        'mediators': value['mediators'],
        'notaryNote': value['notaryNote'],
        'isInhouseNotary': value['isInhouseNotary'],
        'terminateReason': value['terminateReason'],
    };
}

