import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsEye as ViewIcon } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { Session, SignedPdfDTO } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl } from "../../../utils/utils-apis";
import PreviewModal from "../PreviewModal";

const DocumentsData = ({
  signedDocPath,
  session,
  idx,
}: {
  signedDocPath: SignedPdfDTO;
  session?: Session;
  idx: number;
}) => {
  const { configuration } = useAuth();
  const [from, setFrom] = useState<string>("");
  const [signedDocUrl, setSignedDocUrl] = useState<string>("");
  const [auitLogUrl, setAuditLogUrl] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getFileUrl(configuration, signedDocPath?.pdfKey).then((url) => {
      setSignedDocUrl(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedDocPath?.pdfKey]);

  useEffect(() => {
    getFileUrl(configuration, signedDocPath?.auditKey).then((url) => {
      setAuditLogUrl(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedDocPath?.auditKey]);

  const documentName = session?.documents?.find(
    (item) => item.docId === Number(signedDocPath?.docId)
  )?.name;

  return (
    <>
      <Box>
        <Text
          fontWeight={600}
          fontSize={"16px"}
          textDecoration="underline"
          mb={2}
        >
          Document {idx + 1}
        </Text>

        <Flex justify={"space-between"} align={"center"}>
          <Box width={"50%"} borderRight={"1px solid #e2e8f0"}>
            <Box>
              <Text fontSize="sm" fontWeight={400}>
                {documentName ?? "Notarized Document"}
              </Text>
            </Box>
            {signedDocPath?.pdfKey !== "" ? (
              <Flex mt={3}>
                <Button
                  w={24}
                  mr={3}
                  onClick={() => {
                    setFrom("signedDoc");
                    onOpen();
                  }}
                >
                  <ViewIcon /> <Text ml={2}>View</Text>
                </Button>
                <Button
                  fontSize={"sm"}
                  style={{
                    minWidth: "80px",
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                  }}
                  onClick={() => window.open(signedDocUrl, "_blank")}
                >
                  <FiDownload /> <Text ml={2}>Download</Text>
                </Button>
              </Flex>
            ) : (
              <Text
                fontSize={"10px"}
                fontWeight={400}
                w={"90%"}
                mt={2}
                fontStyle="italic"
              >
                (No signed document available for this session)
              </Text>
            )}
          </Box>

          <Box>
            <Box>
              <Text fontSize={"sm"} fontWeight={400}>
                Audit Logs
              </Text>
            </Box>
            {signedDocPath?.auditKey !== "" ? (
              <Flex mt={3}>
                <Button
                  w={24}
                  mr={3}
                  onClick={() => {
                    setFrom("auditLog");
                    onOpen();
                  }}
                >
                  <ViewIcon /> <Text ml={2}>View</Text>
                </Button>
                <Button
                  fontSize={"sm"}
                  style={{
                    minWidth: "80px",
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                  }}
                  onClick={() => window.open(auitLogUrl, "_blank")}
                >
                  <FiDownload /> <Text ml={2}>Download</Text>
                </Button>
              </Flex>
            ) : (
              <Text
                fontSize={"10px"}
                fontWeight={400}
                w={"90%"}
                mt={2}
                fontStyle="italic"
              >
                (No audit logs document available for this session)
              </Text>
            )}
          </Box>
        </Flex>
        <Divider my={3} />
      </Box>

      <PreviewModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        url={from === "auditLog" ? auitLogUrl : signedDocUrl}
        type={[".pdf"]}
      />
    </>
  );
};

export default DocumentsData;
