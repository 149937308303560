/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Recordings } from './Recordings';
import {
    RecordingsFromJSON,
    RecordingsFromJSONTyped,
    RecordingsToJSON,
} from './Recordings';

/**
 * 
 * @export
 * @interface RecordingPaginationResponse
 */
export interface RecordingPaginationResponse {
    /**
     * 
     * @type {Array<Recordings>}
     * @memberof RecordingPaginationResponse
     */
    items: Array<Recordings>;
    /**
     * 
     * @type {object}
     * @memberof RecordingPaginationResponse
     */
    meta: object;
    /**
     * 
     * @type {object}
     * @memberof RecordingPaginationResponse
     */
    links: object;
}

/**
 * Check if a given object implements the RecordingPaginationResponse interface.
 */
export function instanceOfRecordingPaginationResponse(value: object): value is RecordingPaginationResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('meta' in value) || value['meta'] === undefined) return false;
    if (!('links' in value) || value['links'] === undefined) return false;
    return true;
}

export function RecordingPaginationResponseFromJSON(json: any): RecordingPaginationResponse {
    return RecordingPaginationResponseFromJSONTyped(json, false);
}

export function RecordingPaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingPaginationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(RecordingsFromJSON)),
        'meta': json['meta'],
        'links': json['links'],
    };
}

export function RecordingPaginationResponseToJSON(value?: RecordingPaginationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(RecordingsToJSON)),
        'meta': value['meta'],
        'links': value['links'],
    };
}

