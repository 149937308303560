import { useEffect } from "react";
import SessionPayment from "../../../../component/Sessions/Signer/SessionPayment";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SessionPaymentPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(3);
  }, [setStep]);

  return <SessionPayment />;
};

export default SessionPaymentPage;
