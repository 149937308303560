import {
  Box,
  Button,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/use-auth";
import { useSessionCreate } from "../../../store/store";
import { INotaryOptions } from "../../../store/zustandType";
import { getBusinessDetails } from "../../../utils/utils-apis";
import ScheduleMeetingData from "../components/ScheduleMeetingData";
import StateSpecificNotary from "../../commonComponents/StateSpecificNotary/StateSpecificNotary";
import { businessPaths } from "../../../routes/routes";

const BusinessScheduleMeeting = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [selected, setSelected] = useState<string>("onDemand");
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [notaryOptions, setNotaryOptions] = useState<
    INotaryOptions[] | undefined
  >([]);

  useEffect(() => {
    getBusinessDetails(configuration)
      .then((res) => {
        setNotaryOptions(
          res?.inhouseNotaries?.map((el) => ({
            label: `${el.firstName} ${el.middleName} ${el.lastName} ${el.suffix}`,
            value: el.notaryId,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>
      <Text
        fontSize={"22px"}
        textAlign={"center"}
        mb={"20px"}
        fontFamily={"oswald"}
        fontWeight={"600"}
      >
        Session Details
      </Text>

      <ScheduleMeetingData />

      <Divider pt={4} />

      <Box pt={5}>
        <Text fontWeight={600}>Select Notary Assignment</Text>
        <RadioGroup
          value={selected}
          onChange={(value) => setSelected(value)}
          mt={3}
        >
          <Stack direction="row" gap={3}>
            <Radio value="onDemand">On-Demand</Radio>
            <Radio value="inHouse">In-house Notary</Radio>
          </Stack>
        </RadioGroup>
      </Box>

      <Divider pt={4} />

      <Box pt={5}>
        {selected === "inHouse" ? (
          <Box>
            <Text fontWeight={600}>Select your In-house notary</Text>

            <Box width={"300px"} mt={3}>
              <Select
                placeholder="Select Notary"
                options={notaryOptions}
                value={
                  notaryOptions?.find(
                    (item) => item.value === Number(sessionDetail.notaryId)
                  ) || null
                }
                onChange={(option) => {
                  setSessionDetails({
                    ...sessionDetail,
                    notaryId: option?.value?.toString(),
                  });
                }}
              />
            </Box>
          </Box>
        ) : (
          <StateSpecificNotary />
        )}
      </Box>

      <Flex justifyContent={"flex-end"}>
        <Button
          marginTop={5}
          width={"fit-content"}
          alignSelf={"end"}
          style={{ padding: "10px 30px" }}
          onClick={() => {
            navigate(`${businessPaths.createSession}/participants`);
          }}
        >
          Prev
        </Button>
        <Button
          marginTop={5}
          ml={2}
          style={{ background: "#2D3748", color: "#fff", padding: "10px 30px" }}
          width={"fit-content"}
          alignSelf={"end"}
          onClick={() => {
            navigate(`${businessPaths.createSession}/payment`);
          }}
        >
          Next
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessScheduleMeeting;
