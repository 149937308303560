import { Heading, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { SubscriptionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import ActiveProfileModal from "../../Notary/Sessions/Modals/ActiveProfileModal";
import ActiveSessions from "../../../component/commonComponents/ActiveSessions";

const BusinessActiveSession = () => {
  const { configuration } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        onOpen();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ActiveProfileModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        showDataFor={"activePlan"}
        path="/business/plans"
      />
      <Stack spacing={6} mx={"auto"} w={"100%"}>
        <Heading
          fontSize={"22px"}
          lineHeight="30px"
          textAlign={"center"}
          mb={4}
          fontFamily="Oswald"
          fontWeight={600}
          color={"#2D3748"}
        >
          Active Sessions
        </Heading>
        <Text
          fontSize={"16px"}
          fontFamily={"Lato"}
          fontWeight={"400"}
          mb={"40px"}
        >
          Your sessions will appear in each queue below. To start the session,
          simply click on the join button as it appears in the designated queue.
        </Text>
        <ActiveSessions from="business" />
      </Stack>
    </>
  );
};

export default BusinessActiveSession;
