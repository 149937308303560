import { useEffect, useMemo, useRef, useState } from "react";
import { io } from "socket.io-client";
import { Session } from "../api";
import { WS_URL } from "./constants";
// @ts-ignore
import newSessionAudio from "../assets/newSessionAudio.mp3";

const useWebSocket = () => {
  const notaryId = localStorage.getItem("notaryId");
  const role = localStorage.getItem("role");
  const audioElement = new Audio(newSessionAudio);

  const availableSessionsRef = useRef<Session[]>([]);
  const availableWitnessSessionsRef = useRef<Session[]>([]);
  const availableBusinessSessionsRef = useRef<Session[]>([]);
  const availableTitleLenderSessionsRef = useRef<Session[]>([]);

  const [availableSessions, setAvailableSessions] = useState<Session[]>([]);
  const [availableBusinessSessions, setAvailableBusinessSessions] = useState<
    Session[]
  >([]);
  const [availableTitleLenderSessions, setAvailableTitleLenderSessions] =
    useState<Session[]>([]);
  const [availableWitnessSessions, setAvailableWitnessSessions] = useState<
    Session[]
  >([]);

  //notary all sessions
  const listener = ({ session }: { session: Session }) => {
    const index = availableSessionsRef.current?.findIndex(
      (s) => s?.sessionId === session?.sessionId
    );
    if (index === -1) {
      if (role === "notary") {
        audioElement.play();
      }
      setAvailableSessions((prev) => [...prev, session]);
      availableSessionsRef.current = [...availableSessionsRef.current, session];
    }
  };

  const updateListener = ({ session }: { session: Session }) => {
    setAvailableSessions(
      availableSessions.filter((s) => s.sessionId !== session?.sessionId)
    );
    availableSessionsRef.current = availableSessionsRef.current.filter(
      (s) => s.sessionId !== session.sessionId
    );

    setAvailableTitleLenderSessions(
      availableTitleLenderSessions.filter(
        (s) => s.sessionId !== session?.sessionId
      )
    );
    availableTitleLenderSessionsRef.current =
      availableTitleLenderSessionsRef.current.filter(
        (s) => s.sessionId !== session.sessionId
      );

    setAvailableBusinessSessions(
      availableSessions.filter((s) => s.sessionId !== session?.sessionId)
    );
    availableBusinessSessionsRef.current =
      availableBusinessSessionsRef.current.filter(
        (s) => s.sessionId !== session.sessionId
      );
  };

  // witness sessions
  const newWitnessSessionsListener = ({ session }: { session: Session }) => {
    // @ts-ignore
    const isNotary = session?.notaryId === Number(notaryId);
    if (!isNotary) {
      if (role === "notary") {
        audioElement.play();
      }
      const index = availableWitnessSessionsRef.current?.findIndex(
        (el) => el.sessionId === session.sessionId
      );
      if (index === -1) {
        setAvailableWitnessSessions((prev) => [...prev, session]);
        availableWitnessSessionsRef.current = [
          ...availableWitnessSessionsRef.current,
          session,
        ];
      } else {
        const result = availableWitnessSessionsRef.current?.map((item) =>
          item?.sessionId === session.sessionId ? session : item
        );
        setAvailableSessions(result);
        availableWitnessSessionsRef.current = result;
      }
    }
  };

  const bookedWitnessSessionsListener = ({ session }: { session: Session }) => {
    setAvailableWitnessSessions(
      availableWitnessSessions.filter((s) => s.sessionId !== session.sessionId)
    );
    availableWitnessSessionsRef.current =
      availableWitnessSessionsRef.current.filter(
        (s) => s.sessionId !== session.sessionId
      );
  };

  //notary all sessions
  const titleLenderSessionslistener = ({ session }: { session: Session }) => {
    const index = availableTitleLenderSessionsRef.current?.findIndex(
      (s) => s?.sessionId === session?.sessionId
    );

    if (index === -1) {
      if (role === "notary") {
        audioElement.play();
      }
      setAvailableTitleLenderSessions((prev) => [...prev, session]);
      availableTitleLenderSessionsRef.current = [
        ...availableTitleLenderSessionsRef.current,
        session,
      ];
    }
  };

  // business sessions
  const businessSessionlistener = ({ session }: { session: Session }) => {
    const index = availableBusinessSessionsRef.current?.findIndex(
      (s) => s?.sessionId === session?.sessionId
    );
    if (index === -1) {
      if (role === "notary") {
        audioElement.play();
      }
      setAvailableBusinessSessions((prev) => [...prev, session]);
      availableBusinessSessionsRef.current = [
        ...availableBusinessSessionsRef.current,
        session,
      ];
    }
  };

  const socket = useMemo(() => {
    return io(WS_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
    });
  }, []);

  useEffect(() => {
    if (!socket) return;
    //notary all sessions
    socket.emit("getSessions", (sessions: Session[]) => {
      setAvailableSessions(sessions);
      availableSessionsRef.current = sessions;
    });

    //witness sessions
    socket.emit("witnessSessions", (sessions: Session[]) => {
      setAvailableWitnessSessions(sessions);
      availableWitnessSessionsRef.current = sessions;
    });

    //title Lander sessions
    socket.emit("getTitleLenderSessions", (sessions: Session[]) => {
      setAvailableTitleLenderSessions(sessions);
      availableTitleLenderSessionsRef.current = sessions;
    });

    //business sessions
    socket.emit("getBusinessSessions", (sessions: Session[]) => {
      setAvailableBusinessSessions(sessions);
      availableBusinessSessionsRef.current = sessions;
    });

    //notary all sessions
    socket.on("newSession", listener);
    socket.on("bookSession", updateListener);

    //witness sessions
    socket.on("newWitnessSession", newWitnessSessionsListener);
    socket.on("bookedWitnessSession", bookedWitnessSessionsListener);

    //title lander sessions
    socket.on("newTitleLenderSession", titleLenderSessionslistener);

    //business sessions
    socket.on("newBusinessSession", businessSessionlistener);

    return () => {
      //notary all sessions
      socket.removeListener("newSession", listener);
      socket.removeListener("bookSession", updateListener);

      //witness sessions
      socket.removeListener("newWitnessSession", newWitnessSessionsListener);
      socket.removeListener(
        "bookedWitnessSession",
        bookedWitnessSessionsListener
      );

      //title lander sessions
      socket.removeListener(
        "newTitleLenderSession",
        titleLenderSessionslistener
      );

      //business sessions
      socket.removeListener("newBusinessSession", businessSessionlistener);

      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return {
    socket,
    availableSessions,
    availableWitnessSessions,
    availableSessionsRef,
    availableWitnessSessionsRef,
    availableTitleLenderSessionsRef,
    availableBusinessSessionsRef,
  };
};

export default useWebSocket;
