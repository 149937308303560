import moment from "moment";
import React from "react";
import { useProfileDetailsStore } from "../../store/notaryStore";

const TexasStamp = ({ name, height, width, commissionNumber, expires }: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 600.000000 240.000000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* ****************************** Name ******************************  */}

      {name.length > 22 ? (
          <>
            <text
              x="70%"
              y="11%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"24px"}
              fontWeight="bold"
              letterSpacing="0.7"
            >
              {notaryProfile.firstName} {notaryProfile.middleName}
            </text>
            <text
              x="70%"
              y="25%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"24px"}
              fontWeight="bold"
              letterSpacing="0.7"
            >
              {notaryProfile.lastName} {notaryProfile.suffix}
            </text>
          </>
        ) : (
          <text
            x="70%"
            y="23%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"26px"}
            fontWeight="bold"
            letterSpacing="0.7"
            // fill="#F0F1F2"
          >
            {name}
          </text>
        )}

      {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

      <text
        x="70%"
        y="39%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"22px"}
        fontWeight="bold"
      >
        Notary Public - State of Texas
      </text>

      {/* ************************************ line ****************************************************** */}

      <line x1="580" y1="120" x2="250" y2="120" stroke="#000000" />

      {/* ****************************** Text -commission ******************************  */}

      <text
        x="70%"
        y="62%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"22px"}
        fontWeight="bold"
      >
        Commission #{commissionNumber}
      </text>

      {/* *************************************** Text -Expires ******************************************** */}

      <text
        x="70%"
        y="76%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"22px"}
        fontWeight="bold"
      >
        Expires on {moment(expires).format("MMM DD, YYYY")}
      </text>

      <g
        transform="translate(0.000000,240.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M0 2375 c0 -28 14 -32 36 -11 21 22 17 36 -11 36 -20 0 -25 -5 -25
-25z m30 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"
        />
        <path
          d="M70 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M110 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M150 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M190 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M230 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M270 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M310 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M350 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M390 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M430 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M470 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M510 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M550 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M590 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M630 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M670 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M710 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M750 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M790 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M830 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M870 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M910 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M950 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M990 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1030 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1070 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1110 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1150 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1190 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1230 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path d="M1271 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1311 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1351 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1391 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1431 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1471 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1511 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1551 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1591 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1631 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M1660 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1700 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1740 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1780 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1820 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1860 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1900 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1940 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1980 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2020 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2060 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2100 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2140 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2180 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2220 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2260 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2300 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2340 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2380 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2420 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2460 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2500 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2540 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2580 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2620 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2660 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2700 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2740 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2780 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2820 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2860 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2900 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2940 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2980 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3020 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3060 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3100 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3140 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3180 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3220 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3260 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3300 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3340 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3380 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3420 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3460 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3500 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3540 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3580 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3620 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3660 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3700 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3740 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3780 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3820 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3860 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3900 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3940 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3980 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4020 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4060 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4100 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4140 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4180 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4220 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path d="M4261 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4301 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4341 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4381 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4421 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4461 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4501 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4541 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4581 2384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M4610 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4650 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4690 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4730 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4770 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4810 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4850 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4890 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4930 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4970 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5010 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5050 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5090 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5130 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5170 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5210 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5250 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5290 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5330 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5370 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5410 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5450 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5490 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5530 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5570 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5610 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5650 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5690 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5730 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5770 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5810 2385 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5850 2389 c0 -14 25 -39 39 -39 6 0 11 11 11 25 0 20 -5 25 -25 25
-14 0 -25 -5 -25 -11z m40 -9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M0 2320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 2320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 2280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 2280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 2240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 2240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 2200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 2200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 2160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path d="M1140 2140 c0 -23 4 -30 20 -30 22 0 23 3 14 38 -9 33 -34 28 -34 -8z" />
        <path
          d="M1190 2140 c0 -23 4 -30 20 -30 16 0 20 7 20 30 0 23 -4 30 -20 30
-16 0 -20 -7 -20 -30z"
        />
        <path
          d="M1247 2163 c-4 -3 -7 -17 -7 -30 0 -16 6 -23 20 -23 16 0 20 7 20 30
0 28 -17 40 -33 23z"
        />
        <path
          d="M1300 2140 c0 -35 25 -41 33 -8 7 26 1 38 -19 38 -9 0 -14 -11 -14
-30z"
        />
        <path d="M1354 2155 c-9 -22 0 -50 16 -50 21 0 26 51 5 59 -9 3 -18 0 -21 -9z" />
        <path
          d="M5870 2160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1080 2146 c0 -25 12 -46 26 -46 9 0 14 11 14 30 0 23 -4 30 -20 30
-11 0 -20 -6 -20 -14z"
        />
        <path
          d="M1030 2120 c0 -23 4 -30 20 -30 20 0 25 15 14 44 -11 28 -34 18 -34
-14z"
        />
        <path
          d="M1405 2129 c-5 -14 -2 -25 6 -30 20 -13 29 -11 29 5 0 8 3 21 6 30 4
11 0 16 -14 16 -12 0 -23 -9 -27 -21z"
        />
        <path
          d="M1459 2123 c-7 -18 -6 -30 1 -37 12 -12 25 -1 34 30 10 34 -22 40
-35 7z"
        />
        <path
          d="M978 2135 c-3 -3 -3 -17 0 -30 5 -27 42 -36 42 -11 0 35 -24 59 -42
41z"
        />
        <path
          d="M0 2120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1512 2105 c-10 -21 -9 -27 4 -32 20 -7 37 10 32 33 -5 27 -23 27
-36 -1z"
        />
        <path
          d="M5870 2120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M925 2098 c10 -32 18 -39 34 -29 19 12 4 51 -20 51 -16 0 -19 -4 -14
-22z"
        />
        <path
          d="M883 2102 c-18 -12 -4 -52 18 -52 22 0 25 19 7 43 -8 10 -18 13 -25
9z"
        />
        <path
          d="M1562 2086 c-11 -25 -7 -36 13 -36 14 0 37 45 28 54 -13 13 -30 5
-41 -18z"
        />
        <path
          d="M0 2080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1610 2070 c-12 -23 -6 -40 15 -40 16 0 29 35 20 50 -10 16 -23 12
-35 -10z"
        />
        <path
          d="M5870 2080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M820 2067 c0 -22 24 -47 37 -39 18 12 4 52 -18 52 -11 0 -19 -6 -19
-13z"
        />
        <path
          d="M1666 2048 c-20 -28 -20 -34 -2 -41 8 -3 21 4 30 18 13 20 13 25 2
35 -11 8 -18 6 -30 -12z"
        />
        <path d="M775 2050 c-9 -14 13 -50 30 -50 16 0 19 18 9 44 -7 18 -30 21 -39 6z" />
        <path
          d="M0 2040 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 2040 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1702 2016 c-9 -20 -8 -25 5 -30 16 -7 45 21 39 39 -6 20 -33 15 -44
-9z"
        />
        <path
          d="M727 2024 c-6 -7 13 -54 22 -54 3 0 12 4 19 9 12 7 12 12 1 30 -14
21 -30 27 -42 15z"
        />
        <path
          d="M1130 2014 c0 -4 9 -23 19 -43 11 -20 22 -49 26 -65 7 -35 16 -28 31
27 7 23 15 52 19 65 11 33 -13 28 -26 -5 l-10 -28 -21 28 c-18 25 -38 36 -38
21z"
        />
        <path
          d="M0 2000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1337 2004 c-2 -5 -8 -35 -11 -66 -5 -42 -4 -58 4 -58 7 0 14 9 17
20 4 14 14 20 32 20 45 0 69 43 38 69 -19 15 -73 25 -80 15z m63 -44 c0 -13
-7 -20 -18 -20 -24 0 -32 8 -25 26 8 22 43 17 43 -6z"
        />
        <path
          d="M1752 1989 c-10 -16 -11 -24 -3 -32 14 -14 57 25 47 42 -11 17 -30
13 -44 -10z"
        />
        <path
          d="M5870 2000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M685 1990 c-7 -12 12 -50 26 -50 16 0 22 29 9 45 -14 17 -26 19 -35
5z"
        />
        <path
          d="M1028 1987 c-42 -10 -43 -12 -25 -81 15 -56 44 -80 34 -28 -9 42 25
51 52 14 24 -33 39 -22 21 15 -7 16 -12 38 -11 50 3 30 -25 42 -71 30z m42
-31 c0 -15 -37 -32 -47 -22 -3 3 -3 12 0 21 8 19 47 20 47 1z"
        />
        <path
          d="M1464 1925 c-16 -50 -16 -57 -3 -71 9 -8 28 -16 44 -17 22 -2 32 5
46 28 10 17 21 42 24 58 12 45 -19 33 -35 -15 -11 -31 -21 -44 -37 -46 -28 -4
-30 18 -8 71 13 33 12 47 -6 47 -4 0 -16 -25 -25 -55z"
        />
        <path
          d="M1800 1965 c-13 -16 -7 -45 9 -45 14 0 33 38 26 50 -9 14 -21 12 -35
-5z"
        />

        <path
          d="M0 1960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />

        <path
          d="M5870 1960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1841 1929 c-23 -25 -14 -46 14 -36 20 8 30 36 17 49 -6 6 -17 2 -31
-13z"
        />
        <path
          d="M602 1928 c-14 -18 11 -53 33 -45 20 8 19 20 -4 41 -14 13 -20 14
-29 4z"
        />
        <path
          d="M874 1894 c3 -26 6 -57 6 -70 0 -15 6 -24 15 -24 8 0 15 9 15 19 0
23 23 33 56 25 13 -3 24 -2 24 2 0 17 -85 94 -103 94 -18 0 -19 -5 -13 -46z
m50 -25 c-10 -16 -24 -9 -24 12 0 18 2 19 15 9 8 -7 12 -16 9 -21z"
        />
        <path
          d="M0 1920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />

        <path
          d="M1595 1855 c-29 -54 -30 -54 -10 -70 46 -35 95 -27 95 16 0 11 4 19
9 19 5 0 12 8 16 18 4 12 -3 25 -26 45 -18 15 -38 27 -44 27 -6 0 -24 -25 -40
-55z m85 -5 c0 -13 -37 -13 -45 0 -3 6 -3 15 0 20 7 12 45 -5 45 -20z m-31
-34 c8 -10 8 -16 -2 -25 -17 -13 -54 10 -43 27 10 16 31 15 45 -2z"
        />
        <path
          d="M1876 1891 c-15 -16 -15 -19 -1 -31 13 -11 19 -11 32 3 15 15 12 47
-5 47 -5 0 -16 -8 -26 -19z"
        />
        <path
          d="M554 1889 c-3 -6 3 -21 15 -32 16 -16 23 -19 33 -9 9 9 7 16 -7 32
-20 23 -31 25 -41 9z"
        />
        <path
          d="M762 1872 c-38 -32 -41 -45 -9 -37 19 5 29 -1 51 -29 15 -20 31 -36
37 -36 17 0 8 25 -22 60 -26 30 -27 36 -14 46 8 6 15 14 15 18 0 14 -27 4 -58
-22z"
        />
        <path
          d="M0 1880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1916 1851 c-14 -15 -14 -20 -3 -30 11 -8 18 -7 31 8 10 11 15 25 11
30 -9 16 -20 14 -39 -8z"
        />
        <path
          d="M525 1849 c-4 -5 1 -19 11 -30 13 -15 20 -16 31 -8 11 10 11 15 -3
30 -19 22 -30 24 -39 8z"
        />
        <path
          d="M0 1840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path d="M4110 1826 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
        <path
          d="M1170 1815 c-15 -18 -6 -45 13 -45 8 0 20 7 27 15 10 12 10 18 0 30
-16 19 -24 19 -40 0z"
        />
        <path
          d="M1242 1817 c-9 -10 -9 -18 1 -31 17 -23 49 -11 45 18 -4 25 -30 32
-46 13z"
        />
        <path
          d="M1722 1787 c-23 -24 -42 -47 -41 -52 0 -14 59 -59 66 -51 4 3 -3 17
-15 29 l-22 23 26 25 c32 30 47 56 36 63 -5 3 -27 -14 -50 -37z"
        />
        <path
          d="M1956 1815 c-18 -14 -17 -45 2 -45 15 0 44 38 37 49 -8 14 -16 13
-39 -4z"
        />
        <path
          d="M483 1805 c-5 -14 21 -44 39 -45 17 0 18 23 2 41 -21 23 -33 24 -41
4z"
        />
        <path
          d="M1090 1804 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M1314 1805 c-8 -20 4 -35 27 -35 14 0 19 7 19 25 0 18 -5 25 -20 25
-11 0 -23 -7 -26 -15z"
        />
        <path
          d="M0 1800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />

        <path
          d="M660 1780 c-29 -29 -25 -64 11 -94 36 -31 50 -32 77 -8 34 29 36 56
6 91 -30 36 -65 40 -94 11z m74 -26 c18 -18 21 -50 5 -59 -17 -11 -58 4 -69
24 -23 43 29 71 64 35z"
        />
        <path
          d="M1384 1785 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M1014 1775 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M1986 1775 c-17 -13 -17 -45 1 -45 19 1 43 29 37 44 -7 19 -13 20
-38 1z"
        />
        <path
          d="M453 1765 c-7 -19 22 -48 42 -42 17 6 11 29 -12 44 -21 14 -23 14
-30 -2z"
        />
        <path
          d="M1460 1765 c-11 -13 -11 -18 2 -31 20 -20 50 -7 46 20 -4 26 -30 32
-48 11z"
        />
        <path
          d="M0 1760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M950 1755 c-7 -8 -10 -22 -6 -30 7 -20 42 -19 50 1 8 22 8 22 -13 33
-14 8 -22 7 -31 -4z"
        />
        <path
          d="M5870 1760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1531 1736 c-15 -18 -3 -46 19 -46 10 0 21 7 24 16 6 16 -9 44 -24
44 -4 0 -13 -6 -19 -14z"
        />
        <path
          d="M1805 1705 c-33 -29 -47 -47 -40 -54 7 -6 27 6 59 35 65 60 47 77
-19 19z"
        />
        <path
          d="M2014 1726 c-14 -11 -16 -18 -8 -26 15 -15 54 4 54 25 0 19 -21 19
-46 1z"
        />
        <path
          d="M0 1720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M425 1720 c-8 -13 13 -40 31 -40 22 0 28 22 9 37 -23 16 -32 16 -40
3z"
        />
        <path
          d="M884 1715 c-9 -23 4 -47 23 -43 22 4 30 38 11 49 -19 13 -28 11 -34
-6z"
        />
        <path
          d="M5870 1720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1597 1694 c-12 -12 -8 -42 6 -47 21 -8 39 8 35 29 -3 19 -29 30 -41
18z"
        />
        <path
          d="M2053 1687 c-24 -10 -30 -31 -13 -42 14 -8 50 13 50 30 0 20 -12 24
-37 12z"
        />
        <path
          d="M0 1680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M396 1673 c-7 -19 19 -46 39 -40 17 6 11 29 -11 44 -19 12 -22 12
-28 -4z"
        />
        <path
          d="M580 1681 c0 -6 14 -18 30 -28 17 -9 30 -20 30 -23 0 -3 -21 -3 -46
-1 -71 5 -75 -16 -12 -56 29 -18 56 -33 60 -33 18 0 6 20 -22 37 -42 25 -37
34 16 31 63 -4 66 13 10 51 -46 32 -66 38 -66 22z"
        />
        <path
          d="M5870 1680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M817 1667 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
-11z"
        />
        <path
          d="M1836 1649 c-15 -12 -26 -30 -26 -44 0 -27 44 -67 66 -58 20 7 18 12
-11 23 -32 12 -33 45 -1 65 32 21 56 10 56 -26 0 -33 16 -39 25 -9 4 13 0 31
-9 45 -21 31 -63 33 -100 4z"
        />
        <path
          d="M0 1640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1644 1635 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M2072 1638 c-25 -13 -24 -31 2 -36 22 -5 46 16 39 34 -6 16 -10 16
-41 2z"
        />
        <path
          d="M5870 1640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M366 1623 c-7 -18 -8 -17 20 -31 17 -9 25 -8 34 3 9 11 6 17 -18 29
-27 14 -31 14 -36 -1z"
        />
        <path
          d="M770 1615 c-10 -12 -10 -18 0 -30 16 -19 39 -19 46 0 6 15 -11 45
-26 45 -4 0 -13 -7 -20 -15z"
        />
        <path
          d="M0 1600 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1600 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2098 1589 c-10 -5 -18 -17 -18 -26 0 -12 6 -14 27 -8 35 8 35 9 27
29 -7 18 -12 19 -36 5z"
        />
        <path
          d="M1694 1575 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M347 1573 c-12 -12 -7 -20 18 -31 34 -16 35 -15 35 7 0 24 -37 41
-53 24z"
        />
        <path
          d="M1180 1459 c-30 -66 -59 -124 -65 -128 -5 -5 -62 -14 -125 -20 -179
-19 -174 -9 -54 -119 57 -53 104 -102 104 -109 0 -8 -14 -71 -30 -140 -17 -69
-29 -127 -27 -129 1 -2 61 28 132 66 l128 69 121 -69 c67 -39 122 -66 124 -61
2 5 -8 69 -22 143 -14 73 -22 135 -18 139 4 4 48 45 98 90 50 46 92 89 93 94
1 6 -58 17 -136 25 l-137 15 -57 125 c-31 68 -61 125 -65 127 -5 1 -34 -52
-64 -118z m125 -41 l48 -108 -26 -37 c-15 -21 -40 -54 -56 -73 l-30 -35 -1
190 c0 104 4 185 8 180 5 -6 30 -58 57 -117z m-121 -180 c27 -39 49 -76 49
-83 0 -12 -213 -298 -219 -293 -1 2 9 56 23 120 l26 117 79 27 c44 14 76 30
71 34 -4 4 -81 32 -170 61 -89 29 -161 55 -159 57 4 3 197 29 231 31 14 0 35
-21 69 -71z m366 -19 c-30 -28 -73 -66 -96 -85 l-41 -34 -74 25 c-40 14 -76
24 -80 23 -4 -2 40 -68 97 -147 57 -79 102 -146 100 -148 -2 -3 -52 23 -110
58 l-107 62 3 91 3 91 165 57 c91 31 172 57 180 57 8 0 -10 -23 -40 -50z"
        />
        <path
          d="M0 1560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M720 1555 c-11 -13 -11 -19 3 -32 21 -21 49 -9 45 19 -4 28 -30 35
-48 13z"
        />
        <path
          d="M5870 1560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2123 1539 c-27 -10 -31 -39 -5 -39 28 0 45 16 36 33 -7 10 -15 12
-31 6z"
        />

        <path
          d="M325 1523 c-6 -18 10 -33 38 -33 25 0 18 31 -10 41 -16 6 -23 3 -28
-8z"
        />

        <path
          d="M0 1520 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1737 1517 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
-11z"
        />

        <path
          d="M680 1475 c0 -22 4 -26 23 -23 14 2 22 10 22 23 0 13 -8 21 -22 23
-19 3 -23 -1 -23 -23z"
        />

        <path
          d="M0 1480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2116 1482 c-2 -4 -1 -14 4 -21 11 -19 61 -6 54 14 -6 16 -49 21 -58
7z"
        />
        <path
          d="M5870 1480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M306 1465 c-8 -21 43 -42 56 -23 12 20 10 25 -14 32 -33 8 -35 8 -42
-9z"
        />
        <path
          d="M1781 1460 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"
        />

        <path
          d="M0 1440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2137 1434 c-17 -18 -6 -35 21 -32 16 2 27 9 27 18 0 16 -36 26 -48
14z"
        />
        <path
          d="M300 1411 c0 -11 9 -21 22 -24 29 -8 43 14 21 31 -24 18 -43 15 -43
-7z"
        />
        <path d="M654 1416 c-8 -21 3 -36 27 -36 15 0 20 6 17 22 -4 27 -35 37 -44 14z" />
        <path
          d="M0 1400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1794 1375 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M2158 1383 c-31 -8 -21 -33 13 -33 23 0 30 4 27 17 -3 18 -15 23 -40
16z"
        />
        <path
          d="M286 1364 c-8 -20 -8 -19 25 -28 38 -8 49 21 13 34 -32 13 -31 13
-38 -6z"
        />
        <path
          d="M0 1360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M626 1345 c-3 -8 2 -22 11 -30 14 -15 18 -15 31 -1 8 8 12 22 9 30
-8 20 -43 21 -51 1z"
        />
        <path
          d="M0 1320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2150 1309 c0 -16 5 -20 27 -17 17 2 29 10 31 21 3 13 -3 17 -27 17
-25 0 -31 -4 -31 -21z"
        />
        <path
          d="M5870 1320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M277 1306 c-7 -18 1 -26 29 -26 21 0 39 22 27 34 -12 12 -51 6 -56
-8z"
        />
        <path
          d="M1817 1313 c-13 -12 -7 -41 10 -47 23 -9 47 22 32 41 -12 14 -32 17
-42 6z"
        />
        <path
          d="M0 1280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M470 1253 c0 -29 -3 -32 -37 -35 -37 -4 -35 -8 14 -24 12 -3 14 -7 6
-12 -6 -4 -14 -21 -18 -37 -6 -29 -6 -29 9 -11 9 11 16 24 16 28 0 5 12 -3 27
-17 15 -14 31 -25 35 -25 14 0 8 28 -7 34 -15 5 -22 28 -7 24 4 -2 21 8 37 21
31 25 30 25 -32 6 -14 -5 -18 0 -19 25 -2 44 -24 65 -24 23z"
        />
        <path
          d="M614 1265 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M2000 1265 c-1 -11 -2 -29 -1 -40 1 -12 -1 -16 -6 -10 -5 6 -21 8
-38 4 -30 -6 -28 -8 22 -24 14 -5 15 -7 1 -18 -20 -15 -33 -57 -18 -57 6 0 15
11 20 25 l9 25 27 -25 c30 -28 46 -32 38 -10 -4 8 -10 15 -14 15 -4 0 -11 7
-14 16 -3 8 -2 13 2 10 5 -3 21 6 38 20 23 20 25 24 9 21 -11 -3 -29 -8 -39
-13 -13 -5 -17 -4 -12 4 3 6 0 26 -8 44 -11 26 -15 29 -16 13z"
        />
        <path
          d="M2150 1260 c0 -16 7 -20 30 -20 23 0 30 4 30 20 0 16 -7 20 -30 20
-23 0 -30 -4 -30 -20z"
        />
        <path
          d="M277 1263 c-17 -16 -5 -33 23 -33 19 0 30 5 30 14 0 20 -39 34 -53
19z"
        />
        <path
          d="M0 1240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1817 1227 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
-11z"
        />

        <path
          d="M0 1200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M272 1193 c2 -11 14 -19 31 -21 22 -3 27 1 27 17 0 17 -6 21 -31 21
-24 0 -30 -4 -27 -17z"
        />
        <path
          d="M620 1194 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"
        />
        <path
          d="M5870 1200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 1160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1824 1155 c-9 -23 4 -47 23 -43 22 4 30 38 11 49 -19 13 -28 11 -34
-6z"
        />
        <path d="M2156 1155 c-7 -20 43 -33 54 -14 5 7 6 17 4 21 -9 14 -52 9 -58 -7z" />
        <path
          d="M5870 1160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M277 1153 c-17 -16 -5 -33 23 -33 23 0 30 4 30 20 0 14 -7 20 -23 20
-13 0 -27 -3 -30 -7z"
        />
        <path d="M633 1133 c-18 -6 -16 -42 2 -49 8 -4 22 -1 30 6 24 20 -1 54 -32 43z" />
        <path
          d="M0 1120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2150 1100 c0 -16 7 -20 30 -20 32 0 42 23 14 34 -29 11 -44 6 -44
-14z"
        />
        <path
          d="M280 1079 c0 -17 4 -20 23 -15 12 3 25 6 30 6 4 0 7 7 7 15 0 10 -10
15 -30 15 -24 0 -30 -4 -30 -21z"
        />
        <path
          d="M0 1080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1804 1075 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"
        />
        <path
          d="M5870 1080 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2143 1053 c-7 -17 -4 -20 30 -28 23 -6 27 -4 27 13 0 24 -48 38 -57
15z"
        />
        <path
          d="M641 1046 c-9 -11 -8 -18 5 -31 15 -15 19 -15 32 -1 17 17 10 46 -11
46 -8 0 -20 -6 -26 -14z"
        />

        <path
          d="M0 1040 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M286 1042 c-2 -4 -1 -14 4 -21 11 -19 61 -6 54 14 -6 16 -49 21 -58
7z"
        />

        <path
          d="M5870 1040 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1783 1004 c-3 -8 2 -23 11 -32 15 -15 17 -15 33 0 13 14 14 20 3 32
-16 20 -39 20 -47 0z"
        />
        <path
          d="M2133 1003 c-7 -17 13 -33 40 -33 10 0 17 8 17 19 0 24 -49 36 -57
14z"
        />

        <path
          d="M0 1000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M5870 1000 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M313 993 c-26 -10 -13 -33 18 -33 24 0 30 4 27 17 -3 18 -24 26 -45
16z"
        />
        <path
          d="M663 974 c-3 -8 2 -23 11 -32 15 -15 17 -15 33 0 13 14 14 20 3 32
-16 20 -39 20 -47 0z"
        />
        <path
          d="M0 960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M2120 945 c0 -23 43 -37 54 -18 5 10 4 18 -5 24 -22 14 -49 10 -49
-6z"
        />
        <path
          d="M327 943 c-17 -4 -21 -35 -4 -34 4 1 16 1 27 1 13 0 20 7 20 20 0 20
-8 22 -43 13z"
        />
        <path d="M1754 935 c-8 -20 4 -35 26 -35 23 0 32 13 24 34 -8 20 -43 21 -50 1z" />
        <path
          d="M0 920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path d="M694 905 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51 10z" />
        <path
          d="M2105 900 c-9 -14 1 -27 25 -33 14 -3 23 0 27 10 10 24 -38 46 -52
23z"
        />
        <path
          d="M341 891 c-23 -15 -5 -44 21 -35 30 9 32 10 25 29 -7 17 -25 19 -46
6z"
        />
        <path
          d="M0 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1721 876 c-15 -18 -3 -46 20 -46 25 0 35 26 19 45 -15 19 -24 19
-39 1z"
        />
        <path
          d="M5870 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M740 844 c-11 -12 -10 -18 3 -31 8 -9 23 -13 31 -10 21 8 21 43 0 51
-21 8 -20 8 -34 -10z"
        />
        <path
          d="M2087 853 c-13 -13 -7 -24 20 -34 20 -7 28 -7 34 3 14 21 -36 50 -54
31z"
        />
        <path
          d="M0 840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path d="M368 843 c-21 -5 -25 -43 -5 -43 22 0 51 26 43 38 -7 12 -9 13 -38 5z" />
        <path
          d="M5870 840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1677 815 c-20 -19 -12 -45 14 -45 26 0 36 28 17 46 -13 14 -17 14
-31 -1z"
        />
        <path
          d="M2063 804 c-6 -16 25 -46 42 -40 21 6 15 33 -10 44 -21 10 -27 9 -32
-4z"
        />
        <path
          d="M0 800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 800 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path d="M381 791 c-14 -9 -9 -41 7 -41 17 0 45 27 38 38 -9 14 -26 15 -45 3z" />
        <path d="M794 786 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z" />
        <path
          d="M1764 775 c-27 -27 -30 -45 -12 -63 17 -17 36 -15 66 8 17 14 29 17
35 11 12 -12 -9 -41 -29 -41 -19 0 -26 -17 -10 -23 22 -9 66 31 66 58 0 37
-32 49 -63 25 -30 -24 -47 -26 -47 -5 0 9 11 20 25 25 29 11 31 16 9 24 -9 3
-25 -4 -40 -19z"
        />
        <path
          d="M649 774 c-10 -12 -10 -22 0 -50 14 -39 10 -50 -14 -41 -8 4 -15 18
-15 32 0 27 -17 34 -26 11 -14 -37 41 -85 76 -66 24 13 25 31 5 70 -15 29 -9
45 12 32 6 -4 12 -16 14 -26 5 -26 29 -33 29 -9 0 27 -28 63 -49 63 -10 0 -24
-7 -32 -16z"
        />
        <path
          d="M0 760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1616 753 c-4 -9 0 -23 9 -32 13 -14 17 -14 31 1 9 9 14 24 11 32 -8
21 -43 20 -51 -1z"
        />
        <path
          d="M2040 755 c-9 -11 -7 -16 10 -25 22 -12 50 -7 50 8 0 5 -11 14 -24
20 -19 8 -27 8 -36 -3z"
        />
        <path
          d="M5870 760 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M418 749 c-19 -11 -24 -34 -10 -43 15 -9 52 23 46 39 -7 17 -13 18
-36 4z"
        />
        <path
          d="M843 734 c-3 -8 1 -22 9 -30 12 -12 17 -12 33 0 16 12 17 16 6 30
-17 20 -40 21 -48 0z"
        />
        <path d="M3185 729 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
        <path
          d="M0 720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />

        <path
          d="M5870 720 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M740 711 c0 -6 5 -13 10 -16 7 -4 -3 -24 -25 -50 -45 -55 -27 -72 20
-18 30 34 36 37 54 25 36 -21 25 12 -13 42 -37 28 -46 31 -46 17z"
        />
        <path
          d="M1554 705 c-8 -19 3 -35 26 -35 19 0 34 25 24 41 -8 14 -44 10 -50
-6z"
        />
        <path
          d="M2015 710 c-8 -13 13 -40 31 -40 22 0 28 22 9 37 -23 16 -32 16 -40
3z"
        />
        <path
          d="M436 695 c-15 -11 -17 -18 -9 -26 15 -15 55 -1 51 19 -4 21 -19 24
-42 7z"
        />
        <path d="M914 696 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41 16z" />
        <path
          d="M1684 695 c-8 -20 36 -127 50 -122 6 2 10 12 8 21 -4 23 26 45 51 38
37 -9 12 22 -39 50 -57 32 -63 33 -70 13z m56 -35 c13 -9 13 -11 0 -19 -10 -7
-17 -6 -22 2 -17 26 -5 35 22 17z"
        />

        <path
          d="M0 680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1501 680 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"
        />
        <path
          d="M5870 680 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1987 673 c-13 -12 -7 -28 14 -42 29 -18 45 5 22 31 -17 19 -26 22
-36 11z"
        />
        <path
          d="M471 652 c-28 -23 -14 -50 18 -33 22 12 28 36 12 45 -5 3 -18 -2 -30
-12z"
        />
        <path
          d="M977 657 c-8 -21 8 -39 29 -35 27 5 25 42 -2 46 -12 2 -24 -3 -27
-11z"
        />
        <path
          d="M1424 645 c-9 -23 4 -47 23 -43 22 4 30 38 11 49 -19 13 -28 11 -34
-6z"
        />
        <path
          d="M1633 649 c-7 -7 -13 -21 -13 -31 0 -20 -11 -23 -26 -8 -5 5 -15 6
-23 1 -11 -7 -6 -13 18 -25 27 -14 31 -22 31 -56 0 -31 3 -38 15 -34 8 4 15
17 15 31 0 20 4 24 18 18 28 -11 34 -11 39 1 2 6 -10 17 -26 24 -25 10 -30 18
-33 52 -2 31 -5 37 -15 27z"
        />
        <path
          d="M0 640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1050 634 c-11 -12 -10 -18 3 -31 8 -9 23 -13 31 -10 21 8 21 43 0
51 -21 8 -20 8 -34 -10z"
        />
        <path
          d="M5870 640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1351 621 c-13 -23 10 -47 34 -37 20 7 19 42 -1 50 -22 8 -22 8 -33
-13z"
        />
        <path
          d="M1954 625 c-9 -22 19 -50 41 -41 21 8 16 25 -13 44 -19 13 -22 12
-28 -3z"
        />
        <path
          d="M505 610 c-14 -16 -16 -23 -7 -32 10 -10 17 -8 33 8 17 17 18 22 6
32 -11 9 -18 8 -32 -8z"
        />
        <path
          d="M792 598 c-52 -33 -72 -66 -32 -53 25 8 50 -10 50 -36 0 -11 6 -19
13 -19 13 0 51 116 42 131 -9 14 -16 12 -73 -23z m41 -28 c-5 -20 -15 -25 -26
-13 -8 7 11 33 23 33 5 0 6 -9 3 -20z"
        />
        <path
          d="M1124 616 c-8 -21 13 -46 32 -39 20 8 13 47 -9 51 -9 2 -20 -4 -23
-12z"
        />
        <path
          d="M1196 604 c-4 -10 -2 -22 3 -27 15 -15 46 -3 46 18 0 26 -40 33 -49
9z"
        />
        <path
          d="M1272 598 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 18 -1 22 -23 22
-22 0 -26 -4 -23 -22z"
        />
        <path
          d="M0 600 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M880 601 c0 -5 7 -14 15 -21 13 -10 12 -17 -5 -51 -21 -41 -25 -59
-12 -59 4 0 18 20 30 45 23 47 28 50 51 36 11 -7 11 -16 -3 -57 -9 -26 -16
-49 -16 -50 0 -1 20 -9 45 -17 54 -19 72 -3 21 18 -24 10 -34 20 -30 30 7 18
6 18 38 5 16 -6 27 -6 31 1 4 5 -8 14 -25 20 -17 6 -29 16 -27 23 5 12 6 12
50 1 46 -12 30 11 -20 29 -27 9 -67 26 -91 37 -48 22 -52 22 -52 10z"
        />
        <path
          d="M4541 603 c0 -6 -4 -20 -7 -30 -5 -14 -4 -15 5 -7 7 7 11 20 8 30 -3
11 -5 14 -6 7z"
        />
        <path
          d="M5870 600 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1920 585 c-11 -13 -10 -19 5 -35 17 -19 20 -19 34 -5 14 14 13 17
-5 36 -19 18 -22 19 -34 4z"
        />
        <path
          d="M543 573 c-12 -8 -19 -21 -16 -29 7 -19 27 -18 47 5 24 26 0 45 -31
24z"
        />
        <path
          d="M1446 560 c-44 -15 -82 -33 -84 -40 -3 -8 2 -10 16 -6 18 6 22 1 31
-31 6 -21 11 -44 11 -50 0 -7 7 -13 16 -13 12 0 15 6 10 26 -20 77 -19 84 3
84 14 0 27 -13 42 -42 12 -24 22 -44 24 -45 1 -2 20 5 43 15 47 19 57 38 15
27 -45 -11 -47 -11 -50 6 -2 9 7 21 24 29 22 10 24 14 11 17 -9 2 -26 0 -36
-6 -15 -8 -21 -7 -25 3 -4 9 5 19 24 26 29 12 40 31 17 28 -7 0 -49 -13 -92
-28z"
        />
        <path
          d="M0 560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 560 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1882 547 c-17 -20 22 -57 40 -39 10 10 8 17 -8 33 -17 17 -22 18
-32 6z"
        />
        <path
          d="M576 531 c-19 -21 -14 -41 8 -41 20 0 37 25 30 44 -8 21 -17 20 -38
-3z"
        />
        <path
          d="M0 520 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1140 517 c-20 -10 -26 -21 -28 -54 -4 -48 16 -73 57 -73 35 0 61 28
61 66 0 55 -44 84 -90 61z m60 -36 c7 -13 7 -28 0 -45 -16 -43 -70 -28 -70 19
0 43 50 62 70 26z"
        />
        <path
          d="M5870 520 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1250 455 c0 -51 3 -65 15 -65 9 0 15 9 15 25 0 18 7 26 25 31 35 9
31 24 -6 24 -41 0 -33 24 9 28 49 5 38 22 -13 22 l-45 0 0 -65z"
        />
        <path
          d="M1845 510 c-7 -11 11 -50 23 -50 5 0 13 5 20 12 9 9 8 16 -5 30 -18
20 -29 23 -38 8z"
        />
        <path
          d="M616 491 c-14 -15 -14 -20 -3 -30 11 -8 18 -7 31 8 10 11 15 25 11
30 -9 16 -20 14 -39 -8z"
        />
        <path
          d="M0 480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1802 478 c-14 -18 11 -53 33 -45 20 8 19 20 -4 41 -14 13 -20 14
-29 4z"
        />
        <path
          d="M5870 480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M656 458 c-15 -20 -15 -23 1 -32 12 -6 20 -4 30 10 7 10 12 23 10 28
-6 19 -25 17 -41 -6z"
        />
        <path
          d="M0 440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M702 429 c-20 -31 1 -49 28 -24 15 14 17 21 9 32 -16 18 -22 17 -37
-8z"
        />
        <path
          d="M1756 441 c-10 -15 27 -52 43 -42 10 7 11 13 2 30 -12 22 -36 28 -45
12z"
        />
        <path
          d="M5870 440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M746 398 c-17 -23 -13 -38 9 -38 12 0 35 28 35 43 0 17 -32 13 -44
-5z"
        />
        <path
          d="M1710 407 c0 -7 6 -22 14 -33 20 -27 43 -6 27 25 -13 23 -41 29 -41
8z"
        />
        <path
          d="M0 400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 400 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1673 394 c-15 -7 4 -54 22 -54 20 0 24 11 13 36 -11 25 -14 26 -35
18z"
        />
        <path
          d="M792 370 c-7 -12 -10 -25 -7 -30 11 -18 32 -11 43 14 9 20 8 25 -4
30 -9 3 -16 6 -17 6 -1 0 -8 -9 -15 -20z"
        />
        <path
          d="M0 360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M842 346 c-6 -13 -8 -27 -5 -30 13 -13 30 -5 41 18 9 20 8 25 -4 30
-21 8 -19 9 -32 -18z"
        />
        <path d="M1620 353 c0 -28 15 -44 33 -37 20 7 20 9 7 35 -13 24 -40 25 -40 2z" />
        <path
          d="M5870 360 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M889 323 c-12 -33 9 -52 29 -26 16 23 15 40 -3 47 -11 4 -19 -3 -26
-21z"
        />
        <path
          d="M1570 333 c0 -28 16 -47 34 -40 12 5 14 12 7 29 -10 29 -41 37 -41
11z"
        />
        <path d="M1531 331 c-19 -12 -6 -62 14 -55 16 7 20 31 7 51 -5 7 -13 9 -21 4z" />
        <path
          d="M0 320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M941 308 c-10 -26 -6 -38 14 -38 16 0 29 35 20 50 -10 17 -25 11 -34
-12z"
        />
        <path
          d="M5870 320 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path d="M996 293 c-7 -25 8 -48 25 -37 14 9 16 53 2 58 -21 8 -20 9 -27 -21z" />
        <path
          d="M1470 290 c0 -23 4 -30 20 -30 20 0 25 15 14 44 -11 28 -34 18 -34
-14z"
        />
        <path
          d="M1045 281 c-3 -12 -3 -25 0 -31 11 -18 35 -11 35 10 0 11 0 23 1 28
1 17 -30 11 -36 -7z"
        />
        <path
          d="M1366 292 c-11 -18 7 -52 26 -52 14 0 18 5 14 16 -3 9 -6 22 -6 30 0
15 -25 19 -34 6z"
        />
        <path
          d="M1414 284 c3 -9 6 -20 6 -25 0 -5 9 -9 21 -9 15 0 19 4 15 16 -3 9
-6 20 -6 25 0 5 -9 9 -21 9 -15 0 -19 -4 -15 -16z"
        />
        <path
          d="M0 280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M1098 266 c-4 -18 0 -26 14 -29 10 -3 18 -2 18 1 0 4 3 17 6 30 5 17
2 22 -14 22 -13 0 -21 -8 -24 -24z"
        />
        <path
          d="M1150 260 c0 -35 25 -42 35 -10 8 24 -1 40 -21 40 -9 0 -14 -11 -14
-30z"
        />
        <path
          d="M1310 260 c0 -23 4 -30 20 -30 16 0 20 7 20 30 0 23 -4 30 -20 30
-16 0 -20 -7 -20 -30z"
        />
        <path
          d="M5870 280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1200 256 c0 -23 22 -41 34 -29 3 4 6 17 6 30 0 16 -6 23 -20 23 -14
0 -20 -7 -20 -24z"
        />
        <path
          d="M1260 255 c0 -18 5 -25 20 -25 15 0 20 7 20 25 0 18 -5 25 -20 25
-15 0 -20 -7 -20 -25z"
        />
        <path
          d="M0 240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 240 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 200 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 160 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 120 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 80 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0 -15
-4 -15 -10z"
        />
        <path
          d="M5870 80 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M0 25 c0 -20 5 -25 25 -25 28 0 32 14 11 36 -22 21 -36 17 -36 -11z
m30 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"
        />
        <path
          d="M5863 35 c-20 -22 -15 -35 12 -35 20 0 25 5 25 25 0 27 -18 32 -37
10z m27 -15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"
        />
        <path
          d="M70 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10
-7 -10 -15z"
        />
        <path
          d="M110 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M150 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M190 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M230 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M270 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M310 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M350 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M390 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M430 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M470 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M510 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M550 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M590 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M630 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M670 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M710 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M750 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M790 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M830 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M870 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M910 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M950 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M990 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1030 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1070 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1110 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1150 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1190 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1230 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1270 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path d="M1311 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1351 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1391 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1431 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1471 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1511 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1551 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1591 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M1631 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M1660 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1700 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1740 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1780 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1820 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1860 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1900 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1940 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M1980 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2020 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2060 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2100 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2140 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2180 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2220 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2260 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2300 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2340 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2380 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2420 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2460 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2500 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2540 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2580 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2620 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2660 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2700 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2740 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2780 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2820 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2860 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2900 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2940 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M2980 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3020 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3060 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3100 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3140 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3180 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3220 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3260 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3300 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3340 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3380 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3420 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3460 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3500 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3540 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3580 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3620 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3660 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3700 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3740 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3780 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3820 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3860 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3900 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3940 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M3980 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4020 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4060 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4100 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4140 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4180 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4220 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path d="M4261 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4301 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4341 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4381 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4421 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4461 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4501 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4541 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path d="M4581 14 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
        <path
          d="M4610 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4650 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4690 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4730 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4770 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4810 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4850 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4890 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4930 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M4970 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5010 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5050 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5090 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5130 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5170 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5210 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5250 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5290 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5330 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5370 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5410 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5450 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5490 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5530 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5570 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5610 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5650 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5690 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5730 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5770 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
        <path
          d="M5810 15 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"
        />
      </g>
    </svg>
  );
}
export default TexasStamp;
