/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountStatus,
  DevlopmentResponseDTO,
  EmailDTOUser,
  User,
} from '../models/index';
import {
    AccountStatusFromJSON,
    AccountStatusToJSON,
    DevlopmentResponseDTOFromJSON,
    DevlopmentResponseDTOToJSON,
    EmailDTOUserFromJSON,
    EmailDTOUserToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface UsersControllerGetUserRequest {
    email: string;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async usersControllerAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/stripeAccount-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountStatusFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountStatus> {
        const response = await this.usersControllerAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async usersControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.usersControllerGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetOneRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DevlopmentResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/maintenance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DevlopmentResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerGetOne(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DevlopmentResponseDTO> {
        const response = await this.usersControllerGetOneRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async usersControllerGetUserRaw(requestParameters: UsersControllerGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailDTOUser>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling usersControllerGetUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/get-userId/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters['email']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDTOUserFromJSON(jsonValue));
    }

    /**
     */
    async usersControllerGetUser(requestParameters: UsersControllerGetUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailDTOUser> {
        const response = await this.usersControllerGetUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
