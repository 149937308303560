import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentData from "../../commonComponents/PaymentCard/PaymentData";
import { signerPaths } from "../../../routes/routes";

const SessionPayment = () => {
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState<boolean>(true);

  return (
    <Stack spacing={2}>
      <Text
        fontSize={"22px"}
        textAlign={"center"}
        mb={"20px"}
        fontFamily={"oswald"}
        fontWeight={"600"}
        mt={"10px"}
      >
        Payment Details
      </Text>

      <PaymentData onDisable={(value) => setIsDisable(value)} />

      <Flex marginTop={5} justifyContent={"flex-end"}>
        <Button
          mx={2}
          style={{ padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            navigate(`${signerPaths.createSession}/participants`);
          }}
        >
          Prev
        </Button>
        <Button
          mx={2}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          width={"fit-content"}
          onClick={() => {
            navigate(`${signerPaths.createSession}/notary-detail`);
          }}
          isDisabled={isDisable}
        >
          Next
        </Button>
      </Flex>
    </Stack>
  );
};

export default SessionPayment;
