import { Alert, AlertIcon, CloseButton } from "@chakra-ui/react";

const AlertMsg = ({
  errorMsg,
  onClose,
  isOpen,
}: {
  errorMsg?: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  return (
    <Alert
      {...(isOpen ? { display: "flex" } : { display: "none" })}
      status="error"
      maxW={"100%"}
      justifyContent={"center"}
      alignItems={"flexStart"}
      margin="auto"
      fontSize={"14px"}
      my={2}
    >
      <AlertIcon />
      {errorMsg}
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  );
};

export default AlertMsg;
