/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignDocDTO
 */
export interface SignDocDTO {
    /**
     * 
     * @type {number}
     * @memberof SignDocDTO
     */
    sessionId: number;
    /**
     * 
     * @type {string}
     * @memberof SignDocDTO
     */
    passCode?: string;
}

/**
 * Check if a given object implements the SignDocDTO interface.
 */
export function instanceOfSignDocDTO(value: object): value is SignDocDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    return true;
}

export function SignDocDTOFromJSON(json: any): SignDocDTO {
    return SignDocDTOFromJSONTyped(json, false);
}

export function SignDocDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignDocDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'passCode': json['passCode'] == null ? undefined : json['passCode'],
    };
}

export function SignDocDTOToJSON(value?: SignDocDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'passCode': value['passCode'],
    };
}

