import { useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { BsCalendar2Date, BsCalendar2Month } from "react-icons/bs";
import { MdOutlineKeyboardArrowUp as UpArrow } from "react-icons/md";
import { MdKeyboardArrowDown, MdOutlineCalendarMonth } from "react-icons/md";
import { EventType } from "../../../store/auditLogs";
import {
  currentDate,
  currentMonth,
  currentYear,
  getFullMonth,
  getMonth,
  getdateFormatString,
} from "../../../utils/utils";

const DateTool = () => {
  const [expandDate, setExpandDate] = useState<boolean>(false);
  const [expandMonth, setExpandMonth] = useState<boolean>(false);
  const [expandYear, setExpandYear] = useState<boolean>(false);

  const dateOptions = [
    {
      title: `${getdateFormatString(currentDate)}`,
      data: `${getdateFormatString(currentDate)}`,
      icon: <BsCalendar2Date />,
    },
    {
      title: `${currentDate}`,
      data: `${currentDate}`,
      icon: <BsCalendar2Date />,
    },
  ];

  const monthOptions = [
    {
      title: `${currentMonth}`,
      data: `${currentMonth}`,
      icon: <BsCalendar2Month />,
    },
    {
      title: `${getMonth(currentMonth)}`,
      data: `${getMonth(currentMonth)}`,
      icon: <BsCalendar2Month />,
    },
    {
      title: `${getFullMonth(currentMonth)}`,
      data: `${getFullMonth(currentMonth)}`,
      icon: <BsCalendar2Month />,
    },
  ];

  const yearOptions = [
    {
      title: `${currentYear}`,
      data: `${currentYear}`,
      icon: <MdOutlineCalendarMonth />,
    },
    {
      title: `${currentYear?.toString()?.slice(-2)}`,
      data: `${currentYear?.toString()?.slice(-2)}`,
      icon: <MdOutlineCalendarMonth />,
    },
  ];

  return (
    <>
      <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
        <Button
          justifyContent="space-between"
          width={"100%"}
          style={{
            backgroundColor: "white",
          }}
          onClick={() => {
            setExpandDate((prev) => !prev);
          }}
        >
          <Flex alignItems={"center"}>
            <BsCalendar2Date />
            <Text className="tools-button-text">Date</Text>
          </Flex>

          <Text className="tools-button-text">
            {expandDate ? <UpArrow /> : <MdKeyboardArrowDown />}
          </Text>
        </Button>

        {expandDate &&
          dateOptions?.map((item, idx) => (
            <Button
              key={idx}
              width={"100%"}
              mt={idx === 0 ? 0.5 : 2}
              justifyContent="left"
              draggable={true}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "application/json",
                  JSON.stringify({
                    action: "placeTextObject",
                    payload: {
                      x: 0,
                      y: 0,
                      text: `${item.data}`,
                      type: EventType.text,
                    },
                  })
                );
              }}
            >
              {item.icon}
              <Text className="tools-button-text">{item.title}</Text>
            </Button>
          ))}
      </Box>

      <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
        <Button
          justifyContent="space-between"
          width={"100%"}
          style={{
            backgroundColor: "white",
          }}
          onClick={() => {
            setExpandMonth((prev) => !prev);
          }}
        >
          <Flex alignItems={"center"}>
            <BsCalendar2Month />
            <Text className="tools-button-text">Month</Text>
          </Flex>

          <Text className="tools-button-text">
            {expandMonth ? <UpArrow /> : <MdKeyboardArrowDown />}
          </Text>
        </Button>

        {expandMonth &&
          monthOptions?.map((item, idx) => (
            <Button
              key={idx}
              width={"100%"}
              mt={idx === 0 ? 0.5 : 2}
              justifyContent="left"
              draggable={true}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "application/json",
                  JSON.stringify({
                    action: "placeTextObject",
                    payload: {
                      x: 0,
                      y: 0,
                      text: `${item.data}`,
                      type: EventType.text,
                    },
                  })
                );
              }}
            >
              {item.icon}
              <Text className="tools-button-text">{item.title}</Text>
            </Button>
          ))}
      </Box>

      <Box border={"1px solid"} borderRadius="6px" backgroundColor="white">
        <Button
          justifyContent="space-between"
          width={"100%"}
          style={{
            backgroundColor: "white",
          }}
          onClick={() => {
            setExpandYear((prev) => !prev);
          }}
        >
          <Flex alignItems={"center"}>
            <MdOutlineCalendarMonth />
            <Text className="tools-button-text">Year</Text>
          </Flex>

          <Text className="tools-button-text">
            {expandYear ? <UpArrow /> : <MdKeyboardArrowDown />}
          </Text>
        </Button>

        {expandYear &&
          yearOptions?.map((item, idx) => (
            <Button
              key={idx}
              width={"100%"}
              mt={idx === 0 ? 0.5 : 2}
              justifyContent="left"
              draggable={true}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "application/json",
                  JSON.stringify({
                    action: "placeTextObject",
                    payload: {
                      x: 0,
                      y: 0,
                      text: `${item.data}`,
                      type: EventType.text,
                    },
                  })
                );
              }}
            >
              {item.icon}
              <Text className="tools-button-text">{item.title}</Text>
            </Button>
          ))}
      </Box>
    </>
  );
};

export default DateTool;
