import { Box } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import { ActiveSessionApi, Session } from "../../../api";
import { useMessagesStore } from "../../../store/mesagesStore";
import { WS_URL } from "../../../utils/constants";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../utils/utils";
import LoadingContent from "../../commonComponents/LoadingComponents/LoadingContent";
import CommonMeeting from "../CommonMeeting/CommonMeeting";
import DeviceInfoModal from "../CommonMeeting/DeviceModal/DeviceInfoModal";
import MeetingRoom from "../CommonMeeting/MeetingRoom";

const SignerMeeting = () => {
  const { configuration } = useAuth();
  const [searchParams] = useSearchParams();
  const { notification } = useNotification();
  const { clearMessages } = useMessagesStore();
  const sessionID = searchParams.get("session-id");
  const [isPreferencesSet, setIsPreferencesSet] = useState(false);
  const [token, setToken] = useState<string | undefined>();

  const userId = localStorage.getItem("UserID");

  const socket = useMemo(() => {
    return io(WS_URL, {
      query: {
        token: localStorage.getItem("accessToken"),
      },
    });
  }, []);

  const endMeeting = useCallback(() => {
    // clear current Session messages
    clearMessages();
    // window.location.href = `/`;
    window.location.href = `/signer/on-demand/feedback/${sessionID}`;
    notification("fail", "Meeting ended");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPreferencesSet === false) return;
    const listener = ({ session }: { session: Session }) => {
      if (session.sessionId === Number(sessionID)) {
        endMeeting();
      }
    };
    socket.on("session-close", listener);
    return () => {
      socket.removeListener("session-close", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreferencesSet]);

  useEffect(() => {
    if (!!sessionID === false) {
      console.log("sessionID not found");
      return;
    }
    new ActiveSessionApi(configuration)
      .activeSessionControllerFindOne({
        sessionId: sessionID,
      })
      .then((res) => {
        console.log("active session data", res);
        console.log("active session data", res.data);
        // #TODO : get all the paricipants and witnesses here
        // also get the meeting token here for livekit
        // then set the token to join the livekit meeting
        // we know here that the token is for signer as this file is for signer
        // @ts-ignore
        const data = res?.data?.participants;
        if (!data) {
          console.log("participants not found");
          return;
        }
        const tokens = data?.reduce((acc, cur) => {
          if (typeof cur !== "object") return acc;
          if (Object.keys(cur)[0].startsWith("notary")) {
            acc["notary"] = {
              accessToken: Object.values(cur)[0],
            };
          } else if (Object.keys(cur)[0].startsWith("primarySigner")) {
            // #NOTE : not a primary signer
            if (
              Number(Object.keys(cur)[0].split(LIVEKIT_SPLIT_IDENTIFIER)[1]) ===
              Number(userId)
            ) {
              acc["primarySigner"] = {
                accessToken: Object.values(cur)[0],
              };
            }
          } else if (Object.keys(cur)[0].startsWith("signer")) {
            if (
              Number(Object.keys(cur)[0].split(LIVEKIT_SPLIT_IDENTIFIER)[1]) ===
              Number(userId)
            ) {
              acc["signer"] = {
                accessToken: Object.values(cur)[0],
              };
            }
          }
          return acc;
        }, {});
        if (tokens?.primarySigner === undefined) {
          console.log("not a primary signer");
          setToken(tokens?.signer?.accessToken as string);
        } else {
          setToken(tokens?.primarySigner?.accessToken as string);
        }
      });
  }, [sessionID]);

  return (
    <Box height="100vh" backgroundColor={"#fff"}>
      {/* <div>Signer</div> */}
      {token === undefined ? (
        <LoadingContent />
      ) : (
        <MeetingRoom token={token}>
          <>
            <DeviceInfoModal
              from="signer"
              onCloseComplete={() => {
                setTimeout(() => {
                  setIsPreferencesSet(true);
                }, 1000);
              }}
              sessionId={sessionID ?? ""}
            />
            <CommonMeeting
              sessionID={sessionID}
              from="signer"
              socket={socket}
            />
          </>
        </MeetingRoom>
      )}
    </Box>
  );
};

export default SignerMeeting;
