/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocResponseDTO
 */
export interface DocResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof DocResponseDTO
     */
    validFrom: string;
    /**
     * 
     * @type {string}
     * @memberof DocResponseDTO
     */
    validTo: string;
}

/**
 * Check if a given object implements the DocResponseDTO interface.
 */
export function instanceOfDocResponseDTO(value: object): value is DocResponseDTO {
    if (!('validFrom' in value) || value['validFrom'] === undefined) return false;
    if (!('validTo' in value) || value['validTo'] === undefined) return false;
    return true;
}

export function DocResponseDTOFromJSON(json: any): DocResponseDTO {
    return DocResponseDTOFromJSONTyped(json, false);
}

export function DocResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'validFrom': json['validFrom'],
        'validTo': json['validTo'],
    };
}

export function DocResponseDTOToJSON(value?: DocResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'validFrom': value['validFrom'],
        'validTo': value['validTo'],
    };
}

