import { Box, Center, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Configuration } from "../../../api";
import BaseLayout from "../../../layout/BaseLayout";
import { BASE_URL } from "../../../utils/constants";
import { signerPaths } from "../../../routes/routes";
import SignerProfileData from "./components/SignerProfileData";
import { useSignerProfileStore } from "../../../store/signerStore";
import { getSessionDetails, getSignerDetails } from "../../../utils/utils-apis";
import KbaVerification from "../../../component/commonComponents/KbaVerificationModal/KbaVerification";

const SignerProfile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session-id");
  const token = localStorage.getItem("accessToken");
  const accessToken = searchParams.get("access-token");
  const [loading, setLoading] = useState<boolean>(false);
  const [signerKbaStatus, setSignerKbaStatus] = useState<string>("pending");
  const { signerProfileDetails, setSignerProfileDetails } =
    useSignerProfileStore();
  const {
    isOpen: isKbaOpen,
    onOpen: onKbaOpen,
    onClose: onKbaClose,
  } = useDisclosure();

  useEffect(() => {
    setLoading(true);
    const configuration = new Configuration({
      basePath: BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    getSignerDetails(configuration)
      .then((res) => {
        localStorage.setItem("UserID", res?.user?.id?.toString());
        localStorage.setItem("role", "signer");
        if (token !== null) {
          localStorage.setItem("accessToken", token);
        }
        setSignerProfileDetails({
          ...signerProfileDetails,
          firstName: res.firstName,
          lastName: res.lastName,
          phoneNumber: res.phoneNumber,
          dob: res.dob,
          lastFourDigitsSsnNumber: res.lastFourDigitsSsnNumber,
          country: res.country,
          addressLine1: res.addressLine1,
          addressLine2: res.addressLine2,
          city: res.city,
          state: res.state,
          zip: res.zip?.toString(),
          signerId: res.signerId,
          email: res.email,
          kbaStatus: res?.kbaStatus,
          firstScanImage: res?.firstScanImage,
          firstBackScanImage: res?.firstBackScanImage,
          wantSmsNotification: res?.wantSmsNotification,
        });

        if (sessionId !== null) {
          getSessionDetails(configuration, Number(sessionId)).then(
            (session) => {
              const kbaLogId = session?.kbaLogs?.find(
                (el) => Number(el?.userId) === Number(res?.user?.id)
              )?.kbaLogId;
              if (
                kbaLogId === "" ||
                kbaLogId === undefined ||
                kbaLogId === "pending"
              ) {
                setSignerKbaStatus("pending");
              } else if (kbaLogId === "knownByNotary") {
                setSignerKbaStatus("knownByNotary");
              } else if (kbaLogId === "demo") {
                setSignerKbaStatus("demo");
              } else {
                setSignerKbaStatus("completed");
              }
              setLoading(false);
            }
          );
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"}>
        Signer Information
      </Text>

      <Text fontSize={"16px"} fontFamily={"Lato"} fontWeight={"400"} m={"20px"}>
        Enter your personal information below as is required by law to execute a
        Remote Online Notary session. Your credit/debit card will not be charged
        unless you successfully complete a session with a notary public
      </Text>

      {token != null && (
        <>
          {loading ? (
            <Center>
              <Spinner size={"xl"} />
            </Center>
          ) : (
            <Box mx={2} fontFamily="Lato" fontWeight={600}>
              <SignerProfileData
                onClose={() => navigate("/")}
                from={sessionId != null ? "modal" : "page"}
                onSubmit={(isNew) => {
                  if (sessionId != null) {
                    if (
                      signerKbaStatus === "completed" ||
                      signerKbaStatus === "knownByNotary" ||
                      signerKbaStatus === "approved"
                    ) {
                      navigate(
                        `/signer/on-demand/waiting-for-notary/?session-id=${sessionId}`
                      );
                    } else {
                      onKbaOpen();
                    }
                  } else {
                    if (isNew) {
                      navigate(`${signerPaths.createSession}/document-upload`);
                    } else {
                      navigate("/");
                    }
                  }
                }}
              />
            </Box>
          )}

          <KbaVerification
            isOpen={isKbaOpen}
            onClose={onKbaClose}
            onOpen={onKbaOpen}
            from="signer"
            sessionId={Number(sessionId)}
          />
        </>
      )}
    </BaseLayout>
  );
};

export default SignerProfile;
