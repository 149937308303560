//@ts-nocheck
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { BsCircleFill as CircleIcon } from "react-icons/bs";
interface IDrawingCanvas extends CanvasDraw {
  interactionSM?: {
    handleDrawStart: (e: React.MouseEvent, canvasRef: CanvasDraw) => void;
    handleDrawMove: (e: React.MouseEvent, canvasRef: CanvasDraw) => void;
  };
  getDataURL?: () => string;
}

const ScribbleBoard = ({
  onSendCanvas,
  isDisable,
  onClose,
  canvasWidth,
  canvasHeight,
}: {
  onSendCanvas: (path: string) => void;
  isDisable?: boolean;
  onClose: () => void;
  canvasWidth: number;
  canvasHeight: number;
}) => {
  const drawingCanvasRef = useRef<IDrawingCanvas>();
  const [color, setColor] = useState<string>("#000000");
  const [canvas, setCanvas] = useState<
    (CanvasDraw & { getDataURL: () => string }) | null
  >(null);

  const [isClicked, setIsClicked] = useState<{
    clickCount: number;
    state: boolean;
  }>({
    clickCount: 0,
    state: false,
  });

  const handleClick = () => {
    setIsClicked((prev) => {
      if (prev.clickCount === 0) {
        return {
          clickCount: prev.clickCount + 1,
          state: !prev.state,
        };
      } else {
        return {
          clickCount: prev.clickCount + 1,
          state: !prev.state,
        };
      }
    });
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isClicked.state && isClicked.clickCount === 1) {
      drawingCanvasRef.current?.interactionSM?.handleDrawStart(
        e,
        drawingCanvasRef.current
      );
      drawingCanvasRef.current?.interactionSM?.handleDrawMove(
        e,
        drawingCanvasRef.current
      );
    }
  };

  return (
    <div>
      <CanvasDraw
        hideInterface={true}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        onChange={(e) =>
          setCanvas(e as CanvasDraw & { getDataURL: () => string })
        }
        brushRadius={3}
        lazyRadius={1}
        brushColor={color}
      />

      {/* <div
        onMouseDown={handleClick}
        onMouseMove={(e) => {
          handleMouseMove(e);
        }}
      >
        <CanvasDraw
          hideInterface={true}
          canvasWidth={300}
          canvasHeight={150}
          ref={(canvasRef) => {
            if (canvasRef) {
              drawingCanvasRef.current = canvasRef;
            }
          }}
          brushRadius={3}
          lazyRadius={1}
          brushColor={color}
        />
      </div> */}

      <Flex mt={3} justifyContent="space-between" alignItems="center">
        <Flex gap={2} alignItems="center">
          <Button
            onClick={() => {
              canvas && onSendCanvas(canvas?.getDataURL());
              // if (drawingCanvasRef.current?.getDataURL) {
              //   onSendCanvas(drawingCanvasRef.current?.getDataURL());
              // }
            }}
            isDisabled={isDisable ? isDisable : false}
            style={{ backgroundColor: "#D5AD36", color: "#fff" }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              onClose();
              // drawingCanvasRef.current?.clear();
              // setIsClicked({
              //   clickCount: 0,
              //   state: false,
              // });
            }}
          >
            Cancel
          </Button>
        </Flex>

        <Flex gap={2} alignItems="center">
          <Text fontWeight={600}>Color:</Text>
          <Box
            p={0.5}
            role="button"
            onClick={() => setColor("#000000")}
            border={`${color === "#000000" ? "1px solid #000000" : "none"}`}
            borderRadius={"full"}
          >
            <CircleIcon color="#000000" size={20} />
          </Box>
          <Box
            p={0.5}
            role="button"
            onClick={() => setColor("#000080")}
            border={color === "#000080" ? "1px solid #000080" : "none"}
            borderRadius={"full"}
          >
            <CircleIcon color="#000080" size={20} />
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default ScribbleBoard;
