import { MutableRefObject, useEffect } from "react";
import { useCamera } from "../store/useCamera";
import { useMicrophone } from "../store/useMicrophone";

type usePermissionType = (
  refresh: boolean,
  ref: MutableRefObject<HTMLVideoElement>
) => {
  cameraDevices?: MediaDeviceInfo[];
  microphoneDevices?: MediaDeviceInfo[];
  selectedCamera?: MediaDeviceInfo | null;
  selectedMicrophone?: MediaDeviceInfo | null;

  selectCamera: (deviceId: MediaDeviceInfo["deviceId"]) => void;
  selectMicrophone: (deviceId: MediaDeviceInfo["deviceId"]) => void;
};

const usePermission: usePermissionType = (refresh, ref) => {
  const getCameraPermission = useCamera((state) => state.getCameraPermissions);
  const getCameras = useCamera((state) => state.getCameras);
  const cameraDevices = useCamera((state) => state.cameraDevices);
  const selectedCamera = useCamera((state) => state.selectedCamera);
  const selectCamera = useCamera((state) => state.selectCamera);

  const getMicrophonePermission = useMicrophone(
    (state) => state.getMicrophonePermissions
  );
  const getMicrophones = useMicrophone((state) => state.getMicrophones);
  const selectedMicrophone = useMicrophone((state) => state.selectedMicrophone);
  const selectMicrophone = useMicrophone((state) => state.selectMicrophone);
  const microphoneDevices = useMicrophone((state) => state.microphoneDevices);

  useEffect(() => {
    Promise.all([getCameraPermission(), getMicrophonePermission()])
      .then(([cameraPermission, microphonePermission]) => {
        if (cameraPermission && microphonePermission) {
          navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: true,
            })
            .then((stream) => {
              const videoTrack = stream.getVideoTracks()[0];
              const audioTrack = stream.getAudioTracks()[0];
              videoTrack.addEventListener("ended", () => {
                videoTrack.stop();
                audioTrack.stop();
              });
              ref.current.srcObject = stream;
              ref.current.play();
            });
        }
      })
      .catch((error) => {
        console.log("ERROR while getting permissions :", error);
      });
  }, [ref]);

  return {
    microphoneDevices,
    cameraDevices,
    selectedCamera,
    selectCamera,
    selectedMicrophone,
    selectMicrophone,
  };
};

export default usePermission;