/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatedDTO
 */
export interface UpdatedDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdatedDTO
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatedDTO
     */
    status?: number;
}

/**
 * Check if a given object implements the UpdatedDTO interface.
 */
export function instanceOfUpdatedDTO(value: object): value is UpdatedDTO {
    return true;
}

export function UpdatedDTOFromJSON(json: any): UpdatedDTO {
    return UpdatedDTOFromJSONTyped(json, false);
}

export function UpdatedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatedDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'] == null ? undefined : json['message'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UpdatedDTOToJSON(value?: UpdatedDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'status': value['status'],
    };
}

