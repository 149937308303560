/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ADDCardResponse
 */
export interface ADDCardResponse {
    /**
     * 
     * @type {object}
     * @memberof ADDCardResponse
     */
    card: object;
}

/**
 * Check if a given object implements the ADDCardResponse interface.
 */
export function instanceOfADDCardResponse(value: object): value is ADDCardResponse {
    if (!('card' in value) || value['card'] === undefined) return false;
    return true;
}

export function ADDCardResponseFromJSON(json: any): ADDCardResponse {
    return ADDCardResponseFromJSONTyped(json, false);
}

export function ADDCardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ADDCardResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'card': json['card'],
    };
}

export function ADDCardResponseToJSON(value?: ADDCardResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'card': value['card'],
    };
}

