import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import {
  PartialObjectData,
  subscribeAuditLogs,
  useAuditLogsStore,
} from "./auditLogs";

export interface IDocument {
  [key: string]: PartialObjectData[][];
}

interface IDocumentStore {
  currentDocId: number;
  setCurrentDocId: (newDocId: number) => void;
  removeCurrentDocId: () => void;
  documents: IDocument;
  additionalForm: IDocument;
  setAdditionalForms: (forms: IDocument) => void;
  addAdditionalForm: (typeOfForm: "Jurat" | "Acknowlegement") => void;
  removeAdditionalForm: (typeOfForm: "Jurat" | "Acknowlegement") => void;
  setDocuments: (key: number, value: PartialObjectData[][]) => void;
}

export const useDocumentStore = create<IDocumentStore>()(
  devtools(
    OpenReplay.createLogger("docStore")(
      // @ts-ignore
      (set, get) => ({
        currentDocId: -1,
        documents: {},
        additionalForm: {},
        setAdditionalForms: (forms: IDocument) =>
          set((state: any) => ({
            ...state,
            additionalForm: forms,
          })),
        addAdditionalForm: (typeOfForm: string) =>
          set((state: any) => ({
            ...state,
            additionalForm: {
              ...state.additionalForm,
              [get().currentDocId]:
                state.additionalForm?.[get().currentDocId] == undefined
                  ? [typeOfForm]
                  : [...state.additionalForm?.[get().currentDocId], typeOfForm],
            },
          })),
        removeAdditionalForm: (typeOfForm: string) =>
          set((state: any) => ({
            ...state,
            additionalForm: {
              ...state.additionalForm,
              [get().currentDocId]: [
                ...state.additionalForm?.[get().currentDocId].filter(
                  // @ts-ignore
                  (el) => el !== typeOfForm
                ),
              ],
            },
          })),
        setCurrentDocId: (newDocId: number) =>
          set((state: any) => {
            // if (get().currentDocId !== -1) {
            return {
              ...state,
              documents: {
                ...get().documents,
                [get().currentDocId]: useAuditLogsStore.getState().auditLogs,
              },
              currentDocId: newDocId,
            };
            // } else {
            //   return state;
            // }
          }),
        removeCurrentDocId: () => {
          set({ currentDocId: -1 });
        },
        setDocuments: (key: number, value: PartialObjectData[][]) =>
          set((state: any) => ({
            ...state,
            documents: {
              ...get().documents,
              [key]: value,
            },
          })),
      }),
      {
        name: "docStore",
      }
    )
  )
);

const subscriber = subscribeAuditLogs((state) => {
  if (useDocumentStore.getState().currentDocId !== -1) {
    useDocumentStore.setState({
      documents: {
        ...useDocumentStore.getState().documents,
        [useDocumentStore.getState().currentDocId]: state.auditLogs,
      },
    });
  }
  // useDocumentStore.setState({
  //   documents: {
  //     ...useDocumentStore.getState().documents,
  //     [useDocumentStore.getState().currentDocId]: state.auditLogs,
  //   },
  // });
});

// # TODO : Check if this is not creating any Chaos
export const { subscribe: subscribeDocumentStore } = useDocumentStore;
