import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { MdFileDownload as DownloadIcon } from "react-icons/md";
import { Session } from "../../../api";
import RecordingData from "../../commonComponents/DetailModalData/RecordingData";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";

const RecordingPreviewer = ({
  isOpen,
  onClose,
  onOpen,
  session,
  recordingKey,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  session?: Session;
  recordingKey?: string;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"5xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader pt={6} borderBottom="2px solid #aaa">
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Previewer
          </Text>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody mb={4}>
          <RecordingData session={session} recordingKey={recordingKey} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RecordingPreviewer;
