import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { Session, SessionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { getStatusBgColor } from "../../../utils/utils";

const WitnessSessionTable = ({
  sessions,
  startSession,
  isActive,
}: {
  sessions: Session[];
  startSession: (sessionID: number) => void;
  isActive: boolean;
}) => {
  const { configuration } = useAuth();
  const [isDisable, setDisable] = useState<boolean>(false);

  const handleJoin = (sessionId: number) => {
    setDisable(true);
    new SessionApi(configuration)
      .sessionControllerBookWitness({
        id: sessionId.toString(),
      })
      .then((res) => {
        startSession(sessionId);
        setDisable(false);
      })
      .catch((err) => {
        setDisable(false);
      });
  };

  return (
    <TableContainer overflowY={"auto"} maxHeight={isActive ? "100%" : "240px"}>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th color={"#000"}>SESSION ID</Th>
            {isActive && <Th color={"#000"}>STATUS</Th>}
            <Th color={"#000"}>PRIMARY SIGNER</Th>
            <Th color={"#000"}>DATE</Th>
            <Th color={"#000"}>WITNESS</Th>
            <Th color={"#000"}>ACTION</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sessions
            .sort((a, b) => (a?.createdDate < b?.createdDate ? 1 : -1))
            .flatMap((item, idx) => {
              const primarySigner = item.signers?.find(
                (el) => el?.user?.id === item.primarySignerUserId
              );

              return (
                <Tooltip key={idx} label={item.sessionId}>
                  <Tr role="button" fontWeight={500}>
                    <Td color="#9A9595" textTransform="capitalize">
                      {item.sessionId}
                    </Td>

                    {isActive && (
                      <Td>
                        <Text
                          py={"4px"}
                          fontSize="12px"
                          textAlign="center"
                          textTransform="capitalize"
                          borderRadius="20px"
                          width="80px"
                          color="#fff"
                          style={{
                            background: getStatusBgColor(item.status),
                          }}
                        >
                          {item.status}
                        </Text>
                      </Td>
                    )}

                    <Td color="#9A9595" textTransform="capitalize">
                      {primarySigner !== undefined
                        ? primarySigner?.firstName
                          ? `${primarySigner?.firstName?.toLowerCase()} ${primarySigner?.lastName?.toLowerCase()}`
                          : primarySigner?.email
                        : item.signers?.[0]?.firstName
                        ? `${item.signers?.[0]?.firstName?.toLowerCase()} ${item.signers?.[0]?.lastName?.toLowerCase()}`
                        : item.primarySignerName}
                    </Td>

                    <Td color="#9A9595">
                      {moment(item.createdDate).format("MM-DD-YYYY")}
                    </Td>

                    <Td color="#9A9595">
                      {item.witness?.length ?? 0}/{item.witnessesCount}
                    </Td>
                    <Td>
                      <Button
                        width={"fit-content"}
                        style={{
                          background:
                            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                          color: "#000",
                          padding: "0px 10px",
                          height: "25px",
                        }}
                        isDisabled={isDisable}
                        onClick={() => {
                          if (isActive) {
                            startSession(item.sessionId);
                          } else {
                            handleJoin(item.sessionId);
                          }
                        }}
                      >
                        Join
                      </Button>
                    </Td>
                  </Tr>
                </Tooltip>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default WitnessSessionTable;
