/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AverageResponse,
  DefaultDTO,
  PlatformRatingDTO,
} from '../models/index';
import {
    AverageResponseFromJSON,
    AverageResponseToJSON,
    DefaultDTOFromJSON,
    DefaultDTOToJSON,
    PlatformRatingDTOFromJSON,
    PlatformRatingDTOToJSON,
} from '../models/index';

export interface PlatformRatingControllerCreateRequest {
    platformRatingDTO: PlatformRatingDTO;
}

/**
 * 
 */
export class PlatformRatingApi extends runtime.BaseAPI {

    /**
     */
    async platformRatingControllerCreateRaw(requestParameters: PlatformRatingControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultDTO>> {
        if (requestParameters['platformRatingDTO'] == null) {
            throw new runtime.RequiredError(
                'platformRatingDTO',
                'Required parameter "platformRatingDTO" was null or undefined when calling platformRatingControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/platform-rating`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlatformRatingDTOToJSON(requestParameters['platformRatingDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultDTOFromJSON(jsonValue));
    }

    /**
     */
    async platformRatingControllerCreate(requestParameters: PlatformRatingControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultDTO> {
        const response = await this.platformRatingControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async platformRatingControllerGetAverageStarRatingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AverageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/platform-rating/average-star-rating`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AverageResponseFromJSON(jsonValue));
    }

    /**
     */
    async platformRatingControllerGetAverageStarRating(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AverageResponse> {
        const response = await this.platformRatingControllerGetAverageStarRatingRaw(initOverrides);
        return await response.value();
    }

}
