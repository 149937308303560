import { Box, Divider, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscriptionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";
import useWebSocket from "../../../utils/useWebSocket";
import { useProfileDetailsStore } from "../../../store/notaryStore";
import NotarySessionTable from "../../commonComponents/SessionTable/NotarySessionTable";
import WitnessSessionTable from "../../commonComponents/SessionTable/WitnessSessionTable";
import { notaryPaths } from "../../../routes/routes";

const OnDemandNotary = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notaryProfile } = useProfileDetailsStore();
  const [activePlan, setActivePlan] = useState<string>("Free Plan");
  const {
    availableSessionsRef,
    availableWitnessSessionsRef,
    availableBusinessSessionsRef,
    availableTitleLenderSessionsRef,
  } = useWebSocket();

  useEffect(() => {
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        if (res.notaryPlan) {
          setActivePlan(res?.notaryPlan?.planName);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"}>
      <Heading
        fontSize={"22px"}
        textAlign={"center"}
        fontFamily="Oswald"
        fontWeight={600}
      >
        On-Demand Sessions
      </Heading>

      <Box fontSize={"16px"} fontFamily={"Lato"} mb={"40px"}>
        {notaryProfile.nnaIdNumber !== undefined ||
        notaryProfile.nnaIdNumber !== "" ? (
          <Text>
            New sessions will appear in each queue below. To start the session,
            simply click on the join button as it appears in the designated
            queue. Sessions are first come first serve, the longest waiting
            session will start once clicked in the queue.
          </Text>
        ) : (
          <Text>
            “Only NNA Signing Agents will be approved to notarize for Notarize
            Genie’s business clients and On-Demand requests. However, non-NNA
            Signing Agents will be permitted to bring their own transactions,
            BYOTs.”
          </Text>
        )}
      </Box>

      {(notaryProfile.nnaIdNumber !== undefined ||
        notaryProfile.nnaIdNumber !== "") && (
        <>
          <Flex
            flexDirection={{ base: "column", md: "row", lg: "row" }}
            gap={"30px"}
          >
            <Box flexBasis={{ base: "100%", md: "50%", lg: "50%" }}>
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize={20}
                mb={6}
                textDecoration="underline"
                color="#000080"
              >
                General Notary Work
              </Text>
              {notaryProfile.onDemandStatus ? (
                availableSessionsRef?.current?.length === 0 ? (
                  <NoDataFound text="No Session Found" from="session" />
                ) : (
                  <NotarySessionTable
                    sessions={availableSessionsRef?.current}
                    startSession={(sessionId: number) => {
                      console.log("sessionId", sessionId);
                    }}
                    isActive={false}
                    from="notary"
                  />
                )
              ) : (
                <NoDataFound
                  text="Your profile must be approved before taking sessions."
                  from="session"
                />
              )}
            </Box>

            <Box>
              <Divider orientation={"vertical"} borderColor="#000" />
            </Box>

            <Box flexBasis={{ base: "100%", md: "50%", lg: "50%" }}>
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize={20}
                mb={6}
                textDecoration="underline"
                color="#000080"
              >
                Witness Request
              </Text>
              {activePlan === "Pro Plan" ? (
                <>
                  {availableWitnessSessionsRef.current?.length === 0 ? (
                    <NoDataFound text="No Session Found" from="session" />
                  ) : (
                    <WitnessSessionTable
                      sessions={availableWitnessSessionsRef.current}
                      startSession={(sessionId: number) => {
                        navigate(
                          `${notaryPaths.notaryOndemand}/witness-session/?session-id=${sessionId}`
                        );
                      }}
                      isActive={false}
                    />
                  )}
                </>
              ) : (
                <NoDataFound
                  text="To join as witness in session you need to get Pro Plan."
                  from="session"
                />
              )}
            </Box>
          </Flex>

          <Divider borderColor="#000" />

          <Flex
            flexDirection={{ base: "column", md: "row", lg: "row" }}
            gap={"30px"}
          >
            <Box flexBasis={{ base: "100%", md: "50%", lg: "50%" }}>
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize={20}
                mb={6}
                textDecoration="underline"
                color="#000080"
              >
                Business Partners
              </Text>
              {notaryProfile.businessStatus ? (
                availableBusinessSessionsRef?.current?.length === 0 ? (
                  <NoDataFound text="No Session Found" from="session" />
                ) : (
                  <NotarySessionTable
                    sessions={availableBusinessSessionsRef?.current}
                    startSession={(sessionId: number) => {
                      console.log("sessionId", sessionId);
                    }}
                    isActive={false}
                    from="notary"
                  />
                )
              ) : (
                <NoDataFound
                  text="Your profile must be approved before taking sessions."
                  from="session"
                />
              )}
            </Box>

            <Box>
              <Divider orientation={"vertical"} borderColor="#000" />
            </Box>

            <Box flexBasis={{ base: "100%", md: "50%", lg: "50%" }}>
              <Text
                textAlign="center"
                fontWeight={700}
                fontSize={20}
                mb={6}
                textDecoration="underline"
                color="#000080"
              >
                Title/Lender Package
              </Text>

              {notaryProfile.titleLenderStatus ? (
                availableTitleLenderSessionsRef?.current?.length === 0 ? (
                  <NoDataFound text="No Session Found" from="session" />
                ) : (
                  <NotarySessionTable
                    sessions={availableTitleLenderSessionsRef?.current}
                    startSession={(sessionId: number) => {
                      console.log("sessionId", sessionId);
                    }}
                    isActive={false}
                    from="notary"
                  />
                )
              ) : (
                <NoDataFound
                  text="Your profile must be approved before taking sessions."
                  from="session"
                />
              )}
            </Box>
          </Flex>
        </>
      )}
    </Stack>
  );
};

export default OnDemandNotary;
