/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AverageResponse
 */
export interface AverageResponse {
    /**
     * 
     * @type {number}
     * @memberof AverageResponse
     */
    average: number;
}

/**
 * Check if a given object implements the AverageResponse interface.
 */
export function instanceOfAverageResponse(value: object): value is AverageResponse {
    if (!('average' in value) || value['average'] === undefined) return false;
    return true;
}

export function AverageResponseFromJSON(json: any): AverageResponse {
    return AverageResponseFromJSONTyped(json, false);
}

export function AverageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AverageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'average': json['average'],
    };
}

export function AverageResponseToJSON(value?: AverageResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'average': value['average'],
    };
}

