import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ActiveProfileModal = ({
  isOpen,
  onClose,
  onOpen,
  showDataFor,
  path,
}: {
  path: string;
  showDataFor: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        navigate("/");
      }}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent p={6}>
        {/* <ModalHeader>
          <Text fontSize={"16px"}>Notice</Text>
        </ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Flex justifyContent={"center"} my={2}></Flex>
            <Text
              fontSize={{ base: "18px", md: "22px", lg: "22px" }}
              fontWeight={"900"}
              fontFamily="Lato"
              textAlign={"center"}
            >
              {showDataFor === "activePlan"
                ? "You have no active plan."
                : showDataFor === "payment"
                ? "No bank account added in your profile."
                : "Your profile is not active."}
            </Text>
            <Text
              fontSize={{ base: "16px", md: "18px", lg: "18px" }}
              fontFamily="Lato"
              fontWeight={"400"}
              textAlign={"center"}
            >
              {showDataFor === "activePlan"
                ? "You must have active plan before creating or taking sessions."
                : showDataFor === "payment"
                ? "To create or take session first add payment details."
                : "Your profile must be approved before creating or taking sessions."}
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent={"center"}>
          <Stack>
            <Button
              w={"120px"}
              background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%);"
              borderRadius="10px"
              mb={2}
              fontSize={{ base: "14px", md: "16px", lg: "16px" }}
              onClick={() => {
                onClose();
                if (showDataFor === "activePlan" || showDataFor === "payment") {
                  navigate(path);
                } else {
                  navigate("/");
                }
              }}
            >
              {showDataFor === "activePlan" ? "Get Plan" : "OK"}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ActiveProfileModal;
