/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KbaType
 */
export interface KbaType {
    /**
     * 
     * @type {number}
     * @memberof KbaType
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof KbaType
     */
    kbaLogId: string;
}

/**
 * Check if a given object implements the KbaType interface.
 */
export function instanceOfKbaType(value: object): value is KbaType {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('kbaLogId' in value) || value['kbaLogId'] === undefined) return false;
    return true;
}

export function KbaTypeFromJSON(json: any): KbaType {
    return KbaTypeFromJSONTyped(json, false);
}

export function KbaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KbaType {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'kbaLogId': json['kbaLogId'],
    };
}

export function KbaTypeToJSON(value?: KbaType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'kbaLogId': value['kbaLogId'],
    };
}

