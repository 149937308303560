import { useEffect } from "react";
import SignerSessionConnecting from "../../../../component/Sessions/Signer/SignerSessionConnecting";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SignerSessionConnectingPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(6);
  }, [setStep]);

  return <SignerSessionConnecting />;
};

export default SignerSessionConnectingPage;
