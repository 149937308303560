import { Box, Flex, Text } from "@chakra-ui/react";

const Card = ({ title, children }: { title?: string; children: any }) => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      style={{
        padding: "20px",
        boxShadow: "-1px 5px 15px 0px #ddd",
      }}
    >
      {title && (
        <Text fontSize="16px" fontWeight={700} color="#000080">
          {title}
        </Text>
      )}

      {title?.includes("Rating") ? (
        <Flex justifyContent={"center"} pt={"80px"}>
          {children}
        </Flex>
      ) : (
        children
      )}
    </Box>
  );
};

export default Card;
