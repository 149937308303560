import { create } from "zustand";

export interface IDimensionsStore {
  ratio: number;
  setRatio: (newRatio: number) => void;
}

export const useDimensionsStore = create<IDimensionsStore>((set) => ({
  ratio: 1,
  setRatio: (newRatio: number) => {
    set({
      ratio: newRatio,
    });
  },
}));
