/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    docId: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    url: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Document
     */
    user: OmitTypeClass;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): value is Document {
    if (!('docId' in value) || value['docId'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    return true;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if (json == null) {
        return json;
    }
    return {
        
        'docId': json['docId'],
        'name': json['name'] == null ? undefined : json['name'],
        'url': json['url'],
        'user': OmitTypeClassFromJSON(json['user']),
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'docId': value['docId'],
        'name': value['name'],
        'url': value['url'],
        'user': OmitTypeClassToJSON(value['user']),
    };
}

