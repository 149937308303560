/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KbaBody
 */
export interface KbaBody {
    /**
     * 
     * @type {number}
     * @memberof KbaBody
     */
    kbaLogId: number;
}

/**
 * Check if a given object implements the KbaBody interface.
 */
export function instanceOfKbaBody(value: object): value is KbaBody {
    if (!('kbaLogId' in value) || value['kbaLogId'] === undefined) return false;
    return true;
}

export function KbaBodyFromJSON(json: any): KbaBody {
    return KbaBodyFromJSONTyped(json, false);
}

export function KbaBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): KbaBody {
    if (json == null) {
        return json;
    }
    return {
        
        'kbaLogId': json['kbaLogId'],
    };
}

export function KbaBodyToJSON(value?: KbaBody | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kbaLogId': value['kbaLogId'],
    };
}

