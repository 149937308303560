import { Image } from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import deleteIcon from "../../../assets/icons/trash.svg";
import checkedIcon from "../../../assets/icons/checkbox.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { useDocumentStore } from "../../../store/documentsStore";
import { useDimensionsStore } from "../../../store/dimensions";
import { EventAction, useAuditLogsStore } from "../../../store/auditLogs";
import { SessionToolsProps } from "../../../store/zustandType";

const CheckBox = ({ object, page, from, scale }: SessionToolsProps) => {
  const ratio = useDimensionsStore((state) => state.ratio);
  const docId = useDocumentStore((state) => state.currentDocId);
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  return (
    <Rnd
      disableDragging={
        from !== "notary" && from !== "business" && from !== "edit"
      }
      enableResizing={
        from === "notary" || from === "business" || from === "edit"
      }
      bounds="parent"
      scale={scale}
      style={{
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: 0,
        zIndex: 1,
      }}
      size={{
        height: object.height
          ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 20,
        width: object.width
          ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 20,
      }}
      position={{
        x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
        y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
      }}
      onDragStop={(e, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
      onResizeStop={(e, direction, ref, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
    >
      <div
        style={{
          zIndex: 4,
        }}
      >
        {object.checked ? (
          <div
            draggable="false"
            onClick={(e) => {
              e.stopPropagation();
              updateAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                auditLog: { ...object, checked: false },
              });
              emitEvent({
                action: EventAction.update,
                event: object.type!,
                pageNumber: page,
                payload: { ...object, checked: false },
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <Image src={checkedIcon} alt="checkbox" height={6} width={6} />
          </div>
        ) : (
          <div
            draggable="false"
            onClick={(e) => {
              e.stopPropagation();
              updateAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                auditLog: { ...object, checked: true },
              });
              emitEvent({
                action: EventAction.update,
                event: object.type!,
                pageNumber: page,
                payload: { ...object, checked: true },
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <div
              style={{
                border: "1px solid gray",
                backgroundColor: "white",
                height: "20px",
                width: "20px",
                borderRadius: "4px",
              }}
            ></div>
          </div>
        )}
      </div>
      {(from === "notary" || from === "business" || from === "edit") && (
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            height: "30px",
            width: "30px",
            left: "100%",
          }}
          onClick={() => {
            deleteAuditLog({
              id: object.id!,
              selectedPage: page,
              type: object.type!,
              payload: object,
            });
            emitEvent({
              action: EventAction.delete,
              event: object.type!,
              pageNumber: page,
              payload: object,
              sessionId: sessionId,
              socket: socket,
              docId,
            });
          }}
        >
          <Image src={deleteIcon} alt="delete icon" height={6} width={6} />
        </div>
      )}
    </Rnd>
  );
};
export default CheckBox;
