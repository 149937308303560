/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResponseDTO
 */
export interface LoginResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    accessToken: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponseDTO
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    businessName: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    username: string;
}

/**
 * Check if a given object implements the LoginResponseDTO interface.
 */
export function instanceOfLoginResponseDTO(value: object): value is LoginResponseDTO {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('businessName' in value) || value['businessName'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    return true;
}

export function LoginResponseDTOFromJSON(json: any): LoginResponseDTO {
    return LoginResponseDTOFromJSONTyped(json, false);
}

export function LoginResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'userId': json['userId'],
        'role': json['role'],
        'businessName': json['businessName'],
        'username': json['username'],
    };
}

export function LoginResponseDTOToJSON(value?: LoginResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'accessToken': value['accessToken'],
        'userId': value['userId'],
        'role': value['role'],
        'businessName': value['businessName'],
        'username': value['username'],
    };
}

