/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    couponCode: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    discountValue: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    discountPercent: number;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    validity: string;
}

/**
 * Check if a given object implements the Coupon interface.
 */
export function instanceOfCoupon(value: object): value is Coupon {
    if (!('couponCode' in value) || value['couponCode'] === undefined) return false;
    if (!('discountValue' in value) || value['discountValue'] === undefined) return false;
    if (!('discountPercent' in value) || value['discountPercent'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    if (!('validity' in value) || value['validity'] === undefined) return false;
    return true;
}

export function CouponFromJSON(json: any): Coupon {
    return CouponFromJSONTyped(json, false);
}

export function CouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coupon {
    if (json == null) {
        return json;
    }
    return {
        
        'couponCode': json['couponCode'],
        'discountValue': json['discountValue'],
        'discountPercent': json['discountPercent'],
        'quantity': json['quantity'],
        'validity': json['validity'],
    };
}

export function CouponToJSON(value?: Coupon | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'couponCode': value['couponCode'],
        'discountValue': value['discountValue'],
        'discountPercent': value['discountPercent'],
        'quantity': value['quantity'],
        'validity': value['validity'],
    };
}

