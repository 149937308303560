import { useEffect } from "react";
import NNaDetailsForm from "../../../component/Details/NNaDetailsForm";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const NNaDetails = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  return <NNaDetailsForm />;
};

export default NNaDetails;
