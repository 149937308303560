/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediatorDTO,
  MeetingMediator,
  Witness,
  WitnessDTO,
} from '../models/index';
import {
    MediatorDTOFromJSON,
    MediatorDTOToJSON,
    MeetingMediatorFromJSON,
    MeetingMediatorToJSON,
    WitnessFromJSON,
    WitnessToJSON,
    WitnessDTOFromJSON,
    WitnessDTOToJSON,
} from '../models/index';

export interface MediatorControllerPostRequest {
    mediatorDTO: MediatorDTO;
}

export interface MediatorControllerWitnessPostRequest {
    witnessDTO: WitnessDTO;
}

/**
 * 
 */
export class MeetingMediatorApi extends runtime.BaseAPI {

    /**
     */
    async mediatorControllerPostRaw(requestParameters: MediatorControllerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingMediator>> {
        if (requestParameters['mediatorDTO'] == null) {
            throw new runtime.RequiredError(
                'mediatorDTO',
                'Required parameter "mediatorDTO" was null or undefined when calling mediatorControllerPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting-mediator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediatorDTOToJSON(requestParameters['mediatorDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingMediatorFromJSON(jsonValue));
    }

    /**
     */
    async mediatorControllerPost(requestParameters: MediatorControllerPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingMediator> {
        const response = await this.mediatorControllerPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediatorControllerWitnessPostRaw(requestParameters: MediatorControllerWitnessPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Witness>> {
        if (requestParameters['witnessDTO'] == null) {
            throw new runtime.RequiredError(
                'witnessDTO',
                'Required parameter "witnessDTO" was null or undefined when calling mediatorControllerWitnessPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/meeting-mediator/witness-post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WitnessDTOToJSON(requestParameters['witnessDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WitnessFromJSON(jsonValue));
    }

    /**
     */
    async mediatorControllerWitnessPost(requestParameters: MediatorControllerWitnessPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Witness> {
        const response = await this.mediatorControllerWitnessPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
