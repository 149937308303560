import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Steppers from "../../../component/commonComponents/Steppers";
import { useBusinessSessionStepStore } from "../../../store/businessStore";
import { initialSessionDetails, useSessionCreate } from "../../../store/store";
import { businessPaths } from "../../../routes/routes";

const stepData = [
  { label: "Session Type", path: "type" },
  { label: "Document Upload", path: "document-upload" },
  { label: "Participants", path: "participants" },
  { label: "Session Details", path: "details" },
  { label: "Payment Details", path: "payment" },
];

const BusinessSessionCreate = () => {
  const { step, setStep } = useBusinessSessionStepStore();
  const { setSessionDetails } = useSessionCreate();

  useEffect(() => {
    setSessionDetails(initialSessionDetails);
  }, [setSessionDetails]);

  return (
    <>
      <Steppers
        from={`${businessPaths.createSession}`}
        step={step}
        stepData={stepData}
        onSetStep={(step) => setStep(step)}
      />
      <Outlet />
    </>
  );
};

export default BusinessSessionCreate;
