/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManuallUpdateDto,
  ReqDTO,
} from '../models/index';
import {
    ManuallUpdateDtoFromJSON,
    ManuallUpdateDtoToJSON,
    ReqDTOFromJSON,
    ReqDTOToJSON,
} from '../models/index';

export interface ReqSubscriptionControllerUpdateRequest {
    manuallUpdateDto: ManuallUpdateDto;
}

/**
 * 
 */
export class RequestedSubscriptionApi extends runtime.BaseAPI {

    /**
     */
    async reqSubscriptionControllerGetPendingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReqDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/requested-subscription/pending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReqDTOFromJSON(jsonValue));
    }

    /**
     */
    async reqSubscriptionControllerGetPending(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReqDTO> {
        const response = await this.reqSubscriptionControllerGetPendingRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async reqSubscriptionControllerUpdateRaw(requestParameters: ReqSubscriptionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['manuallUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'manuallUpdateDto',
                'Required parameter "manuallUpdateDto" was null or undefined when calling reqSubscriptionControllerUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/requested-subscription/update/FreePlan`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManuallUpdateDtoToJSON(requestParameters['manuallUpdateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reqSubscriptionControllerUpdate(requestParameters: ReqSubscriptionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reqSubscriptionControllerUpdateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async reqSubscriptionControllerUpdateSubsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/requested-subscription/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reqSubscriptionControllerUpdateSubs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reqSubscriptionControllerUpdateSubsRaw(initOverrides);
    }

}
