import { PDFDocument } from "pdf-lib";

export const addForm = async (
  currentDocUrl: string,
  formToAdd: "Jurat" | "Acknowlegement"
): Promise<{ pdfWithFormB64: string; pageCount: number }> => {
  let pdfDoc: PDFDocument;
  let formPdf: PDFDocument;

  if (currentDocUrl.startsWith("https")) {
    const pdfArrayBuffer = await fetch(currentDocUrl).then((r) =>
      r.arrayBuffer()
    );
    pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  } else {
    const pdfArrayBuffer = _base64ToArrayBuffer(currentDocUrl);
    pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  }
  // ______________ check the type of form to add
  if (formToAdd === "Jurat") {
    const juratPdf = await fetch("/forms/NGJurat.pdf").then((r) =>
      r.arrayBuffer()
    );
    formPdf = await PDFDocument.load(juratPdf);
  } else {
    const acknowlegementPdf = await fetch("/forms/NGAcknowlegement.pdf").then(
      (r) => r.arrayBuffer()
    );
    formPdf = await PDFDocument.load(acknowlegementPdf);
  }

  const [copiedPage] = await pdfDoc.copyPages(formPdf, [0]);

  const pageCount = pdfDoc.getPageCount();
  pdfDoc.addPage(copiedPage);
  const pdfWithFormB64 = await pdfDoc.saveAsBase64();

  return { pdfWithFormB64, pageCount };
};

const getPdfData = async (formsToAdd: string[], pdfDoc: PDFDocument) => {
  formsToAdd.map(async (formToAdd) => {
    let formPdf: PDFDocument;

    if (formToAdd === "Jurat") {
      const juratPdf = await fetch("/forms/NGJurat.pdf").then((r) =>
        r.arrayBuffer()
      );
      formPdf = await PDFDocument.load(juratPdf);
    } else {
      const acknowlegementPdf = await fetch("/forms/NGAcknowlegement.pdf").then(
        (r) => r.arrayBuffer()
      );
      formPdf = await PDFDocument.load(acknowlegementPdf);
    }

    const [copiedPage] = await pdfDoc.copyPages(formPdf, [0]);
    pdfDoc.addPage(copiedPage);
  });

  return;
};

export const addMegaForm = async (
  currentDocUrl: string,
  formsToAdd: string[]
): Promise<{ pdfWithFormB64: string; pageCount: number } | undefined> => {
  let pdfDoc: PDFDocument;

  if (currentDocUrl.startsWith("https")) {
    const pdfArrayBuffer = await fetch(currentDocUrl).then((r) =>
      r.arrayBuffer()
    );
    pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  } else {
    return;
    // const pdfArrayBuffer = _base64ToArrayBuffer(currentDocUrl);
    // pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  }
  // ______________ check the type of form to add

  if (formsToAdd !== undefined) {
    // formsToAdd.map(async(formToAdd) => {
    for (const formToAdd of formsToAdd) {
      let formPdf: PDFDocument;

      if (formToAdd === "Jurat") {
        const juratPdf = await fetch("/forms/NGJurat.pdf").then((r) =>
          r.arrayBuffer()
        );
        formPdf = await PDFDocument.load(juratPdf);
      } else {
        const acknowlegementPdf = await fetch(
          "/forms/NGAcknowlegement.pdf"
        ).then((r) => r.arrayBuffer());
        formPdf = await PDFDocument.load(acknowlegementPdf);
      }

      const [copiedPage] = await pdfDoc.copyPages(formPdf, [0]);
      pdfDoc.addPage(copiedPage);
    }
    // })
  }

  const pageCount = pdfDoc.getPageCount();
  const pdfWithFormB64 = await pdfDoc.saveAsBase64();

  return { pdfWithFormB64, pageCount };
};

export const _base64ToArrayBuffer = (base64: string) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};
