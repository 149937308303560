import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { BiCheckCircle as CheckIcon } from "react-icons/bi";
import Select, { SingleValue } from "react-select";
import { Socket } from "socket.io-client";
import { AuditLogsApi, KbaType } from "../../../../api";
import demoFrontImg from "../../../../assets/images/demo.jpg";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";
import { useVerifyMembersStore } from "../../../../store/verifyMembers";
import { emitAuditEvent } from "../../../../utils/pdf-utils/emitEvent";
import { useAuth } from "../../../../utils/use-auth";
import { getFileUrl } from "../../../../utils/utils-apis";
import ImageModal, {
  ImageComponent,
} from "../../../commonComponents/DetailModalData/ImageModal";
import NoDataFound from "../../../commonComponents/LoadingComponents/NoDataFound";

const VerifyDocModal = ({
  isOpen,
  onOpen,
  onClose,
  allVerified,
  kbaLogsDetail,
  socket,
  onSetVerifiedMembers,
  sessionID,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  socket: Socket;
  allVerified: boolean;
  kbaLogsDetail: KbaType[];
  onSetVerifiedMembers: (data: string[]) => void;
  sessionID?: number;
}) => {
  const { configuration } = useAuth();
  const [firstScanImage, setFirstScanImage] = useState<string>("");
  const [firstBackScanImage, setFirstBackScanImage] = useState<string>("");
  const [faceImage, setFaceImage] = useState<string>("");
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const members = useVerifyMembersStore((state) => state.members);
  const verifyMember = useVerifyMembersStore((state) => state.verifyMember);
  const {
    isOpen: isImageModalOpen,
    onOpen: onImageModalOpen,
    onClose: onImageModalClose,
  } = useDisclosure();
  const [selectedImgaeUrl, setSelectedImgaeUrl] = useState<
    string | undefined
  >();

  const [data, setData] = useState<{
    firstScanImage?: string;
    firstBackScanImage?: string;
    phone: string;
    address: string;
    email: string;
    kbaStatus?: string;
    scanUrl?: string;
  }>({
    firstScanImage: "",
    firstBackScanImage: "",
    phone: "",
    address: "",
    email: "",
    kbaStatus: "pending",
  });

  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: string;
      value: number;
      role: string;
    }>
  >();

  useEffect(() => {
    if (data?.firstScanImage)
      getFileUrl(configuration, data?.firstScanImage as string).then((url) => {
        setFirstScanImage(url);
      });
  }, [data?.firstScanImage]);

  useEffect(() => {
    if (data?.firstBackScanImage) {
      // #NOTE : we didn't create new field in backend for face image, so we are using the same field for storing back image and face image
      const [backImage, faceImage] = data?.firstBackScanImage?.split("-face-");
      getFileUrl(configuration, backImage as string).then((url) => {
        setFirstBackScanImage(url);
      });

      getFileUrl(configuration, faceImage as string).then((url) => {
        setFaceImage(url);
      });
    }
  }, [data?.firstBackScanImage]);

  const handleVerify = () => {
    const payload = {
      name: selectedOption?.label,
      role: selectedOption?.role,
      ...data,
    };

    if (selectedOption?.role === "witness") {
      delete payload.firstScanImage;
      delete payload.firstBackScanImage;
    }

    if (
      (selectedOption?.role === "signer" ||
        selectedOption?.role === "primarySigner") &&
      data?.kbaStatus === "knownByNotary"
    ) {
      delete payload.firstScanImage;
      delete payload.firstBackScanImage;
    }

    const findMember = members?.find(
      (item) => item.value === selectedOption?.value
    );

    const selectedmemberIdx = members.findIndex(
      (member) => member.value === findMember?.value
    );

    if (findMember) {
      verifyMember({ member: findMember, configuration, sessionID });

      // onSetMembers((prev) => {
      //   const arr: IMembers[] = [
      //     ...prev?.slice(0, selectedmemberIdx),
      //     { ...findMember, isVerify: true },
      //     ...prev?.slice(selectedmemberIdx + 1, prev?.length),
      //   ];
      //   return arr;
      // })
    }
    //add audit logs
    emitAuditEvent({
      event: "documentVerify",
      action: "verified",
      payload,
      sessionId: sessionInfo?.sessionId?.toString() as string,
      socket,
    });
    setSelectedOption(undefined);
  };

  const handleVerificationComplete = () => {
    new AuditLogsApi(configuration)
      .auditLogsControllerGetAudiLogsBySessionId({
        sessionId: Number(sessionInfo?.sessionId),
      })
      .then((audits) => {
        const res = audits
          ?.filter((el) => el.action === "verified")
          // @ts-ignore
          ?.map((audit) => audit?.payload?.email);
        onSetVerifiedMembers(res);
        // onSetVerifiedMembers(
        //   audits
        //     .map((audit) => {
        //       if (audit.action === "verified") {
        //         // @ts-ignore
        //         return audit?.payload?.email;
        //       }
        //     })
        //     .filter(Boolean)
        // );
        onClose();
        setSelectedOption(undefined);
      });
  };

  const openImageModal = useCallback(
    (image: string) => {
      if (image === undefined) return;
      setSelectedImgaeUrl(image);
      onImageModalOpen();
    },
    [onImageModalOpen]
  );

  return (
    <Modal closeOnEsc isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            ml={2}
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
          >
            Verify ID
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p={8}>
          {members?.map((el) => el.isVerify).includes(true) && (
            <Box mb={5}>
              <Text fontSize={"16px"} fontWeight={600}>
                Verified Member
              </Text>
              <Box mt={2}>
                {members
                  ?.filter((el) => el?.isVerify === true)
                  ?.map((item, idx) => (
                    <Flex alignItems={"center"} key={idx}>
                      <CheckIcon color="green" />
                      <Text ml={2} textTransform="capitalize">
                        {item?.label}
                      </Text>
                    </Flex>
                  ))}
              </Box>
            </Box>
          )}

          <Box>
            <Text fontSize={"16px"} fontWeight={600}>
              Select Member
            </Text>

            <Box width={"30%"} mt={3} mb={5}>
              <Select
                placeholder="Select Member"
                options={members}
                value={selectedOption}
                onChange={async (option) => {
                  setSelectedOption(option);
                  if (
                    option?.role === "signer" ||
                    option?.role === "primarySigner"
                  ) {
                    const selectedSigner = sessionInfo?.signers?.find(
                      (s) => s.signerId === option?.value
                    );
                    const kbaLogId = kbaLogsDetail?.find(
                      (kba) => kba?.userId === selectedSigner?.user?.id
                    )?.kbaLogId;

                    setData({
                      scanUrl: "",
                      firstScanImage: selectedSigner?.firstScanImage,
                      firstBackScanImage: selectedSigner?.firstBackScanImage,
                      email: selectedSigner?.email as string,
                      phone: selectedSigner?.phoneNumber as string,
                      kbaStatus:
                        kbaLogId === "" ||
                        kbaLogId === undefined ||
                        kbaLogId === "pending"
                          ? "pending"
                          : kbaLogId === "knownByNotary"
                          ? "knownByNotary"
                          : kbaLogId === "demo"
                          ? "demo"
                          : "completed",
                      address:
                        selectedSigner?.addressLine1 === undefined
                          ? "No address given"
                          : `${selectedSigner?.addressLine1}, ${selectedSigner?.addressLine2} ${selectedSigner?.state}, ${selectedSigner?.city} - ${selectedSigner?.zip}`,
                    });
                  } else if (option?.role === "witness") {
                    const selectedWitness = sessionInfo?.witness?.find(
                      (s) => s.witnessId === option?.value
                    );
                    setData({
                      email: selectedWitness?.witnessEmail as string,
                      phone:
                        selectedWitness?.witnessPhone?.toString() as string,
                      address:
                        selectedWitness?.witnessAddress === undefined
                          ? "No address given"
                          : `${selectedWitness?.witnessAddress}, ${selectedWitness?.notary?.address2} ${selectedWitness?.notary?.state}, ${selectedWitness?.notary?.city} - ${selectedWitness?.notary?.zip}`,
                    });
                  } else {
                    setData({
                      firstScanImage: "",
                      firstBackScanImage: "",
                      phone: "",
                      address: "",
                      email: "",
                      kbaStatus: "pending",
                    });
                  }
                }}
              />
            </Box>

            {members?.length === 0 && (
              <Text fontSize={"13px"} color="red.600">
                No signer present in the session.
              </Text>
            )}

            {selectedOption === undefined ? (
              ""
            ) : (
              <>
                <Box mb={5}>
                  <Text>
                    <span style={{ fontWeight: 600 }}>Id</span> -{" "}
                    {selectedOption?.value}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 600 }}>Role</span> -{" "}
                    {selectedOption?.role === "primarySigner"
                      ? "Primary Signer"
                      : selectedOption?.role === "signer"
                      ? "Additional Signer"
                      : "Witness"}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 600 }}>Name</span> -{" "}
                    {selectedOption?.label}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 600 }}>Email</span> -{" "}
                    {data?.email}
                  </Text>
                  <Text>
                    <span style={{ fontWeight: 600 }}>Phone</span> -{" "}
                    {data?.phone}
                  </Text>
                  {selectedOption?.role !== "witness" && (
                    <Text>
                      <span style={{ fontWeight: 600 }}>KBA Status</span> -{" "}
                      {data?.kbaStatus === "knownByNotary"
                        ? "Known By Notary"
                        : data?.kbaStatus === "demo"
                        ? "Demo"
                        : data?.kbaStatus === "" ||
                          data?.kbaStatus === "pending"
                        ? "Pending"
                        : "Completed"}
                    </Text>
                  )}

                  <Text>
                    <span style={{ fontWeight: 600 }}>Address</span> -{" "}
                    {data?.address}
                  </Text>
                </Box>

                {selectedOption?.role !== "witness" &&
                  data?.kbaStatus !== "knownByNotary" && (
                    <Flex justifyContent={"space-between"}>
                      <Box textAlign={"center"}>
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          textDecoration="underline"
                          mb={3}
                        >
                          Front Image
                        </Text>
                        {data?.kbaStatus === "demo" ? (
                          <Image src={demoFrontImg} height="190px" />
                        ) : data?.firstScanImage === "" ||
                          data?.firstScanImage === undefined ? (
                          <NoDataFound text="No ID Found" />
                        ) : (
                          <ImageComponent
                            src={firstScanImage}
                            openImageModal={openImageModal}
                            height="190px"
                          />
                        )}
                      </Box>
                      <Divider orientation={"vertical"} size="xl" />
                      <Box textAlign={"center"}>
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          textDecoration="underline"
                          mb={3}
                        >
                          Face Image
                        </Text>
                        {data?.kbaStatus === "demo" ? (
                          <Image src={demoFrontImg} height="190px" />
                        ) : faceImage === "" || faceImage === undefined ? (
                          <NoDataFound text="No ID Found" />
                        ) : (
                          <ImageComponent
                            src={faceImage}
                            openImageModal={openImageModal}
                            height="190px"
                          />
                        )}
                      </Box>
                      <Divider orientation={"vertical"} size="xl" />
                      <Box textAlign={"center"}>
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          textDecoration="underline"
                          mb={3}
                        >
                          Back Image
                        </Text>
                        {data?.kbaStatus === "demo" ? (
                          <Image src={demoFrontImg} height="190px" />
                        ) : data?.firstBackScanImage === "" ||
                          data?.firstBackScanImage === undefined ? (
                          <NoDataFound text="No ID Found" />
                        ) : (
                          <ImageComponent
                            src={firstBackScanImage}
                            openImageModal={openImageModal}
                            height="190px"
                          />
                        )}
                      </Box>
                    </Flex>
                  )}
              </>
            )}
          </Box>
          <ImageModal
            isOpen={isImageModalOpen}
            onClose={onImageModalClose}
            title="Image"
            content={
              <Box>
                <Image
                  src={selectedImgaeUrl}
                  height="100%"
                  width={"100%"}
                  objectFit={"contain"}
                />
              </Box>
            }
            size="full"
          />
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button
            px="8"
            py="5"
            background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
            borderRadius="10px"
            fontSize={"16px"}
            _hover={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
            }}
            onClick={() => {
              if (allVerified) {
                handleVerificationComplete();
              } else {
                handleVerify();
              }
            }}
          >
            {allVerified ? "Verification Complete" : "Verify"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerifyDocModal;
