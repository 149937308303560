/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicesGraph
 */
export interface InvoicesGraph {
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    paidInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    draftInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    voidInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    openInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    uncollectible: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    unpaidInvoices: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    totalInvoicesCount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesGraph
     */
    totalInvoiceAmount: number;
}

/**
 * Check if a given object implements the InvoicesGraph interface.
 */
export function instanceOfInvoicesGraph(value: object): value is InvoicesGraph {
    if (!('paidInvoices' in value) || value['paidInvoices'] === undefined) return false;
    if (!('draftInvoices' in value) || value['draftInvoices'] === undefined) return false;
    if (!('voidInvoices' in value) || value['voidInvoices'] === undefined) return false;
    if (!('openInvoices' in value) || value['openInvoices'] === undefined) return false;
    if (!('uncollectible' in value) || value['uncollectible'] === undefined) return false;
    if (!('unpaidInvoices' in value) || value['unpaidInvoices'] === undefined) return false;
    if (!('totalInvoicesCount' in value) || value['totalInvoicesCount'] === undefined) return false;
    if (!('totalInvoiceAmount' in value) || value['totalInvoiceAmount'] === undefined) return false;
    return true;
}

export function InvoicesGraphFromJSON(json: any): InvoicesGraph {
    return InvoicesGraphFromJSONTyped(json, false);
}

export function InvoicesGraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicesGraph {
    if (json == null) {
        return json;
    }
    return {
        
        'paidInvoices': json['paidInvoices'],
        'draftInvoices': json['draftInvoices'],
        'voidInvoices': json['voidInvoices'],
        'openInvoices': json['openInvoices'],
        'uncollectible': json['uncollectible'],
        'unpaidInvoices': json['unpaidInvoices'],
        'totalInvoicesCount': json['totalInvoicesCount'],
        'totalInvoiceAmount': json['totalInvoiceAmount'],
    };
}

export function InvoicesGraphToJSON(value?: InvoicesGraph | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paidInvoices': value['paidInvoices'],
        'draftInvoices': value['draftInvoices'],
        'voidInvoices': value['voidInvoices'],
        'openInvoices': value['openInvoices'],
        'uncollectible': value['uncollectible'],
        'unpaidInvoices': value['unpaidInvoices'],
        'totalInvoicesCount': value['totalInvoicesCount'],
        'totalInvoiceAmount': value['totalInvoiceAmount'],
    };
}

