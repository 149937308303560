import { Box, Button, Divider, Image, Text } from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import "./Sidebar.css";
import { useAuth } from "../../utils/use-auth";
import logo from "../../assets/icons/logo.png";
import { getInitials } from "../../utils/utils";
import SidebarMenu from "./components/SidebarMenu";
import { getNotaryDetails } from "../../utils/utils-apis";
import logo2 from "../../assets/images/DashboardLogo.png";
import {
  IRoute,
  businessRoutes,
  notaryRoutes,
  signerRoutes,
} from "../../routes/routes";
import {
  Sidebar,
  useProSidebar,
  sidebarClasses,
  Menu,
  MenuItem,
} from "react-pro-sidebar";

const SidebarComponent = () => {
  const { configuration } = useAuth();
  const role = localStorage.getItem("role");
  const { collapseSidebar, collapsed } = useProSidebar();
  const [notaryRoutesData, setNotaryRoutesData] =
    useState<IRoute[]>(notaryRoutes);

  useEffect(() => {
    if (role === "notary") {
      getNotaryDetails(configuration).then((res) => {
        if (res.isInhouseNotary) {
          setNotaryRoutesData(
            notaryRoutes.filter((route) => route.title !== "OnDemand")
          );
        } else {
          setNotaryRoutesData(notaryRoutes);
        }
      });
    }
  }, []);

  return (
    <Sidebar
      breakPoint="md"
      defaultCollapsed
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: "#E4F1FF",
        },
      }}
      className="newSidebar"
    >
      <Box my={2} px={"auto"}>
        <Image
          src={collapsed ? logo : logo2}
          role="button"
          style={{ margin: "30px auto 12px", height: "45px" }}
          onClick={() => collapseSidebar()}
        />

        <Text textAlign={"center"} textTransform="capitalize">
          ({collapsed ? getInitials(role as string) : role})
        </Text>

        <Button
          position={"absolute"}
          right={"-0px"}
          top={"120px"}
          borderRadius={"100%"}
          height="28px"
          width="28px"
          minWidth={"0px"}
          className="arrow"
          p={0}
          zIndex={9}
          _hover={{
            background: "#D5AD36",
            color: "#fff",
          }}
          border={"1px solid #D5AD36"}
          backgroundColor={"#fff"}
          color={"#D5AD36"}
          onClick={() => collapseSidebar()}
        >
          {collapsed ? (
            <IoIosArrowForward size={20} />
          ) : (
            <IoIosArrowBack size={20} />
          )}
        </Button>
      </Box>

      <Divider borderColor={"#000080"} py={2} />

      <Box mt={7} overflowY="auto" height={"60vh"} className="sidebar-scroll">
        {role === "notary" ? (
          <SidebarMenu userRoutes={notaryRoutesData} />
        ) : role === "business" ? (
          <SidebarMenu userRoutes={businessRoutes} />
        ) : (
          <SidebarMenu userRoutes={signerRoutes} />
        )}
      </Box>

      <Box
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "#E4F1FF",
        }}
      >
        <Divider borderColor={"#000080"} mb={3} />
        <Menu style={{ margin: "0px 10px" }}>
          <MenuItem
            component={<Link to={"/logout"} />}
            icon={<BiLogOut size={25} />}
            style={{ padding: "12px" }}
          >
            <Text ml={3}>Logout</Text>
          </MenuItem>
        </Menu>
      </Box>
    </Sidebar>
  );
};

export default SidebarComponent;
