import { useCallback, useEffect } from "react";
import { Socket } from "socket.io-client";
import { DocumentApi, SessionApi } from "../../../api";
import {
  EventAction,
  EventType,
  useAuditLogsStore,
} from "../../../store/auditLogs";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl } from "../../../utils/utils-apis";
import SessionDocUpload from "../../commonComponents/UploadComponents/SessionDocUpload";

type Props = {
  sessionId: string;
  socket: Socket;
  updateDocUrl: (newDocUrl: string) => void;
  onCallApi: () => void;
};

const SessionUploadPdf = ({
  sessionId,
  socket,
  updateDocUrl,
  onCallApi,
}: Props) => {
  const { configuration } = useAuth();
  const setSessionId = useAuditLogsStore((state) => state.setSessionId);

  const uploadDocument = useCallback((path: string, name: string) => {
    // got the path of the uploaded document from fileUpload api
    // convert it into doc Path by upload it to Document Api
    new DocumentApi(configuration)
      .documentControllerCreate({
        documentDTO: {
          name: name ?? "",
          url: path,
        },
      })
      .then((doc) => {
        // updated the session with that document Id
        new SessionApi(configuration).sessionControllerUpdateSession({
          sessionId: Number(sessionId),
          sessionUpdateDTO: {
            documents: [doc.docId?.toString()],
          },
        });

        emitEvent({
          action: EventAction.docUploaded,
          event: EventType.docUploaded,
          pageNumber: undefined,
          payload: { docId: doc.docId, fileName: name },
          sessionId,
          socket,
        });
        onCallApi();
      });
  }, []);

  useEffect(() => {
    const newAuditLogListener = (data: any) => {
      if (data?.auditLog?.action === EventAction.docUploaded) {
        // now here we have the doc path and we have to get the working url from s3 by using getFileUrl util function
        getFileUrl(configuration, data?.auditLog?.payload?.url).then(
          (docUrl) => {
            updateDocUrl(docUrl);
          }
        );
      }
    };

    socket?.emit("session-connect", sessionId, (e: boolean) => {
      if (e === true) {
        socket.on("newAuditLogs", newAuditLogListener);
      }
    });

    setSessionId(sessionId);
    // setSocket(socket);
    return () => {
      socket.off("newAuditLogs", newAuditLogListener);
      socket.emit("session-disconnect");
    };
  }, []);

  return (
    <SessionDocUpload
      file={[]}
      onUpload={(file) => {
        uploadDocument(file?.[0]?.path, file?.[0]?.filename);
      }}
      from="sessionUpload"
    />
  );
};

export default SessionUploadPdf;
