//@ts-nocheck
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Stack,
  ModalBody,
  Modal,
  Flex,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import { TransactionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import card from "../../../assets/images/card.png";
import "react-datepicker/dist/react-datepicker.css";
import useNotification from "../../../utils/useNotification";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PaymentCard = ({
  isOpen,
  onClose,
  onOpen,
  customerId,
  onSave,
  isFirst,
}: {
  isFirst: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  customerId: string;
  onSave: () => void;
}) => {
  const initialData = {
    name: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    isDefault: isFirst,
  };
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const [disable, setDisable] = useState<boolean>(false);
  const [cardDetails, setCardDetails] = useState(initialData);
  const [expDate, setExpDate] = useState<Date | null>(null);

  const formik = useFormik({
    initialValues: cardDetails,

    validationSchema: Yup.object({
      cardNumber: Yup.string()
        .required("Card number is required")
        .min(18, "Card number should not be less than 15 digits")
        .max(19, "Card number should not exceed 16 digits"),
      name: Yup.string()
        .max(20, "Name must be 20 characters or less.")
        .required("Name is required"),
      // expiryDate: Yup.date()
      //   .required("Expiry Date is Required")
      //   .min(new Date(), "invalid Expiry Date "),
      cvc: Yup.string()
        .typeError("Invalid CVC number")
        .required("CVC number is required")
        .min(3, "CVC number should not be less than 3 digits")
        .max(4, "CVC number should not exceed 4 digits"),
    }),

    onSubmit: (values) => {
      setDisable(true);
      if (expDate == null) return;
      new TransactionApi(configuration)
        .transactionControllerAddCard({
          cardDTO: {
            cardNumber: values.cardNumber,
            cardCvc: values.cvc,
            cardExpMonth: expDate.getMonth() + 1,
            cardExpYear: expDate.getFullYear(),
            customerId,
          },
        })
        .then((res) => {
          if (values?.isDefault) {
            new TransactionApi(configuration)
              .transactionControllerSetdefault({
                setDTO: {
                  // @ts-ignore
                  paymentMethodId: res?.card?.id,
                  customerId: customerId,
                },
              })
              .then((res) => {
                notification("success", "Card Added Successfully");
                onSave();
              });
          } else {
            notification("success", "Card Added Successfully");
            onSave();
          }
          setDisable(false);
          setCardDetails(initialData);
        })
        .catch(() => {
          notification("fail", "Failed to Add Card");
          setDisable(false);
        });
    },
    enableReinitialize: true,
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setCardDetails(initialData);
        }}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent p={6} style={{ margin: "auto" }}>
          <ModalCloseButton />

          <ModalBody>
            <Stack>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  className=""
                  style={{
                    maxWidth: "220px",
                    width: "90%",
                    margin: "auto auto 30px",
                  }}
                >
                  <img src={card} alt="cards" />
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <Box mb={"15px"}>
                    <FormControl>
                      <FormLabel
                        style={{ fontWeight: "600", fontFamily: "Lato" }}
                      >
                        Card Number
                      </FormLabel>

                      <Input
                        as={InputMask}
                        mask="**** **** **** ****"
                        name="cardNumber"
                        {...(formik.touched.cardNumber &&
                        formik.errors.cardNumber
                          ? { isinvalid: true }
                          : "")}
                        errorBorderColor="red.300"
                        onChange={formik.handleChange}
                        placeholder="XXXX  XXXX  XXXX  XXXX"
                        style={{ fontSize: "14px" }}
                        value={formik.values.cardNumber}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          (formik.touched.cardNumber as boolean) &&
                          formik.errors.cardNumber !== "" &&
                          formik.errors.cardNumber !== undefined
                        }
                        // @ts-ignore
                        maskChar={null}
                      />

                      {formik.touched.cardNumber &&
                        formik.errors.cardNumber && (
                          <Text className="field-input-error">
                            {formik.errors.cardNumber}
                          </Text>
                        )}
                    </FormControl>
                  </Box>

                  <Box mb={"15px"}>
                    <FormLabel
                      style={{ fontWeight: "600", fontFamily: "Lato" }}
                    >
                      Card Holder's Name
                    </FormLabel>
                    <Input
                      name="name"
                      {...(formik.touched.name && formik.errors.name
                        ? { isInvalid: true }
                        : "")}
                      errorBorderColor="red.300"
                      placeholder="John Smith"
                      style={{ fontSize: "14px" }}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Text className="field-input-error">
                        {formik.errors.name}
                      </Text>
                    )}
                  </Box>

                  <Box mb={"15px"}>
                    <FormLabel
                      style={{ fontWeight: "600", fontFamily: "Lato" }}
                    >
                      Expiry Date
                    </FormLabel>
                    {/* <Input
                      name="expiryDate"
                      {...(formik.touched.expiryDate && formik.errors.expiryDate
                        ? { isInvalid: true }
                        : "")}
                        errorBorderColor="red.300"
                        placeholder=""
                        type="month"
                        onChange={formik.handleChange}
                        value={formik.values.expiryDate}
                        onBlur={formik.handleBlur}
                      /> */}

                    <div
                      style={{
                        border: "1px solid #e2e8f0",
                        borderRadius: "6px",
                        padding: "4px",
                      }}
                    >
                      <DatePicker
                        // onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        // value={formik.values.expiryDate}
                        value={
                          expDate != null
                            ? `${
                                monthNames[expDate.getMonth()]
                              } ${expDate.getFullYear()}`
                            : undefined
                        }
                        onChange={(val) => {
                          setExpDate(val);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                      />
                    </div>

                    {/* {formik.touched.expiryDate && formik.errors.expiryDate && (
                      <Text className="field-input-error">
                        {formik.errors.expiryDate}
                      </Text>
                    )} */}
                  </Box>
                  <Box mb={"15px"}>
                    <FormLabel
                      style={{ fontWeight: "600", fontFamily: "Lato" }}
                    >
                      CVC
                    </FormLabel>

                    <Input
                      as={InputMask}
                      mask="****"
                      name="cvc"
                      type="tel"
                      inputMode="numeric"
                      {...(formik.touched.cvc && formik.errors.cvc
                        ? { isinvalid: true }
                        : "")}
                      errorBorderColor="red.300"
                      onChange={formik.handleChange}
                      placeholder="XXX"
                      style={{
                        maxWidth: "100px",
                        width: "100%",
                        fontSize: "14px",
                      }}
                      value={formik.values.cvc}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        (formik.touched.cvc as boolean) &&
                        formik.errors.cvc !== "" &&
                        formik.errors.cvc !== undefined
                      }
                      // @ts-ignore
                      maskChar={null}
                    />

                    {formik.touched.cvc && formik.errors.cvc && (
                      <Text className="field-input-error">
                        {formik.errors.cvc}
                      </Text>
                    )}
                  </Box>

                  {isFirst ? null : (
                    <Box mb={"15px"}>
                      <FormLabel
                        style={{ fontWeight: "600", fontFamily: "Lato" }}
                      >
                        Set as default:
                      </FormLabel>
                      <RadioGroup
                        value={formik.values?.isDefault ? "yes" : "no"}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "isDefault",
                            value === "yes" ? true : false
                          );
                        }}
                      >
                        <Stack direction="row" style={{ marginTop: "10px" }}>
                          <Radio value="no">No</Radio>
                          <Radio value="yes" pl={3}>
                            Yes
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                  )}

                  <Flex justifyContent={"space-between"} marginTop={10}>
                    <Button
                      background="white"
                      border={"1px solid rgba(0, 0, 128, 1)"}
                      borderRadius="10px"
                      p="6"
                      fontSize={"16px"}
                      color="rgba(0, 0, 128, 1)"
                      onClick={() => {
                        onClose();
                        setCardDetails(initialData);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        background:
                          "linear-gradient(rgb(213, 173, 54) 0%, rgba(213, 173, 54, 0.5) 100%)",
                        color: "#000",
                        padding: "14px 20px",
                        height: "50px",
                      }}
                      isDisabled={disable || expDate == null}
                    >
                      Save Card
                    </Button>
                  </Flex>
                </form>
              </div>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentCard;
