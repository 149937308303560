import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
} from "@chakra-ui/react";
import Plans from "./Plans";
import { Subscription } from "../../../../api";

const PlansModal = ({
  isOpen,
  onClose,
  onOpen,
  requestedPlanData,
  isDisablePlans,
  activePlan,
}: {
  isOpen: boolean;
  activePlan?: Subscription | undefined;
  requestedPlanData?: any;
  onOpen: () => void;
  onClose: () => void;
  isDisablePlans?: boolean;
}) => {
  console.log({ activePlan });
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={"6xl"}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent p={6}>
        <ModalCloseButton
          style={{
            background: "#D5AD36",
            color: "#fff",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            position: "absolute",
            right: "-9px",
            top: "-14px",
          }}
        />
        <ModalBody>
          <Plans
            isDisablePlans={isDisablePlans}
            activePlan={activePlan}
            requestedPlanData={requestedPlanData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlansModal;
