/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DefaultResponse
 */
export interface DefaultResponse {
    /**
     * 
     * @type {string}
     * @memberof DefaultResponse
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof DefaultResponse
     */
    status: number;
}

/**
 * Check if a given object implements the DefaultResponse interface.
 */
export function instanceOfDefaultResponse(value: object): value is DefaultResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function DefaultResponseFromJSON(json: any): DefaultResponse {
    return DefaultResponseFromJSONTyped(json, false);
}

export function DefaultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'status': json['status'],
    };
}

export function DefaultResponseToJSON(value?: DefaultResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'status': value['status'],
    };
}

