/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { InvoicesResponseDTO } from './InvoicesResponseDTO';
import {
    InvoicesResponseDTOFromJSON,
    InvoicesResponseDTOFromJSONTyped,
    InvoicesResponseDTOToJSON,
} from './InvoicesResponseDTO';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';

/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {Array<Session>}
     * @memberof PaginationResponse
     */
    items: Array<Session>;
    /**
     * 
     * @type {object}
     * @memberof PaginationResponse
     */
    meta: object;
    /**
     * 
     * @type {object}
     * @memberof PaginationResponse
     */
    links: object;
    /**
     * 
     * @type {InvoicesResponseDTO}
     * @memberof PaginationResponse
     */
    invoices: InvoicesResponseDTO;
}

/**
 * Check if a given object implements the PaginationResponse interface.
 */
export function instanceOfPaginationResponse(value: object): value is PaginationResponse {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('meta' in value) || value['meta'] === undefined) return false;
    if (!('links' in value) || value['links'] === undefined) return false;
    if (!('invoices' in value) || value['invoices'] === undefined) return false;
    return true;
}

export function PaginationResponseFromJSON(json: any): PaginationResponse {
    return PaginationResponseFromJSONTyped(json, false);
}

export function PaginationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SessionFromJSON)),
        'meta': json['meta'],
        'links': json['links'],
        'invoices': InvoicesResponseDTOFromJSON(json['invoices']),
    };
}

export function PaginationResponseToJSON(value?: PaginationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SessionToJSON)),
        'meta': value['meta'],
        'links': value['links'],
        'invoices': InvoicesResponseDTOToJSON(value['invoices']),
    };
}

