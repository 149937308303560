/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Notary } from './Notary';
import {
    NotaryFromJSON,
    NotaryFromJSONTyped,
    NotaryToJSON,
} from './Notary';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';

/**
 * 
 * @export
 * @interface Recordings
 */
export interface Recordings {
    /**
     * 
     * @type {number}
     * @memberof Recordings
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Recordings
     */
    recordingKey: string;
    /**
     * 
     * @type {Notary}
     * @memberof Recordings
     */
    notary: Notary;
    /**
     * 
     * @type {Session}
     * @memberof Recordings
     */
    session: Session;
    /**
     * 
     * @type {Date}
     * @memberof Recordings
     */
    createdDate: Date;
}

/**
 * Check if a given object implements the Recordings interface.
 */
export function instanceOfRecordings(value: object): value is Recordings {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('recordingKey' in value) || value['recordingKey'] === undefined) return false;
    if (!('notary' in value) || value['notary'] === undefined) return false;
    if (!('session' in value) || value['session'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    return true;
}

export function RecordingsFromJSON(json: any): Recordings {
    return RecordingsFromJSONTyped(json, false);
}

export function RecordingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Recordings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recordingKey': json['recordingKey'],
        'notary': NotaryFromJSON(json['notary']),
        'session': SessionFromJSON(json['session']),
        'createdDate': (new Date(json['createdDate'])),
    };
}

export function RecordingsToJSON(value?: Recordings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'recordingKey': value['recordingKey'],
        'notary': NotaryToJSON(value['notary']),
        'session': SessionToJSON(value['session']),
        'createdDate': ((value['createdDate']).toISOString()),
    };
}

