//Icons
import {
  AiOutlineHistory,
  AiOutlineHome,
  AiOutlineSetting,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { GrDocumentVerified } from "react-icons/gr";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOndemandVideo, MdOutlinePayments } from "react-icons/md";
import { HiOutlineDocumentPlus, HiOutlineUserGroup } from "react-icons/hi2";
import { TbCertificate, TbFileInvoice } from "react-icons/tb";

import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import NotaryDashboard from "../pages/Notary/NotaryDashboard";
import OnDemandNotaryPage from "../pages/Notary/OnDemand/OnDemandNotaryPage";
import CommissionDetails from "../pages/Notary/Profile/CommissionDetails";
import DigitalCertificate from "../pages/Notary/Profile/DigitalCertificate";
import InsuranceDetails from "../pages/Notary/Profile/InsuranceDetails";
import NNaDetails from "../pages/Notary/Profile/NNaDetails";
import PaymentInfo from "../pages/Notary/Profile/PaymentInfo";
import PersonalInformation from "../pages/Notary/Profile/PersonalInformation";
import SignatureAndSeal from "../pages/Notary/Profile/SignatureAndSeal";
import DocumentUploadPage from "../pages/Notary/Sessions/CreateSession/DocumentUploadPage";
import NotaryMeetingPage from "../pages/Notary/Sessions/CreateSession/NotaryMeetingsPage";
import NotaryNotePage from "../pages/Notary/Sessions/CreateSession/NotaryNotePage";
import PaymentPage from "../pages/Notary/Sessions/CreateSession/PaymentPage";
import PrimarySignerPage from "../pages/Notary/Sessions/CreateSession/PrimarySignerPage";
import SignerDashboard from "../pages/Signer/SignerDashboard";
import SessionDocumentUploadPage from "../pages/Signer/Sessions/CreateSession/SessionDocumentUploadPage";
import SessionParticipantsPage from "../pages/Signer/Sessions/CreateSession/SessionParticipantsPage";
import SessionPaymentPage from "../pages/Signer/Sessions/CreateSession/SessionPaymentPage";
import SignerVerificationPage from "../pages/Signer/Sessions/CreateSession/SignerVerificationPage";
import Profile from "../pages/Notary/Profile/Profile";
import NotarySession from "../pages/Notary/Sessions/NotarySession";
import SignerSession from "../pages/Signer/Sessions/SignerSession";
import SignerSessionConnectingPage from "../pages/Signer/Sessions/CreateSession/SignerSessionConnectingPage";
import BusinessDashboard from "../pages/Business/BusinessDashboard";
import BusinessDocuments from "../pages/Business/BusinessDocuments";
import List from "../pages/Signer/SessionsList/List";
import BusinessSessionCreate from "../pages/Business/BusinessSessions/BusinessSessionCreate";
import BusinessSessionParticipantsPage from "../pages/Business/BusinessSessions/CreateSession/BusinessSessionParticipantsPage";
import BusinessSessionPaymentPage from "../pages/Business/BusinessSessions/CreateSession/BusinessSessionPaymentPage";
import BusinessDocUploadPage from "../pages/Business/BusinessSessions/CreateSession/BusinessDocUploadPage";
import Invoices from "../pages/Notary/Invoices/Invoices";
import ActiveNotarySessionsPage from "../pages/Notary/Sessions/ActiveNotarySessionsPage";
import SignerInvoices from "../pages/Signer/SignerInvoices/SignerInvoices";
import BusinessInvoices from "../pages/Business/BusinessInvoices/BusinessInvoices";
import BusinessSession from "../pages/Business/BusinessSessions/BusinessSession";
import BusinessActiveSession from "../pages/Business/BusinessActiveSession/BusinessActiveSession";
import BusinessSchedulePage from "../pages/Business/BusinessSessions/CreateSession/BusinessSchedulePage";
import SessionNotaryDetailPage from "../pages/Signer/Sessions/CreateSession/SessionNotaryDetailPage";
import BusinessInhouseNotary from "../pages/Business/BusinessInhouseNotary/BusinessInhouseNotary";
import NotaryJournalPage from "../pages/Notary/NotaryJournal/NotaryJournalPage";
import SignerSessionCreate from "../pages/Signer/Sessions/SignerSessionCreate";
import NotaryCreateSession from "../pages/Notary/Sessions/NotaryCreateSession";
import ActiveWitnessSessionsPage from "../pages/Notary/Sessions/ActiveWitnessSessionsPage";
import SessionTypePage from "../pages/Notary/Sessions/CreateSession/SessionTypePage";
import SignerSessionTypePage from "../pages/Signer/Sessions/CreateSession/SignerSessionTypePage";
import BusinessSessionTypePage from "../pages/Business/BusinessSessions/CreateSession/BusinessSessionTypePage";
import { FiEdit } from "react-icons/fi";
export interface IRoute {
  title: string;
  route: string;
  icon?: JSX.Element;
  component?: any;
  children?: IRoute[];
}

export const publicRoutes = [
  {
    title: "Sign In",
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    title: "Sign Up",
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    title: "Forgot Password",
    route: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    title: "Reset Password",
    route: "/reset-password",
    component: <ResetPassword />,
  },

  {
    title: "404",
    route: "*",
    component: <SignIn />,
  },
];

export const notaryPaths = {
  notaryInvoices: "/notary/invoices",
  notaryJournal: "/notary/journal",
  notaryOndemand: "/notary/on-demand",
  profileSetting: "/settings/profile",
  session: "/notary/sessions",
  createSession: "/notary/sessions/create",
};

export const signerPaths = {
  signerInvoices: "/signer/invoices",
  activeSessions: "/signer/active",
  list: "/signer/list",
  session: "/signer/sessions",
  createSession: "/signer/sessions/create",
};

export const businessPaths = {
  activeSessions: "/business/active-session",
  businessInvoices: "/business/invoices",
  businessDocuments: "/business/documents",
  session: "/business/sessions",
  createSession: "/business/sessions/create",
  inHouseNotary: "/business/inhouse-notary",
};

export const notaryRoutes: IRoute[] = [
  {
    title: "Dashboard",
    route: "/",
    icon: <AiOutlineHome size={25} />,
    component: <NotaryDashboard />,
  },
  {
    title: "Sessions",
    route: `${notaryPaths.session}`,
    component: <NotarySession />,
    icon: <FiEdit size={24} />,
    children: [
      {
        title: "Create Session",
        route: `${notaryPaths.createSession}`,
        icon: <FiEdit />,
        component: <NotaryCreateSession />,
        children: [
          {
            title: "Session Type",
            route: `${notaryPaths.createSession}/type`,
            component: <SessionTypePage />,
          },
          {
            title: "Primary Signer",
            route: `${notaryPaths.createSession}/primary-signer`,
            component: <PrimarySignerPage />,
          },
          {
            title: "Notary Meeting",
            route: `${notaryPaths.createSession}/notary-meetings`,
            component: <NotaryMeetingPage />,
          },
          {
            title: "Document Upload",
            route: `${notaryPaths.createSession}/document-upload`,
            component: <DocumentUploadPage />,
          },
          {
            title: "Notary Note",
            route: `${notaryPaths.createSession}/notary-note`,
            component: <NotaryNotePage />,
          },
          {
            title: "Payment",
            route: `${notaryPaths.createSession}/payment`,
            component: <PaymentPage />,
          },
        ],
      },
      {
        title: "My Sessions",
        route: `${notaryPaths.session}/active`,
        icon: <MdOndemandVideo />,
        component: <ActiveNotarySessionsPage />,
      },
      {
        title: "My Witness Sessions",
        route: `${notaryPaths.session}/witness-sessions`,
        icon: <MdOndemandVideo />,
        component: <ActiveWitnessSessionsPage />,
      },
    ],
  },
  {
    title: "OnDemand",
    route: `${notaryPaths.notaryOndemand}`,
    icon: <MdOndemandVideo size={26} />,
    component: <OnDemandNotaryPage />,
  },
  {
    title: "Notary Journal",
    route: `${notaryPaths.notaryJournal}`,
    icon: <AiOutlineHistory size={25} />,
    component: <NotaryJournalPage />,
  },
  {
    title: "Invoices",
    route: `${notaryPaths.notaryInvoices}`,
    icon: <TbFileInvoice size={25} />,
    component: <Invoices />,
  },
  {
    title: "Settings",
    route: "/settings",
    icon: <AiOutlineSetting size={25} />,
    children: [
      {
        title: "My Profile",
        icon: <CgProfile />,
        route: `${notaryPaths.profileSetting}`,
        component: <Profile />,
        children: [
          {
            title: "Personal Information",
            route: `${notaryPaths.profileSetting}/personal-information`,
            icon: <IoDocumentTextOutline />,
            component: <PersonalInformation />,
          },
          {
            title: "Commission Details",
            route: `${notaryPaths.profileSetting}/commission-details`,
            icon: <GrDocumentVerified />,
            component: <CommissionDetails />,
          },
          {
            title: "Insurance Details",
            route: `${notaryPaths.profileSetting}/insurance-details`,
            icon: <HiOutlineDocumentPlus />,
            component: <InsuranceDetails />,
          },
          {
            title: "NNA Details",
            route: `${notaryPaths.profileSetting}/nna-details`,
            component: <NNaDetails />,
          },
          {
            title: "Digital Certificate",
            route: `${notaryPaths.profileSetting}/digital-certificate`,
            component: <DigitalCertificate />,
          },
          {
            title: "Signature & Seal",
            route: `${notaryPaths.profileSetting}/signature-seal`,
            icon: <TbCertificate />,
            component: <SignatureAndSeal />,
          },
          {
            title: "Payment Info",
            route: `${notaryPaths.profileSetting}/payment-info`,
            icon: <MdOutlinePayments />,
            component: <PaymentInfo />,
          },
        ],
      },
    ],
  },
];

export const signerRoutes: IRoute[] = [
  {
    title: "Dashboard",
    route: "/",
    icon: <AiOutlineHome size={25} />,
    component: <SignerDashboard />,
  },
  {
    title: "Notarize a Document",
    route: `${signerPaths.session}`,
    icon: <FiEdit size={24} />,
    component: <SignerSession />,
    children: [
      {
        title: "Create",
        route: `${signerPaths.createSession}`,
        icon: <FiEdit />,
        component: <SignerSessionCreate />,
        children: [
          {
            title: "Session Type",
            route: `${signerPaths.createSession}/type`,
            component: <SignerSessionTypePage />,
          },
          {
            title: "Document Upload",
            route: `${signerPaths.createSession}/document-upload`,
            component: <SessionDocumentUploadPage />,
          },
          {
            title: "Paticipants",
            route: `${signerPaths.createSession}/participants`,
            component: <SessionParticipantsPage />,
          },
          {
            title: "Payment",
            route: `${signerPaths.createSession}/payment`,
            component: <SessionPaymentPage />,
          },
          {
            title: "Notary Detail",
            route: `${signerPaths.createSession}/notary-detail`,
            component: <SessionNotaryDetailPage />,
          },
          // {
          //   title: "Custom Email",
          //   route: `${signerPaths.createSession}/custom-email`,
          //   component: <CustomSignerEmailPage />,
          // },
          {
            title: "Signer Verification",
            route: `${signerPaths.createSession}/signer-verification`,
            component: <SignerVerificationPage />,
          },
          {
            title: "Signer Session",
            route: `${signerPaths.createSession}/signer-session-connecting`,
            component: <SignerSessionConnectingPage />,
          },
        ],
      },
    ],
  },
  // {
  //   title: "Active Sessions",
  //   route: `${signerPaths.activeSessions}`,
  //   icon: <MdOndemandVideo size={25} />,
  //   component: <ActiveSignerSessions />,
  // },
  {
    title: "My Sessions",
    route: `${signerPaths.list}`,
    icon: <AiOutlineHistory size={25} />,
    // icon: <DocIcon />,
    component: <List />,
  },
  {
    title: "Invoices",
    route: `${signerPaths.signerInvoices}`,
    icon: <TbFileInvoice size={25} />,
    component: <SignerInvoices />,
  },
];

export const businessRoutes: IRoute[] = [
  {
    title: "Dashboard",
    route: "/",
    icon: <AiOutlineHome size={25} />,
    component: <BusinessDashboard />,
  },
  {
    title: "Sessions",
    route: `${businessPaths.session}`,
    icon: <FiEdit size={24} />,
    component: <BusinessSession />,
    children: [
      {
        title: "Create",
        route: `${businessPaths.createSession}`,
        icon: <FiEdit />,
        component: <BusinessSessionCreate />,
        children: [
          {
            title: "Session Type",
            route: `${businessPaths.createSession}/type`,
            component: <BusinessSessionTypePage />,
          },
          {
            title: "Document Upload",
            route: `${businessPaths.createSession}/document-upload`,
            component: <BusinessDocUploadPage />,
          },
          {
            title: "Paticipants",
            route: `${businessPaths.createSession}/participants`,
            component: <BusinessSessionParticipantsPage />,
          },
          {
            title: "Session Details",
            route: `${businessPaths.createSession}/details`,
            component: <BusinessSchedulePage />,
          },
          {
            title: "Payment",
            route: `${businessPaths.createSession}/payment`,
            component: <BusinessSessionPaymentPage />,
          },
        ],
      },
    ],
  },
  {
    title: "Active Sessions",
    route: `${businessPaths.activeSessions}`,
    icon: <MdOndemandVideo size={25} />,
    component: <BusinessActiveSession />,
  },
  {
    title: "My Sessions",
    route: `${businessPaths.businessDocuments}`,
    icon: <AiOutlineHistory size={25} />,
    component: <BusinessDocuments />,
  },
  {
    title: "In-house Notary",
    route: `${businessPaths.inHouseNotary}`,
    icon: <HiOutlineUserGroup size={25} />,
    component: <BusinessInhouseNotary />,
  },
  {
    title: "Invoices",
    route: `${businessPaths.businessInvoices}`,
    icon: <TbFileInvoice size={25} />,
    component: <BusinessInvoices />,
  },
];
