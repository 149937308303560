/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignedPdfDTO
 */
export interface SignedPdfDTO {
    /**
     * 
     * @type {string}
     * @memberof SignedPdfDTO
     */
    pdfKey: string;
    /**
     * 
     * @type {string}
     * @memberof SignedPdfDTO
     */
    auditKey: string;
    /**
     * 
     * @type {string}
     * @memberof SignedPdfDTO
     */
    docId: string;
}

/**
 * Check if a given object implements the SignedPdfDTO interface.
 */
export function instanceOfSignedPdfDTO(value: object): value is SignedPdfDTO {
    if (!('pdfKey' in value) || value['pdfKey'] === undefined) return false;
    if (!('auditKey' in value) || value['auditKey'] === undefined) return false;
    if (!('docId' in value) || value['docId'] === undefined) return false;
    return true;
}

export function SignedPdfDTOFromJSON(json: any): SignedPdfDTO {
    return SignedPdfDTOFromJSONTyped(json, false);
}

export function SignedPdfDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignedPdfDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'pdfKey': json['pdfKey'],
        'auditKey': json['auditKey'],
        'docId': json['docId'],
    };
}

export function SignedPdfDTOToJSON(value?: SignedPdfDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pdfKey': value['pdfKey'],
        'auditKey': value['auditKey'],
        'docId': value['docId'],
    };
}

