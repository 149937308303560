import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./pdfEditor.css";
import { HiOutlineUpload as UploadIcon } from "react-icons/hi";
import { getDocument, PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Socket } from "socket.io-client";
import PdfPage from "./components/PdfPage";
import PdfHeader from "./components/PdfHeader";
import { useAuth } from "../../utils/use-auth";
import { useResponsive } from "../../store/store";
import SignerTools from "./components/SignerTools";
import NotaryTools from "./components/NotaryTools";
import { INotaryUrlData } from "../../store/zustandType";
import AddDocumentModal from "./modals/AddDocumentModal";
import SessionUploadPdf from "./components/SessionUploadPdf";
import { removeForm } from "../../utils/pdf-utils/removeForm";
import { AuditLogsApi, FileUploadApi, SessionApi } from "../../api";
import { EventAction, useAuditLogsStore } from "../../store/auditLogs";
import { IDocument, useDocumentStore } from "../../store/documentsStore";
import LoadingContent from "../commonComponents/LoadingComponents/LoadingContent";
import {
  useCurrentDocStore,
  useSessionDocStore,
} from "../../store/sessionDocStore";
import {
  _base64ToArrayBuffer,
  addForm,
  addMegaForm,
} from "../../utils/pdf-utils/addForm";

type Props = {
  sessionId: string;
  socket: Socket;
  from: string;
  notaryUrlData: INotaryUrlData;
};

const SessionPdfEditor = ({
  sessionId,
  socket,
  from,
  notaryUrlData,
}: Props) => {
  const { configuration } = useAuth();
  const { isMobileView } = useResponsive();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(true);
  const [pages, setPages] = useState<PDFPageProxy[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedPage, setSelectedpage] = useState<number>(0);
  const pdfDoc = useRef() as MutableRefObject<PDFDocumentProxy>;
  const [callApiAgain, setCallApiAgain] = useState<boolean>(true);
  const [isNotaryExemplarSheet, setIsNotaryExemplarSheet] =
    useState<boolean>(false);

  const addAdditionalForm = useDocumentStore(
    (state) => state.addAdditionalForm
  );
  const removeAdditionalForm = useDocumentStore(
    (state) => state.removeAdditionalForm
  );
  const setAdditionalForms = useDocumentStore(
    (state) => state.setAdditionalForms
  );
  const removeCurrentDocId = useDocumentStore(
    (state) => state.removeCurrentDocId
  );
  const [currentDocId, setCurrentDocId] = useDocumentStore((state) => [
    state.currentDocId,
    state.setCurrentDocId,
  ]);
  const [sessionDocs, addSessionDoc, setSessionDocs] = useSessionDocStore(
    (state) => [state.sessionDocs, state.addSessionDoc, state.setSessionDocs]
  );
  const [removeCurrentDocUrl, setCurrentDocUrl, currentDocUrl] =
    useCurrentDocStore((state) => [
      state.removeCurrentDocUrl,
      state.setCurrentDocUrl,
      state.currentDocUrl,
    ]);
  const [
    addAuditLog,
    removeAuditLog,
    deleteAuditLog,
    updateAuditLog,
    initialize,
    setSessionId,
    setAuditLogs,
    setSocket,
  ] = useAuditLogsStore((state) => [
    state.addAuditLog,
    state.removeAuditLog,
    state.deleteAuditLog,
    state.updateAuditLog,
    state.initialize,
    state.setSessionId,
    state.setAuditLogs,
    state.setSocket,
  ]);

  const getSessionDocs = () => {
    new SessionApi(configuration)
      .sessionControllerGetById({
        sessionId: Number(sessionId),
      })
      .then((session) => {
        if (session.isNotaryExemplarSheet) {
          setIsNotaryExemplarSheet(session.isNotaryExemplarSheet);
        }
        if (session.documents) {
          setSessionDocs(session.documents);
        }
        setCallApiAgain(false);
      });
  };

  useEffect(() => {
    if (sessionId && callApiAgain) {
      getSessionDocs();
    }
  }, [callApiAgain, sessionId]);

  useEffect(() => {
    if (sessionDocs.length !== 0) {
      setCurrentDocId(
        sessionDocs.find((document) => document.docId === currentDocId)
          ?.docId || sessionDocs[0].docId
      );
    } else {
      removeCurrentDocUrl();
      removeCurrentDocId();
    }
  }, [callApiAgain, sessionId]);

  // ______________________ load SessionDocument ________________

  const loadDocument = useCallback(() => {
    const key =
      currentDocId !== -1
        ? sessionDocs.find((document) => document.docId === currentDocId)?.url
        : sessionDocs[0]?.url;

    if (key !== undefined) {
      new FileUploadApi(configuration)
        .fileControllerUrlS3({
          key,
        })
        .then((res) => {
          setCurrentDocUrl(res.url);
        });
    }
  }, [currentDocId, sessionDocs]);

  useEffect(() => {
    if (sessionDocs.length !== 0) {
      if (isNotaryExemplarSheet) {
        setCurrentDocUrl(
          "https://notarizegenie-assets.s3.amazonaws.com/images/Exemplar.Sheet.pdf"
        );
      } else {
        loadDocument();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDocs, currentDocId, isNotaryExemplarSheet]);

  // __________________________ load pdf ________________
  const loadPdf = useCallback(async (url: string) => {
    // # IMPORTANT: send updated pdf here in getDocument()
    let urlArrayBufferOrString: ArrayBuffer | string;
    if (url.startsWith("http")) {
      urlArrayBufferOrString = url;
    } else {
      urlArrayBufferOrString = _base64ToArrayBuffer(url);
    }
    if (pdfDoc.current) {
      await pdfDoc.current.destroy();
    }
    pdfDoc.current = await getDocument(urlArrayBufferOrString).promise;
    const pageCount = pdfDoc.current.numPages;
    setTotalPages(pageCount);
    const pdfPages = new Array(pageCount)
      .fill(undefined)
      .map((_, i) => pdfDoc.current.getPage(i + 1));
    const allObjects = pdfPages.map(() => []);
    setAuditLogs(allObjects);
    Promise.all(pdfPages).then((r) => {
      setPages(r);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (currentDocUrl !== "") {
      loadPdf(currentDocUrl).then(() => {
        setTimeout(() => setLoading(false), 1000);
      });
    } else {
      setLoading(false);
    }
  }, [currentDocUrl]);

  useEffect(() => {
    // # TODO : detect all added forms in current session with their docIds
    let forms: IDocument = {};
    new AuditLogsApi(configuration)
      .auditLogsControllerGetAudiLogsForm({
        docId: currentDocId.toString(),
        sessionId: Number(sessionId),
      })
      .then((data) => {
        for (const audit of data) {
          if (audit?.action === EventAction.addedForm) {
            forms = {
              ...forms,
              [audit?.docId]:
                // @ts-ignore
                forms?.[audit?.docId] === undefined
                  ? // @ts-ignore
                    [audit?.payload?.typeOfForm]
                  : [
                      // @ts-ignore
                      ...forms?.[audit?.docId],
                      // @ts-ignore
                      audit?.payload?.typeOfForm,
                    ],
            };
          }
        }
        setAdditionalForms(forms);
        if (forms[currentDocId]?.length !== 0) {
          addMegaForm(
            currentDocUrl,
            forms[currentDocId] as unknown as string[]
          ).then((res) => {
            res && setCurrentDocUrl(res.pdfWithFormB64);
          });
        }
      });
    // additionaForms Store is updtaing but changes are not reflecting on REFRESH
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDocId,
    currentDocUrl,
    sessionId,
    setAdditionalForms,
    setCurrentDocUrl,
  ]);

  useEffect(() => {
    const section = document.querySelector(`#page${selectedPage}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [selectedPage]);

  useEffect(() => {
    if (loading) return;
    const newAuditLogListener = (data: any) => {
      // _________________ Add & Remove form ______________

      if (data?.auditLog?.action === EventAction.addedForm) {
        if (currentDocUrl !== "") {
          // getting the type of form added by notary
          addForm(currentDocUrl, data?.auditLog?.payload?.typeOfForm).then(
            (res) => {
              setCurrentDocUrl(res.pdfWithFormB64);
              addAdditionalForm(data?.auditLog?.payload?.typeOfForm);
            }
          );
        }
      }

      if (data?.auditLog?.action === EventAction.remove) {
        removeAuditLog({ pageNumber: data?.auditLog?.pageNumber });
        removeForm(currentDocUrl, data?.auditLog?.pageNumber).then(
          (pdfWithRemovedPage) => {
            setCurrentDocUrl(pdfWithRemovedPage);
            removeAdditionalForm(data?.auditLog?.payload?.typeOfForm);
          }
        );
      }

      if (data?.auditLog?.action === EventAction.newDocAdded) {
        addSessionDoc(data?.auditLog?.payload?.doc);
      }

      // Important : this will change the current document on both sides when the notary or signer selects a different Document during the session
      if (data?.auditLog?.action === EventAction.navigatedToDifferentDoc) {
        setCurrentDocId(Number(data?.auditLog?.payload?.docId));
      }

      // Important : this will change the current page on both sides when the notary or signer selects a different page during the session
      if (data?.auditLog?.action === EventAction.navigatedToSelectedPage) {
        setSelectedpage(Number(data?.auditLog?.pageNumber));
      }

      if (data?.auditLog?.userId === Number(localStorage.getItem("UserID")))
        return;
      if (data?.auditLog?.action === "add") {
        addAuditLog({
          selectedPage: data?.auditLog?.pageNumber,
          payload: data?.auditLog?.payload,
          userId: data?.auditLog?.userId,
          docId: data?.auditLog?.docId,
        });
      } else if (data?.auditLog?.action === "delete") {
        deleteAuditLog({
          id: data?.auditLog?.payload?.id,
          selectedPage: data?.auditLog?.pageNumber,
          type: data?.auditLog?.payload?.type,
          payload: data?.auditLog?.payload,
        });
      } else if (data?.auditLog?.action === "update") {
        updateAuditLog({
          auditLog: data?.auditLog?.payload,
          id: data?.auditLog?.payload?.id,
          selectedPage: data?.auditLog?.pageNumber,
          type: data?.auditLog?.payload?.type,
        });
      }
    };

    socket?.emit("session-connect", sessionId, (e: boolean) => {
      if (e) {
        new AuditLogsApi(configuration)
          .auditLogsControllerGetAudiLogsForm({
            docId: currentDocId.toString(),
            sessionId: Number(sessionId),
          })
          .then((data) => {
            initialize(data);
          });
        socket.on("newAuditLogs", newAuditLogListener);
      }
    });

    setSessionId(sessionId);
    setSocket(socket);
    return () => {
      socket.off("newAuditLogs", newAuditLogListener);
      socket.emit("session-disconnect");
    };
  }, [loading]);

  return (
    <>
      <Flex
        direction={{ base: "column-reverse", md: "row", lg: "row" }}
        background={"#fff"}
        height="100%"
      >
        <Box
          height={{ base: "80%", md: "100%", lg: "100%" }}
          width={[
            "100%",
            "100%",
            from === "notary" || from === "signer" || from === "edit"
              ? "80%"
              : "100%",
          ]}
        >
          {loading ? (
            <LoadingContent text="Loading document" />
          ) : currentDocUrl === "" ? (
            <Box p={8}>
              <SessionUploadPdf
                socket={socket}
                sessionId={sessionId}
                updateDocUrl={(newDocUrl) => {
                  setCurrentDocUrl(newDocUrl);
                  setCallApiAgain(true);
                }}
                onCallApi={() => setCallApiAgain(true)}
              />
            </Box>
          ) : (
            <Box height={"100%"} borderRight="1px solid #aaa">
              <Box
                height={{ base: "13%", md: "10%", lg: "10%" }}
                borderBottom="1px solid #aaa"
                px={2}
              >
                <PdfHeader
                  from={from}
                  sessionId={sessionId}
                  socket={socket}
                  totalPages={totalPages}
                  selectedPage={selectedPage}
                  onSetSelectedPage={(page) => setSelectedpage(page)}
                />
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                // height={"89%"}
                height={{ base: "87%", md: "90%", lg: "90%" }}
                overflowY={"auto"}
                paddingX={isMobileView ? "20px" : "0px"}
              >
                {pages?.map((page, idx) => (
                  <Box key={idx} border="1px solid #aaa">
                    <PdfPage
                      sessionId={sessionId}
                      socket={socket}
                      page={page}
                      index={idx}
                      setSelectedPage={() => setSelectedpage(idx)}
                      pageNumber={idx}
                      from={from}
                      notaryUrlData={notaryUrlData}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {currentDocUrl !== "" &&
          (from === "notary" || from === "signer" || from === "edit") && (
            <Box
              p={3}
              height={["20%", "100%", "100%"]}
              w={["100%", "100%", "23%"]}
              overflowY="auto"
              wordBreak={"break-all"}
              borderBottom="1px solid #aaa"
            >
              <Text fontSize={"2xl"} mb={3} textAlign="center">
                Tools
              </Text>

              <Flex
                direction={{ base: "row", md: "column", lg: "column" }}
                gap={2}
                flexWrap="wrap"
              >
                {!isNotaryExemplarSheet && from !== "edit" && (
                  <Button onClick={onOpen} justifyContent="left">
                    <UploadIcon />
                    <Text className="tools-button-text">Add Document</Text>
                  </Button>
                )}

                {from === "notary" || from === "edit" ? (
                  <NotaryTools from={from} />
                ) : (
                  <SignerTools />
                )}
              </Flex>
            </Box>
          )}
      </Flex>
      <AddDocumentModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default SessionPdfEditor;
