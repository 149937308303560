import { useEffect } from "react";
import SignerVerification from "../../../../component/Sessions/Signer/SignerVerification";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SignerVerificationPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(5);
  }, [setStep]);

  return <SignerVerification />;
};

export default SignerVerificationPage;
