import moment from "moment";
import { StateFullnameOptions } from "./options";

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const currentDate = new Date().getDate();
export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth() + 1;
export const currentTrimester = Math.ceil(currentMonth / 3);
export const today = moment(new Date()).format("YYYY-MM-DD");

export const getDateAfterDays = (givenDate: Date) => {
  moment(moment(givenDate, "YYYY-MM-DD")?.add(30, "days")).format("MM-DD-YYYY");
};

export const capitalize = (text: string) => {
  return text[0].toUpperCase() + text.slice(1, text.length);
};

export const regexPassword = new RegExp(
  `^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$`
);

export const espTransform = (amount: number) => {
  return "$" + (amount / 100)?.toFixed(2);
};

export const espTransformDocFee = (amount: number) => {
  return "$" + amount?.toFixed(2);
};

export const getInitials = (name: string) => {
  var names = name?.split(" "),
    initials = names?.[0]?.substring(0, 1)?.toUpperCase();
  if (names?.length > 1) {
    initials += names?.[names?.length - 1]?.substring(0, 1)?.toUpperCase();
  }
  return initials;
};

export const getStatusBgColor = (status: string) => {
  if (status === "completed") {
    return "#4d9660";
  } else if (status === "terminated" || status === "expired") {
    return "#db4f37";
  } else if (status === "booked") {
    return "#4f75c9";
  } else if (status === "knownByNotary") {
    return "#4f75c9";
  }
  // else if (status === "pending") {
  //   return "#f9b452";
  // }
  else {
    return "grey";
  }
};

export const getInvoiceStatusBgColor = (status: string) => {
  if (status === "paid") {
    return "#4d9660";
  } else if (status === "unpaid") {
    return "#db4f37";
  } else if (status === "pending") {
    return "#4f75c9";
  } else if (status === "open") {
    return "#D5AD36";
  } else {
    return "grey";
  }
};

export const validateBirthday = (birthday: string) => {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
    return false;
  }
  let parts = birthday.split("-");
  let now = new Date();
  let year = parseInt(parts[0], 10);
  let currentYear = now.getFullYear();
  let month =
    parts[1][0] === "0" ? parseInt(parts[1][1], 10) : parseInt(parts[1], 10);
  let day =
    parts[2][0] === "0" ? parseInt(parts[2][1], 10) : parseInt(parts[2], 10);

  if (year >= currentYear) {
    return false;
  }
  if (currentYear - year < 18 || currentYear - year > 80) {
    return false;
  }
  if (month < 1 || month > 12) {
    return false;
  }
  if (day < 1 || day > 31) {
    return false;
  }
  return true;
};

export const getStateName = (code: string) => {
  const name = StateFullnameOptions?.find((item) => item.value === code)?.label;
  return name;
};

export const phnNumberFormat = (phn: string) => {
  const phone =
    phn.slice(0, 3) + "-" + phn.slice(3, 6) + "-" + phn.slice(6, 10);
  return phone;
};

export const getdateFormatString = (data: number) => {
  const str = data.toString().slice(-1);
  let endStr = "th";
  if (str === "1" && data != 11) {
    endStr = "st";
  } else if (str === "2" && data != 12) {
    endStr = "nd";
  } else if (str === "3" && data != 13) {
    endStr = "rd";
  } else {
    endStr = "th";
  }
  return data + endStr;
};

export const getMonth = (newVal: number) => {
  const value = newVal?.toString()?.length < 2 ? "0" + newVal : newVal;
  var month = "Jan";
  if (value === "01") {
    month = "Jan";
  } else if (value === "02") {
    month = "Feb";
  } else if (value === "03") {
    month = "Mar";
  } else if (value === "04") {
    month = "Apr";
  } else if (value === "05") {
    month = "May";
  } else if (value === "06") {
    month = "Jun";
  } else if (value === "07") {
    month = "Jul";
  } else if (value === "08") {
    month = "Aug";
  } else if (value === "09") {
    month = "Sep";
  } else if (value === "10") {
    month = "Oct";
  } else if (value === "11") {
    month = "Nov";
  } else {
    month = "Dec";
  }
  return month;
};

export const getFullMonth = (newVal: number) => {
  const value = newVal?.toString()?.length < 2 ? "0" + newVal : newVal;
  var month = "January";
  if (value === "01") {
    month = "January";
  } else if (value === "02") {
    month = "February";
  } else if (value === "03") {
    month = "March";
  } else if (value === "04") {
    month = "April";
  } else if (value === "05") {
    month = "May";
  } else if (value === "06") {
    month = "June";
  } else if (value === "07") {
    month = "July";
  } else if (value === "08") {
    month = "August";
  } else if (value === "09") {
    month = "September";
  } else if (value === "10") {
    month = "October";
  } else if (value === "11") {
    month = "November";
  } else {
    month = "December";
  }
  return month;
};

export const LIVEKIT_SPLIT_IDENTIFIER = "."
