import { PropsWithChildren } from "react";
import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";
import Footer from "../component/Footer";
import logo from "../assets/images/logo.png";
import { ToastContainer } from "react-toastify";
import image from "../assets/images/backgroundImage1.png";

type Props = PropsWithChildren & {
  title: string;
  description?: string | JSX.Element;
  [rest: string]: any;
};

const AuthBasic = (props: Props) => {
  const { children, title, description } = props;
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />

      <Box
        bgRepeat="no-repeat"
        bgImage={image}
        backgroundSize={{ xl: "100%", base: "unset" }}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems="center"
          flexDirection={{ base: "column", lg: "row" }}
          p={{ base: 0, lg: "3rem" }}
        >
          <Link href="https://www.notarizegenie.com">
            <Image
              src={logo}
              alt="logo"
              w={{ base: "15rem", lg: "20rem" }}
              py={{ base: "15px", md: "20px", lg: "0" }}
            />
          </Link>
          <Box textAlign="center">
            <Text
              fontSize={{ base: "xl", lg: "4xl", md: "3xl" }}
              color="white"
              fontWeight="700"
              fontFamily="Oswald"
            >
              {title}
            </Text>
            {description && (
              <Text
                fontFamily="Lato"
                fontSize="md"
                color="white"
                fontWeight="400"
                mt={2}
              >
                {description}
              </Text>
            )}
          </Box>
          <Box w={80}></Box>
        </Flex>
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default AuthBasic;
