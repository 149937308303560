/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';

/**
 * 
 * @export
 * @interface ActiveSession
 */
export interface ActiveSession {
    /**
     * 
     * @type {number}
     * @memberof ActiveSession
     */
    activeSessionId: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveSession
     */
    status: string;
    /**
     * 
     * @type {object}
     * @memberof ActiveSession
     */
    data: object;
    /**
     * 
     * @type {Date}
     * @memberof ActiveSession
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActiveSession
     */
    startTime: Date;
    /**
     * 
     * @type {Session}
     * @memberof ActiveSession
     */
    session: Session;
    /**
     * 
     * @type {string}
     * @memberof ActiveSession
     */
    conferenceId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActiveSession
     */
    allConferenceIds?: Array<string>;
}

/**
 * Check if a given object implements the ActiveSession interface.
 */
export function instanceOfActiveSession(value: object): value is ActiveSession {
    if (!('activeSessionId' in value) || value['activeSessionId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    if (!('session' in value) || value['session'] === undefined) return false;
    return true;
}

export function ActiveSessionFromJSON(json: any): ActiveSession {
    return ActiveSessionFromJSONTyped(json, false);
}

export function ActiveSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveSession {
    if (json == null) {
        return json;
    }
    return {
        
        'activeSessionId': json['activeSessionId'],
        'status': json['status'],
        'data': json['data'],
        'createdDate': (new Date(json['createdDate'])),
        'startTime': (new Date(json['startTime'])),
        'session': SessionFromJSON(json['session']),
        'conferenceId': json['conferenceId'] == null ? undefined : json['conferenceId'],
        'allConferenceIds': json['allConferenceIds'] == null ? undefined : json['allConferenceIds'],
    };
}

export function ActiveSessionToJSON(value?: ActiveSession | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeSessionId': value['activeSessionId'],
        'status': value['status'],
        'data': value['data'],
        'createdDate': ((value['createdDate']).toISOString()),
        'startTime': ((value['startTime']).toISOString()),
        'session': SessionToJSON(value['session']),
        'conferenceId': value['conferenceId'],
        'allConferenceIds': value['allConferenceIds'],
    };
}

