import { Button, Text } from "@chakra-ui/react";
import { BsCheckSquare, BsTextareaT } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { BiRectangle } from "react-icons/bi";
import DateTool from "./DateTool";
import { EventType } from "../../../store/auditLogs";

const toolsData = [
  {
    title: "Free Text",
    icon: <BsTextareaT />,
    data: {
      action: "placeTextObject",
      payload: {
        x: 0,
        y: 0,
        text: "Free Text",
        type: EventType.text,
      },
    },
  },
  {
    title: "Full Date",
    icon: <MdDateRange />,
    data: {
      action: "placeDateObject",
      payload: {
        x: 0,
        y: 0,
        type: EventType.date,
        text: new Intl.DateTimeFormat("en-US").format(new Date()).toString(),
      },
    },
  },
  {
    title: "WhiteOut",
    icon: <BiRectangle />,
    data: {
      action: "placeRectangleObject",
      payload: {
        x: 0,
        y: 0,
        type: EventType.rectangle,
      },
    },
  },
  {
    title: "Checkbox",
    icon: <BsCheckSquare />,
    data: {
      action: "placeCheckboxObject",
      payload: {
        x: 0,
        y: 0,
        type: EventType.checkbox,
      },
    },
  },
];

const SignerTools = () => {
  return (
    <>
      {toolsData?.flatMap((tool, idx) => (
        // <>
        //   {tool.title === "Free Text" && isMobileView ? (
        //     <Button
        //       type="button"
        //       onClick={() => {
        //         placeTextObject({
        //           payload: {
        //             x: 0,
        //             y: 0,
        //             text: "Free Text",
        //             type: EventType.text,
        //           },
        //           selectedPage: 0,
        //           scale:0
        //         })
        //       }}
        //     >
        //       Free Text
        //     </Button>
        //   ) : (
        <Button
          // style={{touchAction: "none"}}
          key={idx}
          justifyContent="left"
          draggable={true}
          onDragStart={(e) => {
            e.dataTransfer.setData(
              "application/json",
              JSON.stringify(tool.data)
            );
          }}
          type="button"
        >
          {tool.icon} <Text className="tools-button-text">{tool.title}</Text>
        </Button>
        //   )}
        // </>
      ))}

      <DateTool />
    </>
  );
};
export default SignerTools;
