import { useEffect } from "react";
import PaymentInfoDetails from "../../../component/Details/PaymentInfoDetails";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const PaymentInfo = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(6);
  }, [setStep]);

  return <PaymentInfoDetails />;
};

export default PaymentInfo;
