import { Box, Select, Stack, Text } from "@chakra-ui/react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import usePermission from "../../../../hooks/usePermission";
import { browserName, isMobile, isTablet } from "react-device-detect";
import useInternetSpeed from "../../../../utils/useInternetSpeed";

const DeviceInfoData = ({
  onSetIsDisable,
}: {
  onSetIsDisable: (value: boolean) => void;
}) => {
  const ref = useRef() as MutableRefObject<HTMLVideoElement>;
  const [refresh, setRefresh] = useState<boolean>(false);

  const {
    cameraDevices,
    selectCamera,
    selectedCamera,
    microphoneDevices,
    selectMicrophone,
    selectedMicrophone,
  } = usePermission(refresh, ref);

  useEffect(() => {
    if (microphoneDevices?.length === 0 || cameraDevices?.length === 0) {
      onSetIsDisable(true);
    } else {
      onSetIsDisable(false);
    }
  }, [cameraDevices, microphoneDevices]);

  return (
    <>
      <Stack spacing={4} pt={2}>
        <Text fontSize={"16px"} fontWeight={"600"} fontFamily={"Lato"}>
          Select a device for Video
        </Text>
        <Select
          variant="outline"
          value={selectedCamera?.deviceId}
          onChange={(e) => {
            // setSelectedCamera(e.target.value);
            selectCamera(e.target.value);
          }}
        >
          {cameraDevices?.map((each) => (
            <option key={each.deviceId} value={each.deviceId}>
              {each.label}
            </option>
          ))}
        </Select>

        <Text fontSize={"16px"} fontWeight={"600"} fontFamily={"Lato"}>
          Select a device for Microphone
        </Text>
        <Select
          variant="outline"
          value={selectedMicrophone?.deviceId}
          onChange={(e) => {
            selectMicrophone(e.target.value);
          }}
        >
          {microphoneDevices?.map((each) => (
            <option key={each.deviceId} value={each.deviceId}>
              {each.label}
            </option>
          ))}
        </Select>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Text fontFamily={"Lato"}>Browser: {browserName}</Text>
          <Text fontFamily={"Lato"}>
            Device: {isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop"}
          </Text>
        </div>
        {/* <Text>speed:{speedInKbps.toFixed(2)} kbps</Text> */}

        {/* <Button
          onClick={() => {
            setRefresh((prev) => !prev);
          }}
          _hover={{
            background:
              "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
          }}
          width={120}
        >
          Refresh A/V
        </Button> */}

        <Text fontSize={"16px"} fontWeight={"600"} fontFamily={"Lato"}>
          Video Preview
        </Text>
        <Box>
          <video width={"100%"} ref={ref} />
        </Box>
      </Stack>
    </>
  );
};

export default DeviceInfoData;