import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/use-auth";
import useCreateUser from "../../../hooks/useCreateUser";
import { createSession } from "../../../utils/utils-apis";
import useNotification from "../../../utils/useNotification";
import PaymentData from "../../commonComponents/PaymentCard/PaymentData";
import { initialSessionDetails, useSessionCreate } from "../../../store/store";
import DemoSessionComponent from "../components/DemoSessionComponent";
import { businessPaths } from "../../../routes/routes";

const BusinessSessionPayment = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const sendSynchronously = useCreateUser();
  const { notification } = useNotification();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const isInhouseNotary = sessionDetail.notaryId !== "";

  const createBusinessSession = async () => {
    setIsSubmit(true);
    const users = await sendSynchronously();
    createSession(
      sessionDetail,
      users,
      configuration,
      "business",
      isInhouseNotary
    )
      .then((res) => {
        notification("success", "Session Created Successfully");
        navigate("/");
        setSessionDetails(initialSessionDetails);
        setIsSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        notification("fail", "Failed to Create Session");
        setIsSubmit(false);
      });
  };

  return (
    <Stack spacing={2}>
      <Text
        fontSize={"22px"}
        textAlign={"center"}
        mb={"20px"}
        fontFamily={"oswald"}
        fontWeight={"600"}
      >
        Payment Details
      </Text>

      {/* {!sessionDetail.isTitleLenderSession && <DemoSessionComponent />} */}

      {!sessionDetail.isDemoSession && (
        <>
          <Text fontWeight={600}>
            Do you want to pay for this session or your client to pay for the
            session?
          </Text>
          <RadioGroup
            value={sessionDetail?.paymentBy}
            onChange={(value) => {
              if (value === "signer") {
                setSessionDetails({ ...sessionDetail, paymentBy: value });
              } else {
                setSessionDetails({
                  ...sessionDetail,
                  paymentBy: value,
                  documentFees: 0,
                });
              }
            }}
          >
            <Stack direction="row" gap={3}>
              <Radio value="business">You will pay</Radio>
              <Radio value="signer">Client will pay</Radio>
            </Stack>
          </RadioGroup>

          {sessionDetail?.paymentBy === "business" && (
            <PaymentData onDisable={(value) => setIsDisable(value)} />
          )}
        </>
      )}

      <Flex style={{ marginTop: "4rem" }} justifyContent={"flex-end"}>
        <Button
          mx={2}
          style={{ padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            navigate(`${businessPaths.createSession}/details`);
          }}
        >
          Prev
        </Button>
        <Button
          mx={2}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          width={"fit-content"}
          onClick={() => createBusinessSession()}
          isDisabled={
            (sessionDetail.paymentBy === "signer" ? false : isDisable) ||
            isSubmit
          }
        >
          Create Session
        </Button>
      </Flex>
    </Stack>
  );
};

export default BusinessSessionPayment;
