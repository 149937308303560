/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TerminateSessionDTO
 */
export interface TerminateSessionDTO {
    /**
     * 
     * @type {number}
     * @memberof TerminateSessionDTO
     */
    activeSessionId: number;
    /**
     * 
     * @type {string}
     * @memberof TerminateSessionDTO
     */
    rejectionReason: string;
}

/**
 * Check if a given object implements the TerminateSessionDTO interface.
 */
export function instanceOfTerminateSessionDTO(value: object): value is TerminateSessionDTO {
    if (!('activeSessionId' in value) || value['activeSessionId'] === undefined) return false;
    if (!('rejectionReason' in value) || value['rejectionReason'] === undefined) return false;
    return true;
}

export function TerminateSessionDTOFromJSON(json: any): TerminateSessionDTO {
    return TerminateSessionDTOFromJSONTyped(json, false);
}

export function TerminateSessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerminateSessionDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'activeSessionId': json['activeSessionId'],
        'rejectionReason': json['rejectionReason'],
    };
}

export function TerminateSessionDTOToJSON(value?: TerminateSessionDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeSessionId': value['activeSessionId'],
        'rejectionReason': value['rejectionReason'],
    };
}

