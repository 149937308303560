import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const ArizonaStamp = ({
  name,
  height,
  width,
  commissionNumber,
  expires,
}: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 432.000000 164.000000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: "transparent" }}
      stroke="transparent"
    >
      {/* ****************************** Name ******************************  */}
      {name.length > 22 ? (
        <>
          <text
            x="61%"
            y="19%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"20px"}
            fontWeight="bold"
            letterSpacing="0.7"
          >
            {notaryProfile.firstName} {notaryProfile.middleName}
          </text>
          <text
            x="61%"
            y="31%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"20px"}
            fontWeight="bold"
            letterSpacing="0.7"
          >
            {notaryProfile.lastName} {notaryProfile.suffix}
          </text>
        </>
      ) : (
        <text
          x="61%"
          y="28%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"18px"}
          fontWeight="bold"
          // fill="#F0F1F2"
        >
          {name}
        </text>
      )}

      {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

      <text
        x="62%"
        y="43%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"17px"}
        fontWeight="bold"
      >
        Notary Public - State of Arizona
      </text>

      <text
        x="60%"
        y="54%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"17px"}
        fontWeight="bold"
      >
        Maricopa County
      </text>

      {/* ****************************** Text -commission ******************************  */}

      <text
        x="60%"
        y="66%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"17px"}
        fontWeight="bold"
      >
        Commission #{commissionNumber}
      </text>

      {/* *************************************** Text -Expires ******************************************** */}

      <text
        x="61%"
        y="80%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"16px"}
        fontWeight="bold"
      >
        My Comm. Expires on {moment(expires).format("MMM DD, YYYY")}
      </text>

      <g
        transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M160 1550 c0 -16 5 -30 10 -30 18 0 50 -64 50 -100 0 -36 -32 -100
-50 -100 -5 0 -10 -21 -10 -47 0 -38 6 -53 25 -71 45 -43 45 -116 0 -170 -34
-40 -34 -94 0 -134 45 -54 45 -125 0 -168 -30 -28 -35 -99 -9 -121 24 -20 44
-65 44 -100 0 -32 -34 -99 -51 -99 -5 0 -9 -22 -9 -50 0 -27 4 -50 9 -50 17 0
51 -67 51 -99 0 -35 -20 -80 -44 -100 -9 -8 -16 -22 -16 -32 0 -29 60 -25 106
6 49 34 109 34 158 0 24 -17 51 -25 78 -25 28 0 49 7 70 25 23 19 41 25 78 25
37 0 55 -6 78 -25 22 -18 42 -25 72 -25 30 0 50 7 72 25 42 35 114 35 156 0
40 -34 98 -34 148 0 44 30 102 33 145 8 16 -10 29 -21 29 -25 0 -4 25 -8 55
-8 30 0 55 4 55 8 0 4 13 15 29 25 43 25 101 22 145 -8 50 -34 108 -34 148 0
42 35 114 35 156 0 41 -34 103 -34 144 0 42 35 114 35 156 0 21 -18 42 -25 70
-25 27 0 54 8 78 25 49 34 109 34 158 0 50 -34 108 -34 148 0 41 35 108 35
154 0 45 -34 105 -34 146 0 42 35 114 35 156 0 40 -34 98 -34 148 0 44 30 102
33 145 8 16 -10 29 -21 29 -25 0 -4 20 -8 45 -8 31 0 55 8 81 25 25 17 50 25
79 25 29 0 54 -8 79 -25 50 -34 108 -34 148 0 23 19 41 25 78 25 37 0 55 -6
78 -25 20 -17 42 -25 66 -25 33 0 36 2 36 30 0 17 -4 30 -10 30 -16 0 -40 57
-40 95 0 36 26 95 42 95 4 0 8 23 8 51 0 40 -5 58 -25 81 -34 40 -34 102 0
152 33 49 33 103 0 152 -34 50 -34 112 0 152 34 40 34 98 0 148 -34 50 -34
112 0 152 20 23 25 41 25 81 0 28 -4 51 -8 51 -4 0 -15 13 -25 29 -25 43 -22
101 8 145 14 20 25 46 25 56 0 32 -52 26 -88 -9 -53 -53 -127 -53 -177 -1 -36
37 -98 43 -115 10 -14 -26 -62 -50 -101 -50 -35 0 -94 32 -103 55 -3 10 -19
15 -43 15 -29 0 -45 -7 -66 -30 -50 -52 -126 -52 -179 1 -36 36 -85 39 -114 8
-26 -28 -67 -49 -99 -49 -32 0 -73 21 -99 49 -29 31 -78 28 -114 -8 -53 -53
-129 -53 -179 -1 -21 23 -37 30 -66 30 -24 0 -40 -5 -43 -15 -9 -23 -68 -55
-103 -55 -39 0 -87 24 -101 50 -17 33 -79 27 -115 -10 -50 -52 -124 -52 -177
1 -39 39 -79 39 -124 -1 -60 -52 -129 -52 -179 0 -39 40 -83 41 -123 1 -53
-53 -128 -53 -181 -1 -20 21 -39 30 -61 30 -22 0 -41 -9 -61 -30 -53 -52 -128
-52 -181 1 -36 36 -85 39 -114 8 -57 -63 -141 -63 -198 0 -29 31 -78 28 -114
-8 -53 -53 -127 -53 -177 -1 -36 37 -98 43 -115 10 -14 -26 -61 -50 -100 -50
-39 0 -86 24 -100 50 -8 15 -21 20 -50 20 -38 0 -40 -2 -40 -30z m3890 -735
l0 -635 -1885 0 -1885 0 0 635 0 635 1885 0 1885 0 0 -635z"
        />
        <path
          d="M758 1284 c-159 -38 -295 -160 -350 -312 -30 -80 -36 -221 -13 -298
27 -92 63 -154 130 -221 269 -269 726 -149 827 216 47 171 2 346 -124 477
-123 129 -298 180 -470 138z m237 -18 c83 -22 157 -66 220 -130 72 -73 112
-151 133 -258 14 -72 14 -85 -1 -156 -35 -161 -124 -280 -261 -347 -122 -60
-244 -69 -370 -25 -250 86 -382 367 -292 620 50 138 179 255 325 295 61 17
184 18 246 1z"
        />
        <path
          d="M804 1243 c-7 -26 -24 -31 -24 -8 0 8 -4 15 -10 15 -12 0 -13 -15 -4
-59 7 -34 24 -42 24 -11 0 11 5 20 10 20 6 0 10 -7 10 -15 0 -8 5 -15 10 -15
13 0 13 60 0 80 -8 13 -11 11 -16 -7z"
        />
        <path
          d="M840 1215 c0 -41 2 -45 25 -45 16 0 25 6 25 15 0 8 -8 15 -17 15 -15
0 -16 2 -3 10 13 9 13 11 0 20 -13 8 -12 10 3 10 9 0 17 5 17 10 0 6 -11 10
-25 10 -23 0 -25 -4 -25 -45z"
        />
        <path
          d="M935 1240 c-3 -5 -1 -19 5 -30 9 -16 8 -20 -5 -20 -9 0 -13 -4 -10
-10 8 -13 42 -13 50 0 3 5 1 19 -6 31 -8 15 -8 19 0 15 6 -4 11 0 11 8 0 18
-35 22 -45 6z"
        />
        <path
          d="M707 1228 l-28 -10 24 -22 c13 -12 26 -29 29 -37 3 -8 9 -11 13 -7 5
5 3 20 -4 35 -9 19 -9 29 -2 31 12 4 15 22 4 21 -5 0 -21 -5 -36 -11z"
        />
        <path
          d="M990 1231 c0 -5 5 -11 11 -13 9 -3 9 -9 0 -25 -14 -27 -14 -43 0 -43
5 0 15 13 20 29 7 20 15 28 25 24 8 -3 14 0 14 6 0 6 -12 16 -26 21 -32 12
-44 13 -44 1z"
        />
        <path
          d="M1047 1182 c-32 -33 -34 -46 -7 -37 14 5 20 2 20 -9 0 -21 16 -21 24
0 9 23 7 74 -2 74 -5 0 -20 -13 -35 -28z"
        />
        <path
          d="M619 1181 c-21 -17 -22 -21 -9 -36 14 -17 13 -17 -5 -7 -44 23 -74
-28 -36 -62 28 -26 73 4 55 37 -5 8 -2 7 7 0 7 -7 16 -13 20 -13 8 0 5 88 -3
96 -3 2 -16 -4 -29 -15z m-15 -77 c4 -11 1 -14 -11 -12 -9 2 -18 9 -21 16 -6
18 25 15 32 -4z"
        />
        <path
          d="M1100 1180 c0 -5 5 -10 11 -10 6 0 1 -13 -11 -29 -24 -35 -10 -51 17
-18 10 13 24 22 31 19 18 -6 15 13 -6 31 -21 19 -42 22 -42 7z"
        />
        <path
          d="M773 1146 c-315 -103 -350 -523 -55 -668 60 -30 75 -33 157 -33 77 0
98 4 145 26 78 37 131 87 171 161 33 60 34 67 34 168 0 101 -1 108 -34 168
-64 117 -169 183 -301 189 -45 2 -92 -3 -117 -11z m253 -39 c134 -69 214 -228
185 -369 -22 -104 -91 -196 -186 -246 -45 -24 -61 -27 -150 -27 -90 0 -105 3
-153 28 -68 36 -124 94 -159 165 -24 49 -28 69 -28 142 0 74 4 93 29 144 34
69 88 126 148 157 68 35 88 39 178 35 68 -2 95 -8 136 -29z"
        />
        <path
          d="M623 994 c-3 -8 -3 -35 1 -60 4 -29 2 -44 -6 -44 -17 0 -1 -156 23
-209 30 -69 74 -108 159 -141 19 -7 45 -22 57 -33 22 -20 22 -20 51 4 15 14
48 31 71 39 24 8 63 33 86 56 63 61 76 112 68 275 l-6 129 -249 0 c-216 0
-249 -2 -255 -16z m104 -41 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z m46 30 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m43 -30
c-3 -4 -6 0 -6 7 0 9 -5 7 -15 -6 -14 -18 -14 -17 -3 9 10 24 13 25 21 12 5
-9 6 -19 3 -22z m47 30 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m118 0 c-8 -3 -9 -8 -3 -12 6 -4 5 -11 -4 -19 -12 -11 -14 -9 -14 13 0 17 5
25 18 24 12 0 13 -2 3 -6z m66 -30 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m34 28 c-9 -6 -10 -11 -2 -15 6 -4 11 -12 11 -19 0 -6 -6 -2 -14 8
-15 21 -12 35 7 35 8 0 8 -3 -2 -9z m-380 -34 c-10 -9 -11 -8 -5 6 3 10 9 15
12 12 3 -3 0 -11 -7 -18z m240 0 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0
-11 -7 -18z m-251 -56 c-26 -21 -40 -19 -40 5 0 9 11 14 33 14 l32 0 -25 -19z
m175 9 c4 -6 -12 -10 -40 -10 -28 0 -44 4 -40 10 3 6 21 10 40 10 19 0 37 -4
40 -10z m245 -19 c0 -32 -21 -43 -55 -30 -18 7 -18 8 -2 8 10 1 15 6 12 11 -3
6 4 10 16 10 18 0 20 3 11 12 -8 8 -15 8 -24 1 -7 -6 -27 -13 -45 -16 -31 -6
-32 -6 -15 12 11 13 31 20 60 20 40 1 42 0 42 -28z m-405 -68 c5 -15 4 -15
-13 0 -33 30 -44 22 -40 -33 1 -28 -1 -50 -5 -50 -4 0 -7 26 -6 58 1 33 -1 64
-5 70 -4 7 8 3 28 -8 19 -12 37 -28 41 -37z m55 12 c11 -13 10 -14 -6 -8 -28
12 -64 37 -64 45 0 10 56 -20 70 -37z m267 29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m93 -34 c11 -7 11 -10 2 -10 -7 0 -10 -5 -7 -10 4 -7
-2 -9 -19 -4 -15 3 -26 2 -26 -4 0 -5 -10 -12 -22 -15 -18 -5 -19 -8 -8 -21
17 -21 32 -20 59 5 12 12 25 19 27 16 11 -11 -17 -85 -41 -107 -28 -27 -47
-20 -22 8 10 10 17 24 17 30 0 6 8 9 18 6 14 -5 15 -4 2 5 -8 6 -28 11 -45 12
-16 0 -38 4 -48 8 -16 6 -13 11 21 35 22 15 43 34 46 42 6 16 25 18 46 4z
m-110 -10 c8 -5 11 -12 7 -16 -4 -4 -15 0 -24 9 -18 18 -8 23 17 7z m-236 -22
c-4 -6 0 -16 8 -22 14 -10 14 -13 0 -26 -14 -13 -15 -12 -9 5 7 19 6 19 -7 2
-13 -17 -14 -16 -19 3 -3 13 -1 18 6 13 6 -4 8 2 5 15 -4 15 -2 22 9 22 8 0
11 -5 7 -12z m66 -8 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0
15 -4 15 -10z m122 -23 c-24 -7 -36 -7 -44 1 -9 9 -1 12 33 11 l44 -1 -33 -11z
m-232 -32 c-7 -8 -18 -15 -24 -15 -6 0 -3 7 8 15 11 8 15 19 11 27 -6 10 -5
10 5 1 11 -10 11 -15 0 -28z m144 7 c-6 -4 -14 -16 -17 -26 -4 -10 -10 -16
-13 -13 -9 8 15 47 29 47 8 0 9 -3 1 -8z m104 -25 c8 -6 33 -10 55 -9 34 2 38
0 25 -8 -10 -6 -18 -18 -18 -27 0 -14 -3 -13 -17 3 -13 14 -29 18 -55 16 -34
-3 -36 -1 -31 20 5 19 3 21 -8 11 -7 -7 -19 -13 -27 -13 -12 0 -12 3 -1 16 13
16 52 12 77 -9z m42 13 c12 -8 11 -10 -7 -10 -12 0 -25 5 -28 10 -8 13 15 13
35 0z m-94 -52 c4 -7 3 -8 -4 -4 -7 4 -12 2 -12 -3 0 -6 -7 -11 -15 -11 -8 0
-15 7 -15 15 0 18 36 20 46 3z m-121 -37 c-3 -11 -1 -22 5 -26 5 -3 10 -1 10
6 0 7 2 10 5 7 15 -14 -9 -36 -39 -35 -40 1 -48 34 -10 43 13 3 24 10 24 15 0
5 3 9 6 9 3 0 3 -9 -1 -19z m75 -11 c0 -5 12 -10 28 -11 l27 -1 -25 -8 c-47
-15 -70 -12 -70 10 0 13 7 20 20 20 11 0 20 -4 20 -10z m117 -15 c9 -24 -1
-27 -15 -5 -8 13 -8 20 -2 20 6 0 13 -7 17 -15z m-150 -41 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m133 2 c0 -2 -9 -6 -20 -9 -11 -3 -18 -1
-14 4 5 9 34 13 34 5z m-82 -25 c10 -6 10 -10 -1 -19 -11 -9 -18 -8 -32 8 -17
19 -17 20 1 20 10 0 25 -4 32 -9z"
        />
        <path
          d="M1140 1115 c-23 -24 -23 -25 -5 -45 21 -23 42 -16 23 8 -11 13 -10
15 5 9 11 -4 17 -2 17 8 0 9 6 12 15 9 18 -7 20 7 3 24 -18 18 -32 15 -58 -13z"
        />
        <path
          d="M1208 1049 c-26 -14 -23 -56 3 -64 15 -5 18 -9 9 -15 -9 -5 -9 -9 -1
-14 6 -4 15 -5 20 -1 5 3 12 1 16 -5 10 -17 25 -11 18 8 -5 14 -3 13 10 -3 20
-26 31 -15 16 18 -6 14 -17 23 -25 20 -9 -3 -11 2 -7 16 6 22 -12 51 -31 51
-6 0 -19 -5 -28 -11z m31 -35 c-9 -11 -15 -12 -23 -4 -8 8 -7 13 4 20 20 12
33 1 19 -16z"
        />
        <path
          d="M485 1041 c-3 -5 7 -19 22 -30 l28 -21 -37 6 c-44 7 -50 -7 -17 -42
15 -16 19 -29 14 -41 -8 -17 -8 -17 -12 0 -5 20 -19 22 -27 5 -3 -7 -3 -3 0
10 6 25 -11 31 -20 6 -10 -26 -7 -34 15 -42 19 -7 19 -9 5 -21 -11 -9 -16 -10
-16 -2 0 6 -4 11 -10 11 -16 0 -12 -40 5 -46 8 -4 22 -1 30 6 10 9 15 9 15 1
0 -6 5 -11 11 -11 6 0 9 10 7 23 -2 13 1 29 9 35 7 7 13 26 13 42 0 17 10 40
25 56 28 30 36 66 10 44 -12 -10 -20 -9 -39 4 -14 9 -27 12 -31 7z"
        />
        <path
          d="M1240 890 c0 -5 4 -12 10 -15 5 -3 7 -19 3 -35 -4 -25 -2 -30 13 -30
17 0 17 -1 -1 -14 -12 -8 -16 -20 -12 -35 3 -11 2 -21 -3 -21 -4 0 -10 -11
-12 -25 -4 -17 -1 -25 8 -25 8 0 14 5 14 12 0 6 11 1 25 -12 28 -26 30 -26 39
13 7 28 -2 35 -20 17 -8 -8 -15 -6 -24 5 -11 13 -8 15 19 15 17 0 31 5 31 10
0 6 -11 10 -24 10 -14 0 -28 5 -31 11 -4 6 4 9 22 7 24 -3 28 1 31 25 3 25 0
27 -30 27 l-33 0 33 22 c24 15 30 23 20 28 -23 13 -78 20 -78 10z"
        />
        <path
          d="M419 796 c-8 -9 2 -76 12 -76 5 0 9 7 9 15 0 10 10 15 28 15 l27 0
-32 -21 c-42 -27 -41 -35 1 -43 19 -4 37 -13 40 -22 5 -12 1 -14 -16 -8 -17 5
-20 3 -14 -12 6 -18 5 -18 -9 0 -18 24 -26 18 -18 -12 6 -24 24 -28 54 -12 13
7 19 7 19 0 0 -5 -6 -10 -14 -10 -7 0 -19 -7 -26 -15 -16 -19 9 -58 30 -45 9
6 18 3 27 -8 11 -15 14 -15 23 -2 8 13 10 13 10 -2 0 -11 -8 -18 -20 -18 -11
0 -20 5 -20 10 0 6 -4 10 -10 10 -15 0 -12 -16 6 -34 20 -21 30 -20 55 5 l20
20 -20 32 c-12 18 -26 32 -33 31 -8 -1 -13 12 -14 33 0 18 -10 47 -22 63 -12
16 -18 31 -13 34 4 3 6 17 3 32 -4 21 -8 25 -29 19 -17 -4 -29 -1 -37 10 -6 9
-14 14 -17 11z"
        />
        <path
          d="M1224 666 c-4 -9 -4 -25 -1 -35 3 -14 1 -18 -10 -14 -11 4 -14 1 -11
-8 3 -8 -6 -22 -19 -32 -12 -10 -22 -23 -20 -30 1 -6 -3 -11 -10 -12 -28 -1
-23 -16 10 -30 53 -22 60 -19 42 15 -21 40 -19 45 11 25 29 -19 43 -13 24 10
-10 13 -9 15 9 15 26 0 27 15 4 29 -16 9 -15 10 6 8 30 -3 50 26 35 51 -15 23
-62 28 -70 8z m54 -24 c3 -9 -2 -13 -14 -10 -9 1 -19 9 -22 16 -3 9 2 13 14
10 9 -1 19 -9 22 -16z"
        />
        <path
          d="M640 469 c-14 -4 -21 -7 -16 -8 5 -1 3 -8 -4 -16 -10 -12 -10 -15 3
-15 8 0 17 -6 19 -12 3 -9 9 -8 21 4 25 25 10 56 -23 47z"
        />
        <path
          d="M1076 457 c-5 -20 26 -55 32 -36 2 5 9 9 16 9 8 0 12 9 9 23 -6 30
-49 33 -57 4z"
        />
        <path
          d="M768 433 c-13 -3 -16 -13 -14 -40 3 -51 23 -53 31 -3 8 46 7 48 -17
43z"
        />
        <path
          d="M953 433 c-18 -7 -16 -23 2 -23 18 0 18 -1 5 -34 -10 -28 -5 -31 38
-20 24 7 31 24 9 24 -7 0 -13 11 -12 27 0 27 -16 37 -42 26z"
        />
        <path
          d="M813 414 c-3 -9 -1 -25 6 -35 7 -13 8 -19 0 -19 -5 0 -7 -5 -4 -10 9
-15 42 6 50 30 12 38 -38 70 -52 34z"
        />
        <path
          d="M889 413 c-11 -13 -11 -17 -1 -20 6 -3 12 -16 12 -29 0 -15 6 -24 16
-24 12 0 15 6 10 23 -3 12 -6 32 -6 45 0 26 -12 28 -31 5z"
        />
      </g>
    </svg>
  );
};
export default ArizonaStamp;
