import { AlertIcon, Box } from "@chakra-ui/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useResponsive } from "../store/store";
import AccessErrorScreen from "../component/AccessErrorScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import SidebarComponent from "../component/Sidebar/SidebarComponent";
import Navbar from "../component/Navbar/Navbar";
import { BASE_URL } from "../utils/constants";

const DashboardLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { setIsMobileView } = useResponsive();
  const [width, setWidth] = useState(window.innerWidth);
  const [isShowErrScreen, setIsShowErrScreen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access-token");
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    fetch(`${BASE_URL}/users/maintenance`).then(async (res) => {
      const result = await res.json();
      if (result.value === true) {
        // localStorage.clear();
        // navigate("/sign-in");
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      if (window.innerWidth <= 790) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    });
  }, []);

  // useEffect(() => {
  if (accessToken !== null) {
    localStorage.setItem("accessToken", accessToken);
    // setToken(accessToken);
  }
  // }, [accessToken]);

  useEffect(() => {
    if (width < 1000 && localStorage.getItem("role") === "notary") {
      setIsShowErrScreen(true);
    } else {
      setIsShowErrScreen(false);
    }
  }, [width]);

  return (
    <>
      {isShowErrScreen ? (
        <AccessErrorScreen />
      ) : (
        (token !== null || accessToken !== null) && (
          <>
            <Box display="flex" height="100vh" background="#fff">
              <SidebarComponent />

              <Box width="100%" height="100%" overflowY="auto">
                <Box height={{ base: "10%", md: "7%", lg: "7%" }}>
                  <Navbar />
                </Box>
                <Box
                  height={{ base: "90%", md: "93%", lg: "93%" }}
                  overflowY="auto"
                  p={["1px 15px 20px", 8, 8, 8]}
                >
                  {children}
                </Box>
              </Box>
            </Box>
          </>
        )
      )}
    </>
  );
};

export default DashboardLayout;
