import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { EventAction } from "../../../store/auditLogs";
import { espTransformDocFee } from "../../../utils/utils";
import RecordingData from "../DetailModalData/RecordingData";
import SignedDocInfo from "../DetailModalData/SignedDocInfo";
import { AuditLogs, Session, SignedPdfDTO, Signer } from "../../../api";
import SessionSignerDetail from "../DetailModalData/SessionSignerDetail";
import useCustomComponentsHook from "../../../hooks/useCustomComponentsHook";

const DetailsModal = ({
  isOpen,
  onClose,
  onOpen,
  session,
  recordingKey,
  meetingInfo,
  actDate,
  signedDocPath,
  signers,
  stampDetails,
  auditData,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  session: Session;
  recordingKey?: string;
  meetingInfo?: {
    notaryNote: string;
    documentType: string;
  };
  actDate?: Date | undefined;
  signedDocPath: SignedPdfDTO[];
  signers: Signer[];
  stampDetails: AuditLogs[];
  auditData?: AuditLogs[];
}) => {
  const userId = localStorage?.getItem("UserID");
  const { flexData, flexDataStatus } = useCustomComponentsHook();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");

  const handleClose = () => {
    setErrMsg("");
    setIsSubmit(false);
    onClose();
  };

  const addJuratstamp = stampDetails?.filter(
    (el) =>
      el.action === EventAction.add &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Jurat"
  )?.length;

  const ackAddCount = stampDetails?.filter(
    (el) =>
      el.action === EventAction.add &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Acknowlegement"
  ).length;

  const deleteJuratstamp = stampDetails?.filter(
    (el) =>
      el.action === EventAction.delete &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Jurat"
  ).length;

  const ackDeleteCount = stampDetails?.filter(
    (el) =>
      el.action === EventAction.delete &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Acknowlegement"
  ).length;

  const totalAcknowlegementStampCount = ackAddCount - ackDeleteCount;

  const totalJuratStampCount = addJuratstamp - deleteJuratstamp;

  const ipAddressData = auditData?.filter((el) => el.event === "IP address");

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size={"5xl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent px={4}>
          <ModalHeader>
            <Text
              fontSize={{ base: "18px", md: "22px", lg: "22px" }}
              fontWeight={"700"}
              fontFamily="Lato"
            >
              Session Details
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody
            mx={"40px"}
            my={10}
            style={{ border: "1px solid rgba(139, 138, 150, 0.32)" }}
            p={4}
            bg={"rgba(245, 245, 245, 0.46)"}
          >
            <Box height="67vh" overflow={"auto"}>
              {actDate !== undefined && (
                <Text fontSize={"12px"}>
                  Notarial Act {actDate?.toLocaleString("en-Us")}
                </Text>
              )}
              <Flex gap={20} mt={3}>
                <Stack w={"98%"}>
                  {!session?.isNotaryExemplarSheet && (
                    <Box>
                      <Text fontSize={"md"} fontWeight={600}>
                        Participants
                      </Text>
                      <Box
                        boxShadow="10px 4px 30px rgba(0, 0, 0, 0.25)"
                        borderRadius="lg"
                        px={"24px"}
                        py={"21px"}
                        mt={2}
                        backgroundColor="#fff"
                      >
                        {session?.primarySignerUserId !== undefined ? (
                          <>
                            {signers
                              .filter(
                                (item, idx) =>
                                  item.user?.id === session?.primarySignerUserId
                              )
                              ?.flatMap((item, idx) => (
                                <SessionSignerDetail
                                  key={idx}
                                  idx={idx}
                                  item={item}
                                  session={session as Session}
                                  ipAddressData={ipAddressData}
                                />
                              ))}
                            {signers
                              ?.filter(
                                (item, idx) =>
                                  item.user?.id !== session?.primarySignerUserId
                              )
                              ?.flatMap((item, idx) => (
                                <SessionSignerDetail
                                  key={idx}
                                  idx={idx + 1}
                                  item={item}
                                  session={session as Session}
                                  ipAddressData={ipAddressData}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            {signers?.flatMap((item, idx) => (
                              <SessionSignerDetail
                                key={idx}
                                idx={idx}
                                item={item}
                                session={session as Session}
                                ipAddressData={ipAddressData}
                              />
                            ))}
                          </>
                        )}

                        {session?.witness?.flatMap((item, idx) => (
                          <Box key={idx}>
                            <Text
                              fontWeight={"600"}
                              mb={1}
                              mt={2}
                              textDecoration="underline"
                            >
                              Witness {idx + 1}
                            </Text>
                            {flexData(
                              "Name",
                              `${item?.firstName} ${item?.lastName}`
                            )}
                            {ipAddressData !== undefined &&
                              flexData(
                                "IP Address",
                                ipAddressData?.find(
                                  (el) => el?.user?.id === item?.user?.id
                                  // @ts-ignore
                                )?.payload?.ip ?? "Not Provided"
                              )}
                            {flexData(
                              "Address",
                              item?.witnessAddress ?? "Not Provided"
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}

                  {session?.user?.id === Number(userId) &&
                    session?.status === "completed" && (
                      <SignedDocInfo
                        session={session}
                        signedDocPath={signedDocPath}
                        onSetIsSubmit={(value) => setIsSubmit(value)}
                        onSetErrMsg={(value) => setErrMsg(value)}
                        errMsg={errMsg}
                        isSubmit={isSubmit}
                        from={"notary"}
                      />
                    )}

                  <Box pt={5}>
                    <Text fontSize={"md"} fontWeight={600}>
                      Session Details
                    </Text>
                    <Box
                      boxShadow="10px 4px 40px rgba(0, 0, 0, 0.25)"
                      borderRadius="lg"
                      px={"24px"}
                      py={"21px"}
                      mt={2}
                      backgroundColor="#fff"
                      fontSize={"sm"}
                    >
                      {flexData(
                        "Session Time",
                        moment(session?.createdDate).format("MM-DD-YYYY")
                      )}
                      {flexData("Session ID", session?.sessionId)}

                      {flexData(
                        "Digital Certificate Applied",
                        session?.notary?.digitalCertificate !== ""
                          ? "Yes"
                          : "No"
                      )}

                      {flexDataStatus("Session Status", session?.status)}

                      {session?.status === "terminated" &&
                        flexData("Terminate Reason", session?.terminateReason)}
                    </Box>
                  </Box>

                  <Box pt={5}>
                    <Text fontSize={"md"} fontWeight={600}>
                      Session Note
                    </Text>
                    <Box
                      boxShadow="10px 4px 30px rgba(0, 0, 0, 0.25)"
                      borderRadius="lg"
                      px={"24px"}
                      py={"21px"}
                      mt={2}
                      backgroundColor="#fff"
                      fontSize={"sm"}
                    >
                      {flexData(
                        "Document Title",
                        meetingInfo?.documentType !== ""
                          ? meetingInfo?.documentType
                          : "No Title"
                      )}
                      {flexData(
                        "Notary Note",
                        session?.notaryNote
                          ? session?.notaryNote
                          : meetingInfo?.notaryNote !== ""
                          ? meetingInfo?.notaryNote
                          : "No note added"
                      )}

                      {session?.status === "completed" && (
                        <>
                          <Text
                            mt={3}
                            fontWeight={500}
                            textDecoration="underline"
                          >
                            Stamps Detail
                          </Text>

                          {flexData("Jurat Stamps Count", totalJuratStampCount)}

                          {flexData(
                            "Acknowlegement Stamps Count",
                            totalAcknowlegementStampCount
                          )}

                          {flexData(
                            "Total Stamps",
                            totalAcknowlegementStampCount + totalJuratStampCount
                          )}
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box pt={5}>
                    <Text fontSize={"md"} fontWeight={600}>
                      Payment
                    </Text>
                    <Box
                      boxShadow="10px 4px 30px rgba(0, 0, 0, 0.25)"
                      borderRadius="lg"
                      px={"24px"}
                      py={"21px"}
                      mt={2}
                      backgroundColor="#fff"
                      fontSize={"sm"}
                    >
                      {flexData("Payment By", session?.paymentBy)}
                      {flexData(
                        "Document Fee Charged",
                        espTransformDocFee(Number(session?.documentFees))
                      )}
                    </Box>
                  </Box>

                  {session?.status === "completed" && (
                    // session?.isInvoicePaid &&
                    <RecordingData
                      session={session}
                      recordingKey={recordingKey}
                    />
                  )}
                </Stack>
              </Flex>

              <Text fontSize={"12px"} fontStyle="italic" mt={6}>
                Notarized online via Notarize Genie
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DetailsModal;
