import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AuditLogs, KBALogs, Session, Signer } from "../../../api";
import useCustomComponentsHook from "../../../hooks/useCustomComponentsHook";
import { useAuth } from "../../../utils/use-auth";
import { getMonth, getStateName } from "../../../utils/utils";
import { getFileUrl } from "../../../utils/utils-apis";
import NoDataFound from "../LoadingComponents/NoDataFound";
import ImageModal, { ImageComponent } from "./ImageModal";

const SessionSignerDetail = ({
  item,
  idx,
  session,
  ipAddressData,
}: {
  item: Signer;
  idx: number;
  session: Session;
  ipAddressData?: AuditLogs[];
}) => {
  const { configuration } = useAuth();
  const [kbaData, setKbaData] = useState<KBALogs | null>();
  const [firstScanImage, setFirstScanImage] = useState<string>("");
  const [firstBackScanImage, setFirstBackScanImage] = useState<string>("");
  const [faceImage, setFaceImage] = useState<string>("");
  const { flexData, flexDataStatus } = useCustomComponentsHook();
  const {
    isOpen: isImageModalOpen,
    onOpen: onImageModalOpen,
    onClose: onImageModalClose,
  } = useDisclosure();
  const [selectedImgaeUrl, setSelectedImgaeUrl] = useState<
    string | undefined
  >();

  const kbaLogId = session?.kbaLogs?.find(
    (el) => Number(el.userId) === Number(item?.user?.id)
  )?.kbaLogId;

  const selectedSigner = useMemo(() => {
    return session?.signers?.find((el) => el?.user?.id === item?.user?.id);
  }, [item, session]);

  useEffect(() => {
    if (!!selectedSigner?.firstScanImage === false) {
      return;
    } else {
      getFileUrl(configuration, selectedSigner?.firstScanImage as string).then(
        (url) => {
          setFirstScanImage(url);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSigner?.firstScanImage]);

  useEffect(() => {
    if (!!selectedSigner?.firstBackScanImage === false) {
      return;
    } else {
      // #NOTE : we didn't create new field in backend for face image, so we are using the same field for storing back image and face image
      const [backImage, faceImage] =
        selectedSigner?.firstBackScanImage?.split("-face-");
      getFileUrl(configuration, backImage as string).then((url) => {
        setFirstBackScanImage(url);
      });

      getFileUrl(configuration, faceImage as string).then((url) => {
        setFaceImage(url);
      });
    }
  }, [selectedSigner?.firstBackScanImage]);

  const kbaStatus =
    kbaLogId === "" || kbaLogId === undefined || kbaLogId === "pending"
      ? "pending"
      : kbaLogId === "knownByNotary"
      ? "knownByNotary"
      : kbaLogId === "demo"
      ? "demo"
      : "completed";

  const userIp = // @ts-ignore
    ipAddressData?.find((el) => el?.user?.id === item?.user?.id)?.payload?.ip;

  const openImageModal = useCallback(
    (image: string) => {
      if (image === undefined) return;
      setSelectedImgaeUrl(image);
      onImageModalOpen();
    },
    [onImageModalOpen]
  );

  return (
    <>
      <Box>
        <Text fontWeight={"600"} mb={1} mt={2} textDecoration="underline">
          Signer {idx + 1}
        </Text>

        {flexDataStatus(`${item?.firstName} ${item?.lastName}`, kbaStatus)}

        {ipAddressData !== undefined &&
          flexData("IP Address", userIp ?? "Not Provided")}

        {flexData(
          "Home Address",
          `${
            item?.addressLine1 === undefined
              ? "No Address"
              : `${item?.addressLine1}, ${
                  item?.addressLine2 !== undefined ? item?.addressLine2 : ""
                } ${getStateName(item?.state as string)}, ${item.city} - ${
                  item.zip
                }`
          }`
        )}

        {kbaStatus === "completed" && (
          <>
            {flexData("Identification Used", "KBA & Credential Analysis")}
            {flexData(
              "Form of Identification",
              // @ts-ignore
              `${kbaData?.scanResponse?.response?.["located-id-scan-record"]?.["id-scan-document-type"]}`
            )}
            {flexData(
              "ID Date of Issuance",
              `${getMonth(
                // @ts-ignore
                kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                  "id-scan-date-of-issuance"
                ]?.month
              )}, ${
                // @ts-ignore
                kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                  "id-scan-date-of-issuance"
                ]?.year
              }`
            )}

            {flexData(
              "ID Expiration Date",

              `${getMonth(
                // @ts-ignore
                kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                  "id-scan-expiration-date"
                ]?.month
              )}, ${
                // @ts-ignore
                kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                  "id-scan-expiration-date"
                ]?.year
              }`
            )}

            <Flex justifyContent={"space-between"} mt={3}>
              <Box textAlign={"center"}>
                {/* <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  textDecoration="underline"
                  mb={3}
                >
                  Front Image
                </Text> */}
                {selectedSigner?.firstScanImage === "" ||
                selectedSigner?.firstScanImage === undefined ? (
                  <NoDataFound text="No ID Found" />
                ) : (
                  <ImageComponent
                    openImageModal={openImageModal}
                    src={firstScanImage}
                  />
                )}
              </Box>
              <Divider orientation={"vertical"} size="xl" />
              <Box textAlign={"center"}>
                {/* <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  textDecoration="underline"
                  mb={3}
                >
                  Back Image
                </Text> */}
                {faceImage === "" || faceImage === undefined ? (
                  <NoDataFound text="No ID Found" />
                ) : (
                  <ImageComponent
                    openImageModal={openImageModal}
                    src={faceImage}
                  />
                )}
              </Box>
              <Divider orientation={"vertical"} size="xl" />
              <Box textAlign={"center"}>
                {/* <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  textDecoration="underline"
                  mb={3}
                >
                  Back Image
                </Text> */}
                {selectedSigner?.firstBackScanImage === "" ||
                selectedSigner?.firstBackScanImage === undefined ? (
                  <NoDataFound text="No ID Found" />
                ) : (
                  <ImageComponent
                    openImageModal={openImageModal}
                    src={firstBackScanImage}
                  />
                )}
              </Box>
            </Flex>
          </>
        )}
      </Box>
      {/* <Divider mt={4} /> */}
      {idx !== session?.signers?.length - 1 && <Divider mt={4} />}

      <ImageModal
        isOpen={isImageModalOpen}
        onClose={onImageModalClose}
        title="Image"
        content={
          <Box>
            <Image
              src={selectedImgaeUrl}
              height="100%"
              width={"100%"}
              objectFit={"contain"}
            />
          </Box>
        }
        size="full"
      />
    </>
  );
};

export default SessionSignerDetail;
