/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteBusinessDTO
 */
export interface InviteBusinessDTO {
    /**
     * 
     * @type {string}
     * @memberof InviteBusinessDTO
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof InviteBusinessDTO
     */
    businessId: number;
}

/**
 * Check if a given object implements the InviteBusinessDTO interface.
 */
export function instanceOfInviteBusinessDTO(value: object): value is InviteBusinessDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('businessId' in value) || value['businessId'] === undefined) return false;
    return true;
}

export function InviteBusinessDTOFromJSON(json: any): InviteBusinessDTO {
    return InviteBusinessDTOFromJSONTyped(json, false);
}

export function InviteBusinessDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteBusinessDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'businessId': json['businessId'],
    };
}

export function InviteBusinessDTOToJSON(value?: InviteBusinessDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'businessId': value['businessId'],
    };
}

