import { Box } from "@chakra-ui/react";
import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const OhioStamp = ({ name, height, width, commissionNumber, expires }: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <Box border={"1px solid"} width={"fit-content"}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 500.000000 192.000000"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ background: "transparent" }}
        stroke="transparent"
      >
        {/* ****************************** Name ******************************  */}

        {name.length > 22 ? (
          <>
            <text
              x="70%"
              y="10%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"26px"}
              fontWeight="bold"
              letterSpacing="0.7"
            >
              {notaryProfile.firstName} {notaryProfile.middleName}
            </text>
            <text
              x="70%"
              y="25%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"26px"}
              fontWeight="bold"
              letterSpacing="0.7"
            >
              {notaryProfile.lastName} {notaryProfile.suffix}
            </text>
          </>
        ) : (
          <text
            x="70%"
            y="20%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"26px"}
            fontWeight="bold"
            letterSpacing="0.7"
            // fill="#F0F1F2"
          >
            {name}
          </text>
        )}

        {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

        <text
          x="70%"
          y="40%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"22px"}
          fontWeight="bold"
        >
          Electronic Notary Public
        </text>

        <text
          x="70%"
          y="55%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"22px"}
          fontWeight="bold"
        >
          State of Ohio
        </text>

        {/* ************************************ line ****************************************************** */}

        {/* <line x1="585" y1="140" x2="250" y2="140" stroke="#000000" /> */}

        {/* ****************************** Text -commission ******************************  */}

        <text
          x="70%"
          y="72%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"22px"}
          fontWeight="bold"
        >
          Commission #{commissionNumber}
        </text>

        {/* *************************************** Text -Expires ******************************************** */}

        <text
          x="70%"
          y="88%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"22px"}
          fontWeight="bold"
        >
          Expires on {moment(expires).format("MMM DD, YYYY")}
        </text>

        <g
          transform="translate(10.000000,194.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          {/* ************************************************ symbol ************************************************  */}

          <path d="M866 1842 c-7 -12 4 -52 15 -52 12 0 12 47 0 55 -5 3 -12 2 -15 -3z" />
          <path
            d="M910 1826 c0 -14 5 -28 10 -31 6 -4 10 7 10 24 0 17 -4 31 -10 31 -5
0 -10 -11 -10 -24z"
          />
          <path
            d="M950 1831 c0 -11 4 -22 9 -25 11 -7 23 22 15 35 -9 16 -24 10 -24
-10z"
          />
          <path
            d="M990 1825 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25 -5
0 -10 -11 -10 -25z"
          />
          <path
            d="M1030 1825 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"
          />
          <path
            d="M1077 1843 c-10 -10 -8 -53 2 -53 10 0 21 24 21 46 0 15 -12 19 -23
7z"
          />
          <path
            d="M820 1831 c0 -17 12 -41 21 -41 5 0 9 11 9 25 0 16 -6 25 -15 25 -8
0 -15 -4 -15 -9z"
          />
          <path
            d="M1110 1815 c0 -28 15 -34 24 -9 10 25 7 34 -9 34 -9 0 -15 -9 -15
-25z"
          />
          <path d="M1157 1834 c-10 -11 -8 -54 2 -54 14 0 25 40 14 51 -5 5 -12 6 -16 3z" />
          <path d="M780 1822 c0 -19 12 -42 22 -42 14 0 5 43 -9 48 -7 2 -13 -1 -13 -6z" />
          <path
            d="M744 1804 c3 -9 6 -20 6 -25 0 -5 5 -9 10 -9 6 0 10 11 10 25 0 16
-6 25 -16 25 -11 0 -14 -5 -10 -16z"
          />
          <path
            d="M1195 1799 c-4 -11 -4 -23 0 -27 7 -7 25 17 25 36 0 20 -18 14 -25
-9z"
          />
          <path d="M700 1806 c0 -17 22 -56 29 -52 13 8 0 56 -15 56 -8 0 -14 -2 -14 -4z" />
          <path d="M1237 1803 c-10 -10 -8 -43 2 -43 9 0 21 24 21 41 0 11 -14 12 -23 2z" />
          <path d="M665 1790 c-8 -13 13 -52 25 -45 9 6 2 43 -11 50 -4 3 -10 1 -14 -5z" />
          <path
            d="M1276 1784 c-3 -9 -6 -23 -6 -32 1 -15 3 -14 15 2 17 22 20 46 6 46
-5 0 -12 -7 -15 -16z"
          />
          <path
            d="M1310 1766 c-12 -34 -12 -36 0 -36 16 0 33 41 21 53 -7 7 -13 2 -21
-17z"
          />
          <path
            d="M620 1772 c0 -15 28 -47 35 -40 8 8 -13 48 -25 48 -6 0 -10 -4 -10
-8z"
          />
          <path
            d="M1351 1752 c-22 -41 -7 -59 17 -21 9 14 10 25 4 31 -7 7 -14 3 -21
-10z"
          />
          <path d="M590 1748 c0 -19 18 -43 25 -36 9 9 -4 48 -16 48 -5 0 -9 -6 -9 -12z" />
          <path
            d="M870 1741 c-26 -8 -26 -8 -15 -93 7 -53 25 -66 25 -18 0 39 24 40 62
3 34 -32 51 -23 19 11 -20 21 -21 27 -9 45 26 42 -19 70 -82 52z m66 -22 c8
-14 -5 -26 -38 -34 -24 -6 -28 -4 -28 13 0 26 52 43 66 21z"
          />
          <path
            d="M990 1745 c0 -2 9 -19 20 -37 11 -18 20 -48 20 -65 0 -18 5 -33 10
-33 6 0 10 10 10 23 0 13 14 42 31 65 38 52 29 71 -10 23 l-28 -34 -12 32 c-8
17 -20 31 -27 31 -8 0 -14 -2 -14 -5z"
          />
          <path
            d="M1386 1734 c-3 -9 -6 -23 -6 -32 1 -14 5 -12 20 7 11 12 17 27 14 32
-8 14 -21 11 -28 -7z"
          />
          <path
            d="M550 1721 c12 -23 30 -36 30 -22 0 21 -13 41 -26 41 -11 0 -12 -4 -4
-19z"
          />
          <path
            d="M1420 1710 c-15 -28 -4 -39 17 -17 13 12 14 20 6 28 -8 8 -14 5 -23
-11z"
          />
          <path
            d="M510 1712 c0 -5 9 -17 20 -27 20 -18 25 -11 14 19 -6 17 -34 23 -34
8z"
          />
          <path
            d="M738 1714 c-10 -16 -12 -139 -2 -142 5 -1 10 6 12 16 2 11 13 24 26
30 19 8 27 7 43 -10 11 -11 23 -16 26 -11 3 5 -18 36 -47 68 -29 32 -55 54
-58 49z m42 -64 c0 -4 -7 -10 -15 -14 -11 -4 -15 2 -15 21 0 21 2 24 15 13 8
-7 15 -16 15 -20z"
          />
          <path
            d="M1213 1703 c-24 -9 -14 -49 18 -72 24 -18 28 -25 19 -37 -15 -18 -48
-2 -60 29 -8 21 -8 21 -16 3 -5 -14 0 -25 20 -43 32 -27 49 -29 70 -7 23 23
20 39 -15 68 -30 25 -32 46 -4 46 8 0 19 -9 25 -20 12 -23 30 -27 30 -6 0 30
-53 53 -87 39z"
          />
          <path
            d="M477 1693 c-4 -6 1 -21 11 -34 12 -13 21 -18 25 -12 4 6 -1 21 -11
34 -12 13 -21 18 -25 12z"
          />
          <path
            d="M1455 1679 c-9 -29 2 -36 20 -13 18 24 19 34 1 34 -8 0 -18 -9 -21
-21z"
          />
          <path
            d="M627 1664 c-26 -14 -45 -29 -41 -35 4 -6 11 -6 21 3 18 14 35 -4 55
-59 7 -18 18 -33 25 -33 17 0 17 3 -12 59 l-24 49 21 14 c38 26 4 27 -45 2z"
          />
          <path
            d="M1490 1659 c-7 -11 -9 -23 -6 -26 8 -9 36 17 36 33 0 21 -18 17 -30
-7z"
          />
          <path
            d="M440 1662 c0 -13 36 -43 43 -36 9 9 -16 44 -30 44 -7 0 -13 -4 -13
-8z"
          />
          <path
            d="M1324 1613 c-15 -27 -31 -53 -36 -60 -6 -8 5 -20 35 -37 24 -14 47
-26 50 -26 17 0 4 17 -29 39 -30 21 -33 26 -21 39 13 12 18 12 41 -3 31 -20
51 -10 22 10 -10 8 -24 16 -29 18 -6 2 -8 11 -4 21 7 17 9 16 41 -3 37 -23 46
-25 46 -12 0 8 -73 61 -84 61 -3 0 -17 -21 -32 -47z"
          />
          <path
            d="M415 1641 c-9 -15 18 -45 35 -39 8 2 6 11 -8 27 -14 18 -21 21 -27
12z"
          />
          <path
            d="M1520 1629 c-7 -11 -10 -22 -8 -25 6 -6 48 26 48 37 0 17 -29 9 -40
-12z"
          />
          <path
            d="M1557 1612 c-30 -32 -18 -43 17 -17 14 10 17 18 10 25 -7 7 -16 5
-27 -8z"
          />
          <path
            d="M380 1612 c0 -5 9 -17 20 -27 16 -14 20 -15 20 -4 0 16 -19 39 -32
39 -4 0 -8 -4 -8 -8z"
          />
          <path
            d="M497 1579 c-35 -27 -36 -71 -2 -104 13 -14 34 -25 45 -25 27 0 70 43
70 69 0 24 -38 67 -66 75 -13 4 -31 -1 -47 -15z m79 -34 c15 -23 15 -27 0 -50
-9 -14 -23 -25 -31 -25 -21 0 -55 36 -55 58 0 45 60 57 86 17z"
          />
          <path
            d="M350 1575 c0 -8 9 -19 20 -25 15 -8 20 -8 20 3 0 14 -19 37 -32 37
-4 0 -8 -7 -8 -15z"
          />
          <path
            d="M1580 1570 c-6 -11 -8 -20 -5 -20 14 0 45 21 45 30 0 18 -29 10 -40
-10z"
          />
          <path
            d="M891 1556 c-9 -10 -9 -16 -1 -21 17 -10 37 12 24 25 -8 8 -15 6 -23
-4z"
          />
          <path
            d="M945 1560 c-4 -6 -2 -16 3 -23 5 -7 10 -46 11 -87 1 -41 7 -79 12
-85 7 -7 9 25 7 94 -2 68 -7 106 -15 108 -6 2 -14 -1 -18 -7z"
          />
          <path
            d="M1000 1555 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1055 1560 c-4 -6 -1 -17 5 -25 9 -11 8 -32 -5 -92 -18 -82 -18 -82
-9 -85 10 -4 56 165 47 171 -4 3 -8 12 -8 20 0 18 -21 26 -30 11z"
          />
          <path
            d="M1615 1550 c-25 -27 -10 -39 16 -13 12 11 17 23 11 27 -5 3 -17 -3
-27 -14z"
          />
          <path
            d="M320 1552 c0 -14 36 -43 43 -35 4 3 0 15 -10 25 -17 19 -33 24 -33
10z"
          />
          <path d="M835 1550 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16 -5z" />
          <path d="M1116 1553 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
          <path
            d="M1438 1519 c-32 -16 -58 -34 -58 -40 0 -10 6 -9 46 7 5 3 18 -4 28
-15 13 -15 15 -24 8 -38 -8 -13 -7 -22 3 -33 8 -8 28 -31 44 -50 17 -19 31
-29 31 -21 0 8 -8 23 -17 33 -16 18 -15 21 27 57 59 51 44 65 -15 16 -25 -20
-49 -35 -52 -31 -3 3 4 31 16 62 36 95 30 100 -61 53z m52 -19 c-12 -21 -25
-26 -35 -11 -3 6 3 14 12 20 27 16 35 13 23 -9z"
          />
          <path
            d="M782 1524 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13
-16z"
          />
          <path
            d="M1165 1530 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
-10z"
          />
          <path
            d="M295 1521 c-3 -5 5 -17 17 -25 26 -18 38 -5 18 19 -14 17 -26 19 -35
6z"
          />
          <path
            d="M1635 1510 c-14 -16 -15 -20 -4 -20 16 0 39 19 39 32 0 14 -16 9 -35
-12z"
          />
          <path
            d="M727 1505 c-8 -18 11 -31 25 -17 11 11 3 32 -12 32 -4 0 -10 -7 -13
-15z"
          />
          <path
            d="M1214 1509 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"
          />
          <path
            d="M835 1493 c4 -10 18 -48 32 -85 14 -37 29 -66 33 -63 13 8 -42 165
-58 165 -9 0 -12 -6 -7 -17z"
          />
          <path
            d="M274 1489 c-4 -7 1 -18 11 -26 36 -26 49 -9 16 21 -14 13 -21 14 -27
5z"
          />
          <path
            d="M400 1490 c0 -6 15 -24 33 -41 l32 -30 -56 7 c-33 3 -59 2 -63 -4 -6
-10 85 -92 103 -92 21 0 11 18 -24 41 -19 13 -35 27 -35 30 0 3 24 2 53 -2 81
-10 86 1 24 54 -57 49 -67 54 -67 37z"
          />
          <path
            d="M1664 1486 c-22 -17 -11 -35 13 -20 19 12 29 34 16 34 -5 0 -18 -6
-29 -14z"
          />
          <path d="M677 1484 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
          <path
            d="M1143 1418 c-63 -112 -61 -133 2 -38 67 99 72 110 53 110 -8 0 -32
-33 -55 -72z"
          />
          <path
            d="M1262 1478 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
          />
          <path
            d="M720 1464 c0 -10 114 -154 122 -154 14 0 9 8 -44 85 -29 41 -58 75
-65 75 -7 0 -13 -3 -13 -6z"
          />
          <path
            d="M1691 1454 c-12 -8 -19 -19 -16 -24 6 -10 55 9 55 21 0 16 -18 18
-39 3z"
          />
          <path
            d="M245 1451 c-6 -11 33 -36 42 -27 7 7 -16 36 -28 36 -4 0 -10 -4 -14
-9z"
          />
          <path
            d="M630 1445 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1310 1446 c0 -8 -6 -16 -12 -18 -21 -5 -142 -137 -134 -145 4 -4 41
27 83 70 41 42 79 77 84 77 5 0 9 7 9 15 0 8 -7 15 -15 15 -8 0 -15 -6 -15
-14z"
          />
          <path
            d="M590 1410 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"
          />
          <path
            d="M1354 1419 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"
          />
          <path
            d="M1716 1415 c-11 -8 -17 -18 -14 -21 11 -10 48 7 48 22 0 18 -10 17
-34 -1z"
          />
          <path
            d="M220 1410 c0 -12 40 -33 48 -25 4 4 -2 13 -12 21 -23 16 -36 18 -36
4z"
          />
          <path
            d="M626 1402 c-6 -10 21 -36 96 -95 78 -62 77 -41 -2 33 -77 71 -85 76
-94 62z"
          />
          <path d="M200 1376 c0 -15 37 -32 48 -22 6 6 -27 35 -40 36 -5 0 -8 -6 -8 -14z" />
          <path d="M546 1381 c-3 -5 1 -14 8 -20 16 -13 32 2 21 19 -8 12 -22 13 -29 1z" />
          <path d="M1396 1381 c-3 -5 1 -14 8 -20 16 -13 32 2 21 19 -8 12 -22 13 -29 1z" />
          <path
            d="M1730 1375 c-7 -9 -10 -18 -7 -21 7 -8 47 14 47 26 0 15 -26 12 -40
-5z"
          />
          <path
            d="M1275 1308 c-49 -34 -85 -63 -79 -65 16 -5 195 107 188 118 -8 14
-14 12 -109 -53z"
          />
          <path d="M180 1336 c0 -14 40 -30 50 -20 5 6 -29 34 -42 34 -4 0 -8 -6 -8 -14z" />
          <path d="M516 1343 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
          <path d="M1435 1340 c-7 -11 2 -30 15 -30 4 0 10 6 13 14 6 16 -19 30 -28 16z" />
          <path
            d="M1744 1335 c-16 -12 -17 -14 -2 -15 23 0 48 11 48 21 0 14 -24 11
-46 -6z"
          />
          <path
            d="M556 1331 c-6 -9 176 -111 197 -111 19 0 -5 17 -96 69 -97 56 -93 55
-101 42z"
          />
          <path d="M965 1330 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
          <path
            d="M900 1320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M165 1300 c-7 -11 44 -34 53 -25 6 5 -27 34 -40 35 -4 0 -10 -5 -13
-10z"
          />
          <path
            d="M1322 1252 c-57 -30 -101 -56 -98 -59 6 -6 195 74 211 88 6 6 6 12 0
17 -6 5 -55 -15 -113 -46z"
          />
          <path
            d="M1760 1295 c-10 -12 -10 -15 3 -15 23 0 47 11 47 21 0 15 -37 10 -50
-6z"
          />
          <path d="M477 1294 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
          <path
            d="M1465 1291 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"
          />
          <path
            d="M1135 1270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
          />
          <path
            d="M1784 1266 c-10 -8 -17 -17 -14 -20 10 -10 50 6 50 20 0 18 -11 18
-36 0z"
          />
          <path
            d="M150 1255 c0 -8 4 -15 9 -15 5 0 17 -3 27 -7 11 -4 15 -2 12 7 -6 20
-48 33 -48 15z"
          />
          <path
            d="M505 1260 c-9 -15 -16 -11 107 -53 167 -57 173 -45 9 20 -47 19 -91
37 -97 39 -7 3 -15 0 -19 -6z"
          />
          <path
            d="M800 1250 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M950 1249 c-44 -9 -86 -58 -95 -111 -6 -32 -10 -38 -23 -32 -10 4
-27 6 -39 5 -13 0 -23 4 -23 9 0 6 -5 10 -11 10 -8 0 -8 -5 1 -15 10 -12 7
-16 -16 -25 -18 -7 -36 -8 -53 -1 -45 17 -134 19 -198 6 -35 -7 -72 -11 -84
-8 -16 4 -20 2 -17 -8 3 -8 13 -13 22 -12 12 0 15 -4 12 -14 -3 -8 -6 -16 -6
-19 0 -2 -7 -1 -16 3 -12 4 -15 1 -12 -13 3 -16 14 -19 83 -22 44 -2 152 -7
240 -11 222 -10 639 -13 742 -5 l85 7 -5 53 c-7 70 -27 110 -52 106 -11 -2
-60 -5 -110 -8 l-90 -4 98 29 c104 30 116 36 101 50 -11 12 -228 -62 -251 -84
-11 -11 -30 -15 -65 -13 -48 3 -50 4 -64 43 -23 69 -79 99 -154 84z m107 -41
c27 -25 47 -67 37 -77 -3 -3 -55 -8 -115 -11 -104 -5 -109 -4 -109 15 1 30 23
64 56 86 37 26 96 20 131 -13z m-110 -104 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m40 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-172 -14 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4
9 -10z m218 3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
          />
          <path
            d="M450 1235 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1492 1238 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12
-10z"
          />
          <path
            d="M1160 1230 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M140 1216 c0 -10 9 -16 25 -16 14 0 25 5 25 10 0 6 -4 10 -9 10 -5 0
-16 3 -25 6 -11 4 -16 1 -16 -10z"
          />
          <path
            d="M1790 1220 c-8 -5 -11 -12 -8 -16 10 -9 58 4 58 16 0 13 -30 13 -50
0z"
          />
          <path
            d="M770 1210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M426 1191 c-11 -17 44 -38 145 -55 53 -9 112 -19 130 -22 23 -5 31
-3 24 4 -5 5 -66 24 -135 40 -69 17 -132 33 -141 37 -10 3 -20 2 -23 -4z"
          />
          <path d="M1517 1194 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
          <path d="M127 1184 c-12 -12 4 -24 31 -24 26 1 26 1 8 15 -22 17 -30 18 -39 9z" />
          <path
            d="M1185 1180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
          />
          <path
            d="M1808 1183 c-10 -2 -18 -9 -18 -14 0 -15 53 -6 58 9 4 12 -8 14 -40
5z"
          />
          <path
            d="M760 1160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M120 1136 c0 -10 9 -16 25 -16 14 0 25 5 25 10 0 6 -4 10 -9 10 -5 0
-16 3 -25 6 -11 4 -16 1 -16 -10z"
          />
          <path
            d="M410 1130 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"
          />
          <path
            d="M1200 1140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
          />
          <path
            d="M1534 1139 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"
          />
          <path
            d="M1813 1143 c-24 -9 -13 -24 15 -21 15 2 26 8 25 13 -2 12 -23 16 -40
8z"
          />
          <path d="M1828 1103 c-27 -7 -22 -23 7 -23 16 0 25 6 25 15 0 15 -2 16 -32 8z" />
          <path
            d="M110 1090 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"
          />
          <path d="M1556 1093 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z" />
          <path
            d="M107 1064 c-3 -4 -4 -11 -1 -15 7 -12 54 -11 54 1 0 6 -4 10 -9 10
-5 0 -16 2 -23 5 -8 3 -17 3 -21 -1z"
          />
          <path
            d="M1821 1061 c-9 -6 -10 -11 -2 -15 14 -9 51 2 51 15 0 11 -30 12 -49
0z"
          />
          <path
            d="M261 1018 c-7 -20 -18 -28 -36 -28 -30 0 -32 -11 -5 -28 16 -10 18
-17 10 -37 -11 -28 -8 -30 20 -13 16 10 24 10 40 0 29 -18 32 -15 25 17 -4 21
-2 30 9 34 24 10 19 27 -8 27 -18 0 -27 8 -34 28 l-10 27 -11 -27z"
          />
          <path
            d="M1556 1032 c-2 -4 -1 -14 4 -22 7 -11 12 -12 21 -3 6 6 8 16 5 22 -8
13 -23 14 -30 3z"
          />
          <path
            d="M1696 1015 c-5 -18 -13 -25 -31 -25 -28 0 -34 -17 -9 -27 12 -4 14
-14 10 -34 -6 -33 -4 -34 24 -17 16 10 24 10 41 0 26 -17 34 -10 20 17 -10 17
-8 23 9 33 27 17 25 26 -7 30 -20 2 -29 9 -31 26 -4 29 -18 28 -26 -3z"
          />
          <path
            d="M1843 1023 c-31 -6 -29 -23 2 -23 16 0 25 6 25 15 0 8 -1 14 -2 14
-2 -1 -13 -4 -25 -6z"
          />
          <path d="M100 1011 c0 -11 40 -22 52 -15 5 3 6 10 3 15 -8 12 -55 12 -55 0z" />
          <path
            d="M420 980 c0 -4 -10 -6 -21 -3 -16 4 -20 2 -17 -8 3 -8 13 -13 22 -13
9 1 16 -6 16 -16 0 -11 -7 -17 -17 -16 -10 0 -18 -6 -18 -14 0 -8 8 -14 17
-14 10 1 21 -10 27 -26 l9 -29 76 6 c54 4 76 3 76 -6 0 -6 8 -11 18 -11 14 0
15 2 2 10 -9 6 -10 10 -3 10 6 0 19 3 29 7 11 4 15 3 11 -4 -4 -6 1 -17 10
-24 14 -12 15 -11 9 8 -6 20 -3 22 70 28 116 11 380 1 399 -15 14 -12 12 -15
-17 -28 -18 -8 -42 -17 -53 -19 -18 -3 -18 -2 -3 9 14 11 15 15 3 25 -9 8 -16
9 -20 3 -3 -6 -1 -10 6 -10 9 0 9 -4 -1 -15 -14 -17 -4 -39 16 -31 7 3 16 1
19 -4 4 -6 1 -10 -5 -10 -6 0 -9 -4 -6 -9 4 -5 0 -12 -6 -15 -7 -3 -1 -4 13
-1 18 3 35 -4 60 -25 22 -19 29 -30 19 -31 -8 -1 -19 -2 -25 -4 -5 -1 -22 -3
-37 -3 -26 -2 -26 -3 -12 -31 16 -31 40 -46 49 -31 4 6 11 10 17 10 6 0 3 -8
-8 -19 -13 -13 -23 -16 -32 -8 -7 5 -16 7 -19 3 -4 -4 -2 -11 5 -15 22 -14
-38 -46 -86 -46 -24 0 -39 1 -33 3 6 2 11 8 11 14 0 6 -7 8 -15 4 -8 -3 -15
-1 -15 4 0 14 16 12 40 -2 17 -11 22 -10 31 2 9 13 10 12 5 -2 -7 -24 19 -24
29 0 17 37 16 54 -2 50 -10 -2 -17 -10 -15 -16 1 -7 -2 -12 -8 -12 -15 0 -12
16 5 34 17 17 20 42 5 51 -17 11 -32 -5 -19 -21 14 -17 -2 -19 -18 -3 -9 9 -8
15 2 25 7 8 18 14 24 14 5 0 13 5 17 11 4 8 -1 9 -17 4 -17 -4 -20 -3 -10 3 8
6 10 15 6 22 -6 9 -4 11 6 5 11 -7 12 -6 2 7 -7 8 -10 18 -6 21 3 4 2 7 -4 7
-5 0 -9 19 -7 43 l2 42 -19 -24 c-10 -13 -19 -31 -18 -40 0 -12 2 -12 9 4 8
19 9 19 9 -2 1 -15 -5 -23 -15 -23 -8 0 -12 -5 -9 -10 4 -6 2 -17 -4 -24 -9
-10 -7 -15 9 -19 11 -3 20 -11 19 -19 0 -10 -2 -10 -6 0 -2 6 -14 11 -26 11
-17 -1 -18 -2 -4 -6 9 -2 17 -9 17 -14 0 -16 -25 -10 -42 11 -16 17 -16 21 -2
35 9 8 12 15 8 15 -3 0 -1 7 6 15 6 8 9 19 5 25 -7 12 -35 14 -35 2 0 -5 -11
-15 -25 -22 -21 -11 -23 -16 -12 -29 10 -12 9 -14 -3 -7 -9 4 -8 1 3 -7 23
-19 22 -30 -2 -18 -15 8 -24 5 -46 -15 -15 -14 -24 -28 -21 -31 3 -4 15 3 26
15 22 23 36 30 25 12 -4 -7 2 -9 18 -4 38 9 66 -3 62 -26 -2 -13 2 -20 11 -20
8 0 13 4 9 9 -3 5 -1 12 5 16 5 3 10 0 10 -9 0 -8 5 -16 12 -18 9 -3 9 -6 0
-12 -7 -4 -12 -3 -12 2 0 5 -11 7 -25 4 -24 -4 -36 -22 -15 -22 6 0 10 -5 10
-11 0 -5 -4 -8 -9 -4 -6 3 -15 -3 -20 -14 -15 -27 -14 -34 4 -27 8 3 15 1 15
-4 0 -6 -7 -10 -15 -10 -18 0 -20 -26 -2 -34 6 -3 3 -4 -8 -1 -15 4 -21 0 -22
-15 -2 -21 11 -27 22 -10 8 12 25 13 25 1 0 -9 -38 -22 -53 -18 -16 4 -29 -33
-17 -48 6 -8 9 -19 5 -25 -9 -15 3 -30 21 -23 9 3 12 12 9 24 -5 14 -2 17 9
13 9 -3 16 -15 16 -25 0 -10 7 -19 15 -19 19 0 19 24 0 43 -13 13 -13 16 1 27
12 11 13 10 9 -2 -7 -18 20 -42 36 -33 8 5 9 1 4 -14 -4 -15 -2 -21 8 -21 21
0 28 12 16 26 -6 8 -7 14 0 18 6 4 18 -5 27 -19 21 -32 42 -18 22 14 -7 11
-16 21 -20 21 -14 0 -8 18 8 24 9 3 12 2 8 -5 -3 -6 7 -24 24 -40 22 -22 32
-26 38 -17 14 22 13 58 -1 58 -15 0 -28 27 -19 41 3 5 -3 9 -13 9 -14 0 -15 2
-4 9 9 6 10 11 2 15 -6 4 -15 5 -20 2 -14 -9 -1 32 14 44 10 9 16 7 24 -8 6
-11 8 -22 5 -24 -2 -3 0 -16 5 -29 5 -13 12 -33 16 -44 7 -19 7 -19 16 3 5 13
14 20 20 17 6 -4 8 -13 5 -21 -3 -7 -1 -16 3 -19 11 -6 21 17 17 42 -2 10 -1
12 1 6 3 -7 10 -13 16 -13 8 0 8 -3 0 -11 -13 -13 -5 -51 11 -46 13 4 16 56 4
63 -12 7 -10 24 3 24 5 0 7 -4 4 -10 -3 -5 -1 -10 4 -10 6 0 11 10 12 23 0 22
0 22 10 -2 7 -19 12 -22 21 -13 14 14 32 16 23 3 -7 -12 12 -24 25 -16 8 5 8
11 0 20 -7 9 -6 16 4 24 11 9 15 8 20 -5 5 -13 10 -14 18 -6 8 8 8 15 -1 25
-9 11 -8 17 4 27 16 13 21 35 6 25 -11 -6 -44 36 -35 44 3 3 10 -2 15 -12 6
-9 14 -15 20 -12 5 4 9 1 9 -4 0 -6 5 -11 11 -11 5 0 7 5 4 10 -3 6 -2 10 4
10 18 0 34 40 21 55 -9 11 -7 12 8 8 13 -3 21 2 24 15 4 16 -3 24 -33 38 -22
9 -39 20 -39 24 0 4 -10 10 -22 13 -12 4 -19 9 -15 13 3 4 18 -1 32 -10 15
-10 33 -15 41 -12 8 3 12 2 9 -4 -7 -12 28 -31 41 -22 9 7 34 57 34 69 0 3 -7
3 -15 -1 -8 -3 -15 -1 -15 4 0 6 6 10 14 10 8 0 18 19 25 44 19 76 18 78 -41
70 -68 -9 -278 -11 -283 -4 -2 4 -34 5 -72 4 -37 -1 -73 3 -80 8 -7 6 -47 10
-90 10 -42 -1 -82 2 -88 6 -5 4 -37 8 -70 10 -33 1 -80 5 -105 8 -58 6 -111 6
-118 -1 -3 -3 -10 -1 -16 4 -15 12 -146 22 -146 11z m950 -130 c69 0 133 -20
115 -37 -7 -7 -15 -9 -19 -6 -3 3 -14 -5 -25 -18 -13 -15 -20 -19 -21 -10 0 8
-3 11 -7 8 -3 -4 -25 4 -47 17 -46 27 -100 39 -163 37 -23 -1 -45 4 -49 10 -5
9 13 10 77 5 46 -4 109 -7 139 -6z m-140 -37 c-19 -3 -43 -12 -52 -20 -10 -9
-18 -11 -18 -5 0 6 6 13 13 15 7 4 8 6 1 6 -6 1 -17 -6 -24 -14 -16 -19 -30
-19 -30 -1 0 20 16 25 85 24 37 -1 47 -3 25 -5z m-305 -73 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m429 -13 c17 -13 18 -14 2
-20 -10 -4 -14 -11 -11 -17 3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -4 6 -1 9 6
8 6 -2 14 3 16 11 4 10 0 11 -17 6 -20 -6 -21 -5 -9 8 17 20 21 20 43 4z
m-141 -27 c3 -11 1 -20 -4 -20 -5 0 -9 4 -9 9 0 5 -3 17 -7 27 -4 11 -3 15 4
11 6 -4 13 -16 16 -27z m180 3 c-7 -2 -13 -9 -13 -14 0 -4 6 -6 13 -4 6 3 18
-4 24 -14 13 -21 8 -51 -8 -51 -4 0 -6 7 -3 15 4 8 1 15 -6 15 -6 0 -8 5 -4
11 5 8 -1 10 -20 7 -21 -4 -24 -3 -15 8 6 8 8 14 3 14 -5 0 -2 5 6 10 8 5 20
9 25 9 6 0 4 -3 -2 -6z m-93 -23 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m-136 -24 c-3 -9 -10 -13 -15 -10 -6 3 -17 0
-25 -7 -8 -6 -17 -9 -21 -5 -4 3 8 12 25 18 18 7 32 17 32 22 0 5 2 7 5 4 3
-3 3 -13 -1 -22z m42 -57 l-15 -34 5 30 c3 17 9 39 12 50 5 16 7 17 9 4 2 -9
-3 -32 -11 -50z m104 40 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4
6 -3 10 -11 10 -16z m40 5 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4
8 -4 15 -8 15 -10z m-337 -39 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15
13 8 -4z m340 -38 c-6 -10 -32 23 -33 42 0 7 8 3 19 -10 11 -13 17 -28 14 -32z
m-183 -7 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m57 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-257 -12 c0
-11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m55 -24 c5 -14 7 -14 15
-2 7 11 10 12 10 2 0 -8 5 -10 13 -6 6 4 9 4 5 0 -3 -4 -10 -8 -15 -8 -11 1
-43 -29 -43 -41 0 -5 7 -7 15 -3 16 6 21 -9 6 -18 -20 -13 -41 6 -35 30 5 18
2 24 -10 24 -9 0 -16 5 -16 10 0 7 7 7 21 0 26 -15 35 -7 14 13 -19 19 -19 28
-1 21 8 -3 17 -13 21 -22z m86 -53 l22 -31 -21 11 c-12 7 -22 18 -22 26 0 7
-6 16 -12 18 -9 4 -10 6 -1 6 6 1 22 -13 34 -30z m-136 11 c-3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"
          />
          <path
            d="M1350 829 c0 -6 72 -39 86 -39 4 0 1 7 -6 15 -10 12 -9 15 6 15 16 0
17 2 4 10 -17 11 -90 10 -90 -1z"
          />
          <path
            d="M107 973 c-14 -13 -6 -23 17 -23 30 0 42 15 20 24 -20 8 -29 8 -37
-1z"
          />
          <path
            d="M1560 965 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1820 970 c0 -5 6 -10 14 -10 8 0 22 -3 30 -6 12 -5 15 -2 10 10 -7
17 -54 23 -54 6z"
          />
          <path
            d="M1820 931 c0 -9 24 -21 41 -21 5 0 9 7 9 15 0 9 -9 15 -25 15 -14 0
-25 -4 -25 -9z"
          />
          <path
            d="M100 920 c0 -5 11 -10 24 -10 14 0 28 5 31 10 4 6 -7 10 -24 10 -17
0 -31 -4 -31 -10z"
          />
          <path
            d="M1557 915 c-8 -18 11 -31 25 -17 11 11 3 32 -12 32 -4 0 -10 -7 -13
-15z"
          />
          <path
            d="M1815 890 c3 -5 17 -10 31 -10 13 0 24 5 24 10 0 6 -14 10 -31 10
-17 0 -28 -4 -24 -10z"
          />
          <path d="M105 881 c-3 -5 -2 -12 3 -15 12 -7 52 4 52 15 0 12 -47 12 -55 0z" />
          <path
            d="M390 855 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1550 855 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M860 851 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"
          />
          <path
            d="M909 858 c-6 -11 -5 -48 1 -48 14 0 32 29 25 40 -5 9 -23 14 -26 8z
m21 -12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z"
          />
          <path
            d="M977 846 c-4 -9 -11 -15 -16 -12 -5 3 -11 -1 -15 -9 -7 -19 18 -20
34 0 6 8 10 20 8 27 -3 8 -7 6 -11 -6z"
          />
          <path d="M1810 851 c0 -10 47 -21 54 -13 3 3 3 9 0 13 -7 12 -54 12 -54 0z" />
          <path
            d="M110 840 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14
0 -25 -4 -25 -10z"
          />
          <path
            d="M681 837 c0 -9 -12 -22 -26 -29 -13 -6 -22 -15 -18 -21 3 -6 1 -7 -6
-3 -7 4 -20 4 -29 0 -9 -4 -21 -8 -27 -9 -21 -3 -71 -18 -88 -27 -15 -8 -16
-11 -3 -23 19 -20 27 -19 19 3 -5 14 -3 15 8 5 16 -16 33 -17 23 -1 -4 6 -3 8
2 5 6 -3 29 3 52 13 25 11 44 15 47 9 4 -5 -10 -16 -29 -24 -20 -9 -36 -20
-36 -26 0 -5 6 -7 13 -3 6 3 -5 -9 -26 -28 -21 -18 -35 -36 -32 -40 8 -8 65
14 65 25 0 4 -6 5 -12 1 -10 -5 -10 -4 -1 6 7 7 20 14 30 15 10 1 28 7 41 14
19 10 21 15 11 34 l-11 22 21 -22 c22 -23 28 -39 12 -28 -19 11 -22 -16 -4
-33 11 -9 13 -13 6 -8 -8 5 -17 5 -21 1 -4 -4 -1 -10 6 -12 17 -6 36 -43 22
-43 -5 0 -10 6 -10 14 0 8 -9 17 -19 21 -12 4 -18 12 -14 21 7 20 -4 18 -28
-5 -12 -11 -19 -23 -16 -28 3 -5 -6 -9 -20 -8 -14 0 -28 -5 -30 -11 -2 -6 8
-10 24 -9 16 1 20 0 11 -2 -23 -6 -23 -23 0 -23 22 0 64 -29 57 -39 -2 -5 -15
-6 -29 -4 -27 6 -44 -13 -27 -30 9 -9 14 -9 19 0 4 7 15 11 25 9 21 -3 51 -42
42 -55 -8 -15 14 -24 29 -12 15 13 27 5 20 -14 -6 -16 12 -29 26 -20 8 5 7 11
-1 21 -14 17 -5 18 23 3 16 -9 18 -13 7 -20 -11 -7 -12 -10 -1 -21 11 -11 16
-10 26 5 18 23 39 22 31 -2 -8 -27 13 -37 23 -10 5 13 4 32 -2 45 -6 13 -13
19 -14 14 -2 -6 -8 -8 -13 -4 -5 3 -9 1 -9 -5 0 -6 -7 -8 -16 -4 -12 4 -9 9
15 19 17 7 35 23 41 36 5 13 9 17 9 10 1 -7 6 -10 11 -7 6 3 8 17 6 30 -3 13
0 25 6 27 5 2 11 16 11 31 1 15 8 29 15 32 6 2 12 18 12 35 0 28 -2 30 -34 27
-19 -1 -33 -4 -31 -7 2 -4 -5 -11 -15 -18 -12 -7 -21 -8 -25 -2 -4 6 -11 8
-16 5 -16 -11 -10 16 12 46 11 16 25 27 30 24 5 -4 9 2 9 11 0 10 9 28 21 40
l20 22 -30 -4 c-31 -4 -43 10 -18 19 6 3 2 6 -10 6 -12 1 -23 -5 -26 -11 -2
-7 -3 -5 -2 5 2 9 -1 17 -6 17 -5 0 -9 -8 -9 -17 0 -10 -5 -25 -10 -33 -8 -11
-9 -8 -4 12 4 17 1 32 -7 40 -17 16 -65 22 -73 9 -3 -5 4 -7 16 -4 19 4 20 3
8 -6 -22 -16 -38 -13 -43 7 -4 16 -5 16 -6 -1z m17 -62 c-3 -3 -9 2 -12 12 -6
14 -5 15 5 6 7 -7 10 -15 7 -18z m-128 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m141 -87 c13 -16 12 -17 -3 -4 -10 7 -18
15 -18 17 0 8 8 3 21 -13z m185 -35 c5 -8 2 -9 -10 -5 -10 4 -16 2 -15 -6 3
-23 -1 -37 -11 -37 -5 0 -6 8 -3 17 5 12 2 15 -10 10 -9 -3 -17 -2 -17 3 0 6
7 10 15 10 8 0 15 5 15 10 0 14 27 13 36 -2z m-242 -29 c-30 -12 -37 -10 -24
5 6 8 19 12 27 9 14 -6 14 -8 -3 -14z m16 -34 c-10 -12 -16 -13 -28 -3 -13 11
-11 13 9 19 30 7 35 3 19 -16z m110 -79 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10
4 0 6 5 11 10 11 6 0 10 -2 10 -4z m45 -6 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3
10 19 12 23 12 15 0z"
          />
          <path
            d="M475 830 c-3 -5 -13 -6 -23 -3 -13 4 -14 3 -6 -6 11 -12 44 -5 44 10
0 12 -7 11 -15 -1z"
          />
          <path
            d="M505 830 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2 0
-7 -4 -10 -10z"
          />
          <path
            d="M540 830 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
          />
          <path
            d="M600 819 c-19 -4 -55 -10 -79 -13 -51 -5 -73 -21 -43 -31 12 -3 34 0
49 8 15 7 36 12 46 10 9 -3 17 1 17 7 0 6 4 9 9 6 5 -4 19 -2 32 2 32 12 10
20 -31 11z"
          />
          <path
            d="M404 809 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
          />
          <path
            d="M1534 809 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
          />
          <path
            d="M1806 811 c15 -15 47 -17 51 -3 3 8 -6 12 -28 12 -20 0 -29 -4 -23
-9z"
          />
          <path d="M120 795 c0 -16 9 -19 34 -9 25 9 19 24 -9 24 -16 0 -25 -6 -25 -15z" />
          <path d="M1795 770 c-3 -5 6 -12 20 -16 29 -7 41 -1 30 15 -8 14 -42 14 -50 1z" />
          <path d="M125 761 c-3 -5 -1 -12 5 -16 13 -8 50 3 50 16 0 12 -47 12 -55 0z" />
          <path d="M422 748 c5 -15 28 -18 29 -3 0 6 -7 12 -17 13 -10 3 -15 -1 -12 -10z" />
          <path
            d="M1520 745 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1084 732 c-15 -2 -21 -10 -21 -27 0 -19 3 -23 16 -16 9 5 22 6 29 4
6 -3 12 1 12 7 0 10 4 10 15 0 10 -9 15 -9 15 -1 0 6 -4 11 -9 11 -5 0 -16 6
-23 13 -7 6 -23 11 -34 9z m23 -18 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z"
          />
          <path
            d="M1794 725 c24 -18 49 -20 44 -2 -3 6 -18 13 -34 15 -28 2 -28 2 -10
-13z"
          />
          <path d="M150 720 c-8 -5 -12 -12 -8 -15 9 -9 48 4 48 16 0 12 -21 11 -40 -1z" />
          <path
            d="M545 707 c-4 -10 -14 -16 -21 -13 -7 3 -16 1 -19 -5 -4 -5 1 -12 10
-15 20 -8 49 19 42 38 -3 9 -7 8 -12 -5z"
          />
          <path d="M445 701 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
          <path d="M1495 701 c-3 -5 -1 -12 5 -16 12 -7 30 2 30 16 0 12 -27 12 -35 0z" />
          <path
            d="M1784 685 c24 -18 49 -20 44 -2 -3 6 -18 13 -34 15 -28 2 -28 2 -10
-13z"
          />
          <path d="M155 679 c-4 -6 -5 -13 -2 -16 8 -7 47 7 47 18 0 13 -37 11 -45 -2z" />
          <path
            d="M474 659 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
          />
          <path
            d="M1470 645 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
          />
          <path
            d="M1774 645 c25 -19 36 -19 36 0 0 10 -10 15 -27 15 -27 -1 -27 -1 -9
-15z"
          />
          <path
            d="M170 640 c-12 -8 -12 -10 4 -16 11 -4 24 0 34 10 15 15 15 16 -3 16
-11 0 -27 -4 -35 -10z"
          />
          <path d="M1745 620 c-7 -12 32 -33 45 -25 6 4 8 10 5 14 -7 13 -44 20 -50 11z" />
          <path d="M512 606 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10 -12z" />
          <path
            d="M1434 609 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
          />
          <path
            d="M187 603 c-24 -23 9 -31 39 -8 18 14 18 14 -7 15 -14 0 -29 -3 -32
-7z"
          />
          <path
            d="M400 585 c-11 -13 -11 -25 -3 -56 7 -27 7 -41 0 -46 -15 -9 -46 34
-40 57 7 27 -14 26 -22 -1 -9 -26 23 -73 52 -77 33 -5 49 23 34 64 -14 39 -7
61 15 43 8 -6 14 -22 14 -35 0 -28 15 -32 24 -7 8 19 -23 66 -46 71 -8 2 -21
-4 -28 -13z"
          />
          <path
            d="M1530 587 c-36 -18 -48 -59 -26 -92 34 -52 101 -52 125 2 14 31 -6
74 -44 91 -31 14 -25 14 -55 -1z m64 -33 c20 -20 21 -63 1 -70 -24 -10 -63 4
-75 26 -24 45 37 82 74 44z"
          />
          <path
            d="M1726 582 c-7 -12 42 -38 51 -28 5 4 -1 14 -11 22 -23 16 -33 18 -40
6z"
          />
          <path
            d="M223 569 c-24 -9 -31 -29 -10 -29 17 1 50 27 40 33 -4 2 -18 1 -30
-4z"
          />
          <path
            d="M544 569 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"
          />
          <path d="M1404 566 c-8 -22 3 -33 18 -18 9 9 9 15 0 24 -9 9 -13 7 -18 -6z" />
          <path d="M590 560 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
          <path
            d="M1719 529 c17 -14 27 -17 35 -9 13 13 -9 30 -39 30 -19 -1 -18 -2 4
-21z"
          />
          <path d="M231 531 c-7 -5 -10 -14 -7 -20 8 -13 46 3 46 19 0 12 -20 13 -39 1z" />
          <path
            d="M495 500 c18 -20 18 -21 -23 -63 -55 -56 -41 -70 14 -16 37 36 45 40
59 29 31 -26 28 1 -5 35 -37 38 -78 52 -45 15z"
          />
          <path d="M1686 513 c-7 -7 23 -43 36 -43 4 0 8 7 8 15 0 16 -34 38 -44 28z" />
          <path
            d="M267 499 c-17 -10 -24 -39 -9 -39 14 0 44 38 35 43 -5 3 -16 1 -26
-4z"
          />
          <path d="M625 491 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15 -9z" />
          <path
            d="M1440 492 c0 -14 99 -106 107 -99 4 4 -15 30 -42 57 -47 48 -65 59
-65 42z"
          />
          <path
            d="M860 469 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
          />
          <path
            d="M1262 463 c4 -22 38 -30 38 -10 0 8 -7 18 -16 21 -23 9 -26 8 -22
-11z"
          />
          <path
            d="M1675 460 c10 -11 22 -20 27 -20 15 0 9 28 -8 34 -30 11 -37 6 -19
-14z"
          />
          <path d="M280 455 c-7 -9 -10 -18 -7 -21 7 -8 47 14 47 26 0 15 -26 12 -40 -5z" />
          <path
            d="M1410 459 c0 -6 5 -19 11 -30 8 -16 6 -23 -13 -39 -23 -19 -23 -19
-37 0 -7 11 -20 20 -28 20 -9 0 0 -21 28 -60 28 -41 46 -57 52 -51 7 7 4 17
-8 31 -18 20 -18 21 3 42 l22 22 25 -24 c42 -39 39 -15 -4 44 -42 57 -51 65
-51 45z"
          />
          <path
            d="M1642 428 c8 -12 20 -24 26 -26 20 -6 13 27 -7 38 -30 15 -35 12 -19
-12z"
          />
          <path
            d="M310 425 c-21 -25 -1 -31 23 -7 17 18 18 22 5 22 -8 0 -21 -7 -28
-15z"
          />
          <path
            d="M1165 418 c2 -24 19 -35 30 -18 7 11 -11 40 -25 40 -3 0 -6 -10 -5
-22z"
          />
          <path
            d="M555 398 c-22 -16 -47 -33 -55 -38 -8 -5 -11 -12 -7 -16 4 -5 16 -3
25 3 25 15 58 -10 54 -41 -2 -13 1 -27 6 -30 12 -7 47 128 37 143 -9 15 -15
13 -60 -21z m37 -23 c-5 -27 -16 -32 -28 -13 -4 6 1 17 11 25 24 17 23 17 17
-12z"
          />
          <path
            d="M1612 408 c6 -17 36 -34 45 -24 4 4 -1 14 -11 22 -24 17 -39 18 -34
2z"
          />
          <path
            d="M337 392 c-10 -10 -14 -22 -10 -25 7 -8 43 21 43 35 0 14 -16 9 -33
-10z"
          />
          <path
            d="M650 389 c0 -6 8 -17 17 -24 15 -11 14 -16 -11 -60 -20 -32 -25 -50
-18 -57 8 -8 19 6 38 45 25 50 30 55 48 45 12 -6 22 -12 23 -13 2 -1 -4 -25
-13 -54 -8 -29 -13 -55 -11 -58 3 -2 26 -11 51 -18 38 -12 46 -12 46 -1 0 8
-16 17 -35 20 -26 5 -35 12 -35 27 0 19 4 20 40 14 50 -8 55 11 5 21 -37 7
-40 11 -27 32 7 10 15 10 36 2 34 -13 46 -13 46 0 0 5 -19 14 -42 21 -24 6
-69 25 -100 41 -36 18 -58 24 -58 17z"
          />
          <path
            d="M1580 382 c0 -14 39 -46 47 -39 3 4 -2 16 -12 27 -19 21 -35 26 -35
12z"
          />
          <path
            d="M365 367 c-10 -8 -15 -19 -11 -26 6 -9 13 -8 27 5 33 30 20 47 -16
21z"
          />
          <path
            d="M1252 364 c-38 -26 -26 -100 19 -125 27 -14 73 4 87 35 18 41 -24
106 -70 106 -8 0 -24 -7 -36 -16z m74 -28 c21 -32 15 -67 -14 -77 -46 -17 -87
66 -44 90 27 16 40 13 58 -13z"
          />
          <path
            d="M1550 352 c0 -17 38 -44 45 -32 4 6 -1 18 -11 25 -22 16 -34 19 -34
7z"
          />
          <path
            d="M397 332 c-10 -10 -14 -22 -10 -25 7 -8 43 21 43 35 0 14 -16 9 -33
-10z"
          />
          <path d="M1520 323 c1 -18 30 -44 40 -38 8 5 6 13 -6 26 -17 19 -34 25 -34 12z" />
          <path d="M430 305 c-7 -9 -10 -18 -7 -22 8 -7 37 15 37 28 0 14 -16 11 -30 -6z" />
          <path
            d="M1072 309 c-13 -5 -21 -12 -19 -16 3 -5 10 -34 17 -65 6 -32 16 -58
22 -58 6 0 8 13 4 35 -7 34 -6 35 24 35 19 0 30 5 30 14 0 11 -9 13 -35 9 -27
-4 -35 -2 -35 10 0 16 20 27 51 27 10 0 19 5 19 10 0 12 -47 12 -78 -1z"
          />
          <path
            d="M938 293 c-37 -42 -28 -101 18 -122 70 -32 125 67 68 123 -21 21 -67
20 -86 -1z m71 -17 c7 -8 11 -30 9 -48 -3 -28 -7 -33 -32 -36 -32 -4 -46 10
-46 49 0 44 43 66 69 35z"
          />
          <path
            d="M1490 298 c0 -18 27 -44 37 -34 4 4 -3 16 -15 29 -18 17 -22 18 -22
5z"
          />
          <path d="M460 279 c-7 -11 -9 -24 -5 -27 7 -7 35 25 35 40 0 16 -19 8 -30 -13z" />
          <path
            d="M503 267 c-19 -24 -23 -36 -14 -41 5 -3 16 5 25 19 16 24 6 45 -11
22z"
          />
          <path
            d="M1460 273 c0 -5 6 -18 14 -29 17 -22 35 -11 20 13 -12 19 -34 29 -34
16z"
          />
          <path
            d="M1420 252 c0 -5 6 -17 14 -28 19 -25 31 -12 14 15 -13 21 -28 28 -28
13z"
          />
          <path d="M532 238 c-14 -14 -16 -38 -2 -38 9 0 30 31 30 44 0 10 -15 7 -28 -6z" />
          <path
            d="M1390 232 c0 -5 6 -17 14 -28 19 -25 31 -12 14 15 -13 21 -28 28 -28
13z"
          />
          <path
            d="M563 210 c-9 -13 -9 -21 -2 -26 12 -7 29 13 29 34 0 18 -14 15 -27
-8z"
          />
          <path
            d="M602 200 c-17 -28 -4 -50 15 -25 14 21 17 45 5 45 -4 0 -13 -9 -20
-20z"
          />
          <path
            d="M1350 212 c0 -15 28 -47 35 -40 8 8 -13 48 -25 48 -6 0 -10 -4 -10
-8z"
          />
          <path
            d="M639 179 c-7 -15 -8 -24 0 -32 7 -7 13 -2 21 17 12 34 12 36 0 36 -5
0 -14 -9 -21 -21z"
          />
          <path d="M1320 188 c0 -21 17 -41 29 -34 7 5 7 13 -2 26 -13 23 -27 26 -27 8z" />
          <path d="M1285 163 c8 -34 11 -37 24 -29 7 5 7 15 -1 31 -15 34 -32 32 -23 -2z" />
          <path d="M675 159 c-4 -11 -4 -23 0 -27 7 -7 25 17 25 36 0 20 -18 14 -25 -9z" />
          <path d="M715 150 c-8 -26 4 -48 16 -29 12 19 11 49 0 49 -5 0 -12 -9 -16 -20z" />
          <path d="M1240 158 c0 -19 18 -43 25 -36 9 9 -4 48 -16 48 -5 0 -9 -6 -9 -12z" />
          <path
            d="M754 135 c-6 -24 0 -44 10 -34 11 11 17 59 7 59 -6 0 -13 -11 -17
-25z"
          />
          <path d="M1200 148 c0 -19 18 -43 25 -36 9 9 -4 48 -16 48 -5 0 -9 -6 -9 -12z" />
          <path
            d="M796 124 c-8 -29 -3 -39 13 -29 13 8 15 55 2 55 -5 0 -12 -12 -15
-26z"
          />
          <path d="M1170 126 c0 -16 6 -26 14 -26 9 0 12 7 9 20 -8 31 -23 34 -23 6z" />
          <path d="M835 119 c-8 -23 -1 -34 14 -24 13 8 15 45 2 45 -5 0 -13 -9 -16 -21z" />
          <path d="M876 125 c-9 -24 -7 -45 3 -45 5 0 13 9 16 21 8 26 -9 48 -19 24z" />
          <path
            d="M1090 111 c0 -17 5 -31 10 -31 6 0 10 11 10 24 0 14 -4 28 -10 31 -6
4 -10 -7 -10 -24z"
          />
          <path
            d="M1122 119 c1 -12 9 -24 16 -27 9 -3 13 3 10 19 -1 12 -9 24 -16 27
-9 3 -13 -3 -10 -19z"
          />
          <path
            d="M920 121 c0 -5 -3 -16 -6 -25 -4 -11 -1 -16 10 -16 10 0 16 9 16 25
0 14 -4 25 -10 25 -5 0 -10 -4 -10 -9z"
          />
          <path
            d="M960 100 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"
          />
          <path
            d="M1010 100 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30 -5
0 -10 -13 -10 -30z"
          />
          <path
            d="M1042 109 c1 -12 9 -24 16 -27 9 -3 13 3 10 19 -1 12 -9 24 -16 27
-9 3 -13 -3 -10 -19z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default OhioStamp;
