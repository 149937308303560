/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardDTO
 */
export interface CardDTO {
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    customerId: string;
    /**
     * 
     * @type {number}
     * @memberof CardDTO
     */
    cardExpYear: number;
    /**
     * 
     * @type {number}
     * @memberof CardDTO
     */
    cardExpMonth: number;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    cardNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    cardCvc: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof CardDTO
     */
    paymentMethodId?: string;
}

/**
 * Check if a given object implements the CardDTO interface.
 */
export function instanceOfCardDTO(value: object): value is CardDTO {
    if (!('customerId' in value) || value['customerId'] === undefined) return false;
    if (!('cardExpYear' in value) || value['cardExpYear'] === undefined) return false;
    if (!('cardExpMonth' in value) || value['cardExpMonth'] === undefined) return false;
    if (!('cardNumber' in value) || value['cardNumber'] === undefined) return false;
    if (!('cardCvc' in value) || value['cardCvc'] === undefined) return false;
    return true;
}

export function CardDTOFromJSON(json: any): CardDTO {
    return CardDTOFromJSONTyped(json, false);
}

export function CardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'cardExpYear': json['cardExpYear'],
        'cardExpMonth': json['cardExpMonth'],
        'cardNumber': json['cardNumber'],
        'cardCvc': json['cardCvc'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'paymentMethodId': json['paymentMethodId'] == null ? undefined : json['paymentMethodId'],
    };
}

export function CardDTOToJSON(value?: CardDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'customerId': value['customerId'],
        'cardExpYear': value['cardExpYear'],
        'cardExpMonth': value['cardExpMonth'],
        'cardNumber': value['cardNumber'],
        'cardCvc': value['cardCvc'],
        'brand': value['brand'],
        'paymentMethodId': value['paymentMethodId'],
    };
}

