import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useNotification = () => {
  const notification = (type: string, message: string) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "fail":
        toast.error(message);
        break;
      case "warn":
        toast.warn(message);
        break;
      default:
        toast("");
    }
  };
  return { notification };
};

export default useNotification;
