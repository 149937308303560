import { Socket } from "socket.io-client";
import { PDFPageProxy } from "pdfjs-dist";
import { useRef, useState } from "react";
import { TbZoomReset } from "react-icons/tb";
import { GrZoomIn, GrZoomOut } from "react-icons/gr";
import PdfPageData from "./PdfPageData";
import { useResponsive } from "../../../store/store";
import { useAuditLogsStore } from "../../../store/auditLogs";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { INotaryUrlData } from "../../../store/zustandType";

type Props = {
  page: PDFPageProxy;
  index: number;
  setSelectedPage: () => void;
  pageNumber: number;
  sessionId: string;
  socket: Socket;
  from: string;
  notaryUrlData: INotaryUrlData;
};

const PdfPage = ({
  page,
  index,
  setSelectedPage,
  pageNumber,
  from,
  sessionId,
  socket,
  notaryUrlData,
}: Props) => {
  const { isMobileView } = useResponsive();
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
  const auditLogs = useAuditLogsStore((state) => state.auditLogs?.[index]);
  const [pagePosition, setPagePosition] = useState<{
    x: number;
    y: number;
    scale: number;
  }>({
    x: 0,
    y: 0,
    scale: 1,
  });

  if (!auditLogs) return <p>Loading...</p>;

  return (
    <>
      {isMobileView ? (
        <PdfPageData
          sessionId={sessionId}
          socket={socket}
          page={page}
          index={pageNumber}
          setSelectedPage={setSelectedPage}
          pageNumber={pageNumber}
          from={from}
          pagePosition={pagePosition}
          notaryUrlData={notaryUrlData}
        />
      ) : (
        <TransformWrapper
          doubleClick={{
            disabled: true,
          }}
          initialScale={1}
          minScale={1}
          maxScale={7}
          panning={{ activationKeys: ["Shift"] }}
          onTransformed={(ref, state) => {
            setPagePosition((p) => ({
              ...p,
              x: state.positionX,
              y: state.positionY,
              scale: state.scale,
            }));
          }}
          initialPositionX={0}
          initialPositionY={0}
          ref={transformComponentRef}
          wheel={{
            disabled: true,
          }}
          disablePadding
          alignmentAnimation={{
            disabled: true,
            animationTime: 0,
            velocityAlignmentTime: 0,
            animationType: "linear",
          }}
          velocityAnimation={{
            disabled: true,
            sensitivity: 0,
            animationTime: 0,
          }}
        >
          {(utils) => (
            <>
              <Control {...utils} />
              <TransformComponent>
                <PdfPageData
                  sessionId={sessionId}
                  socket={socket}
                  page={page}
                  index={pageNumber}
                  setSelectedPage={setSelectedPage}
                  pageNumber={pageNumber}
                  from={from}
                  pagePosition={pagePosition}
                  notaryUrlData={notaryUrlData}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </>
  );
};

export default PdfPage;

const Control = ({ zoomIn, zoomOut, resetTransform }: ReactZoomPanPinchRef) => {
  return (
    <>
      <button
        style={{ padding: "6px" }}
        onClick={() => {
          zoomIn();
        }}
      >
        <GrZoomIn />
      </button>
      <button
        style={{ padding: "6px" }}
        onClick={() => {
          zoomOut();
        }}
      >
        <GrZoomOut />
      </button>
      <button
        style={{ padding: "6px" }}
        onClick={() => {
          resetTransform();
        }}
      >
        <TbZoomReset />
      </button>
    </>
  );
};
