import { useNavigate } from "react-router-dom";
import { Button, Center, Stack, Text } from "@chakra-ui/react";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";
import { notaryPaths } from "../../../routes/routes";

const OnDemandNoticeScreen = ({ from }: { from?: string }) => {
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <NoDataFound
        text={
          from === "inHouse"
            ? "Sorry, you are not applicaple to take onDemand sessions."
            : "For your state, this feature is not availbale."
        }
        from="session"
      />
      <Text textAlign="center" fontWeight={500}>
        But you can still BYOT.
      </Text>

      <Center>
        <Button
          width={"fit-content"}
          style={{ backgroundColor: "#D5AD36", color: "#fff" }}
          onClick={() =>
            navigate(`${notaryPaths.createSession}/primary-signer`)
          }
        >
          Click here
        </Button>
      </Center>
    </Stack>
  );
};

export default OnDemandNoticeScreen;
