/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';

/**
 * 
 * @export
 * @interface MeetingMediator
 */
export interface MeetingMediator {
    /**
     * 
     * @type {number}
     * @memberof MeetingMediator
     */
    mediatorId: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingMediator
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingMediator
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingMediator
     */
    lastName: string;
    /**
     * 
     * @type {Session}
     * @memberof MeetingMediator
     */
    session?: Session;
}

/**
 * Check if a given object implements the MeetingMediator interface.
 */
export function instanceOfMeetingMediator(value: object): value is MeetingMediator {
    if (!('mediatorId' in value) || value['mediatorId'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    return true;
}

export function MeetingMediatorFromJSON(json: any): MeetingMediator {
    return MeetingMediatorFromJSONTyped(json, false);
}

export function MeetingMediatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingMediator {
    if (json == null) {
        return json;
    }
    return {
        
        'mediatorId': json['mediatorId'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'session': json['session'] == null ? undefined : SessionFromJSON(json['session']),
    };
}

export function MeetingMediatorToJSON(value?: MeetingMediator | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mediatorId': value['mediatorId'],
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'session': SessionToJSON(value['session']),
    };
}

