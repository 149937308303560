import { useState } from "react";
import { Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { useLocation, Link } from "react-router-dom";
import { IRoute } from "../../../routes/routes";

const SidebarMenu = ({ userRoutes }: { userRoutes: IRoute[] }) => {
  const { pathname } = useLocation();
  const { collapsed, toggleSidebar } = useProSidebar();
  const [open, setOpen] = useState<boolean>(false);
  const [collapsedOpen, setCollapsedOpen] = useState<boolean>(false);
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);

  return (
    <Menu style={{ margin: "0px 10px" }}>
      {userRoutes.map((route, idx) =>
        route.children?.length ? (
          <SubMenu
            key={idx}
            icon={route.icon}
            label={route.title}
            style={
              pathname.includes(route.route) && collapsed
                ? {
                    backgroundColor: "#000080",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: "12px",
                  }
                : { padding: "12px", borderRadius: "10px" }
            }
            open={selectedIdx === idx}
            onOpenChange={(open) => {
              if (open) {
                setSelectedIdx(idx);
              } else {
                setSelectedIdx(-1);
              }
              setOpen(open);
              setCollapsedOpen(true);
            }}
            rootStyles={{
              backgroundColor: selectedIdx === idx && open ? "#fff" : "E4F1FF",
            }}
          >
            {collapsed ? (
              <>
                {collapsedOpen &&
                  route.children.map((child, index) => (
                    <MenuItem
                      key={index}
                      style={
                        pathname.includes(child.route)
                          ? {
                              backgroundColor: "#000080",
                              color: "#fff",
                              borderRadius: "10px",
                            }
                          : { borderRadius: "10px" }
                      }
                      component={
                        <Link
                          to={
                            child.children
                              ? child?.children[0].route
                              : child?.route
                          }
                        />
                      }
                      icon={child.icon}
                      onClick={() => {
                        setCollapsedOpen(false);
                        toggleSidebar();
                      }}
                    >
                      {child.title}
                    </MenuItem>
                  ))}
              </>
            ) : (
              <>
                {route.children.map((child, index) => (
                  <MenuItem
                    key={index}
                    style={
                      pathname.includes(child.route)
                        ? {
                            backgroundColor: "#000080",
                            color: "#fff",
                            borderRadius: "10px",
                          }
                        : { borderRadius: "10px" }
                    }
                    component={
                      <Link
                        to={
                          child.children
                            ? child?.children[0].route
                            : child?.route
                        }
                      />
                    }
                    icon={child.icon}
                    onClick={() => {
                      setCollapsedOpen(false);
                      toggleSidebar();
                    }}
                  >
                    {child.title}
                  </MenuItem>
                ))}
              </>
            )}
          </SubMenu>
        ) : (
          <MenuItem
            key={idx}
            component={<Link to={route?.route} />}
            icon={route.icon}
            onClick={() => {
              setOpen(false);
              setSelectedIdx(-1);
              toggleSidebar();
            }}
            style={
              pathname === route.route
                ? {
                    backgroundColor: "#000080",
                    color: "#fff",
                    borderRadius: "10px",
                    padding: "12px",
                  }
                : { padding: "12px", borderRadius: "10px" }
            }
          >
            {route.title}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default SidebarMenu;