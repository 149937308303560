export const NoOfWitnessOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
];

export const LimitOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const SessionStatusOptions = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "completed", label: "Completed" },
  { value: "booked", label: "Booked" },
  { value: "terminated", label: "Terminated" },
];

export const InvoiceStatusOptions = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "paid", label: "Paid" },
  { value: "draft", label: "Draft" },
  { value: "uncollectible", label: "Uncollectible" },
];

export const FeedbackTypeOptions = [
  { value: "bug", label: "Bug" },
  { value: "feature", label: "Feature" },
  { value: "other", label: "Other" },
];

export const StateOptions = [
  {
    value: "AL",
    label: "AL",
    name: "Alabama",
  },
  {
    value: "AK",
    label: "AK",
    name: "Alaska",
  },
  {
    value: "AZ",
    label: "AZ",
    name: "Arizona",
  },
  {
    value: "AR",
    label: "AR",
    name: "Arkansas",
  },
  {
    value: "CA",
    label: "CA",
    name: "California",
  },
  {
    value: "CO",
    label: "CO",
    name: "Colorado",
  },
  {
    value: "CT",
    label: "CT",
    name: "Connecticut",
  },
  {
    value: "DE",
    label: "DE",
    name: "Delaware",
  },
  {
    value: "DC",
    label: "DC",
    name: "District Of Columbia",
  },
  {
    value: "FM",
    label: "FM",
    name: "Federated States Of Micronesia",
  },
  {
    value: "FL",
    label: "FL",
    name: "Florida",
  },
  {
    value: "GA",
    label: "GA",
    name: "Georgia",
  },

  {
    value: "HI",
    label: "HI",
    name: "Hawaii",
  },
  {
    value: "ID",
    label: "ID",
    name: "Idaho",
  },
  {
    value: "IL",
    label: "IL",
    name: "Illinois",
  },
  {
    value: "IN",
    label: "IN",
    name: "Indiana",
  },
  {
    value: "IA",
    label: "IA",
    name: "Iowa",
  },
  {
    value: "KS",
    label: "KS",
    name: "Kansas",
  },
  {
    value: "KY",
    label: "KY",
    name: "Kentucky",
  },
  {
    value: "LA",
    label: "LA",
    name: "Louisiana",
  },
  {
    value: "ME",
    label: "ME",
    name: "Maine",
  },
  {
    value: "MH",
    label: "MH",
    name: "Marshall Islands",
  },
  {
    value: "MD",
    label: "MD",
    name: "Maryland",
  },
  {
    value: "MA",
    label: "MA",
    name: "Massachusetts",
  },
  {
    value: "MI",
    label: "MI",
    name: "Michigan",
  },
  {
    value: "MN",
    label: "MN",
    name: "Minnesota",
  },
  {
    value: "MS",
    label: "MS",
    name: "Mississippi",
  },
  {
    value: "MO",
    label: "MO",
    name: "Missouri",
  },
  {
    value: "MT",
    label: "MT",
    name: "Montana",
  },
  {
    value: "NE",
    label: "NE",
    name: "Nebraska",
  },
  {
    value: "NV",
    label: "NV",
    name: "Nevada",
  },
  {
    value: "NH",
    label: "NH",
    name: "New Hampshire",
  },
  {
    value: "NJ",
    label: "NJ",
    name: "New Jersey",
  },
  {
    value: "NM",
    label: "NM",
    name: "New Mexico",
  },
  {
    value: "NY",
    label: "NY",
    name: "New York",
  },
  {
    value: "NC",
    label: "NC",
    name: "North Carolina",
  },
  {
    value: "ND",
    label: "ND",
    name: "North Dakota",
  },
  {
    value: "OH",
    label: "OH",
    name: "Ohio",
  },
  {
    value: "OK",
    label: "OK",
    name: "Oklahoma",
  },
  {
    value: "OR",
    label: "OR",
    name: "Oregon",
  },
  {
    value: "PW",
    label: "PW",
    name: "Palau",
  },
  {
    value: "PA",
    label: "PA",
    name: "Pennsylvania",
  },
  {
    value: "RI",
    label: "RI",
    name: "Rhode Island",
  },
  {
    value: "SC",
    label: "SC",
    name: "South Carolina",
  },
  {
    value: "SD",
    label: "SD",
    name: "South Dakota",
  },
  {
    value: "TN",
    label: "TN",
    name: "Tennessee",
  },
  {
    value: "TX",
    label: "TX",
    name: "Texas",
  },
  {
    value: "UT",
    label: "UT",
    name: "Utah",
  },
  {
    value: "VT",
    label: "VT",
    name: "Vermont",
  },
  {
    value: "VA",
    label: "VA",
    name: "Virginia",
  },
  {
    value: "WA",
    label: "WA",
    name: "Washington",
  },
  {
    value: "WV",
    label: "WV",
    name: "West Virginia",
  },
  {
    value: "WI",
    label: "WI",
    name: "Wisconsin",
  },
  {
    value: "WY",
    label: "WY",
    name: "Wyoming",
  },
  // {
  //   value: "PR",
  //   label: "PR",
  // },
  // {
  //   value: "VI",
  //   label: "VI",
  // },
  // {
  //   value: "MP",
  //   label: "MP",
  // },
  // {
  //   value: "GU",
  //   label: "GU",
  // },
  // {
  //   value: "AS",
  //   label: "AS",
  // },
];

export const StateFullnameOptions = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
  // {
  //   label: "American Samoa",
  //   value: "AS",
  // },
  // {
  //   label: "Virgin Islands",
  //   value: "VI",
  // },
  // {
  //   label: "Puerto Rico",
  //   value: "PR",
  // },
  // {
  //   label: "Northern Mariana Islands",
  //   value: "MP",
  // },
];
