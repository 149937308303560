/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { GrDocumentPdf as PdfIcon } from "react-icons/gr";
import { BsFillImageFill as ImageIcon } from "react-icons/bs";
import { HiOutlineDocumentText as DocIcon } from "react-icons/hi2";
import PreviewModal from "../PreviewModal";
import { FileUploadApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { IFileInfo } from "../../../store/zustandType";
import useNotification from "../../../utils/useNotification";
import UploadIcon1 from "../../../assets/icons/uploadIcon1.png";

const UploadDoc = ({
  acceptFileType,
  filePath,
  onUpload,
  url,
}: {
  acceptFileType: string[];
  filePath: string;
  url?: string;
  onUpload: (file: string, name?: string) => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileName, setFileName] = useState<string | undefined>("");
  const [fileuploading, setFileUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles: IFileInfo[]) => {
    setFileUploading(true);
    acceptedFiles.forEach((file: IFileInfo) => {
      if (
        acceptFileType.map((item) => file?.name?.endsWith(item)).includes(true)
      ) {
        setFileName(file.name);
        new FileUploadApi(configuration)
          .fileControllerUploadS3File({
            file: file,
          })
          .then((res) => {
            setFileUploading(false);
            onUpload(res.path, file.name);
            notification("success", "Uploaded Successfully");
          })
          .catch((err) => {
            console.log(err);
            setFileUploading(false);
            notification("fail", "Failed to Upload File");
          });
      } else {
        setFileUploading(false);
        notification("fail", "Unsupported file Type ");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // multiple: true,
    maxFiles: 1,
  });

  return (
    <div>
      {url !== "" && url !== undefined ? (
        <Flex
          align="center"
          justify="center"
          bg={useColorModeValue("none", "navy.900")}
          border={useColorModeValue("1px dashed #000", "none")}
          borderRadius={8}
          w="60%"
          minH="130px"
          cursor="pointer"
          minW={"270px"}
          wordBreak={"break-word"}
          overflowWrap="break-word"
          position="relative"
          overflow="hidden"
        >
          <Flex position="absolute" top={2} right={2} justify={"space-between"}>
            <div {...getRootProps({ className: "dropzone" })}>
              {/* @ts-ignore */}
              <Input type="image" variant="main" {...getInputProps()} />
              <Button
                size="sm"
                style={{ backgroundColor: "#D5AD36", color: "#fff" }}
              >
                Upload
              </Button>
            </div>
          </Flex>
          <Image
            src={url}
            w="80px"
            minH="130px"
            cursor="pointer"
            minW={"270px"}
            h="70px"
          />
        </Flex>
      ) : (
        <Flex
          align="center"
          justify="center"
          bg={useColorModeValue("none", "navy.900")}
          border={useColorModeValue("1px dashed #000", "none")}
          borderRadius={8}
          w="60%"
          minH="130px"
          cursor="pointer"
          minW={"270px"}
          wordBreak={"break-word"}
          overflowWrap="break-word"
          {...getRootProps({ className: "dropzone" })}
        >
          {/* @ts-ignore */}
          <Input type="image" variant="main" {...getInputProps()} />
          <Button
            variant="no-effects"
            display={"flex"}
            flexDirection="column"
            alignItems="center"
            width={40}
          >
            {fileuploading ? (
              <Box width={"100%"}>
                <Text mb={2}>Uploading....</Text>
                <Progress size="xs" isIndeterminate />
              </Box>
            ) : filePath !== "" ? (
              <>
                {acceptFileType.includes(".pdf") ? (
                  <PdfIcon size={20} />
                ) : acceptFileType.includes(".jpeg") ||
                  acceptFileType.includes(".jpg") ||
                  acceptFileType.includes(".png") ? (
                  <ImageIcon size={20} />
                ) : (
                  <DocIcon size={22} />
                )}
                {fileName ? (
                  <Text
                    color="gray.400"
                    fontWeight="normal"
                    fontSize={13}
                    mt={2}
                  >
                    {fileName}
                  </Text>
                ) : (
                  <Text
                    color="green.400"
                    fontWeight="normal"
                    fontSize={13}
                    mt={2}
                  >
                    Uploaded Successfully!
                  </Text>
                )}
              </>
            ) : (
              <>
                <Image src={UploadIcon1} height={10} />
                <Text color="gray.400" fontWeight="normal">
                  Upload File
                </Text>
              </>
            )}
          </Button>
        </Flex>
      )}

      <PreviewModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        url={url}
        type={acceptFileType}
      />
    </div>
  );
};

export default UploadDoc;
