/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DocDTO,
  DocResponseDTO,
  Document,
  DocumentDTO,
  DocumentROI,
  DocumentROIDTO,
  DocumentROIType,
} from '../models/index';
import {
    DocDTOFromJSON,
    DocDTOToJSON,
    DocResponseDTOFromJSON,
    DocResponseDTOToJSON,
    DocumentFromJSON,
    DocumentToJSON,
    DocumentDTOFromJSON,
    DocumentDTOToJSON,
    DocumentROIFromJSON,
    DocumentROIToJSON,
    DocumentROIDTOFromJSON,
    DocumentROIDTOToJSON,
    DocumentROITypeFromJSON,
    DocumentROITypeToJSON,
} from '../models/index';

export interface DocumentControllerCreateRequest {
    documentDTO: DocumentDTO;
}

export interface DocumentControllerCreateRoiRequest {
    docId: number;
    documentROIDTO: DocumentROIDTO;
}

export interface DocumentControllerCreateRoiTypeRequest {
    documentROIType: DocumentROIType;
}

export interface DocumentControllerCryptoDocumentRequest {
    docDTO: DocDTO;
}

export interface DocumentControllerDeleteRoiRequest {
    id: number;
}

export interface DocumentControllerFindOneRequest {
    id: string;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     */
    async documentControllerCreateRaw(requestParameters: DocumentControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters['documentDTO'] == null) {
            throw new runtime.RequiredError(
                'documentDTO',
                'Required parameter "documentDTO" was null or undefined when calling documentControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentDTOToJSON(requestParameters['documentDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerCreate(requestParameters: DocumentControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Document> {
        const response = await this.documentControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerCreateRoiRaw(requestParameters: DocumentControllerCreateRoiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocumentROI>> {
        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling documentControllerCreateRoi().'
            );
        }

        if (requestParameters['documentROIDTO'] == null) {
            throw new runtime.RequiredError(
                'documentROIDTO',
                'Required parameter "documentROIDTO" was null or undefined when calling documentControllerCreateRoi().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document/{docId}/roi`.replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentROIDTOToJSON(requestParameters['documentROIDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentROIFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerCreateRoi(requestParameters: DocumentControllerCreateRoiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocumentROI> {
        const response = await this.documentControllerCreateRoiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerCreateRoiTypeRaw(requestParameters: DocumentControllerCreateRoiTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters['documentROIType'] == null) {
            throw new runtime.RequiredError(
                'documentROIType',
                'Required parameter "documentROIType" was null or undefined when calling documentControllerCreateRoiType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document/roi-type`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentROITypeToJSON(requestParameters['documentROIType']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerCreateRoiType(requestParameters: DocumentControllerCreateRoiTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Document> {
        const response = await this.documentControllerCreateRoiTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerCryptoDocumentRaw(requestParameters: DocumentControllerCryptoDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DocResponseDTO>> {
        if (requestParameters['docDTO'] == null) {
            throw new runtime.RequiredError(
                'docDTO',
                'Required parameter "docDTO" was null or undefined when calling documentControllerCryptoDocument().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/document/digital-certificate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DocDTOToJSON(requestParameters['docDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerCryptoDocument(requestParameters: DocumentControllerCryptoDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DocResponseDTO> {
        const response = await this.documentControllerCryptoDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerDeleteRoiRaw(requestParameters: DocumentControllerDeleteRoiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling documentControllerDeleteRoi().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document/roi/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentControllerDeleteRoi(requestParameters: DocumentControllerDeleteRoiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.documentControllerDeleteRoiRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentControllerFindOneRaw(requestParameters: DocumentControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling documentControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerFindOne(requestParameters: DocumentControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Document> {
        const response = await this.documentControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Document>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     */
    async documentControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Document> {
        const response = await this.documentControllerGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async documentControllerUpdateBusinessRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document/updateFreeStamps/business`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentControllerUpdateBusiness(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.documentControllerUpdateBusinessRaw(initOverrides);
    }

    /**
     */
    async documentControllerUpdateNotaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/document/updateFreeStamps/notary`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentControllerUpdateNotary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.documentControllerUpdateNotaryRaw(initOverrides);
    }

}
