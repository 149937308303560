import {
  ControlBar,
  ParticipantTile,
  TrackLoop,
  TrackReference,
  VideoTrack,
} from "@livekit/components-react";
import { useEffect, useState } from "react";
import { Notary, NotaryApi, Signer, SignerApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../../utils/utils";
import NotaryParticipant from "./NotaryParticipant";
import { Box, Flex, Text } from "@chakra-ui/react";
import { COLORS } from "../../../../utils/constants";

function LocalPaticipants({ tracks }: { tracks: TrackReference[] }) {
  const { configuration } = useAuth();
  const [userData, setUserData] = useState<Notary | Signer | null>(null);
  const role = localStorage.getItem("role");

  let localUserId = localStorage.getItem("UserID");
  let localRole = localStorage.getItem("role");

  useEffect(() => {
    let userId = localUserId;
    let role = localRole;
    if (localUserId === null && localRole === null) {
      role = tracks[0].participant.identity.split(LIVEKIT_SPLIT_IDENTIFIER)[0];
      userId = tracks[0].participant.identity.split(
        LIVEKIT_SPLIT_IDENTIFIER
      )[1];
    } else {
      if (role === "notary") {
        new NotaryApi(configuration)
          .notaryControllerFindOne({ userId })
          .then((res) => {
            setUserData(res);
          });
      } else if (role === "signer") {
        new SignerApi(configuration)
          .signerControllerGetProfileUser({ userId: Number(userId) })
          .then((res) => {
            setUserData(res);
          });
      }
    }
  }, [localUserId, localRole]);

  return (
    <Box
      sx={{
        margin: "20px 0px",
        backgroundColor: role === "notary" ? COLORS.gold+"99" : COLORS.blue+"99",
        padding: "10px",
        width: "fit-content",
        borderRadius: "8px",
      }}
    >
      {role !==null && <Box><Text fontWeight={"semibold"} textTransform={"capitalize"}>{role}</Text></Box>}
      <TrackLoop tracks={tracks}>
        <div className="">
          <ParticipantTile style={{ height: "100%", width: "100%" }}>
            <VideoTrack
              style={{
                maxHeight: "300px",
                maxWidth: "300px",
                height: "100%",
                width: "100%",
                aspectRatio: "16 / 12",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </ParticipantTile>
        </div>
      </TrackLoop>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        {userData !== null ? (
          <p>{userData?.firstName + " " + userData?.lastName}</p>
        ) : null}
        <Box mt={"10px"}>
          <ControlBar
            style={{
              margin: "0px 20px",
              position: "static",
              height: "30px",
              width: "60px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            variation="minimal"
            controls={{
              microphone: true,
              camera: false,
              screenShare: false,
              leave: false,
              settings: false,
              chat: false,
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
}

export default LocalPaticipants;
