import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

const DocumentTypeModal = ({
  isOpen,
  onClose,
  onOpen,
  onSelection,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSelection: (type: "Jurat" | "Acknowlegement") => void;
}) => {
  const [value, setValue] = useState<"Jurat" | "Acknowlegement">("Jurat");
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Type of Document
          </Text>
        </ModalHeader>

        <Divider />
        <ModalBody mt={2}>
          <RadioGroup
            onChange={(type: "Jurat" | "Acknowlegement") => setValue(type)}
            value={value}
          >
            <Stack>
              <Radio value="Jurat">Jurat</Radio>
              <Radio value="Acknowlegement">Acknowlegement</Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter mb={3}>
          <Button
            style={{ backgroundColor: "#D5AD36", color: "#fff" }}
            onClick={() => {
              //handle close and the type of document
              onSelection(value);
              onClose()
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentTypeModal;
