import { useEffect } from "react";
import SignatureAndSealForm from "../../../component/Details/SignatureAndSealForm";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const SignatureAndSeal = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(5);
  }, [setStep]);

  return <SignatureAndSealForm />;
};

export default SignatureAndSeal;
