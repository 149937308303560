/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DefaultDTO,
  InviteBusinessDTO,
  InviteMediatorDTO,
  InviteSignerDTO,
  InviteWitnessDTO,
  PaginationResponse,
  Session,
  SessionDTO,
  SessionUpdateDTO,
  UpdateResponse,
  UpdatedDTO,
} from '../models/index';
import {
    DefaultDTOFromJSON,
    DefaultDTOToJSON,
    InviteBusinessDTOFromJSON,
    InviteBusinessDTOToJSON,
    InviteMediatorDTOFromJSON,
    InviteMediatorDTOToJSON,
    InviteSignerDTOFromJSON,
    InviteSignerDTOToJSON,
    InviteWitnessDTOFromJSON,
    InviteWitnessDTOToJSON,
    PaginationResponseFromJSON,
    PaginationResponseToJSON,
    SessionFromJSON,
    SessionToJSON,
    SessionDTOFromJSON,
    SessionDTOToJSON,
    SessionUpdateDTOFromJSON,
    SessionUpdateDTOToJSON,
    UpdateResponseFromJSON,
    UpdateResponseToJSON,
    UpdatedDTOFromJSON,
    UpdatedDTOToJSON,
} from '../models/index';

export interface SessionControllerBookWitnessRequest {
    id: string;
}

export interface SessionControllerCreateRequest {
    sessionDTO: SessionDTO;
}

export interface SessionControllerDeleteRequest {
    sessionId: number;
    docId: number;
}

export interface SessionControllerDetailsRequest {
    sessionId: number;
}

export interface SessionControllerGetAllPaginated2Request {
    page: number;
    limit: number;
    search: string;
    status: string;
}

export interface SessionControllerGetByIdRequest {
    sessionId: number;
}

export interface SessionControllerGetByNotaryIdRequest {
    page: number;
    limit: number;
    search: string;
    status: string;
}

export interface SessionControllerGetFilteredWitnessSessionsRequest {
    userId: number;
}

export interface SessionControllerGetJournalSessionsRequest {
    sessionId: number;
}

export interface SessionControllerInviteBusinessRequest {
    inviteBusinessDTO: InviteBusinessDTO;
}

export interface SessionControllerInviteMediatorRequest {
    inviteMediatorDTO: InviteMediatorDTO;
}

export interface SessionControllerInviteOwnWitnesssRequest {
    inviteWitnessDTO: InviteWitnessDTO;
}

export interface SessionControllerInviteSignerRequest {
    inviteSignerDTO: InviteSignerDTO;
}

export interface SessionControllerSendEmailsRequest {
    sessionId: number;
}

export interface SessionControllerUpdateRequest {
    sessionId: number;
    sessionUpdateDTO: SessionUpdateDTO;
}

export interface SessionControllerUpdateSessionRequest {
    sessionId: number;
    sessionUpdateDTO: SessionUpdateDTO;
}

/**
 * 
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     */
    async sessionControllerBookWitnessRaw(requestParameters: SessionControllerBookWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sessionControllerBookWitness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/book-witness/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerBookWitness(requestParameters: SessionControllerBookWitnessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.sessionControllerBookWitnessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerCreateRaw(requestParameters: SessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['sessionDTO'] == null) {
            throw new runtime.RequiredError(
                'sessionDTO',
                'Required parameter "sessionDTO" was null or undefined when calling sessionControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SessionDTOToJSON(requestParameters['sessionDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerCreate(requestParameters: SessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.sessionControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerDeleteRaw(requestParameters: SessionControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdatedDTO>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerDelete().'
            );
        }

        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling sessionControllerDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/deleteDocument/{sessionId}/{docId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))).replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdatedDTOFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerDelete(requestParameters: SessionControllerDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdatedDTO> {
        const response = await this.sessionControllerDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerDetailsRaw(requestParameters: SessionControllerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/InviteDetails/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sessionControllerDetails(requestParameters: SessionControllerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sessionControllerDetailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sessionControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetAllPaginated2Raw(requestParameters: SessionControllerGetAllPaginated2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling sessionControllerGetAllPaginated2().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling sessionControllerGetAllPaginated2().'
            );
        }

        if (requestParameters['search'] == null) {
            throw new runtime.RequiredError(
                'search',
                'Required parameter "search" was null or undefined when calling sessionControllerGetAllPaginated2().'
            );
        }

        if (requestParameters['status'] == null) {
            throw new runtime.RequiredError(
                'status',
                'Required parameter "status" was null or undefined when calling sessionControllerGetAllPaginated2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search '] = requestParameters['search'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/all-paginated2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerGetAllPaginated2(requestParameters: SessionControllerGetAllPaginated2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponse> {
        const response = await this.sessionControllerGetAllPaginated2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetAllSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/getSessions/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetAllSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetAllSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetBusinessSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/getBusiness/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetBusinessSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetBusinessSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetByIdRaw(requestParameters: SessionControllerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerGetById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerGetById(requestParameters: SessionControllerGetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.sessionControllerGetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetByNotaryIdRaw(requestParameters: SessionControllerGetByNotaryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling sessionControllerGetByNotaryId().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling sessionControllerGetByNotaryId().'
            );
        }

        if (requestParameters['search'] == null) {
            throw new runtime.RequiredError(
                'search',
                'Required parameter "search" was null or undefined when calling sessionControllerGetByNotaryId().'
            );
        }

        if (requestParameters['status'] == null) {
            throw new runtime.RequiredError(
                'status',
                'Required parameter "status" was null or undefined when calling sessionControllerGetByNotaryId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search '] = requestParameters['search'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/get-by-notary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerGetByNotaryId(requestParameters: SessionControllerGetByNotaryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponse> {
        const response = await this.sessionControllerGetByNotaryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetFilteredWitnessSessionsRaw(requestParameters: SessionControllerGetFilteredWitnessSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling sessionControllerGetFilteredWitnessSessions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/witnessFiltered/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetFilteredWitnessSessions(requestParameters: SessionControllerGetFilteredWitnessSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetFilteredWitnessSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetJournalSessionsRaw(requestParameters: SessionControllerGetJournalSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerGetJournalSessions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/allSessions/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerGetJournalSessions(requestParameters: SessionControllerGetJournalSessionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.sessionControllerGetJournalSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetNotarySessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/getNotary/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetNotarySessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetNotarySessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetSignerSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/getSigner/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetSignerSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetSignerSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerGetWitnessSessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Session>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/getWitness/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionFromJSON));
    }

    /**
     */
    async sessionControllerGetWitnessSessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Session>> {
        const response = await this.sessionControllerGetWitnessSessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerInviteBusinessRaw(requestParameters: SessionControllerInviteBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['inviteBusinessDTO'] == null) {
            throw new runtime.RequiredError(
                'inviteBusinessDTO',
                'Required parameter "inviteBusinessDTO" was null or undefined when calling sessionControllerInviteBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/invite-business`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteBusinessDTOToJSON(requestParameters['inviteBusinessDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sessionControllerInviteBusiness(requestParameters: SessionControllerInviteBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sessionControllerInviteBusinessRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sessionControllerInviteMediatorRaw(requestParameters: SessionControllerInviteMediatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['inviteMediatorDTO'] == null) {
            throw new runtime.RequiredError(
                'inviteMediatorDTO',
                'Required parameter "inviteMediatorDTO" was null or undefined when calling sessionControllerInviteMediator().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/invite-mediator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteMediatorDTOToJSON(requestParameters['inviteMediatorDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sessionControllerInviteMediator(requestParameters: SessionControllerInviteMediatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sessionControllerInviteMediatorRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sessionControllerInviteOwnWitnesssRaw(requestParameters: SessionControllerInviteOwnWitnesssRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['inviteWitnessDTO'] == null) {
            throw new runtime.RequiredError(
                'inviteWitnessDTO',
                'Required parameter "inviteWitnessDTO" was null or undefined when calling sessionControllerInviteOwnWitnesss().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/invite-own-witness`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteWitnessDTOToJSON(requestParameters['inviteWitnessDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sessionControllerInviteOwnWitnesss(requestParameters: SessionControllerInviteOwnWitnesssRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sessionControllerInviteOwnWitnesssRaw(requestParameters, initOverrides);
    }

    /**
     */
    async sessionControllerInviteSignerRaw(requestParameters: SessionControllerInviteSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        if (requestParameters['inviteSignerDTO'] == null) {
            throw new runtime.RequiredError(
                'inviteSignerDTO',
                'Required parameter "inviteSignerDTO" was null or undefined when calling sessionControllerInviteSigner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/invite-signer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteSignerDTOToJSON(requestParameters['inviteSignerDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerInviteSigner(requestParameters: SessionControllerInviteSignerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
        const response = await this.sessionControllerInviteSignerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerSendEmailsRaw(requestParameters: SessionControllerSendEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultDTO>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerSendEmails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/session/SendManullyEmails/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultDTOFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerSendEmails(requestParameters: SessionControllerSendEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultDTO> {
        const response = await this.sessionControllerSendEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerUpdateRaw(requestParameters: SessionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponse>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerUpdate().'
            );
        }

        if (requestParameters['sessionUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'sessionUpdateDTO',
                'Required parameter "sessionUpdateDTO" was null or undefined when calling sessionControllerUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/witness/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SessionUpdateDTOToJSON(requestParameters['sessionUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerUpdate(requestParameters: SessionControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponse> {
        const response = await this.sessionControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sessionControllerUpdateSessionRaw(requestParameters: SessionControllerUpdateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponse>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling sessionControllerUpdateSession().'
            );
        }

        if (requestParameters['sessionUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'sessionUpdateDTO',
                'Required parameter "sessionUpdateDTO" was null or undefined when calling sessionControllerUpdateSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SessionUpdateDTOToJSON(requestParameters['sessionUpdateDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async sessionControllerUpdateSession(requestParameters: SessionControllerUpdateSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponse> {
        const response = await this.sessionControllerUpdateSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
