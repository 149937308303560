/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdologyAnswersDTO
 */
export interface IdologyAnswersDTO {
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    idNumber: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question1Type: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question1Answer: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question2Type: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question2Answer: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question3Type: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question3Answer: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question4Type: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question4Answer: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question5Type: string;
    /**
     * 
     * @type {string}
     * @memberof IdologyAnswersDTO
     */
    question5Answer: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdologyAnswersDTO
     */
    isBiometric: boolean;
}

/**
 * Check if a given object implements the IdologyAnswersDTO interface.
 */
export function instanceOfIdologyAnswersDTO(value: object): value is IdologyAnswersDTO {
    if (!('idNumber' in value) || value['idNumber'] === undefined) return false;
    if (!('question1Type' in value) || value['question1Type'] === undefined) return false;
    if (!('question1Answer' in value) || value['question1Answer'] === undefined) return false;
    if (!('question2Type' in value) || value['question2Type'] === undefined) return false;
    if (!('question2Answer' in value) || value['question2Answer'] === undefined) return false;
    if (!('question3Type' in value) || value['question3Type'] === undefined) return false;
    if (!('question3Answer' in value) || value['question3Answer'] === undefined) return false;
    if (!('question4Type' in value) || value['question4Type'] === undefined) return false;
    if (!('question4Answer' in value) || value['question4Answer'] === undefined) return false;
    if (!('question5Type' in value) || value['question5Type'] === undefined) return false;
    if (!('question5Answer' in value) || value['question5Answer'] === undefined) return false;
    if (!('isBiometric' in value) || value['isBiometric'] === undefined) return false;
    return true;
}

export function IdologyAnswersDTOFromJSON(json: any): IdologyAnswersDTO {
    return IdologyAnswersDTOFromJSONTyped(json, false);
}

export function IdologyAnswersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdologyAnswersDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'idNumber': json['idNumber'],
        'question1Type': json['question1Type'],
        'question1Answer': json['question1Answer'],
        'question2Type': json['question2Type'],
        'question2Answer': json['question2Answer'],
        'question3Type': json['question3Type'],
        'question3Answer': json['question3Answer'],
        'question4Type': json['question4Type'],
        'question4Answer': json['question4Answer'],
        'question5Type': json['question5Type'],
        'question5Answer': json['question5Answer'],
        'isBiometric': json['isBiometric'],
    };
}

export function IdologyAnswersDTOToJSON(value?: IdologyAnswersDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idNumber': value['idNumber'],
        'question1Type': value['question1Type'],
        'question1Answer': value['question1Answer'],
        'question2Type': value['question2Type'],
        'question2Answer': value['question2Answer'],
        'question3Type': value['question3Type'],
        'question3Answer': value['question3Answer'],
        'question4Type': value['question4Type'],
        'question4Answer': value['question4Answer'],
        'question5Type': value['question5Type'],
        'question5Answer': value['question5Answer'],
        'isBiometric': value['isBiometric'],
    };
}

