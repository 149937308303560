/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePassswordDTO
 */
export interface ChangePassswordDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangePassswordDTO
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePassswordDTO
     */
    password: string;
}

/**
 * Check if a given object implements the ChangePassswordDTO interface.
 */
export function instanceOfChangePassswordDTO(value: object): value is ChangePassswordDTO {
    if (!('oldPassword' in value) || value['oldPassword'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function ChangePassswordDTOFromJSON(json: any): ChangePassswordDTO {
    return ChangePassswordDTOFromJSONTyped(json, false);
}

export function ChangePassswordDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePassswordDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'oldPassword': json['oldPassword'],
        'password': json['password'],
    };
}

export function ChangePassswordDTOToJSON(value?: ChangePassswordDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldPassword': value['oldPassword'],
        'password': value['password'],
    };
}

