/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemovePageResponseDTO
 */
export interface RemovePageResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof RemovePageResponseDTO
     */
    pageNumber: number;
}

/**
 * Check if a given object implements the RemovePageResponseDTO interface.
 */
export function instanceOfRemovePageResponseDTO(value: object): value is RemovePageResponseDTO {
    if (!('pageNumber' in value) || value['pageNumber'] === undefined) return false;
    return true;
}

export function RemovePageResponseDTOFromJSON(json: any): RemovePageResponseDTO {
    return RemovePageResponseDTOFromJSONTyped(json, false);
}

export function RemovePageResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemovePageResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'pageNumber': json['pageNumber'],
    };
}

export function RemovePageResponseDTOToJSON(value?: RemovePageResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pageNumber': value['pageNumber'],
    };
}

