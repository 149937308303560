/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenClientResponseDTO
 */
export interface TokenClientResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof TokenClientResponseDTO
     */
    accessToken: string;
    /**
     * 
     * @type {number}
     * @memberof TokenClientResponseDTO
     */
    expiresIn: number;
    /**
     * 
     * @type {string}
     * @memberof TokenClientResponseDTO
     */
    tokenType: string;
}

/**
 * Check if a given object implements the TokenClientResponseDTO interface.
 */
export function instanceOfTokenClientResponseDTO(value: object): value is TokenClientResponseDTO {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('expiresIn' in value) || value['expiresIn'] === undefined) return false;
    if (!('tokenType' in value) || value['tokenType'] === undefined) return false;
    return true;
}

export function TokenClientResponseDTOFromJSON(json: any): TokenClientResponseDTO {
    return TokenClientResponseDTOFromJSONTyped(json, false);
}

export function TokenClientResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenClientResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'expiresIn': json['expires_in'],
        'tokenType': json['token_type'],
    };
}

export function TokenClientResponseDTOToJSON(value?: TokenClientResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'access_token': value['accessToken'],
        'expires_in': value['expiresIn'],
        'token_type': value['tokenType'],
    };
}

