/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KbaType } from './KbaType';
import {
    KbaTypeFromJSON,
    KbaTypeFromJSONTyped,
    KbaTypeToJSON,
} from './KbaType';

/**
 * 
 * @export
 * @interface InviteSignerDTO
 */
export interface InviteSignerDTO {
    /**
     * 
     * @type {string}
     * @memberof InviteSignerDTO
     */
    sessionId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteSignerDTO
     */
    signerId: Array<string>;
    /**
     * 
     * @type {Array<KbaType>}
     * @memberof InviteSignerDTO
     */
    kbaLogs?: Array<KbaType>;
}

/**
 * Check if a given object implements the InviteSignerDTO interface.
 */
export function instanceOfInviteSignerDTO(value: object): value is InviteSignerDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('signerId' in value) || value['signerId'] === undefined) return false;
    return true;
}

export function InviteSignerDTOFromJSON(json: any): InviteSignerDTO {
    return InviteSignerDTOFromJSONTyped(json, false);
}

export function InviteSignerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteSignerDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'signerId': json['signerId'],
        'kbaLogs': json['kbaLogs'] == null ? undefined : ((json['kbaLogs'] as Array<any>).map(KbaTypeFromJSON)),
    };
}

export function InviteSignerDTOToJSON(value?: InviteSignerDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'signerId': value['signerId'],
        'kbaLogs': value['kbaLogs'] == null ? undefined : ((value['kbaLogs'] as Array<any>).map(KbaTypeToJSON)),
    };
}

