/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterDTO
 */
export interface RegisterDTO {
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDTO
     */
    coupon?: string;
}

/**
 * Check if a given object implements the RegisterDTO interface.
 */
export function instanceOfRegisterDTO(value: object): value is RegisterDTO {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function RegisterDTOFromJSON(json: any): RegisterDTO {
    return RegisterDTOFromJSONTyped(json, false);
}

export function RegisterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'password': json['password'],
        'role': json['role'],
        'businessName': json['businessName'] == null ? undefined : json['businessName'],
        'coupon': json['coupon'] == null ? undefined : json['coupon'],
    };
}

export function RegisterDTOToJSON(value?: RegisterDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'email': value['email'],
        'password': value['password'],
        'role': value['role'],
        'businessName': value['businessName'],
        'coupon': value['coupon'],
    };
}

