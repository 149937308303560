import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { IMediator } from "../../../../store/zustandType";
import { useSessionCreate } from "../../../../store/store";

const MediatoresDetail = ({
  values,
  from,
}: {
  from: string;
  values: {
    mediators: IMediator[];
  };
}) => {
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  return (
    <Box my={5}>
      <Text fontWeight={600}>Do you need mediator for this session?</Text>
      <RadioGroup
        mb={5}
        value={sessionDetail?.needMediator ? "yes" : "no"}
        onChange={(value) => {
          if (value === "no") {
            setSessionDetails({
              ...sessionDetail,
              needMediator: false,
              mediatores: [],
            });
          } else {
            setSessionDetails({ ...sessionDetail, needMediator: true });
          }
        }}
      >
        <Stack direction="row" style={{ marginTop: "10px" }}>
          <Radio value="no">No</Radio>
          <Radio value="yes" pl={3}>
            Yes
          </Radio>
        </Stack>
      </RadioGroup>

      {sessionDetail?.needMediator && (
        <FieldArray
          name="mediators"
          render={(arrayHelpers) => {
            const mediators = values.mediators;
            return (
              <div>
                {mediators && mediators.length > 0
                  ? mediators.map((mediator, index) => (
                      <div key={index}>
                        <Flex justifyContent="space-between">
                          <Text
                            fontWeight={600}
                            fontSize={"20px"}
                            color={"#000080"}
                            pb={"15px"}
                            mb={"20px"}
                          >
                            Mediator {index + 1}
                          </Text>

                          {index !== 0 && (
                            <Button
                              style={{
                                background:
                                  "linear-gradient(rgb(213, 173, 54) 0%, rgba(213, 173, 54, 0.5) 100%)",
                                color: "#000",
                                padding: "10px 30px",
                                fontSize: "15px",
                                height: "40px",
                                width: "16%",
                              }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove
                            </Button>
                          )}
                        </Flex>

                        <Flex flexWrap="wrap">
                          <FormControl
                            mb={6}
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>First Name*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              fontSize="14px"
                              placeholder="John"
                              name={`mediators.${index}.firstName`}
                              value={mediator?.firstName}
                              style={{ textTransform: "capitalize" }}
                            />
                            <br />
                            <ErrorMessage
                              name={`mediators.${index}.firstName`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                            mb={6}
                          >
                            <FormLabel>Last Name*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="Doe"
                              name={`mediators.${index}.lastName`}
                              value={mediator?.lastName}
                              style={{ textTransform: "capitalize" }}
                            />
                            <br />
                            <ErrorMessage
                              name={`mediators.${index}.lastName`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>Email*</FormLabel>
                            <Field
                              className="field-input"
                              type="email"
                              pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                              placeholder="johndoe123@email.com"
                              name={`mediators.${index}.email`}
                              value={mediator?.email}
                            />
                            <br />
                            <ErrorMessage
                              name={`mediators.${index}.email`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>
                        </Flex>
                        <Divider my={4} />
                      </div>
                    ))
                  : null}

                <Button
                  mt={3}
                  width="fit-content"
                  background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                  _hover={{
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  }}
                  isDisabled={arrayHelpers.form.values?.mediator?.length >= 4}
                  onClick={() => {
                    arrayHelpers.push({
                      email: "",
                      firstName: "",
                      lastName: "",
                    });
                  }}
                >
                  + Add Mediator
                </Button>
              </div>
            );
          }}
        />
      )}
    </Box>
  );
};

export default MediatoresDetail;
