/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActiveSession,
  MeetingReview,
  MeetingReviewDTO,
  RecordingPaginationResponse,
  Recordings,
  SessionBodyDTO,
  TerminateSessionDTO,
  UploadRecordingDTO,
} from '../models/index';
import {
    ActiveSessionFromJSON,
    ActiveSessionToJSON,
    MeetingReviewFromJSON,
    MeetingReviewToJSON,
    MeetingReviewDTOFromJSON,
    MeetingReviewDTOToJSON,
    RecordingPaginationResponseFromJSON,
    RecordingPaginationResponseToJSON,
    RecordingsFromJSON,
    RecordingsToJSON,
    SessionBodyDTOFromJSON,
    SessionBodyDTOToJSON,
    TerminateSessionDTOFromJSON,
    TerminateSessionDTOToJSON,
    UploadRecordingDTOFromJSON,
    UploadRecordingDTOToJSON,
} from '../models/index';

export interface ActiveSessionControllerActiveSessionInfoRequest {
    sessionId: number;
}

export interface ActiveSessionControllerCreateRequest {
    sessionBodyDTO: SessionBodyDTO;
}

export interface ActiveSessionControllerEndSessionRequest {
    uploadRecordingDTO: UploadRecordingDTO;
}

export interface ActiveSessionControllerFindOneRequest {
    sessionId: string;
}

export interface ActiveSessionControllerGetAllPaginatedRequest {
    page: number;
    limit: number;
    search: string;
}

export interface ActiveSessionControllerGetMeetingRequest {
    sessionId: number;
}

export interface ActiveSessionControllerGetRecordingbySessionIdRequest {
    sessionId: number;
}

export interface ActiveSessionControllerGetRecordingsRequest {
    notaryId: number;
}

export interface ActiveSessionControllerNewConferenceRequest {
    sessionId: number;
}

export interface ActiveSessionControllerReviewMeetingRequest {
    meetingReviewDTO: MeetingReviewDTO;
}

export interface ActiveSessionControllerTerminateRequest {
    terminateSessionDTO: TerminateSessionDTO;
}

/**
 * 
 */
export class ActiveSessionApi extends runtime.BaseAPI {

    /**
     */
    async activeSessionControllerActiveSessionInfoRaw(requestParameters: ActiveSessionControllerActiveSessionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling activeSessionControllerActiveSessionInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/activeSessionInfo/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerActiveSessionInfo(requestParameters: ActiveSessionControllerActiveSessionInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.activeSessionControllerActiveSessionInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerCreateRaw(requestParameters: ActiveSessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['sessionBodyDTO'] == null) {
            throw new runtime.RequiredError(
                'sessionBodyDTO',
                'Required parameter "sessionBodyDTO" was null or undefined when calling activeSessionControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/active-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SessionBodyDTOToJSON(requestParameters['sessionBodyDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerCreate(requestParameters: ActiveSessionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.activeSessionControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerEndSessionRaw(requestParameters: ActiveSessionControllerEndSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uploadRecordingDTO'] == null) {
            throw new runtime.RequiredError(
                'uploadRecordingDTO',
                'Required parameter "uploadRecordingDTO" was null or undefined when calling activeSessionControllerEndSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/active-session/end-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadRecordingDTOToJSON(requestParameters['uploadRecordingDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activeSessionControllerEndSession(requestParameters: ActiveSessionControllerEndSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activeSessionControllerEndSessionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async activeSessionControllerFindOneRaw(requestParameters: ActiveSessionControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling activeSessionControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerFindOne(requestParameters: ActiveSessionControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.activeSessionControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerGetAllPaginatedRaw(requestParameters: ActiveSessionControllerGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordingPaginationResponse>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling activeSessionControllerGetAllPaginated().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling activeSessionControllerGetAllPaginated().'
            );
        }

        if (requestParameters['search'] == null) {
            throw new runtime.RequiredError(
                'search',
                'Required parameter "search" was null or undefined when calling activeSessionControllerGetAllPaginated().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/recordings-paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordingPaginationResponseFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerGetAllPaginated(requestParameters: ActiveSessionControllerGetAllPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordingPaginationResponse> {
        const response = await this.activeSessionControllerGetAllPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerGetMeetingRaw(requestParameters: ActiveSessionControllerGetMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingReview>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling activeSessionControllerGetMeeting().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/meeting-review/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingReviewFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerGetMeeting(requestParameters: ActiveSessionControllerGetMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingReview> {
        const response = await this.activeSessionControllerGetMeetingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerGetRecordingbySessionIdRaw(requestParameters: ActiveSessionControllerGetRecordingbySessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Recordings>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling activeSessionControllerGetRecordingbySessionId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/get-recordings-by-session/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordingsFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerGetRecordingbySessionId(requestParameters: ActiveSessionControllerGetRecordingbySessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Recordings> {
        const response = await this.activeSessionControllerGetRecordingbySessionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerGetRecordingsRaw(requestParameters: ActiveSessionControllerGetRecordingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Recordings>>> {
        if (requestParameters['notaryId'] == null) {
            throw new runtime.RequiredError(
                'notaryId',
                'Required parameter "notaryId" was null or undefined when calling activeSessionControllerGetRecordings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/get-recordings/{notaryId}`.replace(`{${"notaryId"}}`, encodeURIComponent(String(requestParameters['notaryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecordingsFromJSON));
    }

    /**
     */
    async activeSessionControllerGetRecordings(requestParameters: ActiveSessionControllerGetRecordingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Recordings>> {
        const response = await this.activeSessionControllerGetRecordingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerNewConferenceRaw(requestParameters: ActiveSessionControllerNewConferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling activeSessionControllerNewConference().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/newConference/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerNewConference(requestParameters: ActiveSessionControllerNewConferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.activeSessionControllerNewConferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeSessionControllerReviewMeetingRaw(requestParameters: ActiveSessionControllerReviewMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingReviewDTO'] == null) {
            throw new runtime.RequiredError(
                'meetingReviewDTO',
                'Required parameter "meetingReviewDTO" was null or undefined when calling activeSessionControllerReviewMeeting().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/active-session/MeetingReview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingReviewDTOToJSON(requestParameters['meetingReviewDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activeSessionControllerReviewMeeting(requestParameters: ActiveSessionControllerReviewMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activeSessionControllerReviewMeetingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async activeSessionControllerSaveRecordingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/active-session/recording-available`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activeSessionControllerSaveRecording(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activeSessionControllerSaveRecordingRaw(initOverrides);
    }

    /**
     */
    async activeSessionControllerTerminateRaw(requestParameters: ActiveSessionControllerTerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveSession>> {
        if (requestParameters['terminateSessionDTO'] == null) {
            throw new runtime.RequiredError(
                'terminateSessionDTO',
                'Required parameter "terminateSessionDTO" was null or undefined when calling activeSessionControllerTerminate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/active-session/terminate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TerminateSessionDTOToJSON(requestParameters['terminateSessionDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveSessionFromJSON(jsonValue));
    }

    /**
     */
    async activeSessionControllerTerminate(requestParameters: ActiveSessionControllerTerminateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveSession> {
        const response = await this.activeSessionControllerTerminateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
