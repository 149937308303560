/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Payout
 */
export interface Payout {
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    paymentFrequency: string;
    /**
     * 
     * @type {number}
     * @memberof Payout
     */
    userId: number;
}

/**
 * Check if a given object implements the Payout interface.
 */
export function instanceOfPayout(value: object): value is Payout {
    if (!('paymentFrequency' in value) || value['paymentFrequency'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function PayoutFromJSON(json: any): Payout {
    return PayoutFromJSONTyped(json, false);
}

export function PayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payout {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentFrequency': json['paymentFrequency'],
        'userId': json['userId'],
    };
}

export function PayoutToJSON(value?: Payout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentFrequency': value['paymentFrequency'],
        'userId': value['userId'],
    };
}

