/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardDTO } from './CardDTO';
import {
    CardDTOFromJSON,
    CardDTOFromJSONTyped,
    CardDTOToJSON,
} from './CardDTO';

/**
 * 
 * @export
 * @interface CardDetails
 */
export interface CardDetails {
    /**
     * 
     * @type {Array<CardDTO>}
     * @memberof CardDetails
     */
    cards: Array<CardDTO>;
    /**
     * 
     * @type {string}
     * @memberof CardDetails
     */
    defaultPaymentMethodId: string;
}

/**
 * Check if a given object implements the CardDetails interface.
 */
export function instanceOfCardDetails(value: object): value is CardDetails {
    if (!('cards' in value) || value['cards'] === undefined) return false;
    if (!('defaultPaymentMethodId' in value) || value['defaultPaymentMethodId'] === undefined) return false;
    return true;
}

export function CardDetailsFromJSON(json: any): CardDetails {
    return CardDetailsFromJSONTyped(json, false);
}

export function CardDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'cards': ((json['cards'] as Array<any>).map(CardDTOFromJSON)),
        'defaultPaymentMethodId': json['defaultPaymentMethodId'],
    };
}

export function CardDetailsToJSON(value?: CardDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cards': ((value['cards'] as Array<any>).map(CardDTOToJSON)),
        'defaultPaymentMethodId': value['defaultPaymentMethodId'],
    };
}

