import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

function ImageModal({
  isOpen,
  onClose,
  title,
  content,
  size = "lg",
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: string | JSX.Element;
  size?: string;
}) {
  return (
    <>
      <Modal onClose={onClose} size={size} isOpen={isOpen} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{content}</ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ImageModal;

export function ImageComponent({
  src,
  openImageModal,
  height = "210px",
}: {
  src: string;
  openImageModal: Function;
  height?: string;
}) {
  return (
    <Box position={"relative"} height={height} width={"100%"}>
      <Image
        cursor={"pointer"}
        src={src}
        height="100%"
        width={"100%"}
        alt=""
        objectFit={"contain"}
      />
      <Flex
        _hover={{
          opacity: "0.8",
        }}
        opacity={0}
        position={"absolute"}
        top={0}
        right={0}
        height={"100%"}
        width={"100%"}
        backgroundColor={"rgba(0, 0, 0, 0.5)"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Button
          color={"white"}
          onClick={() => openImageModal(src)}
          backgroundColor={"black"}
          _hover={{
            backgroundColor: "black",
          }}
          display={"flex"}
          flexDirection={"column"}
        >
          <ViewIcon />
          <Text fontWeight={600} fontSize={"14px"}>
            view
          </Text>
        </Button>
      </Flex>
    </Box>
  );
}
