import { AuditLogs } from "../api";
import { EventType, PartialObjectData } from "./auditLogs";

export function addAuditLogUtil(
  payload: PartialObjectData,
  auditLogs: PartialObjectData[][],
  selectedPage: number
) {
  if (!auditLogs?.[selectedPage]) return;
  const index = auditLogs[selectedPage].findIndex(
    (a: PartialObjectData) => a.id === payload.id
  );
  if (index !== -1) {
    // updateAuditLog({
    //   auditLog: payload,
    //   id: auditLogs[selectedPage][index].id,
    //   selectedPage,
    // });
    updateAuditLogUtil(payload, auditLogs,selectedPage)
    return;
  }
  if (payload?.type === EventType.text) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          text: payload.text,
          type: "text",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.disclosure) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          text: payload.text,
          type: "disclosure",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.date) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          text: new Intl.DateTimeFormat("en-US").format(new Date()).toString(),
          type: "date",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.signerInitials) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: "signerInitials",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.rectangle) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: "rectangle",
          color: "#ffffff",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.checkbox) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: "checkbox",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.signerSignature) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: "signerSignature",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.scribble) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: "scribble",
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.witnessSignature) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: EventType.witnessSignature,
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.notaryStamp) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: payload?.type,
          url: payload.url,
          typeOfStamp: payload?.typeOfStamp,
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.notarySignature) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: payload?.type,
          url: payload.url,
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  } else if (payload?.type === EventType.notaryInitials) {
    auditLogs = [
      ...auditLogs.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage],
        {
          ...payload,
          type: payload?.type,
          url: payload.url,
        },
      ],
      ...auditLogs.slice(selectedPage + 1, auditLogs.length),
    ];
  }
  return auditLogs;
}

export function updateAuditLogUtil(
  payload: PartialObjectData,
  auditLogs: PartialObjectData[][],
  selectedPage: number
) {
  if (!auditLogs) return;
  if (auditLogs[selectedPage]) {
    auditLogs = [
      ...auditLogs?.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage]?.map((audit: any) =>
          audit.id === payload.id ? { ...payload } : audit
        ),
      ],
      ...auditLogs?.slice(selectedPage + 1, auditLogs?.length),
    ];
  }
  return auditLogs;
}


export function deleteAuditLogUtil(
  payload: PartialObjectData,
  auditLogs: PartialObjectData[][],
  selectedPage: number
) {
  if (!auditLogs) return;
  if (auditLogs[selectedPage]) {
    auditLogs = [
      ...auditLogs?.slice(0, selectedPage),
      [
        ...auditLogs[selectedPage].filter(
          (el: any) => el.id !== payload.id 
        ),
      ],
      ...auditLogs?.slice(selectedPage + 1, auditLogs?.length),
    ];
  }
  return auditLogs;
}