import {
  Button,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import WaitingForNotaryImage from "../../../assets/images/WaitingForNotary.png";

const WaitingModal = ({
  isOpen,
  onOpen,
  onClose,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  useEffect(() => {
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={"90%"}>
        <ModalHeader>Stay tuned. We’ll notify you... </ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Center py={4}>
            <Image src={WaitingForNotaryImage} />
          </Center>
          <Text textAlign={"center"} p={2}>
            Please keep this screen open and your volume turned up.
          </Text>
          <Text textAlign={"center"} p={2}>
            A notary will join you shortly!
          </Text>
          <Center py={4}>
            <Button onClick={onClose} width={"50%"} backgroundColor={"#D5AD36"}>
              Got it
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WaitingModal;
