/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionBodyDTO
 */
export interface SessionBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof SessionBodyDTO
     */
    session: number;
}

/**
 * Check if a given object implements the SessionBodyDTO interface.
 */
export function instanceOfSessionBodyDTO(value: object): value is SessionBodyDTO {
    if (!('session' in value) || value['session'] === undefined) return false;
    return true;
}

export function SessionBodyDTOFromJSON(json: any): SessionBodyDTO {
    return SessionBodyDTOFromJSONTyped(json, false);
}

export function SessionBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionBodyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'session': json['session'],
    };
}

export function SessionBodyDTOToJSON(value?: SessionBodyDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'session': value['session'],
    };
}

