import { LiveKitRoom, RoomAudioRenderer } from "@livekit/components-react";
import Loader from "../../commonComponents/LoadingComponents/Loader";
import { Room } from "livekit-client";
import { useMemo } from "react";
import { LIVEKIT_URL } from "../../../utils/constants";

const serverUrl = LIVEKIT_URL;
const MeetingRoom = ({
  token,
  children,
}: {
  token: string;
  children: JSX.Element;
}) => {
  const room = useMemo(() => new Room(), []);
  return (
    <>
      {token === null ? (
        <>
          <Loader isLoading={true} />
        </>
      ) : (
        <>
          <LiveKitRoom
            room={room}
            audio={true}
            video={true}
            token={token}
            serverUrl={serverUrl}
          >
            <>
            {children}
            <RoomAudioRenderer />
            </>
          </LiveKitRoom>
        </>
      )}
    </>
  );
};
export default MeetingRoom;
