/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentDTO
 */
export interface DocumentDTO {
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDTO
     */
    url: string;
}

/**
 * Check if a given object implements the DocumentDTO interface.
 */
export function instanceOfDocumentDTO(value: object): value is DocumentDTO {
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function DocumentDTOFromJSON(json: any): DocumentDTO {
    return DocumentDTOFromJSONTyped(json, false);
}

export function DocumentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'url': json['url'],
    };
}

export function DocumentDTOToJSON(value?: DocumentDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'url': value['url'],
    };
}

