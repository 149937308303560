//@ts-nocheck
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import MaskedInput from "react-text-mask";
import { MdDeleteOutline } from "react-icons/md";
import Select, { SingleValue } from "react-select";
import { ErrorMessage, Field, FieldArray } from "formik";
import { useEffect, useState, ChangeEvent, FocusEvent } from "react";
import { IWitness } from "../../../../store/zustandType";
import { phoneNumberMask } from "../../../../utils/utils";
import { useSessionCreate } from "../../../../store/store";
import { NoOfWitnessOptions } from "../../../../utils/options";

const NeedWitness = ({
  values,
  from,
  handleChange,
  handleBlur,
}: {
  from: string;
  values: {
    witnesses: IWitness[];
  };
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any, Element>) => void;
}) => {
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: number;
      value: number;
    }>
  >(
    NoOfWitnessOptions?.find(
      (item) => item.value === sessionDetail.witnessesCount
    ) || {
      label: 1,
      value: 1,
    }
  );

  useEffect(() => {
    if (
      sessionDetail?.needWitness === "no" ||
      sessionDetail?.needWitness === "yes"
    ) {
      setSessionDetails({ ...sessionDetail, witnessesCount: 0 });
    } else {
      setSessionDetails({
        ...sessionDetail,
        witnessesCount: selectedOption?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, sessionDetail?.needWitness]);

  return (
    <Box my={4}>
      <Text fontWeight={600} fontSize={"17px"}>
        Need a witness?
      </Text>
      <RadioGroup
        mb={5}
        value={sessionDetail?.needWitness}
        onChange={(value) => {
          setSessionDetails({ ...sessionDetail, needWitness: value });
        }}
      >
        <Stack direction="row" style={{ marginTop: "10px" }}>
          <Radio value="no">No</Radio>
          <Radio value="yes" pl={3}>
            Bring your own witness
          </Radio>
          <Radio value="need" pl={3}>
            Need notarize genie witness
          </Radio>
        </Stack>
      </RadioGroup>

      {sessionDetail?.needWitness === "need" && (
        <Box>
          <Text fontWeight={500} mt={5}>
            Select number of witness you need
          </Text>
          <Box width={"90px"} mt={3}>
            <Select
              placeholder="Select"
              menuPlacement="top"
              options={NoOfWitnessOptions}
              value={selectedOption}
              onChange={(option) => setSelectedOption(option)}
            />
          </Box>
        </Box>
      )}

      {sessionDetail?.needWitness === "yes" && (
        <FieldArray
          name="witnesses"
          render={(arrayHelpers) => {
            const witnesses = values.witnesses;
            return (
              <div>
                {witnesses && witnesses.length > 0
                  ? witnesses.map((witness, index) => (
                      <div key={index}>
                        <Flex justifyContent="space-between">
                          <Text
                            fontWeight={600}
                            fontSize={"20px"}
                            color={"#000080"}
                            pb={"15px"}
                            mb={"20px"}
                          >
                            Witness {index + 1}
                          </Text>

                          {index !== 0 && (
                            <Button
                              color={"#df2c14"}
                              height={"40px"}
                              _hover={{
                                background: "#df2c14",
                                color: "#fff",
                              }}
                              border={"1px solid #df2c14"}
                              backgroundColor={"white"}
                              p={0}
                              width={"100px"}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <MdDeleteOutline /> <Text ml={1}>Remove</Text>
                            </Button>
                          )}
                        </Flex>

                        <Flex flexWrap="wrap">
                          <FormControl
                            mb={6}
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>First Name*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              fontSize="14px"
                              placeholder="John"
                              name={`witnesses.${index}.firstName`}
                              value={witness?.firstName}
                              style={{ textTransform: "capitalize" }}
                            />
                            <br />
                            <ErrorMessage
                              name={`witnesses.${index}.firstName`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                            mb={6}
                          >
                            <FormLabel>Last Name*</FormLabel>
                            <Field
                              className="field-input"
                              type="text"
                              placeholder="Doe"
                              name={`witnesses.${index}.lastName`}
                              value={witness?.lastName}
                              style={{ textTransform: "capitalize" }}
                            />
                            <br />
                            <ErrorMessage
                              name={`witnesses.${index}.lastName`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>Email*</FormLabel>
                            <Field
                              className="field-input"
                              type="email"
                              pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                              placeholder="johndoe123@email.com"
                              name={`witnesses.${index}.email`}
                              value={witness?.email}
                            />
                            <br />
                            <ErrorMessage
                              name={`witnesses.${index}.email`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>

                          <FormControl
                            mb={6}
                            minW={"230px"}
                            w={["100%", "33%", "33%"]}
                            pr={4}
                          >
                            <FormLabel>Phone Number*</FormLabel>
                            <Field
                              name={`witnesses.${index}.phoneNumber`}
                              // @ts-ignore
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={phoneNumberMask}
                                  id={`witnesses.${index}.phoneNumber`}
                                  placeholder="(___)-___-____"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="field-input"
                                  value={witness?.phoneNumber}
                                />
                              )}
                            />
                            <br />
                            <ErrorMessage
                              name={`witnesses.${index}.phoneNumber`}
                              render={(msg) => (
                                <div className="field-input-error">{msg}</div>
                              )}
                            />
                          </FormControl>
                        </Flex>
                        <Divider my={4} />
                      </div>
                    ))
                  : null}

                <Button
                  mt={3}
                  width="fit-content"
                  background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                  _hover={{
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  }}
                  isDisabled={arrayHelpers.form.values?.witnesses?.length >= 4}
                  onClick={() => {
                    arrayHelpers.push({
                      email: "",
                      firstName: "",
                      lastName: "",
                      phoneNumber: "",
                    });
                  }}
                >
                  + Add Witness
                </Button>
              </div>
            );
          }}
        />
      )}
    </Box>
  );
};

export default NeedWitness;
