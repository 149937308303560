/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OmitTypeClass
 */
export interface OmitTypeClass {
    /**
     * 
     * @type {number}
     * @memberof OmitTypeClass
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    coupon?: string;
    /**
     * 
     * @type {string}
     * @memberof OmitTypeClass
     */
    role: string;
    /**
     * 
     * @type {Date}
     * @memberof OmitTypeClass
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OmitTypeClass
     */
    updatedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof OmitTypeClass
     */
    applyCouponDate?: Date;
}

/**
 * Check if a given object implements the OmitTypeClass interface.
 */
export function instanceOfOmitTypeClass(value: object): value is OmitTypeClass {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function OmitTypeClassFromJSON(json: any): OmitTypeClass {
    return OmitTypeClassFromJSONTyped(json, false);
}

export function OmitTypeClassFromJSONTyped(json: any, ignoreDiscriminator: boolean): OmitTypeClass {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'token': json['token'] == null ? undefined : json['token'],
        'businessName': json['businessName'] == null ? undefined : json['businessName'],
        'coupon': json['coupon'] == null ? undefined : json['coupon'],
        'role': json['role'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
        'applyCouponDate': json['applyCouponDate'] == null ? undefined : (new Date(json['applyCouponDate'])),
    };
}

export function OmitTypeClassToJSON(value?: OmitTypeClass | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'token': value['token'],
        'businessName': value['businessName'],
        'coupon': value['coupon'],
        'role': value['role'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
        'applyCouponDate': value['applyCouponDate'] == null ? undefined : ((value['applyCouponDate']).toISOString()),
    };
}

