import {
  Heading,
  Stack,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Center,
  Spinner,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import FlatList from "flatlist-react";
import { useState, useEffect } from "react";
import Select, { SingleValue } from "react-select";
// import { BsEye as ViewIcon } from "react-icons/bs";
import { BsDownload as DownloadIcon } from "react-icons/bs";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import useNotification from "../../utils/useNotification";
import { useAuth } from "../../utils/use-auth";
import { TransactionApi } from "../../api";
import { handleError } from "../../utils/utils-functions";
import { espTransform, getInvoiceStatusBgColor } from "../../utils/utils";
import { InvoiceStatusOptions } from "../../utils/options";
import NoDataFound from "../commonComponents/LoadingComponents/NoDataFound";
import PreviewModal from "../commonComponents/PreviewModal";
import InvoiceHeader from "./Component/InvoiceHeader";

const InvoiceList = () => {
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [invoices, setInvoices] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState({
    searchTerm: "",
    date: "",
  });
  const [status, setStatus] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >({
    label: "All",
    value: "all",
  });

  useEffect(() => {
    setLoading(true);
    new TransactionApi(configuration)
      .transactionControllerGetInvoices()
      .then((res) => {
        setInvoices(res.data);
        setLoading(false);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        notification("fail", message.errorMessage);
        setLoading(false);
        setInvoices([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (url: string, id: string) => {
    window.open(url, "_blank");
  };

  const renderInvoices = ({
    id,
    number,
    amount_paid,
    status,
    created,
    invoice_pdf,
    account_name,
    total,
  }: any) => (
    <Tr style={{ fontWeight: 500 }}>
      <Td style={{ color: "#9A9595" }}>{id}</Td>
      <Td style={{ color: "#9A9595" }}>{account_name}</Td>
      <Td style={{ color: "#9A9595" }}>{espTransform(total)}</Td>
      <Td>
        <Text
          py={"4px"}
          fontSize="12px"
          textAlign="center"
          textTransform="capitalize"
          borderRadius="20px"
          width="100px"
          color="#fff"
          style={{
            background: getInvoiceStatusBgColor(status),
          }}
        >
          {status}
        </Text>
      </Td>
      <Td>
        <Flex
          role="button"
          alignItems={"center"}
          onClick={() => {
            handleDownload(invoice_pdf, id);
            setUrl(invoice_pdf);
            // onOpen();
          }}
          color="#000080"
        >
          <DownloadIcon />
          <Text ml={2}>Download</Text>
          {/* <ViewIcon /> <Text ml={2}>View</Text> */}
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <Stack spacing={6} w={"100%"} px={3}>
      <Heading
        fontSize={"22px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={4}
        fontFamily="Oswald"
        fontWeight={600}
        color={"#2D3748"}
      >
        Invoices
      </Heading>

      {invoices.length !== 0 && <InvoiceHeader invoices={invoices} />}

      <Flex style={{ gap: "20px" }}>
        <InputGroup width={"40%"}>
          <InputLeftElement
            pointerEvents="none"
            children={<Search color="gray.300" />}
          />
          <Input
            type="text"
            autoFocus
            value={filters.searchTerm}
            onChange={(e) => {
              setFilters({ ...filters, searchTerm: e.target.value });
            }}
            placeholder="Search by Invoice Id"
          />
        </InputGroup>

        <Box width={"190px"}>
          <Select
            placeholder="Select"
            options={InvoiceStatusOptions}
            value={status}
            onChange={(option) => setStatus(option)}
          />
        </Box>

        {/* <Input
            width={"30%"}
            placeholder="Date"
            size="md"
            type="date"
            style={{ color: "#9A9595" }}
            value={filters.date}
            onChange={(e) => setFilters({ ...filters, date: e.target.value })}
          /> */}
      </Flex>

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : invoices?.length === 0 ? (
        <NoDataFound />
      ) : (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th style={{ color: "#000" }}>INVOICE ID </Th>
                {/* <Th style={{ color: "#000" }}>INVOICE NUMBER </Th> */}
                <Th style={{ color: "#000" }}>ACCOUNT NAME</Th>
                <Th style={{ color: "#000" }}>AMOUNT</Th>
                <Th style={{ color: "#000" }}>STATUS</Th>
                <Th style={{ color: "#000" }}>INVOICE PDF</Th>
              </Tr>
            </Thead>
            <Tbody>
              <FlatList
                searchTerm={filters.searchTerm}
                filterBy={(option) =>
                  status?.value === "all"
                    ? true
                    : status?.value === option.status
                }
                searchBy={["id"]}
                searchCaseInsensitive
                searchableMinCharactersCount={1}
                list={invoices}
                renderItem={renderInvoices}
                // sortBy={"createdDate"}
                // sortDescending
                renderWhenEmpty={() => <div>List is empty!</div>}
              />
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <PreviewModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        url={url}
        type={[".pdf"]}
      />
    </Stack>
  );
};

export default InvoiceList;
