import {
  Box,
  Flex,
  Text,
  Divider,
  Heading,
  Button,
  Center,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";
import { useAuth } from "../../../../utils/use-auth";
import { espTransform } from "../../../../utils/utils";
import { getBusinessDetails } from "../../../../utils/utils-apis";
import CouponModal from "../../../Notary/NotaryPlans/Components/CouponModal";
import {
  BusinessPlansApi,
  Plan,
  Subscription,
  TransactionApi,
} from "../../../../api";

const Plans = ({
  onClose,
  isDisablePlans,
  requestedPlanData,
  activePlan,
}: {
  activePlan?: Subscription | undefined;
  requestedPlanData?: any;
  onClose?: () => void;
  isDisablePlans?: boolean;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");
  const role = localStorage.getItem("role");
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const { isOpen, onOpen, onClose: onCouponClose } = useDisclosure();
  const [selectedPlan, setSelectedPlan] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    new BusinessPlansApi(configuration)
      .planControllerGetAll()
      .then((res) => {
        setPlans(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBusinessDetails(configuration)
      .then((res) => {
        if (
          res.user.coupon === "" ||
          res.user.coupon === null ||
          res.user.coupon === undefined
        ) {
          setCouponApplied(false);
        } else {
          setCouponApplied(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlan = (planId: number) => {
    setIsDisable(true);
    new TransactionApi(configuration)
      .transactionControllerGetStripeSubscriptionRaw()
      .then(async (res) => {
        const result = await res.raw.json();
        if (result.status === 404) {
          new TransactionApi(configuration)
            .transactionControllerCreateCheckout({
              checkoutRequestDTO: {
                successUrl: `${window.location.protocol}//${window.location.host}/thank-you`,
                cancelUrl: `${window.location.protocol}//${window.location.host}`,
                planId: planId,
                role: role as string,
              },
            })
            .then((res) => {
              setIsDisable(false);
              console.log({ res });
              window.location.href = res?.redirectUrl;
              if (onClose) {
                onClose();
              }
            })
            .catch((error) => {
              console.log(error);
              setIsDisable(false);
            });
        } else if (result.status === 200) {
          new TransactionApi(configuration)
            .transactionControllerCreateReqSubscriptionRaw({
              requestedSubscriptionDto: {
                planId: planId,
              },
            })
            .then((res) => {
              setIsDisable(false);
              navigate("/")
              if (onClose) {
                onClose();
              }
            })
            .catch((error) => {
              console.log(error);
              setIsDisable(false);
            });
        }
      });
  };

  // const handlePlan = (planId: number) => {
  //   setIsDisable(true);
  //   new TransactionApi(configuration)
  //     .transactionControllerCreateCheckout({
  //       checkoutRequestDTO: {
  //         // successUrl: `${window.location.protocol}//${window.location.host}/business/plans`,
  //         successUrl: `${window.location.protocol}//${window.location.host}/thank-you`,
  //         cancelUrl: `${window.location.protocol}//${window.location.host}`,
  //         planId: planId,
  //         role: role as string,
  //       },
  //     })
  //     .then((res) => {
  //       window.location.href = res?.redirectUrl;
  //       setIsDisable(false);
  //       if (onClose) {
  //         onClose();
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsDisable(false);
  //     });
  // };
  return (
    <div>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={4}>
        Choose Your Business Subscription
      </Text>

      {requestedPlanData?.planName !== "" ? (
        <Text
          textAlign="center"
          color="red"
          fontSize="11px"
          fontWeight={"bold"}
          mb={6}
          style={{ fontStyle: "italic" }}
        >
          (Plan Change in progress. No further action is required at this time.)
        </Text>
      ) : null}

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : plans?.length === 0 ? (
        <Text textAlign="center">No Plan found</Text>
      ) : (
        <Flex
          flexWrap={"wrap"}
          gap={2}
          justifyContent={"flex-start"}
          m={"10px"}
        >
          {plans
            ?.sort((a, b) => (a?.planAmount > b?.planAmount ? 1 : -1))
            ?.map((plan) => (
              <Box
                flexBasis={{ base: "100%", md: "24%", lg: "24%" }}
                cursor={"pointer"}
                key={plan.planId}
              >
                <Box
                  style={{ boxShadow: "3px 5px 20px 0px #ddd" }}
                  w={"100%"}
                  px={5}
                  py={5}
                  borderRadius="10px"
                  border={`${
                    Number(planId) === plan.planId ? "2px solid #000080" : ""
                  }`}
                >
                  <Heading
                    fontSize="24px"
                    fontFamily={"Oswald"}
                    mb={"6px"}
                    textAlign="center"
                    color="#000080"
                  >
                    {plan.planName}{" "}
                    <span style={{ color: "#D5AD36" }}>Package</span>
                  </Heading>
                  <Divider my={4} mr={4} />
                  <Text
                    fontSize={"35px"}
                    color="#000080"
                    fontFamily="Oswald"
                    textAlign="center"
                    fontWeight={500}
                  >
                    {espTransform(plan.planAmount)}/
                    <span style={{ fontSize: "16px", color: "#000" }}>
                      Per month
                    </span>
                  </Text>
                  <Divider my={4} mr={4} />
                  <Box minHeight={{ base: "10rem", md: "20rem", lg: "20rem" }}>
                    {plan?.planFeatures !== undefined &&
                      JSON.parse(plan?.planFeatures)?.map(
                        (item: any, idx: number) => (
                          <Flex key={idx} alignItems="flex-start" mb={3}>
                            <Box mt={1}>
                              <CheckIcon size={18} color="#D5AD36" />
                            </Box>
                            <Text ml={3} fontSize="16px">
                              {item}
                            </Text>
                          </Flex>
                        )
                      )}
                  </Box>

                  <Divider my={4} mr={4} />
                  <Box>
                    {plan.planName !== "Sands" && couponApplied === false && (
                      <Button
                        px={9}
                        mb={2}
                        fontSize={"12px"}
                        fontWeight={600}
                        onClick={() => {
                          setSelectedPlan(plan?.planId);
                          onOpen();
                        }}
                        borderRadius={"34px"}
                      >
                        Apply Coupon Code
                      </Button>
                    )}
                    <Button
                      isDisabled={
                        isDisable
                          ? plan.planName !== "Sands"
                            ? isDisablePlans
                            : false
                          : activePlan?.plan?.planName === plan.planName
                          ? true
                          : false ||
                            isDisable ||
                            (requestedPlanData?.planName !== "" ? true : false)
                      }
                      // isDisabled={
                      //   isDisable ||
                      //   (plan.planName !== "Pro Plan"
                      //     ? activePlan === plan?.planName
                      //     : false) ||
                      //   isDisable ||
                      //   requestedPlanData
                      //     ? true
                      //     : false
                      // }
                      px={10}
                      fontSize={"14px"}
                      fontWeight={600}
                      borderRadius={"34px"}
                      style={{
                        background:
                          "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                        color: "#fff",
                      }}
                      onClick={() => {
                        setSelectedPlan(plan?.planId);
                        handlePlan(plan.planId);
                      }}
                    >
                      Get Started Now
                    </Button>
                  </Box>
                </Box>
                <CouponModal
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onCouponClose}
                  onApply={() => handlePlan(selectedPlan)}
                />
              </Box>
            ))}
        </Flex>
      )}
    </div>
  );
};

export default Plans;
