import { Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Steppers from "../../../component/commonComponents/Steppers";
import { useNotarySessionStepStore } from "../../../store/notaryStore";
import { initialSessionDetails, useSessionCreate } from "../../../store/store";
import { notaryPaths } from "../../../routes/routes";

const stepData = [
  { label: "Session Type", path: "type" },
  { label: "Participants Details", path: "primary-signer" },
  { label: "Meeting Details", path: "notary-meetings" },
  { label: "Document Upload", path: "document-upload" },
  { label: "Notary Notes", path: "notary-note" },
  { label: "Payment", path: "payment" },
];

const NotaryCreateSession = () => {
  const { step, setStep } = useNotarySessionStepStore();
  const { setSessionDetails } = useSessionCreate();

  useEffect(() => {
    setSessionDetails(initialSessionDetails);
  }, [setSessionDetails]);

  return (
    <>
      <Steppers
        from={`${notaryPaths.createSession}`}
        step={step}
        stepData={stepData}
        onSetStep={(step) => setStep(step)}
      />
      <Stack px={6}>
        <Outlet />
      </Stack>
    </>
  );
};

export default NotaryCreateSession;
