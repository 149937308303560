import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SessionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";

const NoteModal = ({
  sessionId,
  note,
  isOpen,
  onOpen,
  onClose,
  onSetCallApi,
}: {
  isOpen: boolean;
  note: string | undefined;
  sessionId: number;
  onOpen: () => void;
  onClose: () => void;
  onSetCallApi: () => void;
}) => {
  const { notification } = useNotification();
  const { configuration } = useAuth();
  
  const formik = useFormik({
    initialValues: {
      note: note,
    },
    validationSchema: Yup.object({
      note: Yup.string()
        .required("Note is required")
        .max(800, "Note must be 800 characters or less."),
    }),

    onSubmit: (values) => {
      new SessionApi(configuration)
        .sessionControllerUpdate({
          sessionId: Number(sessionId),
          sessionUpdateDTO: {
            notaryNote: values.note,
          },
        })
        .then((res) => {
          notification("success", "Note added Sucessfully!");
          onClose();
          onSetCallApi();
        })
        .catch((err) => {
          notification("fail", "Failed to add note!");
          onClose();
          // onSetCallApi();
        });
    },
    enableReinitialize: true,
  });

  return (
    <Modal closeOnEsc isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            ml={2}
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
          >
            Notary Note
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody margin="12px">
          <form onSubmit={formik.handleSubmit} onTouchCancel={onClose}>
            <Flex flexWrap="wrap">
              <FormControl>
                <FormLabel>Session Note</FormLabel>
                <Textarea
                  autoFocus
                  rows={5}
                  name="note"
                  value={formik.values.note}
                  placeholder="Enter your note..."
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  {...(formik.touched.note && formik.errors.note
                    ? { isInvalid: true }
                    : "")}
                />
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.note && formik.errors.note
                    ? formik.errors.note
                    : ""}
                </Text>
              </FormControl>
            </Flex>

            <Divider mt={"20px"}/>

            <Flex justifyContent={"flex-end"}mt={"18px"} >
              <Button
                p="4"
                background="white"
                border={"1px solid rgba(0, 0, 128, 1)"}
                borderRadius="10px"
                fontSize={"16px"}
                color="rgba(0, 0, 128, 1)"
                onClick={() => onClose()}
                mr={3}
              >
                Cancel
              </Button>
              <Button
                p="4"
                background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                borderRadius="10px"
                fontSize={"16px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                }}
                type="submit"
              >
                Save
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NoteModal;
