import { TrackReference } from "@livekit/components-react";

const notaryTracks = (tracks: TrackReference[]) => {
  return tracks.filter(
    (track) =>
      track.participant.identity?.startsWith("notary") &&
      track.participant.isLocal === false
  );
};
const primarySignerTracks = (tracks: TrackReference[]) => {
  return tracks.filter(
    (track) =>
      track.participant.identity?.startsWith("primarySigner") &&
      track.participant.isLocal === false
  );
};
const otherParticipantsTracks = (tracks: TrackReference[]) => {
  return tracks.filter(
    (track) =>
      !track.participant.identity?.startsWith("notary") &&
      !track.participant?.identity?.startsWith("primarySigner") &&
      track.participant.isLocal === false
  );
};
const localParticipantTracks = (tracks: TrackReference[]) => {
  return tracks.filter((track) => track.participant.isLocal === true);
};
const localNotaryTracks = (tracks: TrackReference[]) => {
  return tracks.filter(
    (track) =>
      track.participant.isLocal === true &&
      track.participant.identity?.startsWith("notary")
  );
};
export {
  localParticipantTracks,
  notaryTracks,
  otherParticipantsTracks,
  primarySignerTracks,
  localNotaryTracks,
};
