import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { jwtDecoder } from "../utils/utils-functions";

const FullScreenLayout = () => {
  const [searchParam] = useSearchParams();
  const accessToken = searchParam.get("access-token");
  const token = localStorage.getItem("accessToken");
  if (token !== null) {
    const { userId, role } = jwtDecoder(token);
    localStorage.setItem("UserID", userId);
    localStorage.setItem("role", role);
  }
  // const [token, setToken] = useState(
  //   localStorage.getItem("accessToken") !== null
  //     ? localStorage.getItem("accessToken")
  //     : accessToken
  // );

  // useEffect(() => {
  if (accessToken !== null) {
    localStorage.setItem("accessToken", accessToken);
    // localStorage.setItem("role", "signer");
    // setToken(accessToken);
  }
  // }, [accessToken]);

  return token !== null || accessToken != null ? (
    <Outlet />
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default FullScreenLayout;
