//@ts-nocheck
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import MaskedInput from "react-text-mask";
import { ChangeEvent, FocusEvent } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { ErrorMessage, Field, FieldArrayRenderProps } from "formik";
import { phoneNumberMask } from "../../../../utils/utils";

const FormData = ({
  arrayHelpers,
  from,
  index,
  participant,
  setFieldValue,
  handleChange,
  handleBlur,
}: {
  arrayHelpers: FieldArrayRenderProps;
  from: string;
  index: number;
  participant: {
    firstName: string;
    lastName: string;
    email: string;
    kbaStatus?: string;
    phoneNumber?: string;
  };
  setFieldValue: any;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: FocusEvent<any, Element>) => void;
}) => {
  return (
    <>
      <Flex justifyContent="space-between">
        {index !== 0 && (
          <Text
            fontWeight={600}
            fontSize={"20px"}
            color={"#000080"}
            pb={"15px"}
            mb={"20px"}
          >
            Additional{" "}
            {from === "signer"
              ? "Signer"
              : from === "witness"
              ? "Witness"
              : "Mediator"}{" "}
            {index + 1}
          </Text>
        )}
        {index !== 0 && (
          <Button
            color={"#df2c14"}
            height={"40px"}
            _hover={{
              background: "#df2c14",
              color: "#fff",
            }}
            border={"1px solid #df2c14"}
            backgroundColor={"white"}
            p={0}
            width={"100px"}
            onClick={() => arrayHelpers.remove(index)}
          >
            <MdDeleteOutline /> <Text ml={1}>Remove</Text>
          </Button>
        )}
      </Flex>
      <Flex flexWrap={"wrap"}>
        <FormControl mb={6} minW={"230px"} w={"33%"} pr={4}>
          <FormLabel>First Name</FormLabel>
          <Field
            className="field-input"
            type="text"
            fontSize="14px"
            placeholder="John"
            name={`participants.${index}.firstName`}
            value={participant?.firstName}
            style={{ textTransform: "capitalize" }}
          />
          <br />
          <ErrorMessage
            name={`participants.${index}.firstName`}
            render={(msg) => <div className="field-input-error">{msg}</div>}
          />
        </FormControl>

        <FormControl minW={"230px"} w={"33%"} pr={4}>
          <FormLabel>Last Name</FormLabel>
          <Field
            className="field-input"
            type="text"
            placeholder="Doe"
            name={`participants.${index}.lastName`}
            value={participant?.lastName}
            style={{ textTransform: "capitalize" }}
          />
          <br />
          <ErrorMessage
            name={`participants.${index}.lastName`}
            render={(msg) => <div className="field-input-error">{msg}</div>}
          />
        </FormControl>

        <FormControl mb={6} minW={"230px"} w={"33%"} pr={4}>
          <FormLabel>Email</FormLabel>
          <Field
            className="field-input"
            type="email"
            pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
            placeholder="johndoe123@email.com"
            name={`participants.${index}.email`}
            value={participant?.email}
          />
          <br />
          <ErrorMessage
            name={`participants.${index}.email`}
            render={(msg) => <div className="field-input-error">{msg}</div>}
          />
        </FormControl>

        {from === "signer" && (
          <FormControl minW={"280px"} w={"100%"} pr={4} mb={6}>
            <FormLabel>
              Do you personaly know the signer? If so, the signer does not have
              to complete KBA and credentials analysis.
            </FormLabel>

            <RadioGroup
              name={`participants.${index}.kbaStatus`}
              id={`participants.${index}.kbaStatus`}
              value={participant.kbaStatus === "" ? "no" : "yes"}
              onChange={(value) => {
                if (value === "no") {
                  setFieldValue(`participants.${index}.kbaStatus`, "pending");
                }
                if (value === "yes") {
                  setFieldValue(
                    `participants.${index}.kbaStatus`,
                    "knownByNotary"
                  );
                }
              }}
            >
              <Stack direction="row" style={{ marginTop: "10PX" }}>
                <Radio value="no">No</Radio>
                <Radio value="yes" pl={3}>
                  Yes
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        )}

        {from === "witness" && (
          <FormControl mb={6} minW={"230px"} w={["100%", "33%", "33%"]} pr={4}>
            <FormLabel>Phone Number*</FormLabel>
            <Field
              name={`participants.${index}.phoneNumber`}
              // @ts-ignore
              render={({ field }) => (
                <MaskedInput
                  {...field}
                  mask={phoneNumberMask}
                  id={`participants.${index}.phoneNumber`}
                  placeholder="(___)-___-____"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="field-input"
                  value={participant?.phoneNumber}
                />
              )}
            />
            <br />
            <ErrorMessage
              name={`participants.${index}.phoneNumber`}
              render={(msg) => <div className="field-input-error">{msg}</div>}
            />
          </FormControl>
        )}
      </Flex>

      <Divider my={4} />
    </>
  );
};

export default FormData;
