import { Box } from "@chakra-ui/react";
import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const VirginiaStamp = ({
  name,
  height,
  width,
  commissionNumber,
  expires,
}: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <Box width={"fit-content"}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 309.000000 127.000000"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ background: "transparent" }}
        stroke="transparent"
      >
        {/* ****************************** Name ******************************  */}
        {name.length > 22 ? (
          <>
            <text
              x="50%"
              y="12%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"16px"}
              fontWeight="bold"
            >
              {notaryProfile.firstName} {notaryProfile.middleName}
            </text>
            <text
              x="50%"
              y="24.5%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"16px"}
              fontWeight="bold"
            >
              {notaryProfile.lastName} {notaryProfile.suffix}
            </text>
          </>
        ) : (
          <text
            x="50%"
            y="21%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"19px"}
            fontWeight="bold"
            // letterSpacing="0.2"
            // fill="#F0F1F2"
          >
            {name}
          </text>
        )}

        {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

        <text
          x="50%"
          y="38%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"16px"}
          fontWeight="bold"
        >
          Electronic Notary Public
        </text>

        <text
          x="50%"
          y="54%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"16px"}
          fontWeight="bold"
        >
          Commonwealth of Virginia
        </text>

        {/* ************************************ line ****************************************************** */}

        {/* <line x1="585" y1="140" x2="250" y2="140" stroke="#000000" /> */}

        {/* ****************************** Text -commission ******************************  */}

        <text
          x="50%"
          y="69%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"16px"}
          fontWeight="bold"
        >
          Registration No. {commissionNumber}
        </text>

        {/* *************************************** Text -Expires ******************************************** */}

        <text
          x="50%"
          y="84%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"16px"}
          fontWeight="bold"
        >
          Expires on {moment(expires).format("MMM DD, YYYY")}
        </text>

        <g
          transform="translate(0.000000,127.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M65 1243 c-16 -14 -31 -33 -32 -42 -1 -9 -7 -30 -14 -48 -11 -27 -10
-37 5 -72 19 -41 18 -62 -5 -118 -11 -28 -11 -37 4 -68 21 -45 21 -68 0 -112
-14 -30 -15 -40 -4 -67 22 -52 24 -83 7 -117 -20 -39 -20 -68 -1 -93 8 -11 15
-34 15 -51 0 -17 -7 -40 -15 -51 -19 -25 -19 -55 0 -90 19 -38 19 -65 -2 -109
-15 -32 -16 -39 -2 -78 11 -29 22 -43 37 -45 11 -2 26 -10 32 -18 14 -16 56
-19 65 -3 17 26 56 30 96 7 40 -22 40 -22 77 -3 20 10 40 20 43 22 3 1 25 -7
48 -19 l43 -21 38 22 c39 22 78 18 95 -8 10 -17 41 -13 79 10 l36 21 36 -21
c41 -24 67 -27 83 -7 20 24 48 25 92 3 41 -20 42 -20 78 -2 20 11 39 20 42 22
3 1 25 -7 48 -19 l43 -21 38 22 c39 22 78 18 95 -8 10 -17 40 -13 78 9 27 16
40 18 60 11 14 -5 28 -15 32 -20 9 -16 50 -13 64 3 20 24 48 25 92 3 41 -20
42 -20 78 -2 20 11 39 20 42 22 3 1 25 -7 48 -19 l43 -21 38 22 c39 22 78 18
95 -8 10 -17 41 -13 79 10 l36 21 36 -21 c38 -23 69 -27 79 -10 17 26 56 30
96 7 40 -22 40 -22 77 -3 20 10 40 20 43 22 3 1 25 -7 48 -19 l43 -21 38 22
c39 22 78 18 95 -8 10 -17 40 -13 78 9 27 16 40 18 60 11 14 -5 28 -15 32 -20
8 -14 52 -14 60 0 4 5 18 15 32 20 20 7 33 5 60 -11 19 -11 42 -20 51 -20 21
1 79 47 70 56 -4 3 1 23 11 43 18 35 18 37 0 71 -24 46 -24 72 1 121 l21 40
-21 33 c-25 42 -25 66 0 116 l20 40 -20 37 c-25 46 -25 67 -1 118 19 39 19 40
0 77 -24 47 -24 69 0 114 20 36 20 37 0 75 -24 48 -24 70 0 117 19 36 19 39 1
76 -10 22 -30 44 -46 52 -16 8 -37 19 -46 26 -14 10 -21 9 -41 -10 -29 -28
-70 -28 -109 -1 l-30 22 -28 -22 c-16 -11 -41 -21 -55 -21 -14 0 -39 10 -55
21 l-28 22 -30 -22 c-38 -26 -66 -27 -108 0 l-34 21 -26 -21 c-34 -26 -74 -27
-107 -1 -14 11 -28 20 -32 20 -4 0 -18 -9 -32 -20 -33 -26 -73 -25 -107 1
l-28 22 -28 -22 c-37 -27 -73 -27 -110 0 l-28 22 -30 -22 c-38 -26 -66 -27
-108 0 l-34 21 -26 -21 c-34 -26 -74 -27 -107 -1 -14 11 -28 20 -32 20 -4 0
-18 -9 -32 -20 -33 -26 -73 -25 -107 1 l-28 22 -28 -22 c-37 -27 -73 -27 -110
0 l-28 22 -30 -22 c-38 -26 -72 -27 -105 -1 -31 25 -33 25 -64 0 -14 -11 -38
-20 -53 -20 -15 0 -39 9 -53 20 -31 25 -33 25 -64 0 -33 -26 -73 -25 -107 1
l-28 22 -28 -22 c-37 -27 -73 -27 -110 0 l-28 22 -30 -22 c-38 -26 -66 -27
-108 0 l-34 21 -26 -21 c-34 -26 -74 -27 -107 -1 -14 11 -28 20 -32 20 -4 0
-18 -9 -32 -20 -14 -11 -38 -20 -53 -20 -15 0 -39 9 -53 20 -32 25 -36 25 -72
-7z m2955 -588 l0 -545 -1480 0 -1480 0 0 545 0 545 1480 0 1480 0 0 -545z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default VirginiaStamp;
