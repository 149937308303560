import { useEffect } from "react";
import BusinessDocUpload from "../../../../component/Sessions/Business/BusinessDocUpload";
import { useBusinessSessionStepStore } from "../../../../store/businessStore";

const BusinessDocUploadPage = () => {
  const { setStep } = useBusinessSessionStepStore();

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  return <BusinessDocUpload />;
};

export default BusinessDocUploadPage;
