/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicesResponseDTO
 */
export interface InvoicesResponseDTO {
    /**
     * 
     * @type {Array<object>}
     * @memberof InvoicesResponseDTO
     */
    data: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof InvoicesResponseDTO
     */
    object: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoicesResponseDTO
     */
    hasMore: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoicesResponseDTO
     */
    url: string;
}

/**
 * Check if a given object implements the InvoicesResponseDTO interface.
 */
export function instanceOfInvoicesResponseDTO(value: object): value is InvoicesResponseDTO {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    if (!('hasMore' in value) || value['hasMore'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function InvoicesResponseDTOFromJSON(json: any): InvoicesResponseDTO {
    return InvoicesResponseDTOFromJSONTyped(json, false);
}

export function InvoicesResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicesResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'object': json['object'],
        'hasMore': json['has_more'],
        'url': json['url'],
    };
}

export function InvoicesResponseDTOToJSON(value?: InvoicesResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'object': value['object'],
        'has_more': value['hasMore'],
        'url': value['url'],
    };
}

