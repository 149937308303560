/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocDTO
 */
export interface DocDTO {
    /**
     * 
     * @type {string}
     * @memberof DocDTO
     */
    file: string;
}

/**
 * Check if a given object implements the DocDTO interface.
 */
export function instanceOfDocDTO(value: object): value is DocDTO {
    if (!('file' in value) || value['file'] === undefined) return false;
    return true;
}

export function DocDTOFromJSON(json: any): DocDTO {
    return DocDTOFromJSONTyped(json, false);
}

export function DocDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'file': json['file'],
    };
}

export function DocDTOToJSON(value?: DocDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file': value['file'],
    };
}

