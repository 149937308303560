import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Card from "../../Card";
import { SubscriptionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { useProfileDetailsStore } from "../../../store/notaryStore";

const ProfileStatusData = () => {
  const { configuration } = useAuth();
  const { notaryProfile } = useProfileDetailsStore();
  const [activePlan, setActivePlan] = useState<string>("Free Plan");

  useEffect(() => {
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        if (res.notaryPlan) {
          setActivePlan(res?.notaryPlan?.planName);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flexButtonData = (title: string, condition: boolean) => {
    return (
      <Flex alignItems={"center"}>
        {condition ? (
          <BsCheck2Circle color="green" size={18} />
        ) : (
          <AiOutlineCloseCircle color="red" size={18} />
        )}
        <Text fontWeight={500} ml={2}>
          {title}
        </Text>
      </Flex>
    );
  };

  return (
    <Box mt={7}>
      <Card>
        <Text fontSize="20px" fontWeight={"bold"} mb={5}>
          Profile Status
        </Text>

        <Flex alignItems={"center"} justifyContent={"space-between"}>
          {flexButtonData(
            "BYOTs (GNW & Title/Lender)",
            notaryProfile?.notaryStatus === "active"
          )}

          {flexButtonData("ON-Demand GNW Queue", notaryProfile?.onDemandStatus)}

          {flexButtonData(
            "ON-Demand Title/Lender Queue",
            notaryProfile?.titleLenderStatus
          )}

          {flexButtonData(
            "ON-Demand Business Partner Queue",
            notaryProfile?.businessStatus
          )}

          {flexButtonData("ON-Demand Witness Queue", activePlan === "Pro Plan")}
        </Flex>
      </Card>
    </Box>
  );
};

export default ProfileStatusData;
