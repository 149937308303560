import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import {
  IBusinessProfileDetails,
  IBusinessProfileDetailsStore,
  IProfileStepStore,
} from "./zustandType";

const initialBusinessProfileData = {
  businessName: localStorage.getItem("businessName") as string,
  position: "",
  state: "",
  email: localStorage.getItem("email") as string,
  address1: "",
  address2: "",
  businessWebsite: "",
  businessCity: "",
  businessZip: "",
  businessPhone: "",
  country: "",
  businessId: -1,
};

export const useBusinessProfileDetailsStore =
  create<IBusinessProfileDetailsStore>()(
    devtools(
      OpenReplay.createLogger("businessProfile")(
        // @ts-ignore
        (set) => ({
          businessProfile: initialBusinessProfileData,
          setBusinessProfile: (newDetails: Object) =>
            set((state: { businessProfile: IBusinessProfileDetails }) => ({
              businessProfile: { ...state.businessProfile, ...newDetails },
            })),
        }),
        {
          name: "business-profile-store",
        }
      )
    )
  );

export const useBusinessSessionStepStore = create<IProfileStepStore>((set) => ({
  step: 0,
  setStep: (newDetails: number) =>
    set((state: { step: number }) => ({
      step: newDetails,
    })),
}));
