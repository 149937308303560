import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { BsCheckCircleFill } from "react-icons/bs";
import { HiIdentification } from "react-icons/hi2";
import { IoPersonAdd } from "react-icons/io5";
import { RiIndeterminateCircleFill } from "react-icons/ri";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import { useParticipants } from "@livekit/components-react";
import { useEffect, useMemo } from "react";
import { LocalParticipant, RemoteParticipant } from "livekit-client";
import { LIVEKIT_SPLIT_IDENTIFIER } from "../../../utils/utils";
import { Signer } from "../../../api";
import { useVerifyMembersStore } from "../../../store/verifyMembers";

const ButtonsComponent = ({
  onRejectDocumentModalOpen,
  onAllMeetingModalOpen,
  onInviteModalOpen,
  onVerifyOpen,
  allVerified,
  onSetActive,
}: {
  onRejectDocumentModalOpen: () => void;
  onAllMeetingModalOpen: () => void;
  onInviteModalOpen: () => void;
  onVerifyOpen: () => void;
  allVerified: boolean;
  onSetActive: (value: string) => void;
}) => {
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const participants = useParticipants();
  // const verifiedMembers = useVerifyMembersStore((state) => state.verifiedMembers);
  const setMembers = useVerifyMembersStore((state) => state.setMembers);
  const verifiedMembers = useVerifyMembersStore((state) => state.verifiedMembers);

  useEffect(() => {
    console.log("sessionInfo",sessionInfo)
    if (participants?.length !== 0) {
      const joinedUsersId = participants.map(
        (el: RemoteParticipant | LocalParticipant) =>
          el?.identity?.split(LIVEKIT_SPLIT_IDENTIFIER)[1]
      );

      let membersList: Signer[] = [];
      joinedUsersId?.forEach((el) => {
        const findSigner = sessionInfo?.signers?.find(
          // @ts-ignore, idk
          (signer) => signer?.user?.id === Number(el)
        );
        if (findSigner !== undefined) {
          membersList?.push(findSigner);
        }
      });

      if (membersList?.length !== 0) {
        setMembers([
          ...membersList?.map((member, idx) => ({
            label: `${member?.firstName?.toUpperCase()} ${member?.lastName?.toUpperCase()}`,
            value: member?.signerId,
            role:
              member?.user?.id === sessionInfo?.primarySignerUserId
                ? "primarySigner"
                : "signer",
            isVerify: Boolean(
              verifiedMembers?.find((el) => el === member.email)
            ),
          })),
        ]);

      }
    }
  }, [sessionInfo, setMembers, verifiedMembers, participants]);


  const handleSelect = (value: string) => {
    onSetActive(value);
    onInviteModalOpen();
  };

  return (
    <Box>
      <Flex justifyContent={"space-between"} order={{ base: 2, md: 1, lg: 1 }}>
        <Button
          fontSize={"16px"}
          fontWeight={"normal"}
          color={"#df2c14"}
          height={"40px"}
          _hover={{
            background: "#df2c14",
            color: "#fff",
          }}
          border={"1px solid #df2c14"}
          backgroundColor={"white"}
          onClick={onRejectDocumentModalOpen}
        >
          <RiIndeterminateCircleFill /> <Text ml={2}>Terminate</Text>
        </Button>

        {!sessionInfo?.isNotaryExemplarSheet && (
          <Button
            fontSize={"16px"}
            fontWeight={"normal"}
            height={"40px"}
            _hover={
              allVerified
                ? {
                    opacity: "0.9",
                  }
                : {
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                    color: "#fff",
                  }
            }
            border={allVerified ? "1px solid #09773C" : "1px solid #D5AD36"}
            backgroundColor={allVerified ? "#09773C" : "#fff"}
            color={allVerified ? "#fff" : "#D5AD36"}
            onClick={onVerifyOpen}
          >
            <HiIdentification size={18} />{" "}
            <Text ml={2}>{allVerified ? "ID Verified" : "Verify ID"} </Text>
          </Button>
        )}

        {!sessionInfo?.isNotaryExemplarSheet && (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              fontSize={"16px"}
              fontWeight={"normal"}
              color={"#D5AD36"}
              height={"40px"}
              _hover={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                color: "#fff",
              }}
              border={"1px solid #D5AD36"}
              backgroundColor={"#fff"}
              _expanded={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                color: "#fff",
              }}
            >
              <Flex alignItems={"center"}>
                <IoPersonAdd /> <Text ml={2}>Add Participants</Text>
              </Flex>
            </MenuButton>
            <MenuList zIndex={99}>
              <MenuItem onClick={() => handleSelect("signer")}>
                Signers
              </MenuItem>
              <MenuItem onClick={() => handleSelect("witness")}>
                Witnesses
              </MenuItem>
              <MenuItem onClick={() => handleSelect("mediator")}>
                Mediators
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        <Tooltip
          label="Verify participants before complete the meeting"
          placement="left-end"
        >
          <Button
            fontSize="16px"
            fontWeight="normal"
            _hover={{
              opacity: "0.9",
            }}
            backgroundColor="#09773C"
            color="#fff"
            borderRadius="4px"
            onClick={onAllMeetingModalOpen}
            isDisabled={
              sessionInfo?.isNotaryExemplarSheet === false &&
              // isMeetingBtnDisable ||
              allVerified === false &&
              sessionInfo?.isDemoSession === false
            }
          >
            <BsCheckCircleFill /> <Text ml={2}>Complete</Text>
          </Button>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default ButtonsComponent;
