/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutResponseDTO
 */
export interface CheckoutResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CheckoutResponseDTO
     */
    redirectUrl: string;
}

/**
 * Check if a given object implements the CheckoutResponseDTO interface.
 */
export function instanceOfCheckoutResponseDTO(value: object): value is CheckoutResponseDTO {
    if (!('redirectUrl' in value) || value['redirectUrl'] === undefined) return false;
    return true;
}

export function CheckoutResponseDTOFromJSON(json: any): CheckoutResponseDTO {
    return CheckoutResponseDTOFromJSONTyped(json, false);
}

export function CheckoutResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'redirectUrl': json['redirectUrl'],
    };
}

export function CheckoutResponseDTOToJSON(value?: CheckoutResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'redirectUrl': value['redirectUrl'],
    };
}

