import { useEffect } from "react";
import PersonalDetailsForm from "../../../component/Details/PersonalDetailsForm";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const PersonalInformation = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(0);
  }, [setStep]);

  return <PersonalDetailsForm />;
};

export default PersonalInformation;
