/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedBackDTO
 */
export interface FeedBackDTO {
    /**
     * 
     * @type {string}
     * @memberof FeedBackDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FeedBackDTO
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FeedBackDTO
     */
    description: string;
}

/**
 * Check if a given object implements the FeedBackDTO interface.
 */
export function instanceOfFeedBackDTO(value: object): value is FeedBackDTO {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    return true;
}

export function FeedBackDTOFromJSON(json: any): FeedBackDTO {
    return FeedBackDTOFromJSONTyped(json, false);
}

export function FeedBackDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedBackDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'type': json['type'],
        'description': json['description'],
    };
}

export function FeedBackDTOToJSON(value?: FeedBackDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'type': value['type'],
        'description': value['description'],
    };
}

