import { useEffect } from "react";
import InsuranceDetailsForm from "../../../component/Details/InsuranceDetailsForm";
import { useNotaryProfileStepStore } from "../../../store/notaryStore";

const InsuranceDetails = () => {
  const { setStep } = useNotaryProfileStepStore();

  useEffect(() => {
    setStep(3);
  }, [setStep]);

  return <InsuranceDetailsForm />;
};

export default InsuranceDetails;
