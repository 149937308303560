import { Flex, Text } from "@chakra-ui/react";
import { getStatusBgColor } from "../utils/utils";

const useCustomComponentsHook = () => {
  const flexData = (title: string, content: string | undefined | number) => {
    return (
      <Flex mt={1} w={"100%"} fontSize={"sm"}>
        <Text width={"40%"} textTransform="capitalize">
          {title}
        </Text>
        <Text textTransform="capitalize">{content}</Text>
      </Flex>
    );
  };

  const flexDataStatus = (
    title: string,
    content: string | undefined | number
  ) => {
    return (
      <Flex mt={1} w={"100%"} fontSize={"sm"}>
        <Text width={"40%"} textTransform="capitalize">
          {title}
        </Text>
        <Text
          py={"3px"}
          px={3}
          fontSize="12px"
          textAlign="center"
          textTransform="capitalize"
          borderRadius="20px"
          color="#fff"
          style={{
            background: getStatusBgColor(content as string),
          }}
        >
          {content === "knownByNotary" ? "Known By Notary" : content}
        </Text>
      </Flex>
    );
  };

  return {
    flexData,
    flexDataStatus,
  };
};

export default useCustomComponentsHook;
