import moment from "moment";
import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "../../../Card";
import { DashboardApisApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import FilterComponent from "../../FilterComponent";
import PieChartComponent from "./PieChartComponent";
import { currentTrimester } from "../../../../utils/utils";
import { IPieChartData } from "../../../../store/zustandType";
import { Rating } from "react-simple-star-rating";

const DashboardGraphs = ({
  onSetTotalInvoicesAmount,
  from,
  rating,
}: {
  from?: string;
  rating?: number;
  onSetTotalInvoicesAmount: (value: number) => void;
}) => {
  const { configuration } = useAuth();
  const [totalSessionsCount, setTotalSessionsCount] = useState<number>(0);
  const [totalInvoicesCount, setTotalInvoicesCount] = useState<number>(0);
  const [sessionsGraphData, setSessionsGraphData] = useState<IPieChartData[]>(
    []
  );
  const [invoicesGraphData, setInvoicesGraphData] = useState<IPieChartData[]>(
    []
  );
  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({
    endDate: moment()
      .utc()
      .set("quarter", currentTrimester)
      .endOf("quarter")
      .toISOString(),
    startDate: moment()
      .utc()
      .set("quarter", currentTrimester)
      .startOf("quarter")
      .toISOString(),
  });

  useEffect(() => {
    new DashboardApisApi(configuration)
      .dashboardControllerInvoice()
      .then((res) => {
        const graph = [
          { name: "paid", value: res?.paidInvoices || 0 },
          { name: "draft", value: res?.draftInvoices || 0 },
          { name: "open", value: res?.openInvoices || 0 },
          { name: "unpaid", value: res.unpaidInvoices || 0 },
          { name: "uncollectible", value: res.uncollectible || 0 },
          { name: "void", value: res.voidInvoices || 0 },
        ];
        setTotalInvoicesCount(res?.totalInvoicesCount);
        onSetTotalInvoicesAmount(res?.totalInvoiceAmount);
        setInvoicesGraphData(graph.filter((d) => d.value > 0));
      });
  }, []);

  useEffect(() => {
    new DashboardApisApi(configuration)
      .dashboardControllerSessionsGraph({
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
      .then((res) => {
        const graph = [
          { name: "completed", value: res?.completeSessionCount || 0 },
          { name: "booked", value: res?.bookedSessionCount || 0 },
          { name: "pending", value: res?.pendingSessionCount || 0 },
          { name: "terminated", value: res?.terminateSessionCount || 0 },
          { name: "expired", value: res?.expiredSessionCount || 0 },
        ];
        setTotalSessionsCount(res?.totalSessionCount);
        setSessionsGraphData(graph.filter((d) => d.value > 0));
      });
  }, [filter]);

  return (
    <>
      <Box mt={8}>
        <Flex
          gap={3}
          height={{ base: "auto", md: "auto", lg: "360px" }}
          direction={{ base: "column", md: "column", lg: "row" }}
        >
          <Card>
            <Flex justifyContent={"space-between"} alignItems="center">
              <Text fontSize="16px" fontWeight={700} color="#000080">
                Sessions{" "}
                <span style={{ color: "#000", fontSize: "10px" }}>
                  (From {moment(filter.startDate).format("MM-DD-YYYY")} to{" "}
                  {moment(filter.endDate).format("MM-DD-YYYY")})
                </span>
              </Text>
              <FilterComponent onFilterChange={(value) => setFilter(value)} />
            </Flex>

            <PieChartComponent
              graphData={sessionsGraphData}
              totalCount={totalSessionsCount}
              label="Sessions"
            />
          </Card>

          {from === "notary" ? (
            <Card title={`Rating - ${rating?.toFixed(2)}`}>
              <Rating
                size={60}
                initialValue={rating}
                style={{ marginTop: "10px" }}
                disableFillHover
                allowFraction
                readonly
              />
            </Card>
          ) : (
            <Card title={"Invoices"}>
              <PieChartComponent
                graphData={invoicesGraphData}
                totalCount={totalInvoicesCount}
                label="Invoices"
              />
            </Card>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default DashboardGraphs;
