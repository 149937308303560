/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentROIType
 */
export interface DocumentROIType {
    /**
     * 
     * @type {number}
     * @memberof DocumentROIType
     */
    typeId: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentROIType
     */
    name: string;
}

/**
 * Check if a given object implements the DocumentROIType interface.
 */
export function instanceOfDocumentROIType(value: object): value is DocumentROIType {
    if (!('typeId' in value) || value['typeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function DocumentROITypeFromJSON(json: any): DocumentROIType {
    return DocumentROITypeFromJSONTyped(json, false);
}

export function DocumentROITypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentROIType {
    if (json == null) {
        return json;
    }
    return {
        
        'typeId': json['typeId'],
        'name': json['name'],
    };
}

export function DocumentROITypeToJSON(value?: DocumentROIType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'typeId': value['typeId'],
        'name': value['name'],
    };
}

