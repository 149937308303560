import { useEffect } from "react";
import SessionTypeDetails from "../../../../component/Sessions/components/SessionTypeDetails";
import { useBusinessSessionStepStore } from "../../../../store/businessStore";
import { businessPaths } from "../../../../routes/routes";

const BusinessSessionTypePage = () => {
  const { setStep } = useBusinessSessionStepStore();

  useEffect(() => {
    setStep(0);
  }, [setStep]);

  return (
    <SessionTypeDetails
      nextButtonPath={`${businessPaths.createSession}/document-upload`}
    />
  );
};

export default BusinessSessionTypePage;
