/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Transaction } from './Transaction';
import {
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './Transaction';
import type { NotaryPlan } from './NotaryPlan';
import {
    NotaryPlanFromJSON,
    NotaryPlanFromJSONTyped,
    NotaryPlanToJSON,
} from './NotaryPlan';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';
import type { Plan } from './Plan';
import {
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './Plan';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    subscriptionId: number;
    /**
     * 
     * @type {Plan}
     * @memberof Subscription
     */
    plan?: Plan;
    /**
     * 
     * @type {NotaryPlan}
     * @memberof Subscription
     */
    notaryPlan?: NotaryPlan;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Subscription
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    status: string;
    /**
     * 
     * @type {Transaction}
     * @memberof Subscription
     */
    transaction: Transaction;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    updatedDate: Date;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    packagePerMonth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    isBusiness: boolean;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    freeStamps: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    firstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    inHouseNotaryFirstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    firstStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotFirstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotAdditionalStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    primarySigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    primarySignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotPrimarySigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalSigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalSignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotAdditionalSigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    primaryWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    primaryWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotPrimaryWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    additionalWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    byotAdditionalWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    ownWitnessPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    discountCoupenCode?: string;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): value is Subscription {
    if (!('subscriptionId' in value) || value['subscriptionId'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('transaction' in value) || value['transaction'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    if (!('isBusiness' in value) || value['isBusiness'] === undefined) return false;
    if (!('freeStamps' in value) || value['freeStamps'] === undefined) return false;
    return true;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if (json == null) {
        return json;
    }
    return {
        
        'subscriptionId': json['subscriptionId'],
        'plan': json['plan'] == null ? undefined : PlanFromJSON(json['plan']),
        'notaryPlan': json['notaryPlan'] == null ? undefined : NotaryPlanFromJSON(json['notaryPlan']),
        'user': OmitTypeClassFromJSON(json['user']),
        'status': json['status'],
        'transaction': TransactionFromJSON(json['transaction']),
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
        'packagePerMonth': json['packagePerMonth'] == null ? undefined : json['packagePerMonth'],
        'isBusiness': json['isBusiness'],
        'freeStamps': json['freeStamps'],
        'firstStampPrice': json['firstStampPrice'] == null ? undefined : json['firstStampPrice'],
        'inHouseNotaryFirstStampPrice': json['inHouseNotaryFirstStampPrice'] == null ? undefined : json['inHouseNotaryFirstStampPrice'],
        'firstStampPriceFees': json['firstStampPriceFees'] == null ? undefined : json['firstStampPriceFees'],
        'byotFirstStampPrice': json['byotFirstStampPrice'] == null ? undefined : json['byotFirstStampPrice'],
        'additionalStampPrice': json['additionalStampPrice'] == null ? undefined : json['additionalStampPrice'],
        'additionalStampPriceFees': json['additionalStampPriceFees'] == null ? undefined : json['additionalStampPriceFees'],
        'byotAdditionalStampPrice': json['byotAdditionalStampPrice'] == null ? undefined : json['byotAdditionalStampPrice'],
        'primarySigner': json['primarySigner'] == null ? undefined : json['primarySigner'],
        'primarySignerFees': json['primarySignerFees'] == null ? undefined : json['primarySignerFees'],
        'byotPrimarySigner': json['byotPrimarySigner'] == null ? undefined : json['byotPrimarySigner'],
        'additionalSigner': json['additionalSigner'] == null ? undefined : json['additionalSigner'],
        'additionalSignerFees': json['additionalSignerFees'] == null ? undefined : json['additionalSignerFees'],
        'byotAdditionalSigner': json['byotAdditionalSigner'] == null ? undefined : json['byotAdditionalSigner'],
        'primaryWitness': json['primaryWitness'] == null ? undefined : json['primaryWitness'],
        'primaryWitnessFees': json['primaryWitnessFees'] == null ? undefined : json['primaryWitnessFees'],
        'byotPrimaryWitness': json['byotPrimaryWitness'] == null ? undefined : json['byotPrimaryWitness'],
        'additionalWitness': json['additionalWitness'] == null ? undefined : json['additionalWitness'],
        'additionalWitnessFees': json['additionalWitnessFees'] == null ? undefined : json['additionalWitnessFees'],
        'byotAdditionalWitness': json['byotAdditionalWitness'] == null ? undefined : json['byotAdditionalWitness'],
        'ownWitnessPrice': json['ownWitnessPrice'] == null ? undefined : json['ownWitnessPrice'],
        'discountCoupenCode': json['discountCoupenCode'] == null ? undefined : json['discountCoupenCode'],
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'subscriptionId': value['subscriptionId'],
        'plan': PlanToJSON(value['plan']),
        'notaryPlan': NotaryPlanToJSON(value['notaryPlan']),
        'user': OmitTypeClassToJSON(value['user']),
        'status': value['status'],
        'transaction': TransactionToJSON(value['transaction']),
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
        'packagePerMonth': value['packagePerMonth'],
        'isBusiness': value['isBusiness'],
        'freeStamps': value['freeStamps'],
        'firstStampPrice': value['firstStampPrice'],
        'inHouseNotaryFirstStampPrice': value['inHouseNotaryFirstStampPrice'],
        'firstStampPriceFees': value['firstStampPriceFees'],
        'byotFirstStampPrice': value['byotFirstStampPrice'],
        'additionalStampPrice': value['additionalStampPrice'],
        'additionalStampPriceFees': value['additionalStampPriceFees'],
        'byotAdditionalStampPrice': value['byotAdditionalStampPrice'],
        'primarySigner': value['primarySigner'],
        'primarySignerFees': value['primarySignerFees'],
        'byotPrimarySigner': value['byotPrimarySigner'],
        'additionalSigner': value['additionalSigner'],
        'additionalSignerFees': value['additionalSignerFees'],
        'byotAdditionalSigner': value['byotAdditionalSigner'],
        'primaryWitness': value['primaryWitness'],
        'primaryWitnessFees': value['primaryWitnessFees'],
        'byotPrimaryWitness': value['byotPrimaryWitness'],
        'additionalWitness': value['additionalWitness'],
        'additionalWitnessFees': value['additionalWitnessFees'],
        'byotAdditionalWitness': value['byotAdditionalWitness'],
        'ownWitnessPrice': value['ownWitnessPrice'],
        'discountCoupenCode': value['discountCoupenCode'],
    };
}

