import { Image, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { useEffect, useState } from "react";
import deleteIcon from "../../../assets/icons/trash.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import DrawSignatureModal from "../modals/DrawSignatureModal";
import { useDimensionsStore } from "../../../store/dimensions";
import { useDocumentStore } from "../../../store/documentsStore";
import {
  EventAction,
  EventType,
  useAuditLogsStore,
} from "../../../store/auditLogs";
import { SessionToolsProps } from "../../../store/zustandType";

const GetSignature = ({ from, object, page, scale }: SessionToolsProps) => {
  const userId = localStorage.getItem("UserID");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ratio = useDimensionsStore((state) => state.ratio);
  const docId = useDocumentStore((state) => state.currentDocId);
  const [drawingData, setDrawingData] = useState<string | undefined>();
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  useEffect(() => {
    if (drawingData !== undefined) {
      updateAuditLog({
        id: object.id!,
        selectedPage: page,
        type: object.type!,
        auditLog: { ...object, url: drawingData },
      });
      emitEvent({
        action: EventAction.update,
        socket: socket,
        sessionId: sessionId,
        pageNumber: page,
        event: object.type!,
        payload: { ...object, url: drawingData },
        docId,
      });
    }
  }, [drawingData]);

  return (
    <>
      <DrawSignatureModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onDrawingEnd={(data: string | undefined) => setDrawingData(data)}
        object={object}
        page={page}
      />
      <Rnd
        disableDragging={
          from !== "notary" &&
          from !== "business" &&
          from !== "edit" &&
          object.toBeSignedByUserId !== Number(userId)
        }
        enableResizing={
          from === "notary" || from === "business" || from === "edit"
        }
        bounds="parent"
        scale={scale}
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          border: `1px dashed ${
            EventType.witnessSignature ? "#FCB10050" : "#2490FD50"
          }`,
          backgroundColor: `${
            EventType.witnessSignature ? "#FCB10030" : "#2490FD30"
          }`,
        }}
        size={{
          height: object.height
            ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
              ratio
            : 200,
          width: object.width
            ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
              ratio
            : 200,
        }}
        position={{
          x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
          y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
        }}
        onDragStop={(e, d) => {
          updateAuditLog({
            id: object.id!,
            selectedPage: page,
            type: object.type!,
            auditLog: {
              ...object,
              x: (d.x / (object.ratio ?? 1)) * ratio,
              y: (d.y / (object.ratio ?? 1)) * ratio,
            },
          });
          emitEvent({
            action: EventAction.update,
            event: object.type!,
            pageNumber: page,
            payload: {
              ...object,
              x: (d.x / (object.ratio ?? 1)) * ratio,
              y: (d.y / (object.ratio ?? 1)) * ratio,
            },
            sessionId: sessionId,
            socket: socket,
            docId,
          });
        }}
        onResizeStop={(e, direction, ref, d) => {
          updateAuditLog({
            id: object.id!,
            selectedPage: page,
            type: object.type!,
            auditLog: {
              ...object,
              width: ref.clientWidth,
              height: ref.clientHeight,
            },
          });
          emitEvent({
            action: EventAction.update,
            event: object.type!,
            pageNumber: page,
            payload: {
              ...object,
              width: ref.clientWidth,
              height: ref.clientHeight,
            },
            sessionId: sessionId,
            socket: socket,
            docId,
          });
        }}
      >
        {/* {from == "notary" && (
          <div style={{ cursor: "pointer", marginRight: "4px" }}>
            <Image src={anchor} alt="move icon" height={5} width={5} />
          </div>
        )} */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
          }}
          onClick={() => {
            if (from === "signer" || from === "witness") {
              onOpen();
            }
          }}
          onTouchEnd={() => {
            if (from === "signer" || from === "witness") {
              onOpen();
            }
          }}
        >
          {object.url === undefined ? (
            <Stack>
              <Text fontWeight={"medium"} fontSize="12px">
                {object.type === EventType.witnessSignature
                  ? "Witness Signature"
                  : object.type === EventType.signerSignature
                  ? "Signer Signature"
                  : "Signer Initials"}
              </Text>
              {object?.toBeSignedBy && (
                <Text fontWeight={"bold"} fontSize="12px">
                  ({object?.toBeSignedBy})
                </Text>
              )}
            </Stack>
          ) : (
            <Image
              src={object.url}
              height="100%"
              width="100%"
              alt="signature"
              draggable="false"
            />
          )}
        </div>

        {(from === "notary" ||
          from === "business" ||
          from === "edit" ||
          (from === "signer" &&
            object.toBeSignedByUserId === Number(userId))) && (
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              height: "30px",
              width: "30px",
              left: "100%",
            }}
            onClick={() => {
              deleteAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                payload: object,
              });
              emitEvent({
                action: EventAction.delete,
                event: object.type!,
                pageNumber: page,
                payload: object,
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <Image src={deleteIcon} alt="delete icon" height={6} width={6} />
          </div>
        )}
      </Rnd>
    </>
  );
};
export default GetSignature;
