import { Image } from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import deleteIcon from "../../../assets/icons/trash.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { useDimensionsStore } from "../../../store/dimensions";
import { useDocumentStore } from "../../../store/documentsStore";
import { EventAction, useAuditLogsStore } from "../../../store/auditLogs";
import { SessionToolsProps } from "../../../store/zustandType";

const Rectangle = ({ object, page, from, scale }: SessionToolsProps) => {
  const ratio = useDimensionsStore((state) => state.ratio);
  const docId = useDocumentStore((state) => state.currentDocId);
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  return (
    <Rnd
      disableDragging={
        from !== "notary" && from !== "business" && from !== "edit"
      }
      enableResizing={
        from === "notary" || from === "business" || from === "edit"
      }
      bounds="parent"
      scale={scale}
      style={{
        margin: 0,
        padding: 0,
        zIndex: 0,
      }}
      size={{
        height: object.height
          ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 50,
        width: object.width
          ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
            ratio
          : 100,
      }}
      position={{
        x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
        y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
      }}
      onDragStop={(e, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            x: (d.x / (object.ratio ?? 1)) * ratio,
            y: (d.y / (object.ratio ?? 1)) * ratio,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
      onResizeStop={(e, direction, ref, d) => {
        updateAuditLog({
          id: object.id!,
          selectedPage: page,
          type: object.type!,
          auditLog: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
        });
        emitEvent({
          action: EventAction.update,
          event: object.type!,
          pageNumber: page,
          payload: {
            ...object,
            width: ref.clientWidth,
            height: ref.clientHeight,
          },
          sessionId: sessionId,
          socket: socket,
          docId,
        });
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <input
          disabled
          type={"color"}
          value={object.color || "#ffffff"}
          onChange={(e) => {
            updateAuditLog({
              id: object.id!,
              selectedPage: page,
              type: object.type!,
              auditLog: { ...object, color: e.target.value },
            });
            emitEvent({
              action: EventAction.update,
              event: object.type!,
              pageNumber: page,
              payload: { ...object, color: e.target.value },
              sessionId: sessionId,
              socket: socket,
              docId,
            });
          }}
          style={{ width: "100%", height: "100%" }}
          placeholder={"Box"}
        />

        {(from === "notary" || from === "business" || from === "edit") && (
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              height: "30px",
              width: "30px",
              left: "100%",
            }}
            onClick={() => {
              deleteAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                payload: object,
              });
              emitEvent({
                action: EventAction.delete,
                event: object.type!,
                pageNumber: page,
                payload: object,
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <Image src={deleteIcon} alt="delete icon" height={6} width={6} />
          </div>
        )}
      </div>
    </Rnd>
  );
};

export default Rectangle;
