/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { NotaryPlan } from './NotaryPlan';
import {
    NotaryPlanFromJSON,
    NotaryPlanFromJSONTyped,
    NotaryPlanToJSON,
} from './NotaryPlan';
import type { Plan } from './Plan';
import {
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './Plan';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    transactionId: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    status: string;
    /**
     * 
     * @type {Coupon}
     * @memberof Transaction
     */
    coupon?: Coupon;
    /**
     * 
     * @type {Plan}
     * @memberof Transaction
     */
    plan?: Plan;
    /**
     * 
     * @type {NotaryPlan}
     * @memberof Transaction
     */
    notaryPlan?: NotaryPlan;
    /**
     * 
     * @type {User}
     * @memberof Transaction
     */
    user: User;
    /**
     * 
     * @type {object}
     * @memberof Transaction
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    discountCoupenCode?: string;
}

/**
 * Check if a given object implements the Transaction interface.
 */
export function instanceOfTransaction(value: object): value is Transaction {
    if (!('transactionId' in value) || value['transactionId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    return true;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if (json == null) {
        return json;
    }
    return {
        
        'transactionId': json['transactionId'],
        'status': json['status'],
        'coupon': json['coupon'] == null ? undefined : CouponFromJSON(json['coupon']),
        'plan': json['plan'] == null ? undefined : PlanFromJSON(json['plan']),
        'notaryPlan': json['notaryPlan'] == null ? undefined : NotaryPlanFromJSON(json['notaryPlan']),
        'user': UserFromJSON(json['user']),
        'data': json['data'] == null ? undefined : json['data'],
        'role': json['role'],
        'discountCoupenCode': json['discountCoupenCode'] == null ? undefined : json['discountCoupenCode'],
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transactionId': value['transactionId'],
        'status': value['status'],
        'coupon': CouponToJSON(value['coupon']),
        'plan': PlanToJSON(value['plan']),
        'notaryPlan': NotaryPlanToJSON(value['notaryPlan']),
        'user': UserToJSON(value['user']),
        'data': value['data'],
        'role': value['role'],
        'discountCoupenCode': value['discountCoupenCode'],
    };
}

