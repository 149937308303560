/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeLoginLink
 */
export interface StripeLoginLink {
    /**
     * 
     * @type {string}
     * @memberof StripeLoginLink
     */
    loginLink: string;
}

/**
 * Check if a given object implements the StripeLoginLink interface.
 */
export function instanceOfStripeLoginLink(value: object): value is StripeLoginLink {
    if (!('loginLink' in value) || value['loginLink'] === undefined) return false;
    return true;
}

export function StripeLoginLinkFromJSON(json: any): StripeLoginLink {
    return StripeLoginLinkFromJSONTyped(json, false);
}

export function StripeLoginLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeLoginLink {
    if (json == null) {
        return json;
    }
    return {
        
        'loginLink': json['loginLink'],
    };
}

export function StripeLoginLinkToJSON(value?: StripeLoginLink | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'loginLink': value['loginLink'],
    };
}

