import { AuthApi } from "../api";
import { useSessionCreate } from "../store/store";
import { useAuth } from "../utils/use-auth";

const useCreateUser = () => {
  const { sessionDetail } = useSessionCreate();
  const { configuration } = useAuth();

  const sendSynchronously = (): Promise<any> => {
    const promises: any[] = [];
    sessionDetail.signers.forEach((eachSigner) => {
      promises.push(
        new AuthApi(configuration).authControllerRegisterEmail({
          registerEmailDTO: {
            email: eachSigner?.email?.toLowerCase(),
          },
        })
      );
    });
    return Promise.allSettled(promises);
  };
  return sendSynchronously;
};

export default useCreateUser;
