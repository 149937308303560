import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ScribbleBoard from "../../commonComponents/ScribbleBoard";
import { useDocumentStore } from "../../../store/documentsStore";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import { PartialObjectData, useAuditLogsStore } from "../../../store/auditLogs";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDrawingEnd: (data: string | undefined) => void;
  modalTitle?: string;
  object: PartialObjectData;
  page: number;
};

const DrawSignatureModal = ({
  isOpen,
  onClose,
  onOpen,
  onDrawingEnd,
  modalTitle = "Create Your Signature",
  object,
  page,
}: Props) => {
  const docId = useDocumentStore((state) => state.currentDocId);
  const [sessionId, socket] = useAuditLogsStore((state) => [
    state.sessionId,
    state.socket,
  ]);

  const sendAuditLog = (url: string) => {
    emitEvent({
      action: "signed",
      socket: socket,
      sessionId: sessionId,
      pageNumber: page,
      event: object.type!,
      payload: { ...object, url: url },
      docId,
    });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody pb={6}>
          <ScribbleBoard
            canvasWidth={400}
            canvasHeight={250}
            onSendCanvas={(file) => {
              new Promise((resolve) => {
                resolve(onDrawingEnd(file));
                sendAuditLog(file);
              }).then(() => {
                onClose();
              });
            }}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DrawSignatureModal;
