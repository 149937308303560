/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KBALogsDTO
 */
export interface KBALogsDTO {
    /**
     * 
     * @type {object}
     * @memberof KBALogsDTO
     */
    data: object;
    /**
     * 
     * @type {string}
     * @memberof KBALogsDTO
     */
    kbaStatus: string;
}

/**
 * Check if a given object implements the KBALogsDTO interface.
 */
export function instanceOfKBALogsDTO(value: object): value is KBALogsDTO {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('kbaStatus' in value) || value['kbaStatus'] === undefined) return false;
    return true;
}

export function KBALogsDTOFromJSON(json: any): KBALogsDTO {
    return KBALogsDTOFromJSONTyped(json, false);
}

export function KBALogsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): KBALogsDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'kbaStatus': json['kbaStatus'],
    };
}

export function KBALogsDTOToJSON(value?: KBALogsDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'kbaStatus': value['kbaStatus'],
    };
}

