/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InvoicesGraph,
  SessionsGraph,
} from '../models/index';
import {
    InvoicesGraphFromJSON,
    InvoicesGraphToJSON,
    SessionsGraphFromJSON,
    SessionsGraphToJSON,
} from '../models/index';

export interface DashboardControllerSessionsGraphRequest {
    startDate: string;
    endDate: string;
}

/**
 * 
 */
export class DashboardApisApi extends runtime.BaseAPI {

    /**
     */
    async dashboardControllerInvoiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesGraph>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboard/invoiceGraphs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesGraphFromJSON(jsonValue));
    }

    /**
     */
    async dashboardControllerInvoice(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesGraph> {
        const response = await this.dashboardControllerInvoiceRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardControllerSessionsGraphRaw(requestParameters: DashboardControllerSessionsGraphRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionsGraph>> {
        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling dashboardControllerSessionsGraph().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling dashboardControllerSessionsGraph().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dashboard/sessionsGraphs/{startDate}/{endDate}`.replace(`{${"startDate"}}`, encodeURIComponent(String(requestParameters['startDate']))).replace(`{${"endDate"}}`, encodeURIComponent(String(requestParameters['endDate']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionsGraphFromJSON(jsonValue));
    }

    /**
     */
    async dashboardControllerSessionsGraph(requestParameters: DashboardControllerSessionsGraphRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionsGraph> {
        const response = await this.dashboardControllerSessionsGraphRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
