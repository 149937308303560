/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DefaultResponse,
  ManualPaymentDTO,
  SignDocDTO,
  SignDocResponse,
  SignedPdfDTO,
} from '../models/index';
import {
    DefaultResponseFromJSON,
    DefaultResponseToJSON,
    ManualPaymentDTOFromJSON,
    ManualPaymentDTOToJSON,
    SignDocDTOFromJSON,
    SignDocDTOToJSON,
    SignDocResponseFromJSON,
    SignDocResponseToJSON,
    SignedPdfDTOFromJSON,
    SignedPdfDTOToJSON,
} from '../models/index';

export interface SignedDocumentControllerGetPdfRequest {
    sessionId: number;
}

export interface SignedDocumentControllerSessionPaymentRequest {
    manualPaymentDTO: ManualPaymentDTO;
}

export interface SignedDocumentControllerSigDocumentRequest {
    signDocDTO: SignDocDTO;
}

/**
 * 
 */
export class SignedDocumentApi extends runtime.BaseAPI {

    /**
     */
    async signedDocumentControllerCheckPasscodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signed-document/check/certificatePasscode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultResponseFromJSON(jsonValue));
    }

    /**
     */
    async signedDocumentControllerCheckPasscode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultResponse> {
        const response = await this.signedDocumentControllerCheckPasscodeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async signedDocumentControllerGetPdfRaw(requestParameters: SignedDocumentControllerGetPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SignedPdfDTO>>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling signedDocumentControllerGetPdf().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/signed-document/pdf/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SignedPdfDTOFromJSON));
    }

    /**
     */
    async signedDocumentControllerGetPdf(requestParameters: SignedDocumentControllerGetPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SignedPdfDTO>> {
        const response = await this.signedDocumentControllerGetPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signedDocumentControllerSessionPaymentRaw(requestParameters: SignedDocumentControllerSessionPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignDocResponse>> {
        if (requestParameters['manualPaymentDTO'] == null) {
            throw new runtime.RequiredError(
                'manualPaymentDTO',
                'Required parameter "manualPaymentDTO" was null or undefined when calling signedDocumentControllerSessionPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signed-document/sessionPayment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManualPaymentDTOToJSON(requestParameters['manualPaymentDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignDocResponseFromJSON(jsonValue));
    }

    /**
     */
    async signedDocumentControllerSessionPayment(requestParameters: SignedDocumentControllerSessionPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignDocResponse> {
        const response = await this.signedDocumentControllerSessionPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async signedDocumentControllerSigDocumentRaw(requestParameters: SignedDocumentControllerSigDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignDocResponse>> {
        if (requestParameters['signDocDTO'] == null) {
            throw new runtime.RequiredError(
                'signDocDTO',
                'Required parameter "signDocDTO" was null or undefined when calling signedDocumentControllerSigDocument().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signed-document/sign-doc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignDocDTOToJSON(requestParameters['signDocDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignDocResponseFromJSON(jsonValue));
    }

    /**
     */
    async signedDocumentControllerSigDocument(requestParameters: SignedDocumentControllerSigDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignDocResponse> {
        const response = await this.signedDocumentControllerSigDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
