import { useEffect } from "react";
import NotaryMeetingDetails from "../../../../component/Sessions/Notary/NotaryMeetingDetails";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";

const NotaryMeetingPage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  return <NotaryMeetingDetails />;
};

export default NotaryMeetingPage;
