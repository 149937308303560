import { Center, Image, Text } from "@chakra-ui/react";
import Empty from "../../../assets/images/Empty.png";
import NoSession from "../../../assets/images/NoSession.png";

const NoDataFound = ({ text, from }: { text?: string; from?: string }) => {
  return (
    <>
      <Center pt={3}>
        <Image
          src={from === "session" ? NoSession : Empty}
          height={from === "session" ? "90px" : "120px"}
          width={from === "session" ? "90px" : "120px"}
        />
      </Center>
      <Text textAlign="center" mt={2} fontWeight={500}>
        {text ? text : "No Data Found"}
      </Text>
    </>
  );
};

export default NoDataFound;
