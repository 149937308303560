/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccountResponseDTO
 */
export interface BankAccountResponseDTO {
    /**
     * 
     * @type {Array<object>}
     * @memberof BankAccountResponseDTO
     */
    data: Array<object>;
}

/**
 * Check if a given object implements the BankAccountResponseDTO interface.
 */
export function instanceOfBankAccountResponseDTO(value: object): value is BankAccountResponseDTO {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BankAccountResponseDTOFromJSON(json: any): BankAccountResponseDTO {
    return BankAccountResponseDTOFromJSONTyped(json, false);
}

export function BankAccountResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccountResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function BankAccountResponseDTOToJSON(value?: BankAccountResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
    };
}

