/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';

/**
 * 
 * @export
 * @interface KBALogs
 */
export interface KBALogs {
    /**
     * 
     * @type {number}
     * @memberof KBALogs
     */
    kbaLogId: number;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof KBALogs
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {object}
     * @memberof KBALogs
     */
    idologyResponse: object;
    /**
     * 
     * @type {object}
     * @memberof KBALogs
     */
    scanResponse?: object;
    /**
     * 
     * @type {number}
     * @memberof KBALogs
     */
    idologyIdNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof KBALogs
     */
    kbaStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof KBALogs
     */
    sessionScanTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof KBALogs
     */
    firstScanImage?: string;
    /**
     * 
     * @type {string}
     * @memberof KBALogs
     */
    firstBackScanImage?: string;
    /**
     * 
     * @type {Date}
     * @memberof KBALogs
     */
    createdDate: Date;
    /**
     * 
     * @type {object}
     * @memberof KBALogs
     */
    kbaAnswersData?: object;
}

/**
 * Check if a given object implements the KBALogs interface.
 */
export function instanceOfKBALogs(value: object): value is KBALogs {
    if (!('kbaLogId' in value) || value['kbaLogId'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('idologyResponse' in value) || value['idologyResponse'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    return true;
}

export function KBALogsFromJSON(json: any): KBALogs {
    return KBALogsFromJSONTyped(json, false);
}

export function KBALogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): KBALogs {
    if (json == null) {
        return json;
    }
    return {
        
        'kbaLogId': json['kbaLogId'],
        'user': OmitTypeClassFromJSON(json['user']),
        'idologyResponse': json['idologyResponse'],
        'scanResponse': json['scanResponse'] == null ? undefined : json['scanResponse'],
        'idologyIdNumber': json['idologyIdNumber'] == null ? undefined : json['idologyIdNumber'],
        'kbaStatus': json['kbaStatus'] == null ? undefined : json['kbaStatus'],
        'sessionScanTime': json['sessionScanTime'] == null ? undefined : (new Date(json['sessionScanTime'])),
        'firstScanImage': json['firstScanImage'] == null ? undefined : json['firstScanImage'],
        'firstBackScanImage': json['firstBackScanImage'] == null ? undefined : json['firstBackScanImage'],
        'createdDate': (new Date(json['createdDate'])),
        'kbaAnswersData': json['kbaAnswersData'] == null ? undefined : json['kbaAnswersData'],
    };
}

export function KBALogsToJSON(value?: KBALogs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'kbaLogId': value['kbaLogId'],
        'user': OmitTypeClassToJSON(value['user']),
        'idologyResponse': value['idologyResponse'],
        'scanResponse': value['scanResponse'],
        'idologyIdNumber': value['idologyIdNumber'],
        'kbaStatus': value['kbaStatus'],
        'sessionScanTime': value['sessionScanTime'] == null ? undefined : ((value['sessionScanTime']).toISOString()),
        'firstScanImage': value['firstScanImage'],
        'firstBackScanImage': value['firstBackScanImage'],
        'createdDate': ((value['createdDate']).toISOString()),
        'kbaAnswersData': value['kbaAnswersData'],
    };
}

