/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterResponseDTO
 */
export interface RegisterResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof RegisterResponseDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    businessName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    coupon?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterResponseDTO
     */
    password: string;
}

/**
 * Check if a given object implements the RegisterResponseDTO interface.
 */
export function instanceOfRegisterResponseDTO(value: object): value is RegisterResponseDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('businessName' in value) || value['businessName'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function RegisterResponseDTOFromJSON(json: any): RegisterResponseDTO {
    return RegisterResponseDTOFromJSONTyped(json, false);
}

export function RegisterResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'role': json['role'],
        'businessName': json['businessName'],
        'token': json['token'],
        'coupon': json['coupon'] == null ? undefined : json['coupon'],
        'accessToken': json['accessToken'],
        'password': json['password'],
    };
}

export function RegisterResponseDTOToJSON(value?: RegisterResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'role': value['role'],
        'businessName': value['businessName'],
        'token': value['token'],
        'coupon': value['coupon'],
        'accessToken': value['accessToken'],
        'password': value['password'],
    };
}

