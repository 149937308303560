//@ts-nocheck
import {
  AuditLogs,
  AuditLogsApi,
  Business,
  Configuration,
  FileUploadApi,
  KBALogs,
  KbaLogsApi,
  MeetingMediatorApi,
  Notary,
  NotaryApi,
  Session,
  SessionApi,
  SignedDocumentApi,
  SignedPdfDTO,
  Signer,
  SignerApi,
  UsersApi,
} from "../api";
import {
  IMediator,
  ISessionDetails,
  IUsersData,
  IWitness,
} from "../store/zustandType";
const UserID = localStorage.getItem("UserID");

export const getNotaryDetails = async (configuration: Configuration) => {
  return new Promise<Notary>((resolve, reject) => {
    new NotaryApi(configuration)
      .notaryControllerGet()
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSignerDetails = async (configuration: Configuration) => {
  return new Promise<Signer>((resolve, reject) => {
    new SignerApi(configuration)
      .signerControllerGetProfile()
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSignerDetailsByUserId = async (
  configuration: Configuration,
  userId: number
) => {
  return new Promise<Signer>((resolve, reject) => {
    new SignerApi(configuration)
      .signerControllerGetProfileUser({
        userId: userId,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getBusinessDetails = async (configuration: Configuration) => {
  return new Promise<Business>((resolve, reject) => {
    new SignerApi(configuration)
      .signerControllerGetBusiness({
        userId: Number(UserID) ?? "",
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSessionDetails = async (
  configuration: Configuration,
  sessionId: number
) => {
  return new Promise<Session>((resolve, reject) => {
    new SessionApi(configuration)
      .sessionControllerGetById({
        sessionId: sessionId,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getFileUrl = async (
  configuration: Configuration,
  file: string
) => {
  return new Promise<string>((resolve, reject) => {
    new FileUploadApi(configuration)
      .fileControllerUrlS3({
        key: file,
      })
      .then((res) => {
        resolve(res?.url);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getKbaLogDetail = async (
  configuration: Configuration,
  id: number
) => {
  return new Promise<KBALogs>((resolve, reject) => {
    new KbaLogsApi(configuration)
      .kBAControllerGetKbaById({
        kbaId: id,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const LogData = async (
  configuration: Configuration,
  kbaLogId: number
) => {
  const res = await getKbaLogDetail(configuration, Number(kbaLogId));
  return res;
};

export const getAuditLogs = async (
  configuration: Configuration,
  id: number
) => {
  return new Promise<AuditLogs[]>((resolve, reject) => {
    new AuditLogsApi(configuration)
      .auditLogsControllerGetAudiLogsBySessionId({
        sessionId: id,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const AuditData = async (configuration: Configuration, id: number) => {
  const res = await getAuditLogs(configuration, Number(id));
  return res;
};

export const auditFormCount = async (
  configuration: Configuration,
  id: number
) => {
  const res = await getAuditLogs(configuration, Number(id));
  const formData = res?.filter((el) => el.event === "formAdded");

  const totalJurat = formData?.filter(
    //  @ts-ignore
    (el) => el.payload?.typeOfForm === "Jurat"
  )?.length;

  const totalAcknowlegement = formData?.filter(
    //  @ts-ignore
    (el) => el.payload?.typeOfForm === "Acknowlegement"
  )?.length;

  return { totalJurat, totalAcknowlegement };
};

export const getSignedDocPath = async (
  configuration: Configuration,
  sessionId: number
) => {
  return new Promise<SignedPdfDTO[]>((resolve, reject) => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerGetPdf({
        sessionId: sessionId,
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getSignerIds = async (
  sessionDetail: ISessionDetails,
  UsersData: IUsersData[],
  configuration: Configuration,
  from: string
) => {
  const signerResponseApi = (): Promise<any> => {
    const promises: any[] = [];
    sessionDetail.signers.forEach((signer, idx) => {
      const createDataToSend = {
        firstName: signer?.firstName,
        lastName: signer?.lastName,
        phoneNumber: signer?.phoneNumber?.replace(/[^\d]/g, ""),
        email: signer?.email?.toLowerCase(),
        addressLine1: signer?.addressLine1,
        addressLine2: signer?.addressLine2,
        dob: signer?.dob,
        city: signer?.city,
        zip: Number(signer?.zip),
        state: signer?.state,
        lastFourDigitsSsnNumber: signer?.lastFourDigitsSsnNumber,
      };

      const updateDataToSend = {
        firstName: signer?.firstName,
        lastName: signer?.lastName,
        email: signer?.email?.toLowerCase(),
        phoneNumber: signer?.phoneNumber?.replace(/[^\d]/g, ""),
      };

      if (from === "signer") {
        promises.push(
          new SignerApi(configuration).signerControllerCreate({
            signerDTO: createDataToSend,
          })
        );
      } else {
        promises.push(
          new SignerApi(configuration).signerControllerCreate({
            signerDTO: updateDataToSend,
          })
        );
      }
    });

    return Promise.allSettled(promises);
  };

  const SignerIDs = await (
    await signerResponseApi()
  ).map((each: Promise<any>) =>
    //@ts-ignore
    each?.value?.signerId?.toString()
  );
  return SignerIDs;
};

export const createMediator = async (
  mediators: IMediator[],
  configuration: Configuration
) => {
  const mediatorResponseApi = (): Promise<any> => {
    const promises: any[] = [];
    mediators.forEach((el) => {
      promises.push(
        new MeetingMediatorApi(configuration).mediatorControllerPost({
          mediatorDTO: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email?.toLowerCase(),
          },
        })
      );
    });
    return Promise.allSettled(promises);
  };
  const MediatorIDs = await (
    await mediatorResponseApi()
  ).map((each: Promise<any>) =>
    //@ts-ignore
    each?.value?.mediatorId?.toString()
  );
  return MediatorIDs;
};

export const createWitness = async (
  witnesses: IWitness[],
  configuration: Configuration
) => {
  const witnesResponseApi = (): Promise<any> => {
    const promises: any[] = [];
    witnesses.forEach((el) => {
      promises.push(
        new MeetingMediatorApi(configuration).mediatorControllerWitnessPost({
          witnessDTO: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email?.toLowerCase(),
            witnessPhone: Number(el?.phoneNumber?.replace(/[^\d]/g, "")),
          },
        })
      );
    });
    return Promise.allSettled(promises);
  };
  const WitnessIDs = await (
    await witnesResponseApi()
  ).map((each: Promise<any>) =>
    //@ts-ignore
    each?.value?.witnessId?.toString()
  );
  return WitnessIDs;
};

export const createSession = async (
  sessionDetail: ISessionDetails,
  users: any,
  configuration: Configuration,
  from: string,
  isInhouseNotary: boolean,
  kbaLogId?: string
) => {
  const UsersData = await users.map((each: any) => {
    return {
      id: each?.value.id,
      token: each?.value?.token,
    };
  });

  const SignerIDs = await getSignerIds(
    sessionDetail,
    UsersData,
    configuration,
    "signer"
  );

  const WitnessIDs =
    sessionDetail?.needWitness === "yes"
      ? await createWitness(
          sessionDetail?.witnesses as IWitness[],
          configuration
        )
      : [];

  const MediatorIDs = sessionDetail?.needMediator
    ? await createMediator(
        sessionDetail?.mediatores as IMediator[],
        configuration
      )
    : [];

  const primarySignerUserId = await new UsersApi(configuration)
    .usersControllerGetUser({
      email: sessionDetail?.signers?.[0]?.email?.toLowerCase(),
    })
    .then((res) => {
      return res?.userId;
    });

  const NotaryID =
    from === "notary"
      ? await new NotaryApi(configuration)
          .notaryControllerFindOne({
            userId: UserID ?? "",
          })
          .then((res) => {
            return res.notaryId;
          })
      : undefined;

  return new Promise<Session>((resolve, reject) => {
    new SessionApi(configuration)
      .sessionControllerCreate({
        sessionDTO: {
          signers: SignerIDs,
          mediators: MediatorIDs,
          witness: sessionDetail?.needWitness === "yes" ? WitnessIDs : [],
          witnessesCount: sessionDetail?.witnessesCount,
          primarySignerUserId: primarySignerUserId,
          primarySignerName: sessionDetail?.primarySignerName,
          specificNotaryState: sessionDetail?.specificNotaryState,
          paymentBy: sessionDetail?.paymentBy,
          documentFees: sessionDetail.documentFees,
          notaryNote: sessionDetail.notaryNote,
          isInhouseNotary: isInhouseNotary,
          isDemoSession: sessionDetail.isDemoSession,
          isTitleLenderSession: sessionDetail.isTitleLenderSession,
          isNotaryExemplarSheet: false,
          documents: sessionDetail.documents.map((doc) =>
            doc.docId?.toString()
          ),
          scheduleDate: sessionDetail?.isSchedule
            ? new Date(sessionDetail?.scheduleDate as string).toUTCString()
            : "",
          notary:
            from === "notary"
              ? NotaryID
              : sessionDetail.notaryId !== ""
              ? Number(sessionDetail.notaryId)
              : undefined,
          kbaLogs:
            from === "signer"
              ? UsersData.map((item: any, idx: number) => {
                  return {
                    userId: UsersData[idx]?.id,
                    kbaLogId: idx === 0 ? kbaLogId : "",
                  };
                })
              : sessionDetail?.signers?.map((el, idx) => {
                  return {
                    userId: UsersData[idx]?.id as number,
                    kbaLogId: (el?.kbaStatus === "pending"
                      ? ""
                      : el?.kbaStatus) as string,
                  };
                }),
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
