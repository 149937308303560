import { useEffect, useState } from "react";
import {
  Heading,
  Stack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Box,
  useDisclosure,
  Center,
  Spinner,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import Select from "react-select";
import FlatList from "flatlist-react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import ListDetailModal from "./ListDetailModal";
import { getStatusBgColor } from "../../utils/utils";
import useNotification from "../../utils/useNotification";
import NoDataFound from "../commonComponents/LoadingComponents/NoDataFound";
import { LimitOptions } from "../../utils/options";
import { IFilter, IReactSelect } from "../../store/zustandType";
import ListHeader from "../commonComponents/ListComponents/ListHeader";
import { ReactComponent as Action } from "../../assets/icons/action.svg";
import KbaVerification from "../commonComponents/KbaVerificationModal/KbaVerification";
import {
  CustomerApi,
  Session,
  SessionApi,
  SignedDocumentApi,
  SignedPdfDTO,
  Signer,
  TransactionApi,
} from "../../api";

const SessionInfoList = ({ from }: { from?: string }) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const role = localStorage?.getItem("role");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Session[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [showSession, setshowSession] = useState<Session>();
  const [signers, setSigners] = useState<Signer[]>([]);
  const [signedDocPath, setSignedDocPath] = useState<SignedPdfDTO[]>([]);
  const [sessionId, setSessionId] = useState<number>();
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const {
    isOpen: isKbaOpen,
    onOpen: onKbaOpen,
    onClose: onKbaClose,
  } = useDisclosure();

  const [filters, setFilters] = useState<IFilter>({
    searchTerm: "",
    date: "",
  });

  const [limit, setLimit] = useState<IReactSelect>({
    label: 10,
    value: 10,
  });

  const [status, setStatus] = useState<IReactSelect>({
    label: "All",
    value: "all",
  });

  useEffect(() => {
    if (role === "signer") {
      setLoading(true);
      new CustomerApi(configuration)
        .userControllerGetCutomerByUserId()
        .then((res) => {
          new TransactionApi(configuration)
            .transactionControllerGetPaymentMethod({
              customerId: res.customerId,
            })
            .then((res) => {
              if (res.cards.length !== 0) {
                setIsDisable(false);
              }
              setLoading(false);
            });
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    new SessionApi(configuration)
      .sessionControllerGetAllPaginated2({
        page: 1,
        limit: from === "dashboard" ? 5 : Number(limit?.value),
        search: filters?.searchTerm,
        status: status?.value === "all" ? "" : (status?.value as string),
      })
      .then((res) => {
        setData(res?.items);
        //@ts-ignore
        setTotalPages(res.meta?.totalPages);
        setLoading(false);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        notification("fail", message.errorMessage);
        setData([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, filters?.searchTerm, status?.value]);

  const handlePageChange = (event: { selected: number }) => {
    new SessionApi(configuration)
      .sessionControllerGetAllPaginated2({
        page: event.selected + 1,
        limit: Number(limit?.value),
        search: filters?.searchTerm,
        status: status?.value === "all" ? "" : (status?.value as string),
      })
      .then((res) => {
        setData(res?.items);
      });
  };

  const getSignedDocPath = (sessionId: number) => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerGetPdf({
        sessionId: sessionId,
      })
      .then((res) => {
        setSignedDocPath(res);
      })
      .catch((err) => {
        setSignedDocPath([]);
      });
  };

  const handleAction = (id: number) => {
    getSignedDocPath(id);
    onOpen();
    new SessionApi(configuration)
      .sessionControllerGetJournalSessions({
        sessionId: id,
      })
      .then((res) => {
        setSigners(res?.signers);
      });
  };

  const listData = (item: Session) => (
    <Tr style={{ fontWeight: 500 }}>
      <Td style={{ color: "#9A9595" }}>{item?.sessionId}</Td>
      <Td>
        <Text
          py={"4px"}
          fontSize="12px"
          textAlign="center"
          textTransform="capitalize"
          borderRadius="20px"
          width="80px"
          color="#fff"
          style={{
            background: getStatusBgColor(item?.status),
          }}
        >
          {item?.status}
        </Text>
      </Td>
      <Td style={{ color: "#9A9595", textTransform: "capitalize" }}>
        {item?.signers[0]?.firstName + " "} {item?.signers[0]?.lastName}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {moment(item?.createdDate).format("MM-DD-YYYY")}
      </Td>
      <Td>
        <Flex>
          <Box
            ml={2.5}
            role="button"
            onClick={() => {
              handleAction(item.sessionId);
              setshowSession(item);
            }}
          >
            <Action />
          </Box>

          {(item?.status === "booked" || item?.status === "pending") &&
            !isDisable &&
            role === "signer" && (
              <Button
                ml={3}
                width={"fit-content"}
                style={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#000",
                  padding: "0px 10px",
                  height: "25px",
                }}
                onClick={() => {
                  setSessionId(item?.sessionId);
                  const kbaLogId = item?.kbaLogs?.find(
                    (el) =>
                      Number(el?.userId) ===
                      Number(localStorage.getItem("UserID"))
                  )?.kbaLogId;
                  if (
                    kbaLogId === "" ||
                    kbaLogId === undefined ||
                    kbaLogId === "pending" ||
                    kbaLogId === "demo"
                  ) {
                    onKbaOpen();
                  } else {
                    navigate(
                      `/signer/on-demand/waiting-for-notary/?session-id=${item?.sessionId}`
                    );
                  }
                }}
              >
                Join
              </Button>
            )}
        </Flex>
      </Td>
    </Tr>
  );

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"} position="relative">
      {from !== "dashboard" && (
        <>
          <Heading
            fontSize={"22px"}
            lineHeight="30px"
            textAlign={"center"}
            mb={4}
            fontFamily="Oswald"
            fontWeight={600}
            color={"#2D3748"}
          >
            Sessions Information
          </Heading>

          <ListHeader
            status={status}
            filters={filters}
            onSetFilters={(value) => setFilters(value)}
            onSetStatus={(value) => setStatus(value)}
          />
        </>
      )}

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : data?.length === 0 ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer
            height={{ base: "392px", md: "unset", lg: "unset" }}
            style={{ overflow: "auto" }}
          >
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th style={{ color: "#000" }}>SESSION ID</Th>
                  <Th style={{ color: "#000" }}>STATUS</Th>
                  <Th style={{ color: "#000" }}>PRIMARY SIGNER</Th>
                  <Th style={{ color: "#000" }}>DATE</Th>
                  <Th style={{ color: "#000" }}>ACTIONS</Th>
                </Tr>
              </Thead>
              <Tbody>
                <FlatList
                  keyExtractor={(item: any, index: number) => index.toString()}
                  list={data}
                  renderItem={listData}
                  renderWhenEmpty={() => (
                    <div style={{ marginTop: "10px" }}>No Items</div>
                  )}
                />
              </Tbody>
            </Table>
          </TableContainer>
          {from !== "dashboard" && (
            <Flex justifyContent={"space-between"}>
              <Box display={{ base: "none", md: "unset", lg: "unset" }}></Box>

              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageChange}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                previousLabel="< Prev"
                containerClassName={"pagination"}
                activeClassName={"active"}
              />

              <Box width={"90px"} mt={3}>
                <Select
                  placeholder="Select"
                  menuPlacement="top"
                  options={LimitOptions}
                  value={limit}
                  onChange={(option) => setLimit(option)}
                />
              </Box>
            </Flex>
          )}
        </>
      )}

      <ListDetailModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        session={showSession}
        signedDocPath={signedDocPath}
        signers={signers}
      />

      <KbaVerification
        isOpen={isKbaOpen}
        onClose={onKbaClose}
        onOpen={onKbaOpen}
        from="signer"
        sessionId={sessionId}
      />
    </Stack>
  );
};

export default SessionInfoList;
