/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayoutToNotaryDTO
 */
export interface PayoutToNotaryDTO {
    /**
     * 
     * @type {number}
     * @memberof PayoutToNotaryDTO
     */
    notaryId: number;
    /**
     * 
     * @type {number}
     * @memberof PayoutToNotaryDTO
     */
    payoutAmount: number;
}

/**
 * Check if a given object implements the PayoutToNotaryDTO interface.
 */
export function instanceOfPayoutToNotaryDTO(value: object): value is PayoutToNotaryDTO {
    if (!('notaryId' in value) || value['notaryId'] === undefined) return false;
    if (!('payoutAmount' in value) || value['payoutAmount'] === undefined) return false;
    return true;
}

export function PayoutToNotaryDTOFromJSON(json: any): PayoutToNotaryDTO {
    return PayoutToNotaryDTOFromJSONTyped(json, false);
}

export function PayoutToNotaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutToNotaryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'notaryId': json['notaryId'],
        'payoutAmount': json['payoutAmount'],
    };
}

export function PayoutToNotaryDTOToJSON(value?: PayoutToNotaryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notaryId': value['notaryId'],
        'payoutAmount': value['payoutAmount'],
    };
}

