import { useEffect } from "react";
import NotaryNotes from "../../../../component/Sessions/Notary/NotaryNotes";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";

const NotaryNotePage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(4);
  }, [setStep]);

  return <NotaryNotes />;
};

export default NotaryNotePage;
