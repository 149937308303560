import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiEditAlt as EditIcon } from "react-icons/bi";
import EditModal from "../EditModal";
import { handleError } from "../../../utils/utils-functions";
import { useAuth } from "../../../utils/use-auth";
import { notaryPaths } from "../../../routes/routes";
import { getStatusBgColor } from "../../../utils/utils";
import { ActiveSessionApi, Session } from "../../../api";
import useNotification from "../../../utils/useNotification";

const NotarySessionTable = ({
  from,
  isActive,
  sessions,
  startSession,
}: {
  from?: string;
  isActive: boolean;
  sessions: Session[];
  startSession: (sessionID: number) => void;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sessionId, setSessionId] = useState<number>();
  const [isDisable, setDisable] = useState<boolean>(false);

  const handleStartSession = (sessionId: number) => {
    startSession(sessionId);
    if (from === "notary") {
      setDisable(true);
      new ActiveSessionApi(configuration)
        .activeSessionControllerCreate({
          sessionBodyDTO: {
            session: Number(sessionId),
          },
        })
        .then((res) => {
          if (
            res.session.notary?.user.id.toString() !==
            (localStorage.getItem("UserID") || "")
          ) {
            notification("warn", "session grabbed by another Notary 😔");
          } else {
            navigate(
              `${notaryPaths.notaryOndemand}/notary-session?session-id=${sessionId}`
            );
            setDisable(false);
          }
        })
        .catch(async (err) => {
          const message = await handleError(err);
          if (message?.statusCode === 500) {
            notification("fail", "Notary already exists in this session.");
          }
          setDisable(false);
        });
    }
  };

  return (
    <>
      <TableContainer
        overflowY={"auto"}
        maxHeight={isActive ? "100%" : "240px"}
      >
        <Table size="sm">
          <Thead>
            <Tr>
              <Th color={"#000"}>SESSION ID</Th>
              {isActive && <Th color={"#000"}>STATUS</Th>}
              <Th color={"#000"}>PRIMARY SIGNER</Th>
              <Th color={"#000"}>DATE</Th>
              <Th color={"#000"} display={"flex"} justifyContent={"center"}>
                ACTIONS
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sessions
              .sort((a, b) => (a?.createdDate < b?.createdDate ? 1 : -1))
              .flatMap((item, idx) => {
                const primarySigner = item.signers?.find(
                  (el) => el?.user?.id === item.primarySignerUserId
                );

                return (
                  <Tr role="button" fontWeight={500}>
                    <Td color="#9A9595">{item.sessionId}</Td>
                    {isActive && (
                      <Td>
                        <Text
                          py={"4px"}
                          fontSize="12px"
                          textAlign="center"
                          textTransform="capitalize"
                          borderRadius="20px"
                          width="80px"
                          color="#fff"
                          style={{
                            background: getStatusBgColor(item.status),
                          }}
                        >
                          {item.status}
                        </Text>
                      </Td>
                    )}

                    <Td color="#9A9595" textTransform="capitalize">
                      {primarySigner !== undefined
                        ? primarySigner?.firstName
                          ? `${primarySigner?.firstName?.toLowerCase()} ${primarySigner?.lastName?.toLowerCase()}`
                          : primarySigner?.email
                        : item.signers?.[0]?.firstName
                        ? `${item.signers?.[0]?.firstName?.toLowerCase()} ${item.signers?.[0]?.lastName?.toLowerCase()}`
                        : item.primarySignerName}
                    </Td>

                    <Td color="#9A9595">
                      {moment(item.createdDate).format("MM-DD-YYYY")}
                    </Td>
                    <Td display={"flex"} justifyContent={"center"}>
                      <Button
                        width={"fit-content"}
                        style={{
                          background:
                            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                          color: "#000",
                          padding: "0px 10px",
                          height: "27px",
                        }}
                        isDisabled={
                          from === "business" && item.status === "pending"
                            ? true
                            : isDisable
                        }
                        onClick={() => handleStartSession(item.sessionId)}
                      >
                        Join
                      </Button>

                      {isActive &&
                        (from === "notary" || from === "business") && (
                          <Button
                            width={"fit-content"}
                            ml={2}
                            style={{
                              background:
                                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                              color: "#000",
                              padding: "0px 10px",
                              height: "27px",
                            }}
                            onClick={() => {
                              setSessionId(item.sessionId);
                              onOpen();
                            }}
                          >
                            <EditIcon style={{ marginRight: "4px" }} /> Prepare
                            Document
                          </Button>
                        )}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>

      <EditModal
        sessionId={sessionId}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

export default NotarySessionTable;
