import { Image, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import deleteIcon from "../../../assets/icons/trash.svg";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import DrawSignatureModal from "../modals/DrawSignatureModal";
import { useDimensionsStore } from "../../../store/dimensions";
import { useDocumentStore } from "../../../store/documentsStore";
import { SessionToolsProps } from "../../../store/zustandType";
import {
  EventAction,
  EventType,
  useAuditLogsStore,
} from "../../../store/auditLogs";

const Scribble = ({ from, object, page, scale }: SessionToolsProps) => {
  const ratio = useDimensionsStore((state) => state.ratio);
  const docId = useDocumentStore((state) => state.currentDocId);
  const [drawingData, setDrawingData] = useState<string | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAuditLog, updateAuditLog, sessionId, socket] = useAuditLogsStore(
    (state) => [
      state.deleteAuditLog,
      state.updateAuditLog,
      state.sessionId,
      state.socket,
    ]
  );

  useEffect(() => {
    if (drawingData !== undefined) {
      console.log(drawingData);
      updateAuditLog({
        id: object.id!,
        selectedPage: page,
        type: object.type!,
        auditLog: {
          ...object,
          url: drawingData,
        },
      });
      emitEvent({
        action: EventAction.update,
        socket: socket,
        sessionId: sessionId,
        pageNumber: page,
        event: object.type!,
        payload: {
          ...object,
          url: drawingData,
        },
        docId,
      });
    }
  }, [drawingData]);

  return (
    <>
      <DrawSignatureModal
        modalTitle="Scribble"
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        object={object}
        page={page}
        onDrawingEnd={(data: string | undefined) => setDrawingData(data)}
      />
      <Rnd
        disableDragging={
          from !== "notary" && from !== "business" && from !== "edit"
        }
        enableResizing={
          from === "notary" || from === "business" || from === "edit"
        }
        bounds="parent"
        scale={scale}
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px dashed #99ff9950",
          backgroundColor: "#99ff9950",
          zIndex: 1,
        }}
        size={{
          height: object.height
            ? (object.height * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
              ratio
            : 200,
          width: object.width
            ? (object.width * (object.zoomScale ?? 1) * (object.ratio ?? 1)) /
              ratio
            : 200,
        }}
        position={{
          x: object.x ? (object.x * (object.ratio ?? 1)) / ratio : 0,
          y: object.y ? (object.y * (object.ratio ?? 1)) / ratio : 0,
        }}
        onDragStop={(e, d) => {
          updateAuditLog({
            id: object.id!,
            selectedPage: page,
            type: object.type!,
            auditLog: {
              ...object,
              x: (d.x / (object.ratio ?? 1)) * ratio,
              y: (d.y / (object.ratio ?? 1)) * ratio,
            },
          });
          emitEvent({
            action: EventAction.update,
            event: object.type!,
            pageNumber: page,
            payload: {
              ...object,
              x: (d.x / (object.ratio ?? 1)) * ratio,
              y: (d.y / (object.ratio ?? 1)) * ratio,
            },
            sessionId: sessionId,
            socket: socket,
            docId,
          });
        }}
        onResizeStop={(e, direction, ref, d) => {
          updateAuditLog({
            id: object.id!,
            selectedPage: page,
            type: object.type!,
            auditLog: {
              ...object,
              width: ref.clientWidth,
              height: ref.clientHeight,
            },
          });
          emitEvent({
            action: EventAction.update,
            event: object.type!,
            pageNumber: page,
            payload: {
              ...object,
              width: ref.clientWidth,
              height: ref.clientHeight,
            },
            sessionId: sessionId,
            socket: socket,
            docId,
          });
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
          }}
          onClick={() => {
            onOpen();
          }}
        >
          {object.url === undefined ? (
            object.type === EventType.scribble ? (
              <Stack>
                <Text fontWeight={"medium"}>Scribble</Text>
              </Stack>
            ) : null
          ) : (
            <Image src={object.url} alt="signature" draggable="false" />
          )}
        </div>
        {(from === "notary" || from === "business" || from === "edit") && (
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              height: "30px",
              width: "30px",
              left: "100%",
            }}
            onClick={() => {
              deleteAuditLog({
                id: object.id!,
                selectedPage: page,
                type: object.type!,
                payload: object,
              });
              emitEvent({
                action: EventAction.delete,
                event: object.type!,
                pageNumber: page,
                payload: object,
                sessionId: sessionId,
                socket: socket,
                docId,
              });
            }}
          >
            <Image src={deleteIcon} alt="delete icon" height={6} width={6} />
          </div>
        )}
      </Rnd>
    </>
  );
};
export default Scribble;
