import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsBank2, BsFillCreditCardFill } from "react-icons/bs";
import { GrAddCircle } from "react-icons/gr";
import { IoIosArrowForward as ArrowIcon } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { NotaryApi } from "../../../../../api";
import { handleError } from "../../../../../utils/utils-functions";
import { useAuth } from "../../../../../utils/use-auth";
import useNotification from "../../../../../utils/useNotification";
import { getMonth } from "../../../../../utils/utils";
import Card from "../../../../Card";

const AccountCard = () => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [accountDetails, setAccountDetails] = useState<object[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    new NotaryApi(configuration)
      .notaryControllerGetAccount()
      .then((res) => {
        setLoading(false);
        setAccountDetails(res?.data);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        setLoading(false);
        setAccountDetails(undefined);
        console.log("msg", message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddAccount = () => {
    new NotaryApi(configuration)
      .notaryControllerStripePyout()
      .then((res) => {
        if (res?.url) {
          window.location.href = res?.url;
        }
      })
      .catch((err) => {
        notification("fail", err);
      });
  };

  return (
    <Card title="My Stripe Account">
      {loading ? (
        <Flex alignItems={"center"} mt={3}>
          <Box borderRadius={"full"} background="#D5AD36" p={2}>
            <BsBank2 size={22} />
          </Box>
          <Box>
            <Text ml={2} fontWeight={500}>
              Loading...
            </Text>
          </Box>
        </Flex>
      ) : accountDetails === undefined ? (
        <Button
          variant="link"
          p={0}
          color="#000"
          mt={4}
          onClick={handleAddAccount}
        >
          <GrAddCircle size={22} />
          <Text ml={2} fontWeight={500}>
            Add Account
          </Text>
        </Button>
      ) : (
        <Flex
          alignItems={"center"}
          justifyContent="space-between"
          mt={3}
          role={"button"}
          onClick={() => navigate("settings/profile/payment-info")}
        >
          {/* @ts-ignore */}
          {accountDetails?.object === "bank_account" ? (
            <Flex alignItems={"center"}>
              <Box borderRadius={"full"} background="#D5AD36" p={2}>
                <BsBank2 size={22} />
              </Box>

              <Box>
                <Text ml={2} fontWeight={500}>
                  {/* @ts-ignore */}
                  {accountDetails?.bank_name}
                </Text>
                <Text ml={2} fontWeight={500}>
                  {/* @ts-ignore  */}
                  ************{accountDetails?.last4}
                </Text>
              </Box>
            </Flex>
          ) : (
            <Flex alignItems={"center"}>
              <Box borderRadius={"full"} background="#D5AD36" p={2}>
                <BsFillCreditCardFill size={22} />
              </Box>

              <Box>
                <Text ml={2} fontWeight={500}>
                  {/* @ts-ignore */}
                  ************{accountDetails?.last4}
                </Text>
                <Text ml={2} fontWeight={500}>
                  {/* @ts-ignore  */}
                  {getMonth(accountDetails?.exp_month)},
                  {
                    //  @ts-ignore
                    accountDetails?.exp_year
                  }
                </Text>
              </Box>
            </Flex>
          )}

          <ArrowIcon size={22} />
        </Flex>
      )}
    </Card>
  );
};

export default AccountCard;
