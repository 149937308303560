/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManuallUpdateDto
 */
export interface ManuallUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ManuallUpdateDto
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ManuallUpdateDto
     */
    planId: number;
}

/**
 * Check if a given object implements the ManuallUpdateDto interface.
 */
export function instanceOfManuallUpdateDto(value: object): value is ManuallUpdateDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('planId' in value) || value['planId'] === undefined) return false;
    return true;
}

export function ManuallUpdateDtoFromJSON(json: any): ManuallUpdateDto {
    return ManuallUpdateDtoFromJSONTyped(json, false);
}

export function ManuallUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManuallUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'planId': json['planId'],
    };
}

export function ManuallUpdateDtoToJSON(value?: ManuallUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'planId': value['planId'],
    };
}

