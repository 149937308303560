/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConferenceAccessTokenResponseDTO,
  ConferenceInviteDTO,
  ConferenceJoinDTO,
  ConferenceUpdateDTO,
  GetRecordingDTO,
  StartRecordingDTO,
  StopRecordingDTO,
  TerminateConfereneDTO,
  TokenClientResponseDTO,
  TokenOAuthResponseDTO,
} from '../models/index';
import {
    ConferenceAccessTokenResponseDTOFromJSON,
    ConferenceAccessTokenResponseDTOToJSON,
    ConferenceInviteDTOFromJSON,
    ConferenceInviteDTOToJSON,
    ConferenceJoinDTOFromJSON,
    ConferenceJoinDTOToJSON,
    ConferenceUpdateDTOFromJSON,
    ConferenceUpdateDTOToJSON,
    GetRecordingDTOFromJSON,
    GetRecordingDTOToJSON,
    StartRecordingDTOFromJSON,
    StartRecordingDTOToJSON,
    StopRecordingDTOFromJSON,
    StopRecordingDTOToJSON,
    TerminateConfereneDTOFromJSON,
    TerminateConfereneDTOToJSON,
    TokenClientResponseDTOFromJSON,
    TokenClientResponseDTOToJSON,
    TokenOAuthResponseDTOFromJSON,
    TokenOAuthResponseDTOToJSON,
} from '../models/index';

export interface ConferenceControllerEndMeetingRequest {
    terminateConfereneDTO: TerminateConfereneDTO;
}

export interface ConferenceControllerGetRecordingRequest {
    getRecordingDTO: GetRecordingDTO;
}

export interface ConferenceControllerInviteRequest {
    conferenceInviteDTO: ConferenceInviteDTO;
}

export interface ConferenceControllerMeetingTokenRequest {
    conferenceJoinDTO: ConferenceJoinDTO;
}

export interface ConferenceControllerStartRecordingRequest {
    startRecordingDTO: StartRecordingDTO;
}

export interface ConferenceControllerStopRecordingRequest {
    stopRecordingDTO: StopRecordingDTO;
}

export interface ConferenceControllerUpdateMeetingRequest {
    conferenceUpdateDTO: ConferenceUpdateDTO;
}

/**
 * 
 */
export class ConferenceApi extends runtime.BaseAPI {

    /**
     */
    async conferenceControllerEndMeetingRaw(requestParameters: ConferenceControllerEndMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['terminateConfereneDTO'] == null) {
            throw new runtime.RequiredError(
                'terminateConfereneDTO',
                'Required parameter "terminateConfereneDTO" was null or undefined when calling conferenceControllerEndMeeting().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/endMeeting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TerminateConfereneDTOToJSON(requestParameters['terminateConfereneDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerEndMeeting(requestParameters: ConferenceControllerEndMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerEndMeetingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async conferenceControllerGetRecordingRaw(requestParameters: ConferenceControllerGetRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['getRecordingDTO'] == null) {
            throw new runtime.RequiredError(
                'getRecordingDTO',
                'Required parameter "getRecordingDTO" was null or undefined when calling conferenceControllerGetRecording().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/getRecording`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetRecordingDTOToJSON(requestParameters['getRecordingDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerGetRecording(requestParameters: ConferenceControllerGetRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerGetRecordingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async conferenceControllerGetTokenClientRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenClientResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/conference/tokenClient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenClientResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async conferenceControllerGetTokenClient(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenClientResponseDTO> {
        const response = await this.conferenceControllerGetTokenClientRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async conferenceControllerGetTokenOAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenOAuthResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/conference/tokenOAuth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenOAuthResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async conferenceControllerGetTokenOAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenOAuthResponseDTO> {
        const response = await this.conferenceControllerGetTokenOAuthRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async conferenceControllerInviteRaw(requestParameters: ConferenceControllerInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['conferenceInviteDTO'] == null) {
            throw new runtime.RequiredError(
                'conferenceInviteDTO',
                'Required parameter "conferenceInviteDTO" was null or undefined when calling conferenceControllerInvite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConferenceInviteDTOToJSON(requestParameters['conferenceInviteDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerInvite(requestParameters: ConferenceControllerInviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerInviteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async conferenceControllerMeetingTokenRaw(requestParameters: ConferenceControllerMeetingTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConferenceAccessTokenResponseDTO>> {
        if (requestParameters['conferenceJoinDTO'] == null) {
            throw new runtime.RequiredError(
                'conferenceJoinDTO',
                'Required parameter "conferenceJoinDTO" was null or undefined when calling conferenceControllerMeetingToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/getAccess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConferenceJoinDTOToJSON(requestParameters['conferenceJoinDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConferenceAccessTokenResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async conferenceControllerMeetingToken(requestParameters: ConferenceControllerMeetingTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConferenceAccessTokenResponseDTO> {
        const response = await this.conferenceControllerMeetingTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conferenceControllerStartRecordingRaw(requestParameters: ConferenceControllerStartRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['startRecordingDTO'] == null) {
            throw new runtime.RequiredError(
                'startRecordingDTO',
                'Required parameter "startRecordingDTO" was null or undefined when calling conferenceControllerStartRecording().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/startRecording`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartRecordingDTOToJSON(requestParameters['startRecordingDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerStartRecording(requestParameters: ConferenceControllerStartRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerStartRecordingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async conferenceControllerStopRecordingRaw(requestParameters: ConferenceControllerStopRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['stopRecordingDTO'] == null) {
            throw new runtime.RequiredError(
                'stopRecordingDTO',
                'Required parameter "stopRecordingDTO" was null or undefined when calling conferenceControllerStopRecording().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/stopRecording`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StopRecordingDTOToJSON(requestParameters['stopRecordingDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerStopRecording(requestParameters: ConferenceControllerStopRecordingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerStopRecordingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async conferenceControllerUpdateMeetingRaw(requestParameters: ConferenceControllerUpdateMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['conferenceUpdateDTO'] == null) {
            throw new runtime.RequiredError(
                'conferenceUpdateDTO',
                'Required parameter "conferenceUpdateDTO" was null or undefined when calling conferenceControllerUpdateMeeting().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/conference/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConferenceUpdateDTOToJSON(requestParameters['conferenceUpdateDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async conferenceControllerUpdateMeeting(requestParameters: ConferenceControllerUpdateMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.conferenceControllerUpdateMeetingRaw(requestParameters, initOverrides);
    }

}
