/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Plan,
  PlanDTO,
} from '../models/index';
import {
    PlanFromJSON,
    PlanToJSON,
    PlanDTOFromJSON,
    PlanDTOToJSON,
} from '../models/index';

export interface PlanControllerCreateRequest {
    planDTO: PlanDTO;
}

export interface PlanControllerFindOneRequest {
    id: string;
}

/**
 * 
 */
export class BusinessPlansApi extends runtime.BaseAPI {

    /**
     */
    async planControllerCreateRaw(requestParameters: PlanControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters['planDTO'] == null) {
            throw new runtime.RequiredError(
                'planDTO',
                'Required parameter "planDTO" was null or undefined when calling planControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanDTOToJSON(requestParameters['planDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async planControllerCreate(requestParameters: PlanControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plan> {
        const response = await this.planControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planControllerFindOneRaw(requestParameters: PlanControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling planControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async planControllerFindOne(requestParameters: PlanControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plan> {
        const response = await this.planControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Plan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/plan/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanFromJSON));
    }

    /**
     */
    async planControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Plan>> {
        const response = await this.planControllerGetAllRaw(initOverrides);
        return await response.value();
    }

}
