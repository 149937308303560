import { Box, Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Session } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl } from "../../../utils/utils-apis";

const RecordingData = ({
  session,
  recordingKey,
}: {
  session?: Session;
  recordingKey?: string;
}) => {
  const { configuration } = useAuth();
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (recordingKey !== "" && recordingKey !== undefined) {
      getFileUrl(configuration, recordingKey)
        .then((url) => {
          setUrl(url);
          setLoading(false);
        })
        .catch((err) => {
          setUrl("");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingKey]);

  return (
    <Box pt={5}>
      <Text fontSize={"md"} fontWeight={600} mb={2}>
        Session Recording
      </Text>
      <Box
        boxShadow="10px 4px 30px rgba(0, 0, 0, 0.25)"
        borderRadius="lg"
        px={"24px"}
        py={"21px"}
        mt={2}
        backgroundColor="#fff"
        fontSize={"sm"}
      >
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : recordingKey === "" || recordingKey === undefined ? (
          <>
            <Text fontSize={"sm"}>No Recording Found</Text>
            {session?.status === "completed" && (
              <Text fontSize={"xs"}>
                (It take some time to save session recording. Please check after
                2-3 minutes)
              </Text>
            )}
          </>
        ) : (
          <Box>
            <Flex justifyContent={"flex-end"}>
              <Button
                mb={3}
                fontSize={"sm"}
                style={{
                  minWidth: "80px",
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                }}
                onClick={() => window.open(url, "_blank")}
              >
                Download
              </Button>
            </Flex>

            <video height="240" width="800" controls>
              <source src={url} type="video/webm" />
            </video>

            <Text fontSize={"xs"} mt={2}>
              *Note - This is the latest recording of the session. If there is
              any issue regarding the recording please contact us.
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RecordingData;
