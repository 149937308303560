import { Link as RouterLink } from "react-router-dom";
import { Flex, MenuDivider, MenuItem, Text } from "@chakra-ui/react";
import { FiInfo as InfoIcon } from "react-icons/fi";
import { HiOutlineDocumentText as PlanIcon } from "react-icons/hi";
import { notaryPaths } from "../../routes/routes";

const NavbarRoutes = () => {
  const role = localStorage.getItem("role");

  return (
    <>
      {role === "notary" ? (
        <>
          <RouterLink to={`${notaryPaths.profileSetting}/personal-information`}>
            <MenuItem>
              <Flex alignItems="center">
                <InfoIcon style={{ color: "#000080" }} size={20} />
                <Text ml={2}>Notary Profile</Text>
              </Flex>
            </MenuItem>
          </RouterLink>

          <MenuDivider />

          <RouterLink to="/notary/plans">
            <MenuItem>
              <Flex alignItems="center">
                <PlanIcon style={{ color: "#000080" }} size={20} />
                <Text ml={2}>Plans & Billing</Text>
              </Flex>
            </MenuItem>
          </RouterLink>
          <MenuDivider />
        </>
      ) : role === "signer" ? (
        <>
          <RouterLink to="/signer/profile">
            <MenuItem>
              <Flex alignItems="center">
                <InfoIcon style={{ color: "#000080" }} size={20} />
                <Text ml={2}>Signer Profile</Text>
              </Flex>
            </MenuItem>
          </RouterLink>
          <MenuDivider />
        </>
      ) : (
        <>
          <RouterLink to="/business/profile">
            <MenuItem>
              <Flex alignItems="center">
                <InfoIcon style={{ color: "#000080" }} size={20} />
                <Text ml={2}>Business Information</Text>
              </Flex>
            </MenuItem>
          </RouterLink>

          <MenuDivider />

          <RouterLink to="/business/plans">
            <MenuItem>
              <Flex alignItems="center">
                <PlanIcon style={{ color: "#000080" }} size={20} />
                <Text ml={2}>Plans & Billing</Text>
              </Flex>
            </MenuItem>
          </RouterLink>
          <MenuDivider />
        </>
      )}
    </>
  );
};

export default NavbarRoutes;
