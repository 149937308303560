import { Box, Button, Flex } from "@chakra-ui/react";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Socket } from "socket.io-client";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import { SessionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { emitEvent } from "../../../utils/pdf-utils/emitEvent";
import ReactSelect, { SingleValue } from "react-select";
import useNotification from "../../../utils/useNotification";
import { useDocumentStore } from "../../../store/documentsStore";
import { EventAction, EventType } from "../../../store/auditLogs";
import { useSessionDocStore } from "../../../store/sessionDocStore";

const PdfHeader = ({
  from,
  socket,
  sessionId,
  totalPages,
  onSetSelectedPage,
  selectedPage,
}: {
  from: string;
  selectedPage: number;
  socket: Socket;
  sessionId: string;
  totalPages: number;
  onSetSelectedPage: (page: number) => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const sessionDocs = useSessionDocStore((state) => state.sessionDocs);
  const currentDocId = useDocumentStore((state) => state.currentDocId);
  const setCurrentDocId = useDocumentStore((state) => state.setCurrentDocId);
  const removeSessionDoc = useSessionDocStore(
    (state) => state.removeSessionDoc
  );

  const [docSelectOptions, setDocSelectOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: string;
      value: number;
    }>
  >();

  const currentDocIdIdx = docSelectOptions?.findIndex(
    (item) => item.value === currentDocId
  );

  const handlePageChange = (e: { selected: number }) => {
    if (from === "notary" || from === "edit") {
      onSetSelectedPage(e.selected);
      const section = document.querySelector(`#page${e.selected}`);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
      emitEvent({
        action: EventAction.navigatedToSelectedPage,
        event: EventType.navigatedToSelectedPage,
        pageNumber: e.selected,
        payload: { pageNumber: e.selected },
        sessionId,
        socket,
      });
    }
  };

  useEffect(() => {
    const removeDupliicate = sessionDocs?.filter(
      (el, index) =>
        index === sessionDocs?.findIndex((other) => el?.docId === other?.docId)
    );

    setDocSelectOptions(
      removeDupliicate?.map((document, idx) => ({
        value: document?.docId,
        label: `${document?.name}`,
      }))
    );
  }, [sessionDocs]);

  const handleDeleteDoc = () => {
    new SessionApi(configuration)
      .sessionControllerDelete({
        sessionId: Number(sessionId),
        docId: currentDocId,
      })
      .then((res) => {
        if (res.status === 200) {
          notification("success", "Document deleted successfully!");
          removeSessionDoc(currentDocId);
        } else {
          notification("fail", "Failed to delete document!");
        }
      })
      .catch((err) => {
        notification("fail", "Failed to delete document!");
      });
  };

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Box pt={3} width="60%" zIndex={9}>
        {sessionDocs?.length !== 0 && (
          <Flex>
            <Box width="80%">
              <ReactSelect
                placeholder="Select Document"
                options={docSelectOptions}
                value={
                  docSelectOptions.find(
                    (item) => item.value === currentDocId
                  ) ?? selectedOption
                }
                onChange={(option) => {
                  setSelectedOption(option);
                  setCurrentDocId(Number(option?.value));
                  emitEvent({
                    action: EventAction.navigatedToDifferentDoc,
                    event: EventType.navigatedToDifferentDoc,
                    pageNumber: undefined,
                    payload: { docId: option?.value },
                    sessionId,
                    socket,
                  });
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "14px",
                  }),
                  menu: (base) => ({
                    ...base,
                    fontSize: "14px",
                  }),
                }}
              />
            </Box>

            <Flex direction={"column"} ml={1}>
              <Button
                border="1.8px solid hsl(0, 0%, 80%)"
                borderRadius={"5px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }}
                onClick={() => {
                  setCurrentDocId(
                    Number(docSelectOptions[currentDocIdIdx - 1]?.value)
                  );
                  emitEvent({
                    action: EventAction.navigatedToDifferentDoc,
                    event: EventType.navigatedToDifferentDoc,
                    pageNumber: undefined,
                    payload: {
                      docId: docSelectOptions[currentDocIdIdx - 1]?.value,
                    },
                    sessionId,
                    socket,
                  });
                }}
                isDisabled={currentDocIdIdx === 0}
                p={0}
                style={{
                  height: "18px",
                  width: "18px",
                  minWidth: "10px",
                }}
              >
                <RiArrowUpSLine />
              </Button>
              <Button
                p={0}
                style={{
                  height: "18px",
                  width: "18px",
                  minWidth: "10px",
                }}
                isDisabled={currentDocIdIdx === docSelectOptions?.length - 1}
                border="1.8px solid hsl(0, 0%, 80%)"
                borderRadius={"5px"}
                mt={0.5}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }}
                onClick={() => {
                  setCurrentDocId(
                    Number(docSelectOptions[currentDocIdIdx + 1]?.value)
                  );
                  emitEvent({
                    action: EventAction.navigatedToDifferentDoc,
                    event: EventType.navigatedToDifferentDoc,
                    pageNumber: undefined,
                    payload: {
                      docId: docSelectOptions[currentDocIdIdx + 1]?.value,
                    },
                    sessionId,
                    socket,
                  });
                }}
              >
                <RiArrowDownSLine />
              </Button>
            </Flex>

            {/* {from === "notary" && (
              <Tooltip label="It will delete selected documnet from this session.">
                <Button
                  borderRadius={"5px"}
                  color={"#df2c14"}
                  _hover={{
                    background: "#df2c14",
                    color: "#fff",
                  }}
                  border={"1px solid #df2c14"}
                  backgroundColor={"white"}
                  onClick={handleDeleteDoc}
                  p={0}
                  ml={2}
                >
                  <MdDelete />
                </Button>
              </Tooltip>
            )} */}
          </Flex>
        )}
      </Box>

      <ReactPaginate
        breakLabel="..."
        nextLabel="Next >"
        previousLabel="< Prev"
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={(e) => handlePageChange(e)}
        pageCount={Number(totalPages)}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={selectedPage}
      />
    </Flex>
  );
};

export default PdfHeader;
