/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPassswordResponseDTO
 */
export interface ResetPassswordResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordResponseDTO
     */
    message: string;
}

/**
 * Check if a given object implements the ResetPassswordResponseDTO interface.
 */
export function instanceOfResetPassswordResponseDTO(value: object): value is ResetPassswordResponseDTO {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ResetPassswordResponseDTOFromJSON(json: any): ResetPassswordResponseDTO {
    return ResetPassswordResponseDTOFromJSONTyped(json, false);
}

export function ResetPassswordResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPassswordResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function ResetPassswordResponseDTOToJSON(value?: ResetPassswordResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
    };
}

