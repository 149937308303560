import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { SubscriptionApi } from "../../../api";
import { handleError } from "../../../utils/utils-functions";
import { useAuth } from "../../../utils/use-auth";
import { notaryPaths } from "../../../routes/routes";
import useNotification from "../../../utils/useNotification";
import { getNotaryDetails } from "../../../utils/utils-apis";
import { useProfileDetailsStore } from "../../../store/notaryStore";
import ActiveProfileModal from "../Sessions/Modals/ActiveProfileModal";
import OnDemand from "../../../component/onDemands/Notary/OnDemandNotary";
import OnDemandNoticeScreen from "../../../component/onDemands/Notary/OnDemandNoticeScreen";

const OnDemandNotaryPage = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showDataFor, setShowDataFor] = useState("");
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();

  useEffect(() => {
    getNotaryDetails(configuration)
      .then((res) => {
        localStorage.setItem("userName", res.firstName);
        localStorage.setItem("notaryId", res?.notaryId?.toString());
        setNotaryProfile({
          ...notaryProfile,
          notaryId: res?.notaryId?.toString(),
          notaryStatus: res.notaryStatus,
          arizonaNotaryStatus: res?.arizonaNotaryStatus,
          state: res?.state,
          onDemandStatus: res?.onDemandStatus,
          paymentDetails: res?.paymentDetails,
          isInhouseNotary: res?.isInhouseNotary,
          titleLenderStatus: res?.titleLenderStatus,
          businessStatus: res?.businessStatus,
        });
        if (!res.paymentDetails) {
          setShowDataFor("payment");
          onOpen();
        } else {
          new SubscriptionApi(configuration)
            .subscriptionControllerGet()
            .then((res) => {
              console.log("res", res);
            })
            .catch((e) => {
              setShowDataFor("activePlan");
              onOpen();
            });
        }
      })
      .catch(async (error) => {
        const message = await handleError(error);
        if (message.errorMessage === "Notary not Found") {
          notification("warn", "Complete your Profile");
          setTimeout(() => {
            navigate(`${notaryPaths.profileSetting}/personal-information`);
          }, 1000);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ActiveProfileModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        showDataFor={showDataFor}
        path={
          showDataFor === "activePlan"
            ? "/notary/plans"
            : `${notaryPaths.profileSetting}/personal-information`
        }
      />

      {notaryProfile.isInhouseNotary ? (
        <OnDemandNoticeScreen from="inHouse" />
      ) : (
        <OnDemand />
      )}
    </>
  );
};

export default OnDemandNotaryPage;
