/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionsGraph
 */
export interface SessionsGraph {
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    completeSessionCount: number;
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    pendingSessionCount: number;
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    bookedSessionCount: number;
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    terminateSessionCount: number;
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    expiredSessionCount: number;
    /**
     * 
     * @type {number}
     * @memberof SessionsGraph
     */
    totalSessionCount: number;
}

/**
 * Check if a given object implements the SessionsGraph interface.
 */
export function instanceOfSessionsGraph(value: object): value is SessionsGraph {
    if (!('completeSessionCount' in value) || value['completeSessionCount'] === undefined) return false;
    if (!('pendingSessionCount' in value) || value['pendingSessionCount'] === undefined) return false;
    if (!('bookedSessionCount' in value) || value['bookedSessionCount'] === undefined) return false;
    if (!('terminateSessionCount' in value) || value['terminateSessionCount'] === undefined) return false;
    if (!('expiredSessionCount' in value) || value['expiredSessionCount'] === undefined) return false;
    if (!('totalSessionCount' in value) || value['totalSessionCount'] === undefined) return false;
    return true;
}

export function SessionsGraphFromJSON(json: any): SessionsGraph {
    return SessionsGraphFromJSONTyped(json, false);
}

export function SessionsGraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionsGraph {
    if (json == null) {
        return json;
    }
    return {
        
        'completeSessionCount': json['completeSessionCount'],
        'pendingSessionCount': json['pendingSessionCount'],
        'bookedSessionCount': json['bookedSessionCount'],
        'terminateSessionCount': json['terminateSessionCount'],
        'expiredSessionCount': json['expiredSessionCount'],
        'totalSessionCount': json['totalSessionCount'],
    };
}

export function SessionsGraphToJSON(value?: SessionsGraph | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'completeSessionCount': value['completeSessionCount'],
        'pendingSessionCount': value['pendingSessionCount'],
        'bookedSessionCount': value['bookedSessionCount'],
        'terminateSessionCount': value['terminateSessionCount'],
        'expiredSessionCount': value['expiredSessionCount'],
        'totalSessionCount': value['totalSessionCount'],
    };
}

