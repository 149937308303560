/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConferenceUpdateDTO
 */
export interface ConferenceUpdateDTO {
    /**
     * 
     * @type {object}
     * @memberof ConferenceUpdateDTO
     */
    participants: object;
    /**
     * 
     * @type {string}
     * @memberof ConferenceUpdateDTO
     */
    conferenceId: string;
}

/**
 * Check if a given object implements the ConferenceUpdateDTO interface.
 */
export function instanceOfConferenceUpdateDTO(value: object): value is ConferenceUpdateDTO {
    if (!('participants' in value) || value['participants'] === undefined) return false;
    if (!('conferenceId' in value) || value['conferenceId'] === undefined) return false;
    return true;
}

export function ConferenceUpdateDTOFromJSON(json: any): ConferenceUpdateDTO {
    return ConferenceUpdateDTOFromJSONTyped(json, false);
}

export function ConferenceUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConferenceUpdateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'participants': json['participants'],
        'conferenceId': json['conferenceId'],
    };
}

export function ConferenceUpdateDTOToJSON(value?: ConferenceUpdateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'participants': value['participants'],
        'conferenceId': value['conferenceId'],
    };
}

