import { Button, Flex, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import KbaQR from "../../commonComponents/KbaVerificationModal/KbaQR";
import { useSessionCreate } from "../../../store/store";
import DemoKbaQR from "../../commonComponents/KbaVerificationModal/DemoKba/DemoKbaQR";
import { signerPaths } from "../../../routes/routes";

const SignerSessionConnecting = () => {
  const navigate = useNavigate();
  const { sessionDetail } = useSessionCreate();

  return (
    <Stack px={6}>
      {sessionDetail?.isDemoSession ? (
        <DemoKbaQR from="page" />
      ) : (
        <KbaQR from="page" />
      )}

      {!sessionDetail.isDemoSession && (
        <Flex alignSelf={"end"} pt={7}>
          <Button
            mx={2}
            style={{ padding: "10px 30px" }}
            width={"fit-content"}
            onClick={() => {
              navigate(`${signerPaths.createSession}/signer-verification`);
            }}
          >
            Prev
          </Button>
        </Flex>
      )}
    </Stack>
  );
};

export default SignerSessionConnecting;
