/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePassswordResponseDTO
 */
export interface ChangePassswordResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangePassswordResponseDTO
     */
    message: string;
}

/**
 * Check if a given object implements the ChangePassswordResponseDTO interface.
 */
export function instanceOfChangePassswordResponseDTO(value: object): value is ChangePassswordResponseDTO {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function ChangePassswordResponseDTOFromJSON(json: any): ChangePassswordResponseDTO {
    return ChangePassswordResponseDTOFromJSONTyped(json, false);
}

export function ChangePassswordResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePassswordResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function ChangePassswordResponseDTOToJSON(value?: ChangePassswordResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
    };
}

