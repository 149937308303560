import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NotaryApi } from "../../api";
import { useAuth } from "../../utils/use-auth";
import { useNavigate } from "react-router-dom";
import UploadDoc from "../commonComponents/UploadComponents/UploadDoc";
import { useProfileDetailsStore } from "../../store/notaryStore";
import SealModal from "./Modals/SealModal";
import ScribblePopover from "./Modals/ScribblePopover";
import { getFileUrl } from "../../utils/utils-apis";
import { notaryPaths } from "../../routes/routes";

const SignatureAndSealForm = () => {
  const { configuration } = useAuth();
  const navigate = useNavigate();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [error, setError] = useState<boolean>(false);
  const [signatureFile, setSignatureFile] = useState<string>(
    notaryProfile.signature ?? ""
  );
  const [initialFile, setInitialFile] = useState<string>(
    notaryProfile?.initials ?? ""
  );
  const [sealFile, setSealFile] = useState<string>(notaryProfile?.seal ?? "");
  const [isSignatureFileUploaded, setIsSignatureFileUploaded] = useState(false);
  const [isInitialsFileUploaded, setIsInitialsFileUploaded] = useState(false);
  const [isSealFileUploaded, setIsSealFileUploaded] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<string>("");
  const [initialUrl, setInitialUrl] = useState<string>("");
  const [sealUrl, setSealUrl] = useState<string>("");

  const {
    onOpen: onStampOpen,
    onClose: onStampClose,
    isOpen: isStampOpen,
  } = useDisclosure();

  const updateNotaryProfile = () => {
    new NotaryApi(configuration)
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          signature: signatureFile,
          seal: sealFile,
          initials: initialFile,
        },
      })
      .then((res) => {
        navigate(`${notaryPaths.profileSetting}/payment-info`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNotaryProfile({
      ...notaryProfile,
      signature: signatureFile,
      seal: sealFile,
      initials: initialFile,
    });
    if (signatureFile === "" || initialFile === "" || sealFile === "") {
      setError(true);
    } else {
      setError(false);
      updateNotaryProfile();
    }
  };

  useEffect(() => {
    getFileUrl(configuration, sealFile).then((url) => {
      setSealUrl(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sealFile]);

  useEffect(() => {
    getFileUrl(configuration, signatureFile).then((url) => {
      setSignatureUrl(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureFile]);

  useEffect(() => {
    getFileUrl(configuration, initialFile).then((url) => {
      setInitialUrl(url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFile]);

  return (
    <>
      <Stack px={6}>
        <Heading
          fontSize={{ base: "18px", md: "22px", lg: "22px" }}
          lineHeight="30px"
          textAlign={"center"}
          mb={4}
          fontFamily="Oswald"
          fontWeight={600}
        >
          Signature, Initial & Seal
        </Heading>

        <Text
          fontSize={"16px"}
          fontFamily={"Lato"}
          fontWeight={"400"}
          mb={"40px"}
        >
          Please upload or create your notary signature, initials and stamp (or
          eSeal). If you do not have an eSeal yet, Notarize Genie will create
          one for you.
        </Text>

        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl p="1">
            <FormLabel
              fontWeight={"600"}
              p="1"
              {...(isSignatureFileUploaded &&
              isInitialsFileUploaded &&
              isSealFileUploaded
                ? { color: "#000080" }
                : {})}
            >
              Upload Signature, Initial & Seal*
            </FormLabel>

            <Flex flexWrap={"wrap"} justifyContent={"space-around"}>
              <Stack mx={"10px"} my={3}>
                <UploadDoc
                  acceptFileType={[".jpg", ".png"]}
                  filePath={signatureFile}
                  url={signatureUrl}
                  onUpload={(file) => {
                    setSignatureFile(file);
                    setIsSignatureFileUploaded(true);
                  }}
                />
                <ScribblePopover
                  from="Signature"
                  onSetFile={(path) => {
                    setSignatureFile(path);
                  }}
                />
              </Stack>

              <Stack mx={"10px"} my={3}>
                <UploadDoc
                  acceptFileType={[".jpg", ".png"]}
                  filePath={initialFile}
                  url={initialUrl}
                  onUpload={(file) => {
                    setInitialFile(file);
                    setIsInitialsFileUploaded(true);
                  }}
                />
                <ScribblePopover
                  from="Initial"
                  onSetFile={(path) => {
                    setInitialFile(path);
                    // setNotaryProfile({
                    //   ...notaryProfile,
                    //   initials: path,
                    // });
                  }}
                />
              </Stack>

              <Stack my={3}>
                <UploadDoc
                  acceptFileType={[".jpg", ".png"]}
                  filePath={sealFile}
                  url={sealUrl}
                  onUpload={(file) => {
                    setSealFile(file);
                    setIsSealFileUploaded(true);
                  }}
                />
                {(notaryProfile.state === "FL" ||
                  notaryProfile.state === "TX" ||
                  notaryProfile.state === "OH" ||
                  notaryProfile.state === "WA" ||
                  notaryProfile.state === "VA" ||
                  notaryProfile.state === "OK" ||
                  notaryProfile.state === "NY" ||
                  notaryProfile.state === "PA" ||
                  notaryProfile.state === "TN" ||
                  notaryProfile.state === "AZ" ||
                  notaryProfile.state === "NJ") && (
                  <Button onClick={onStampOpen}>Create Stamp</Button>
                )}
              </Stack>
            </Flex>
          </FormControl>

          {error && (
            <Text color={"red"} fontSize="12px">
              *Please upload your signature, initial and seal
            </Text>
          )}
          <Box ml="auto" p="2">
            <Flex justifyContent={"space-between"}>
              <Button
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "10px 30px",
                  marginTop: "30px",
                }}
                onClick={() =>
                  navigate(`${notaryPaths.profileSetting}/digital-certificate`)
                }
              >
                PREV
              </Button>

              <Button
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "10px 30px",
                  marginTop: "30px",
                }}
                type="submit"
              >
                SAVE & NEXT
              </Button>
            </Flex>
          </Box>
        </form>
      </Stack>

      <SealModal
        onClose={onStampClose}
        onOpen={onStampOpen}
        isOpen={isStampOpen}
        onSetSealFile={(path) => setSealFile(path)}
      />
    </>
  );
};

export default SignatureAndSealForm;
