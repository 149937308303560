/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPassswordDTO
 */
export interface ResetPassswordDTO {
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordDTO
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassswordDTO
     */
    password: string;
}

/**
 * Check if a given object implements the ResetPassswordDTO interface.
 */
export function instanceOfResetPassswordDTO(value: object): value is ResetPassswordDTO {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function ResetPassswordDTOFromJSON(json: any): ResetPassswordDTO {
    return ResetPassswordDTOFromJSONTyped(json, false);
}

export function ResetPassswordDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPassswordDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'password': json['password'],
    };
}

export function ResetPassswordDTOToJSON(value?: ResetPassswordDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'password': value['password'],
    };
}

