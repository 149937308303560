import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useSessionCreate } from "../../../store/store";

const DemoSessionComponent = () => {
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  return (
    <Box pb={5}>
      <Text fontWeight={600}>Do you want to create a demo session?</Text>
      {/* <Text>
        (Note- If you create a demo session you will be charge $1 for the
        session.)
      </Text> */}

      <RadioGroup
        mt={3}
        value={sessionDetail?.isDemoSession ? "yes" : "no"}
        onChange={(value) => {
          if (value === "no") {
            setSessionDetails({ ...sessionDetail, isDemoSession: false });
          } else {
            setSessionDetails({
              ...sessionDetail,
              isDemoSession: true,
            });
          }
        }}
      >
        <Stack direction="row" gap={3}>
          <Radio value="no">No</Radio>
          <Radio value="yes">Yes</Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
};

export default DemoSessionComponent;
