import Tracker from "@openreplay/tracker";
import trackerZustand from "@openreplay/tracker-zustand";

export class OpenReplay {
  // staging-key
  // static PROJECT_KEY = "4t6pLR9GV6OqXXPVOKXv";

  // prod-key
  static PROJECT_KEY =
    process.env.REACT_APP_ENV === "dev"
      ? "4t6pLR9GV6OqXXPVOKXv"
      : "vw4XUvMKNpKvjMPdFxtu";
  static userID = localStorage.getItem("email") as string;
  // static userID = localStorage.getItem("UserID") as string;
  static tracker = new Tracker({
    projectKey: this.PROJECT_KEY,
    __DISABLE_SECURE_MODE: true,
    // @ts-ignore
    network: {
      capturePayload: true,
    },
  });
  static zustandPlugin = this.tracker.use(trackerZustand());
  static init() {
    OpenReplay.tracker.start({
      userID: OpenReplay.userID,
    });
  }

  public static setUser(userID: string) {
    if (OpenReplay.userID === userID || !OpenReplay.tracker) return;
    OpenReplay.tracker.setUserID(userID);
    OpenReplay.userID = userID;
  }

  public static createLogger(storeName: string): Function {
    if (!OpenReplay.tracker)
      // @ts-ignore
      return (config) => (set, get, api) =>
        config(
          // @ts-ignore
          (...args) => {
            set(...args);
          },
          get,
          api
        );
    return OpenReplay.zustandPlugin(storeName);
  }
}
