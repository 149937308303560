import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { Session, SignedPdfDTO, Signer } from "../../api";
import SessionSignerDetail from "../commonComponents/DetailModalData/SessionSignerDetail";
import useCustomComponentsHook from "../../hooks/useCustomComponentsHook";
import { getStateName } from "../../utils/utils";
import { phnNumberFormat } from "../../utils/utils";
import { useState } from "react";
import SignedDocInfo from "../commonComponents/DetailModalData/SignedDocInfo";

const ListDetailModal = ({
  isOpen,
  onClose,
  onOpen,
  session,
  signedDocPath,
  signers,
}: {
  signers: Signer[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  session?: Session;
  signedDocPath: SignedPdfDTO[];
}) => {
  const { flexData, flexDataStatus } = useCustomComponentsHook();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");

  const handleClose = () => {
    setErrMsg("");
    setIsSubmit(false);
    onClose();
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size={"6xl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent px={4}>
          <ModalHeader>
            <Text
              fontSize={{ base: "18px", md: "22px", lg: "22px" }}
              fontWeight={"700"}
              fontFamily="Lato"
            >
              Session Details
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody
            mx={5}
            my={7}
            style={{ border: "1px solid rgba(139, 138, 150, 0.32)" }}
            p={4}
            bg={"rgba(245, 245, 245, 0.46)"}
          >
            <Box height="67vh" overflow={"auto"}>
              <Stack w={"90%"} px={4}>
                <Box>
                  <Text fontSize={"md"} fontWeight={600}>
                    Participants
                  </Text>
                  <Box
                    boxShadow="10px 4px 40px rgba(0, 0, 0, 0.25)"
                    borderRadius="lg"
                    px={"24px"}
                    py={"21px"}
                    mt={2}
                    backgroundColor="#fff"
                  >
                    {session?.primarySignerUserId !== undefined ? (
                      <>
                        {signers
                          .filter(
                            (item, idx) =>
                              item.user?.id === session?.primarySignerUserId
                          )
                          ?.flatMap((item, idx) => (
                            <SessionSignerDetail
                              key={idx}
                              idx={idx}
                              item={item}
                              session={session as Session}
                            />
                          ))}
                        {signers
                          ?.filter(
                            (item, idx) =>
                              item.user?.id !== session?.primarySignerUserId
                          )
                          ?.flatMap((item, idx) => (
                            <SessionSignerDetail
                              key={idx}
                              idx={idx + 1}
                              item={item}
                              session={session as Session}
                            />
                          ))}
                      </>
                    ) : (
                      <>
                        {signers?.flatMap((item, idx) => (
                          <SessionSignerDetail
                            key={idx}
                            idx={idx}
                            item={item}
                            session={session as Session}
                          />
                        ))}
                      </>
                    )}

                    {session?.witness?.flatMap((item, idx) => (
                      <Box key={idx}>
                        <Text
                          fontWeight={"600"}
                          mb={1}
                          mt={2}
                          textDecoration="underline"
                        >
                          Witness {idx + 1}
                        </Text>

                        {flexData(
                          "Name",
                          `${item?.firstName + " "} ${item?.lastName}`
                        )}

                        {flexData("Address", item?.witnessAddress)}
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box pt={5}>
                  <Text fontSize={"md"} fontWeight={600}>
                    Notary
                  </Text>
                  <Box
                    boxShadow="10px 4px 40px rgba(0, 0, 0, 0.25)"
                    borderRadius="lg"
                    px={"24px"}
                    py={"21px"}
                    mt={2}
                    backgroundColor="#fff"
                    fontSize={"sm"}
                  >
                    {session?.notary != null ? (
                      <Box>
                        {flexData(
                          "Notary Name",
                          ` ${session?.notary?.firstName + " "} ${
                            session?.notary?.lastName
                          }`
                        )}
                        {flexData(
                          "Practices in",
                          getStateName(session?.notary?.state as string)
                        )}
                        {flexData(
                          "Commission Expires",
                          moment(
                            session?.notary?.commissionExpirationDate
                          ).format("MM-DD-YYYY")
                        )}
                        {flexData(
                          "Phone Number",
                          phnNumberFormat(session?.notary?.phoneNumber)
                        )}
                        <Flex mt={1} w={"100%"} fontSize={"sm"}>
                          <Text width={"40%"} textTransform="capitalize">
                            Email
                          </Text>
                          <Text textTransform="lowercase">
                            {session?.notary?.user?.email}
                          </Text>
                        </Flex>
                      </Box>
                    ) : (
                      <Text fontSize={"10px"} fontWeight={400} w={"90%"}>
                        Notary not joined this session yet.
                      </Text>
                    )}
                  </Box>
                </Box>

                <SignedDocInfo
                  session={session}
                  signedDocPath={signedDocPath}
                  onSetIsSubmit={(value) => setIsSubmit(value)}
                  onSetErrMsg={(value) => setErrMsg(value)}
                  errMsg={errMsg}
                  isSubmit={isSubmit}
                  from={"listModal"}
                />

                <Box py={5}>
                  <Text fontSize={"md"} fontWeight={600}>
                    Session Details
                  </Text>
                  <Box
                    boxShadow="10px 4px 40px rgba(0, 0, 0, 0.25)"
                    borderRadius="lg"
                    px={"24px"}
                    py={"21px"}
                    mt={2}
                    backgroundColor="#fff"
                    fontSize={"sm"}
                  >
                    <Flex mt={1}>
                      <Text width={"40%"}>Session Time</Text>
                      <Text color={"#827272"}>
                        {moment(session?.createdDate).format("MM-DD-YYYY")}
                      </Text>
                    </Flex>
                    {flexData("Session ID", session?.sessionId)}

                    {flexDataStatus("Session Status", session?.status)}

                    {session?.status === "terminated" &&
                      flexData("Terminate Reason", session?.terminateReason)}
                  </Box>
                </Box>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ListDetailModal;
