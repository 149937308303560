import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadDoc from "../commonComponents/UploadComponents/UploadDoc";
import { useProfileDetailsStore } from "../../store/notaryStore";
import moment from "moment";
import { today } from "../../utils/utils";
import { useAuth } from "../../utils/use-auth";
import { NotaryApi } from "../../api";
import { notaryPaths } from "../../routes/routes";

const CommissionDetailsForm = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      ...notaryProfile,
    },

    validationSchema: Yup.object({
      commissionNumber: Yup.string().required("Commission Number is required"),
      // .matches(
      //   /^([A-Z]{2})+[\s]+([0-9]{6})$/,
      //   "Commission Number must be Alpha Numeric Example : (AB 123456)"
      // ),
      commissionExpirationDate: Yup.date()
        .required("Commission Expiration Date is required")
        .typeError("Commission Expiration Date is required"),
      copyOfNotaryCommission: Yup.string().required(
        "Traditional Commission File is required"
      ),
    }),

    onSubmit: (values) => {
      setNotaryProfile(values);
      new NotaryApi(configuration)
        .notaryControllerPartialUpdateNotary({
          id: Number(notaryProfile?.notaryId),
          updateNotaryDTO: {
            commissionNumber: values.commissionNumber,
            commissionExpirationDate: new Date(
              values.commissionExpirationDate
            ).toUTCString(),
            copyOfNotaryCommission: values.copyOfNotaryCommission,
          },
        })
        .then((res) => {
          navigate(`${notaryPaths.profileSetting}/insurance-details`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enableReinitialize: true,
  });

  return (
    <Stack px={6}>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Commission Details
      </Heading>

      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Please enter and upload your Traditional Notary (or R.O.N. specific)
        Commission Number, the expiration date and a copy of your Notary
        Commission Certificate.
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <Flex flexWrap={"wrap"} fontFamily="Lato" fontWeight={600} mt={3}>
          <FormControl
            id="commissionNumber"
            w={"100%"}
            minW={"300px"}
            mb={"15px"}
          >
            <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
              Commission Number*
            </FormLabel>
            <Input
              {...(formik.touched.commissionNumber &&
              formik.errors.commissionNumber
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              name="commissionNumber"
              value={formik.values.commissionNumber}
              placeholder="Commission Number"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.commissionNumber && formik.errors.commissionNumber
                ? formik.errors.commissionNumber
                : ""}
            </Text>
          </FormControl>

          <FormControl id="TraditionalCommissionExpirationDate" mb={"15px"}>
            <FormLabel p="1" style={{ fontWeight: "600", fontFamily: "Lato" }}>
              Traditional Commission Expiration Date*
            </FormLabel>
            <Input
              name="commissionExpirationDate"
              value={moment(formik.values.commissionExpirationDate).format(
                "YYYY-MM-DD"
              )}
              maxW={"400px"}
              minW={"230px"}
              type="date"
              min={today}
              placeholder="Commission Number"
              color={"grey"}
              onChange={(e) => {
                console.log("date", e.target.value);
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.commissionExpirationDate &&
              formik.errors.commissionExpirationDate
                ? formik.errors.commissionExpirationDate
                : ""}
            </Text>
          </FormControl>
          <FormControl p="1">
            <FormLabel
              style={{ fontWeight: "600", fontFamily: "Lato" }}
              {...(isFileUploaded ? { color: "#000080" } : {})}
            >
              Upload a copy of your traditional commission*
            </FormLabel>

            {notaryProfile.copyOfNotaryCommission ? (
              <Text mb={3} fontSize={"13px"} fontWeight={500}>
                ( {formik.values.copyOfNotaryCommission.split("-").slice(-1)})
              </Text>
            ) : (
              <Text mb={3} fontSize={"13px"} fontWeight={500}>
                Accept file format only jpeg, png, jpg and pdf
              </Text>
            )}

            <UploadDoc
              // url={fileUrl}
              acceptFileType={[".pdf", ".jpeg", ".jpg", ".png"]}
              filePath={formik.values.copyOfNotaryCommission}
              onUpload={(file) => {
                formik.setFieldValue("copyOfNotaryCommission", file);
                setIsFileUploaded(true);
              }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.copyOfNotaryCommission &&
              formik.errors.copyOfNotaryCommission
                ? formik.errors.copyOfNotaryCommission
                : ""}
            </Text>
          </FormControl>
        </Flex>

        <Box ml="auto" p="2">
          <Flex justifyContent={"space-between"}>
            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              onClick={() =>
                navigate(`${notaryPaths.profileSetting}/nna-details`)
              }
            >
              PREV
            </Button>

            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              type="submit"
            >
              SAVE & NEXT
            </Button>
          </Flex>
        </Box>
      </form>
    </Stack>
  );
};

export default CommissionDetailsForm;
