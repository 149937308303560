import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Notary, SignerApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";

const InviteInhouseNotaryModal = ({
  isOpen,
  onClose,
  onOpen,
  businessId,
  notaryList,
  data,
  handleAdd,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  businessId: number;
  notaryList: Notary[];
  data?: Notary[];
  handleAdd: () => void;
}) => {
  const { configuration } = useAuth();
  const [email, setEmail] = useState<string>("");

  const filteredList = notaryList?.filter(
    (x) => !data?.map((el) => el.notaryId)?.includes(x.notaryId)
  );

  const matchedList = filteredList?.filter((item) =>
    item?.user?.email?.includes(email)
  );

  const handleAddNotary = (id: string) => {
    new SignerApi(configuration)
      .signerControllerUpdateInhouseNotary({
        businessId: businessId,
        updateInhouseNotaries: {
          inhouseNotaries: [id],
        },
      })
      .then((res) => {
        handleAdd();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setEmail("");
      }}
      size={"2xl"}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight="700"
            fontFamily="Lato"
          >
            Add Notary
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <Divider />

        <ModalBody mx={5} mb={7} p={4}>
          <Text>
            Enter the email of notary that you want to add as your in-house
            notary
          </Text>

          <Box mt={4}>
            <Input
              width={"60%"}
              value={email}
              placeholder="john@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            {email?.length > 2 && matchedList?.length !== 0 && (
              <Box
                mt={4}
                pl={2}
                // width={"60%"}
                maxHeight={"40vh"}
                overflow="auto"
              >
                <Text fontWeight={500} fontSize="15px">
                  List of notary that already registered
                </Text>
                {matchedList?.flatMap((el, idx) => (
                  <Box key={idx}>
                    <Flex
                      pt={3}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Text fontSize="15px">{el?.user?.email}</Text>
                      <Button
                        style={{
                          background:
                            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                        }}
                        fontSize={"15px"}
                        fontWeight={"normal"}
                        height={"32px"}
                        onClick={() =>
                          handleAddNotary(el?.notaryId?.toString())
                        }
                      >
                        + Add
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </ModalBody>

        <Divider />

        <ModalFooter>
          {/* {email?.length > 3 && filteredList?.length === 0 && (
            <Button
              style={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              }}
              fontSize={"16px"}
              fontWeight={"normal"}
              height={"40px"}
              onClick={onOpen}
              mr={3}
              width={"120px"}
            >
              Send Invite
            </Button>
          )} */}

          <Button
            fontSize={"16px"}
            fontWeight={"normal"}
            height={"40px"}
            _hover={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#fff",
            }}
            border={"1px solid #D5AD36"}
            backgroundColor={"#fff"}
            color={"#D5AD36"}
            onClick={() => {
              onClose();
              setEmail("");
            }}
            width={"120px"}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteInhouseNotaryModal;
