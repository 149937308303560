import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useParticipants } from "@livekit/components-react";
import { useEffect, useReducer } from "react";
import { BiExit } from "react-icons/bi";
import { Socket } from "socket.io-client";
import { useActiveSessionStore } from "../../../store/activeSessionStore";
import { useMessagesStore } from "../../../store/mesagesStore";
import { useResponsive } from "../../../store/store";
import { useAuth } from "../../../utils/use-auth";
import { getFileUrl } from "../../../utils/utils-apis";
import SessionPdfEditor from "../../SessionPdfEditor/SessionPdfEditor";
import ConfereneceParticipants from "./ConferenceParticipants";

interface ICommonMeetingProps {
  socket: Socket;
  from: string;
  children?: JSX.Element;
  sessionID: string | null;
  onSetSessionParticipants?: (value: any[]) => void;
}

const CommonMeeting = ({
  from,
  socket,
  children,
  sessionID,
  onSetSessionParticipants,
}: ICommonMeetingProps) => {
  const { configuration } = useAuth();
  const { clearMessages } = useMessagesStore();
  const { setIsMobileView } = useResponsive();
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);
  const participants = useParticipants();

  const [notaryUrlData, dispatch] = useReducer(
    (state: any, { type, payload }: any) => {
      if (type === "initialize") {
        return {
          ...payload,
        };
      }
    },
    {
      notarySignatureUrl: "",
      notaryInitialsUrl: "",
      notaryStampUrl: "",
    }
  );

  useEffect(() => {
    if (sessionInfo?.notary) {
      Promise.all([
        getFileUrl(configuration, sessionInfo?.notary?.signature!),
        getFileUrl(configuration, sessionInfo?.notary?.initials!),
        getFileUrl(configuration, sessionInfo?.notary?.seal!),
      ]).then((r) => {
        dispatch({
          type: "initialize",
          payload: {
            notarySignatureUrl: r[0],
            notaryInitialsUrl: r[1],
            notaryStampUrl: r[2],
          },
        });
      });
    }
  }, [sessionInfo?.notary]);

  useEffect(() => {
    const resizeObserver = () => {
      if (window.innerWidth <= 790) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", resizeObserver);
    return () => {
      window.removeEventListener("resize", resizeObserver);
    };
  }, []);

  // useEffect(() => {
  //   if (onSetSessionParticipants) {
  //     onSetSessionParticipants(participants);
  //   }
  // }, [participants]);

  return (
    <>
      <Flex
        direction={{ base: "column-reverse", md: "row", lg: "row" }}
        width="100%"
        background={"#fff"}
      >
        {/* videos start here */}
        <Flex
          w={["100%", "100%", "17%"]}
          direction={{ base: "row", md: "column", lg: "column" }}
          background={"#fff"}
          height={["auto", "100vh", "100vh"]}
          overflowY={"auto"}
          gap={2}
          pt={4}
          borderRight="1px solid #aaa"
        >
          <ConfereneceParticipants />
        </Flex>
        {/* videos ends here */}
        {/* other than the video, starts here */}
        <Box width={["100%", "100%", "83%"]}>
          {/* top buttons start */}
          <Flex
            zIndex={5}
            p={4}
            background="#fff"
            borderBottom="1px solid #aaa"
            alignItems={"center"}
            height="10vh"
          >
            {from !== "notary" && (
              <Button
                fontSize={"16px"}
                fontWeight={"normal"}
                color={"#df2c14"}
                height={"40px"}
                _hover={{
                  background: "#df2c14",
                  color: "#fff",
                }}
                border={"1px solid #df2c14"}
                backgroundColor={"white"}
                onClick={() => {
                  clearMessages();
                  window.location.href = "/";
                }}
              >
                <BiExit /> <Text ml={2}>Exit</Text>
              </Button>
            )}
            <Box flexGrow={1}>{children !== undefined && children}</Box>
          </Flex>
          {/* top buttons end */}

          {/* pdf editor start */}
          <Box height={"90vh"}>
            {sessionID !== null ? (
              <SessionPdfEditor
                socket={socket}
                sessionId={sessionID}
                from={from}
                notaryUrlData={notaryUrlData}
              />
            ) : null}
          </Box>
          {/* pdf editor end */}
        </Box>
        {/* other than the video, ends here */}
      </Flex>
      {/* </>
      )} */}
    </>
  );
};

export default CommonMeeting;
