/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanDTO
 */
export interface PlanDTO {
    /**
     * 
     * @type {boolean}
     * @memberof PlanDTO
     */
    isSubscription: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    planName: string;
    /**
     * 
     * @type {number}
     * @memberof PlanDTO
     */
    planAmount: number;
    /**
     * 
     * @type {string}
     * @memberof PlanDTO
     */
    planFeatures: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanDTO
     */
    isActive: boolean;
}

/**
 * Check if a given object implements the PlanDTO interface.
 */
export function instanceOfPlanDTO(value: object): value is PlanDTO {
    if (!('isSubscription' in value) || value['isSubscription'] === undefined) return false;
    if (!('planName' in value) || value['planName'] === undefined) return false;
    if (!('planAmount' in value) || value['planAmount'] === undefined) return false;
    if (!('planFeatures' in value) || value['planFeatures'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    return true;
}

export function PlanDTOFromJSON(json: any): PlanDTO {
    return PlanDTOFromJSONTyped(json, false);
}

export function PlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'isSubscription': json['isSubscription'],
        'planName': json['planName'],
        'planAmount': json['planAmount'],
        'planFeatures': json['planFeatures'],
        'isActive': json['isActive'],
    };
}

export function PlanDTOToJSON(value?: PlanDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isSubscription': value['isSubscription'],
        'planName': value['planName'],
        'planAmount': value['planAmount'],
        'planFeatures': value['planFeatures'],
        'isActive': value['isActive'],
    };
}

