import { Flex } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Legend, Label, Sector } from "recharts";
import { IPieChartData } from "../../../../store/zustandType";
import { useResponsive } from "../../../../store/store";

const InvoiceColors = {
  paid: "#4d9660",
  open: "#4f75c9",
  uncollectible: "#f9b452",
  draft: "#737373",
  unpaid: "#f84854",
  void: "#800000",
};

const SessionColors = {
  completed: "#4d9660",
  booked: "#4f75c9",
  pending: "#f9b452",
  terminated: "#f84854",
  expired: "#737373",
};

const PieChartComponent = ({
  totalCount,
  graphData,
  label,
}: {
  label: string;
  totalCount: number;
  graphData: IPieChartData[];
}) => {
  const { isMobileView } = useResponsive();
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (item: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <>
        <Flex alignItems={"flex-start"} justifyContent="space-between">
          {payload.map((entry: any, index: number) => {
            if (entry.value === "no") return null;
            return (
              <div
                key={`item-${index}`}
                style={{
                  fontSize: 14,
                  marginRight: 15,
                  textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 5,
                    display: "inline-block",
                    background:
                      label === "Sessions"
                        ? // @ts-ignore
                          SessionColors[entry.value]
                        : // @ts-ignore
                          InvoiceColors[entry.value],
                  }}
                />
                {entry.value}
              </div>
            );
          })}
        </Flex>
      </>
    );
  };

  const renderCenterLabel = () => {
    return (
      <>
        <Label
          value={label}
          position="centerBottom"
          fontSize="16px"
          fontWeight="500"
          color="#000"
        />
        <Label
          value={totalCount}
          position="centerTop"
          className="label-top"
          fontWeight="500"
          color="#000"
        />
      </>
    );
  };

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {label}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        {!isMobileView && (
          <>
            <path
              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
              stroke={fill}
              fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          </>
        )}
        <text
          // x={ex + (cos >= 0 ? 1 : -1) * 12}
          x={ex + (isMobileView ? (cos >= 0 ? -3 : 3) : cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
          style={{
            textTransform: "capitalize",
            fontSize: "13px",
            fontWeight: 500,
          }}
        >{`${payload.name}-${value}`}</text>
        <text
          x={ex + (isMobileView ? (cos >= 0 ? -2 : 2) : cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          // fill="#999"
          fill={fill}
          style={{ textTransform: "capitalize", fontSize: "12px" }}
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <PieChart width={isMobileView ? 350 : 500} height={300}>
      <Legend
        align="right"
        verticalAlign="bottom"
        layout="vertical"
        wrapperStyle={{ fontSize: "1rem" }}
        content={renderLegend}
      />
      {totalCount === 0 ? (
        <Pie
          data={[{ name: "no", value: 1 }]}
          cx={isMobileView ? 150 : 230}
          cy={120}
          innerRadius={isMobileView ? 45 : 67}
          outerRadius={isMobileView ? 55 : 80}
          dataKey="value"
          color="#777777"
        >
          {renderCenterLabel()}
        </Pie>
      ) : (
        <Pie
          data={graphData}
          cx={isMobileView ? 150 : 230}
          cy={120}
          innerRadius={isMobileView ? 45 : 67}
          outerRadius={isMobileView ? 55 : 80}
          isAnimationActive={true}
          animationDuration={2000}
          fill="#8884d8"
          paddingAngle={totalCount === 0 ? 0 : 2}
          dataKey="value"
          nameKey="name"
          activeIndex={activeIndex}
          onMouseEnter={onPieEnter}
          activeShape={renderActiveShape}
        >
          {graphData?.map((entry, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                fill={
                  label === "Sessions"
                    ? // @ts-ignore
                      SessionColors[entry.name]
                    : // @ts-ignore
                      InvoiceColors[entry.name]
                }
              />
            );
          })}
          {renderCenterLabel()}
        </Pie>
      )}
    </PieChart>
  );
};

export default PieChartComponent;
