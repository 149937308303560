/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadRecordingDTO
 */
export interface UploadRecordingDTO {
    /**
     * 
     * @type {number}
     * @memberof UploadRecordingDTO
     */
    activeSessionId: number;
    /**
     * 
     * @type {number}
     * @memberof UploadRecordingDTO
     */
    notaryId: number;
}

/**
 * Check if a given object implements the UploadRecordingDTO interface.
 */
export function instanceOfUploadRecordingDTO(value: object): value is UploadRecordingDTO {
    if (!('activeSessionId' in value) || value['activeSessionId'] === undefined) return false;
    if (!('notaryId' in value) || value['notaryId'] === undefined) return false;
    return true;
}

export function UploadRecordingDTOFromJSON(json: any): UploadRecordingDTO {
    return UploadRecordingDTOFromJSONTyped(json, false);
}

export function UploadRecordingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadRecordingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'activeSessionId': json['activeSessionId'],
        'notaryId': json['notaryId'],
    };
}

export function UploadRecordingDTOToJSON(value?: UploadRecordingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeSessionId': value['activeSessionId'],
        'notaryId': value['notaryId'],
    };
}

