/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeetingReview
 */
export interface MeetingReview {
    /**
     * 
     * @type {number}
     * @memberof MeetingReview
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingReview
     */
    activeSessionId: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingReview
     */
    typeOfDocumnet: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingReview
     */
    notaryNote: string;
}

/**
 * Check if a given object implements the MeetingReview interface.
 */
export function instanceOfMeetingReview(value: object): value is MeetingReview {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('activeSessionId' in value) || value['activeSessionId'] === undefined) return false;
    if (!('typeOfDocumnet' in value) || value['typeOfDocumnet'] === undefined) return false;
    if (!('notaryNote' in value) || value['notaryNote'] === undefined) return false;
    return true;
}

export function MeetingReviewFromJSON(json: any): MeetingReview {
    return MeetingReviewFromJSONTyped(json, false);
}

export function MeetingReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingReview {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'activeSessionId': json['activeSessionId'],
        'typeOfDocumnet': json['typeOfDocumnet'],
        'notaryNote': json['notaryNote'],
    };
}

export function MeetingReviewToJSON(value?: MeetingReview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'activeSessionId': value['activeSessionId'],
        'typeOfDocumnet': value['typeOfDocumnet'],
        'notaryNote': value['notaryNote'],
    };
}

