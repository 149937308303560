/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestedSubscriptionDto
 */
export interface RequestedSubscriptionDto {
    /**
     * 
     * @type {number}
     * @memberof RequestedSubscriptionDto
     */
    planId?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestedSubscriptionDto
     */
    notaryPlanId?: number;
}

/**
 * Check if a given object implements the RequestedSubscriptionDto interface.
 */
export function instanceOfRequestedSubscriptionDto(value: object): value is RequestedSubscriptionDto {
    return true;
}

export function RequestedSubscriptionDtoFromJSON(json: any): RequestedSubscriptionDto {
    return RequestedSubscriptionDtoFromJSONTyped(json, false);
}

export function RequestedSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestedSubscriptionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'] == null ? undefined : json['planId'],
        'notaryPlanId': json['notaryPlanId'] == null ? undefined : json['notaryPlanId'],
    };
}

export function RequestedSubscriptionDtoToJSON(value?: RequestedSubscriptionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'notaryPlanId': value['notaryPlanId'],
    };
}

