import { Socket } from "socket.io-client";

export const emitEvent = ({
  action,
  event,
  pageNumber,
  payload,
  sessionId,
  socket,
  docId,
}: {
  event: string;
  action: string;
  payload: any | undefined;
  pageNumber: number | undefined;
  socket: Socket | undefined;
  sessionId: string;
  docId?: number;
}) => {
  if (socket !== undefined && docId !== -1) {
    try {
      socket.emit("session-connect", sessionId, (e: boolean) => {
        if (e === true) {
          socket.emit(
            "session-auditLogs-post",
            JSON.stringify({
              event,
              action,
              payload,
              pageNumber,
              userId: Number(localStorage.getItem("UserID")),
              docId,
            })
          );
        }
      });
    } catch (err) {
      console.log("error", err);
    }
  }
};

export const emitAuditEvent = ({
  action,
  event,
  payload,
  sessionId,
  socket,
}: {
  event: string;
  action: string;
  payload: any | undefined;
  socket: Socket | undefined;
  sessionId: string;
}) => {
  if (socket !== undefined) {
    try {
      socket.emit("session-connect", sessionId, (e: boolean) => {
        if (e === true) {
          socket.emit(
            "session-auditLogs-post",
            JSON.stringify({
              event,
              action,
              payload,
            })
          );
        }
      });
    } catch (err) {
      console.log("error", err);
    }
  }
};
