import { ResponseError } from "../api";
import { jsPDF } from "jspdf";

export const handleError = async (err: any) => {
  const { errorMessage, statusCode } = await (
    err as ResponseError
  )?.response?.json();
  return { errorMessage, statusCode };
};

export const imageToPdf = (imageDataURL: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageDataURL;
    img.onload = () => {
      let pageWidth = img.naturalWidth;
      let pageHeight = img.naturalHeight;
      const pdf = new jsPDF({
        orientation: pageHeight > pageWidth ? "portrait" : "landscape",
        unit: "px",
        format: [pageHeight, pageWidth],
      });

      // Add the image to the PDF with dimensions equal to the internal dimensions of the page.
      pdf.addImage(
        imageDataURL,
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
      resolve(pdf.output("blob"));
    };
  });
};

export function jwtDecoder(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
