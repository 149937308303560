/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentROIDTO
 */
export interface DocumentROIDTO {
    /**
     * 
     * @type {Array<object>}
     * @memberof DocumentROIDTO
     */
    data: Array<object>;
}

/**
 * Check if a given object implements the DocumentROIDTO interface.
 */
export function instanceOfDocumentROIDTO(value: object): value is DocumentROIDTO {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function DocumentROIDTOFromJSON(json: any): DocumentROIDTO {
    return DocumentROIDTOFromJSONTyped(json, false);
}

export function DocumentROIDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentROIDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

export function DocumentROIDTOToJSON(value?: DocumentROIDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
    };
}

