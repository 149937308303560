/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';
import type { Business } from './Business';
import {
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
} from './Business';

/**
 * 
 * @export
 * @interface Signer
 */
export interface Signer {
    /**
     * 
     * @type {number}
     * @memberof Signer
     */
    signerId: number;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    kbaStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Signer
     */
    notifyViaText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    lastFourDigitsSsnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof Signer
     */
    zip?: number;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    firstScanImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Signer
     */
    firstBackScanImage?: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Signer
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {Date}
     * @memberof Signer
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Signer
     */
    updatedDate: Date;
    /**
     * 
     * @type {Business}
     * @memberof Signer
     */
    business?: Business;
    /**
     * 
     * @type {boolean}
     * @memberof Signer
     */
    wantSmsNotification?: boolean;
}

/**
 * Check if a given object implements the Signer interface.
 */
export function instanceOfSigner(value: object): value is Signer {
    if (!('signerId' in value) || value['signerId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function SignerFromJSON(json: any): Signer {
    return SignerFromJSONTyped(json, false);
}

export function SignerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signer {
    if (json == null) {
        return json;
    }
    return {
        
        'signerId': json['signerId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'kbaStatus': json['kbaStatus'] == null ? undefined : json['kbaStatus'],
        'notifyViaText': json['notifyViaText'] == null ? undefined : json['notifyViaText'],
        'lastFourDigitsSsnNumber': json['lastFourDigitsSsnNumber'] == null ? undefined : json['lastFourDigitsSsnNumber'],
        'addressLine1': json['addressLine1'] == null ? undefined : json['addressLine1'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
        'dob': json['dob'] == null ? undefined : json['dob'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'country': json['country'] == null ? undefined : json['country'],
        'firstScanImage': json['firstScanImage'] == null ? undefined : json['firstScanImage'],
        'firstBackScanImage': json['firstBackScanImage'] == null ? undefined : json['firstBackScanImage'],
        'user': OmitTypeClassFromJSON(json['user']),
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
        'business': json['business'] == null ? undefined : BusinessFromJSON(json['business']),
        'wantSmsNotification': json['wantSmsNotification'] == null ? undefined : json['wantSmsNotification'],
    };
}

export function SignerToJSON(value?: Signer | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'signerId': value['signerId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'kbaStatus': value['kbaStatus'],
        'notifyViaText': value['notifyViaText'],
        'lastFourDigitsSsnNumber': value['lastFourDigitsSsnNumber'],
        'addressLine1': value['addressLine1'],
        'addressLine2': value['addressLine2'],
        'dob': value['dob'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'country': value['country'],
        'firstScanImage': value['firstScanImage'],
        'firstBackScanImage': value['firstBackScanImage'],
        'user': OmitTypeClassToJSON(value['user']),
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
        'business': BusinessToJSON(value['business']),
        'wantSmsNotification': value['wantSmsNotification'],
    };
}

