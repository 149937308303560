/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StopRecordingDTO
 */
export interface StopRecordingDTO {
    /**
     * 
     * @type {string}
     * @memberof StopRecordingDTO
     */
    conferenceId: string;
}

/**
 * Check if a given object implements the StopRecordingDTO interface.
 */
export function instanceOfStopRecordingDTO(value: object): value is StopRecordingDTO {
    if (!('conferenceId' in value) || value['conferenceId'] === undefined) return false;
    return true;
}

export function StopRecordingDTOFromJSON(json: any): StopRecordingDTO {
    return StopRecordingDTOFromJSONTyped(json, false);
}

export function StopRecordingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StopRecordingDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'conferenceId': json['conferenceId'],
    };
}

export function StopRecordingDTOToJSON(value?: StopRecordingDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conferenceId': value['conferenceId'],
    };
}

