import {
  Box,
  Flex,
  Text,
  Divider,
  Heading,
  Button,
  Center,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NotaryPlan, NotaryPlansApi, TransactionApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { espTransform } from "../../../../utils/utils";
import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";
import CouponModal from "./CouponModal";
import { getNotaryDetails } from "../../../../utils/utils-apis";

const NotaryAllPlans = ({
  onClose,
  requestedPlanData,
  activePlan,
}: {
  onClose?: () => void;
  requestedPlanData?: any;
  activePlan?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");
  const { configuration } = useAuth();
  const [plans, setPlans] = useState<NotaryPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const role = localStorage.getItem("role");
  const { isOpen, onOpen, onClose: onCouponClose } = useDisclosure();
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    new NotaryPlansApi(configuration)
      .notaryPlanControllerGetAll()
      .then((res) => {
        setPlans(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlan = (planId: number) => {
    setIsDisable(true);
    new TransactionApi(configuration)
      .transactionControllerGetStripeSubscriptionRaw()
      .then(async (res) => {
        const result = await res.raw.json();
        console.log(result.status);
        if (result.status === 404) {
          new TransactionApi(configuration)
            .transactionControllerCreateCheckout({
              checkoutRequestDTO: {
                // successUrl: `${window.location.protocol}//${window.location.host}/notary/plans`,
                successUrl: `${window.location.protocol}//${window.location.host}/thank-you`,
                cancelUrl: `${window.location.protocol}//${window.location.host}`,
                notaryPlanId: planId,
                role: role as string,
              },
            })
            .then((res) => {
              setIsDisable(false);
              console.log({ res });
              window.location.href = res?.redirectUrl;
              if (onClose) {
                onClose();
              }
            })
            .catch((error) => {
              console.log(error);
              setIsDisable(false);
            });
        } else if (result.status === 200) {
          new TransactionApi(configuration)
            .transactionControllerCreateReqSubscriptionRaw({
              requestedSubscriptionDto: {
                notaryPlanId: planId,
              },
            })
            .then((res) => {
              setIsDisable(false);
              navigate("/")
              if (onClose) {
                onClose();
              }
            })
            .catch((error) => {
              console.log(error);
              setIsDisable(false);
            });
        }
      });
  };

  useEffect(() => {
    getNotaryDetails(configuration)
      .then((res) => {
        if (
          res.user.coupon === "" ||
          res.user.coupon === null ||
          res.user.coupon === undefined
        ) {
          setCouponApplied(false);
        } else {
          setCouponApplied(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={6}>
        Choose Your Subscription
      </Text>
      {requestedPlanData !== "" ? (
        <Text
          textAlign="center"
          color="red"
          fontSize="11px"
          fontWeight={"bold"}
          mb={6}
          style={{ fontStyle: "italic" }}
        >
          (Plan Change in progress. No further action is required at this time.)
        </Text>
      ) : null}

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : plans?.length === 0 ? (
        <Text textAlign="center">No Plan found</Text>
      ) : (
        <Flex flexWrap={"wrap"} gap={6} justifyContent={"center"} m={"10px"}>
          {plans.map((plan) => (
            <Box
              flexBasis={{ base: "100%", md: "40%", lg: "40%" }}
              p={"10px"}
              cursor={"pointer"}
              key={plan.notaryPlanId}
            >
              <Box
                style={{ boxShadow: "3px 5px 20px 0px #ddd" }}
                w={"100%"}
                px={5}
                py={10}
                borderRadius="10px"
                border={`${
                  Number(planId) === plan.notaryPlanId
                    ? "2px solid #000080"
                    : ""
                }`}
              >
                <Heading
                  fontSize="24px"
                  fontFamily={"Oswald"}
                  mb={"6px"}
                  textAlign="center"
                  color="#000080"
                >
                  {plan.planName}
                  {/* <span style={{ color: "#D5AD36" }}>Package</span> */}
                </Heading>
                <Divider my={4} mr={4} />
                <Text
                  fontSize={"45px"}
                  color="#000080"
                  fontFamily="Oswald"
                  textAlign="center"
                  fontWeight={500}
                >
                  {espTransform(plan.planAmount)}/
                  <span style={{ fontSize: "16px", color: "#000" }}>
                    Per month
                  </span>
                </Text>
                <Divider my={4} mr={4} />
                <div style={{ minHeight: "17rem" }}>
                  {plan?.planFeatures &&
                    JSON.parse(plan?.planFeatures)?.map(
                      (item: any, idx: number) => (
                        <Flex key={idx} alignItems="flex-start" mb={3}>
                          <Box mt={1}>
                            <CheckIcon size={18} color="#D5AD36" />
                          </Box>

                          <Text ml={3} fontSize="16px">
                            {item}
                          </Text>
                        </Flex>
                      )
                    )}
                </div>

                <Divider my={4} mr={4} />
                {/* {requestedPlanData === plan?.planName && (
                  <Text fontSize={"12px"} textAlign="center" mb={2}>
                    *You already have this plan
                  </Text>
                )} */}
                <Flex
                  justifyContent={
                    plan.planName === "Pro Plan" && couponApplied === false
                      ? "space-between"
                      : "center"
                  }
                >
                  {plan.planName === "Pro Plan" && couponApplied === false && (
                    <Flex justifyContent={"center"}>
                      <Button
                        px={8}
                        fontSize={"12px"}
                        fontWeight={600}
                        onClick={() => {
                          setSelectedPlan(plan?.notaryPlanId);
                          onOpen();
                        }}
                        borderRadius={"34px"}
                      >
                        Apply Coupon Code
                      </Button>
                    </Flex>
                  )}

                  <Button
                    isDisabled={
                      isDisable ||
                      (plan.planName === "Pro Plan"
                        ? activePlan === plan?.planName
                        : false) ||
                      isDisable ||
                      (requestedPlanData ? true : false) ||
                      isDisable ||
                      (activePlan === plan.planName ? true : false)
                    }
                    px={8}
                    fontSize={"14px"}
                    fontWeight={600}
                    borderRadius={"34px"}
                    style={{
                      background:
                        "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                      color: "#fff",
                    }}
                    onClick={() => {
                      setSelectedPlan(plan?.notaryPlanId);
                      handlePlan(plan.notaryPlanId);
                    }}
                  >
                    Get Started Now
                  </Button>
                </Flex>
              </Box>
              <CouponModal
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onCouponClose}
                onApply={() => handlePlan(selectedPlan)}
              />
            </Box>
          ))}
        </Flex>
      )}
    </div>
  );
};

export default NotaryAllPlans;
