import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { currentMonth, currentTrimester, currentYear } from "../../utils/utils";

const filterByYearList = Array.from(
  { length: 7 },
  (x, idx) => currentYear - idx
).map((year) => ({ value: year, label: year }));

const filterByMonthList = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

const filterByTrimesterList = [
  { value: 1, label: "1st Trimester" },
  { value: 2, label: "2nd Trimester" },
  { value: 3, label: "3rd Trimester" },
  { value: 4, label: "4th Trimester" },
];

const filterPeriodTypes = {
  trimester: "trimester",
  monthly: "monthly",
  annual: "year",
  custom: "custom",
};

const filterTypes = {
  presets: "presets",
  custom: "customRange",
};

const FilterComponent = ({
  onFilterChange,
}: {
  onFilterChange: (value: any) => void;
}) => {
  const [filterYear, setFilterYear] = useState(currentYear);
  const [customEndDate, setCustomEndDate] = useState(moment());
  const [filterTrimester, setFilterTrimester] = useState(currentTrimester);
  const [filterMonthStart, setFilterMonthStart] = useState(currentMonth - 1);
  const [selectedFilterTypes, setSelectedTypes] = useState<string>(
    filterTypes.presets
  );
  const [filterType, setFilterType] = useState<string>(
    filterPeriodTypes.trimester
  );
  const [filterMonthEnd, setFilterMonthEnd] = useState(
    currentMonth === 12 ? currentMonth - 1 : currentMonth
  );
  const [customStartDate, setCustomStartDate] = useState(
    moment().subtract(1, "week")
  );

  const handleSet = () => {
    let startDate: any;
    let endDate: any;
    if (
      filterType === filterPeriodTypes.annual &&
      selectedFilterTypes === filterTypes.presets
    ) {
      startDate = moment()
        .utc()
        .startOf("year")
        .set("year", filterYear)
        .utc(false);
      endDate = moment().utc().endOf("year").set("year", filterYear).utc(false);
    } else if (
      filterType === filterPeriodTypes.monthly &&
      selectedFilterTypes === filterTypes.presets
    ) {
      startDate = moment()
        .utc()
        .set("month", filterMonthStart)
        .startOf("month")
        .set("year", filterYear);
      endDate = moment()
        .utc()
        .set("month", filterMonthEnd)
        .endOf("month")
        .set("year", filterYear);
    } else if (
      filterType === filterPeriodTypes.trimester &&
      selectedFilterTypes === filterTypes.presets
    ) {
      startDate = moment()
        .utc()
        .set("quarter", filterTrimester)
        .startOf("quarter");
      endDate = moment().utc().set("quarter", filterTrimester).endOf("quarter");
    } else {
      startDate = customStartDate.startOf("day").utc();
      endDate = customEndDate.endOf("day").utc();
    }
    const savedFilter = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    onFilterChange(savedFilter);
  };

  const renderFilterPeriodSelect = (
    dataList: any[],
    value: any,
    onChange: (e: any) => void,
    label?: string
  ) => (
    <Box>
      {label && <small>{label}</small>}
      <ChakraSelect
        value={value}
        className="w-100 mt-1"
        onChange={onChange}
        // options={dataList}
      >
        {dataList?.map((data: any, idx: number) => (
          <option key={idx} value={data.value}>
            {data.label}
          </option>
        ))}
      </ChakraSelect>
    </Box>
  );

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant={"outline"}
          // style={{
          //   boxShadow: "-1px 5px 15px 0px #ddd",
          // }}
        >
          <Text mr={2}>Filter</Text> <MdKeyboardArrowDown />
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>Filter</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Text>Period</Text>
            <Tabs
              variant="unstyled"
              onChange={(index) => {
                if (index === 0) {
                  setFilterType(filterPeriodTypes?.monthly);
                } else if (index === 1) {
                  setFilterType(filterPeriodTypes.trimester);
                } else {
                  setFilterType(filterPeriodTypes?.annual);
                }
              }}
              mt={2}
            >
              <TabList gap={3}>
                <Tab
                  _selected={{
                    color: "#000080",
                    border: "2px solid #000080",
                    fontWeight: 500,
                  }}
                  border={"1px solid #000000"}
                  borderRadius="lg"
                  py={1}
                  fontSize="14px"
                >
                  Monthly
                </Tab>
                <Tab
                  _selected={{
                    color: "#000080",
                    border: "2px solid #000080",
                    fontWeight: 500,
                  }}
                  border={"1px solid #000000"}
                  borderRadius="lg"
                  py={1}
                  fontSize="14px"
                >
                  Trimester
                </Tab>
                <Tab
                  _selected={{
                    color: "#000080",
                    border: "2px solid #000080",
                    fontWeight: 500,
                  }}
                  border={"1px solid #000000"}
                  borderRadius="lg"
                  py={1}
                  fontSize="14px"
                >
                  Annual
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <>
                    {renderFilterPeriodSelect(
                      filterByYearList,
                      filterYear,
                      (e) => setFilterYear(e.target.value),
                      "Select Year"
                    )}
                    <Flex mt={2} gap={3}>
                      {renderFilterPeriodSelect(
                        filterByMonthList,
                        filterMonthStart,
                        (e) => {
                          setFilterMonthStart(e.target.value);
                          setFilterMonthEnd(e.target.value);
                        },
                        "From"
                      )}
                      {renderFilterPeriodSelect(
                        filterByMonthList.filter(
                          (v) => v.value >= filterMonthStart
                        ),
                        filterMonthEnd,
                        (e) => {
                          setFilterMonthEnd(e.target.value);
                        },
                        "To"
                      )}
                    </Flex>
                  </>
                </TabPanel>
                <TabPanel>
                  {renderFilterPeriodSelect(
                    filterByTrimesterList,
                    filterTrimester,
                    (e) => setFilterTrimester(e.target.value)
                  )}
                </TabPanel>
                <TabPanel>
                  {renderFilterPeriodSelect(filterByYearList, filterYear, (e) =>
                    setFilterYear(e.target.value)
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
          <PopoverFooter>
            <Button style={{ backgroundColor: "#D5AD36" }} onClick={handleSet}>
              Apply
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default FilterComponent;
