import {
  Box,
  Button,
  Center,
  Flex,
  Td,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { GrDocumentText as ActionIcon } from "react-icons/gr";
import { BsCheck2Circle } from "react-icons/bs";
import { useAuth } from "../../../utils/use-auth";
import { LogData } from "../../../utils/utils-apis";
import { useEffect, useState } from "react";
import { getMonth, getStatusBgColor } from "../../../utils/utils";
import { EventAction } from "../../../store/auditLogs";
import DetailsModal from "../../commonComponents/NotaryModals/DetailsModal";
import RecordingPreviewer from "./RecordingPreviewer";
import { useNavigate } from "react-router-dom";
import {
  ActiveSessionApi,
  AuditLogs,
  AuditLogsApi,
  KBALogs,
  Session,
  SessionApi,
  SignedDocumentApi,
  SignedPdfDTO,
  Signer,
} from "../../../api";
import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import NoteModal from "./NoteModal";
import { INewSession } from "../../../store/zustandType";
import { notaryPaths } from "../../../routes/routes";
import moment from "moment";

const TableData = ({
  session,
  signer,
  onSetCallApi,
}: {
  session: INewSession;
  signer: Signer;
  onSetCallApi: () => void;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showSession, setshowSession] = useState<Session>();
  const [notarialActDate, setNotarialActDate] = useState<Date>();
  const [stampDetails, setStampDetails] = useState<AuditLogs[]>([]);
  const [auditData, setAuditData] = useState<AuditLogs[]>([]);
  const [signers, setSigners] = useState<Signer[]>([]);
  const [signedDocPath, setSignedDocPath] = useState<SignedPdfDTO[]>([]);
  const [recordingKey, setRecordingKey] = useState<string>("");
  const [kbaData, setKbaData] = useState<KBALogs | null>();
  const [auditDetails, setAuditDetails] = useState<AuditLogs[]>([]);
  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const {
    isOpen: isNoteOpen,
    onOpen: onNoteOpen,
    onClose: onNoteClose,
  } = useDisclosure();

  const [meetingInfo, setMeetingReview] = useState<{
    notaryNote: string;
    documentType: string;
  }>({
    notaryNote: "",
    documentType: "",
  });

  const kbaLogId = session?.kbaLogs?.find(
    (kba) => Number(kba.userId) === signer?.user?.id
  )?.kbaLogId;

  useEffect(() => {
    if (!session?.isNotaryExemplarSheet) {
      if (
        kbaLogId === "demo" ||
        kbaLogId === "knownByNotary" ||
        kbaLogId === undefined ||
        kbaLogId === "pending" ||
        kbaLogId === ""
      ) {
        setKbaData(null);
      } else {
        LogData(configuration, Number(kbaLogId)).then((res) => {
          setKbaData(res);
          return res;
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbaLogId, session?.isNotaryExemplarSheet]);

  useEffect(() => {
    if (session?.status !== "pending") {
      new AuditLogsApi(configuration)
        .auditLogsControllerGetAudiLogsBySessionId({
          sessionId: session?.sessionId,
        })
        .then((audits) => {
          setAuditData(audits);
          setAuditDetails(audits?.filter((el) => el.event === "formAdded"));
          const stampEvent = audits?.filter(
            (el) =>
              el.event === "notaryStamp" &&
              (el.action === EventAction.add ||
                el.action === EventAction.delete)
          );
          if (stampEvent.length !== 0) {
            setStampDetails(stampEvent);
          } else {
            setStampDetails([]);
          }
        })
        .catch((err) => {
          setAuditDetails([]);
          setStampDetails([]);
        });
    }
  }, [session?.status]);

  const getSignedDocPath = () => {
    new SignedDocumentApi(configuration)
      .signedDocumentControllerGetPdf({
        sessionId: session?.sessionId,
      })
      .then((res) => {
        setSignedDocPath(res);
      })
      .catch((err) => {
        setSignedDocPath([]);
      });
  };

  const getMeetingReview = () => {
    new ActiveSessionApi(configuration)
      .activeSessionControllerGetMeeting({
        sessionId: session?.sessionId,
      })
      .then((res) => {
        setMeetingReview({
          notaryNote: res?.notaryNote,
          documentType: res?.typeOfDocumnet,
        });
      })
      .catch((err) => {
        console.log(err);
        setMeetingReview({
          notaryNote: "",
          documentType: "",
        });
      });
  };

  const getRecording = () => {
    new ActiveSessionApi(configuration)
      .activeSessionControllerGetRecordingbySessionId({
        sessionId: session?.sessionId,
      })
      .then((res) => {
        setNotarialActDate(res.createdDate);
        setRecordingKey(res.recordingKey);
      })
      .catch((err) => {
        console.log(err);
        setRecordingKey("");
      });
  };

  const getCompleteMeetingData = () => {
    getRecording();
    getMeetingReview();
    getSignedDocPath();
    onOpen();
  };

  const getSigners = () => {
    new SessionApi(configuration)
      .sessionControllerGetJournalSessions({
        sessionId: session?.sessionId,
      })
      .then((res) => {
        setSigners(res?.signers);
      });
  };

  const totalJurat = auditDetails?.filter(
    //  @ts-ignore
    (el) => el.payload?.typeOfForm === "Jurat"
  )?.length;

  const totalAcknowlegement = auditDetails?.filter(
    //  @ts-ignore
    (el) => el.payload?.typeOfForm === "Acknowlegement"
  )?.length;

  const addJuratstamp = stampDetails?.filter(
    (el) =>
      el.action === EventAction.add &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Jurat"
  )?.length;

  const ackAddCount = stampDetails?.filter(
    (el) =>
      el.action === EventAction.add &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Acknowlegement"
  ).length;

  const deleteJuratstamp = stampDetails?.filter(
    (el) =>
      el.action === EventAction.delete &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Jurat"
  ).length;

  const ackDeleteCount = stampDetails?.filter(
    (el) =>
      el.action === EventAction.delete &&
      // @ts-ignore
      el.payload?.typeOfStamp === "Acknowlegement"
  ).length;

  const totalAcknowlegementStampCount = ackAddCount - ackDeleteCount;

  const totalJuratStampCount = addJuratstamp - deleteJuratstamp;

  return (
    <>
      <Td>{session?.sessionId}</Td>
      <Td>
        <Text
          py={"4px"}
          fontSize="12px"
          textAlign="center"
          textTransform="capitalize"
          borderRadius="20px"
          width="80px"
          color="#fff"
          style={{
            background: getStatusBgColor(session?.status),
          }}
        >
          {session?.status ?? "Pending"}
        </Text>
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {moment(
          session?.scheduleDate !== ""
            ? session?.scheduleDate
            : session?.createdDate
        ).format("MM/DD/YYYY h:mm:ss A")}
        {/* {session.scheduleDate !== ""
          ? moment(session?.scheduleDate).format("MM/DD/YYYY h:mm:ss A")
          : session?.createdDate?.toLocaleString("en-Us")} */}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {/* {totalJurat !== 0 ? `Jurat(${totalJurat}), ` : ""}
        {totalAcknowlegement !== 0
          ? `Acknowlegement(${totalAcknowlegement})`
          : ""} */}
        {totalJuratStampCount !== 0 ? `Jurat(${totalJuratStampCount}), ` : ""}
        {totalAcknowlegementStampCount !== 0
          ? `Acknowlegement(${totalAcknowlegementStampCount})`
          : ""}
      </Td>
      <Td style={{ color: "#9A9595" }}>{session?.documents?.[0]?.name}</Td>
      <Td style={{ color: "#9A9595", textTransform: "capitalize" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : `${signer?.firstName} ${signer?.lastName}`}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : signer?.addressLine1 !== undefined && signer?.addressLine1 !== ""
          ? `${signer?.addressLine1}, ${signer?.addressLine2} ${
              "," + signer?.state
            },
            ${signer?.city}, ${signer?.zip}`
          : "No address provided"}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : "KBA + Credetial Analysis"}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : // @ts-ignore
          kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
              "id-scan-document-type"
            ] === "driverLicense"
          ? "Driver License"
          : // @ts-ignore
            kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
              "id-scan-document-type"
            ]}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : kbaData === null
          ? "No Data"
          : `${getMonth(
              // @ts-ignore
              kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-date-of-issuance"
              ]?.month
            )}, ${
              // @ts-ignore
              kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-date-of-issuance"
              ]?.year
            }`}
      </Td>
      <Td style={{ color: "#9A9595" }}>
        {session?.isNotaryExemplarSheet
          ? "Exemplar"
          : kbaLogId === "knownByNotary"
          ? "Known By Notary"
          : kbaLogId === "demo"
          ? "Demo"
          : kbaData === null
          ? "No Data"
          : `${getMonth(
              // @ts-ignore
              kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-expiration-date"
              ]?.month
            )}, ${
              // @ts-ignore
              kbaData?.scanResponse?.response?.["located-id-scan-record"]?.[
                "id-scan-expiration-date"
              ]?.year
            }`}
      </Td>
      <Td style={{ color: "#9A9595" }}>{session?.invoiceNumber}</Td>
      <Td style={{ color: "#9A9595" }}>Notarized online via Notarize Genie</Td>
      <Td style={{ color: "#9A9595", display: "flex" }}>
        {session?.notaryNote ? (
          <>
            <Text mr={1}>{session?.notaryNote}</Text>
            <AiOutlineEdit
              role={"button"}
              color={"#000080"}
              onClick={onNoteOpen}
            />
          </>
        ) : (
          <Text
            color={"#000080"}
            fontSize="12px"
            role={"button"}
            textDecoration="underline"
            onClick={onNoteOpen}
          >
            +Add Note
          </Text>
        )}
      </Td>
      <Td>
        <Center>
          {session?.status === "completed" ? (
            <BsCheck2Circle color="green" size={18} />
          ) : (
            <AiOutlineCloseCircle color="red" size={18} />
          )}
        </Center>
      </Td>
      <Td
        textAlign={"center"}
        color={session.status === "completed" ? "green" : "#9A9595"}
        textDecoration={session.status === "completed" ? "underline" : "none"}
        onClick={() => {
          if (session.status === "completed") {
            getRecording();
            onVideoOpen();
          }
        }}
        cursor={session.status === "completed" ? "pointer" : "default"}
      >
        {session.status === "completed" ? "Open" : "No Video"}
      </Td>
      <Td>
        <Flex>
          <Box
            role="button"
            onClick={() => {
              setshowSession(session);
              getSigners();
              if (session.status === "completed") {
                getCompleteMeetingData();
              } else {
                onOpen();
              }
            }}
          >
            <ActionIcon size={20} color="#5856d6" />
          </Box>
          {session?.status === "booked" && (
            <Button
              ml={3}
              width={"fit-content"}
              style={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                color: "#000",
                padding: "0px 10px",
                height: "25px",
              }}
              onClick={() =>
                navigate(
                  `${notaryPaths.notaryOndemand}/notary-session?session-id=${session?.sessionId}`
                )
              }
            >
              Join
            </Button>
          )}
        </Flex>
      </Td>

      <DetailsModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        session={showSession as Session}
        recordingKey={recordingKey}
        meetingInfo={meetingInfo}
        actDate={notarialActDate}
        signedDocPath={signedDocPath}
        signers={signers}
        stampDetails={stampDetails}
        auditData={auditData}
      />
      <RecordingPreviewer
        isOpen={isVideoOpen}
        onClose={onVideoClose}
        onOpen={onVideoOpen}
        session={showSession as Session}
        recordingKey={recordingKey}
      />
      <NoteModal
        sessionId={session?.sessionId}
        note={session?.notaryNote}
        isOpen={isNoteOpen}
        onClose={onNoteClose}
        onOpen={onNoteOpen}
        onSetCallApi={onSetCallApi}
      />
    </>
  );
};

export default TableData;
