import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import FlatList from "flatlist-react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/use-auth";
import { getStateName } from "../../../utils/utils";
import { Notary, NotaryApi, SignerApi } from "../../../api";
import { getBusinessDetails } from "../../../utils/utils-apis";
import { RiDeleteBin6Line as DeleteIcon } from "react-icons/ri";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import NoDataFound from "../../../component/commonComponents/LoadingComponents/NoDataFound";
import InviteInhouseNotaryModal from "./components/InviteInhouseNotaryModal";
import useNotification from "../../../utils/useNotification";

const BusinessInhouseNotary = () => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Notary[] | undefined>([]);
  const [businessId, setBusinessId] = useState<number>(-1);
  const [notaryList, setNotaryList] = useState<Notary[]>([]);

  const getNotariesList = () => {
    new NotaryApi(configuration).notaryControllerFindAllNotary().then((res) => {
      setNotaryList(res?.filter((el) => el?.isInhouseNotary === false));
    });
  };

  useEffect(() => {
    getNotariesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    getBusinessDetails(configuration)
      .then((res) => {
        setBusinessId(res?.businessId);
        setData(res?.inhouseNotaries);
        setLoading(false);
        getNotariesList();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id: number) => {
    new SignerApi(configuration)
      .signerControllerRemoveInhouseNotary({
        businessId: businessId,
        removeNotary: {
          notaryId: id,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          notification("success", res?.message?.toString() as string);
          getData();
        }
      });
  };

  const listData = (item: Notary) => (
    <Tr style={{ fontWeight: 500 }}>
      <Td style={{ color: "#9A9595" }}>{item?.notaryId}</Td>
      <Td style={{ color: "#9A9595", textTransform: "capitalize" }}>
        {item?.firstName + " "} {item?.middleName + " "} {item?.lastName + " "}
        {item?.suffix}
      </Td>
      <Td style={{ color: "#9A9595" }}>{item.city}</Td>
      <Td style={{ color: "#9A9595" }}>{getStateName(item.state)}</Td>
      <Td>
        <Box
          ml={2.5}
          role="button"
          onClick={() => {
            handleDelete(item.notaryId);
          }}
        >
          <DeleteIcon size={20} color={"red"} />
        </Box>
      </Td>
    </Tr>
  );

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"}>
      <Heading
        fontSize={"22px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={4}
        fontFamily="Oswald"
        fontWeight={600}
        color={"#2D3748"}
      >
        In-house Notary
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Here the list of your In-house notaries. You can also add new notary in
        your list.
      </Text>

      <Box>
        <Flex justifyContent={"space-between"} pr={2}>
          <InputGroup width={"40%"} mb={8}>
            <InputLeftElement
              pointerEvents="none"
              children={<Search color="gray.300" />}
            />
            <Input
              type="text"
              autoFocus
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder="Search by Notary Id or Name"
            />
          </InputGroup>

          <Button
            style={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
            }}
            fontSize={"16px"}
            fontWeight={"normal"}
            height={"40px"}
            onClick={onOpen}
          >
            + Add Notary
          </Button>
        </Flex>
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : data?.length === 0 ? (
          <NoDataFound />
        ) : (
          <>
            <TableContainer
              height={{ base: "392px", md: "unset", lg: "unset" }}
              style={{ overflow: "auto" }}
            >
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th style={{ color: "#000" }}>NOTARY ID</Th>
                    <Th style={{ color: "#000" }}>NAME</Th>
                    <Th style={{ color: "#000" }}>CITY</Th>
                    <Th style={{ color: "#000" }}>STATE</Th>
                    <Th style={{ color: "#000" }}>ACTIONS</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <FlatList
                    keyExtractor={(item: Notary, index: number) =>
                      index.toString()
                    }
                    list={data as Notary[]}
                    renderItem={listData}
                    renderWhenEmpty={() => (
                      <div style={{ marginTop: "10px" }}>No Items</div>
                    )}
                    searchTerm={searchTerm}
                    searchableMinCharactersCount={1}
                    searchBy={["notaryId", "firstName"]}
                    searchCaseInsensitive
                  />
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      <InviteInhouseNotaryModal
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        businessId={businessId}
        notaryList={notaryList}
        data={data}
        handleAdd={getData}
      />
    </Stack>
  );
};

export default BusinessInhouseNotary;
