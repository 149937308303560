/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ActiveSession } from './ActiveSession';
import {
    ActiveSessionFromJSON,
    ActiveSessionFromJSONTyped,
    ActiveSessionToJSON,
} from './ActiveSession';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Notary } from './Notary';
import {
    NotaryFromJSON,
    NotaryFromJSONTyped,
    NotaryToJSON,
} from './Notary';
import type { Session } from './Session';
import {
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';

/**
 * 
 * @export
 * @interface AuditLogs
 */
export interface AuditLogs {
    /**
     * 
     * @type {number}
     * @memberof AuditLogs
     */
    auditLogId: number;
    /**
     * 
     * @type {object}
     * @memberof AuditLogs
     */
    details?: object;
    /**
     * 
     * @type {string}
     * @memberof AuditLogs
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogs
     */
    action?: string;
    /**
     * 
     * @type {object}
     * @memberof AuditLogs
     */
    payload?: object;
    /**
     * 
     * @type {number}
     * @memberof AuditLogs
     */
    pageNumber?: number;
    /**
     * 
     * @type {ActiveSession}
     * @memberof AuditLogs
     */
    activeSession?: ActiveSession | null;
    /**
     * 
     * @type {Session}
     * @memberof AuditLogs
     */
    session?: Session;
    /**
     * 
     * @type {Notary}
     * @memberof AuditLogs
     */
    notary?: Notary;
    /**
     * 
     * @type {User}
     * @memberof AuditLogs
     */
    user?: User | null;
    /**
     * 
     * @type {number}
     * @memberof AuditLogs
     */
    docId: number;
    /**
     * 
     * @type {Date}
     * @memberof AuditLogs
     */
    createdDate: Date;
}

/**
 * Check if a given object implements the AuditLogs interface.
 */
export function instanceOfAuditLogs(value: object): value is AuditLogs {
    if (!('auditLogId' in value) || value['auditLogId'] === undefined) return false;
    if (!('docId' in value) || value['docId'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    return true;
}

export function AuditLogsFromJSON(json: any): AuditLogs {
    return AuditLogsFromJSONTyped(json, false);
}

export function AuditLogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogs {
    if (json == null) {
        return json;
    }
    return {
        
        'auditLogId': json['auditLogId'],
        'details': json['details'] == null ? undefined : json['details'],
        'event': json['event'] == null ? undefined : json['event'],
        'action': json['action'] == null ? undefined : json['action'],
        'payload': json['payload'] == null ? undefined : json['payload'],
        'pageNumber': json['pageNumber'] == null ? undefined : json['pageNumber'],
        'activeSession': json['activeSession'] == null ? undefined : ActiveSessionFromJSON(json['activeSession']),
        'session': json['session'] == null ? undefined : SessionFromJSON(json['session']),
        'notary': json['notary'] == null ? undefined : NotaryFromJSON(json['notary']),
        'user': json['user'] == null ? undefined : UserFromJSON(json['user']),
        'docId': json['docId'],
        'createdDate': (new Date(json['createdDate'])),
    };
}

export function AuditLogsToJSON(value?: AuditLogs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'auditLogId': value['auditLogId'],
        'details': value['details'],
        'event': value['event'],
        'action': value['action'],
        'payload': value['payload'],
        'pageNumber': value['pageNumber'],
        'activeSession': ActiveSessionToJSON(value['activeSession']),
        'session': SessionToJSON(value['session']),
        'notary': NotaryToJSON(value['notary']),
        'user': UserToJSON(value['user']),
        'docId': value['docId'],
        'createdDate': ((value['createdDate']).toISOString()),
    };
}

