import { useEffect } from "react";
import { SessionParticipants } from "../../../../component/Sessions/Signer/SessionParticipants";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SessionParticipantsPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  return <SessionParticipants />;
};

export default SessionParticipantsPage;
