import { Stack, Text, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSessionCreate } from "../../../store/store";
import SessionDocUpload from "../../commonComponents/UploadComponents/SessionDocUpload";
import DemoSessionComponent from "../components/DemoSessionComponent";
import { signerPaths } from "../../../routes/routes";

const SignerDocumentUpload = () => {
  const navigate = useNavigate();
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  return (
    <Stack spacing={8}>
      <Text
        fontWeight={600}
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        textAlign={"center"}
        fontFamily={"oswald"}
      >
        Document Upload
      </Text>

      <SessionDocUpload
        file={sessionDetail?.documents}
        onUpload={(file) => {
          setSessionDetails({
            ...sessionDetail,
            documents: file,
          });
        }}
      />

      {/* {!sessionDetail.isTitleLenderSession && <DemoSessionComponent />} */}

      <Flex justifyContent={"flex-end"}>
        <Button
          mx={2}
          style={{ padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            navigate(`${signerPaths.createSession}/type`);
          }}
        >
          Prev
        </Button>
        <Button
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          width={"fit-content"}
          alignSelf={"end"}
          isDisabled={sessionDetail.documents === undefined}
          onClick={() => {
            navigate(`${signerPaths.createSession}/participants`);
          }}
        >
          Next
        </Button>
      </Flex>
    </Stack>
  );
};

export default SignerDocumentUpload;
