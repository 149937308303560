import { useEffect } from "react";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";
import SessionTypeDetails from "../../../../component/Sessions/components/SessionTypeDetails";
import { notaryPaths } from "../../../../routes/routes";

const SessionTypePage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(0);
  }, [setStep]);

  return (
    <SessionTypeDetails
      nextButtonPath={`${notaryPaths.createSession}/primary-signer`}
    />
  );
};

export default SessionTypePage;
