import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import phone from "../assets/images/NoPhone.png";

const AccessErrorScreen = () => {
  return (
    <Box
      w="100vw"
      h="100vh"
      bg="linear-gradient(180deg, rgba(0, 0, 128) 0%, #000080 100%)"
      // bg="#D5AD36"
      textAlign="center"
    >
      <Flex alignItems={"center"} justify="center">
        <Box
          mt={"16vh"}
          pt={"150px"}
          px={10}
          w="65vw"
          h="65vh"
          bg="#D5AD36"
          borderRadius={"full"}
        >
          <Center>
            <Image src={phone} height={"120px"} width={"120px"} />
          </Center>
          <Text mt={6} fontSize="24px" fontWeight={600}>
            Notary can't access through their phone devices
          </Text>
          <Text mt={2} fontSize="16px">
            (Please use your laptop or computer)
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default AccessErrorScreen;
