import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const FloridaStamp = ({
  name,
  height,
  width,
  commissionNumber,
  expires,
}: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 432.000000 164.000000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: "transparent" }}
      stroke="transparent"
    >
      {/* ****************************** Name ******************************  */}
      {name.length > 22 ? (
        <>
          <text
          x="60%"
          y="21%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
            {notaryProfile.firstName} {notaryProfile.middleName}
          </text>
          <text
          x="60%"
          y="33%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
            {notaryProfile.lastName} {notaryProfile.suffix}
          </text>
        </>
      ) : (
        <text
          x="60%"
          y="30%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          {name}
        </text>
      )}

      {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

      <text
        x="62%"
        y="48%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"18px"}
        fontWeight="bold"
      >
        Notary Public - State of Florida
      </text>

      {/* ****************************** Text -commission ******************************  */}

      <text
        x="62%"
        y="63%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"19px"}
        fontWeight="bold"
      >
        Commission #{commissionNumber}
      </text>

      {/* *************************************** Text -Expires ******************************************** */}

      <text
        x="62%"
        y="78%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"19px"}
        fontWeight="bold"
      >
        Expires on {moment(expires).format("MMM DD, YYYY")}
      </text>

      <g
        transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M160 1552 c0 -17 10 -38 25 -52 45 -43 45 -114 0 -168 -34 -40 -34
-94 0 -134 45 -54 45 -125 0 -168 -30 -28 -35 -99 -9 -121 24 -20 44 -65 44
-101 0 -34 -32 -98 -50 -98 -5 0 -10 -22 -10 -50 0 -27 4 -50 9 -50 17 0 51
-67 51 -99 0 -35 -20 -80 -44 -100 -21 -18 -21 -94 0 -112 23 -19 44 -64 44
-94 0 -30 -21 -75 -44 -94 -9 -8 -16 -22 -16 -32 0 -30 69 -26 117 7 31 21 46
25 81 21 25 -3 53 -14 66 -26 32 -31 104 -29 152 4 23 15 51 25 73 25 22 0 51
-10 73 -25 49 -33 105 -33 154 0 23 16 51 25 74 25 23 0 51 -9 74 -25 48 -33
120 -35 152 -4 13 12 41 23 66 26 35 4 50 0 81 -21 51 -35 113 -35 164 0 31
21 46 25 81 21 25 -3 53 -14 66 -26 32 -31 104 -29 152 4 23 16 51 25 74 25
23 0 51 -9 74 -25 49 -33 105 -33 154 0 48 33 98 33 146 0 48 -33 120 -35 152
-4 13 12 41 23 66 26 35 4 50 0 81 -21 51 -35 112 -35 163 -1 22 15 51 25 71
25 34 0 93 -27 93 -42 0 -4 22 -8 50 -8 36 0 59 7 86 25 23 16 51 25 74 25 23
0 51 -9 74 -25 27 -18 50 -25 86 -25 28 0 50 4 50 9 0 14 65 41 98 41 16 0 46
-11 66 -25 51 -34 112 -34 163 1 31 21 46 25 81 21 25 -3 53 -14 66 -26 32
-31 104 -29 152 4 48 33 98 33 146 0 47 -32 108 -35 108 -5 0 10 -11 36 -25
56 -34 50 -34 112 0 152 34 40 34 98 0 148 -30 44 -33 102 -8 145 10 16 21 29
25 29 4 0 8 23 8 51 0 40 -5 58 -25 81 -34 40 -34 102 0 152 34 50 34 108 0
148 -18 21 -25 41 -25 72 0 30 8 55 25 80 33 49 33 103 0 152 -17 25 -25 50
-25 80 0 31 7 51 25 72 14 17 25 42 25 56 0 39 -43 35 -91 -9 -31 -28 -47 -35
-80 -35 -47 0 -94 22 -109 50 -17 33 -66 26 -111 -15 -34 -30 -46 -35 -89 -35
-43 0 -55 5 -89 35 -45 41 -94 48 -111 15 -15 -28 -62 -50 -109 -50 -33 0 -49
7 -80 35 -47 43 -92 47 -116 12 -41 -63 -159 -63 -200 0 -24 35 -69 31 -116
-12 -31 -28 -47 -35 -80 -35 -47 0 -94 22 -109 50 -17 33 -66 26 -111 -15 -34
-30 -46 -35 -89 -35 -43 0 -55 5 -89 35 -45 41 -94 48 -111 15 -15 -28 -62
-50 -109 -50 -33 0 -49 7 -80 35 -44 40 -91 47 -113 17 -23 -32 -62 -52 -101
-52 -45 0 -92 22 -107 50 -17 33 -66 26 -111 -15 -34 -30 -46 -35 -88 -35 -43
0 -55 5 -87 35 -23 22 -47 35 -64 35 -17 0 -41 -13 -64 -35 -32 -30 -44 -35
-87 -35 -42 0 -54 5 -88 35 -45 41 -94 48 -111 15 -15 -28 -62 -50 -107 -50
-39 0 -78 20 -101 52 -22 30 -69 23 -113 -17 -31 -28 -47 -35 -80 -35 -47 0
-94 22 -109 50 -17 33 -66 26 -111 -15 -34 -30 -46 -35 -89 -35 -43 0 -55 5
-89 35 -27 24 -48 35 -70 35 -27 0 -31 -3 -31 -28z m3890 -747 l0 -635 -1885
0 -1885 0 0 635 0 635 1885 0 1885 0 0 -635z"
        />
        <path
          d="M750 1340 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"
        />
        <path d="M790 1343 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
        <path d="M830 1339 c0 -11 4 -18 10 -14 5 3 7 12 3 20 -7 21 -13 19 -13 -6z" />
        <path
          d="M870 1339 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"
        />
        <path d="M710 1333 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
        <path d="M910 1328 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10 -22z" />
        <path d="M950 1331 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z" />
        <path d="M670 1328 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z" />
        <path d="M987 1315 c-4 -8 -2 -17 2 -20 5 -2 11 4 14 15 6 23 -8 27 -16 5z" />
        <path d="M630 1315 c0 -15 18 -40 24 -34 3 3 -1 14 -9 25 -8 10 -14 15 -15 9z" />
        <path
          d="M1027 1306 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"
        />
        <path d="M590 1291 c0 -5 7 -14 15 -21 16 -14 18 -10 9 14 -6 17 -24 22 -24 7z" />
        <path
          d="M815 1283 c-8 -26 -20 -30 -29 -8 -3 8 -11 15 -17 15 -8 0 -7 -7 3
-22 8 -13 18 -36 22 -51 4 -16 11 -25 17 -21 6 3 8 13 5 21 -3 8 -1 23 5 34 5
11 8 28 6 37 -4 14 -6 13 -12 -5z"
        />
        <path
          d="M1060 1281 c-7 -15 -7 -21 0 -21 12 0 25 28 17 36 -3 3 -10 -4 -17
-15z"
        />
        <path
          d="M891 1246 c-13 -55 -13 -56 -1 -56 6 0 10 7 10 15 0 8 8 15 18 15 10
0 23 5 29 11 11 11 6 49 -7 49 -5 0 -15 3 -23 6 -10 4 -17 -7 -26 -40z m40 1
c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z"
        />
        <path d="M560 1275 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z" />
        <path
          d="M701 1271 c-11 -7 -11 -15 -2 -41 17 -51 14 -54 -26 -20 -42 34 -50
27 -38 -36 6 -34 25 -48 25 -19 0 8 9 15 19 15 30 0 51 11 51 26 0 11 4 12 19
4 30 -15 36 -12 17 9 -10 11 -15 25 -11 31 8 13 -12 40 -29 40 -6 0 -17 -4
-25 -9z m29 -21 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z"
        />
        <path d="M1095 1259 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
        <path
          d="M970 1231 c-16 -39 -10 -65 17 -69 16 -2 28 6 43 30 22 37 25 48 10
48 -5 0 -16 -13 -25 -30 -17 -34 -41 -41 -31 -10 3 11 9 29 12 40 10 31 -12
23 -26 -9z"
        />
        <path
          d="M530 1235 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
        />
        <path
          d="M1127 1243 c-4 -3 -7 -13 -7 -21 0 -12 3 -12 15 -2 8 7 15 16 15 21
0 11 -14 12 -23 2z"
        />
        <path
          d="M500 1205 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
        />
        <path
          d="M568 1195 c-30 -25 -36 -40 -15 -32 6 2 27 -9 45 -26 22 -22 32 -26
32 -16 0 9 -9 24 -20 34 -14 12 -18 25 -14 41 8 29 6 29 -28 -1z"
        />
        <path d="M1155 1209 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
        <path
          d="M1046 1180 c-32 -38 -32 -48 -3 -59 24 -9 47 3 47 24 0 8 6 15 13 18
9 3 9 9 1 23 -17 28 -30 27 -58 -6z m44 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-20 -28 c0 -13 -12 -22 -22 -16 -10 6 -1
24 13 24 5 0 9 -4 9 -8z"
        />
        <path
          d="M474 1175 c21 -16 32 -13 21 4 -3 6 -14 11 -23 11 -15 -1 -15 -2 2
-15z"
        />
        <path d="M1180 1180 c-8 -5 -11 -12 -7 -16 4 -4 15 0 24 9 18 18 8 23 -17 7z" />
        <path
          d="M450 1145 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
        />
        <path
          d="M736 1144 c-154 -56 -226 -243 -150 -390 106 -208 398 -206 501 4 73
148 -2 334 -155 386 -56 20 -144 19 -196 0z m203 -20 c48 -20 103 -63 95 -75
-3 -5 -14 -7 -24 -4 -22 7 -33 -25 -14 -43 19 -18 46 -4 38 20 -11 35 19 15
40 -26 18 -37 15 -59 -6 -38 -17 17 -40 -11 -30 -39 6 -15 11 -18 16 -11 4 7
12 10 17 7 5 -4 9 1 9 9 0 9 5 16 10 16 6 0 10 -20 10 -45 0 -25 -4 -45 -10
-45 -5 0 -10 7 -10 16 0 13 -3 14 -13 5 -8 -6 -17 -8 -20 -4 -4 3 -7 -6 -7
-21 0 -15 4 -25 9 -21 5 3 13 0 16 -5 5 -7 12 -5 22 7 14 17 14 17 9 0 -3 -11
-9 -30 -12 -43 -7 -27 -24 -33 -24 -9 0 19 -30 16 -46 -5 -16 -19 14 -44 33
-28 25 21 13 -10 -16 -41 -20 -22 -38 -31 -60 -31 -29 0 -30 2 -24 30 4 21 2
30 -7 30 -10 0 -11 17 -8 65 3 36 2 65 -3 65 -5 0 -9 7 -9 15 0 20 10 19 25
-2 31 -43 64 33 40 92 -7 17 -21 50 -31 75 -10 25 -20 48 -23 51 -3 4 -55 10
-115 14 -88 5 -107 4 -104 -7 3 -7 13 -15 24 -17 10 -1 27 -10 36 -18 11 -10
28 -13 44 -10 34 6 63 -25 64 -66 0 -16 9 -40 20 -54 11 -14 20 -31 20 -37 0
-6 -7 -3 -14 7 -20 26 -30 20 -12 -8 20 -30 20 -35 1 -35 -8 0 -16 10 -17 23
0 12 -2 28 -2 35 -1 7 -6 16 -12 20 -7 4 -14 16 -16 27 l-5 20 -8 -20 c-5 -11
-23 -34 -39 -51 -51 -52 -53 -56 -48 -122 3 -45 1 -62 -8 -62 -9 0 -11 -10 -6
-30 5 -29 4 -30 -30 -30 -40 0 -72 29 -107 95 -16 30 -22 58 -22 115 0 65 4
82 31 129 67 119 208 167 333 115z m-102 -39 c17 -3 45 -7 61 -7 26 -1 31 -7
52 -62 28 -77 37 -136 20 -136 -16 0 -50 39 -50 56 0 7 -5 14 -10 16 -6 2 -12
18 -15 37 -5 46 -42 84 -73 76 -24 -6 -52 9 -52 27 0 5 8 7 18 4 9 -2 31 -7
49 -11z m23 -191 c14 -35 14 -34 -20 -34 -21 0 -28 4 -25 15 3 8 7 22 10 30 7
23 24 18 35 -11z m-54 0 c-3 -9 -6 -20 -6 -25 0 -5 -7 -9 -15 -9 -12 0 -13 4
-3 25 12 27 34 35 24 9z m-29 -146 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13
2 -33 0 -45z m50 0 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z
m20 0 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m20 0 c-2 -13
-4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m40 0 c-2 -13 -4 -3 -4 22 0
25 2 35 4 23 2 -13 2 -33 0 -45z m-92 -66 c-6 -5 -26 -18 -45 -30 -31 -18 -38
-19 -60 -7 l-25 14 33 1 c22 0 32 5 32 15 0 11 11 15 37 15 21 0 33 -4 28 -8z
m115 -7 c0 -10 10 -15 28 -15 21 0 24 -2 12 -10 -22 -14 -31 -12 -75 15 l-40
24 38 1 c26 0 37 -4 37 -15z m-47 -19 c21 -13 35 -26 30 -29 -18 -11 -90 -16
-123 -9 l-35 8 40 26 c22 15 42 27 45 27 3 0 22 -10 43 -23z"
        />
        <path
          d="M669 1042 c-24 -4 -30 -37 -9 -49 12 -8 19 -7 27 6 13 21 2 48 -18
43z"
        />
        <path
          d="M607 953 c-14 -4 -16 -8 -6 -19 6 -8 8 -14 3 -15 -5 0 0 -4 11 -8 27
-11 49 17 29 36 -7 7 -14 12 -16 12 -2 -1 -11 -3 -21 -6z"
        />
        <path
          d="M596 854 c-9 -22 10 -43 34 -37 19 5 17 31 -4 45 -17 11 -23 10 -30
-8z"
        />
        <path
          d="M624 763 c-2 -26 22 -38 40 -21 19 18 8 46 -17 44 -15 0 -23 -8 -23
-23z"
        />
        <path d="M1207 1153 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z" />
        <path
          d="M1103 1124 c-34 -27 -34 -32 1 -78 14 -17 16 -17 55 5 23 12 41 26
41 31 0 13 -12 10 -49 -13 -18 -11 -36 -18 -39 -15 -3 4 -2 6 3 6 4 0 1 7 -6
16 -11 14 -10 20 14 40 48 41 30 48 -20 8z"
        />
        <path
          d="M505 1127 c-25 -19 -13 -60 21 -76 22 -10 30 -9 45 5 25 22 24 30 -6
59 -28 29 -34 30 -60 12z m43 -19 c18 -18 14 -38 -7 -38 -20 0 -35 25 -25 41
7 12 18 11 32 -3z"
        />
        <path d="M420 1120 c0 -12 28 -25 36 -17 3 3 -4 10 -15 17 -15 7 -21 7 -21 0z" />
        <path
          d="M1225 1110 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
        />
        <path d="M400 1081 c0 -12 28 -22 35 -12 3 5 -2 12 -11 15 -21 8 -24 8 -24 -3z" />
        <path
          d="M1250 1080 c-8 -5 -12 -11 -9 -14 2 -3 11 -1 19 4 8 5 12 11 9 14 -2
3 -11 1 -19 -4z"
        />
        <path
          d="M460 1049 c0 -5 10 -14 23 -19 21 -8 20 -9 -10 -15 -45 -10 -41 -20
12 -38 45 -16 65 -4 23 13 -22 8 -21 9 10 15 44 10 40 20 -13 38 -25 9 -45 12
-45 6z"
        />
        <path
          d="M390 1040 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"
        />
        <path d="M1260 1040 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z" />
        <path
          d="M1146 1027 c-14 -10 -17 -21 -12 -40 3 -15 11 -27 16 -27 6 0 8 11 4
24 -8 32 32 48 53 20 16 -22 31 -10 17 14 -14 22 -54 27 -78 9z"
        />
        <path
          d="M380 1000 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5 0
-9 -4 -9 -10z"
        />
        <path
          d="M1265 1000 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
        />

        <path
          d="M370 960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1270 960 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12 0
-21 -4 -21 -10z"
        />

        <path
          d="M452 923 c2 -10 10 -18 18 -18 22 0 18 29 -3 33 -14 3 -18 -1 -15
-15z"
        />
        <path
          d="M1190 927 c0 -19 10 -27 26 -20 21 8 17 33 -6 33 -11 0 -20 -6 -20
-13z"
        />
        <path
          d="M360 920 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10 -8
0 -14 -4 -14 -10z"
        />
        <path
          d="M1280 920 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />

        <path
          d="M360 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M1280 880 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M478 850 c-15 -24 -28 -26 -28 -5 0 8 -5 15 -11 15 -6 0 -9 -10 -7
-22 4 -31 44 -37 53 -9 7 23 25 28 26 9 0 -7 2 -23 4 -34 4 -17 -2 -23 -27
-30 -17 -5 -33 -15 -35 -21 -3 -9 5 -9 32 0 30 9 38 8 45 -3 17 -27 21 -3 8
46 -19 76 -37 92 -60 54z"
        />
        <path
          d="M1185 853 c-38 -9 -37 -21 3 -42 52 -26 54 -26 46 4 -4 15 -2 25 5
25 6 0 11 5 11 10 0 10 -29 11 -65 3z"
        />
        <path
          d="M360 840 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"
        />
        <path
          d="M1280 840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
        />
        <path
          d="M365 800 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8 0
-18 -4 -21 -10z"
        />
        <path
          d="M1270 800 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10 -8
0 -14 -4 -14 -10z"
        />
        <path
          d="M1143 778 c-25 -32 -23 -38 13 -53 48 -20 61 -19 67 6 11 40 -56 78
-80 47z m54 -23 c3 -9 3 -18 0 -21 -7 -8 -47 15 -47 26 0 16 40 11 47 -5z"
        />
        <path
          d="M2185 770 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"
        />
        <path
          d="M2380 760 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"
        />
        <path
          d="M375 760 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
        />
        <path
          d="M1270 760 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"
        />

        <path
          d="M1250 731 c0 -5 7 -11 15 -15 8 -3 15 -1 15 3 0 5 -7 11 -15 15 -8 3
-15 1 -15 -3z"
        />
        <path
          d="M390 720 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0 -16
-4 -16 -10z"
        />
        <path
          d="M488 723 c-33 -5 -36 -18 -7 -26 16 -4 19 -11 14 -26 -10 -33 9 -25
44 19 l32 40 -28 -1 c-15 -1 -40 -4 -55 -6z m42 -17 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M1110 721 c0 -14 68 -53 82 -49 7 3 -6 17 -29 31 -46 29 -53 32 -53
18z"
        />

        <path
          d="M569 701 c-1 -3 -1 -10 0 -15 1 -5 -10 -22 -25 -37 -36 -37 -22 -51
15 -15 23 22 30 24 40 13 9 -11 7 -20 -9 -40 -25 -32 -25 -39 1 -55 26 -17 39
-6 14 12 -12 9 -15 15 -7 20 7 4 17 4 23 0 8 -4 9 0 4 15 -7 23 4 28 22 10 6
-6 13 -7 15 -2 3 4 -17 29 -44 54 -26 25 -49 43 -49 40z"
        />
        <path d="M410 690 c-8 -5 -12 -12 -9 -15 8 -8 39 5 39 16 0 11 -11 11 -30 -1z" />
        <path
          d="M1083 685 c-21 -16 -12 -37 30 -69 31 -23 50 -14 21 10 -18 15 -18
15 9 11 35 -6 46 10 12 19 -14 3 -25 12 -25 19 0 20 -26 25 -47 10z m27 -25
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"
        />
        <path d="M1244 685 c11 -8 22 -12 25 -9 6 6 -19 24 -34 24 -5 -1 -1 -7 9 -15z" />
        <path
          d="M425 650 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
        />
        <path
          d="M1215 650 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"
        />
        <path
          d="M1019 624 c-26 -32 34 -101 65 -75 21 18 20 54 -2 74 -23 21 -46 22
-63 1z m51 -19 c7 -8 9 -22 6 -32 -6 -15 -9 -15 -28 4 -11 12 -17 26 -14 32
10 15 21 14 36 -4z"
        />
        <path d="M451 621 c-8 -5 -9 -11 -3 -15 5 -3 15 1 22 9 14 16 3 20 -19 6z" />
        <path d="M1203 613 c9 -9 20 -13 24 -9 4 4 1 11 -7 16 -25 16 -35 11 -17 -7z" />
        <path d="M477 593 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z" />
        <path d="M1180 585 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
        <path
          d="M694 566 c-23 -36 -15 -70 17 -74 42 -5 73 53 44 82 -23 23 -42 20
-61 -8z m44 -23 c-4 -32 -38 -46 -38 -16 0 20 18 43 33 43 4 0 7 -12 5 -27z"
        />
        <path
          d="M950 580 c0 -24 30 -80 43 -80 7 0 22 7 33 16 18 15 18 15 -7 10 -23
-5 -29 -1 -39 24 -13 32 -30 49 -30 30z"
        />
        <path
          d="M898 573 c-21 -5 -21 -3 -7 -58 6 -25 15 -42 21 -39 5 3 6 14 3 25
-4 13 -2 19 9 19 24 0 19 18 -6 23 l-23 4 23 8 c42 14 25 30 -20 18z"
        />
        <path d="M505 559 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
        <path
          d="M776 539 c-9 -45 -7 -69 4 -69 6 0 10 8 10 19 0 11 10 22 23 26 19 6
19 8 5 12 -24 6 -23 23 2 23 11 0 20 5 20 10 0 6 -13 10 -29 10 -24 0 -29 -5
-35 -31z"
        />
        <path
          d="M1150 555 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
        />
        <path d="M1120 545 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z" />
        <path d="M535 529 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />

        <path d="M566 504 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16 -16z" />
        <path d="M1090 512 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z" />

        <path d="M600 485 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17 -11z" />
        <path
          d="M1056 485 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4 0
-6 -7 -3 -15z"
        />
        <path
          d="M636 465 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"
        />
        <path
          d="M1020 471 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"
        />

        <path
          d="M677 464 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23 -2
6 -7 8 -11 4z"
        />
        <path
          d="M980 463 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22 -5
0 -10 -3 -10 -7z"
        />
        <path
          d="M717 454 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23 -2
6 -7 8 -11 4z"
        />
        <path
          d="M950 440 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0 -4
-9 -4 -20z"
        />
        <path
          d="M750 429 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5 0
-10 -9 -10 -21z"
        />
        <path d="M910 433 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
        <path
          d="M790 419 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5 0
-10 -9 -10 -21z"
        />
        <path
          d="M830 419 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5 0
-10 -9 -10 -21z"
        />
        <path d="M870 419 c0 -11 4 -18 10 -14 5 3 7 12 3 20 -7 21 -13 19 -13 -6z" />
        <path
          d="M2180 395 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"
        />
      </g>
    </svg>
  );
};
export default FloridaStamp;
