import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
} from "@chakra-ui/react";
import NotaryAllPlans from "./NotaryAllPlans";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  requestedPlanData?: string;
  activePlan?: string;
};

const NotaryPlansModal = ({
  isOpen,
  onClose,
  onOpen,
  requestedPlanData,
  activePlan,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={"6xl"}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent p={6}>
        <ModalCloseButton
          style={{
            background: "#D5AD36",
            color: "#fff",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            position: "absolute",
            right: "-9px",
            top: "-14px",
          }}
        />
        <ModalBody>
          <NotaryAllPlans
            requestedPlanData={requestedPlanData}
            activePlan={activePlan}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotaryPlansModal;
