import { Heading, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import TitleLenderSession from "./TitleLenderSession";

const SessionTypeDetails = ({ nextButtonPath }: { nextButtonPath: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Session Type
      </Heading>

      <TitleLenderSession />

      <Flex justifyContent={"flex-end"} mt={"20px"}>
        <Button
          marginTop={5}
          ml={2}
          style={{ background: "#2D3748", color: "#fff", padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            navigate(nextButtonPath);
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default SessionTypeDetails;
