import {
  Box,
  Flex,
  Text,
  Divider,
  Heading,
  Button,
  useDisclosure,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BaseLayout from "../../../layout/BaseLayout";
import { useAuth } from "../../../utils/use-auth";
import { espTransform } from "../../../utils/utils";
import { BsCheckCircleFill as CheckIcon } from "react-icons/bs";
import NotaryPlansModal from "./Components/NotaryPlansModal";
import NotaryAllPlans from "./Components/NotaryAllPlans";
import {
  NotaryPlansApi,
  RequestedSubscriptionApi,
  Subscription,
  SubscriptionApi,
  TransactionApi,
} from "../../../api";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";

const NotaryPlans = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const selectedPlanId = searchParams.get("plan");
  const [activePlan, setActivePlan] = useState<Subscription>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestedPlanData, setRequestedPlanData] = useState<{
    planName: string;
    activeDate: Date | null;
  }>({ planName: "", activeDate: null });

  const {
    isOpen: isPlanModalOpen,
    onOpen: onPlanModalOpen,
    onClose: onPlanClose,
  } = useDisclosure();

  useEffect(() => {
    if (selectedPlanId === null) return;
    const handlePlanPurchase = () => {
      new TransactionApi(configuration)
        .transactionControllerGetStripeSubscriptionRaw()
        .then(async (res) => {
          const result = await res.raw.json();
          console.log(result.status);
          if (result.status === 404) {
            new TransactionApi(configuration)
              .transactionControllerCreateCheckout({
                checkoutRequestDTO: {
                  // successUrl: `${window.location.protocol}//${window.location.host}/notary/plans`,
                  successUrl: `${window.location.protocol}//${window.location.host}/thank-you`,
                  cancelUrl: `${window.location.protocol}//${window.location.host}`,
                  notaryPlanId: Number(selectedPlanId),
                  role: "notary",
                },
              })
              .then((res) => {
                window.location.href = res?.redirectUrl;
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (result.status === 200) {
            new TransactionApi(configuration)
              .transactionControllerCreateReqSubscriptionRaw({
                requestedSubscriptionDto: {
                  notaryPlanId: Number(selectedPlanId),
                },
              })
              .then((res) => {
                navigate("/");
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
    };

    new NotaryPlansApi(configuration)
      .notaryPlanControllerGetAll()
      .then((res) => {
        if (
          res.find((plan) => plan?.notaryPlanId === Number(selectedPlanId))
            ?.planName === "Pro Plan"
        ) {
          handlePlanPurchase();
        }
      });
  }, [selectedPlanId]);

  useEffect(() => {
    setLoading(true);
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        setActivePlan(res);
        setLoading(false);
        new RequestedSubscriptionApi(configuration)
          .reqSubscriptionControllerGetPending()
          .then((result) => {
            if (result.status === 200) {
              setRequestedPlanData({
                planName: result?.planName,
                activeDate: result?.activePlanCreatedDate,
              });
            } else {
              setRequestedPlanData({
                planName: "",
                activeDate: null,
              });
            }
          })
          .catch((error) => {
            setRequestedPlanData({
              planName: "",
              activeDate: null,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : activePlan !== undefined ? (
        <div>
          <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={6}>
            Your Active Plan
          </Text>

          {requestedPlanData?.activeDate !== null && (
            <Text textAlign="center" fontSize="13px" mb={6}>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Note
              </span>{" "}
              - Your requested plan ({requestedPlanData?.planName}) will be
              active on{" "}
              {moment(requestedPlanData?.activeDate).format("MM-DD-YYYY")}
            </Text>
          )}

          <Center>
            <Box
              style={{ boxShadow: "3px 5px 20px 0px #ddd" }}
              maxW={"400px"}
              px={5}
              py={10}
              borderRadius="10px"
            >
              <Heading
                fontSize="24px"
                fontFamily={"Oswald"}
                mb={"6px"}
                textAlign="center"
                color="#000080"
              >
                {activePlan?.notaryPlan?.planName}{" "}
                <span style={{ color: "#D5AD36" }}>Package</span>
              </Heading>
              <Divider my={4} mr={4} />
              <Text
                fontSize={"45px"}
                color="#000080"
                fontFamily="Oswald"
                textAlign="center"
                fontWeight={500}
              >
                {espTransform(Number(activePlan?.notaryPlan?.planAmount ?? 0))}/
                <span style={{ fontSize: "16px", color: "#000" }}>
                  Per month
                </span>
              </Text>

              <div>
                <Divider my={4} mr={4} />
                {activePlan?.notaryPlan != null &&
                  JSON.parse(activePlan?.notaryPlan!.planFeatures)?.map(
                    (item: any, idx: number) => (
                      <Flex key={idx} alignItems="flex-start" mb={3}>
                        <Box mt={1}>
                          <CheckIcon size={18} color="#D5AD36" />
                        </Box>
                        <Text ml={3} fontSize="16px">
                          {item}
                        </Text>
                      </Flex>
                    )
                  )}
              </div>

              <Divider my={4} mr={4} />
              <Center>
                <Button
                  px={10}
                  fontSize={"14px"}
                  fontWeight={600}
                  borderRadius={"34px"}
                  style={{
                    background:
                      "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                    color: "#fff",
                  }}
                  onClick={() => {
                    onPlanModalOpen();
                  }}
                >
                  Switch Plan
                </Button>
              </Center>
            </Box>
          </Center>
        </div>
      ) : (
        <NotaryAllPlans requestedPlanData={requestedPlanData?.planName} />
      )}

      <NotaryPlansModal
        isOpen={isPlanModalOpen}
        onOpen={onPlanModalOpen}
        onClose={onPlanClose}
        requestedPlanData={requestedPlanData?.planName}
        activePlan={activePlan?.notaryPlan?.planName}
      />
    </BaseLayout>
  );
};

export default NotaryPlans;
