/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    messageId: number;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Message
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    imageLink?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    videoLink?: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    createdDate: Date;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): value is Message {
    if (!('messageId' in value) || value['messageId'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    return true;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if (json == null) {
        return json;
    }
    return {
        
        'messageId': json['messageId'],
        'user': OmitTypeClassFromJSON(json['user']),
        'title': json['title'],
        'message': json['message'],
        'link': json['link'] == null ? undefined : json['link'],
        'imageLink': json['imageLink'] == null ? undefined : json['imageLink'],
        'videoLink': json['videoLink'] == null ? undefined : json['videoLink'],
        'createdDate': (new Date(json['createdDate'])),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'messageId': value['messageId'],
        'user': OmitTypeClassToJSON(value['user']),
        'title': value['title'],
        'message': value['message'],
        'link': value['link'],
        'imageLink': value['imageLink'],
        'videoLink': value['videoLink'],
        'createdDate': ((value['createdDate']).toISOString()),
    };
}

