/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReqDTO
 */
export interface ReqDTO {
    /**
     * 
     * @type {Date}
     * @memberof ReqDTO
     */
    activePlanCreatedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ReqDTO
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof ReqDTO
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof ReqDTO
     */
    planName: string;
}

/**
 * Check if a given object implements the ReqDTO interface.
 */
export function instanceOfReqDTO(value: object): value is ReqDTO {
    if (!('activePlanCreatedDate' in value) || value['activePlanCreatedDate'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('planName' in value) || value['planName'] === undefined) return false;
    return true;
}

export function ReqDTOFromJSON(json: any): ReqDTO {
    return ReqDTOFromJSONTyped(json, false);
}

export function ReqDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReqDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'activePlanCreatedDate': (new Date(json['activePlanCreatedDate'])),
        'message': json['message'],
        'status': json['status'],
        'planName': json['planName'],
    };
}

export function ReqDTOToJSON(value?: ReqDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activePlanCreatedDate': ((value['activePlanCreatedDate']).toISOString()),
        'message': value['message'],
        'status': value['status'],
        'planName': value['planName'],
    };
}

