import { Heading, Button, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ScheduleMeetingData from "../components/ScheduleMeetingData";
import { notaryPaths } from "../../../routes/routes";

const NotaryMeetingDetails = () => {
  const navigate = useNavigate();

  return (
    <>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign="center"
        mb="30px"
        fontFamily="Oswald"
        fontWeight={600}
      >
        Notary Meeting Details
      </Heading>

      <ScheduleMeetingData />

      <Flex justifyContent={"flex-end"}>
        <Button
          marginTop={5}
          width={"fit-content"}
          alignSelf={"end"}
          style={{ padding: "10px 30px" }}
          onClick={() => {
            navigate(`${notaryPaths.createSession}/primary-signer`);
          }}
        >
          Prev
        </Button>
        <Button
          marginTop={5}
          ml={2}
          style={{ background: "#2D3748", color: "#fff", padding: "10px 30px" }}
          width={"fit-content"}
          alignSelf={"end"}
          onClick={() => {
            navigate(`${notaryPaths.createSession}/document-upload`);
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default NotaryMeetingDetails;
