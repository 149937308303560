import { Heading, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Session, SessionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";
import WitnessSessionTable from "../../commonComponents/SessionTable/WitnessSessionTable";
import { notaryPaths } from "../../../routes/routes";

const ActiveWitnessSessions = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [witnessSessions, setWitnessSessions] = useState<Session[]>([]);

  useEffect(() => {
    new SessionApi(configuration)
      .sessionControllerGetFilteredWitnessSessions({
        userId: Number(localStorage.getItem("UserID")),
      })
      .then((res) => {
        setWitnessSessions(res);
      })
      .catch((err) => {
        setWitnessSessions([]);
      });
  }, []);

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"}>
      <Heading
        fontSize={"22px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={4}
        fontFamily="Oswald"
        fontWeight={600}
        color={"#2D3748"}
      >
        My Sessions
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Sessions you joined as a witness will appear here. To join the session,
        simply click on the join button as it appears in the designated queue.
      </Text>

      {witnessSessions.length === 0 ? (
        <NoDataFound text="No Session Found" from="session" />
      ) : (
        <WitnessSessionTable
          sessions={witnessSessions}
          startSession={(sessionId: number) => {
            navigate(
              `${notaryPaths.notaryOndemand}/witness-session/?session-id=${sessionId}`
            );
          }}
          isActive={true}
        />
      )}
    </Stack>
  );
};

export default ActiveWitnessSessions;
