import { Heading } from "@chakra-ui/react";
import Participants from "../components/SessionParticipants/Participants";
import { notaryPaths } from "../../../routes/routes";

const PrimarySignerDetails = () => {
  return (
    <>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign="center"
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Participants Details
      </Heading>

      <Participants
        nextNavigationPath={`${notaryPaths.createSession}/notary-meetings`}
        prevNavigationPath={`${notaryPaths.createSession}/type`}
        from="notary"
      />
    </>
  );
};

export default PrimarySignerDetails;
