import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthApi, ResetPassswordDTO } from "../../api";
import { useAuth } from "../../utils/use-auth";
import AuthBasic from "../../layout/AuthBasic";
import { Link as RouterLink } from "react-router-dom";
import { regexPassword } from "../../utils/utils";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [error, setError] = useState("");
  const [invalidError, setInvalidError] = useState("");

  const [dataToSend, setDataToSend] = useState<ResetPassswordDTO>({
    token: searchParams.get("token") ?? "",
    password: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (regexPassword.test(e.target.value) === false) {
      setInvalidError(
        "must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number & 8 characters long"
      );
    } else if (regexPassword.test(e.target.value) === true) {
      setInvalidError("");
    }
    setDataToSend((prev) => ({
      ...prev,
      password: e.target.value,
    }));
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    new AuthApi(configuration)
      .authControllerResetPassword({
        resetPassswordDTO: dataToSend,
      })
      .then((res) => {
        navigate("/sign-in");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");

  return (
    <AuthBasic title="" description="">
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "20px", md: "100px" }}
          mb={{ base: "20px", md: "auto" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <Text
            fontWeight="700"
            fontFamily="Oswald"
            color={textColor}
            textAlign="center"
            mb="20px"
            fontSize={{ base: "3xl", md: "4xl" }}
          >
            Reset password
          </Text>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleReset(e);
            }}
          >
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="400"
                fontFamily="Lato"
              >
                New Password
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms="4px"
                type="password"
                placeholder="New Password"
                mb="24px"
                size="lg"
                border="1px solid #E2E8F0"
                fontWeight="400"
                fontFamily="Lato"
                onChange={(e) => handleChange(e)}
              />
              {invalidError !== "" ? (
                <Text fontSize={"14px"} color="red" px="2">
                  {invalidError}
                </Text>
              ) : (
                ""
              )}
            </FormControl>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="400"
                fontFamily="Lato"
              >
                Confirm Password
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                mt="4px"
                type="password"
                placeholder="Confirm Password"
                size="lg"
                border="1px solid #E2E8F0"
                fontWeight="400"
                fontFamily="Lato"
                onChange={(e) => {
                  if (dataToSend.password !== e.target.value) {
                    setError("Password and confirm password doesnot match");
                  } else {
                    setError("");
                  }
                }}
              />
              {error !== "" ? (
                <Text fontSize={"14px"} color="red" px="2">
                  {error}
                </Text>
              ) : (
                ""
              )}
              <Button
                fontSize="14px"
                variant="dark"
                fontWeight="900"
                fontFamily="Lato"
                w="100%"
                h="45"
                my={"24px"}
                bgGradient="linear(to-t,#D5AD3680,#D5AD36)"
                type="submit"
                isDisabled={invalidError !== "" || error !== ""}
              >
                SEND
              </Button>
            </FormControl>
          </form>
          <RouterLink to="/sign-in">
            <Text
              textAlign="center"
              color="gray.400"
              fontWeight="400"
              fontFamily="Lato"
              textDecoration="underline"
            >
              Back to sign in page
            </Text>
          </RouterLink>
        </Flex>
      </Flex>
    </AuthBasic>
  );
};

export default ResetPassword;
