import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      maxW="100%"
      p="5"
      justifyContent="space-around"
      fontWeight="400"
      fontFamily="Lato"
      fontSize="12px"
      style={{
        position: "fixed",
        bottom: 2,
        left: 2,
        right: 2,
        // backgroundColor: "#F7FAFC",
      }}
    >
      <Box>
        <Text color="gray.400">
          Copyright@{currentYear}{" "}
          <span style={{ marginLeft: "4px" }}>Notarize Genie</span>
        </Text>
      </Box>
      {/* <Box flexBasis="30%">
        <Flex alignItems="center" maxW="100%" justifyContent="space-around">
          <Text color="gray.400" mx="4">
            Notarize
          </Text>
          <Text color="gray.400" mx="4">
            Blog
          </Text>
          <Text color="gray.400" mx="4">
            License
          </Text>
        </Flex>
      </Box> */}
    </Flex>
  );
};

export default Footer;
