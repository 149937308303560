import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { handleError } from "../../../utils/utils-functions";
import { AuthApi, LoginDTO } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import { useAuthStore } from "../../../store/store";
import { useProfileDetailsStore } from "../../../store/notaryStore";
import AlertMsg from "../../../component/commonComponents/AlertMsg";
import { BsEye as EyeIcon, BsEyeSlash as EyeCloseIcon } from "react-icons/bs";

const SignInForm = ({
  isUnderMaintenance,
}: {
  isUnderMaintenance: boolean;
}) => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.400", "white");
  const { configuration } = useAuth();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { authInfo, setAuthInfo } = useAuthStore();
  const { setNotaryProfile } = useProfileDetailsStore();
  const [errorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState<boolean>(false);
  const [signInData, setSignInData] = useState<LoginDTO>({
    email: "",
    password: "",
  });

  const handleClick = () => setShow(!show);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSignInData({
      ...signInData,
      [e.target.name]: e.target.value,
    });
  };

  const signin = () => {
    const dataToSend = {
      email: signInData?.email?.toLowerCase(),
      password: signInData?.password,
    };

    // if (isUnderMaintenance === false) {
      new AuthApi(configuration)
        .authControllerLogin({
          loginDTO: dataToSend,
        })
        .then((res) => {
          setNotaryProfile({ email: signInData.email });
          localStorage.setItem("accessToken", res.accessToken);
          localStorage.setItem("email", signInData.email?.toLowerCase());
          // userId after LogIn
          localStorage.setItem("UserID", res?.userId?.toString());
          localStorage.setItem("role", res?.role?.toString());
          localStorage.setItem("businessName", res?.businessName?.toString());
          // localStorage.setItem("username", res?.username?.toString());
          setAuthInfo({
            ...authInfo,
            userID: res.userId?.toString(),
            role: res.role,
          });

          if (planId != null) {
            if (res?.role === "notary") {
              navigate(`/notary/plans?plan=${planId}`);
            } else if (res?.role === "business") {
              navigate(planId ? `/business/plans?plan=${planId}` : "/");
            } else {
              navigate("/");
            }
          } else {
            window.location.href = `/`;
          }
        })
        .catch(async (err) => {
          const message = await handleError(err);
          setErrorMsg(message.errorMessage);
          onOpen();
        });
    // }
  };

  return (
    <>
      <AlertMsg errorMsg={errorMsg} onClose={onClose} isOpen={isOpen} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          signin();
        }}
      >
        <FormControl>
          <FormLabel ms="4px" fontSize="sm" fontWeight="400" fontFamily="Lato">
            Email
          </FormLabel>
          <Input
            id="email"
            variant="auth"
            fontSize="sm"
            ms="4px"
            type="email"
            placeholder="johndoe123@email.com"
            mb="24px"
            size="lg"
            border="1px solid #E2E8F0"
            fontWeight="400"
            fontFamily="Lato"
            name="email"
            onChange={handleInputChange}
          />
          <FormLabel ms="4px" fontSize="sm" fontWeight="400" fontFamily="Lato">
            Password
          </FormLabel>

          <InputGroup size="md" alignItems="center">
            <Input
              // pr="4.5rem"
              id="password"
              variant="auth"
              fontSize="sm"
              ms="4px"
              type={show ? "text" : "password"}
              placeholder="Your password"
              mb="24px"
              size="lg"
              border="1px solid #E2E8F0"
              fontWeight="400"
              fontFamily="Lato"
              name="password"
              onChange={handleInputChange}
            />
            <InputRightElement pt={2}>
              {show ? (
                <EyeCloseIcon size={20} onClick={handleClick} role="button" />
              ) : (
                <EyeIcon size={20} onClick={handleClick} role="button" />
              )}
            </InputRightElement>
          </InputGroup>

          {/* <FormControl display="flex" alignItems="center" mb="24px">
              <Switch id="remember-login" colorScheme="blue" me="10px" />
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                fontWeight="400"
                fontFamily="Lato"
              >
                Remember me
              </FormLabel>
            </FormControl> */}
          <Flex>
            <RouterLink to="/forgot-password">
              <Text
                color={textColor}
                _hover={{ color: "grey.600" }}
                mb={2}
                textDecoration="underline"
                fontSize={"16px"}
              >
                Forgot Password?
              </Text>
            </RouterLink>
          </Flex>
          <Button
            fontSize="14px"
            variant="dark"
            fontWeight="900"
            className="sign-in"
            fontFamily="Lato"
            w="100%"
            h="45"
            mb="24px"
            bgGradient="linear(to-t,#D5AD3680,#D5AD36)"
            type="submit"
            // isDisabled={signInData.email === ""}
          >
            SIGN IN
          </Button>
        </FormControl>
      </form>
    </>
  );
};

export default SignInForm;
