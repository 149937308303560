//@ts-nocheck
import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import * as Yup from "yup";
import { Dispatch, SetStateAction, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import FormData from "./FormData";
import { useAuth } from "../../../../utils/use-auth";
import useNotification from "../../../../utils/useNotification";
import { threeFieldsValidations } from "../../../../utils/validations";
import {
  KbaType,
  MeetingMediatorApi,
  SessionApi,
  SignerApi,
} from "../../../../api";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";

type ISigner = {
  firstName: string;
  lastName: string;
  email: string;
  kbaStatus: string;
};

const InviteParticipants = ({
  onSuccess,
  from,
}: {
  onSuccess: () => void;
  from: string;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [errMsg, setErrMsg] = useState<string>("");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const sessionInfo = useActiveSessionStore((state) => state.sessionInfo);

  const createSigners = (participants: ISigner[]) => {
    const promises: any[] = [];
    return participants.map((participant) => {
      promises.push(
        new SignerApi(configuration).signerControllerCreate({
          signerDTO: {
            firstName: participant.firstName,
            lastName: participant.lastName,
            email: participant.email?.toLowerCase(),
            kbaStatus:
              participant.kbaStatus === "pending" ? "" : participant.kbaStatus,
          },
        })
      );
      return Promise.allSettled(promises);
    });
  };

  const createMediator = (participants: ISigner[]) => {
    const promises: any[] = [];
    return participants.map((el) => {
      promises.push(
        new MeetingMediatorApi(configuration).mediatorControllerPost({
          mediatorDTO: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email?.toLowerCase(),
          },
        })
      );
      return Promise.allSettled(promises);
    });
  };

  const handleSubmit = (values: { participants: ISigner[] }) => {
    const totalParticipants = values?.participants?.length;
    if (totalParticipants > 5) {
      setErrMsg(
        "You can add maximum 5 participants in a session including additional signers and witnesses"
      );
    } else {
      setErrMsg("");
      if (sessionInfo?.sessionId) {
        setIsDisable(true);
        if (from === "signer") {
          createSigners(values.participants).forEach((el) => {
            el.then((each) => {
              // @ts-ignore
              const signers = each.map((signer) => signer.value);
              const dataToSend = {
                sessionId: sessionInfo?.sessionId?.toString(),
                signerId: signers.map((data) => data.signerId),
                kbaLogs: signers.map((data) => {
                  return {
                    userId: data?.user?.id,
                    kbaLogId: values.participants.find(
                      (item) => item.email === data?.email
                    )?.kbaStatus,
                  };
                }) as KbaType[],
              };
              if (signers?.length === values.participants.length) {
                new SessionApi(configuration)
                  .sessionControllerInviteSigner({
                    inviteSignerDTO: dataToSend,
                  })
                  .then(() => {
                    setIsDisable(false);
                    notification("success", "Invited Sucessfully!");
                    onSuccess();
                  })
                  .catch(() => {
                    setIsDisable(false);
                  });
              } else {
                setIsDisable(false);
              }
            });
          });
        } else {
          // call api for adding mediator in session
          createMediator(values.participants).forEach((el) => {
            el.then((each) => {
              // @ts-ignore
              const mediators = each.map((signer) => signer.value);
              const dataToSend = {
                sessionId: sessionInfo?.sessionId?.toString(),
                mediatorId: mediators.map((data) => data.mediatorId),
              };
              if (mediators?.length === values.participants.length) {
                new SessionApi(configuration)
                  .sessionControllerInviteMediator({
                    inviteMediatorDTO: dataToSend,
                  })
                  .then((res) => {
                    notification("success", "Invited Sucessfully!");
                    setIsDisable(false);
                    onSuccess();
                  })
                  .catch(() => {
                    setIsDisable(false);
                  });
              } else {
                setIsDisable(false);
              }
            });
          });
        }
      }
    }
  };

  if (!sessionInfo?.sessionId) {
    onSuccess();
    return <h3>No SessionId </h3>;
  }

  return (
    <>
      <Text
        fontWeight={600}
        fontSize={{ base: "17px", md: "20px", lg: "20px" }}
        color={"#000080"}
        pb={"15px"}
        pt={2}
      >
        {from === "signer" ? "Additional Signer" : "Mediator"}
      </Text>
      <Formik
        initialValues={{
          participants: [
            {
              firstName: "",
              lastName: "",
              email: "",
              kbaStatus: "",
            },
          ],
        }}
        enableReinitialize
        validationSchema={Yup.object({
          participants: threeFieldsValidations,
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        render={({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="participants"
              render={(arrayHelpers) => {
                const participants = values.participants;
                return (
                  <div>
                    {participants && participants.length > 0
                      ? participants.map((participant, index) => (
                          <FormData
                            key={index}
                            from={from}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            participant={participant}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                          />
                        ))
                      : null}

                    <Button
                      mt={3}
                      width="fit-content"
                      background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                      _hover={{
                        background:
                          "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                      }}
                      isDisabled={
                        arrayHelpers.form.values?.participants?.length >= 5
                      }
                      onClick={() => {
                        arrayHelpers.push({
                          email: "",
                          firstName: "",
                          lastName: "",
                          kbaStatus: "",
                        });
                      }}
                    >
                      + Add {from === "signer" ? "Signer" : "Mediator"}
                    </Button>
                  </div>
                );
              }}
            />

            {errMsg !== "" && (
              <Text color={"red"} fontSize={14}>
                *{errMsg}
              </Text>
            )}

            <Divider pt={5} />

            <Flex pt={3} justifyContent={"flex-end"}>
              <Button
                background="white"
                border={"1px solid rgba(0, 0, 128, 1)"}
                p="22px"
                fontSize={"16px"}
                color="rgba(0, 0, 128, 1)"
                onClick={onSuccess}
                width="130px"
                mr={4}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "23px",
                  fontSize: "18px",
                }}
                width="130px"
                isDisabled={isDisable}
              >
                Invite
              </Button>
            </Flex>
          </Form>
        )}
      />
    </>
  );
};

export default InviteParticipants;
