import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Radio,
  RadioGroup,
  ModalCloseButton,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { ActiveSessionApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { useMessagesStore } from "../../../../store/mesagesStore";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const TerminateMeetingModal = ({ isOpen, onOpen, onClose }: Props) => {
  const { configuration } = useAuth();
  const { clearMessages } = useMessagesStore();
  const [otherReason, setOtherReason] = useState<string>("");
  const [value, setValue] = useState<string>("documentError");
  const [disable, setDisable] = useState<boolean>(false);
  const activeSessionId = useActiveSessionStore(
    (state) => state.activeSessionId
  );

  const handleSubmit = () => {
    if (activeSessionId !== undefined) {
      setDisable(true);
      new ActiveSessionApi(configuration)
        .activeSessionControllerTerminate({
          terminateSessionDTO: {
            activeSessionId: activeSessionId,
            rejectionReason: value === "other" ? otherReason : value,
          },
        })
        .then((res) => {
          // clear current Session messages
          clearMessages();
          setDisable(false);
          window.location.href = "/";
          // navigate("/");
        })
        .catch(() => {
          // #TODO: handle error
          setDisable(false);
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent p={6}>
        <ModalCloseButton
          _hover={{
            background: "#000",
          }}
          style={{
            background: "#D5AD36",
            color: "#fff",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            position: "absolute",
            right: "-9px",
            top: "-14px",
          }}
        />
        <ModalBody>
          <Stack>
            <Text
              fontSize={"24px"}
              fontWeight={"700"}
              fontFamily="Lato"
              textAlign={"center"}
            >
              Reject This Document
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={"700"}
              fontFamily="Lato"
              textAlign={"center"}
              color="rgba(139, 138, 150, 1)"
            >
              Why did you reject this document?
            </Text>
          </Stack>
          <RadioGroup onChange={setValue} value={value}>
            <Stack p={4} fontFamily="Lato" fontSize={"16px"}>
              <Radio value="documentError">Document error</Radio>
              <Radio value="unacceptableDocument">Unacceptable document</Radio>
              <Radio value="other">Other</Radio>
              {value === "other" && (
                <Textarea
                  height={"180px"}
                  value={otherReason}
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                />
              )}
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter justifyContent={"center"}>
          <Stack>
            <Button
              w={"380px"}
              background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
              borderRadius="10px"
              p="6"
              fontSize={"16px"}
              marginBottom={"10px"}
              _hover={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              }}
              onClick={handleSubmit}
              isDisabled={(value === "other" && otherReason === "") || disable}
            >
              Submit Report
            </Button>
            <Button
              onClick={onClose}
              w={"380px"}
              background="white"
              border={"1px solid rgba(0, 0, 128, 1)"}
              borderRadius="10px"
              p="6"
              fontSize={"16px"}
              color="rgba(0, 0, 128, 1)"
              _hover={{
                background: "#000080",
                color: "#fff",
              }}
            >
              Return to Meeting
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TerminateMeetingModal;
