/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangePassswordDTO,
  ChangePassswordResponseDTO,
  CouponResponse,
  CouponVerifyBody,
  ForgotPassswordDTO,
  LoginDTO,
  LoginResponseDTO,
  RegisterDTO,
  RegisterEmailDTO,
  RegisterResponseDTO,
  ResetPassswordDTO,
  ResetPassswordResponseDTO,
  UserUpdate,
  UserUpdateResponse,
} from '../models/index';
import {
    ChangePassswordDTOFromJSON,
    ChangePassswordDTOToJSON,
    ChangePassswordResponseDTOFromJSON,
    ChangePassswordResponseDTOToJSON,
    CouponResponseFromJSON,
    CouponResponseToJSON,
    CouponVerifyBodyFromJSON,
    CouponVerifyBodyToJSON,
    ForgotPassswordDTOFromJSON,
    ForgotPassswordDTOToJSON,
    LoginDTOFromJSON,
    LoginDTOToJSON,
    LoginResponseDTOFromJSON,
    LoginResponseDTOToJSON,
    RegisterDTOFromJSON,
    RegisterDTOToJSON,
    RegisterEmailDTOFromJSON,
    RegisterEmailDTOToJSON,
    RegisterResponseDTOFromJSON,
    RegisterResponseDTOToJSON,
    ResetPassswordDTOFromJSON,
    ResetPassswordDTOToJSON,
    ResetPassswordResponseDTOFromJSON,
    ResetPassswordResponseDTOToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
    UserUpdateResponseFromJSON,
    UserUpdateResponseToJSON,
} from '../models/index';

export interface AuthControllerChangePasswordRequest {
    changePassswordDTO: ChangePassswordDTO;
}

export interface AuthControllerCouponCheckRequest {
    couponCode: string;
    couponVerifyBody: CouponVerifyBody;
}

export interface AuthControllerForgotPasswordRequest {
    forgotPassswordDTO: ForgotPassswordDTO;
}

export interface AuthControllerLoginRequest {
    loginDTO: LoginDTO;
}

export interface AuthControllerPartialUpdateRequest {
    userId: number;
    userUpdate: UserUpdate;
}

export interface AuthControllerRegisterRequest {
    registerDTO: RegisterDTO;
}

export interface AuthControllerRegisterEmailRequest {
    registerEmailDTO: RegisterEmailDTO;
}

export interface AuthControllerResetPasswordRequest {
    resetPassswordDTO: ResetPassswordDTO;
}

export interface AuthControllerUpdateRequest {
    userId: number;
    userUpdate: UserUpdate;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async authControllerChangePasswordRaw(requestParameters: AuthControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangePassswordResponseDTO>> {
        if (requestParameters['changePassswordDTO'] == null) {
            throw new runtime.RequiredError(
                'changePassswordDTO',
                'Required parameter "changePassswordDTO" was null or undefined when calling authControllerChangePassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/change-password`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePassswordDTOToJSON(requestParameters['changePassswordDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangePassswordResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authControllerChangePassword(requestParameters: AuthControllerChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangePassswordResponseDTO> {
        const response = await this.authControllerChangePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerCouponCheckRaw(requestParameters: AuthControllerCouponCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CouponResponse>> {
        if (requestParameters['couponCode'] == null) {
            throw new runtime.RequiredError(
                'couponCode',
                'Required parameter "couponCode" was null or undefined when calling authControllerCouponCheck().'
            );
        }

        if (requestParameters['couponVerifyBody'] == null) {
            throw new runtime.RequiredError(
                'couponVerifyBody',
                'Required parameter "couponVerifyBody" was null or undefined when calling authControllerCouponCheck().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/verifiedCoupon/{couponCode}`.replace(`{${"couponCode"}}`, encodeURIComponent(String(requestParameters['couponCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CouponVerifyBodyToJSON(requestParameters['couponVerifyBody']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerCouponCheck(requestParameters: AuthControllerCouponCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CouponResponse> {
        const response = await this.authControllerCouponCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerForgotPasswordRaw(requestParameters: AuthControllerForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResetPassswordResponseDTO>> {
        if (requestParameters['forgotPassswordDTO'] == null) {
            throw new runtime.RequiredError(
                'forgotPassswordDTO',
                'Required parameter "forgotPassswordDTO" was null or undefined when calling authControllerForgotPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPassswordDTOToJSON(requestParameters['forgotPassswordDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPassswordResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authControllerForgotPassword(requestParameters: AuthControllerForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResetPassswordResponseDTO> {
        const response = await this.authControllerForgotPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponseDTO>> {
        if (requestParameters['loginDTO'] == null) {
            throw new runtime.RequiredError(
                'loginDTO',
                'Required parameter "loginDTO" was null or undefined when calling authControllerLogin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDTOToJSON(requestParameters['loginDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponseDTO> {
        const response = await this.authControllerLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerPartialUpdateRaw(requestParameters: AuthControllerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserUpdateResponse>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling authControllerPartialUpdate().'
            );
        }

        if (requestParameters['userUpdate'] == null) {
            throw new runtime.RequiredError(
                'userUpdate',
                'Required parameter "userUpdate" was null or undefined when calling authControllerPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/updateCoupon/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters['userUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerPartialUpdate(requestParameters: AuthControllerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserUpdateResponse> {
        const response = await this.authControllerPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerRegisterRaw(requestParameters: AuthControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterResponseDTO>> {
        if (requestParameters['registerDTO'] == null) {
            throw new runtime.RequiredError(
                'registerDTO',
                'Required parameter "registerDTO" was null or undefined when calling authControllerRegister().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterDTOToJSON(requestParameters['registerDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authControllerRegister(requestParameters: AuthControllerRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterResponseDTO> {
        const response = await this.authControllerRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerRegisterEmailRaw(requestParameters: AuthControllerRegisterEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterResponseDTO>> {
        if (requestParameters['registerEmailDTO'] == null) {
            throw new runtime.RequiredError(
                'registerEmailDTO',
                'Required parameter "registerEmailDTO" was null or undefined when calling authControllerRegisterEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterEmailDTOToJSON(requestParameters['registerEmailDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async authControllerRegisterEmail(requestParameters: AuthControllerRegisterEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterResponseDTO> {
        const response = await this.authControllerRegisterEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authControllerResetPasswordRaw(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resetPassswordDTO'] == null) {
            throw new runtime.RequiredError(
                'resetPassswordDTO',
                'Required parameter "resetPassswordDTO" was null or undefined when calling authControllerResetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPassswordDTOToJSON(requestParameters['resetPassswordDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPassword(requestParameters: AuthControllerResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authControllerResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authControllerUpdateRaw(requestParameters: AuthControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserUpdateResponse>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling authControllerUpdate().'
            );
        }

        if (requestParameters['userUpdate'] == null) {
            throw new runtime.RequiredError(
                'userUpdate',
                'Required parameter "userUpdate" was null or undefined when calling authControllerUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/updateCoupon/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters['userUpdate']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async authControllerUpdate(requestParameters: AuthControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserUpdateResponse> {
        const response = await this.authControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
