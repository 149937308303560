import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Icons
import { FiEdit } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { TbFileInvoice } from "react-icons/tb";
import { AiOutlineHistory } from "react-icons/ai";

import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import { signerPaths } from "../../routes/routes";
import { getSignerDetails } from "../../utils/utils-apis";
import useNotification from "../../utils/useNotification";
import { useSignerProfileStore } from "../../store/signerStore";
import SupportModal from "../../component/commonComponents/SupportModal";
import SessionInfoList from "../../component/SessionsInfo/SessionInfoList";
import DashboardCards from "../../component/commonComponents/DashboradData/DashboradCards/DashboardCards";
import DashboardGraphs from "../../component/commonComponents/DashboradData/DashboardGraphs/DashboardGraphs";
import Card from "../../component/Card";

const SignerDashboard = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const token = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState<number>(0);
  const { signerProfileDetails, setSignerProfileDetails } =
    useSignerProfileStore();

  useEffect(() => {
    if (token !== null) {
      getSignerDetails(configuration)
        .then((result) => {
          localStorage.setItem("userName", result.firstName);
          setSignerProfileDetails({
            ...signerProfileDetails,
            firstName: result?.firstName,
            lastName: result?.lastName,
          });
        })
        .catch(async (err) => {
          const message = await handleError(err);
          if (
            message.errorMessage === "Signer not Found" ||
            message?.statusCode === 500
          ) {
            notification("warn", "Complete your Profile");
            setTimeout(() => {
              navigate("/signer/profile");
            }, 1000);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const flexButtonData = (title: string, link: string, icon: JSX.Element) => {
    return (
      <Button
        fontSize={"16px"}
        fontWeight={"normal"}
        color={"#D5AD36"}
        height={"40px"}
        _hover={{
          background:
            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
          color: "#fff",
        }}
        border={"1px solid #D5AD36"}
        backgroundColor={"white"}
        onClick={() => {
          if (link === "support") {
            onOpen();
          } else {
            navigate(link);
          }
        }}
      >
        {icon} <span style={{ marginLeft: "5px" }}>{title}</span>
      </Button>
    );
  };

  return (
    <>
      <Box p={[0, "1rem", "1rem", "1rem"]}>
        <Heading
          fontSize={{ base: "22px", md: "22px", lg: "22px" }}
          lineHeight="30px"
          textAlign={"center"}
          mb={6}
          fontFamily="Oswald"
          fontWeight={600}
        >
          Signer Dashboard
        </Heading>

        {token != null && (
          <>
            <Card>
              <Text fontSize="20px" fontWeight={"bold"} mb={5}>
                My Shortcuts
              </Text>
              <Flex flexWrap={"wrap"} justifyContent={"flex-start"} gap={3}>
                {flexButtonData(
                  "Notarize a Document",
                  `${signerPaths.createSession}/type`,
                  <FiEdit />
                )}
                {flexButtonData(
                  "Update Profile",
                  "/signer/profile",
                  <CgProfile />
                )}
                {flexButtonData(
                  "My Sessions",
                  `${signerPaths.list}`,
                  <AiOutlineHistory />
                )}
                {flexButtonData(
                  "Receipts",
                  `${signerPaths.signerInvoices}`,
                  <TbFileInvoice />
                )}
                {/* {flexButtonData("Support", "support", <MdContactSupport />)} */}
              </Flex>
            </Card>

            <DashboardCards
              totalInvoicesAmount={totalInvoicesAmount}
              from="signer"
            />

            <DashboardGraphs
              onSetTotalInvoicesAmount={(value) =>
                setTotalInvoicesAmount(value)
              }
            />

            <Box
              mt={8}
              style={{
                width: "100%",
                padding: "20px",
                boxShadow: "-1px 5px 15px 0px #ddd",
              }}
            >
              <Text fontSize="20px" fontWeight={"bold"} mb={6}>
                Most Recent Sessions
              </Text>
              <SessionInfoList from="dashboard" />
              <Text
                color={"#000080"}
                fontWeight={600}
                fontSize={"14px"}
                textAlign="right"
                textDecoration={"underline"}
                mt={3}
                role="button"
                onClick={() => navigate(`${signerPaths.list}`)}
              >
                View More &gt;
              </Text>
            </Box>
          </>
        )}
      </Box>

      <SupportModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        name={`${signerProfileDetails?.firstName} ${signerProfileDetails?.lastName}`}
        phoneNumber={signerProfileDetails?.phoneNumber}
      />
    </>
  );
};

export default SignerDashboard;
