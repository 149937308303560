import {
  Box,
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActiveSessionApi } from "../../../api";
import BaseLayout from "../../../layout/BaseLayout";
import { useAuth } from "../../../utils/use-auth";

const options = [
  { id: "Notary Exemplar", name: "Notary Exemplar" },
  { id: "Application", name: "Application" },
  { id: "Bill of Sale", name: "Bill of Sale" },
  { id: "POA", name: "POA" },
  { id: "Copy Certification", name: "Copy Certification" },
  { id: "Deeds", name: "Deeds" },
  { id: "Lease", name: "Lease" },
  { id: "DMV Form", name: "DMV Form" },
  { id: "Title Package", name: "Title Package" },
  { id: "Will or Trust", name: "Will or Trust" },
  { id: "Lender Package", name: "Lender Package" },
  { id: "Court issued Document", name: "Court issued Document" },
  { id: "PS-1583", name: "PS-1583 (Virtual Mailbox)" },
  { id: "Vital Records Request", name: "Vital Records Request" },
  { id: "other", name: "Other" },
];

const MeetingCompleted = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { activeSessionId } = useParams();
  const [value, setValue] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [otherValue, setOtherValue] = useState<string>("");

  const handleSubmit = () => {
    new ActiveSessionApi(configuration)
      .activeSessionControllerReviewMeeting({
        meetingReviewDTO: {
          activeSessionId: Number(activeSessionId),
          typeOfDocumnet: value === "other" ? otherValue : value,
          notaryNote: note,
        },
      })
      .then((res) => {
        console.log(res);
        setNote("");
        setValue("");
        navigate("/");
      });
  };

  return (
    <BaseLayout showBack={false}>
      <Heading
        fontSize={"20px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        mt={6}
        fontFamily="Oswald"
        fontWeight={600}
        color={"#2D3748"}
      >
        Success! Meeting Complete
      </Heading>

      <Box width={["100%", "70%", "70%"]} margin={"auto"} pt={6}>
        <Text>Please fill the following details about the session.</Text>

        <Text py={4}>1. What type of document did you notarize?</Text>
        <RadioGroup value={value} onChange={setValue}>
          <Flex flexWrap={"wrap"} justifyContent={"flex-start"}>
            {options.flatMap((item) => (
              <Box
                flexBasis={{ base: "100%", md: "50%", lg: "50%" }}
                key={item.id}
              >
                <Radio value={item?.id} fontSize={"15px"} pb={2}>
                  {item.name}
                </Radio>
              </Box>
            ))}
          </Flex>
        </RadioGroup>
        {value === "other" && (
          <Textarea
            py={4}
            mt={2}
            placeholder="Enter Other Type"
            value={otherValue}
            onChange={(e) => setOtherValue(e.target.value)}
          />
        )}

        <Text py={4} pt={6}>
          2. Please enter any meeting notes (these will be added to your notary
          journal)
        </Text>
        <Textarea
          py={4}
          placeholder="Enter Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        <Button
          mt={10}
          px={12}
          bgGradient="linear(to-t, yellow.500, yellow.300)"
          _hover={{
            background: "yellow.400",
          }}
          onClick={handleSubmit}
          isDisabled={
            value === "" ||
            // note === "" ||
            (value === "other" && otherValue === "")
          }
        >
          Submit
        </Button>
      </Box>
    </BaseLayout>
  );
};

export default MeetingCompleted;
