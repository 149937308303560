import BusinessScheduleMeeting from "../../../../component/Sessions/Business/BusinessScheduleMeeting";
import { useBusinessSessionStepStore } from "../../../../store/businessStore";
import { useEffect } from "react";

const BusinessSchedulePage = () => {
  const { setStep } = useBusinessSessionStepStore();

  useEffect(() => {
    setStep(3);
  }, [setStep]);

  return <BusinessScheduleMeeting />;
};

export default BusinessSchedulePage;
