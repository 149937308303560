import {
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Divider,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeviceInfoData from "./DeviceInfoData";
import { useAuth } from "../../../../utils/use-auth";
import { emitAuditEvent } from "../../../../utils/pdf-utils/emitEvent";
import useWebSocket from "../../../../utils/useWebSocket";
import {
  ActiveSessionApi,
  ConferenceApi,
  KbaType,
  Notary,
  SessionApi,
  Signer,
  Witness,
} from "../../../../api";
import { useKBAVerification } from "../../../../store/store";
import { useActiveSessionStore } from "../../../../store/activeSessionStore";

type IData = {
  conferenceId: string;
  token: string;
  externalId: string;
};

const DeviceInfoModal = ({
  from,
  sessionId,
  onCloseComplete,
  onSetConfData,
  onSetKbaLogsDetail,
}: {
  from: string;
  sessionId: string;
  onCloseComplete: () => void;
  onSetConfData?: (data: IData) => void;
  onSetKbaLogsDetail?: (details: KbaType[]) => void;
}) => {
  const navigate = useNavigate();
  const { socket } = useWebSocket();
  const { configuration } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const { wasKBACompleted } = useKBAVerification();
  const setSessionInfo = useActiveSessionStore((state) => state.setSessionInfo);
  const ipData = useActiveSessionStore((state) => state.ipData);
  const setIpData = useActiveSessionStore((state) => state.setIpData);
  const setActiveSessionId = useActiveSessionStore(
    (state) => state.setActiveSessionId
  );

  useEffect(() => {
    const getData = () => {
      fetch("https://geolocation-db.com/json/")
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setIpData(res?.IPv4);
        });
    };
    getData();
  }, []);

  const checkLenderSession = async (sessionId: number) => {
    if (localStorage.getItem("role") === "signer" && !wasKBACompleted) {
      const session = await new SessionApi(
        configuration
      ).sessionControllerGetById({
        sessionId,
      });
      if (session.isTitleLenderSession) {
        await new SessionApi(configuration).sessionControllerUpdate({
          sessionId,
          sessionUpdateDTO: {
            kbaLogs: [],
          },
        });
        window.location.href = `/signer/profile?session-id=${sessionId}`;
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (sessionId) {
      checkLenderSession(Number(sessionId)).then((res) => {
        if (res) {
          onOpen();
          setLoading(false);
          grabMeeting();
        }
        // }
      });
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  const meetingResponse = async () => {
    return new ActiveSessionApi(configuration).activeSessionControllerFindOne({
      sessionId: sessionId ?? "",
    });
  };

  const grabMeeting = async () => {
    if (from === "witness") {
      new ConferenceApi(configuration)
        .conferenceControllerMeetingToken({
          conferenceJoinDTO: {
            sessionId: sessionId ?? "",
          },
        })
        .then((res) => {
          if (onSetConfData) {
            onSetConfData({
              conferenceId: res?.conferenceId,
              token: res?.token,
              externalId: res?.externalId,
            });
          }
        });
    }

    const { session, activeSessionId } = await meetingResponse();
    setActiveSessionId(activeSessionId);
    setSessionInfo(session);

    if (session?.kbaLogs && onSetKbaLogsDetail) {
      onSetKbaLogsDetail(session?.kbaLogs);
    }
  };

  const handleModalClose = () => {
    onClose();
    // navigate("/");
  };

  const handleSave = () => {
    onClose();
    const payload = {
      ip: ipData,
      role: localStorage.getItem("role"),
      name: localStorage.getItem("userName"),
      email: localStorage.getItem("email"),
    };
    if (from !== "mediator") {
      emitAuditEvent({
        action: "verified",
        event: "IP address",
        payload,
        sessionId,
        socket,
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        size={"xl"}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onCloseComplete={onCloseComplete}
      >
        <ModalOverlay />
        <ModalContent pb={2} px={1.5}>
          <ModalHeader>
            <Text fontSize={"24px"} fontWeight={"700"} fontFamily="Lato">
              Setup Video and Audio
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <>
            {loading ? (
              <Center>
                <Spinner size={"xl"} />
              </Center>
            ) : (
              <>
                <ModalBody>
                  <DeviceInfoData
                    onSetIsDisable={(value) => setIsDisable(value)}
                  />
                </ModalBody>
                <Divider />
                <ModalFooter justifyContent={"center"}>
                  <Stack>
                    <Button
                      minW={"300px"}
                      w={"500px"}
                      style={{
                        background:
                          "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                      }}
                      borderRadius="10px"
                      p="8"
                      onClick={handleSave}
                      isDisabled={isDisable}
                    >
                      Save Preferences
                    </Button>
                  </Stack>
                </ModalFooter>
              </>
            )}
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeviceInfoModal;
