import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SessionApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import useCreateUser from "../../../../hooks/useCreateUser";
import useNotification from "../../../../utils/useNotification";
import {
  initialSessionDetails,
  useKBAVerification,
  useSessionCreate,
} from "../../../../store/store";
import { createSession, getSessionDetails } from "../../../../utils/utils-apis";
import demoFrontImg from "../../../../assets/images/demo.jpg";
import { signerPaths } from "../../../../routes/routes";

const DemoKbaQR = ({
  from,
  paramsSessionId,
}: {
  from?: string;
  paramsSessionId?: number;
}) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const sendSynchronously = useCreateUser();
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [selectedIdType, setSelectedIdType] = useState<string>("driverLicense");
  const {setWasKBACompleted} = useKBAVerification()
  const handleCreateSession = async () => {
    const users = await sendSynchronously();
    const kbaLogId = "demo";

    if (from === "page") {
      createSession(
        sessionDetail,
        users,
        configuration,
        "signer",
        false,
        kbaLogId
      )
        .then((res) => {
          setSessionDetails(initialSessionDetails);
          navigate(
            `/signer/on-demand/waiting-for-notary/?session-id=${res.sessionId}`
          );
        })
        .catch((err) => {
          console.log(err);
          notification("fail", "Failed to create Session");
        });
    } else {
      getSessionDetails(configuration, Number(paramsSessionId)).then(
        (session) => {
          const kbaLogs = session?.kbaLogs;
          const logData = kbaLogs?.find(
            (el) => Number(el.userId) === Number(localStorage.getItem("UserID"))
          );
          if (logData === undefined) {
            kbaLogs?.push({
              userId: Number(localStorage.getItem("UserID")),
              kbaLogId: kbaLogId,
            });
          } else {
            logData.kbaLogId = kbaLogId;
          }
          new SessionApi(configuration)
            .sessionControllerUpdate({
              sessionId: Number(paramsSessionId),
              sessionUpdateDTO: {
                kbaLogs: kbaLogs,
              },
            })
            .then((res) => {
              setWasKBACompleted(true);
              navigate(
                `/signer/on-demand/waiting-for-notary/?session-id=${paramsSessionId}`
              );
            });
        }
      );
    }
  };

  return (
    <>
      <Text
        fontWeight={600}
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        textAlign={"center"}
        fontFamily={"oswald"}
      >
        Verify Your ID
      </Text>

      <Box py={5}>
        <Text fontWeight={600}>Select Id type?</Text>

        <RadioGroup
          mt={3}
          value={selectedIdType}
          onChange={(value) => setSelectedIdType(value)}
        >
          <Stack direction="row" gap={3}>
            <Radio value="driverLicense">Driving License</Radio>
            <Radio value="passport">Passport</Radio>
            <Radio value="other">Other</Radio>
          </Stack>
        </RadioGroup>
      </Box>

      <Divider />

      <Box pt={4}>
        <Text fontWeight={600}>Upload your ID</Text>
      </Box>

      <Flex flexWrap={"wrap"} justifyContent={"space-around"}>
        <Stack mx={"10px"} my={3}>
          <Image src={demoFrontImg} height={"160px"} />
          <Text fontWeight={600} textAlign={"center"}>
            Front Side
          </Text>
        </Stack>
        <Stack mx={"10px"} my={3}>
          <Image src={demoFrontImg} height={"160px"} />
          <Text fontWeight={600} textAlign={"center"}>
            Back Side
          </Text>
        </Stack>
      </Flex>

      <Flex alignSelf={"end"} pt={7}>
        <Button
          mx={2}
          style={{ padding: "10px 30px" }}
          width={"fit-content"}
          onClick={() => {
            navigate(`${signerPaths.createSession}/signer-verification`);
          }}
        >
          Prev
        </Button>

        <Button
          mx={2}
          width={"fit-content"}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 20px",
          }}
          onClick={handleCreateSession}
        >
          {from === "page" ? "Create Session" : "Join Session"}
        </Button>
      </Flex>
    </>
  );
};

export default DemoKbaQR;
