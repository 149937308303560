import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Configuration } from "../api";
import { BASE_URL } from "./constants";

export const useAuth = () => {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access-token");
  const localStorageToken = localStorage.getItem("accessToken");
  const [token, setToken] = useState(
    accessToken !== null ? accessToken : localStorageToken
  );

  useEffect(() => {
    if (accessToken == null) return;
    setToken(accessToken);
  }, [accessToken]);

  const configuration = new Configuration({
    basePath: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return {
    configuration,
  };
};
