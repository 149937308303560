import { create } from "zustand";

interface IMicrophone {
  permissionGiven: boolean;
  microphoneDevices: MediaDeviceInfo[];
  selectedMicrophone: MediaDeviceInfo | null;
  getMicrophonePermissions: () => Promise<boolean>;
  getMicrophones: () => Promise<MediaDeviceInfo[]>;
  selectMicrophone: (device: MediaDeviceInfo["deviceId"]) => void;
}

export const useMicrophone = create<IMicrophone>((set, get) => ({
  permissionGiven: false,
  microphoneDevices: [],
  selectedMicrophone: null,
  selectMicrophone: (device: MediaDeviceInfo["deviceId"]) => {
    const selectedMicrophone = get().microphoneDevices.find(
      (microphone) => microphone.deviceId === device
    );
    set({ selectedMicrophone });
  },
  getMicrophones: async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphoneDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    set({
      microphoneDevices: microphoneDevices,
    });
    return microphoneDevices;
  },
  getMicrophonePermissions: async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphoneDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      set({
        microphoneDevices: microphoneDevices,
        permissionGiven: true,
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
}));