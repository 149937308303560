/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AproveNotaryDTO,
  BankAccountResponseDTO,
  CertificateResponseDTO,
  DeleteAccountDTO,
  DeleteAccountResponseDTO,
  NNAIdVerificationDTO,
  Notary,
  NotaryDTO,
  Payout,
  PayoutResponseDTO,
  PayoutToNotaryDTO,
  StripeDT,
  StripeLoginLink,
  StripePaymentResponseDTO,
  UpdateNotaryDTO,
} from '../models/index';
import {
    AproveNotaryDTOFromJSON,
    AproveNotaryDTOToJSON,
    BankAccountResponseDTOFromJSON,
    BankAccountResponseDTOToJSON,
    CertificateResponseDTOFromJSON,
    CertificateResponseDTOToJSON,
    DeleteAccountDTOFromJSON,
    DeleteAccountDTOToJSON,
    DeleteAccountResponseDTOFromJSON,
    DeleteAccountResponseDTOToJSON,
    NNAIdVerificationDTOFromJSON,
    NNAIdVerificationDTOToJSON,
    NotaryFromJSON,
    NotaryToJSON,
    NotaryDTOFromJSON,
    NotaryDTOToJSON,
    PayoutFromJSON,
    PayoutToJSON,
    PayoutResponseDTOFromJSON,
    PayoutResponseDTOToJSON,
    PayoutToNotaryDTOFromJSON,
    PayoutToNotaryDTOToJSON,
    StripeDTFromJSON,
    StripeDTToJSON,
    StripeLoginLinkFromJSON,
    StripeLoginLinkToJSON,
    StripePaymentResponseDTOFromJSON,
    StripePaymentResponseDTOToJSON,
    UpdateNotaryDTOFromJSON,
    UpdateNotaryDTOToJSON,
} from '../models/index';

export interface NotaryControllerAproveNotaryRequest {
    aproveNotaryDTO: AproveNotaryDTO;
}

export interface NotaryControllerCreateRequest {
    notaryDTO: NotaryDTO;
}

export interface NotaryControllerDeleteBankAccountRequest {
    deleteAccountDTO: DeleteAccountDTO;
}

export interface NotaryControllerDeleteConnectedAccountRequest {
    connId: string;
}

export interface NotaryControllerFindOneRequest {
    userId: string;
}

export interface NotaryControllerNnaVeriFicationRequest {
    nnaId: string;
}

export interface NotaryControllerPartialUpdateNotaryRequest {
    id: number;
    updateNotaryDTO: UpdateNotaryDTO;
}

export interface NotaryControllerPayoutSchedulingRequest {
    payout: Payout;
}

export interface NotaryControllerPayoutToNotaryRequest {
    payoutToNotaryDTO: PayoutToNotaryDTO;
}

export interface NotaryControllerStripeAccRequest {
    stripeDT: StripeDT;
}

export interface NotaryControllerUpdateNotaryRequest {
    id: number;
    updateNotaryDTO: UpdateNotaryDTO;
}

export interface NotaryControllerUploadCertificateRequest {
    file: Blob;
    passcode: string;
}

export interface NotaryControllerUploadCertificatemanuallRequest {
    userId: number;
    file: Blob;
    passcode: string;
}

/**
 * 
 */
export class NotaryApi extends runtime.BaseAPI {

    /**
     */
    async notaryControllerAproveNotaryRaw(requestParameters: NotaryControllerAproveNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['aproveNotaryDTO'] == null) {
            throw new runtime.RequiredError(
                'aproveNotaryDTO',
                'Required parameter "aproveNotaryDTO" was null or undefined when calling notaryControllerAproveNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/aprove-notary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AproveNotaryDTOToJSON(requestParameters['aproveNotaryDTO']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerAproveNotary(requestParameters: NotaryControllerAproveNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerAproveNotaryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notaryControllerCreateRaw(requestParameters: NotaryControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notary>> {
        if (requestParameters['notaryDTO'] == null) {
            throw new runtime.RequiredError(
                'notaryDTO',
                'Required parameter "notaryDTO" was null or undefined when calling notaryControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotaryDTOToJSON(requestParameters['notaryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerCreate(requestParameters: NotaryControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notary> {
        const response = await this.notaryControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerDeleteBankAccountRaw(requestParameters: NotaryControllerDeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteAccountResponseDTO>> {
        if (requestParameters['deleteAccountDTO'] == null) {
            throw new runtime.RequiredError(
                'deleteAccountDTO',
                'Required parameter "deleteAccountDTO" was null or undefined when calling notaryControllerDeleteBankAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/delete-bank-account`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAccountDTOToJSON(requestParameters['deleteAccountDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteAccountResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerDeleteBankAccount(requestParameters: NotaryControllerDeleteBankAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteAccountResponseDTO> {
        const response = await this.notaryControllerDeleteBankAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerDeleteConnectedAccountRaw(requestParameters: NotaryControllerDeleteConnectedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['connId'] == null) {
            throw new runtime.RequiredError(
                'connId',
                'Required parameter "connId" was null or undefined when calling notaryControllerDeleteConnectedAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/delete-coonected-account/{connId}`.replace(`{${"connId"}}`, encodeURIComponent(String(requestParameters['connId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerDeleteConnectedAccount(requestParameters: NotaryControllerDeleteConnectedAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerDeleteConnectedAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notaryControllerFindAllNotaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Notary>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/all/notary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotaryFromJSON));
    }

    /**
     */
    async notaryControllerFindAllNotary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Notary>> {
        const response = await this.notaryControllerFindAllNotaryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerFindOneRaw(requestParameters: NotaryControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notary>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling notaryControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerFindOne(requestParameters: NotaryControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notary> {
        const response = await this.notaryControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notary> {
        const response = await this.notaryControllerGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerGetAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankAccountResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/get-stripe-account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankAccountResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerGetAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankAccountResponseDTO> {
        const response = await this.notaryControllerGetAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerLoginLinkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripeLoginLink>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/stripe/login-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripeLoginLinkFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerLoginLink(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripeLoginLink> {
        const response = await this.notaryControllerLoginLinkRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerManuallyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/manuallyUpdateOnDemand`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerManually(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerManuallyRaw(initOverrides);
    }

    /**
     */
    async notaryControllerNnaVeriFicationRaw(requestParameters: NotaryControllerNnaVeriFicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NNAIdVerificationDTO>> {
        if (requestParameters['nnaId'] == null) {
            throw new runtime.RequiredError(
                'nnaId',
                'Required parameter "nnaId" was null or undefined when calling notaryControllerNnaVeriFication().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/verification/{nnaId}`.replace(`{${"nnaId"}}`, encodeURIComponent(String(requestParameters['nnaId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NNAIdVerificationDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerNnaVeriFication(requestParameters: NotaryControllerNnaVeriFicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NNAIdVerificationDTO> {
        const response = await this.notaryControllerNnaVeriFicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerPartialUpdateNotaryRaw(requestParameters: NotaryControllerPartialUpdateNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notary>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notaryControllerPartialUpdateNotary().'
            );
        }

        if (requestParameters['updateNotaryDTO'] == null) {
            throw new runtime.RequiredError(
                'updateNotaryDTO',
                'Required parameter "updateNotaryDTO" was null or undefined when calling notaryControllerPartialUpdateNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotaryDTOToJSON(requestParameters['updateNotaryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerPartialUpdateNotary(requestParameters: NotaryControllerPartialUpdateNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notary> {
        const response = await this.notaryControllerPartialUpdateNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerPayoutSchedulingRaw(requestParameters: NotaryControllerPayoutSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['payout'] == null) {
            throw new runtime.RequiredError(
                'payout',
                'Required parameter "payout" was null or undefined when calling notaryControllerPayoutScheduling().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/Payout-scheduling`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayoutToJSON(requestParameters['payout']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerPayoutScheduling(requestParameters: NotaryControllerPayoutSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerPayoutSchedulingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notaryControllerPayoutToNotaryRaw(requestParameters: NotaryControllerPayoutToNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutResponseDTO>> {
        if (requestParameters['payoutToNotaryDTO'] == null) {
            throw new runtime.RequiredError(
                'payoutToNotaryDTO',
                'Required parameter "payoutToNotaryDTO" was null or undefined when calling notaryControllerPayoutToNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/payout-to-notaryAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayoutToNotaryDTOToJSON(requestParameters['payoutToNotaryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerPayoutToNotary(requestParameters: NotaryControllerPayoutToNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutResponseDTO> {
        const response = await this.notaryControllerPayoutToNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerStripeAccRaw(requestParameters: NotaryControllerStripeAccRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['stripeDT'] == null) {
            throw new runtime.RequiredError(
                'stripeDT',
                'Required parameter "stripeDT" was null or undefined when calling notaryControllerStripeAcc().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/connectedAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StripeDTToJSON(requestParameters['stripeDT']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerStripeAcc(requestParameters: NotaryControllerStripeAccRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerStripeAccRaw(requestParameters, initOverrides);
    }

    /**
     */
    async notaryControllerStripePyoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripePaymentResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/stripe-payout-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripePaymentResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerStripePyout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripePaymentResponseDTO> {
        const response = await this.notaryControllerStripePyoutRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerUpdateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary/updateBusinessStatus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async notaryControllerUpdate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notaryControllerUpdateRaw(initOverrides);
    }

    /**
     */
    async notaryControllerUpdateNotaryRaw(requestParameters: NotaryControllerUpdateNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Notary>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notaryControllerUpdateNotary().'
            );
        }

        if (requestParameters['updateNotaryDTO'] == null) {
            throw new runtime.RequiredError(
                'updateNotaryDTO',
                'Required parameter "updateNotaryDTO" was null or undefined when calling notaryControllerUpdateNotary().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotaryDTOToJSON(requestParameters['updateNotaryDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerUpdateNotary(requestParameters: NotaryControllerUpdateNotaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Notary> {
        const response = await this.notaryControllerUpdateNotaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerUploadCertificateRaw(requestParameters: NotaryControllerUploadCertificateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CertificateResponseDTO>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling notaryControllerUploadCertificate().'
            );
        }

        if (requestParameters['passcode'] == null) {
            throw new runtime.RequiredError(
                'passcode',
                'Required parameter "passcode" was null or undefined when calling notaryControllerUploadCertificate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['passcode'] != null) {
            formParams.append('passcode', requestParameters['passcode'] as any);
        }

        const response = await this.request({
            path: `/notary/upload-certificate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CertificateResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerUploadCertificate(requestParameters: NotaryControllerUploadCertificateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CertificateResponseDTO> {
        const response = await this.notaryControllerUploadCertificateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryControllerUploadCertificatemanuallRaw(requestParameters: NotaryControllerUploadCertificatemanuallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CertificateResponseDTO>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling notaryControllerUploadCertificatemanuall().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling notaryControllerUploadCertificatemanuall().'
            );
        }

        if (requestParameters['passcode'] == null) {
            throw new runtime.RequiredError(
                'passcode',
                'Required parameter "passcode" was null or undefined when calling notaryControllerUploadCertificatemanuall().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['passcode'] != null) {
            formParams.append('passcode', requestParameters['passcode'] as any);
        }

        const response = await this.request({
            path: `/notary/upload-certificate/manuall/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CertificateResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async notaryControllerUploadCertificatemanuall(requestParameters: NotaryControllerUploadCertificatemanuallRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CertificateResponseDTO> {
        const response = await this.notaryControllerUploadCertificatemanuallRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
