/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveNotary
 */
export interface RemoveNotary {
    /**
     * 
     * @type {number}
     * @memberof RemoveNotary
     */
    notaryId?: number;
}

/**
 * Check if a given object implements the RemoveNotary interface.
 */
export function instanceOfRemoveNotary(value: object): value is RemoveNotary {
    return true;
}

export function RemoveNotaryFromJSON(json: any): RemoveNotary {
    return RemoveNotaryFromJSONTyped(json, false);
}

export function RemoveNotaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveNotary {
    if (json == null) {
        return json;
    }
    return {
        
        'notaryId': json['notaryId'] == null ? undefined : json['notaryId'],
    };
}

export function RemoveNotaryToJSON(value?: RemoveNotary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notaryId': value['notaryId'],
    };
}

