import { create } from "zustand";
import { Session } from "../api";

export interface IActiveSessionStore {
  ipData: string;
  sessionInfo?: Session;
  activeSessionId: number;
  setIpData: (value: string) => void;
  setSessionInfo: (session: Session) => void;
  setActiveSessionId: (activeSessionId: number) => void;
  clearSessionInfo: () => void;
}

export const useActiveSessionStore = create<IActiveSessionStore>((set) => ({
  ipData: "",
  session: undefined,
  activeSessionId: 0,
  setIpData: (value: string) => {
    set({
      ipData: value,
    });
  },
  setSessionInfo: (session: Session) => {
    set({
      sessionInfo: session,
    });
  },
  setActiveSessionId: (activeSessionId: number) => {
    set({
      activeSessionId: activeSessionId,
    });
  },
  clearSessionInfo: () => {
    set({
      sessionInfo: undefined,
    });
  },
}));
