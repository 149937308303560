import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISignerData } from "../../../../store/zustandType";
import { signerPaths } from "../../../../routes/routes";

const DemoVerificationData = ({
  from,
  onSetResult,
  signerData,
}: {
  from?: string;
  onSetResult?: (result: string) => void;
  signerData: ISignerData;
}) => {
  const navigate = useNavigate();
  const [ans, setAns] = useState<any>({});

  const questions = [
    {
      question: "What is your first name?",
      type: "firstName",
      answers: ["John", "Joe", "Adam", "None of the above"],
    },
    {
      question: "What is your last name?",
      type: "lastName",
      answers: ["Deo", "Leo", "Smith", "None of the above"],
    },
    {
      question: "What is your state?",
      type: "state",
      answers: ["Florida", "Ohio", "New York", "None of the above"],
    },
    {
      question: "What is your date of birth?",
      type: "dob",
      answers: ["2000-01-15", signerData?.dob, "1999-11-20", "1998-01-10"],
    },
    {
      question: "What is last four digit of your SSN number?",
      type: "lastSsn",
      answers: [signerData?.lastSsn, "4444", "6565", "0123"],
    },
  ];

  const handleSubmit = () => {
    if (onSetResult) {
      onSetResult("pass");
    }
    if (from === "page") {
      navigate(`${signerPaths.createSession}/signer-session-connecting`);
    }
    setAns({});
  };

  return (
    <Stack px={4}>
      <>
        <Text pt={3} fontSize={"18px"}>
          Verify your profile with Idology
        </Text>

        {questions?.flatMap((item, idx) => (
          <Box
            key={idx}
            pt={3}
            fontFamily={"Lato"}
            fontWeight="500"
            fontSize={"16.5px"}
          >
            <Text>
              {idx + 1}. {item?.question}
            </Text>
            <RadioGroup
              pt={4}
              value={ans[item?.type]}
              onChange={(value) => {
                setAns({ ...ans, [item?.type]: value });
              }}
            >
              {item?.answers?.flatMap((item, idx) => (
                <Box key={idx}>
                  <Radio value={item.toString()} fontSize={"15px"} pb={2}>
                    {item}
                  </Radio>
                </Box>
              ))}
            </RadioGroup>
          </Box>
        ))}
      </>

      <Flex alignSelf={"end"} marginTop={5}>
        {from === "page" && (
          <Button
            mx={2}
            style={{ padding: "10px 30px" }}
            width={"fit-content"}
            onClick={() => {
              navigate(`${signerPaths.createSession}/notary-detail`);
            }}
          >
            Prev
          </Button>
        )}

        <Button
          mx={2}
          width={"fit-content"}
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Flex>
    </Stack>
  );
};

export default DemoVerificationData;
