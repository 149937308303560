import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { NotaryApi, NotaryQuizApi } from "../../../api";
import { useProfileDetailsStore } from "../../../store/notaryStore";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import Steppers from "../../commonComponents/Steppers";
import NotaryQuizForm from "./NotaryQuizForm";
import { string } from "yup";

const stepData = [
  {
    label: "Notary Terms of Service",
    path: "https://www.notarizegenie.com/notary-terms-modal",
  },
  {
    label: "Privacy Policy",
    path: "https://www.notarizegenie.com/privacy-policy-modal",
  },
  {
    label: "Terms of Service",
    path: "https://www.notarizegenie.com/terms-service-modal",
  },
  // {
  //   label: "Notary Quiz",
  //   path: "https://forms.gle/qs1zMmSfuYKPid9D8",
  // },
  {
    label: "Notary On-boarding Video",
    path: "https://www.youtube.com/embed/C9hN0TwwkUM",
  },
];

const TermsModal = ({
  isOpen,
  onClose,
  onOpen,
  isQuiz,
  setIsAgreed
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isQuiz: boolean;
  setIsAgreed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [step, setStep] = useState<number>(0);
  const [ans, setAns] = useState<string[]>([]);
  const [disable, setDisable] = useState<boolean>(false);
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();

  useEffect(() => {
    if (notaryProfile?.quizAnswers !== undefined) {
      if (Object.keys(notaryProfile?.quizAnswers).length === 11) {
        setDisable(false);
        const arrayAns = Object.values(notaryProfile?.quizAnswers);
        const ress = arrayAns.filter((item, idx) => idx === 3);
        const nonArr = arrayAns.filter((item, idx) => idx !== 3);
        const spArr = ress?.[0] as string[];
        const totalArr = [...nonArr, ...spArr];
        setAns(totalArr as string[]);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(false);
    }
  }, [notaryProfile?.quizAnswers]);
  // const handleSubmit = () => {
  //   if (notaryProfile?.quizAnswers !== undefined) {
  //     if (Object.keys(notaryProfile?.quizAnswers).length === 0) {
  //       new NotaryQuizApi(configuration)
  //         .notaryQuizControllerGetAnswers({
  //           answersDTO: {
  //             answers: ans,
  //           },
  //         })
  //         .then((res) => {
  //           setNotaryProfile({ ...notaryProfile, quizResult: String(2) });
  //           if (Number(res?.percent) >=0) {
  //             notification("success", "You pass the quiz!");
  //             setNotaryProfile({
  //               ...notaryProfile,
  //               quizAnswers: {},
  //               quizResult :String(2),
  //             });
  //             setAns([]);
  //             // setStep(4);
  //             // onClose();
  //             // window.open(
  //             //   "https://calendly.com/notarizegenie/30min?month=2023-02",
  //             //   "_blank"
  //             // );
  //           } else {
  //             if (notaryProfile?.quizAttempts === 2) {
  //               // window.open(
  //               //   "https://calendly.com/notarizegenie/30min?month=2023-02",
  //               //   "_blank"
  //               // );
  //               // onClose();
  //               setStep(4);
  //             }
  //             notification("fail", "You fail the quiz!");
  //             setNotaryProfile({
  //               ...notaryProfile,
  //               quizAnswers: {},
  //               quizResult: res.percent,
  //             });
  //             setAns([]);
  //             // setStep(0);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setStep(0);
  //           setNotaryProfile({ ...notaryProfile, quizAnswers: {} });
  //           setAns([]);
  //         });
  //     }
  //   }
  // };

  const handleRetake = () => {
    new NotaryApi(configuration)
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          quizAttempts: notaryProfile?.quizAttempts + 1,
        },
      })
      .then((res) => {
        setNotaryProfile({
          ...notaryProfile,
          quizAnswers: {},
          quizResult: "",
          quizAttempts: notaryProfile?.quizAttempts + 1,
        });
      });
    setAns([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setStep(0);
        onClose();
      }}
      size={"5xl"}
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalCloseButton
          style={{
            background: "#D5AD36",
            color: "#fff",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            position: "absolute",
            right: "-9px",
            top: "-14px",
          }}
        />

        <ModalBody pt={5}>
          <Steppers
            step={step}
            stepData={stepData}
            onSetStep={(step) => setStep(step)}
            from="terms"
          />

          <Divider mb={2} />

          <Box height="450" overflow={"auto"}>
            {
            // step === 3 ? (
              // <NotaryQuizForm />
            // ) 
            // : 
            (
              <iframe
                title="myFrame"
                src={stepData?.[step].path}
                width={"100%"}
                height="450"
                allow="autoplay"
              />
            )}
          </Box>
        </ModalBody>

        <Divider />

        <ModalFooter justifyContent={"space-between"}>
          {step !== 0 ? (
            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
              }}
              onClick={() => setStep(step - 1)}
            >
              Prev
            </Button>
          ) : (
            <Box />
          )}

          {step === 3 ? (
            <Button
              style={{
                backgroundColor: "#D5AD36",
                color: "#fff",
                padding: "10px 30px",
              }}
              onClick={() => {
                setStep(0);
                onClose();
                setIsAgreed(true);
              }}
            >
              Agree & Submit
            </Button>
          ) 
          : 
         (
            <Button
              style={{
                backgroundColor: "#D5AD36",
                color: "#fff",
                padding: "10px 30px",
              }}
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Next
            </Button>
          )
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsModal;
