/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuditLogs,
  RemoveAuditLogDTO,
  RemovePageResponseDTO,
} from '../models/index';
import {
    AuditLogsFromJSON,
    AuditLogsToJSON,
    RemoveAuditLogDTOFromJSON,
    RemoveAuditLogDTOToJSON,
    RemovePageResponseDTOFromJSON,
    RemovePageResponseDTOToJSON,
} from '../models/index';

export interface AuditLogsControllerGetRequest {
    sessionId: number;
}

export interface AuditLogsControllerGetActiveSessionIdRequest {
    activeSessionId: number;
}

export interface AuditLogsControllerGetAudiLogsBySessionIdRequest {
    sessionId: number;
}

export interface AuditLogsControllerGetAudiLogsFormRequest {
    sessionId: number;
    docId: string;
}

export interface AuditLogsControllerRemovePageRequest {
    removeAuditLogDTO: RemoveAuditLogDTO;
}

/**
 * 
 */
export class AuditLogsApi extends runtime.BaseAPI {

    /**
     */
    async auditLogsControllerGetRaw(requestParameters: AuditLogsControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditLogs>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling auditLogsControllerGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/auditlogs/test/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditLogsFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsControllerGet(requestParameters: AuditLogsControllerGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditLogs> {
        const response = await this.auditLogsControllerGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerGetActiveSessionIdRaw(requestParameters: AuditLogsControllerGetActiveSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuditLogs>>> {
        if (requestParameters['activeSessionId'] == null) {
            throw new runtime.RequiredError(
                'activeSessionId',
                'Required parameter "activeSessionId" was null or undefined when calling auditLogsControllerGetActiveSessionId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/get/{activeSessionId}`.replace(`{${"activeSessionId"}}`, encodeURIComponent(String(requestParameters['activeSessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditLogsFromJSON));
    }

    /**
     */
    async auditLogsControllerGetActiveSessionId(requestParameters: AuditLogsControllerGetActiveSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuditLogs>> {
        const response = await this.auditLogsControllerGetActiveSessionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerGetAllAudiLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditLogs>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditLogsFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsControllerGetAllAudiLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditLogs> {
        const response = await this.auditLogsControllerGetAllAudiLogsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerGetAudiLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuditLogs>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/get-auditLogs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditLogsFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsControllerGetAudiLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuditLogs> {
        const response = await this.auditLogsControllerGetAudiLogsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerGetAudiLogsBySessionIdRaw(requestParameters: AuditLogsControllerGetAudiLogsBySessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuditLogs>>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling auditLogsControllerGetAudiLogsBySessionId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/get/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditLogsFromJSON));
    }

    /**
     */
    async auditLogsControllerGetAudiLogsBySessionId(requestParameters: AuditLogsControllerGetAudiLogsBySessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuditLogs>> {
        const response = await this.auditLogsControllerGetAudiLogsBySessionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerGetAudiLogsFormRaw(requestParameters: AuditLogsControllerGetAudiLogsFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuditLogs>>> {
        if (requestParameters['sessionId'] == null) {
            throw new runtime.RequiredError(
                'sessionId',
                'Required parameter "sessionId" was null or undefined when calling auditLogsControllerGetAudiLogsForm().'
            );
        }

        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling auditLogsControllerGetAudiLogsForm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/audit-logs/getAll/{sessionId}/{docId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters['sessionId']))).replace(`{${"docId"}}`, encodeURIComponent(String(requestParameters['docId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditLogsFromJSON));
    }

    /**
     */
    async auditLogsControllerGetAudiLogsForm(requestParameters: AuditLogsControllerGetAudiLogsFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuditLogs>> {
        const response = await this.auditLogsControllerGetAudiLogsFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async auditLogsControllerRemovePageRaw(requestParameters: AuditLogsControllerRemovePageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemovePageResponseDTO>> {
        if (requestParameters['removeAuditLogDTO'] == null) {
            throw new runtime.RequiredError(
                'removeAuditLogDTO',
                'Required parameter "removeAuditLogDTO" was null or undefined when calling auditLogsControllerRemovePage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/audit-logs/remove`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveAuditLogDTOToJSON(requestParameters['removeAuditLogDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemovePageResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async auditLogsControllerRemovePage(requestParameters: AuditLogsControllerRemovePageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemovePageResponseDTO> {
        const response = await this.auditLogsControllerRemovePageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
