import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Stack,
  Text,
  Divider,
  Box,
} from "@chakra-ui/react";
import InviteWitness from "../components/InviteWitness";
import InviteParticipants from "../components/InviteParticipants";

type Props = {
  active: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const InviteParticipantsModal = ({
  active,
  isOpen,
  onOpen,
  onClose,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={"4xl"}
    >
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalCloseButton
          _hover={{
            background: "#000",
          }}
          style={{
            background: "#D5AD36",
            color: "#fff",
            height: "35px",
            width: "35px",
            borderRadius: "50%",
            position: "absolute",
            right: "-9px",
            top: "-14px",
          }}
        />
        <ModalBody>
          <Stack>
            <Text fontSize={"24px"} fontWeight={"700"} fontFamily="Lato">
              {active === "signer"
                ? "Invite Signers"
                : active === "witness"
                ? "Invite Witnesses"
                : "Invite Mediators"}
            </Text>
            <Divider />
            <Box maxHeight={"650px"} overflow="auto">
              {active === "signer" ? (
                <InviteParticipants from="signer" onSuccess={onClose} />
              ) : active === "witness" ? (
                <InviteWitness onSuccess={onClose} />
              ) : (
                <InviteParticipants from="mediator" onSuccess={onClose} />
              )}
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InviteParticipantsModal;
