/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripePaymentResponseDTO
 */
export interface StripePaymentResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof StripePaymentResponseDTO
     */
    object: string;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentResponseDTO
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentResponseDTO
     */
    expiresAt: string;
    /**
     * 
     * @type {string}
     * @memberof StripePaymentResponseDTO
     */
    url: string;
}

/**
 * Check if a given object implements the StripePaymentResponseDTO interface.
 */
export function instanceOfStripePaymentResponseDTO(value: object): value is StripePaymentResponseDTO {
    if (!('object' in value) || value['object'] === undefined) return false;
    if (!('created' in value) || value['created'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function StripePaymentResponseDTOFromJSON(json: any): StripePaymentResponseDTO {
    return StripePaymentResponseDTOFromJSONTyped(json, false);
}

export function StripePaymentResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePaymentResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'object': json['object'],
        'created': json['created'],
        'expiresAt': json['expires_at'],
        'url': json['url'],
    };
}

export function StripePaymentResponseDTOToJSON(value?: StripePaymentResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object': value['object'],
        'created': value['created'],
        'expires_at': value['expiresAt'],
        'url': value['url'],
    };
}

