/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    planId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    isSubscription: boolean;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    planName: string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    planAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    planFeatures: string;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    isActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    packagePerMonth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    isBusiness: boolean;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    freeStamps: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    firstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    inHouseNotaryFirstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    firstStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    primarySigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    primarySignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalSigner?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalSignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    primaryWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    primaryWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    additionalWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    ownWitnessPrice?: number;
}

/**
 * Check if a given object implements the Plan interface.
 */
export function instanceOfPlan(value: object): value is Plan {
    if (!('planId' in value) || value['planId'] === undefined) return false;
    if (!('isSubscription' in value) || value['isSubscription'] === undefined) return false;
    if (!('planName' in value) || value['planName'] === undefined) return false;
    if (!('planAmount' in value) || value['planAmount'] === undefined) return false;
    if (!('planFeatures' in value) || value['planFeatures'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isBusiness' in value) || value['isBusiness'] === undefined) return false;
    if (!('freeStamps' in value) || value['freeStamps'] === undefined) return false;
    return true;
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'],
        'isSubscription': json['isSubscription'],
        'planName': json['planName'],
        'planAmount': json['planAmount'],
        'planFeatures': json['planFeatures'],
        'isActive': json['isActive'],
        'packagePerMonth': json['packagePerMonth'] == null ? undefined : json['packagePerMonth'],
        'isBusiness': json['isBusiness'],
        'freeStamps': json['freeStamps'],
        'firstStampPrice': json['firstStampPrice'] == null ? undefined : json['firstStampPrice'],
        'inHouseNotaryFirstStampPrice': json['inHouseNotaryFirstStampPrice'] == null ? undefined : json['inHouseNotaryFirstStampPrice'],
        'firstStampPriceFees': json['firstStampPriceFees'] == null ? undefined : json['firstStampPriceFees'],
        'additionalStampPrice': json['additionalStampPrice'] == null ? undefined : json['additionalStampPrice'],
        'additionalStampPriceFees': json['additionalStampPriceFees'] == null ? undefined : json['additionalStampPriceFees'],
        'primarySigner': json['primarySigner'] == null ? undefined : json['primarySigner'],
        'primarySignerFees': json['primarySignerFees'] == null ? undefined : json['primarySignerFees'],
        'additionalSigner': json['additionalSigner'] == null ? undefined : json['additionalSigner'],
        'additionalSignerFees': json['additionalSignerFees'] == null ? undefined : json['additionalSignerFees'],
        'primaryWitness': json['primaryWitness'] == null ? undefined : json['primaryWitness'],
        'primaryWitnessFees': json['primaryWitnessFees'] == null ? undefined : json['primaryWitnessFees'],
        'additionalWitness': json['additionalWitness'] == null ? undefined : json['additionalWitness'],
        'additionalWitnessFees': json['additionalWitnessFees'] == null ? undefined : json['additionalWitnessFees'],
        'ownWitnessPrice': json['ownWitnessPrice'] == null ? undefined : json['ownWitnessPrice'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'isSubscription': value['isSubscription'],
        'planName': value['planName'],
        'planAmount': value['planAmount'],
        'planFeatures': value['planFeatures'],
        'isActive': value['isActive'],
        'packagePerMonth': value['packagePerMonth'],
        'isBusiness': value['isBusiness'],
        'freeStamps': value['freeStamps'],
        'firstStampPrice': value['firstStampPrice'],
        'inHouseNotaryFirstStampPrice': value['inHouseNotaryFirstStampPrice'],
        'firstStampPriceFees': value['firstStampPriceFees'],
        'additionalStampPrice': value['additionalStampPrice'],
        'additionalStampPriceFees': value['additionalStampPriceFees'],
        'primarySigner': value['primarySigner'],
        'primarySignerFees': value['primarySignerFees'],
        'additionalSigner': value['additionalSigner'],
        'additionalSignerFees': value['additionalSignerFees'],
        'primaryWitness': value['primaryWitness'],
        'primaryWitnessFees': value['primaryWitnessFees'],
        'additionalWitness': value['additionalWitness'],
        'additionalWitnessFees': value['additionalWitnessFees'],
        'ownWitnessPrice': value['ownWitnessPrice'],
    };
}

