import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { MdEmail as MsgIcon } from "react-icons/md";
import { Message, MessagesApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import NotificationModal from "./NotificationModal";

const Notifications = ({ onClose }: { onClose: () => void }) => {
  const { configuration } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Message[]>([]);
  const [selectedData, setSelectedData] = useState<Message>();
  const { isOpen, onOpen, onClose: onNoticClose } = useDisclosure();
  const token = localStorage.getItem("accessToken");
  const [isShow, setIsShow] = useState<boolean>(true);

  useEffect(() => {
    if (token !== null) {
      setLoading(true);
      new MessagesApi(configuration)
        .messageControllerGetMessage()
        .then((res) => {
          setData(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setData([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {/* <Flex mb={3} alignItems="center" justifyContent={"space-between"}>
        <Flex alignItems="center">
          <IoSettingsOutline />
          <Text ml={2} fontWeight={500}>
            Show Notifications
          </Text>
        </Flex>
        <Switch isChecked={isShow} onChange={() => setIsShow(!isShow)} />
      </Flex> */}
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : data?.length === 0 ? (
        <Text>No Notification</Text>
      ) : (
        data?.flatMap((item, idx) => (
          <>
            <Box
              key={idx}
              role="button"
              onClick={() => {
                setSelectedData(item);
                onClose();
                onOpen();
              }}
            >
              <Flex mb={2}>
                {item?.imageLink ? (
                  <Image
                    src={item?.imageLink}
                    borderRadius="full"
                    h={"50px"}
                    w={"50px"}
                  />
                ) : (
                  <Box
                    borderRadius="full"
                    h={"50px"}
                    w={"50px"}
                    background="#000080"
                    display={"flex"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MsgIcon size={28} color="#D5AD36" />
                  </Box>
                )}

                <Box ml={3}>
                  <Text fontWeight={600}>{item?.title}</Text>
                  <Text fontWeight={500}>{item?.message}</Text>
                </Box>
              </Flex>
            </Box>
            <Divider />
          </>
        ))
      )}
      <NotificationModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onNoticClose}
        selectedData={selectedData}
      />
    </>
  );
};

export default Notifications;
