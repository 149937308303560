import { useEffect } from "react";
import DocumentUpload from "../../../../component/Sessions/Notary/DocumentUpload";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";

const DocumentUploadPage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(3);
  }, [setStep]);

  return <DocumentUpload />;
};

export default DocumentUploadPage;
