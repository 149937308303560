/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactUsDTO,
  ContactUsResponseDTO,
  EmailDto,
} from '../models/index';
import {
    ContactUsDTOFromJSON,
    ContactUsDTOToJSON,
    ContactUsResponseDTOFromJSON,
    ContactUsResponseDTOToJSON,
    EmailDtoFromJSON,
    EmailDtoToJSON,
} from '../models/index';

export interface ContactUsControllerCreateRequest {
    contactUsDTO: ContactUsDTO;
}

export interface ContactUsControllerSendMailRequest {
    emailDto: EmailDto;
}

/**
 * 
 */
export class ContactUsApi extends runtime.BaseAPI {

    /**
     */
    async contactUsControllerCreateRaw(requestParameters: ContactUsControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactUsResponseDTO>> {
        if (requestParameters['contactUsDTO'] == null) {
            throw new runtime.RequiredError(
                'contactUsDTO',
                'Required parameter "contactUsDTO" was null or undefined when calling contactUsControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contact-us`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactUsDTOToJSON(requestParameters['contactUsDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactUsResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async contactUsControllerCreate(requestParameters: ContactUsControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactUsResponseDTO> {
        const response = await this.contactUsControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactUsControllerSendMailRaw(requestParameters: ContactUsControllerSendMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['emailDto'] == null) {
            throw new runtime.RequiredError(
                'emailDto',
                'Required parameter "emailDto" was null or undefined when calling contactUsControllerSendMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/contact-us/sendEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailDtoToJSON(requestParameters['emailDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contactUsControllerSendMail(requestParameters: ContactUsControllerSendMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contactUsControllerSendMailRaw(requestParameters, initOverrides);
    }

}
