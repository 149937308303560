/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';

/**
 * 
 * @export
 * @interface FeedBack
 */
export interface FeedBack {
    /**
     * 
     * @type {number}
     * @memberof FeedBack
     */
    feedbackId: number;
    /**
     * 
     * @type {string}
     * @memberof FeedBack
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FeedBack
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FeedBack
     */
    description: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof FeedBack
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {Date}
     * @memberof FeedBack
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof FeedBack
     */
    updatedDate: Date;
}

/**
 * Check if a given object implements the FeedBack interface.
 */
export function instanceOfFeedBack(value: object): value is FeedBack {
    if (!('feedbackId' in value) || value['feedbackId'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function FeedBackFromJSON(json: any): FeedBack {
    return FeedBackFromJSONTyped(json, false);
}

export function FeedBackFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedBack {
    if (json == null) {
        return json;
    }
    return {
        
        'feedbackId': json['feedbackId'],
        'title': json['title'],
        'type': json['type'],
        'description': json['description'],
        'user': OmitTypeClassFromJSON(json['user']),
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function FeedBackToJSON(value?: FeedBack | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'feedbackId': value['feedbackId'],
        'title': value['title'],
        'type': value['type'],
        'description': value['description'],
        'user': OmitTypeClassToJSON(value['user']),
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
    };
}

