import { Box } from "@chakra-ui/react";
import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const TennesseStamp = ({
  name,
  height,
  width,
  commissionNumber,
  expires,
}: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <Box border={"1px solid"} width={"fit-content"}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 500.000000 193.000000"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ background: "transparent" }}
        stroke="transparent"
      >
        {/* ****************************** Name ******************************  */}

        {name.length > 22 ? (
          <>
            <text
              x="68%"
              y="7%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"22px"}
              fontWeight="bold"
            >
              {notaryProfile.firstName} {notaryProfile.middleName}
            </text>
            <text
              x="68%"
              y="20%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"22px"}
              fontWeight="bold"
            >
              {notaryProfile.lastName} {notaryProfile.suffix}
            </text>
          </>
        ) : (
          <text
            x="68%"
            y="17%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={"22px"}
            fontWeight="bold"
          >
            {name}
          </text>
        )}

        {/* ****************************** Text - Notary Public - state of Floriada ******************************  */}

        <text
          x="68%"
          y="32%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          Tennesse Notary Public
        </text>

        <text
          x="68%"
          y="47%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          Online Notary Public
        </text>

        <text
          x="68%"
          y="62%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          Sumner County
        </text>

        {/* ****************************** Text -commission ******************************  */}

        <text
          x="68%"
          y="77%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          Commission #: {commissionNumber}
        </text>

        {/* *************************************** Text -Expires ******************************************** */}

        <text
          x="67%"
          y="92%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"20px"}
          fontWeight="bold"
        >
          Commission Expires: {moment(expires).format("MMM DD, YYYY")}
        </text>

        <g
          transform="translate(0.000000,193.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M950 1796 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"
          />
          <path
            d="M990 1801 c0 -11 5 -23 10 -26 6 -4 10 5 10 19 0 14 -4 26 -10 26 -5
0 -10 -9 -10 -19z"
          />
          <path
            d="M1025 1810 c-3 -5 -1 -18 4 -27 9 -16 10 -14 11 10 0 28 -5 34 -15
17z"
          />
          <path d="M912 1790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path d="M1072 1790 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path
            d="M1110 1790 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"
          />
          <path
            d="M1147 1790 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"
          />
          <path d="M830 1783 c0 -12 5 -25 10 -28 13 -8 13 15 0 35 -8 12 -10 11 -10 -7z" />
          <path
            d="M870 1786 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"
          />
          <path
            d="M1187 1786 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"
          />
          <path
            d="M790 1778 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
-5 3 -10 0 -10 -7z"
          />
          <path d="M1227 1777 c-11 -29 -9 -43 4 -30 6 6 9 19 7 29 -3 18 -4 18 -11 1z" />
          <path
            d="M1267 1774 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"
          />
          <path d="M760 1749 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z" />
          <path
            d="M1305 1750 c-4 -12 -5 -24 -2 -27 3 -2 8 5 12 17 4 12 5 24 2 27 -3
2 -8 -5 -12 -17z"
          />
          <path d="M710 1753 c0 -14 19 -38 25 -32 8 8 -5 39 -16 39 -5 0 -9 -3 -9 -7z" />
          <path
            d="M677 1743 c-3 -4 1 -17 9 -28 10 -13 14 -15 14 -4 0 20 -16 43 -23
32z"
          />
          <path d="M1333 1735 c-9 -25 4 -29 17 -6 7 15 7 21 0 21 -6 0 -13 -7 -17 -15z" />
          <path
            d="M644 1714 c3 -9 6 -18 6 -20 0 -2 4 -4 9 -4 5 0 7 9 4 20 -3 11 -10
20 -15 20 -6 0 -7 -7 -4 -16z"
          />

          <path d="M1377 1723 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z" />
          <path
            d="M1410 1700 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27 -3
2 -11 -5 -17 -17z"
          />
          <path d="M600 1701 c0 -5 7 -14 15 -21 16 -14 18 -10 9 14 -6 17 -24 22 -24 7z" />
          <path
            d="M1440 1680 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27 -3
2 -11 -5 -17 -17z"
          />

          <path d="M3473 1625 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />

          <path d="M580 1669 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z" />
          <path
            d="M995 1635 c0 -30 3 -55 8 -55 4 0 7 25 7 55 0 30 -3 55 -7 55 -5 0
-8 -25 -8 -55z"
          />

          <path
            d="M1056 1674 c-32 -31 -11 -104 30 -104 36 0 54 20 54 61 0 33 -4 41
-26 49 -34 13 -40 13 -58 -6z m72 -42 c6 -54 -61 -68 -74 -15 -4 15 0 30 11
43 25 27 59 12 63 -28z"
          />

          <path
            d="M921 1648 c1 -18 5 -44 10 -58 6 -18 7 -12 4 25 l-5 50 30 -35 c37
-43 40 -31 4 16 -33 43 -44 43 -43 2z"
          />
          <path
            d="M1192 1660 c14 -9 15 -15 3 -55 -8 -25 -11 -45 -7 -45 5 0 13 20 20
45 9 36 16 45 34 46 22 1 22 1 -2 9 -14 4 -34 8 -45 9 -18 0 -18 -1 -3 -9z"
          />

          <path
            d="M1476 1654 c-9 -24 -7 -28 9 -14 17 14 20 30 6 30 -5 0 -12 -7 -15
-16z"
          />

          <path d="M540 1645 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
          <path
            d="M770 1640 c-23 -23 -24 -29 -11 -63 18 -47 92 -42 90 6 l0 22 -9 -23
c-12 -28 -36 -38 -54 -23 -23 19 -25 50 -6 74 15 18 21 20 31 10 18 -18 34
-16 19 2 -17 20 -36 19 -60 -5z"
          />

          <path
            d="M1510 1635 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"
          />
          <path d="M510 1625 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
          <path
            d="M1267 1592 c-21 -21 -34 -41 -31 -45 4 -4 12 0 18 8 17 23 54 8 59
-24 2 -14 5 -2 6 27 0 28 1 55 2 59 2 20 -20 9 -54 -25z m40 -19 c-3 -3 -11
-2 -17 2 -8 5 -7 11 2 22 12 15 13 14 16 -1 2 -10 1 -20 -1 -23z"
          />
          <path d="M470 1612 c0 -10 22 -32 32 -32 5 0 3 9 -4 20 -13 20 -28 27 -28 12z" />
          <path
            d="M700 1615 c0 -13 45 -95 52 -95 5 0 -3 23 -17 50 -22 44 -35 60 -35
45z"
          />
          <path
            d="M1536 1604 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"
          />
          <path
            d="M650 1596 c0 -5 9 -24 21 -42 24 -40 22 -41 -32 -13 -23 11 -43 18
-46 15 -7 -7 46 -96 57 -96 5 0 0 16 -11 35 -23 38 -18 42 21 20 14 -8 33 -14
43 -15 17 0 17 3 -4 38 -25 42 -49 70 -49 58z"
          />
          <path
            d="M1570 1578 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"
          />
          <path
            d="M455 1570 c10 -11 20 -20 22 -20 2 0 -1 9 -7 20 -6 11 -16 20 -22 20
-6 0 -3 -9 7 -20z"
          />
          <path
            d="M1363 1555 c-27 -41 -31 -70 -5 -38 15 19 19 20 35 9 13 -10 16 -22
12 -42 -5 -28 -5 -28 10 4 8 17 20 32 25 32 19 0 10 39 -12 54 -32 23 -40 20
-65 -19z m77 -12 c0 -15 -28 -17 -49 -4 -12 8 -12 12 -2 25 10 12 16 13 32 3
10 -6 19 -17 19 -24z"
          />
          <path
            d="M1600 1555 c-17 -21 -9 -27 13 -10 10 7 14 16 9 19 -5 3 -15 -1 -22
-9z"
          />
          <path
            d="M420 1545 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
          />
          <path d="M1625 1529 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z" />
          <path d="M390 1522 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28 10z" />
          <path
            d="M1490 1502 c0 -10 -9 -34 -19 -55 -18 -34 -12 -55 6 -22 5 8 26 21
48 30 l40 15 -36 -5 c-35 -5 -35 -5 -31 25 2 16 2 30 -2 30 -3 0 -6 -8 -6 -18z"
          />
          <path
            d="M518 1499 c-29 -16 -24 -75 8 -96 24 -15 29 -15 53 -2 32 18 38 49
15 83 -17 27 -46 33 -76 15z m65 -31 c17 -22 18 -30 7 -43 -16 -20 -39 -19
-62 2 -21 19 -23 44 -6 61 18 18 38 11 61 -20z"
          />

          <path
            d="M1650 1495 c-14 -16 -10 -18 14 -9 9 3 16 10 16 15 0 14 -16 11 -30
-6z"
          />
          <path
            d="M370 1485 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"
          />
          <path
            d="M1674 1459 c-19 -22 -19 -22 4 -10 12 6 22 16 22 21 0 15 -5 12 -26
-11z"
          />

          <path
            d="M344 1455 c26 -19 42 -19 26 0 -7 8 -20 15 -29 15 -13 -1 -13 -3 3
-15z"
          />

          <path d="M4323 1375 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
          <path d="M310 1431 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30 6z" />
          <path d="M1700 1430 c-8 -5 -12 -11 -9 -14 3 -3 14 1 25 9 21 16 8 20 -16 5z" />

          <path
            d="M422 1387 c-12 -13 -22 -26 -22 -28 0 -3 20 -20 45 -38 25 -18 45
-28 45 -23 0 6 -7 12 -15 16 -19 7 -19 17 -1 41 12 16 16 17 30 6 8 -7 20 -11
27 -9 6 2 -2 10 -18 18 -15 8 -32 21 -36 27 -12 19 -31 16 -55 -10z m45 -3 c8
-21 -23 -52 -38 -37 -8 8 -8 17 0 32 14 25 29 27 38 5z"
          />

          <path
            d="M1724 1395 c-10 -8 -14 -14 -9 -15 15 0 40 18 34 24 -3 3 -14 -1 -25
-9z"
          />

          <path d="M290 1391 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30 6z" />
          <path
            d="M1568 1373 c-38 -26 -54 -53 -19 -31 17 10 23 8 39 -12 35 -43 79 -2
48 45 -20 30 -22 30 -68 -2z m62 -13 c9 -16 8 -22 -6 -27 -18 -7 -48 23 -38
38 9 16 33 9 44 -11z"
          />
          <path d="M270 1361 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30 6z" />
          <path d="M1740 1360 c-8 -5 -12 -11 -9 -14 3 -3 14 1 25 9 21 16 8 20 -16 5z" />
          <path
            d="M1006 1352 c-10 -2 -52 -22 -93 -43 -41 -21 -83 -39 -93 -39 -11 0
-37 -7 -59 -16 -30 -12 -45 -26 -61 -58 -11 -23 -20 -49 -20 -58 0 -8 -20 -55
-45 -104 -25 -48 -45 -97 -44 -108 0 -12 6 -4 13 19 7 22 26 63 43 90 17 28
36 75 43 105 17 71 50 104 119 119 30 6 79 25 110 41 102 53 103 54 178 14 38
-19 100 -44 138 -55 78 -21 100 -40 115 -102 5 -23 30 -82 55 -130 48 -95 48
-102 2 -169 -10 -15 -31 -56 -47 -91 -16 -35 -34 -68 -42 -74 -7 -6 -42 -19
-78 -28 -36 -9 -92 -32 -126 -51 -33 -19 -72 -34 -85 -34 -13 0 -49 13 -79 30
-30 16 -66 29 -80 29 -23 -1 -22 -2 5 -9 17 -4 58 -22 93 -39 l62 -33 74 41
c40 22 103 48 139 57 75 18 88 30 132 116 18 35 45 87 60 116 31 59 33 52 -31
158 -17 28 -36 76 -43 106 -15 71 -40 94 -132 119 -41 11 -87 29 -102 40 -35
26 -97 46 -121 41z"
          />

          <path
            d="M1760 1325 c-14 -16 -10 -18 14 -9 9 3 16 10 16 15 0 14 -16 11 -30
-6z"
          />
          <path d="M250 1321 c0 -5 9 -11 20 -14 24 -6 27 8 4 17 -21 8 -24 8 -24 -3z" />
          <path
            d="M360 1294 c-12 -19 -19 -38 -15 -42 3 -4 11 1 16 11 9 16 12 16 50
-4 54 -27 60 -19 8 10 -31 18 -38 27 -34 42 10 30 -1 23 -25 -17z"
          />
          <path
            d="M1638 1284 c-42 -22 -51 -47 -29 -76 l19 -23 47 23 c26 13 45 25 42
28 -3 3 -23 -4 -45 -15 -37 -19 -42 -19 -56 -6 -21 22 -12 38 37 63 23 11 35
21 27 21 -8 0 -27 -6 -42 -15z"
          />
          <path
            d="M1774 1285 c-20 -15 -11 -18 20 -6 11 4 15 10 9 14 -5 3 -19 0 -29
-8z"
          />
          <path d="M235 1280 c3 -5 16 -10 28 -10 18 0 19 2 7 10 -20 13 -43 13 -35 0z" />
          <path
            d="M1785 1250 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
          />
          <path d="M220 1240 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
          <path
            d="M210 1210 c0 -4 9 -10 20 -13 11 -3 20 -2 20 3 0 4 -9 10 -20 13 -11
3 -20 2 -20 -3z"
          />
          <path d="M1800 1210 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z" />
          <path
            d="M316 1189 c-16 -23 -12 -47 12 -66 20 -16 64 -17 80 -1 17 17 15 53
-3 68 -18 15 -19 7 -3 -20 18 -28 -10 -55 -46 -46 -39 10 -51 41 -26 66 11 11
16 20 11 20 -6 0 -17 -10 -25 -21z"
          />
          <path d="M2693 1125 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />

          <path d="M4133 1125 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
          <path
            d="M1685 1173 c-27 -8 -51 -16 -53 -18 -7 -6 28 -75 38 -75 5 0 18 4 28
9 9 5 25 12 35 15 17 5 22 37 9 67 -6 15 -12 15 -57 2z m50 -24 c8 -25 1 -34
-20 -26 -19 8 -20 47 -1 47 8 0 18 -9 21 -21z m-45 -19 c0 -19 -5 -30 -14 -30
-20 0 -32 40 -15 51 23 15 29 10 29 -21z"
          />

          <path d="M200 1170 c0 -7 24 -20 36 -20 13 0 1 19 -16 23 -11 3 -20 2 -20 -3z" />
          <path d="M1810 1170 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z" />

          <path d="M190 1130 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
          <path
            d="M1815 1130 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"
          />
          <path
            d="M918 1103 c-2 -16 -2 -97 0 -180 l3 -153 70 0 c78 0 129 17 153 51
23 34 20 83 -8 116 -21 25 -22 31 -10 48 17 24 18 84 0 107 -20 27 -61 38
-136 38 -67 0 -69 0 -72 -27z m180 -10 c27 -24 34 -63 16 -93 -13 -24 -14 -24
-8 15 9 61 -19 85 -96 85 l-60 0 0 -63 c0 -36 5 -68 12 -75 9 -9 9 -12 0 -12
-8 0 -12 -23 -12 -75 l0 -75 53 0 c62 0 96 15 116 52 20 35 2 75 -39 91 -17 6
-30 15 -30 19 0 13 10 9 43 -14 42 -29 47 -37 47 -75 0 -59 -54 -88 -153 -81
l-48 3 -2 158 -2 157 71 0 c54 0 77 -4 92 -17z m-20 -25 c14 -14 15 -47 3 -67
-5 -7 -32 -16 -60 -19 l-51 -5 0 52 0 51 48 0 c26 0 53 -5 60 -12z m-11 -137
c19 -2 43 -31 43 -52 0 -36 -36 -59 -90 -59 l-50 0 0 53 c0 30 3 56 6 60 5 5
37 5 91 -2z"
          />
          <path d="M184 1089 c-3 -5 6 -9 20 -9 31 0 34 6 6 13 -11 3 -23 1 -26 -4z" />
          <path
            d="M284 1064 c-8 -44 4 -54 64 -54 35 0 42 3 42 19 0 11 3 27 7 37 4 12
3 15 -5 10 -7 -4 -12 -18 -12 -32 0 -18 -5 -24 -21 -24 -18 0 -21 5 -17 30 3
17 1 30 -4 30 -4 0 -8 -11 -8 -25 0 -19 -5 -25 -20 -25 -18 0 -20 4 -14 35 3
19 4 35 1 35 -4 0 -9 -16 -13 -36z"
          />

          <path d="M1830 1090 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z" />
          <path
            d="M1693 1063 c-30 -4 -33 -7 -33 -39 0 -19 4 -34 10 -34 5 0 7 13 4 30
-6 28 -4 30 22 31 37 1 64 6 72 13 6 6 -20 6 -75 -1z"
          />
          <path
            d="M180 1050 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10 -8
0 -14 -4 -14 -10z"
          />
          <path d="M1833 1053 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path
            d="M1830 1010 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"
          />
          <path d="M183 1003 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path
            d="M370 952 c0 -27 -3 -29 -42 -30 -60 -3 -60 -12 0 -12 l52 0 0 35 c0
19 -2 35 -5 35 -3 0 -5 -13 -5 -28z"
          />
          <path
            d="M1830 970 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"
          />
          <path d="M183 963 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path d="M1685 959 c-22 -5 -15 -7 28 -8 31 0 57 3 57 9 0 10 -35 9 -85 -1z" />
          <path
            d="M1830 930 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"
          />

          <path d="M183 923 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path
            d="M1676 917 c-20 -15 -19 -68 2 -75 11 -3 11 0 1 16 -24 39 12 74 57
54 28 -13 31 -38 7 -64 -9 -10 -12 -18 -7 -18 17 0 34 23 34 46 0 46 -55 70
-94 41z"
          />

          <path
            d="M600 890 c0 -23 41 -85 78 -115 17 -15 32 -32 32 -39 0 -14 58 -86
70 -86 4 0 -8 17 -26 37 -19 20 -34 41 -34 46 0 6 -20 32 -46 59 -25 26 -51
63 -59 81 -8 17 -14 25 -15 17z"
          />
          <path d="M1833 893 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
          <path
            d="M180 880 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12 0
-21 -4 -21 -10z"
          />
          <path
            d="M381 858 c-1 -48 -27 -51 -40 -5 l-9 32 -1 -37 c-1 -47 -26 -52 -36
-7 -4 17 -9 28 -12 25 -3 -2 -2 -19 2 -36 l7 -32 51 7 c29 3 53 8 54 9 2 2 -1
21 -6 42 l-9 39 -1 -37z"
          />
          <path
            d="M195 850 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"
          />
          <path
            d="M1820 856 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"
          />
          <path d="M1815 820 c-4 -6 6 -10 22 -10 22 0 25 2 13 10 -19 12 -27 12 -35 0z" />
          <path d="M190 800 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23 -10z" />
          <path
            d="M218 773 c-10 -2 -18 -8 -18 -13 0 -4 9 -6 20 -3 11 3 20 9 20 14 0
5 -1 8 -2 8 -2 -1 -11 -3 -20 -6z"
          />
          <path
            d="M1820 770 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"
          />
          <path d="M1800 740 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z" />
          <path
            d="M228 732 c-10 -2 -18 -8 -18 -13 0 -11 11 -11 30 1 18 11 11 18 -12
12z"
          />
          <path
            d="M1790 700 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"
          />
          <path
            d="M225 690 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
          />

          <path
            d="M1780 660 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"
          />
          <path
            d="M245 650 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"
          />

          <path d="M808 643 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path d="M1761 624 c6 -8 18 -14 26 -14 20 0 8 16 -17 23 -17 4 -19 3 -9 -9z" />
          <path
            d="M264 615 c-10 -8 -14 -14 -9 -15 15 0 40 18 34 24 -3 3 -14 -1 -25
-9z"
          />
          <path d="M1740 590 c0 -7 24 -20 36 -20 13 0 1 19 -16 23 -11 3 -20 2 -20 -3z" />
          <path
            d="M288 582 c-10 -2 -18 -8 -18 -13 0 -11 11 -11 30 1 18 11 11 18 -12
12z"
          />

          <path d="M1720 561 c0 -10 30 -24 37 -17 3 2 -5 9 -16 16 -12 6 -21 7 -21 1z" />
          <path d="M304 545 c-17 -13 -17 -14 -2 -15 9 0 20 5 23 11 11 17 0 20 -21 4z" />
          <path
            d="M1467 518 l-36 -43 27 -28 26 -27 43 42 c24 23 43 47 43 53 0 5 -9
-1 -20 -15 -17 -22 -19 -23 -25 -7 -4 9 -2 24 5 32 7 8 9 15 5 15 -4 0 -14 -8
-21 -17 -17 -24 -29 -10 -14 16 16 32 7 25 -33 -21z m39 -27 c22 -18 23 -19 5
-35 -18 -16 -41 -22 -41 -9 0 3 7 15 17 25 9 10 11 18 5 18 -6 0 -15 -7 -20
-16 -5 -10 -14 -14 -21 -9 -9 5 -8 12 5 26 21 23 20 23 50 0z"
          />
          <path
            d="M517 544 c-3 -3 -2 -17 4 -31 12 -32 12 -33 -6 -33 -8 0 -15 5 -15
10 0 6 -5 10 -11 10 -8 0 -8 -5 1 -15 7 -8 21 -15 32 -15 18 0 19 4 13 36 -5
28 -3 35 7 31 7 -2 12 -12 12 -21 -1 -9 2 -16 7 -16 13 0 11 24 -3 38 -13 13
-31 16 -41 6z"
          />
          <path
            d="M1704 515 c11 -8 25 -15 30 -15 6 0 2 7 -8 15 -11 8 -25 15 -30 15
-6 0 -2 -7 8 -15z"
          />
          <path
            d="M324 505 c-10 -8 -14 -14 -9 -15 15 0 40 18 34 24 -3 3 -14 -1 -25
-9z"
          />
          <path
            d="M583 496 c3 -8 -4 -27 -15 -43 l-22 -28 30 28 c17 15 35 24 40 20 5
-5 0 2 -10 15 -22 25 -31 28 -23 8z"
          />
          <path d="M1683 483 c9 -9 20 -13 24 -9 4 4 1 11 -7 16 -25 16 -35 11 -17 -7z" />
          <path
            d="M350 475 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"
          />
          <path
            d="M1650 463 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"
          />
          <path d="M375 446 c-11 -8 -14 -15 -8 -19 6 -4 16 0 23 8 17 21 8 28 -15 11z" />
          <path
            d="M610 436 c-34 -20 -34 -20 -10 -26 15 -4 24 -13 24 -23 -1 -9 2 -14
7 -12 9 6 24 74 18 79 -2 2 -20 -6 -39 -18z m22 -18 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z"
          />
          <path d="M1400 450 c-12 -7 -10 -9 8 -7 12 0 22 5 22 9 0 11 -12 10 -30 -2z" />
          <path
            d="M677 429 c9 -11 8 -20 -2 -39 -22 -39 -17 -49 5 -13 13 21 25 31 34
27 14 -5 14 -9 1 -34 -20 -39 -19 -45 13 -59 l27 -12 -23 19 c-27 21 -19 34
17 27 25 -5 26 -5 4 6 -31 16 -29 32 3 26 31 -6 24 1 -41 38 -27 16 -45 22
-38 14z"
          />
          <path d="M1630 431 c0 -13 28 -33 33 -23 3 4 -3 13 -14 20 -10 7 -19 8 -19 3z" />

          <path
            d="M1359 420 c-15 -4 -25 -14 -27 -29 -3 -20 0 -22 30 -19 28 3 33 0 30
-17 -2 -15 -1 -17 7 -6 16 25 1 43 -30 36 -23 -5 -29 -3 -29 10 0 12 6 16 20
12 11 -3 20 -2 20 1 0 6 44 8 75 3 6 -1 -1 -11 -14 -21 -13 -11 -19 -20 -13
-20 18 0 41 30 36 44 -5 14 -56 17 -105 6z"
          />
          <path d="M395 409 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
          <path
            d="M1600 403 c0 -13 29 -38 35 -31 3 3 -4 13 -15 23 -11 10 -20 13 -20
8z"
          />

          <path d="M427 385 c-10 -7 -14 -16 -9 -19 5 -3 15 1 22 9 17 21 9 27 -13 10z" />
          <path
            d="M1278 378 c-14 -11 -14 -17 -2 -45 15 -34 29 -40 54 -22 12 8 9 9
-12 5 -35 -8 -39 16 -5 32 21 11 20 11 -5 6 -34 -7 -38 13 -5 26 13 5 16 9 6
9 -8 1 -22 -5 -31 -11z"
          />
          <path d="M1580 360 c6 -11 15 -18 21 -14 6 4 6 11 -2 20 -17 21 -31 17 -19 -6z" />
          <path d="M460 355 c-7 -9 -10 -18 -7 -21 3 -3 10 4 17 15 13 25 8 27 -10 6z" />
          <path
            d="M1245 338 l7 -33 -24 23 c-40 37 -49 24 -23 -33 l14 -30 -6 35 -6 35
27 -24 c32 -30 38 -25 25 22 -12 46 -23 49 -14 5z"
          />
          <path
            d="M810 346 c-15 -19 -9 -58 10 -63 12 -4 12 -3 2 5 -22 15 -11 56 16
60 27 4 40 -23 23 -49 -6 -11 -7 -19 -1 -19 16 0 23 49 10 65 -16 19 -44 19
-60 1z"
          />
          <path
            d="M1350 340 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"
          />
          <path d="M1550 329 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z" />
          <path d="M485 329 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
          <path
            d="M890 311 c0 -26 3 -28 28 -22 25 5 25 6 5 9 -13 2 -23 10 -23 19 0
11 7 14 28 9 20 -5 23 -4 12 4 -29 19 -50 12 -50 -19z"
          />
          <path
            d="M1177 310 l6 -35 -25 30 c-32 37 -44 28 -29 -22 l10 -38 -5 38 -5 39
30 -34 30 -33 1 30 c0 17 -5 37 -10 45 -7 11 -8 5 -3 -20z"
          />
          <path d="M1008 323 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
          <path
            d="M1051 293 c-1 -41 0 -43 29 -43 17 0 30 4 30 9 0 4 -11 6 -25 3 -18
-3 -25 0 -25 11 0 9 11 17 28 20 l27 4 -27 2 c-16 0 -28 8 -31 19 -3 9 -5 -2
-6 -25z"
          />
          <path d="M1510 325 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z" />
          <path d="M3716 322 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />
          <path
            d="M520 305 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3 3
-11 -1 -18 -10z"
          />
          <path
            d="M1480 304 c0 -5 7 -19 15 -30 8 -10 15 -14 15 -8 0 5 -7 19 -15 30
-8 10 -15 14 -15 8z"
          />
          <path d="M550 281 c-6 -12 -7 -21 -1 -21 10 0 24 30 17 37 -2 3 -9 -5 -16 -16z" />
          <path d="M1002 275 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M592 268 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15 -8z" />
          <path d="M1450 259 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z" />
          <path d="M622 248 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15 -8z" />
          <path d="M1420 239 c7 -11 14 -18 17 -15 8 8 -5 36 -17 36 -7 0 -7 -6 0 -21z" />
          <path
            d="M656 224 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3 -7
-3 -11 -12z"
          />
          <path
            d="M1380 233 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23 -5
3 -10 3 -10 -2z"
          />
          <path d="M1350 213 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
          <path d="M690 201 c-6 -12 -7 -21 -1 -21 10 0 24 30 17 37 -2 3 -9 -5 -16 -16z" />
          <path
            d="M726 194 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3 -7
-3 -11 -12z"
          />
          <path
            d="M1310 196 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"
          />
          <path d="M767 187 c-11 -29 -9 -43 4 -30 6 6 9 19 7 29 -3 18 -4 18 -11 1z" />
          <path d="M1270 183 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
          <path
            d="M1230 178 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
-5 3 -10 0 -10 -7z"
          />
          <path d="M800 158 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10 -22z" />
          <path d="M847 174 c-4 -4 -7 -17 -7 -28 1 -19 1 -20 11 -3 11 19 8 43 -4 31z" />
          <path d="M1200 163 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
          <path d="M880 148 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10 -22z" />
          <path d="M918 150 c-7 -24 -3 -38 8 -28 3 4 4 17 2 30 l-3 23 -7 -25z" />
          <path d="M1120 148 c0 -16 5 -28 10 -28 13 0 13 20 0 40 -8 12 -10 9 -10 -12z" />
          <path
            d="M1160 150 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"
          />
          <path d="M962 140 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path d="M1002 135 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M1052 135 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M1080 143 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z" />
        </g>
      </svg>
    </Box>
  );
};
export default TennesseStamp;
