/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeetingReviewDTO
 */
export interface MeetingReviewDTO {
    /**
     * 
     * @type {number}
     * @memberof MeetingReviewDTO
     */
    activeSessionId: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingReviewDTO
     */
    typeOfDocumnet: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingReviewDTO
     */
    notaryNote: string;
}

/**
 * Check if a given object implements the MeetingReviewDTO interface.
 */
export function instanceOfMeetingReviewDTO(value: object): value is MeetingReviewDTO {
    if (!('activeSessionId' in value) || value['activeSessionId'] === undefined) return false;
    if (!('typeOfDocumnet' in value) || value['typeOfDocumnet'] === undefined) return false;
    if (!('notaryNote' in value) || value['notaryNote'] === undefined) return false;
    return true;
}

export function MeetingReviewDTOFromJSON(json: any): MeetingReviewDTO {
    return MeetingReviewDTOFromJSONTyped(json, false);
}

export function MeetingReviewDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingReviewDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'activeSessionId': json['activeSessionId'],
        'typeOfDocumnet': json['typeOfDocumnet'],
        'notaryNote': json['notaryNote'],
    };
}

export function MeetingReviewDTOToJSON(value?: MeetingReviewDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeSessionId': value['activeSessionId'],
        'typeOfDocumnet': value['typeOfDocumnet'],
        'notaryNote': value['notaryNote'],
    };
}

