/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveAuditLogDTO
 */
export interface RemoveAuditLogDTO {
    /**
     * 
     * @type {string}
     * @memberof RemoveAuditLogDTO
     */
    typeOfForm: string;
    /**
     * 
     * @type {number}
     * @memberof RemoveAuditLogDTO
     */
    sessionId: number;
}

/**
 * Check if a given object implements the RemoveAuditLogDTO interface.
 */
export function instanceOfRemoveAuditLogDTO(value: object): value is RemoveAuditLogDTO {
    if (!('typeOfForm' in value) || value['typeOfForm'] === undefined) return false;
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    return true;
}

export function RemoveAuditLogDTOFromJSON(json: any): RemoveAuditLogDTO {
    return RemoveAuditLogDTOFromJSONTyped(json, false);
}

export function RemoveAuditLogDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveAuditLogDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'typeOfForm': json['typeOfForm'],
        'sessionId': json['sessionId'],
    };
}

export function RemoveAuditLogDTOToJSON(value?: RemoveAuditLogDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'typeOfForm': value['typeOfForm'],
        'sessionId': value['sessionId'],
    };
}

