import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { IoIosArrowForward as ArrowIcon } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CardDTO, CustomerApi, TransactionApi } from "../../../../api";
import { useAuth } from "../../../../utils/use-auth";
import { espTransform, getMonth } from "../../../../utils/utils";
import Card from "../../../Card";
import PaymentCard from "../../PaymentCard/PaymentCard";
import AccountCard from "./components/AccountCard";
import PlanCard from "./components/PlanCard";
import {
  businessPaths,
  notaryPaths,
  signerPaths,
} from "../../../../routes/routes";
const DashboardCards = ({
  from,
  totalInvoicesAmount,
}: {
  from: string;
  totalInvoicesAmount: number;
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const { configuration } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [cardDetails, setCardDetails] = useState<CardDTO[]>([]);
  const [defaultCardId, setDefaultCardId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");

  const getCard = () => {
    setLoading(true);
    new CustomerApi(configuration)
      .userControllerGetCutomerByUserId()
      .then((res) => {
        setCustomerId(res.customerId);
        new TransactionApi(configuration)
          .transactionControllerGetPaymentMethod({
            customerId: res.customerId,
          })
          .then((result) => {
            setCardDetails(result.cards);
            setDefaultCardId(result?.defaultPaymentMethodId);
            if (
              result?.defaultPaymentMethodId === null &&
              result.cards?.length !== 0
            ) {
              new TransactionApi(configuration)
                .transactionControllerSetdefault({
                  setDTO: {
                    paymentMethodId: result.cards?.[0]
                      ?.paymentMethodId as string,
                    customerId: res.customerId,
                  },
                })
                .then((res) => {
                  if (res?.status === 200) {
                    setDefaultCardId(
                      result.cards?.[0]?.paymentMethodId as string
                    );
                  }
                });
            }
            setLoading(false);
          })
          .catch((err) => {
            setCardDetails([]);
            setDefaultCardId("");
            setLoading(false);
          });
      });
  };

  useEffect(() => {
    if (token !== null) {
      getCard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleSaveCard = () => {
    getCard();
    onClose();
  };

  return (
    <>
      <Flex
        alignItems={"flex-start"}
        mt={8}
        gap={4}
        height={{ base: "auto", md: "auto", lg: "130px" }}
        direction={{ base: "column", md: "column", lg: "row" }}
      >
        {from === "notary" && <AccountCard />}

        {from === "business" && <PlanCard />}

        <Card title={"My Payment Card"}>
          {loading ? (
            <Text ml={2} fontWeight={500} mt={3}>
              Loading...
            </Text>
          ) : cardDetails?.length === 0 ? (
            <Button variant="link" p={0} color="#000" mt={4} onClick={onOpen}>
              <GrAddCircle size={22} />
              <Text ml={2} fontWeight={500}>
                Add Card
              </Text>
            </Button>
          ) : (
            cardDetails
              ?.filter((el) => el?.paymentMethodId === defaultCardId)
              ?.map((card, idx) => (
                <Box
                  key={idx}
                  mt={3}
                  position="relative"
                  role="button"
                  onClick={() => navigate("/payment")}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Flex>
                      <Box>
                        <Text fontWeight={600}>Card Number</Text>
                        <Text fontSize={14}>{card?.cardNumber}</Text>
                      </Box>
                      <Box ml={12}>
                        <Text fontWeight={600}>Expiry Date</Text>
                        <Text fontSize={14}>
                          {getMonth(card?.cardExpMonth)} {card?.cardExpYear}
                        </Text>
                      </Box>
                    </Flex>
                    <ArrowIcon size={22} />
                  </Flex>

                  <Box position={"absolute"} right={0} style={{ top: "-37px" }}>
                    <Tooltip label="This will be used when charging for subscriptions or invoices. You can always override this default.">
                      <Text fontStyle="italic" color={"green"}>
                        (Default)
                      </Text>
                    </Tooltip>
                  </Box>
                </Box>
              ))
          )}
        </Card>

        <Card title={"Total Invoice Amount"}>
          <Flex
            role={"button"}
            onClick={() => {
              if (from === "notary") {
                navigate(`${notaryPaths.notaryInvoices}`);
              } else if (from === "signer") {
                navigate(`${signerPaths.signerInvoices}`);
              } else {
                navigate(`${businessPaths.businessInvoices}`);
              }
            }}
            alignItems={"center"}
            justifyContent="space-between"
            mt={5}
          >
            <Flex alignItems={"center"}>
              <Box borderRadius={"full"} background="#D5AD36" p={2}>
                <FaFileInvoiceDollar size={22} />
              </Box>

              <Text ml={2} fontWeight={500}>
                {espTransform(totalInvoicesAmount)}
              </Text>
            </Flex>
            <ArrowIcon size={22} />
          </Flex>
        </Card>
      </Flex>

      <PaymentCard
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        customerId={customerId}
        onSave={handleSaveCard}
        isFirst={cardDetails.length === 0 ? true : false}
      />
    </>
  );
};

export default DashboardCards;
