/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConferenceAccessTokenResponseDTO
 */
export interface ConferenceAccessTokenResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ConferenceAccessTokenResponseDTO
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceAccessTokenResponseDTO
     */
    conferenceId: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceAccessTokenResponseDTO
     */
    externalId: string;
}

/**
 * Check if a given object implements the ConferenceAccessTokenResponseDTO interface.
 */
export function instanceOfConferenceAccessTokenResponseDTO(value: object): value is ConferenceAccessTokenResponseDTO {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('conferenceId' in value) || value['conferenceId'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    return true;
}

export function ConferenceAccessTokenResponseDTOFromJSON(json: any): ConferenceAccessTokenResponseDTO {
    return ConferenceAccessTokenResponseDTOFromJSONTyped(json, false);
}

export function ConferenceAccessTokenResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConferenceAccessTokenResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'conferenceId': json['conferenceId'],
        'externalId': json['externalId'],
    };
}

export function ConferenceAccessTokenResponseDTOToJSON(value?: ConferenceAccessTokenResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'conferenceId': value['conferenceId'],
        'externalId': value['externalId'],
    };
}

