import {
  Stack,
  Heading,
  Flex,
  Button,
  Box,
  Text,
  Center,
  Spinner,
  RadioGroup,
  Radio,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
// import { DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TermsModal from "./Modals/TermsModal";
import { getMonth } from "../../utils/utils";
import { useAuth } from "../../utils/use-auth";
import { NotaryApi, UsersApi } from "../../api";
import { BASE_URL } from "../../utils/constants";
import { getNotaryDetails } from "../../utils/utils-apis";
import useNotification from "../../utils/useNotification";
import { useProfileDetailsStore, useTermsAndConditionStore } from "../../store/notaryStore";
import { notaryPaths } from "../../routes/routes";

const PaymentInfoDetails = () => {
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [accountDetails, setAccountDetails] = useState();
  const [error, setError] = useState<boolean>(false);
  const [errorAccount, setErrorAccount] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [disablePayoutBtn, setDisablePayoutBtn] = useState<boolean>(false);
  const [payoutStatus, setPayoutStatus] = useState<boolean>(true);
  const [isQuiz, setIsQuiz] = useState<boolean>(true);
const isAgreed = useTermsAndConditionStore((state: any) => state.isAgreed);
const setIsAgreed = useTermsAndConditionStore((state: any) => state.setIsAgreed);

  const handleAdd = () => {
    new NotaryApi(configuration)
      .notaryControllerStripePyout()
      .then((res) => {
        if (res?.url) {
          window.location.href = res?.url;
        }
      })
      .catch((err) => {
        notification("fail", err);
      });
  };

  useEffect(() => {
    getNotaryDetails(configuration).then((res) => {
      setNotaryProfile({
        ...notaryProfile,
        quizAttempts: res?.quizAttempts,
        quizResult: res?.notaryQuizResult,
      });
    });
    const fetchDetails = async () => {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/notary/get-stripe-account`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const result = await response.json();
      if (result?.error === "No Bank Account Added for this Account") {
        setErrorAccount(false);
        setAccountDetails(undefined);
        setLoading(false);
      } else if (
        result?.error === "Connected Account is not created for this Account" ||
        result?.statusCode === 500
      ) {
        setErrorAccount(true);
        setAccountDetails(undefined);
        setLoading(false);
      } else {
        setAccountDetails(result?.data);
        setNotaryProfile({ ...notaryProfile, paymentDetails: true });
        setLoading(false);
      }
      setLoading(false);
    };
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountDetails !== undefined) {
      const pollingRslt = async () => {
        try {
          const res = await new UsersApi(
            configuration
          ).usersControllerAccount();
          return res?.payoutsEnabled;
        } catch (err) {
          console.log(err);
        }
      };

      const interval = setInterval(async () => {
        const rslt = await pollingRslt();
        if (rslt) {
          setPayoutStatus(rslt);
          setNotaryProfile({ ...notaryProfile, paymentDetails: rslt });
          clearInterval(interval);
        } else {
          setPayoutStatus(false);
          setNotaryProfile({ ...notaryProfile, paymentDetails: false });
        }
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (errorAccount) {
      navigate("/");
    }
    if (notaryProfile.paymentDetails === false) {
      setError(true);
    } else {
      setError(false);
      navigate("/");
    }
  };

  const handleSave = () => {
    setDisablePayoutBtn(true);
    new NotaryApi(configuration)
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          payoutFrequency: notaryProfile?.payoutFrequency,
        },
      })
      .then((res) => {
        new NotaryApi(configuration)
          .notaryControllerPayoutScheduling({
            payout: {
              paymentFrequency: notaryProfile?.payoutFrequency,
              userId: Number(localStorage?.getItem("UserID")),
            },
          })
          .then((res) => {
            notification("success", "Changed Successfully!");
            setDisablePayoutBtn(false);
          })
          .catch((error) => {
            console.log(error);
            setDisablePayoutBtn(false);
            notification("fail", "Failed to change!");
          });
      })
      .catch((error) => {
        console.log(error);
        setDisablePayoutBtn(false);
      });
  };

  const handleRemoveAcc = (id: string) => {
    new NotaryApi(configuration)
      .notaryControllerDeleteBankAccount({
        deleteAccountDTO: { bankAccountId: id },
      })
      .then((res) => {
        notification("success", "Removed Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStripe = () => {
    new NotaryApi(configuration)
      .notaryControllerLoginLink()
      .then((res) => {
        if (res?.loginLink !== undefined) {
          window.open(res?.loginLink, "_blank");
        } else {
          notification(
            "fail",
            "Cannot create an edit link for your account, which is not an Express account."
          );
        }
      })
      .catch(async (error) => {
        notification("fail", error);
      });
  };

  const dataToShow = (title: string, data: string) => {
    return (
      <>
        <Text mt={5} fontWeight={600}>
          {title}
        </Text>
        <Text fontSize={14}>{data}</Text>
      </>
    );
  };

  return (
    <Stack px={6}>
      <Heading
        fontSize={"20px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Payment Details
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Notarize Genie partners with Stripe to securely pay notaries for
        On-Demand sessions. Payments or fees from a notary’s Bring Your Own
        Transactions, (or BYOTs), are deducted from the notary’s credit/debit
        card.
      </Text>

      <Box pt={3}>
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : errorAccount ? (
          <Text color={"red"} pt={2} fontSize="14px">
            *Stripe Connected Account is not created for this Account
          </Text>
        ) : accountDetails === undefined ? (
          <Box>
            <Text fontWeight={"600"}>Add your business information</Text>
            <Button
              type="button"
              width={"115px"}
              style={{
                background:
                  "linear-gradient(rgb(213, 173, 54) 0%, rgba(213, 173, 54, 0.5) 100%)",
                color: "#000",
                marginTop: "25px",
                padding: "22px",
              }}
              onClick={handleAdd}
            >
              + Add
            </Button>
          </Box>
        ) : (
          <Box>
            <Text fontWeight={"600"}>Your business information</Text>
            <Flex marginTop={4}>
              <div
                style={{
                  maxWidth: "450px",
                  width: "100%",
                  padding: "0px 20px 20px 20px",
                  boxShadow: "-1px 5px 15px 0px #ddd",
                }}
              >
                <Flex justifyContent={"space-between"}>
                  {/* @ts-ignore */}
                  {accountDetails?.object === "bank_account" ? (
                    <Flex>
                      <Box>
                        {dataToShow(
                          "Bank Name",
                          //  @ts-ignore
                          `${accountDetails?.bank_name}`
                        )}

                        {dataToShow(
                          "Account Number",
                          //  @ts-ignore
                          `************${accountDetails?.last4}`
                        )}
                        {dataToShow(
                          "Country",
                          //  @ts-ignore
                          `${accountDetails?.country ?? "Not Given"}`
                        )}
                      </Box>
                      <Box ml={12}>
                        {dataToShow(
                          "Account Holder Name",
                          `${
                            //  @ts-ignore
                            accountDetails?.account_holder_name ?? "Not Given"
                          }`
                        )}

                        {dataToShow(
                          "Account Holder Type",
                          `${
                            //  @ts-ignore
                            accountDetails?.account_holder_type ?? "Not Given"
                          }`
                        )}
                        {dataToShow(
                          "Currency",
                          `${
                            //  @ts-ignore
                            accountDetails?.currency?.toUpperCase() ??
                            "Not Given"
                          }`
                        )}
                      </Box>
                    </Flex>
                  ) : (
                    <Flex>
                      <Box>
                        {dataToShow(
                          "Card Number",
                          //  @ts-ignore
                          `************${accountDetails?.last4}`
                        )}

                        {dataToShow(
                          "Expiry Date",
                          //  @ts-ignore
                          `${getMonth(accountDetails?.exp_month)} ${
                            //  @ts-ignore
                            accountDetails?.exp_year
                          }`
                        )}
                      </Box>
                      <Box ml={12}>
                        {dataToShow(
                          "Brand",
                          //  @ts-ignore
                          `${accountDetails?.brand}`
                        )}
                        {dataToShow(
                          "Country",
                          //  @ts-ignore
                          `${accountDetails?.country ?? "Not Given"}`
                        )}
                      </Box>
                    </Flex>
                  )}

                  {/* <div role="button" onClick={() => handleRemoveAcc(acc?.id)}>
                    <DeleteIcon w={6} h={6} color="red.500" />
                  </div> */}
                </Flex>
              </div>
            </Flex>

            <Divider mt={7} />

            <Box mt={5}>
              <Text pt={2} fontSize="14px">
                Browse your balance and recent payouts in Stripe Connect account
                dashboard. You can also update your personal or company
                settings.
              </Text>

              {!payoutStatus && (
                <Text mt={2} fontSize="12px" color={"red"} fontStyle="italic">
                  (Your stripe connected account is not completed yet. Please go
                  to your stripe dashboard and complete your profile.)
                </Text>
              )}

              <Button
                mt={3}
                fontSize={"16px"}
                fontWeight={"normal"}
                color={"#D5AD36"}
                height={"40px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }}
                border={"1px solid #D5AD36"}
                backgroundColor={"white"}
                onClick={handleStripe}
              >
                Stripe Dashboard
              </Button>
            </Box>

            <Divider mt={7} />

            <Box mt={5}>
              <Text pt={2} fontSize="14px">
                Stripe Connect account payout frequency: Weekly payout arrives
                every Friday while monthly payout at the end of the month.
              </Text>

              <RadioGroup
                value={notaryProfile?.payoutFrequency}
                onChange={(value) => {
                  setNotaryProfile({
                    ...notaryProfile,
                    payoutFrequency: value,
                  });
                }}
              >
                <Stack direction="row" style={{ marginTop: "10px" }}>
                  <Radio value="daily">Daily</Radio>
                  <Radio value="weekly" pl={4}>
                    Weekly
                  </Radio>
                  <Radio value="monthly" pl={4}>
                    Monthly
                  </Radio>
                </Stack>
              </RadioGroup>

              <Button
                mt={3}
                fontSize={"16px"}
                fontWeight={"normal"}
                color={"#D5AD36"}
                height={"40px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }}
                border="1px solid #D5AD36"
                backgroundColor={"white"}
                onClick={handleSave}
                isDisabled={disablePayoutBtn}
              >
                Save Changes
              </Button>
            </Box>
            <Divider mt={7} />
          </Box>
        )}
      </Box>

      {error && (
        <Text color={"red"} pt={2} fontSize="14px">
          *Please add your business information
        </Text>
      )}

      {(notaryProfile?.quizAttempts === 2 ||
        Number(notaryProfile?.quizResult) > 84.99) && (
        <Box mt={5}>
          <Text pt={2} fontSize="14px">
            Click on button to check your quiz result.
          </Text>

          <Button
            mt={3}
            fontSize={"16px"}
            fontWeight={"normal"}
            color={"#D5AD36"}
            height={"40px"}
            _hover={{
              background:
                "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
              color: "#fff",
            }}
            border="1px solid #D5AD36"
            backgroundColor={"white"}
            onClick={() => {
              setIsQuiz(true);
              onOpen();
            }}
          >
            Notary Quiz Result
          </Button>

          <Divider mt={7} />
        </Box>
      )}

      <Flex justifyContent={"space-between"} pt={"16px"}>
        <Button
          style={{
            background: "#2D3748",
            color: "#fff",
            padding: "10px 30px",
          }}
          onClick={() =>
            navigate(`${notaryPaths.profileSetting}/signature-seal`)
          }
        >
          PREV
        </Button>

        {
        // notaryProfile?.quizAttempts === 2 ||
        // Number(notaryProfile?.quizResult) >= 9?
        isAgreed===true?
         (
          <Button
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            type="submit"
            isDisabled={
              loading || accountDetails === undefined || !payoutStatus
            }
            onClick={(e) => handleSubmit(e)}
          >
            FINISH
          </Button>
        ) : (
          <Button
            style={{
              background: "#2D3748",
              color: "#fff",
              padding: "10px 30px",
            }}
            onClick={onOpen}
          >
            NEXT
          </Button>
        )}
      </Flex>
      <TermsModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isQuiz={isQuiz}
        setIsAgreed={setIsAgreed}
      />
    </Stack>
  );
};

export default PaymentInfoDetails;
