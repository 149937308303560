import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import {
  INotaryProfileDetailsStore,
  IProfileStepDisableStore,
  IProfileStepStore,
  NotaryProfileDetails,
} from "./zustandType";

const initialNotaryProfileData = {
  suffix: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  address1: "",
  address2: "",
  country: "",
  city: "",
  zip: "",
  state: "",
  commissionNumber: "",
  commissionExpirationDate: "",
  copyOfNotaryCommission: "",
  nnaIdNumber: "",
  notarySigningAgentExamExpirationDate: "",
  backgroundCheckExpirationDate: "",
  eoInsuranceProvider: "",
  policyNumber: "",
  insuranceAmount: "",
  policyExpiryDate: "",
  copyOfEoPolicy: "",
  digitalCertificate: "",
  signature: "",
  initials: "",
  seal: "",
  notaryId: "",
  notaryStatus: "",
  paymentDetails: false,
  digitalCertificatePasscode: "",
  payoutFrequency: "daily",
  quizAnswers: {},
  quizResult: "",
  quizAttempts: 0,
  county: "",
  digitalStartDate: "",
  digitalEndDate: "",
  arizonaNotaryStatus: "pending",
  isInhouseNotary: false,
  onDemandStatus: false,
  onDemandWitnessStatus: false,
  wantSmsNotification: false,
  titleLenderStatus: false,
  businessStatus: false,
};

export const useProfileDetailsStore = create<INotaryProfileDetailsStore>()(
  devtools(
    OpenReplay.createLogger("notaryProfile")(
      // @ts-ignore
      (set) => ({
        notaryProfile: initialNotaryProfileData,
        setNotaryProfile: (newDetails: Object) =>
          set((state: { notaryProfile: NotaryProfileDetails }) => ({
            notaryProfile: { ...state.notaryProfile, ...newDetails },
          })),
      }),
      {
        name: "notary-profile-store",
      }
    )
  )
);

export const useNotaryProfileStepDisableStore =
  create<IProfileStepDisableStore>((set) => ({
    isDisableSteps: true,
    setIsDisableStep: (value: boolean) =>
      set((state: { isDisableSteps: boolean }) => ({
        isDisableSteps: value,
      })),
  }));

export const useNotaryProfileStepStore = create<IProfileStepStore>((set) => ({
  step: 0,
  setStep: (newDetails: number) =>
    set((state: { step: number }) => ({
      step: newDetails,
    })),
}));

export const useNotarySessionStepStore = create<IProfileStepStore>((set) => ({
  step: 0,
  setStep: (newDetails: number) =>
    set((state: { step: number }) => ({
      step: newDetails,
    })),
}));

export const useTermsAndConditionStore = create(persist(
  (set) => ({
    isAgreed: false,
    setIsAgreed: (value: boolean) => set({ isAgreed: value }),
  }),
  {
    name: 'terms-and-condition-store',
  }
))
