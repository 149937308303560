import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/use-auth";
import { Session, SessionApi } from "../../../api";
import { Heading, Stack, Text } from "@chakra-ui/react";
import NoDataFound from "../../commonComponents/LoadingComponents/NoDataFound";
import NotarySessionTable from "../../commonComponents/SessionTable/NotarySessionTable";

const ActiveNotarySession = () => {
  const { configuration } = useAuth();
  const [notarySessions, setNotarySessions] = useState<Session[]>([]);

  const startSession = (sessionId: number) => {
    console.log("sessionId", sessionId);
  };

  useEffect(() => {
    new SessionApi(configuration)
      .sessionControllerGetNotarySessions()
      .then((res) => {
        setNotarySessions(res);
      })
      .catch((err) => {
        setNotarySessions([]);
      });
  }, []);

  return (
    <Stack spacing={6} mx={"auto"} w={"100%"}>
      <Heading
        fontSize={"22px"}
        lineHeight="30px"
        textAlign={"center"}
        mb={4}
        fontFamily="Oswald"
        fontWeight={600}
        color={"#2D3748"}
      >
        My Sessions
      </Heading>
      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        New sessions will appear in each queue below. To start the session,
        simply click on the join button as it appears in the designated queue.
        Sessions are first come first serve, the longest waiting session will
        start once clicked in the queue.
      </Text>

      {notarySessions.length === 0 ? (
        <NoDataFound text="No Session Found" from="session" />
      ) : (
        <NotarySessionTable
          sessions={notarySessions}
          startSession={startSession}
          from="notary"
          isActive={true}
        />
      )}
    </Stack>
  );
};

export default ActiveNotarySession;
