import { useEffect } from "react";
import Payment from "../../../../component/Sessions/Notary/Payment";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";

const PaymentPage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(5);
  }, [setStep]);

  return <Payment />;
};

export default PaymentPage;
