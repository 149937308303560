import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadDoc from "../commonComponents/UploadComponents/UploadDoc";
import { useProfileDetailsStore } from "../../store/notaryStore";
import moment from "moment";
import { today } from "../../utils/utils";
import { useAuth } from "../../utils/use-auth";
import { NotaryApi } from "../../api";
import { notaryPaths } from "../../routes/routes";

const InsuranceDetailsForm = () => {
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [file, setFile] = useState<string>(notaryProfile.copyOfEoPolicy ?? "");
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      ...notaryProfile,
    },
    validationSchema: Yup.object({
      eoInsuranceProvider: Yup.string()
        .max(50, "E&0 Insurance Provider must not exceed 50 characters")
        .required("E&0 Insurance Provider is required"),
      policyNumber: Yup.string().required("Policy Number is required"),
      insuranceAmount: Yup.number().required("Insurance Amount is required"),
      policyExpiryDate: Yup.date()
        .typeError("Policy Expiry Date is required")
        .required("Policy Expiry Date is required"),
      copyOfEoPolicy: Yup.string().required("E&O policy file is required"),
    }),

    onSubmit: (values) => {
      setNotaryProfile(values);
      new NotaryApi(configuration)
        .notaryControllerPartialUpdateNotary({
          id: Number(notaryProfile?.notaryId),
          updateNotaryDTO: {
            eoInsuranceProvider: values.eoInsuranceProvider,
            policyNumber: values.policyNumber,
            insuranceAmount: values.insuranceAmount,
            policyExpiryDate: new Date(values.policyExpiryDate).toUTCString(),
            copyOfEoPolicy: values.copyOfEoPolicy,
          },
        })
        .then((res) => {
          navigate(`${notaryPaths.profileSetting}/digital-certificate`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enableReinitialize: true,
  });

  return (
    <Stack px={6}>
      <Heading
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        lineHeight="30px"
        textAlign={"center"}
        mb={6}
        fontFamily="Oswald"
        fontWeight={600}
      >
        Insurance Details
      </Heading>

      <Text
        fontSize={"16px"}
        fontFamily={"Lato"}
        fontWeight={"400"}
        mb={"40px"}
      >
        Please enter and upload your E&O insurance policy details below.
        Notarize Genie requires all notaries to maintain an E&O policy; however,
        in order to be approved for platform requests via the On-Demand queue
        notaries must maintain an E&O policy of $100,000.00 or greater.
      </Text>

      <form onSubmit={formik.handleSubmit}>
        <Flex flexWrap={"wrap"} mt={3}>
          <FormControl id={`E&OInsurance Provider`} w={"100%"} mb={"15px"}>
            <FormLabel p={1}>E&O Insurance Provider*</FormLabel>
            <Input
              name="eoInsuranceProvider"
              value={formik.values.eoInsuranceProvider}
              type="text"
              placeholder={`E&O Insurance Provider`}
              {...(formik.touched.eoInsuranceProvider &&
              formik.errors.eoInsuranceProvider
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.eoInsuranceProvider &&
              formik.errors.eoInsuranceProvider
                ? formik.errors.eoInsuranceProvider
                : ""}
            </Text>
          </FormControl>

          <FormControl id="policyNumber" w={"100%"} mb={"15px"}>
            <FormLabel p={1}>Policy Number*</FormLabel>
            <Input
              name="policyNumber"
              value={formik.values.policyNumber}
              type="text"
              placeholder="Policy Number"
              {...(formik.touched.policyNumber && formik.errors.policyNumber
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.policyNumber && formik.errors.policyNumber
                ? formik.errors.policyNumber
                : ""}
            </Text>
          </FormControl>

          <FormControl id="InsuranceAmount" w={"100%"} mb={"15px"}>
            <FormLabel p="1">Insurance Amount*</FormLabel>
            <Input
              name="insuranceAmount"
              value={formik.values.insuranceAmount}
              // value={Number(
              //   formik.values.insuranceAmount?.replace(/[^0-9.-]+/g, "")
              // )}
              type="number"
              placeholder="Insurance Amount"
              {...(formik.touched.insuranceAmount &&
              formik.errors.insuranceAmount
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.insuranceAmount && formik.errors.insuranceAmount
                ? formik.errors.insuranceAmount
                : ""}
            </Text>
          </FormControl>

          <FormControl id="PolicyExpirationDate" mb={"15px"}>
            <FormLabel p="1">Policy Expiration Date*</FormLabel>
            <Input
              name="policyExpiryDate"
              // value={formik.values.policyExpiryDate}
              value={moment(formik.values.policyExpiryDate).format(
                "YYYY-MM-DD"
              )}
              maxW={"400px"}
              minW={"230px"}
              type="date"
              color={"grey"}
              w={"30%"}
              min={today}
              {...(formik.touched.policyExpiryDate &&
              formik.errors.policyExpiryDate
                ? { isInvalid: true }
                : "")}
              errorBorderColor="red.300"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.policyExpiryDate && formik.errors.policyExpiryDate
                ? formik.errors.policyExpiryDate
                : ""}
            </Text>
          </FormControl>

          <FormControl p="1">
            <FormLabel {...(isFileUploaded ? { color: "#000080" } : {})}>
              Upload a copy of E&O policy*
            </FormLabel>
            {notaryProfile.copyOfEoPolicy ? (
              <Text mb={3} fontSize={"13px"}>
                ( {file.split("-").slice(-1)})
              </Text>
            ) : (
              <Text mb={3} fontSize={"13px"}>
                Accept file format only jpeg, png, jpg and pdf
              </Text>
            )}
            <UploadDoc
              acceptFileType={[".jpeg", ".jpg", ".png", ".pdf"]}
              filePath={file}
              onUpload={(file) => {
                setFile(file);
                formik.setFieldValue("copyOfEoPolicy", file);
                setIsFileUploaded(true);
              }}
            />
            <Text
              color={"red"}
              fontSize={"14px"}
              fontWeight={"500"}
              marginLeft={"6px"}
            >
              {formik.touched.copyOfEoPolicy && formik.errors.copyOfEoPolicy
                ? formik.errors.copyOfEoPolicy
                : ""}
            </Text>
            {/* <div>
              <div>Doc</div>
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {
                  <img
                    src={files[0]?.preview}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "contain",
                    }}
                    onLoad={() => {
                      URL.revokeObjectURL(files[0]?.preview);
                    }}
                    alt="preview"
                  />
                }
              </div>
              <HStack>
                <div>{fileName}</div>{" "}
                <Button
                  fontSize={"16px"}
                  p={"10px 30px"}
                  onClick={() => {
                    setNotaryProfile({
                      ...notaryProfile,
                      copyOfEoPolicy: "",
                    });
                    setIsFileUploaded(false);
                  }}
                >
                  Remove
                </Button>
              </HStack>
            </div> */}
          </FormControl>
        </Flex>
        <Box ml="auto" p="2">
          <Flex justifyContent={"space-between"}>
            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              onClick={() =>
                navigate(`${notaryPaths.profileSetting}/commission-details`)
              }
            >
              PREV
            </Button>

            <Button
              style={{
                background: "#2D3748",
                color: "#fff",
                padding: "10px 30px",
                marginTop: "30px",
              }}
              type="submit"
            >
              SAVE & NEXT
            </Button>
          </Flex>
        </Box>
      </form>
    </Stack>
  );
};

export default InsuranceDetailsForm;
