/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ADDCardResponse,
  CardDTO,
  CardDetails,
  CheckoutRequestDTO,
  CheckoutResponseDTO,
  InvoicesResponseDTO,
  ManuallInvoice,
  RequestedSubscriptionDto,
  SetDTO,
  SetDefaultResponseDTO,
  Transaction,
} from '../models/index';
import {
    ADDCardResponseFromJSON,
    ADDCardResponseToJSON,
    CardDTOFromJSON,
    CardDTOToJSON,
    CardDetailsFromJSON,
    CardDetailsToJSON,
    CheckoutRequestDTOFromJSON,
    CheckoutRequestDTOToJSON,
    CheckoutResponseDTOFromJSON,
    CheckoutResponseDTOToJSON,
    InvoicesResponseDTOFromJSON,
    InvoicesResponseDTOToJSON,
    ManuallInvoiceFromJSON,
    ManuallInvoiceToJSON,
    RequestedSubscriptionDtoFromJSON,
    RequestedSubscriptionDtoToJSON,
    SetDTOFromJSON,
    SetDTOToJSON,
    SetDefaultResponseDTOFromJSON,
    SetDefaultResponseDTOToJSON,
    TransactionFromJSON,
    TransactionToJSON,
} from '../models/index';

export interface TransactionControllerAddCardRequest {
    cardDTO: CardDTO;
}

export interface TransactionControllerCreateRequest {
    manuallInvoice: ManuallInvoice;
}

export interface TransactionControllerCreateCheckoutRequest {
    checkoutRequestDTO: CheckoutRequestDTO;
}

export interface TransactionControllerCreateReqSubscriptionRequest {
    requestedSubscriptionDto: RequestedSubscriptionDto;
}

export interface TransactionControllerDeleteExpressAccountRequest {
    accountId: string;
}

export interface TransactionControllerFindOneRequest {
    id: string;
}

export interface TransactionControllerGetPaymentMethodRequest {
    customerId: string;
}

export interface TransactionControllerRemoveCardRequest {
    paymentMethodId: string;
}

export interface TransactionControllerSetdefaultRequest {
    setDTO: SetDTO;
}

export interface TransactionControllerSubscriptionUpdateRequest {
    email: string;
    requestedSubscriptionDto: RequestedSubscriptionDto;
}

/**
 * 
 */
export class TransactionApi extends runtime.BaseAPI {

    /**
     */
    async transactionControllerAddCardRaw(requestParameters: TransactionControllerAddCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ADDCardResponse>> {
        if (requestParameters['cardDTO'] == null) {
            throw new runtime.RequiredError(
                'cardDTO',
                'Required parameter "cardDTO" was null or undefined when calling transactionControllerAddCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/addCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardDTOToJSON(requestParameters['cardDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ADDCardResponseFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerAddCard(requestParameters: TransactionControllerAddCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ADDCardResponse> {
        const response = await this.transactionControllerAddCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerCancelRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDefaultResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/cancelFreeSubscription/OnStripe`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerCancel(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDefaultResponseDTO> {
        const response = await this.transactionControllerCancelRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerCreateRaw(requestParameters: TransactionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['manuallInvoice'] == null) {
            throw new runtime.RequiredError(
                'manuallInvoice',
                'Required parameter "manuallInvoice" was null or undefined when calling transactionControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/manuall/Invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManuallInvoiceToJSON(requestParameters['manuallInvoice']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async transactionControllerCreate(requestParameters: TransactionControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transactionControllerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async transactionControllerCreateCheckoutRaw(requestParameters: TransactionControllerCreateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutResponseDTO>> {
        if (requestParameters['checkoutRequestDTO'] == null) {
            throw new runtime.RequiredError(
                'checkoutRequestDTO',
                'Required parameter "checkoutRequestDTO" was null or undefined when calling transactionControllerCreateCheckout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/checkout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckoutRequestDTOToJSON(requestParameters['checkoutRequestDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerCreateCheckout(requestParameters: TransactionControllerCreateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutResponseDTO> {
        const response = await this.transactionControllerCreateCheckoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerCreateReqSubscriptionRaw(requestParameters: TransactionControllerCreateReqSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDefaultResponseDTO>> {
        if (requestParameters['requestedSubscriptionDto'] == null) {
            throw new runtime.RequiredError(
                'requestedSubscriptionDto',
                'Required parameter "requestedSubscriptionDto" was null or undefined when calling transactionControllerCreateReqSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/create/RequestSubscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestedSubscriptionDtoToJSON(requestParameters['requestedSubscriptionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerCreateReqSubscription(requestParameters: TransactionControllerCreateReqSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDefaultResponseDTO> {
        const response = await this.transactionControllerCreateReqSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerDeleteExpressAccountRaw(requestParameters: TransactionControllerDeleteExpressAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['accountId'] == null) {
            throw new runtime.RequiredError(
                'accountId',
                'Required parameter "accountId" was null or undefined when calling transactionControllerDeleteExpressAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/expressAccount/{accountId}`.replace(`{${"accountId"}}`, encodeURIComponent(String(requestParameters['accountId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async transactionControllerDeleteExpressAccount(requestParameters: TransactionControllerDeleteExpressAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transactionControllerDeleteExpressAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async transactionControllerFindOneRaw(requestParameters: TransactionControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Transaction>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling transactionControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerFindOne(requestParameters: TransactionControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Transaction> {
        const response = await this.transactionControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerGetInvoicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerGetInvoices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesResponseDTO> {
        const response = await this.transactionControllerGetInvoicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerGetPaymentMethodRaw(requestParameters: TransactionControllerGetPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardDetails>> {
        if (requestParameters['customerId'] == null) {
            throw new runtime.RequiredError(
                'customerId',
                'Required parameter "customerId" was null or undefined when calling transactionControllerGetPaymentMethod().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/getCards/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters['customerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardDetailsFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerGetPaymentMethod(requestParameters: TransactionControllerGetPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardDetails> {
        const response = await this.transactionControllerGetPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerGetStripeSubscriptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDefaultResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/stripe/Subscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerGetStripeSubscription(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDefaultResponseDTO> {
        const response = await this.transactionControllerGetStripeSubscriptionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerRemoveCardRaw(requestParameters: TransactionControllerRemoveCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['paymentMethodId'] == null) {
            throw new runtime.RequiredError(
                'paymentMethodId',
                'Required parameter "paymentMethodId" was null or undefined when calling transactionControllerRemoveCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/removeCard/{paymentMethodId}`.replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(requestParameters['paymentMethodId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async transactionControllerRemoveCard(requestParameters: TransactionControllerRemoveCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transactionControllerRemoveCardRaw(requestParameters, initOverrides);
    }

    /**
     */
    async transactionControllerSetdefaultRaw(requestParameters: TransactionControllerSetdefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDefaultResponseDTO>> {
        if (requestParameters['setDTO'] == null) {
            throw new runtime.RequiredError(
                'setDTO',
                'Required parameter "setDTO" was null or undefined when calling transactionControllerSetdefault().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/set-defaultCard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDTOToJSON(requestParameters['setDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerSetdefault(requestParameters: TransactionControllerSetdefaultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDefaultResponseDTO> {
        const response = await this.transactionControllerSetdefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerSubscriptionUpdateRaw(requestParameters: TransactionControllerSubscriptionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetDefaultResponseDTO>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling transactionControllerSubscriptionUpdate().'
            );
        }

        if (requestParameters['requestedSubscriptionDto'] == null) {
            throw new runtime.RequiredError(
                'requestedSubscriptionDto',
                'Required parameter "requestedSubscriptionDto" was null or undefined when calling transactionControllerSubscriptionUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transaction/ManuallyUpdate/OnCoupenExpired/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters['email']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestedSubscriptionDtoToJSON(requestParameters['requestedSubscriptionDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetDefaultResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async transactionControllerSubscriptionUpdate(requestParameters: TransactionControllerSubscriptionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetDefaultResponseDTO> {
        const response = await this.transactionControllerSubscriptionUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async transactionControllerWebhookSubscriptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transaction/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async transactionControllerWebhookSubscription(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transactionControllerWebhookSubscriptionRaw(initOverrides);
    }

}
