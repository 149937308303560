import { Step, Stepper } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../store/store";

const Steppers = ({
  from,
  stepData,
  step,
  onSetStep,
  isDisable
}: {
  isDisable?:boolean
  from: string;
  step: number;
  onSetStep: (step: number) => void;
  stepData: { label: string; path: string }[];
}) => {
  const navigate = useNavigate();
  const { isMobileView } = useResponsive();

  return (
    <Stepper
      activeStep={step}
      // @ts-ignore
      styleConfig={{
        activeBgColor: "#000080",
        completedBgColor: "#D5AD36",
        // completedBgColor: "#000080",
      }}
      style={{padding:"0px 1px 20px"}}
    >
      {stepData?.flatMap((step, idx) => (
        <Step
          disabled={isDisable}
          label={isMobileView ? "" : step?.label}
          onClick={() => {
            onSetStep(idx);
            if (from !== "terms") {
              navigate(`${from}/${step.path}`);
            }
          }}
        />
      ))}
    </Stepper>
  );
};

export default Steppers;
