/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignerDTO
 */
export interface SignerDTO {
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignerDTO
     */
    notifyViaText?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    lastFourDigitsSsnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof SignerDTO
     */
    zip?: number;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof SignerDTO
     */
    businessId?: number;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    kbaStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    firstScanImage?: string;
    /**
     * 
     * @type {string}
     * @memberof SignerDTO
     */
    firstBackScanImage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignerDTO
     */
    wantSmsNotification?: boolean;
}

/**
 * Check if a given object implements the SignerDTO interface.
 */
export function instanceOfSignerDTO(value: object): value is SignerDTO {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function SignerDTOFromJSON(json: any): SignerDTO {
    return SignerDTOFromJSONTyped(json, false);
}

export function SignerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignerDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'notifyViaText': json['notifyViaText'] == null ? undefined : json['notifyViaText'],
        'lastFourDigitsSsnNumber': json['lastFourDigitsSsnNumber'] == null ? undefined : json['lastFourDigitsSsnNumber'],
        'addressLine1': json['addressLine1'] == null ? undefined : json['addressLine1'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
        'dob': json['dob'] == null ? undefined : json['dob'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'country': json['country'] == null ? undefined : json['country'],
        'businessId': json['businessId'] == null ? undefined : json['businessId'],
        'kbaStatus': json['kbaStatus'] == null ? undefined : json['kbaStatus'],
        'firstScanImage': json['firstScanImage'] == null ? undefined : json['firstScanImage'],
        'firstBackScanImage': json['firstBackScanImage'] == null ? undefined : json['firstBackScanImage'],
        'wantSmsNotification': json['wantSmsNotification'] == null ? undefined : json['wantSmsNotification'],
    };
}

export function SignerDTOToJSON(value?: SignerDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'notifyViaText': value['notifyViaText'],
        'lastFourDigitsSsnNumber': value['lastFourDigitsSsnNumber'],
        'addressLine1': value['addressLine1'],
        'addressLine2': value['addressLine2'],
        'dob': value['dob'],
        'city': value['city'],
        'state': value['state'],
        'zip': value['zip'],
        'country': value['country'],
        'businessId': value['businessId'],
        'kbaStatus': value['kbaStatus'],
        'firstScanImage': value['firstScanImage'],
        'firstBackScanImage': value['firstBackScanImage'],
        'wantSmsNotification': value['wantSmsNotification'],
    };
}

