/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNotaryDTO
 */
export interface UpdateNotaryDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    commissionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    commissionExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    copyOfNotaryCommission?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    nnaIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    notarySigningAgentExamExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    backgroundCheckExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    eoInsuranceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    insuranceAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    policyExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    copyOfEoPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    digitalCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    initials?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    seal?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    payoutFrequency?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotaryDTO
     */
    quizAttempts?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotaryDTO
     */
    zip?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotaryDTO
     */
    paymentDetails?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    digitalCertificatePasscode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    digitalStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotaryDTO
     */
    digitalEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotaryDTO
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotaryDTO
     */
    wantSmsNotification?: boolean;
}

/**
 * Check if a given object implements the UpdateNotaryDTO interface.
 */
export function instanceOfUpdateNotaryDTO(value: object): value is UpdateNotaryDTO {
    return true;
}

export function UpdateNotaryDTOFromJSON(json: any): UpdateNotaryDTO {
    return UpdateNotaryDTOFromJSONTyped(json, false);
}

export function UpdateNotaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotaryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'commissionNumber': json['commissionNumber'] == null ? undefined : json['commissionNumber'],
        'commissionExpirationDate': json['commissionExpirationDate'] == null ? undefined : json['commissionExpirationDate'],
        'copyOfNotaryCommission': json['copyOfNotaryCommission'] == null ? undefined : json['copyOfNotaryCommission'],
        'nnaIdNumber': json['nnaIdNumber'] == null ? undefined : json['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': json['notarySigningAgentExamExpirationDate'] == null ? undefined : json['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': json['backgroundCheckExpirationDate'] == null ? undefined : json['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': json['eoInsuranceProvider'] == null ? undefined : json['eoInsuranceProvider'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'policyExpiryDate': json['policyExpiryDate'] == null ? undefined : json['policyExpiryDate'],
        'copyOfEoPolicy': json['copyOfEoPolicy'] == null ? undefined : json['copyOfEoPolicy'],
        'digitalCertificate': json['digitalCertificate'] == null ? undefined : json['digitalCertificate'],
        'signature': json['signature'] == null ? undefined : json['signature'],
        'initials': json['initials'] == null ? undefined : json['initials'],
        'seal': json['seal'] == null ? undefined : json['seal'],
        'payoutFrequency': json['payoutFrequency'] == null ? undefined : json['payoutFrequency'],
        'quizAttempts': json['quizAttempts'] == null ? undefined : json['quizAttempts'],
        'suffix': json['suffix'] == null ? undefined : json['suffix'],
        'policyNumber': json['policyNumber'] == null ? undefined : json['policyNumber'],
        'country': json['country'] == null ? undefined : json['country'],
        'zip': json['zip'] == null ? undefined : json['zip'],
        'state': json['state'] == null ? undefined : json['state'],
        'city': json['city'] == null ? undefined : json['city'],
        'paymentDetails': json['paymentDetails'] == null ? undefined : json['paymentDetails'],
        'digitalCertificatePasscode': json['digitalCertificatePasscode'] == null ? undefined : json['digitalCertificatePasscode'],
        'county': json['county'] == null ? undefined : json['county'],
        'digitalStartDate': json['digitalStartDate'] == null ? undefined : json['digitalStartDate'],
        'digitalEndDate': json['digitalEndDate'] == null ? undefined : json['digitalEndDate'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'wantSmsNotification': json['wantSmsNotification'] == null ? undefined : json['wantSmsNotification'],
    };
}

export function UpdateNotaryDTOToJSON(value?: UpdateNotaryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'middleName': value['middleName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'address1': value['address1'],
        'address2': value['address2'],
        'commissionNumber': value['commissionNumber'],
        'commissionExpirationDate': value['commissionExpirationDate'],
        'copyOfNotaryCommission': value['copyOfNotaryCommission'],
        'nnaIdNumber': value['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': value['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': value['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': value['eoInsuranceProvider'],
        'insuranceAmount': value['insuranceAmount'],
        'policyExpiryDate': value['policyExpiryDate'],
        'copyOfEoPolicy': value['copyOfEoPolicy'],
        'digitalCertificate': value['digitalCertificate'],
        'signature': value['signature'],
        'initials': value['initials'],
        'seal': value['seal'],
        'payoutFrequency': value['payoutFrequency'],
        'quizAttempts': value['quizAttempts'],
        'suffix': value['suffix'],
        'policyNumber': value['policyNumber'],
        'country': value['country'],
        'zip': value['zip'],
        'state': value['state'],
        'city': value['city'],
        'paymentDetails': value['paymentDetails'],
        'digitalCertificatePasscode': value['digitalCertificatePasscode'],
        'county': value['county'],
        'digitalStartDate': value['digitalStartDate'],
        'digitalEndDate': value['digitalEndDate'],
        'isInhouseNotary': value['isInhouseNotary'],
        'wantSmsNotification': value['wantSmsNotification'],
    };
}

