/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Notary } from './Notary';
import {
    NotaryFromJSON,
    NotaryFromJSONTyped,
    NotaryToJSON,
} from './Notary';
import type { OmitTypeClass } from './OmitTypeClass';
import {
    OmitTypeClassFromJSON,
    OmitTypeClassFromJSONTyped,
    OmitTypeClassToJSON,
} from './OmitTypeClass';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    businessId: number;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    businessName: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    businessWebsite: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    businessCity: string;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    businessZip: number;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    businessPhone: number;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    address2?: string;
    /**
     * 
     * @type {OmitTypeClass}
     * @memberof Business
     */
    user: OmitTypeClass;
    /**
     * 
     * @type {Array<Notary>}
     * @memberof Business
     */
    inhouseNotaries?: Array<Notary>;
    /**
     * 
     * @type {Subscription}
     * @memberof Business
     */
    activeSubscription?: Subscription | null;
}

/**
 * Check if a given object implements the Business interface.
 */
export function instanceOfBusiness(value: object): value is Business {
    if (!('businessId' in value) || value['businessId'] === undefined) return false;
    if (!('businessName' in value) || value['businessName'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('businessWebsite' in value) || value['businessWebsite'] === undefined) return false;
    if (!('businessCity' in value) || value['businessCity'] === undefined) return false;
    if (!('businessZip' in value) || value['businessZip'] === undefined) return false;
    if (!('businessPhone' in value) || value['businessPhone'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    return true;
}

export function BusinessFromJSON(json: any): Business {
    return BusinessFromJSONTyped(json, false);
}

export function BusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Business {
    if (json == null) {
        return json;
    }
    return {
        
        'businessId': json['businessId'],
        'businessName': json['businessName'],
        'position': json['position'],
        'state': json['state'],
        'email': json['email'],
        'businessWebsite': json['businessWebsite'],
        'businessCity': json['businessCity'],
        'businessZip': json['businessZip'],
        'businessPhone': json['businessPhone'],
        'country': json['country'] == null ? undefined : json['country'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'user': OmitTypeClassFromJSON(json['user']),
        'inhouseNotaries': json['inhouseNotaries'] == null ? undefined : ((json['inhouseNotaries'] as Array<any>).map(NotaryFromJSON)),
        'activeSubscription': json['activeSubscription'] == null ? undefined : SubscriptionFromJSON(json['activeSubscription']),
    };
}

export function BusinessToJSON(value?: Business | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'businessId': value['businessId'],
        'businessName': value['businessName'],
        'position': value['position'],
        'state': value['state'],
        'email': value['email'],
        'businessWebsite': value['businessWebsite'],
        'businessCity': value['businessCity'],
        'businessZip': value['businessZip'],
        'businessPhone': value['businessPhone'],
        'country': value['country'],
        'address1': value['address1'],
        'address2': value['address2'],
        'user': OmitTypeClassToJSON(value['user']),
        'inhouseNotaries': value['inhouseNotaries'] == null ? undefined : ((value['inhouseNotaries'] as Array<any>).map(NotaryToJSON)),
        'activeSubscription': SubscriptionToJSON(value['activeSubscription']),
    };
}

