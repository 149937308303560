import { Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { KbaLogsApi, SessionApi } from "../../../api";
import useCreateUser from "../../../hooks/useCreateUser";
import { notaryPaths } from "../../../routes/routes";
import { initialSessionDetails, useSessionCreate } from "../../../store/store";
import { useAuth } from "../../../utils/use-auth";
import useNotification from "../../../utils/useNotification";
import { createSession, getSessionDetails } from "../../../utils/utils-apis";

const KbaQR = ({
  from,
  paramsSessionId,
}: {
  from?: string;
  paramsSessionId?: number;
}) => {
  const navigate = useNavigate();
  const sendSynchronously = useCreateUser();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [loading, setLoading] = useState<boolean>(false);

  const registerSigner = async (kbaLogId: string) => {
    try {
      const users = await sendSynchronously();

      const res = await createSession(
        sessionDetail,
        users,
        configuration,
        "signer",
        false,
        "approved"
      );
      setSessionDetails(initialSessionDetails);
      navigate(
        `/signer/on-demand/waiting-for-notary/?session-id=${res.sessionId}`
      );
    } catch (err) {
      console.log(err);
      notification("fail", "Failed to create Session");
      setLoading(false);
    }
  };

  useEffect(() => {
    async function init() {
      try {
        setLoading(true);
        if (from === "witness") {
          navigate(
            `${notaryPaths.notaryOndemand}/witness-session/?session-id=${paramsSessionId}`
          );
        } else {
          const res = await new KbaLogsApi(configuration).kBAControllerGet();
          if (from === "page") {
            await registerSigner(res?.kbaLogId?.toString());
            setLoading(false);
          } else if (from === "signer") {
            try {
              const session = await getSessionDetails(
                configuration,
                Number(paramsSessionId)
              );
              const kbaLogs = session?.kbaLogs;
              const logData = kbaLogs?.find(
                (el) =>
                  Number(el.userId) === Number(localStorage.getItem("UserID"))
              );
              if (logData === undefined) {
                kbaLogs?.push({
                  userId: Number(localStorage.getItem("UserID")),
                  kbaLogId: res?.kbaLogId?.toString(),
                });
              }
              new SessionApi(configuration)
                .sessionControllerUpdate({
                  sessionId: Number(paramsSessionId),
                  sessionUpdateDTO: {
                    kbaLogs: kbaLogs,
                  },
                })
                .then((res) => {
                  setLoading(false);
                  navigate(
                    `/signer/on-demand/waiting-for-notary/?session-id=${paramsSessionId}`
                  );
                })
                .catch((err) => {
                  console.log(err);
                  notification("fail", "session api failed");
                  setLoading(false);
                });
            } catch (err) {
              console.log(err);
              setLoading(false);
            }
          }
        }
      } catch (error) {
        console.log("ERROR :", "error while getting verification", error);
        setLoading(false);
      }
    }
    init();
  }, []);

  return (
    <>
      <Text fontSize={"20px"}>Verify Your ID</Text>
      {loading ? (
        <Center>
          <Flex direction={"column"} alignItems={"center"} mt={10}>
            <Text>
              Please wait, verifying your ID. It will take couple of minutes
            </Text>
            <Spinner size={"xl"} />
          </Flex>
        </Center>
      ) : null}
    </>
  );
};

export default KbaQR;
