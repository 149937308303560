import {
  Box,
  Divider,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import NavbarData from "./NavbarData";
import { useProSidebar } from "react-pro-sidebar";
import { GiHamburgerMenu as MenuIcon } from "react-icons/gi";
import FeedbackForm from "../commonComponents/FeedbackForm";
import { IoNotifications as NotificationIcon } from "react-icons/io5";
import Notifications from "../commonComponents/Notifications/Notifications";
import { HiChatBubbleBottomCenterText as FeedbackIcon } from "react-icons/hi2";

const Navbar = ({ isShowMenu = true }: { isShowMenu?: boolean }) => {
  const firstFieldRef = useRef(null);
  const notificationRef = useRef(null);
  const { toggleSidebar } = useProSidebar();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onNoticOpen,
    onClose: onNoticClose,
    isOpen: isNoticOpen,
  } = useDisclosure();

  return (
    <>
      <Flex
        w={"100%"}
        height="fit-content"
        alignItems={"center"}
        py={"6px"}
        background={"#000080"}
      >
        {isShowMenu && (
          <Box display={{ lg: "none", md: "none", base: "block" }} m={"16px"}>
            <MenuIcon
              style={{ fill: "#fff" }}
              onClick={() => {
                toggleSidebar();
              }}
            />
          </Box>
        )}

        <Flex
          justifyContent="flex-end"
          // w={{ lg: "30%", md: "40%", sm: "50%" }}
          w={"100%"}
          ml={"auto"}
          color="white"
          fontFamily="Lato"
          fontWeight="900"
          fontSize={"16px"}
        >
          <Tooltip label="Feedback">
            <Box pt={2}>
              <Popover
                offset={[-80, 18]}
                closeOnBlur
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={() => {
                  onOpen();
                  onNoticClose();
                }}
                onClose={onClose}
              >
                <PopoverTrigger>
                  <Link>
                    <FeedbackIcon size={20} style={{ paddingTop: "0.5px" }} />
                  </Link>
                </PopoverTrigger>
                <PopoverContent color="#000" px={3} py={2}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Send Feedback!</PopoverHeader>
                  <PopoverBody pt={5}>
                    <FeedbackForm ref={firstFieldRef} onClose={onClose} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Tooltip>

          <Tooltip label="Notifications">
            <Box pt={2.5} ml={5}>
              <Popover
                offset={[-80, 18]}
                closeOnBlur
                isOpen={isNoticOpen}
                initialFocusRef={notificationRef}
                onOpen={() => {
                  onNoticOpen();
                  onClose();
                }}
                onClose={onNoticClose}
              >
                <PopoverTrigger>
                  <Link>
                    <NotificationIcon size={20} />
                  </Link>
                </PopoverTrigger>
                <PopoverContent color="#000" px={3} py={2}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Notifications</PopoverHeader>
                  <PopoverBody pt={5}>
                    <Notifications onClose={onNoticClose} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Tooltip>

          <Stack ml={5}>
            <Divider orientation={"vertical"} borderColor="#fff" />
          </Stack>

          <Box mx={6}>
            <NavbarData />
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Navbar;
