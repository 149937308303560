import {
  Box,
  FormControl,
  FormLabel,
  Stack,
  Button,
  Heading,
  Flex,
  Text,
  useDisclosure,
  Image,
  useColorModeValue,
  Input,
  Progress,
} from "@chakra-ui/react";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { FormEvent, useCallback, useState } from "react";
import { useAuth } from "../../utils/use-auth";
import PasscodeModal from "./Modals/PasscodeModal";
import { IFileInfo } from "../../store/zustandType";
import useNotification from "../../utils/useNotification";
import UploadIcon1 from "../../assets/icons/uploadIcon1.png";
import { CertificateResponseDTO, NotaryApi } from "../../api";
import { useProfileDetailsStore } from "../../store/notaryStore";
import { HiOutlineDocumentText as DocIcon } from "react-icons/hi2";
import { notaryPaths } from "../../routes/routes";

const DigitalCertificateDetails = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<IFileInfo>();
  const [fileName, setFileName] = useState<string | undefined>("");
  const [fileuploading, setFileUploading] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [docVerfiyData, setDocVerifyData] = useState<CertificateResponseDTO>();
  const [fileUrl, setFileUrl] = useState<string>(
    notaryProfile.digitalCertificate ?? ""
  );

  const updateNotaryProfile = () => {
    new NotaryApi(configuration)
      .notaryControllerPartialUpdateNotary({
        id: Number(notaryProfile?.notaryId),
        updateNotaryDTO: {
          digitalCertificate: notaryProfile.digitalCertificate,
          digitalCertificatePasscode: notaryProfile.digitalCertificatePasscode,
          digitalEndDate: notaryProfile?.digitalEndDate,
          digitalStartDate: notaryProfile?.digitalStartDate,
        },
      })
      .then((res) => {
        navigate(`${notaryPaths.profileSetting}/signature-seal`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      notaryProfile.digitalCertificate === undefined ||
      notaryProfile.digitalCertificate === ""
    ) {
      setIsFileUploaded(true);
    } else {
      updateNotaryProfile();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: IFileInfo[]) => {
      setFileUploading(true);
      acceptedFiles.forEach((file: IFileInfo) => {
        if (file?.name?.endsWith(".pfx") || file?.name?.endsWith(".p12")) {
          setFileName(file.name);
          setFile(file);
          onOpen();
        } else {
          setIsFileUploaded(false);
          setFileUploading(false);
          notification("fail", "Supported file types are (pfx, p12)");
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <>
      <Stack px={6}>
        <Heading
          fontSize={{ base: "18px", md: "22px", lg: "22px" }}
          lineHeight="30px"
          textAlign={"center"}
          mb={6}
          fontFamily="Oswald"
          fontWeight={600}
        >
          Digital Certificate
        </Heading>
        <Box>
          <Text fontSize={"16px"} fontWeight={700}>
            Please ensure the name on your digital certificate matches your name
            exactly as it appears on your commission.
          </Text>

          <Text mt={3}>
            If you don't have one yet,{" "}
            <span style={{ color: "#D5AD36", fontWeight: "700" }}>
              <a
                href="https://www.identrust.com/partners/notarizegenie#:~:text=Notarize%20Genie%20is%20a%20national,documents%20via%20audio%2Dvideo%20communication"
                target={"_blank"}
                rel="noreferrer"
              >
                here
              </a>{" "}
            </span>
            are instructions on how to get a digital certificate. Notarize Genie
            is only compatible with IdenTrust digital certificates, so you must
            purchase from IdenTrust. After you purchase it, come back and
            continue with your profile.
          </Text>
        </Box>

        <form onSubmit={(e) => handleSubmit(e)}>
          <FormControl mt={3}>
            <FormLabel {...(isFileUploaded ? { color: "#000080" } : {})}>
              Upload IdenTrust signing Certificate*{" "}
            </FormLabel>

            <Text mb={2} fontSize={"13px"}>
              (Accept file format only pfx or p12)
            </Text>

            <Flex
              align="center"
              justify="center"
              bg={useColorModeValue("none", "navy.900")}
              border={useColorModeValue("1px dashed #000", "none")}
              borderRadius={8}
              w="60%"
              minH="130px"
              cursor="pointer"
              minW={"270px"}
              wordBreak={"break-word"}
              overflowWrap="break-word"
              {...getRootProps({ className: "dropzone" })}
            >
              {/* @ts-ignore */}
              <Input type="image" variant="main" {...getInputProps()} />
              <Button
                variant="no-effects"
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                width={40}
              >
                {fileuploading ? (
                  <Box width={"100%"}>
                    <Text mb={2}>Uploading....</Text>
                    <Progress size="xs" isIndeterminate />
                  </Box>
                ) : fileUrl !== "" ? (
                  <>
                    <DocIcon size={22} />
                    {fileName ? (
                      <Text
                        color="gray.400"
                        fontWeight="normal"
                        fontSize={13}
                        mt={2}
                      >
                        {fileName}
                      </Text>
                    ) : (
                      <Text
                        color="green.400"
                        fontWeight="normal"
                        fontSize={13}
                        mt={2}
                      >
                        Uploaded Successfully!
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <Image src={UploadIcon1} height={10} />
                    <Text color="gray.400" fontWeight="normal">
                      Upload File
                    </Text>
                  </>
                )}
              </Button>
            </Flex>
          </FormControl>

          {/* {docVerfiyData !== undefined && (
            <Flex alignItems={"center"} mt={3}>
              <Text color="#000080">
                Valid From:{" "}
                <span style={{ color: "#000" }}>
                  {moment(docVerfiyData?.validFrom).format("M/D/YYYY")}
                </span>
              </Text>
              <Text color="#000080" ml={10}>
                Valid To:{" "}
                <span style={{ color: "#000" }}>
                  {moment(docVerfiyData?.validTo).format("M/D/YYYY")}
                </span>
              </Text>
            </Flex>
          )} */}

          {notaryProfile.digitalStartDate !== "" &&
            notaryProfile.digitalStartDate !== undefined && (
              <Flex alignItems={"center"} mt={3}>
                <Text color="#000080">
                  Valid From:{" "}
                  <span style={{ color: "#000" }}>
                    {moment(
                      notaryProfile.digitalStartDate !== ""
                        ? notaryProfile.digitalStartDate
                        : docVerfiyData?.validFrom
                    ).format("M/D/YYYY")}
                  </span>
                </Text>
                <Text color="#000080" ml={10}>
                  Valid To:{" "}
                  <span style={{ color: "#000" }}>
                    {moment(
                      notaryProfile.digitalEndDate !== ""
                        ? notaryProfile.digitalEndDate
                        : docVerfiyData?.validTo
                    ).format("M/D/YYYY")}
                  </span>
                </Text>
              </Flex>
            )}

          <Box ml="auto" p="2">
            <Flex justifyContent={"space-between"}>
              <Button
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "10px 30px",
                  marginTop: "30px",
                }}
                onClick={() =>
                  navigate(`${notaryPaths.profileSetting}/insurance-details`)
                }
              >
                PREV
              </Button>

              <Button
                style={{
                  background: "#2D3748",
                  color: "#fff",
                  padding: "10px 30px",
                  marginTop: "30px",
                }}
                type="submit"
              >
                SAVE & NEXT
              </Button>
            </Flex>
          </Box>
        </form>
      </Stack>

      <PasscodeModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        file={file as IFileInfo}
        onUpload={(res, passcode) => {
          setFileUrl(res.path);
          setDocVerifyData(res);
          setNotaryProfile({
            ...notaryProfile,
            digitalCertificate: res.path,
            digitalCertificatePasscode: passcode,
            digitalEndDate: res?.validTo,
            digitalStartDate: res?.validFrom,
          });

          setFileUploading(false);
          setIsFileUploaded(true);
        }}
        onHandleClose={() => {
          setFileUploading(false);
        }}
      />
    </>
  );
};

export default DigitalCertificateDetails;
