/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Message,
} from '../models/index';
import {
    MessageFromJSON,
    MessageToJSON,
} from '../models/index';

export interface MessageControllerPostMessageRequest {
    message: Message;
}

/**
 * 
 */
export class MessagesApi extends runtime.BaseAPI {

    /**
     */
    async messageControllerGetMessageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Message>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/message/get-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageFromJSON));
    }

    /**
     */
    async messageControllerGetMessage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Message>> {
        const response = await this.messageControllerGetMessageRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async messageControllerPostMessageRaw(requestParameters: MessageControllerPostMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters['message'] == null) {
            throw new runtime.RequiredError(
                'message',
                'Required parameter "message" was null or undefined when calling messageControllerPostMessage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/message/post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageToJSON(requestParameters['message']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     */
    async messageControllerPostMessage(requestParameters: MessageControllerPostMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.messageControllerPostMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
