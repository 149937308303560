import {
  Box,
  Divider,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Message } from "../../../api";

const NotificationModal = ({
  isOpen,
  onOpen,
  onClose,
  selectedData,
}: {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  selectedData?: Message;
}) => {
  return (
    <Modal size={"3xl"} closeOnEsc isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Notification Detail
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody py={8}>
          <Box height={"70vh"} overflow="auto">
            <Text fontWeight={600}>{selectedData?.title}</Text>
            <Text my={3}>{selectedData?.message}</Text>
            <Link href={selectedData?.link} target={"_blank"}>
              Link -{" "}
              <span style={{ color: "#000080", textDecoration: "underline" }}>
                {selectedData?.link}
              </span>
            </Link>
            <Box my={3}>
              <Text mb={3} fontWeight={600}>
                Image
              </Text>
              <Image src={selectedData?.imageLink} />
            </Box>

            {/* <iframe
              width="690"
              height="400"
              src={`https://www.youtube.com/embed/${selectedData?.videoLink}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            /> */}
            <Box my={3}>
              <Text mb={3} fontWeight={600}>
                Video
              </Text>
              <video width="690" height="400" controls>
                {/* <source src="/media/cc0-videos/flower.webm" type="video/webm" /> */}
                <source src={selectedData?.videoLink} type="video/mp4" />
              </video>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationModal;
