import { useTracks } from "@livekit/components-react";
import { Track } from "livekit-client";
import LocalPaticipants from "./Tiles/LocalParticipant";
import NotaryParticipant from "./Tiles/NotaryParticipant";
import PrimarySignerParticipants from "./Tiles/PrimarySignerParticipant";
import RemoteParticipants from "./Tiles/RemoteParticipant";
import {
  localParticipantTracks,
  notaryTracks,
  otherParticipantsTracks,
  primarySignerTracks,
} from "./Tiles/utils";

function ConfereneceParticipants() {
  const tracks = useTracks([Track.Source.Camera]);
  const notaryVideoTracks = notaryTracks(tracks);
  const primarySignerVideoTracks = primarySignerTracks(tracks);
  const localVideoTracks = localParticipantTracks(tracks);
  const remoteVideoTracks = otherParticipantsTracks(tracks);

  return (
    <div>
      {notaryVideoTracks.length > 0 && (
        <>
          <NotaryParticipant tracks={notaryVideoTracks} />
        </>
      )}
      {localVideoTracks.length > 0 && (
        <>
          <LocalPaticipants tracks={localVideoTracks} />
        </>
      )}
      {primarySignerVideoTracks.length > 0 && (
        <>
          <PrimarySignerParticipants tracks={primarySignerVideoTracks} />
        </>
      )}
      {remoteVideoTracks.length > 0 &&
        remoteVideoTracks.map((track) => (
          // we will only send one remote participant at a time, to this component
          <RemoteParticipants
            key={track.participant.identity}
            tracks={[track]}
          />
        ))}
    </div>
  );
}

export default ConfereneceParticipants;
