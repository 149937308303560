import { PDFDocument } from "pdf-lib";
import { _base64ToArrayBuffer } from "./addForm";

export const removeForm = async (
  pdf: string,
  index?: number
): Promise<string> => {
  let pdfDoc: PDFDocument;
  if (pdf.startsWith("https")) {
    const pdfArrayBuffer = await fetch(pdf).then((r) => r.arrayBuffer());
    pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  } else {
    const pdfArrayBuffer = _base64ToArrayBuffer(pdf);
    pdfDoc = await PDFDocument.load(pdfArrayBuffer);
  }
  if (index && index < pdfDoc.getPageCount()) pdfDoc.removePage(index);
  return await pdfDoc.saveAsBase64();
};
