import {
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import WaitingModal from "./WaitingModal";
import { ActiveSessionApi } from "../../../api";
import { useAuth } from "../../../utils/use-auth";
import BaseLayout from "../../../layout/BaseLayout";
import useNotification from "../../../utils/useNotification";
import { getSessionDetails } from "../../../utils/utils-apis";

const WaitingPageData = ({ from }: { from: string }) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [searchParams] = useSearchParams();
  const { notification } = useNotification();
  const sessionId = searchParams.get("session-id");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let intervalId = null;
    getSessionDetails(configuration, Number(sessionId)).then((res) => {
      if (
        res.status === "terminated" ||
        res.status === "expired" ||
        res.status === "completed"
      ) {
        notification("fail", "Meeting has been ended");
        navigate("/");
      } else {
        const pollingFxn = async (sessionId: string) => {
          return new ActiveSessionApi(configuration)
            .activeSessionControllerFindOne({
              sessionId: sessionId,
            })
            .then((res) => {
              if (from === "business") {
                navigate(
                  `/business/mediator-session?session-id=${res.session.sessionId}`
                );
              } else if (from === "witness") {
                navigate(
                  `/notary/on-demand/witness-session?session-id=${res.session.sessionId}`
                );
              } else {
                navigate(
                  `/signer/on-demand/meeting?session-id=${res.session.sessionId}`
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        };

        intervalId = setInterval(() => {
          if (sessionId) {
            pollingFxn(sessionId);
          }
        }, 3000);
      }
    });
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout showBack={false}>
      <Text fontSize={"30px"} fontWeight={700} fontFamily={"Oswald"}>
        Wait until notary start the session
      </Text>
      <Text fontFamily={"Lato"} fontSize={"16px"} fontWeight={"400"} mt={2}>
        Please keep this screen open and your volume turned up. A notary will
        join you shortly.
      </Text>
      <Center my={20}>
        <Spinner
          speed="0.85s"
          borderWidth={"12px"}
          color="#000080"
          size="xl"
          width={"150px"}
          height={"150px"}
          fontWeight={900}
        />
      </Center>
      <Flex justifyContent={"end"}>
        <Button
          m={6}
          color={"white"}
          backgroundColor={"#D5AD36"}
          padding={"20px"}
          onClick={() => navigate("/")}
        >
          Exit
        </Button>
      </Flex>

      <WaitingModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </BaseLayout>
  );
};

export default WaitingPageData;
