import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Document } from "../api";
import { OpenReplay } from "../utils/OpenReplay";

interface IDocStore {
  sessionDocs: Document[];
  setSessionDocs: (docs: Document[]) => void;
  addSessionDoc: (newDoc: Document) => void;
  removeSessionDoc: (docIndex: number) => void;
}

interface IUseCurrentDocStore {
  currentDocUrl: string;
  setCurrentDocUrl: (newUrl: string) => void;
  removeCurrentDocUrl: () => void;
}

export const useSessionDocStore = create<IDocStore>()(
  devtools(
    OpenReplay.createLogger("session-documents-store")(
      // @ts-ignore
      (set) => ({
        sessionDocs: [],
        setSessionDocs: (sessionDocs: Document[]) =>
          set((state: any) => ({
            ...state,
            sessionDocs,
          })),
        addSessionDoc: (newDoc: Document) =>
          set((state: any) => ({
            ...state,
            sessionDocs: [...state.sessionDocs, newDoc],
          })),
        removeSessionDoc: (docId: number) =>
          set((state: any) => ({
            ...state,
            // sessionDocs: [
            //   ...state.sessionDocs.slice(0, docIndex),
            //   ...state.sessionDocs.slice(docIndex + 1),
            // ],
            sessionDocs: state.sessionDocs.filter((el:Document)=>el?.docId !== docId),
          })),
      }),
      {
        name: "session-documents-store",
      }
    )
  )
);

export const useCurrentDocStore = create<IUseCurrentDocStore>()(
  devtools(
    (set) => ({
      currentDocUrl: "",
      setCurrentDocUrl: (newUrl) => {
        set({ currentDocUrl: newUrl });
      },
      removeCurrentDocUrl:()=>{
        set({ currentDocUrl: "" });
      }
    }),
    {
      name: "session-currentDocUrl-store",
    }
  )
);
