/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotaryDTO
 */
export interface NotaryDTO {
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    middleName: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    commissionNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    commissionExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    copyOfNotaryCommission?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    nnaIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    notarySigningAgentExamExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    backgroundCheckExpirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    eoInsuranceProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    insuranceAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    policyExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    copyOfEoPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    digitalCertificate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    initials?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    seal?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    policyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    country?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryDTO
     */
    paymentDetails?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotaryDTO
     */
    zip: number;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    digitalCertificatePasscode?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof NotaryDTO
     */
    user: number;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    payoutFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    digitalStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NotaryDTO
     */
    digitalEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryDTO
     */
    isInhouseNotary?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotaryDTO
     */
    quizAttempts?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryDTO
     */
    wantSmsNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryDTO
     */
    titleLenderStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryDTO
     */
    businessStatus?: boolean;
}

/**
 * Check if a given object implements the NotaryDTO interface.
 */
export function instanceOfNotaryDTO(value: object): value is NotaryDTO {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('middleName' in value) || value['middleName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('zip' in value) || value['zip'] === undefined) return false;
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('payoutFrequency' in value) || value['payoutFrequency'] === undefined) return false;
    return true;
}

export function NotaryDTOFromJSON(json: any): NotaryDTO {
    return NotaryDTOFromJSONTyped(json, false);
}

export function NotaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotaryDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'middleName': json['middleName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'commissionNumber': json['commissionNumber'] == null ? undefined : json['commissionNumber'],
        'commissionExpirationDate': json['commissionExpirationDate'] == null ? undefined : json['commissionExpirationDate'],
        'copyOfNotaryCommission': json['copyOfNotaryCommission'] == null ? undefined : json['copyOfNotaryCommission'],
        'nnaIdNumber': json['nnaIdNumber'] == null ? undefined : json['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': json['notarySigningAgentExamExpirationDate'] == null ? undefined : json['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': json['backgroundCheckExpirationDate'] == null ? undefined : json['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': json['eoInsuranceProvider'] == null ? undefined : json['eoInsuranceProvider'],
        'insuranceAmount': json['insuranceAmount'] == null ? undefined : json['insuranceAmount'],
        'policyExpiryDate': json['policyExpiryDate'] == null ? undefined : json['policyExpiryDate'],
        'copyOfEoPolicy': json['copyOfEoPolicy'] == null ? undefined : json['copyOfEoPolicy'],
        'digitalCertificate': json['digitalCertificate'] == null ? undefined : json['digitalCertificate'],
        'signature': json['signature'] == null ? undefined : json['signature'],
        'initials': json['initials'] == null ? undefined : json['initials'],
        'seal': json['seal'] == null ? undefined : json['seal'],
        'suffix': json['suffix'] == null ? undefined : json['suffix'],
        'policyNumber': json['policyNumber'] == null ? undefined : json['policyNumber'],
        'country': json['country'] == null ? undefined : json['country'],
        'paymentDetails': json['paymentDetails'] == null ? undefined : json['paymentDetails'],
        'zip': json['zip'],
        'state': json['state'] == null ? undefined : json['state'],
        'digitalCertificatePasscode': json['digitalCertificatePasscode'] == null ? undefined : json['digitalCertificatePasscode'],
        'city': json['city'] == null ? undefined : json['city'],
        'user': json['user'],
        'payoutFrequency': json['payoutFrequency'],
        'county': json['county'] == null ? undefined : json['county'],
        'digitalStartDate': json['digitalStartDate'] == null ? undefined : json['digitalStartDate'],
        'digitalEndDate': json['digitalEndDate'] == null ? undefined : json['digitalEndDate'],
        'isInhouseNotary': json['isInhouseNotary'] == null ? undefined : json['isInhouseNotary'],
        'quizAttempts': json['quizAttempts'] == null ? undefined : json['quizAttempts'],
        'wantSmsNotification': json['wantSmsNotification'] == null ? undefined : json['wantSmsNotification'],
        'titleLenderStatus': json['titleLenderStatus'] == null ? undefined : json['titleLenderStatus'],
        'businessStatus': json['businessStatus'] == null ? undefined : json['businessStatus'],
    };
}

export function NotaryDTOToJSON(value?: NotaryDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'middleName': value['middleName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'address1': value['address1'],
        'address2': value['address2'],
        'commissionNumber': value['commissionNumber'],
        'commissionExpirationDate': value['commissionExpirationDate'],
        'copyOfNotaryCommission': value['copyOfNotaryCommission'],
        'nnaIdNumber': value['nnaIdNumber'],
        'notarySigningAgentExamExpirationDate': value['notarySigningAgentExamExpirationDate'],
        'backgroundCheckExpirationDate': value['backgroundCheckExpirationDate'],
        'eoInsuranceProvider': value['eoInsuranceProvider'],
        'insuranceAmount': value['insuranceAmount'],
        'policyExpiryDate': value['policyExpiryDate'],
        'copyOfEoPolicy': value['copyOfEoPolicy'],
        'digitalCertificate': value['digitalCertificate'],
        'signature': value['signature'],
        'initials': value['initials'],
        'seal': value['seal'],
        'suffix': value['suffix'],
        'policyNumber': value['policyNumber'],
        'country': value['country'],
        'paymentDetails': value['paymentDetails'],
        'zip': value['zip'],
        'state': value['state'],
        'digitalCertificatePasscode': value['digitalCertificatePasscode'],
        'city': value['city'],
        'user': value['user'],
        'payoutFrequency': value['payoutFrequency'],
        'county': value['county'],
        'digitalStartDate': value['digitalStartDate'],
        'digitalEndDate': value['digitalEndDate'],
        'isInhouseNotary': value['isInhouseNotary'],
        'quizAttempts': value['quizAttempts'],
        'wantSmsNotification': value['wantSmsNotification'],
        'titleLenderStatus': value['titleLenderStatus'],
        'businessStatus': value['businessStatus'],
    };
}

