import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Notary, NotaryApi } from "../../../api";
import { useSessionCreate } from "../../../store/store";
import { useAuth } from "../../../utils/use-auth";
import { getStateName } from "../../../utils/utils";
import ArizonaDetailModal from "./ArizonaDetailModal";

type IStateOptions = {
  label: string;
  value: string;
};

const StateSpecificNotary = () => {
  const { configuration } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [arizonaNotaryList, setArizonaNotaryList] = useState<Notary[]>([]);
  const { sessionDetail, setSessionDetails } = useSessionCreate();
  const [stateOptions, setStateOptions] = useState<IStateOptions[]>([]);
  const [needSpecificNotary, setNeedSpecificNotary] = useState<string>(
    sessionDetail?.specificNotaryState !== "" ? "yes" : "no"
  );

  useEffect(() => {
    new NotaryApi(configuration).notaryControllerFindAllNotary().then((res) => {
      setArizonaNotaryList(res.filter((el) => el.state === "AZ"));

      const states = res
        ?.filter((item) => item?.notaryStatus === "active")
        ?.map((el) => el.state);
      const removeDupliicate = states.filter(
        (item, index) => states.indexOf(item) === index
      );
      const count: any = {};
      for (const element of states) {
        count[element] = (count[element] || 0) + 1;
      }
      setStateOptions(
        removeDupliicate
          ?.filter((el) => getStateName(el as string))
          .map((el) => ({
            label: `${getStateName(el as string)} (${count[el]})`,
            value: el,
          }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>
        <Text fontWeight={600}>
          Do you need state specific notary for your session?
        </Text>

        <RadioGroup
          mb={5}
          value={needSpecificNotary}
          onChange={(value) => {
            setNeedSpecificNotary(value);
            if (value === "no") {
              setSessionDetails({ ...sessionDetail, specificNotaryState: "" });
            }
          }}
        >
          <Stack direction="row" style={{ marginTop: "10px" }}>
            <Radio value="no">No</Radio>
            <Radio value="yes" pl={3}>
              Yes
            </Radio>
          </Stack>
        </RadioGroup>
      </Box>

      {needSpecificNotary === "yes" && (
        <Box>
          <Text fontWeight={600}>
            Select state for notary you need for your session
          </Text>
          <Box width={"250px"} mt={3}>
            <Select
              placeholder="Select State"
              options={stateOptions}
              value={
                stateOptions?.find(
                  (item) => item.value === sessionDetail.specificNotaryState
                ) || null
              }
              onChange={(option) => {
                setSessionDetails({
                  ...sessionDetail,
                  specificNotaryState: option?.value,
                });
                if (option?.value === "AZ") {
                  onOpen();
                }
              }}
            />
          </Box>
        </Box>
      )}

      <ArizonaDetailModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        arizonaNotaryList={arizonaNotaryList}
      />
    </>
  );
};

export default StateSpecificNotary;
