import { useEffect } from "react";
import BusinessSessionPayment from "../../../../component/Sessions/Business/BusinessSessionPayment";
import { useBusinessSessionStepStore } from "../../../../store/businessStore";

const BusinessSessionPaymentPage = () => {
  const { setStep } = useBusinessSessionStepStore();

  useEffect(() => {
    setStep(4);
  }, [setStep]);

  return <BusinessSessionPayment />;
};

export default BusinessSessionPaymentPage;
