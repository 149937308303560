import { Box, Button, Text } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { Session, SignedDocumentApi, SignedPdfDTO } from "../../../api";
import { handleError } from "../../../utils/utils-functions";
import { useAuth } from "../../../utils/use-auth";
import DocumentsData from "./DocumentsData";

const SignedDocInfo = ({
  session,
  signedDocPath,
  onSetIsSubmit,
  onSetErrMsg,
  errMsg,
  isSubmit,
  from,
}: {
  from: string;
  session?: Session;
  signedDocPath: SignedPdfDTO[];
  onSetIsSubmit: (value: boolean) => void;
  onSetErrMsg: (value: string) => void;
  errMsg: string;
  isSubmit: boolean;
}) => {
  const { configuration } = useAuth();
  const [isInvoicePaid, setIsInvoicePaid] = useState<boolean>(
    session?.isInvoicePaid ? session?.isInvoicePaid : false
  );

  const handlePayment = () => {
    onSetIsSubmit(true);
    new SignedDocumentApi(configuration)
      .signedDocumentControllerSessionPayment({
        manualPaymentDTO: {
          sessionId: Number(session?.sessionId),
        },
      })
      .then((res) => {
        onSetErrMsg("");
        onSetIsSubmit(false);
        setIsInvoicePaid(true);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        onSetErrMsg(message.errorMessage);
        onSetIsSubmit(false);
        setIsInvoicePaid(false);
      });
  };

  return (
    <>
      {session?.status === "completed" && (
        <Box pt={5}>
          <Text fontSize={"md"} fontWeight={600}>
            Documents
          </Text>
          <Box
            boxShadow="10px 4px 40px rgba(0, 0, 0, 0.25)"
            borderRadius="lg"
            px={"24px"}
            py={"21px"}
            mt={2}
            backgroundColor="#fff"
            fontSize={"sm"}
          >
            {isInvoicePaid ? (
              <>
                <Text fontSize={"10px"} fontWeight={400} w={"90%"} mb={2}>
                  Please note that for security reasons we store your documents
                  till{" "}
                  {moment(session?.createdDate)
                    .add(12, "M")
                    .format("MM-DD-YYYY, hh:mm A")}
                </Text>
                {signedDocPath?.flatMap((item, idx) => (
                  <DocumentsData
                    key={idx}
                    session={session}
                    signedDocPath={item}
                    idx={idx}
                  />
                ))}
              </>
            ) : (
              <>
                {from !== "notary" || session?.paymentBy === "notary" ? (
                  <>
                    <Text fontSize={"12px"} fontWeight={400} w={"90%"} mb={2}>
                      Invoice for this session is not paid. Complete your
                      transaction and then you are able to see your documents
                      and audit logs.
                    </Text>

                    {errMsg !== "" && (
                      <Text color="red" fontSize={"13px"}>
                        {errMsg}
                      </Text>
                    )}
                    <Button
                      w={"120px"}
                      background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                      borderRadius="10px"
                      p="4"
                      fontSize={"14px"}
                      _hover={{
                        background:
                          "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                      }}
                      onClick={handlePayment}
                      isDisabled={isSubmit}
                    >
                      Pay Now
                    </Button>
                  </>
                ) : (
                  <>
                    <Text fontSize={"12px"} fontWeight={400} w={"90%"} mb={2}>
                      Invoice for this session is not paid. So, you are not able
                      to see the documents and audit logs.
                    </Text>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SignedDocInfo;
