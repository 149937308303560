import {
  Text,
  Flex,
  Button,
  Box,
  Input,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useSessionCreate } from "../../../store/store";
import { today } from "../../../utils/utils";

const ScheduleMeetingData = () => {
  const { sessionDetail, setSessionDetails } = useSessionCreate();

  return (
    <>
      <Text fontWeight={600}>Do you want to schedule the Appointment?</Text>

      {/* <Flex gap={3}>
        <Button
          onClick={() =>
            setSessionDetails({
              ...sessionDetail,
              isSchedule: false,
              scheduleDate: "",
            })
          }
          _hover={
            !sessionDetail.isSchedule
              ? {
                  opacity: "0.9",
                }
              : {
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }
          }
          border={"1px solid #D5AD36"}
          backgroundColor={
            !sessionDetail.isSchedule ? "#D5AD36" : "#fff"
          }
          color={!sessionDetail.isSchedule ? "#fff" : "#D5AD36"}
          width="130px"
        >
          No
        </Button>
        <Button
          _hover={
            sessionDetail.isSchedule 
              ? {
                  opacity: "0.9",
                }
              : {
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                  color: "#fff",
                }
          }
          border={"1px solid #D5AD36"}
          backgroundColor={
            sessionDetail.isSchedule  ? "#D5AD36" : "#fff"
          }
          color={sessionDetail.isSchedule  ? "#fff" : "#D5AD36"}
          onClick={() =>
            setSessionDetails({ ...sessionDetail, isSchedule: true })
          }
        >
          Schedule date
        </Button>
      </Flex> */}

      <RadioGroup
        value={sessionDetail?.isSchedule ? "yes" : "no"}
        onChange={(value) => {
          if (value === "yes") {
            setSessionDetails({
              ...sessionDetail,
              isSchedule: true,
            });
          } else {
            setSessionDetails({
              ...sessionDetail,
              isSchedule: false,
              scheduleDate: "",
            });
          }
        }}
        mt={3}
      >
        <Stack direction="row" gap={3}>
          <Radio value="no">No</Radio>
          <Radio value="yes">Yes</Radio>
        </Stack>
      </RadioGroup>

      {sessionDetail.isSchedule && (
        <Box pt={4}>
          <Text fontWeight={500} mb={3}>
            Select Date
          </Text>
          <Input
            width="50%"
            color="grey"
            name="scheduleDate"
            type="datetime-local"
            min={today}
            onChange={(e) => {
              setSessionDetails({
                ...sessionDetail,
                scheduleDate: e.target.value,
              });
            }}
            value={sessionDetail?.scheduleDate}
          />
        </Box>
      )}
    </>
  );
};

export default ScheduleMeetingData;
