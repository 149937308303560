import { Box, Center, Spinner, Text } from "@chakra-ui/react";

const LoadingContent = ({ text }: { text?: string }) => {
  return (
    <Box pt={28}>
      <Center>
        <Spinner size={"xl"} thickness="2px" />
      </Center>

      <Text textAlign={"center"} mt={3} fontWeight={600}>
        {text ? text : "Loading your session"}...
      </Text>
    </Box>
  );
};

export default LoadingContent;
