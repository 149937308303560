/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteMediatorDTO
 */
export interface InviteMediatorDTO {
    /**
     * 
     * @type {string}
     * @memberof InviteMediatorDTO
     */
    sessionId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteMediatorDTO
     */
    mediatorId: Array<string>;
}

/**
 * Check if a given object implements the InviteMediatorDTO interface.
 */
export function instanceOfInviteMediatorDTO(value: object): value is InviteMediatorDTO {
    if (!('sessionId' in value) || value['sessionId'] === undefined) return false;
    if (!('mediatorId' in value) || value['mediatorId'] === undefined) return false;
    return true;
}

export function InviteMediatorDTOFromJSON(json: any): InviteMediatorDTO {
    return InviteMediatorDTOFromJSONTyped(json, false);
}

export function InviteMediatorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteMediatorDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'mediatorId': json['mediatorId'],
    };
}

export function InviteMediatorDTOToJSON(value?: InviteMediatorDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sessionId': value['sessionId'],
        'mediatorId': value['mediatorId'],
    };
}

