/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileS3UploadResponseDto
 */
export interface FileS3UploadResponseDto {
    /**
     * 
     * @type {string}
     * @memberof FileS3UploadResponseDto
     */
    path: string;
    /**
     * 
     * @type {Date}
     * @memberof FileS3UploadResponseDto
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof FileS3UploadResponseDto
     */
    updatedDate: Date;
}

/**
 * Check if a given object implements the FileS3UploadResponseDto interface.
 */
export function instanceOfFileS3UploadResponseDto(value: object): value is FileS3UploadResponseDto {
    if (!('path' in value) || value['path'] === undefined) return false;
    if (!('createdDate' in value) || value['createdDate'] === undefined) return false;
    if (!('updatedDate' in value) || value['updatedDate'] === undefined) return false;
    return true;
}

export function FileS3UploadResponseDtoFromJSON(json: any): FileS3UploadResponseDto {
    return FileS3UploadResponseDtoFromJSONTyped(json, false);
}

export function FileS3UploadResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileS3UploadResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'],
        'createdDate': (new Date(json['createdDate'])),
        'updatedDate': (new Date(json['updatedDate'])),
    };
}

export function FileS3UploadResponseDtoToJSON(value?: FileS3UploadResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'path': value['path'],
        'createdDate': ((value['createdDate']).toISOString()),
        'updatedDate': ((value['updatedDate']).toISOString()),
    };
}

