/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotaryPlan
 */
export interface NotaryPlan {
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    notaryPlanId: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryPlan
     */
    isSubscription: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotaryPlan
     */
    planName: string;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    planAmount: number;
    /**
     * 
     * @type {string}
     * @memberof NotaryPlan
     */
    planFeatures: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotaryPlan
     */
    isActive: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    packagePerMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    freeStamps?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    firstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    firstStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotFirstStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalStampPriceFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotAdditionalStampPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    primarySigner?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    primarySignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotprimarySigner?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalSigner?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalSignerFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotAdditionalSigner?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    primaryWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    primaryWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotPrimaryWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    additionalWitnessFees?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    byotAdditionalWitness?: number;
    /**
     * 
     * @type {number}
     * @memberof NotaryPlan
     */
    ownWitnessPrice?: number;
}

/**
 * Check if a given object implements the NotaryPlan interface.
 */
export function instanceOfNotaryPlan(value: object): value is NotaryPlan {
    if (!('notaryPlanId' in value) || value['notaryPlanId'] === undefined) return false;
    if (!('isSubscription' in value) || value['isSubscription'] === undefined) return false;
    if (!('planName' in value) || value['planName'] === undefined) return false;
    if (!('planAmount' in value) || value['planAmount'] === undefined) return false;
    if (!('planFeatures' in value) || value['planFeatures'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    return true;
}

export function NotaryPlanFromJSON(json: any): NotaryPlan {
    return NotaryPlanFromJSONTyped(json, false);
}

export function NotaryPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotaryPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'notaryPlanId': json['notaryPlanId'],
        'isSubscription': json['isSubscription'],
        'planName': json['planName'],
        'planAmount': json['planAmount'],
        'planFeatures': json['planFeatures'],
        'isActive': json['isActive'],
        'packagePerMonth': json['packagePerMonth'] == null ? undefined : json['packagePerMonth'],
        'freeStamps': json['freeStamps'] == null ? undefined : json['freeStamps'],
        'firstStampPrice': json['firstStampPrice'] == null ? undefined : json['firstStampPrice'],
        'firstStampPriceFees': json['firstStampPriceFees'] == null ? undefined : json['firstStampPriceFees'],
        'byotFirstStampPrice': json['byotFirstStampPrice'] == null ? undefined : json['byotFirstStampPrice'],
        'additionalStampPrice': json['additionalStampPrice'] == null ? undefined : json['additionalStampPrice'],
        'additionalStampPriceFees': json['additionalStampPriceFees'] == null ? undefined : json['additionalStampPriceFees'],
        'byotAdditionalStampPrice': json['byotAdditionalStampPrice'] == null ? undefined : json['byotAdditionalStampPrice'],
        'primarySigner': json['primarySigner'] == null ? undefined : json['primarySigner'],
        'primarySignerFees': json['primarySignerFees'] == null ? undefined : json['primarySignerFees'],
        'byotprimarySigner': json['byotprimarySigner'] == null ? undefined : json['byotprimarySigner'],
        'additionalSigner': json['additionalSigner'] == null ? undefined : json['additionalSigner'],
        'additionalSignerFees': json['additionalSignerFees'] == null ? undefined : json['additionalSignerFees'],
        'byotAdditionalSigner': json['byotAdditionalSigner'] == null ? undefined : json['byotAdditionalSigner'],
        'primaryWitness': json['primaryWitness'] == null ? undefined : json['primaryWitness'],
        'primaryWitnessFees': json['primaryWitnessFees'] == null ? undefined : json['primaryWitnessFees'],
        'byotPrimaryWitness': json['byotPrimaryWitness'] == null ? undefined : json['byotPrimaryWitness'],
        'additionalWitness': json['additionalWitness'] == null ? undefined : json['additionalWitness'],
        'additionalWitnessFees': json['additionalWitnessFees'] == null ? undefined : json['additionalWitnessFees'],
        'byotAdditionalWitness': json['byotAdditionalWitness'] == null ? undefined : json['byotAdditionalWitness'],
        'ownWitnessPrice': json['ownWitnessPrice'] == null ? undefined : json['ownWitnessPrice'],
    };
}

export function NotaryPlanToJSON(value?: NotaryPlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notaryPlanId': value['notaryPlanId'],
        'isSubscription': value['isSubscription'],
        'planName': value['planName'],
        'planAmount': value['planAmount'],
        'planFeatures': value['planFeatures'],
        'isActive': value['isActive'],
        'packagePerMonth': value['packagePerMonth'],
        'freeStamps': value['freeStamps'],
        'firstStampPrice': value['firstStampPrice'],
        'firstStampPriceFees': value['firstStampPriceFees'],
        'byotFirstStampPrice': value['byotFirstStampPrice'],
        'additionalStampPrice': value['additionalStampPrice'],
        'additionalStampPriceFees': value['additionalStampPriceFees'],
        'byotAdditionalStampPrice': value['byotAdditionalStampPrice'],
        'primarySigner': value['primarySigner'],
        'primarySignerFees': value['primarySignerFees'],
        'byotprimarySigner': value['byotprimarySigner'],
        'additionalSigner': value['additionalSigner'],
        'additionalSignerFees': value['additionalSignerFees'],
        'byotAdditionalSigner': value['byotAdditionalSigner'],
        'primaryWitness': value['primaryWitness'],
        'primaryWitnessFees': value['primaryWitnessFees'],
        'byotPrimaryWitness': value['byotPrimaryWitness'],
        'additionalWitness': value['additionalWitness'],
        'additionalWitnessFees': value['additionalWitnessFees'],
        'byotAdditionalWitness': value['byotAdditionalWitness'],
        'ownWitnessPrice': value['ownWitnessPrice'],
    };
}

