import moment from "moment";
import { useProfileDetailsStore } from "../../store/notaryStore";

const WashingtonStamp = ({
  name,
  height,
  width,
  commissionNumber,
  expires,
}: any) => {
  const { notaryProfile } = useProfileDetailsStore();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 432.000000 258.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{ background: "transparent" }}
      stroke="transparent"
    >
      {/* ****************************** Name ******************************  */}

      {name.length > 22 ? (
          <>
            <text
              x="50%"
              y="22%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"22px"}
              fontWeight="bold"
              // letterSpacing="0.7"
            >
              {notaryProfile.firstName} {notaryProfile.middleName}
            </text>
            <text
              x="50%"
              y="31.5%"
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={"22px"}
              fontWeight="bold"
              // letterSpacing="0.7"
            >
              {notaryProfile.lastName} {notaryProfile.suffix}
            </text>
          </>
        ) : (
          <text
          x="50%"
          y="29%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={"26px"}
          fontWeight="bold"
          letterSpacing="0.7"
          // fill="#F0F1F2"
        >
          {name}
        </text>
        )}
  

      {/* ****************************** Text - Notary Public  ******************************  */}

      <text
        x="53%"
        y="42%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"24px"}
        fontWeight="bold"
      >
        Notary Public
      </text>

      {/* ****************************** Text - state of Washington ******************************  */}

      <text
        x="52%"
        y="53%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"24px"}
        fontWeight="bold"
      >
        State of Washington
      </text>

      {/* ****************************** Text -commission ******************************  */}

      <text
        x="50%"
        y="66%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"24px"}
        fontWeight="bold"
      >
        Commission #{commissionNumber}
      </text>

      {/* *************************************** Text -Expires ******************************************** */}

      <text
        x="50%"
        y="78%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={"24px"}
        fontWeight="bold"
      >
        My Comm. Expires {moment(expires).format("MMM DD, YYYY")}
      </text>

      <g
        transform="translate(0.000000,258.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M90 2344 c0 -15 13 -41 29 -60 32 -35 51 -89 51 -139 0 -38 -30 -104
   -59 -129 -17 -15 -21 -30 -21 -74 0 -46 5 -60 30 -88 63 -72 64 -170 4 -249
   -46 -61 -48 -119 -5 -173 37 -45 56 -115 47 -164 -4 -21 -22 -60 -41 -89 -47
   -68 -48 -126 -6 -175 32 -35 51 -89 51 -139 0 -38 -30 -104 -59 -129 -31 -28
   -31 -124 0 -152 29 -25 59 -91 59 -129 0 -50 -19 -104 -51 -139 -16 -19 -29
   -43 -29 -55 0 -19 6 -21 55 -21 40 0 57 4 61 15 10 26 69 55 114 55 45 0 104
   -29 114 -55 8 -21 131 -21 140 0 10 28 69 55 118 55 41 0 54 -5 87 -35 34 -31
   45 -35 93 -35 43 0 59 5 74 21 59 65 159 65 218 0 15 -16 31 -21 74 -21 48 0
   59 4 93 35 33 30 46 35 87 35 49 0 108 -27 118 -55 5 -11 23 -15 70 -15 35 0
   64 4 64 10 0 5 17 21 37 35 27 18 51 25 85 25 41 0 54 -5 87 -35 34 -31 45
   -35 93 -35 43 0 59 5 74 21 59 65 159 65 218 0 15 -17 31 -21 75 -21 48 0 59
   4 87 31 57 55 169 49 207 -11 10 -17 23 -20 75 -20 46 0 64 4 68 15 10 26 69
   55 114 55 45 0 104 -29 114 -55 8 -21 131 -21 140 0 10 28 69 55 118 55 41 0
   54 -5 87 -35 34 -31 45 -35 93 -35 43 0 59 5 74 21 59 65 159 65 218 0 15 -16
   31 -21 74 -21 48 0 59 4 93 35 33 30 46 35 87 35 49 0 108 -27 118 -55 5 -11
   21 -15 60 -15 l54 0 0 34 c0 18 -7 39 -16 47 -31 25 -47 73 -48 137 0 54 4 68
   32 107 27 39 32 54 32 106 0 50 -4 66 -25 91 -31 37 -49 101 -40 147 7 39 39
   106 55 116 13 8 13 132 0 140 -16 10 -48 77 -55 116 -9 46 9 110 40 147 21 25
   25 41 25 91 0 52 -5 67 -29 101 -51 71 -49 175 4 238 21 25 25 41 25 92 0 51
   -4 67 -25 92 -54 64 -54 173 0 244 14 18 25 44 25 58 0 25 -2 26 -60 26 -56 0
   -60 -2 -75 -29 -41 -80 -131 -81 -198 -1 -22 25 -33 30 -72 30 -39 0 -50 -5
   -72 -30 -67 -80 -175 -78 -229 5 -14 21 -24 25 -64 25 -42 0 -52 -5 -87 -39
   -67 -67 -137 -67 -202 0 -34 34 -44 39 -84 39 -39 0 -49 -4 -66 -29 -24 -33
   -77 -61 -116 -61 -36 0 -100 37 -116 66 -10 20 -20 24 -61 24 -44 0 -53 -4
   -86 -39 -64 -67 -138 -68 -205 0 -34 33 -46 39 -82 39 -36 0 -48 -6 -82 -39
   -67 -68 -141 -67 -205 0 -33 35 -42 39 -86 39 -42 0 -50 -3 -62 -26 -17 -30
   -78 -64 -116 -64 -38 0 -91 28 -115 61 -17 25 -27 29 -65 29 -38 0 -49 -5 -83
   -39 -67 -67 -137 -67 -204 0 -35 34 -45 39 -87 39 -40 0 -50 -4 -64 -25 -24
   -37 -75 -65 -116 -65 -41 0 -92 28 -116 65 -14 21 -24 25 -64 25 -42 0 -52 -5
   -87 -39 -67 -67 -137 -67 -204 0 -34 34 -45 39 -83 39 -38 0 -48 -4 -65 -29
   -24 -33 -77 -61 -116 -61 -36 0 -100 37 -116 66 -11 21 -20 24 -66 24 -51 0
   -53 -1 -53 -26z m3990 -1049 l0 -895 -1920 0 -1920 0 0 895 0 895 1920 0 1920
   0 0 -895z"
        />
      </g>
    </svg>
  );
};

export default WashingtonStamp;
