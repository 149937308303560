import React, { useEffect } from "react";
import PrimarySignerDetails from "../../../../component/Sessions/Notary/PrimarySignerDetails";
import { useNotarySessionStepStore } from "../../../../store/notaryStore";

const PrimarySignerPage = () => {
  const { setStep } = useNotarySessionStepStore();

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  return <PrimarySignerDetails />;
};

export default PrimarySignerPage;
