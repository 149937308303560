/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StripeCustomersResponseDTO,
  UserControllerGetCutomerByUserId400Response,
  UserControllerGetCutomerByUserId404Response,
} from '../models/index';
import {
    StripeCustomersResponseDTOFromJSON,
    StripeCustomersResponseDTOToJSON,
    UserControllerGetCutomerByUserId400ResponseFromJSON,
    UserControllerGetCutomerByUserId400ResponseToJSON,
    UserControllerGetCutomerByUserId404ResponseFromJSON,
    UserControllerGetCutomerByUserId404ResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     */
    async userControllerGetCutomerByUserIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StripeCustomersResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customer/get-customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StripeCustomersResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async userControllerGetCutomerByUserId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StripeCustomersResponseDTO> {
        const response = await this.userControllerGetCutomerByUserIdRaw(initOverrides);
        return await response.value();
    }

}
