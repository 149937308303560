import { create } from "zustand";
import { IMembers } from "./zustandType";
import { AuditLogsApi } from "../api";

interface IVerifyMembers {
  members: IMembers[];
  verifiedMembers: any[];
  setMembers: (members: IMembers[]) => void;
  verifyMember: ({
    configuration,
    member,
  }: {
    configuration: any;
    member: IMembers;
    sessionID: number;
  }) => void;
  setVerifiedMembers: (members: any[]) => void;
}

export const useVerifyMembersStore = create<IVerifyMembers>((set, get) => ({
  members: [],
  setMembers: (members) => set({ members }),
  verifyMember: ({ configuration, member, sessionID }) => {
    const idx = get().members.findIndex((item) => item.value === member.value);
    set({
      members: [
        ...get().members.slice(0, idx),
        { ...member, isVerify: true },
        ...get().members.slice(idx + 1),
      ],
    });
    setTimeout(() => {
      new AuditLogsApi(configuration)
        .auditLogsControllerGetAudiLogsBySessionId({
          sessionId: Number(sessionID),
        })
        .then((audits) => {
          const res = audits
            ?.filter(
              (el) => el.action === "verified" && el?.event === "documentVerify"
            )
            // @ts-ignore
            ?.map((audit) => audit?.payload?.email);
          set({
            verifiedMembers: res,
          });
        });
    }, 3000);
  },
  verifiedMembers: [],
  setVerifiedMembers: (members) => set({ verifiedMembers: members }),
}));
