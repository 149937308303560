/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WitnessDTO
 */
export interface WitnessDTO {
    /**
     * 
     * @type {string}
     * @memberof WitnessDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WitnessDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof WitnessDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof WitnessDTO
     */
    witnessAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof WitnessDTO
     */
    witnessPhone: number;
}

/**
 * Check if a given object implements the WitnessDTO interface.
 */
export function instanceOfWitnessDTO(value: object): value is WitnessDTO {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('witnessPhone' in value) || value['witnessPhone'] === undefined) return false;
    return true;
}

export function WitnessDTOFromJSON(json: any): WitnessDTO {
    return WitnessDTOFromJSONTyped(json, false);
}

export function WitnessDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitnessDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'witnessAddress': json['witnessAddress'] == null ? undefined : json['witnessAddress'],
        'witnessPhone': json['witnessPhone'],
    };
}

export function WitnessDTOToJSON(value?: WitnessDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'witnessAddress': value['witnessAddress'],
        'witnessPhone': value['witnessPhone'],
    };
}

