/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdologyDTO
 */
export interface IdologyDTO {
    /**
     * 
     * @type {object}
     * @memberof IdologyDTO
     */
    response: object;
}

/**
 * Check if a given object implements the IdologyDTO interface.
 */
export function instanceOfIdologyDTO(value: object): value is IdologyDTO {
    if (!('response' in value) || value['response'] === undefined) return false;
    return true;
}

export function IdologyDTOFromJSON(json: any): IdologyDTO {
    return IdologyDTOFromJSONTyped(json, false);
}

export function IdologyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdologyDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'response': json['response'],
    };
}

export function IdologyDTOToJSON(value?: IdologyDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'response': value['response'],
    };
}

