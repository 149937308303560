//@ts-nocheck
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useAuth } from "../../utils/use-auth";
import useNotification from "../../utils/useNotification";
import * as Yup from "yup";
import { ContactUsApi } from "../../api";
import ReactInputMask from "react-input-mask";

const SupportModal = ({
  isOpen,
  onOpen,
  onClose,
  name,
  phoneNumber,
}: {
  name: string;
  phoneNumber: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  const { configuration } = useAuth();
  const { notification } = useNotification();

  const formik = useFormik({
    initialValues: {
      name: name,
      companyName: "",
      phoneNumber: phoneNumber,
      email: localStorage.getItem("email") as string,
      comment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(100, "Name must be 100 characters or less."),
      companyName: Yup.string().max(
        200,
        "Company Name must be 200 characters or less."
      ),
      phoneNumber: Yup.string().min(10).required("Phone Number is required"),
      email: Yup.string().required("Email is required"),
      comment: Yup.string()
        .required("Comment is required")
        .max(700, "Comment must be 700 characters or less."),
    }),

    onSubmit: (values) => {
      console.log(values);
      new ContactUsApi(configuration)
        .contactUsControllerCreate({
          contactUsDTO: {
            name: values.name,
            companyName: values?.companyName,
            phoneNumber: values.phoneNumber?.replace(/[^\d]/g, ""),
            email: values.email,
            role: localStorage.getItem("role") as string,
            comment: values.comment,
          },
        })
        .then((res) => {
          notification("success", "Sent Successfully");
          setTimeout(() => {
            onClose();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          notification("fail", "Failed to send");
        });
      onClose();
      onClose();
    },
    enableReinitialize: true,
  });

  return (
    <Modal closeOnEsc isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text
            ml={2}
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
          >
            Support
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody py={8}>
          <Box mb={5} fontSize="14px" fontWeight={600}>
            <Text>*Support Hours Monday – Friday 9:00am – 5:00pm EST</Text>
            <Text>*Or reach us by phone: (800) 381-8240</Text>
          </Box>

          <form onSubmit={formik.handleSubmit} onTouchCancel={onClose}>
            <Flex flexWrap="wrap">
              <FormControl w={"50%"} minW={"220px"} p={2}>
                <FormLabel>Full Name*</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  {...(formik.touched.name && formik.errors.name
                    ? { isInvalid: true }
                    : "")}
                />
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ""}
                </Text>
              </FormControl>

              <FormControl w={"50%"} minW={"220px"} p={2}>
                <FormLabel>Company Name</FormLabel>
                <Input
                  type="text"
                  name="companyName"
                  value={formik.values.companyName}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  {...(formik.touched.companyName && formik.errors.companyName
                    ? { isInvalid: true }
                    : "")}
                />
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.companyName && formik.errors.companyName
                    ? formik.errors.companyName
                    : ""}
                </Text>
              </FormControl>

              <FormControl id="email" w={"50%"} minW={"220px"} p={2}>
                <FormLabel
                  p="1"
                  style={{ fontWeight: "600", fontFamily: "Lato" }}
                >
                  Email*
                </FormLabel>
                <Input
                  required
                  name="email"
                  value={formik.values.email}
                  type="email"
                  pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                  placeholder="Email"
                  readOnly
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </FormControl>

              <FormControl id="phoneNumber" w={"50%"} minW={"220px"} p={2}>
                <FormLabel
                  p="1"
                  style={{ fontWeight: "600", fontFamily: "Lato" }}
                >
                  Phone Number*
                </FormLabel>
                <ReactInputMask
                  mask="(999)-999-9999"
                  value={formik.values.phoneNumber
                    ?.toString()
                    ?.replace(
                      /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                      "$1$2$3-$4$5$6-$7$8$9$10"
                    )}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  {/* @ts-ignore */}
                  {() => <Input required placeholder="(___)-___-____" />}
                </ReactInputMask>
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : ""}
                </Text>
              </FormControl>

              <FormControl p={2}>
                <FormLabel>Comment*</FormLabel>
                <Textarea
                  size="md"
                  name="comment"
                  value={formik.values.comment}
                  placeholder="Enter Your Comment"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  {...(formik.touched.comment && formik.errors.comment
                    ? { isInvalid: true }
                    : "")}
                />
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.comment && formik.errors.comment
                    ? formik.errors.comment
                    : ""}
                </Text>
              </FormControl>
            </Flex>

            <Flex justifyContent={"flex-end"} mt={"20px"}>
              <Button
                w={"70px"}
                p="4"
                background="white"
                border={"1px solid rgba(0, 0, 128, 1)"}
                borderRadius="10px"
                fontSize={"16px"}
                color="rgba(0, 0, 128, 1)"
                onClick={() => onClose()}
                mr={3}
              >
                Cancel
              </Button>
              <Button
                // w={"70px"}
                p="4"
                background="linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)"
                borderRadius="10px"
                fontSize={"16px"}
                _hover={{
                  background:
                    "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
                }}
                type="submit"
              >
                Send
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SupportModal;
