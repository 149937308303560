import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//Icons
import { FiEdit } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { TbFileInvoice } from "react-icons/tb";
import { MdOndemandVideo } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { HiOutlineDocumentText as PlanIcon } from "react-icons/hi";

import Card from "../../component/Card";
import { handleError } from "../../utils/utils-functions";
import { useAuth } from "../../utils/use-auth";
import { notaryPaths } from "../../routes/routes";
import { getNotaryDetails } from "../../utils/utils-apis";
import { PlatformRatingApi, SessionApi } from "../../api";
import useNotification from "../../utils/useNotification";
import { useProfileDetailsStore } from "../../store/notaryStore";
import NotaryJournal from "../../component/NotaryJournal/NotaryJournal";
import SupportModal from "../../component/commonComponents/SupportModal";
import DashboardCards from "../../component/commonComponents/DashboradData/DashboradCards/DashboardCards";
import ProfileStatusData from "../../component/commonComponents/DashboradData/ProfileStatusData";
import DashboardGraphs from "../../component/commonComponents/DashboradData/DashboardGraphs/DashboardGraphs";

const NotaryDashboard = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [rating, setRating] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState<number>(0);

  useEffect(() => {
    if (localStorage.getItem("role") === null) {
      navigate("/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    getNotaryDetails(configuration)
      .then((res) => {
        localStorage.setItem("userName", res.firstName);
        localStorage.setItem("notaryId", res?.notaryId?.toString());
        setNotaryProfile({
          ...notaryProfile,
          firstName: res?.firstName,
          lastName: res?.lastName,
          notaryId: res?.notaryId.toString(),
          notaryStatus: res?.notaryStatus,
          isInhouseNotary: res?.isInhouseNotary,
          onDemandStatus: res?.onDemandStatus,
          titleLenderStatus: res?.titleLenderStatus,
          businessStatus: res?.businessStatus,
        });

        new PlatformRatingApi(configuration)
          .platformRatingControllerGetAverageStarRating()
          .then((rating) => {
            if (rating.average !== null) {
              setRating(rating.average);
            } else {
              setRating(0);
            }
          })
          .catch((error) => {
            setRating(0);
          });
      })
      .catch(async (error) => {
        const message = await handleError(error);
        if (
          message?.errorMessage === "Notary not Found" ||
          message?.statusCode === 500
        ) {
          notification("warn", "Complete your Profile");
          setTimeout(() => {
            navigate(`${notaryPaths.profileSetting}/personal-information`);
          }, 1000);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExemplar = () => {
    new SessionApi(configuration)
      .sessionControllerCreate({
        sessionDTO: {
          signers: [],
          witnessesCount: 0,
          mediators: [],
          primarySignerUserId: undefined,
          primarySignerName: "None",
          specificNotaryState: "",
          paymentBy: "notary",
          documentFees: 0,
          notaryNote: "Notary Exemplar",
          isInhouseNotary: false,
          isDemoSession: false,
          isTitleLenderSession: false,
          scheduleDate: "",
          kbaLogs: [],
          notary: Number(notaryProfile?.notaryId),
          documents: [process.env.REACT_APP_ENV !== "dev" ? "1624" : "3847"],
          isNotaryExemplarSheet: true,
        },
      })
      .then((res) => {
        notification("success", "Session Created Successfully");
        navigate("/notary/sessions/active");
      })
      .catch((err) => {
        console.log(err);
        notification("fail", "Failed to Create Session");
      });
  };

  const flexButtonData = (title: string, link: string, icon: JSX.Element) => {
    return (
      <Button
        fontSize={"16px"}
        fontWeight={"normal"}
        color={"#D5AD36"}
        height={"40px"}
        _hover={{
          background:
            "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.5) 100%)",
          color: "#fff",
        }}
        border={"1px solid #D5AD36"}
        backgroundColor={"white"}
        onClick={() => {
          if (link === "support") {
            onOpen();
          } else if (link === "exemplar") {
            handleExemplar();
          } else {
            navigate(link);
          }
        }}
      >
        {icon} <span style={{ marginLeft: "5px" }}>{title}</span>
      </Button>
    );
  };

  return (
    <>
      <Box style={{ padding: "0 1rem" }}>
        <Heading
          fontSize={{ base: "18px", md: "22px", lg: "22px" }}
          lineHeight="30px"
          textAlign={"center"}
          mb={6}
          fontFamily="Oswald"
          fontWeight={600}
        >
          Notary Dashboard
        </Heading>

        <Card>
          <Text fontSize="20px" fontWeight={"bold"} mb={5}>
            My Shortcuts
          </Text>
          <Flex flexWrap={"wrap"} justifyContent={"flex-start"} gap={3}>
            {flexButtonData(
              "Invite a Client",
              `${notaryPaths.createSession}/type`,
              <FiEdit />
            )}
            {!notaryProfile.isInhouseNotary &&
              flexButtonData(
                "Join Session",
                `${notaryPaths.notaryOndemand}`,
                <MdOndemandVideo />
              )}
            {flexButtonData(
              "Update Profile",
              `${notaryPaths.profileSetting}/personal-information`,
              <CgProfile />
            )}
            {flexButtonData(
              "My Journal",
              `${notaryPaths.notaryJournal}`,
              <AiOutlineHistory />
            )}
            {flexButtonData("Manage Plan", "/notary/plans", <PlanIcon />)}
            {flexButtonData(
              "Invoices",
              `${notaryPaths.notaryInvoices}`,
              <TbFileInvoice />
            )}
            {flexButtonData("Notary Exemplar", "exemplar", <IoCreateOutline />)}
          </Flex>
        </Card>

        <ProfileStatusData />

        <DashboardCards
          totalInvoicesAmount={totalInvoicesAmount}
          from="notary"
        />

        <DashboardGraphs
          onSetTotalInvoicesAmount={(value) => setTotalInvoicesAmount(value)}
          from="notary"
          rating={rating}
        />

        <Box
          mt={8}
          style={{
            width: "100%",
            padding: "20px",
            boxShadow: "-1px 5px 15px 0px #ddd",
          }}
        >
          <Text fontSize="20px" fontWeight={"bold"} mb={6}>
            Most Recent Notary Journal Entries
          </Text>
          <NotaryJournal from="dashboard" />
          <Text
            color={"#000080"}
            fontWeight={600}
            fontSize={"14px"}
            textAlign="right"
            textDecoration={"underline"}
            mt={3}
            role="button"
            onClick={() => navigate(`${notaryPaths.notaryJournal}`)}
          >
            View More &gt;
          </Text>
        </Box>
      </Box>

      <SupportModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        name={`${notaryProfile?.firstName} ${notaryProfile?.lastName}`}
        phoneNumber={notaryProfile?.phoneNumber}
      />
    </>
  );
};

export default NotaryDashboard;
