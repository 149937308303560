//@ts-nocheck
import {
  Box,
  Text,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { SignerApi } from "../../../api";
import { handleError } from "../../../utils/utils-functions";
import { useAuth } from "../../../utils/use-auth";
import BaseLayout from "../../../layout/BaseLayout";
import useNotification from "../../../utils/useNotification";
import { getBusinessDetails } from "../../../utils/utils-apis";
import { IBusinessProfileDetails } from "../../../store/zustandType";
import { StateFullnameOptions } from "../../../utils/options";
import { useBusinessProfileDetailsStore } from "../../../store/businessStore";

const BusinessProfile = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const [called, setCalled] = useState<boolean>(true);
  const [disable, setDisable] = useState<boolean>(false);
  const { businessProfile, setBusinessProfile } =
    useBusinessProfileDetailsStore();

  const [selectedOption, setSelectedOption] = useState<
    SingleValue<{
      label: string;
      value: string;
    }>
  >({
    label: "",
    value: "",
  });

  useEffect(() => {
    getBusinessDetails(configuration)
      .then((res) => {
        setBusinessProfile({
          ...businessProfile,
          businessName: res?.businessName
            ? res?.businessName
            : (localStorage.getItem("businessName") as string),
          businessCity: res.businessCity,
          businessPhone: res.businessPhone?.toString(),
          businessWebsite: res.businessWebsite,
          businessZip: res.businessZip.toString(),
          country: res.country,
          position: res.position,
          state: res.state,
          email: res?.email ?? localStorage.getItem("email"),
          businessId: res?.businessId,
          address1: res?.address1,
          address2: res?.address2,
        });
        setSelectedOption(
          StateFullnameOptions?.find(
            (item) => item?.value === res?.state
          ) as SingleValue<{ value: string; label: string }>
        );
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBusinessProfile = (values: IBusinessProfileDetails) => {
    setDisable(true);
    new SignerApi(configuration)
      .signerControllerCreateBusiness({
        businessDTO: {
          ...values,
          businessZip: Number(values.businessZip),
          businessPhone: Number(values.businessPhone),
        },
      })
      .then((res) => {
        notification("success", "Profile Created Successfully");
        setDisable(false);
        setTimeout(() => {
          navigate("/business/plans");
        }, 1000);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        notification(
          "fail",
          message.errorMessage ?? "Failed to Create Profile"
        );
        setDisable(false);
      });
  };

  const updateBusinessProfile = (values: IBusinessProfileDetails) => {
    setDisable(true);
    new SignerApi(configuration)
      .signerControllerUpdateBusiness({
        id: businessProfile?.businessId,
        updateBusinessDTO: {
          ...values,
          businessZip: Number(values.businessZip),
          businessPhone: Number(values.businessPhone),
        },
      })
      .then((res) => {
        notification("success", "Profile Updated Successfully!");
        setDisable(false);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch(async (err) => {
        const message = await handleError(err);
        notification(
          "fail",
          message.errorMessage ?? "Failed to Update Profile"
        );
        setDisable(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...businessProfile,
    },

    validationSchema: Yup.object({
      businessName: Yup.string()
        .max(50, "Business Name must be 50 characters or less.")
        .required("Business Name is required"),
      businessCity: Yup.string()
        .max(50, "Business City must be 50 characters or less.")
        .required("Business City is required"),
      position: Yup.string()
        .required("Position is required")
        .max(50, "Position must be 50 characters or less."),
      businessPhone: Yup.string().min(10).required("Phone Number is required"),
      businessZip: Yup.string()
        .max(8, "Zip Code must be of maximum 8 digits")
        .required("Zip is required"),
      address1: Yup.string()
        .max(200, "Address Line 1 must be 200 characters or less.")
        .required("Address Line 1 is required"),
    }),

    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        businessPhone: values?.businessPhone?.toString().replace(/[^\d]/g, ""),
        state: selectedOption?.value as string,
      };
      setBusinessProfile(dataToSend);

      if (businessProfile?.businessId !== -1) {
        updateBusinessProfile(dataToSend);
      } else {
        createBusinessProfile(dataToSend);
      }
    },
    enableReinitialize: called,
  });

  return (
    <BaseLayout>
      <Text textAlign="center" fontSize="24px" fontWeight={"bold"} mb={6}>
        Business Account Details
      </Text>
      <Box
        style={{ width: "90%", margin: "auto" }}
        fontFamily="Lato"
        fontWeight={600}
        mt={12}
      >
        <form onSubmit={formik.handleSubmit}>
          <Flex flexWrap={"wrap"}>
            <FormControl
              id="position"
              w={["100%", "50%", "50%"]}
              minW={"220px"}
              p={2}
            >
              <FormLabel
                p="1"
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                Your Position*
              </FormLabel>
              <Input
                {...(formik.touched.position && formik.errors.position
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                name="position"
                value={formik.values.position}
                type="text"
                placeholder="CEO"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                style={{ textTransform: "capitalize" }}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.position && formik.errors.position
                  ? formik.errors.position
                  : ""}
              </Text>
            </FormControl>

            <FormControl
              id="businessName"
              w={["100%", "50%", "50%"]}
              minW={"220px"}
              p={2}
            >
              <FormLabel
                p="1"
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                Business Name*
              </FormLabel>
              <Input
                name="businessName"
                type="text"
                placeholder="Business Name"
                {...(formik.touched.businessName && formik.errors.businessName
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.businessName}
                onBlur={formik.handleBlur}
                style={{ textTransform: "capitalize" }}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.businessName && formik.errors.businessName
                  ? formik.errors.businessName
                  : ""}
              </Text>
            </FormControl>

            <FormControl
              id="email"
              w={["100%", "50%", "50%"]}
              minW={"220px"}
              p={2}
            >
              <FormLabel
                p="1"
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                Email*
              </FormLabel>
              <Input
                required
                name="email"
                value={
                  formik.values.email !== ""
                    ? formik.values.email
                    : localStorage.getItem("email") ?? ""
                }
                type="email"
                pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
                placeholder="Email"
                readOnly
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
            </FormControl>

            <FormControl
              id="businessWebsite"
              w={["100%", "50%", "50%"]}
              minW={"220px"}
              p={2}
            >
              <FormLabel
                p="1"
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                Company Website*
              </FormLabel>
              <Input
                name="businessWebsite"
                value={formik.values.businessWebsite}
                type="text"
                placeholder="Business Website"
                {...(formik.touched.businessWebsite &&
                formik.errors.businessWebsite
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.businessWebsite && formik.errors.businessWebsite
                  ? formik.errors.businessWebsite
                  : ""}
              </Text>
            </FormControl>

            <FormControl
              id="businessPhone"
              w={["100%", "50%", "50%"]}
              minW={"220px"}
              p={2}
            >
              <FormLabel
                p="1"
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                Phone Number*
              </FormLabel>
              <ReactInputMask
                mask="(999)-999-9999"
                value={formik.values.businessPhone
                  ?.toString()
                  ?.replace(
                    /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                    "$1$2$3-$4$5$6-$7$8$9$10"
                  )}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              >
                {/* @ts-ignore */}
                {() => <Input required placeholder="(___)-___-____" />}
              </ReactInputMask>
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.businessPhone && formik.errors.businessPhone
                  ? formik.errors.businessPhone
                  : ""}
              </Text>
            </FormControl>

            <FormControl id="address1" w={"100%"} minW={"220px"} p={2}>
              <FormLabel p="1">Address Line 1*</FormLabel>
              <Input
                {...(formik.touched.address1 && formik.errors.address1
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                name="address1"
                value={formik.values.address1}
                type="text"
                placeholder="Address Line 1"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.address1 && formik.errors.address1
                  ? formik.errors.address1
                  : ""}
              </Text>
            </FormControl>

            <FormControl id="address2" w={"100%"} minW={"220px"} p={2}>
              <FormLabel p="1">Address Line 2</FormLabel>
              <Input
                {...(formik.touched.address2 && formik.errors.address2
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                name="address2"
                value={formik.values.address2}
                type="text"
                placeholder="Address Line 2"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.address2 && formik.errors.address2
                  ? formik.errors.address2
                  : ""}
              </Text>
            </FormControl>

            <FormControl
              id="businessCity"
              w={["100%", "35%", "35%"]}
              minW={"250px"}
              p={2}
            >
              <FormLabel
                fontSize={"14px"}
                p={1}
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                City*
              </FormLabel>
              <Input
                fontSize={"14px"}
                placeholder="City"
                name="businessCity"
                type={"text"}
                {...(formik.touched.businessCity && formik.errors.businessCity
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.businessCity}
                onBlur={formik.handleBlur}
                style={{ textTransform: "capitalize" }}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.businessCity && formik.errors.businessCity
                  ? formik.errors.businessCity
                  : ""}
              </Text>
            </FormControl>
            <FormControl
              id="state"
              w={["100%", "35%", "35%"]}
              minW={"250px"}
              p={2}
            >
              <FormLabel
                fontSize={"14px"}
                p={1}
                style={{ fontWeight: "600", fontFamily: "Lato" }}
              >
                State*
              </FormLabel>
              <Select
                placeholder="Select State"
                options={StateFullnameOptions}
                value={selectedOption}
                menuPlacement="top"
                onChange={(option) => {
                  setCalled(false);
                  setSelectedOption(option);
                  setBusinessProfile({
                    ...businessProfile,
                    state: option?.value,
                  });
                }}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.state && formik.errors.state
                  ? formik.errors.state
                  : ""}
              </Text>
            </FormControl>

            <FormControl
              id="businessZip"
              w={["100%", "30%", "30%"]}
              minW={"250px"}
              p={2}
            >
              <FormLabel fontSize={"14px"} p={1}>
                Zip*
              </FormLabel>
              <Input
                minLength={5}
                maxLength={5}
                fontSize={"14px"}
                placeholder="Zip"
                name="businessZip"
                type={"number"}
                {...(formik.touched.businessZip && formik.errors.businessZip
                  ? { isInvalid: true }
                  : "")}
                errorBorderColor="red.300"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.businessZip}
                onBlur={formik.handleBlur}
              />
              <Text
                color={"red"}
                fontSize={"14px"}
                fontWeight={"500"}
                marginLeft={"6px"}
              >
                {formik.touched.businessZip && formik.errors.businessZip
                  ? formik.errors.businessZip
                  : ""}
              </Text>
            </FormControl>
          </Flex>

          <Flex justify={"end"} p="2" mt={12}>
            <Button
              type="button"
              width="12%"
              style={{
                background: "#2D3748",
                color: "#fff",
                minWidth: "80px",
              }}
              onClick={() => navigate("/")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              width="12%"
              ml={4}
              isDisabled={disable}
              style={{
                background:
                  "linear-gradient(180deg, #D5AD36 0%, rgba(213, 173, 54, 0.52) 100%)",
                minWidth: "80px",
                // color: "#fff",
              }}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Box>
    </BaseLayout>
  );
};

export default BusinessProfile;
