import { Stack, Text } from "@chakra-ui/react";
import Participants from "../components/SessionParticipants/Participants";
import { signerPaths } from "../../../routes/routes";

export const SessionParticipants = () => {
  return (
    <Stack px={2}>
      <Text
        fontWeight={600}
        fontSize={{ base: "18px", md: "22px", lg: "22px" }}
        textAlign={"center"}
        fontFamily={"oswald"}
      >
        Participants
      </Text>

      <Participants
        nextNavigationPath={`${signerPaths.createSession}/payment`}
        prevNavigationPath={`${signerPaths.createSession}/document-upload`}
        from="signer"
      />
    </Stack>
  );
};
