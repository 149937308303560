import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { ChangeEvent, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthApi, RegisterDTO } from "../../../api";
import { handleError } from "../../../utils/utils-functions";
import { useAuth } from "../../../utils/use-auth";
import * as Yup from "yup";
import { regexPassword } from "../../../utils/utils";
import useNotification from "../../../utils/useNotification";
import AlertMsg from "../../../component/commonComponents/AlertMsg";
import { BsPatchCheckFill as TickIcon } from "react-icons/bs";
import { notaryPaths } from "../../../routes/routes";

const SignUpForm = () => {
  const { notification } = useNotification();
  const { configuration } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");
  const role = searchParams.get("role");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [invalidError, setInvalidError] = useState<string>("");
  const [signUpData, setSignUpData] = useState<RegisterDTO>({
    username: "",
    email: "",
    password: "",
    role: role ? role : "notary",
    businessName: "",
    coupon: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const passwordRef = useRef<HTMLInputElement | undefined>();
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState<string>();
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [couponMsg, setCouponMsg] = useState<string>("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "password" && passwordRef.current) {
      if (regexPassword.test(passwordRef?.current?.value) === false) {
        setInvalidError(
          "Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number & 8 characters long"
        );
      } else if (regexPassword.test(e.target.value) === true) {
        setInvalidError("");
      }
    }
    setSignUpData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRoleChange = (e: string) => {
    setSignUpData((prev) => ({
      ...prev,
      role: e,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsDisable(true);
    e.preventDefault();
    const dataToSend = {
      username: signUpData?.username,
      email: signUpData?.email?.toLowerCase(),
      password: signUpData?.password,
      role: signUpData?.role,
      businessName: signUpData?.businessName,
      coupon: signUpData?.coupon,
    };
    new AuthApi(configuration)
      .authControllerRegister({
        registerDTO: dataToSend,
      })
      .then((res) => {
        notification("success", "User Created");
        if (res.accessToken) {
          localStorage.setItem("accessToken", res?.accessToken);
          localStorage.setItem("email", res?.email?.toLowerCase());
          localStorage.setItem("UserID", res?.id?.toString());
          localStorage.setItem("role", res?.role?.toString());
          localStorage.setItem("businessName", res?.businessName?.toString());

          if (planId != null) {
            if (res?.role === "notary") {
              navigate(`/notary/plans?plan=${planId}`);
            } else if (res?.role === "business") {
              navigate(planId ? `/business/plans?plan=${planId}` : "/");
            } else {
              window.location.href = `/`;
            }
          } else {
            window.location.href = `/`;
          }
        } else {
          navigate(planId ? `/sign-in?plan=${planId}` : "/sign-in");
        }

        setIsDisable(false);
      })
      .catch(async (error) => {
        const message = await handleError(error);
        setErrorMsg(message.errorMessage);
        setIsDisable(false);
        console.log(error);
        notification("fail", "Failed to create User");
        onOpen();
      });
  };

  const formik = useFormik({
    initialValues: {
      ...signUpData,
    },

    validationSchema: Yup.object({
      businessName: Yup.string()
        .max(20, "Business Name must be 50 characters or less.")
        .required("Business Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .email("Invalid Password")
        .required("Password is required"),
    }),

    onSubmit: (values) => {
      navigate(`${notaryPaths.profileSetting}/commission-details`);
    },
    enableReinitialize: true,
  });

  const handleVerifyCoupon = () => {
    setIsVerifying(true);
    new AuthApi(configuration)
      .authControllerCouponCheck({
        couponCode: signUpData?.coupon as string,
        couponVerifyBody: {
          by: signUpData.role,
        },
      })
      .then((res) => {
        setCouponMsg(res?.status);
        if (res?.status === "Coupon verified!") {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
        setIsVerifying(false);
      })
      .catch((err) => {
        console.log(err);
        setIsVerifying(false);
        setCouponMsg("");
        setIsValid(false);
      });
  };

  return (
    <>
      <AlertMsg errorMsg={errorMsg} onClose={onClose} isOpen={isOpen} />
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {/* {signUpData?.role !== "business" && (
              <FormControl>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="600"
                  fontFamily="Lato"
                >
                  Username
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="text"
                  {...(formik.touched.username && formik.errors.username
                    ? { isInvalid: true }
                    : "")}
                  errorBorderColor="red.300"
                  placeholder="John Doe"
                  mb="18px"
                  size="lg"
                  border="1px solid #E2E8F0"
                  name="username"
                  onChange={(e) => {
                    handleInputChange(e);
                    formik.handleChange(e);
                  }}
                />
                <Text
                  color={"red"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  marginLeft={"6px"}
                >
                  {formik.touched.username && formik.errors.username
                    ? formik.errors.username
                    : ""}
                </Text>
              </FormControl>
            )} */}

        <FormControl>
          <FormLabel ms="4px" fontSize="sm" fontWeight="600" fontFamily="Lato">
            Email
          </FormLabel>
          <Input
            variant="auth"
            fontSize="sm"
            ms="4px"
            type="email"
            {...(formik.touched.email && formik.errors.email
              ? { isInvalid: true }
              : "")}
            errorBorderColor="red.300"
            placeholder="johndoe123@email.com"
            mb="18px"
            size="lg"
            border="1px solid #E2E8F0"
            name="email"
            pattern="\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b"
            onChange={(e) => {
              handleInputChange(e);
              formik.handleChange(e);
            }}
          />
          <Text
            color={"red"}
            fontSize={"14px"}
            fontWeight={"500"}
            marginLeft={"6px"}
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""}
          </Text>
        </FormControl>

        <FormControl>
          <FormLabel ms="4px" fontSize="sm" fontWeight="600" fontFamily="Lato">
            Password
          </FormLabel>

          <Input
            // @ts-ignore
            ref={passwordRef}
            variant="auth"
            fontSize="sm"
            ms="4px"
            type="password"
            placeholder="Your password"
            size="lg"
            border="1px solid #E2E8F0"
            name="password"
            onChange={(e) => handleInputChange(e)}
          />
          {invalidError !== "" && (
            <Text fontSize={"12px"} color="red" p={2}>
              {invalidError}
            </Text>
          )}
        </FormControl>
        <FormControl>
          <FormLabel
            mt="15px"
            ms="4px"
            fontSize="sm"
            fontWeight="600"
            fontFamily="Lato"
          >
            Confirm Password
          </FormLabel>
          <Input
            variant="auth"
            fontSize="sm"
            type="password"
            placeholder="Confirm Password"
            size="lg"
            border="1px solid #E2E8F0"
            onChange={(e) => {
              if (signUpData.password !== e.target.value) {
                setError("Password and confirm password doesnot match");
              } else {
                setError("");
              }
              setConfirmPassword(e.target.value);
            }}
          />
          {error !== "" && (
            <Text fontSize={"12px"} color="red" px="2">
              {error}
            </Text>
          )}
        </FormControl>

        <FormControl my={4}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="600" fontFamily="Lato">
            Role
          </FormLabel>
          <RadioGroup
            value={signUpData?.role}
            onChange={(e) => handleRoleChange(e)}
            name="role"
          >
            <Stack direction="row" justifyContent={"space-between"}>
              <Radio value="notary">Notary</Radio>
              <Radio value="signer">Signer</Radio>
              <Radio value="business">Business</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        {signUpData?.role === "business" && (
          <FormControl mb={3}>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              fontFamily="Lato"
            >
              Business Name
            </FormLabel>
            <Input
              variant="auth"
              fontSize="sm"
              type="text"
              placeholder="Business Name"
              name="businessName"
              size="lg"
              border="1px solid #E2E8F0"
              onChange={(e) => handleInputChange(e)}
            />
          </FormControl>
        )}

        {signUpData?.role !== "signer" && (
          <FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="600"
              fontFamily="Lato"
            >
              Coupon
            </FormLabel>
            <InputGroup size="md" alignItems="center">
              <Input
                variant="auth"
                fontSize="sm"
                type="text"
                placeholder="Enter code"
                name="coupon"
                size="lg"
                border="1px solid #E2E8F0"
                onChange={handleInputChange}
              />
              <InputRightElement
                background="#EDF2F7"
                borderLeft="1px solid #f7f7f8"
                width={"80px"}
                height={"48px"}
                borderRadius="0px 10px 10px 0px"
              >
                {isVerifying ? (
                  <Box>
                    <Spinner />
                  </Box>
                ) : isValid ? (
                  <TickIcon size={20} color="green" />
                ) : (
                  <Box role={"button"} onClick={handleVerifyCoupon}>
                    Verify
                  </Box>
                )}
              </InputRightElement>
            </InputGroup>

            {couponMsg !== "" && (
              <Text
                fontSize={"12px"}
                color={isValid ? "green" : "red"}
                px="2"
                mt={1}
              >
                {couponMsg}
              </Text>
            )}
          </FormControl>
        )}

        <Button
          fontSize="14px"
          variant="dark"
          fontWeight="900"
          fontFamily="Lato"
          w="100%"
          h="45"
          my="20px"
          bgGradient="linear(to-t,#D5AD3680,#D5AD36)"
          type="submit"
          isDisabled={
            // (signUpData.role !== "business" && signUpData.username === "") ||
            invalidError !== "" ||
            error !== "" ||
            signUpData.email === "" ||
            signUpData.password === "" ||
            confirmPassword === "" ||
            (signUpData.role === "business" &&
              signUpData.businessName === "") ||
            isDisable
          }
        >
          SIGN UP
        </Button>
      </form>
    </>
  );
};

export default SignUpForm;
