import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OpenReplay } from "../utils/OpenReplay";
import {
  ISessionDetails,
  ISessionDetailsStore,
  IResponsive,
  IAuthInfo,
  IUseAuthStore,
  IKBAVerification,
} from "./zustandType";
const role = localStorage.getItem("role");

export const initialSessionDetails = {
  signers: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      dob: "",
      lastFourDigitsSsnNumber: "",
      kbaStatus: "pending",
    },
  ],
  mediatores: [
    {
      firstName: "",
      lastName: "",
      email: "",
    },
  ],
  witnesses: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
  ],
  needMediator: false,
  needWitness: "no",
  isSchedule: false,
  emailSubject: "",
  emailMessage: "",
  emailSignature: "",
  documents: [],
  witnessesCount: 0,
  scheduleDate: "",
  notifyViaText: false,
  notaryNote: "",
  paymentBy: role === "business" ? "business" : "signer",
  documentFees: 0,
  kbaLogs: [],
  specificNotaryState: "",
  notaryId: "",
  primarySignerName: "",
  isDemoSession: false,
  isTitleLenderSession: false,
};

export const useSessionCreate = create<ISessionDetailsStore>()(
  devtools(
    OpenReplay.createLogger("create-session-store")(
      // @ts-ignore
      (set) => ({
        sessionDetail: initialSessionDetails,
        setSessionDetails: (newDetails: Object) =>
          set((state: { sessionDetail: ISessionDetails }) => ({
            sessionDetail: { ...state.sessionDetail, ...newDetails },
          })),
      }),
      {
        name: "create-session-store",
      }
    )
  )
);

export const useAuthStore = create<IUseAuthStore>()(
  devtools(
    OpenReplay.createLogger("authStore")(
      // @ts-ignore
      (set) => ({
        authInfo: {
          userID: "",
          role: "",
        },
        setAuthInfo: (newAuthInfo: object) =>
          set((state: { authInfo: IAuthInfo }) => ({
            authInfo: { ...state.authInfo, ...newAuthInfo },
          })),
      }),
      {
        name: "auth-store",
      }
    )
  )
);

export const useResponsive = create<IResponsive>((set) => ({
  isMobileView: false,
  setIsMobileView: (newDetails: boolean) =>
    set((state: { isMobileView: boolean }) => ({
      isMobileView: newDetails,
    })),
}));

// only in case of title lender
export const useKBAVerification = create<IKBAVerification>((set) => ({
  wasKBACompleted: false,
  setWasKBACompleted: (wasKBACompleted: boolean) =>
    set({
      wasKBACompleted: wasKBACompleted,
    }),
}));
