import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Subscription, SubscriptionApi } from "../../../../../api";
import { useAuth } from "../../../../../utils/use-auth";
import { IoIosArrowForward as ArrowIcon } from "react-icons/io";
import PlansModal from "../../../../../pages/Business/Plans/Components/PlansModal";
import { espTransform } from "../../../../../utils/utils";
import { RiFileList3Fill } from "react-icons/ri";
import { IoBagCheck } from "react-icons/io5";
import Card from "../../../../Card";

const PlanCard = () => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const [activePlan, setActivePlan] = useState<Subscription>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setLoading(true);
    new SubscriptionApi(configuration)
      .subscriptionControllerGet()
      .then((res) => {
        setActivePlan(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={"My Active Plan"}>
        {loading ? (
          <Flex alignItems={"center"} mt={3}>
            <Box borderRadius={"full"} background="#D5AD36" p={2}>
              <RiFileList3Fill size={22} />
            </Box>
            <Box>
              <Text ml={2} fontWeight={500}>
                Loading...
              </Text>
            </Box>
          </Flex>
        ) : activePlan === undefined ? (
          <Button variant="link" p={0} color="#000" mt={4} onClick={onOpen}>
            <Box borderRadius={"full"} background="#D5AD36" p={2}>
              <IoBagCheck size={22} />
            </Box>

            <Text ml={2} fontWeight={500}>
              Buy New Plan
            </Text>
          </Button>
        ) : (
          <Flex
            alignItems={"center"}
            justifyContent="space-between"
            mt={2}
            role={"button"}
            onClick={() => navigate("/business/plans")}
          >
            <Flex alignItems={"center"}>
              <Box borderRadius={"full"} background="#D5AD36" p={2}>
                <RiFileList3Fill size={22} />
              </Box>

              <Box ml={2}>
                <Text fontWeight={600}>
                  {activePlan?.plan?.planName} Package
                </Text>
                <Text fontWeight={500} fontSize={"14px"}>
                  {espTransform(Number(activePlan?.plan?.planAmount ?? 0))}
                  /Per month
                </Text>
                <Text fontSize={"12px"}>
                  (Remaining Stamp Count - {activePlan?.freeStamps})
                </Text>
              </Box>
            </Flex>
            <ArrowIcon size={22} />
          </Flex>
        )}
      </Card>
      <PlansModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default PlanCard;
