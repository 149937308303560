import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getNotaryDetails } from "../../../utils/utils-apis";
import { useAuth } from "../../../utils/use-auth";
import { Center, Spinner } from "@chakra-ui/react";
import {
  useNotaryProfileStepDisableStore,
  useNotaryProfileStepStore,
  useProfileDetailsStore,
} from "../../../store/notaryStore";
import Steppers from "../../../component/commonComponents/Steppers";
import { notaryPaths } from "../../../routes/routes";

const stepData = [
  { label: "Personal Information", path: "personal-information" },
  { label: "NNA Details", path: "nna-details" },
  { label: "Commission Details", path: "commission-details" },
  { label: "Insurance Details", path: "insurance-details" },
  { label: "Digital Certificate", path: "digital-certificate" },
  { label: "Signature and Seal", path: "signature-seal" },
  { label: "Payment", path: "payment-info" },
];

const Profile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { step, setStep } = useNotaryProfileStepStore();
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();
  const role = localStorage.getItem("role");
  const { configuration } = useAuth();
  // const [isDisable, setIsDisable] = useState<boolean>(true);
  const { isDisableSteps, setIsDisableStep } =
    useNotaryProfileStepDisableStore();

  useEffect(() => {
    if (role === "notary") {
      setLoading(true);
      getNotaryDetails(configuration)
        .then((res) => {
          setNotaryProfile({
            ...notaryProfile,
            firstName: res.firstName,
            lastName: res.lastName,
            middleName: res.middleName,
            phoneNumber: res.phoneNumber,
            address1: res.address1,
            address2: res.address2,
            commissionNumber: res.commissionNumber,
            commissionExpirationDate: res.commissionExpirationDate,
            copyOfNotaryCommission: res.copyOfNotaryCommission,
            nnaIdNumber: res.nnaIdNumber,
            notarySigningAgentExamExpirationDate:
              res.notarySigningAgentExamExpirationDate,
            backgroundCheckExpirationDate: res.backgroundCheckExpirationDate,
            eoInsuranceProvider: res.eoInsuranceProvider,
            insuranceAmount: res.insuranceAmount,
            policyExpiryDate: res.policyExpiryDate,
            copyOfEoPolicy: res.copyOfEoPolicy,
            digitalCertificate: res.digitalCertificate,
            signature: res.signature,
            initials: res.initials,
            seal: res.seal,
            notaryId: res.notaryId.toString(),
            zip: res.zip.toString(),
            country: res.country,
            city: res.city,
            state: res.state,
            suffix: res.suffix,
            policyNumber: res.policyNumber,
            email: res.user?.email,
            notaryStatus: res.notaryStatus,
            paymentDetails: res.paymentDetails,
            // digitalCertificatePasscode: res.digitalCertificatePasscode,
            payoutFrequency: res?.payoutFrequency
              ? res?.payoutFrequency
              : "daily",
            quizAnswers: {},
            quizResult: res?.notaryQuizResult,
            quizAttempts: res?.quizAttempts,
            county: res?.county,
            digitalStartDate: res?.digitalStartDate,
            digitalEndDate: res?.digitalEndDate,
            arizonaNotaryStatus: res?.arizonaNotaryStatus,
            isInhouseNotary: res?.isInhouseNotary,
            wantSmsNotification: res?.wantSmsNotification,
          });
          setLoading(false);
          setIsDisableStep(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setIsDisableStep(true);
          // notification("warn", "Complete your Profile");
          // navigate("/");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Steppers
        from={`${notaryPaths.profileSetting}`}
        step={step}
        stepData={stepData}
        onSetStep={(step) => setStep(step)}
        isDisable={isDisableSteps}
      />

      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default Profile;
