/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBusinessDTO
 */
export interface UpdateBusinessDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    businessName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    businessWebsite: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    businessCity: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBusinessDTO
     */
    businessZip: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBusinessDTO
     */
    businessPhone: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessDTO
     */
    address2?: string;
}

/**
 * Check if a given object implements the UpdateBusinessDTO interface.
 */
export function instanceOfUpdateBusinessDTO(value: object): value is UpdateBusinessDTO {
    if (!('businessName' in value) || value['businessName'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('businessWebsite' in value) || value['businessWebsite'] === undefined) return false;
    if (!('businessCity' in value) || value['businessCity'] === undefined) return false;
    if (!('businessZip' in value) || value['businessZip'] === undefined) return false;
    if (!('businessPhone' in value) || value['businessPhone'] === undefined) return false;
    return true;
}

export function UpdateBusinessDTOFromJSON(json: any): UpdateBusinessDTO {
    return UpdateBusinessDTOFromJSONTyped(json, false);
}

export function UpdateBusinessDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBusinessDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'businessName': json['businessName'],
        'position': json['position'],
        'state': json['state'],
        'email': json['email'],
        'businessWebsite': json['businessWebsite'],
        'businessCity': json['businessCity'],
        'businessZip': json['businessZip'],
        'businessPhone': json['businessPhone'],
        'country': json['country'] == null ? undefined : json['country'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
    };
}

export function UpdateBusinessDTOToJSON(value?: UpdateBusinessDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'businessName': value['businessName'],
        'position': value['position'],
        'state': value['state'],
        'email': value['email'],
        'businessWebsite': value['businessWebsite'],
        'businessCity': value['businessCity'],
        'businessZip': value['businessZip'],
        'businessPhone': value['businessPhone'],
        'country': value['country'],
        'address1': value['address1'],
        'address2': value['address2'],
    };
}

