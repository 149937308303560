/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TerminateConfereneDTO
 */
export interface TerminateConfereneDTO {
    /**
     * 
     * @type {string}
     * @memberof TerminateConfereneDTO
     */
    conferenceId: string;
}

/**
 * Check if a given object implements the TerminateConfereneDTO interface.
 */
export function instanceOfTerminateConfereneDTO(value: object): value is TerminateConfereneDTO {
    if (!('conferenceId' in value) || value['conferenceId'] === undefined) return false;
    return true;
}

export function TerminateConfereneDTOFromJSON(json: any): TerminateConfereneDTO {
    return TerminateConfereneDTOFromJSONTyped(json, false);
}

export function TerminateConfereneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TerminateConfereneDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'conferenceId': json['conferenceId'],
    };
}

export function TerminateConfereneDTOToJSON(value?: TerminateConfereneDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'conferenceId': value['conferenceId'],
    };
}

