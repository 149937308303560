import {
  Box,
  Flex,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from "@chakra-ui/react";
import { espTransform } from "../../../utils/utils";

const InvoiceHeader = ({ invoices }: { invoices: any }) => {
  const filteredInvoices = invoices.filter((el: any) => el.status === "paid");
  const totalAmount = filteredInvoices
    ?.map((item: any) => item.total)
    ?.reduce((prev: number, next: number) => prev + next);

  return (
    <Flex gap={10} mt={6}>
      <Box
        bg={"#f5f5f2"}
        p={"10px 20px"}
        borderRadius="md"
        display={"flex"}
        gap={10}
      >
        <StatGroup>
          <Stat>
            <StatLabel>Total Amount</StatLabel>
            <StatNumber>{espTransform(totalAmount)}</StatNumber>
            <StatHelpText>
              {/* <StatArrow type="increase" />
              +10% since last month */}
              {localStorage.getItem("email")}
            </StatHelpText>
          </Stat>
        </StatGroup>
        <StatGroup>
          <Stat>
            <StatLabel fontSize={"13px"} color={"blue.600"}>
              Country
            </StatLabel>
            <StatNumber fontSize={"13px"}>US</StatNumber>
            <StatLabel fontSize={"13px"} color={"yellow.500"}>
              Currency
            </StatLabel>
            <StatNumber fontSize={"13px"}>USD</StatNumber>
          </Stat>
        </StatGroup>
      </Box>
    </Flex>
  );
};

export default InvoiceHeader;
