import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";
import {
  MdLogout as LogoutIcon,
  MdPayment as PaymentIcon,
} from "react-icons/md";
import { useEffect } from "react";
import { BiLock as LockIcon } from "react-icons/bi";
import NavbarRoutes from "./NavbarRoutes";
import { useAuth } from "../../utils/use-auth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useProfileDetailsStore } from "../../store/notaryStore";
import { useBusinessProfileDetailsStore } from "../../store/businessStore";
import { useSignerProfileStore } from "../../store/signerStore";
import { getInitials } from "../../utils/utils";
import {
  getBusinessDetails,
  getNotaryDetails,
  getSignerDetails,
} from "../../utils/utils-apis";

const NavbarData = () => {
  const { configuration } = useAuth();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("userName") as string;
  const token = localStorage.getItem("accessToken");
  const { notaryProfile, setNotaryProfile } = useProfileDetailsStore();

  const { businessProfile, setBusinessProfile } =
    useBusinessProfileDetailsStore();

  const { signerProfileDetails, setSignerProfileDetails } =
    useSignerProfileStore();

  const signerName = signerProfileDetails.firstName
    ? `${signerProfileDetails?.firstName} ${signerProfileDetails?.lastName}`
    : username ?? email;

  const notaryName = notaryProfile?.firstName
    ? `${notaryProfile?.firstName} ${notaryProfile?.lastName}`
    : username ?? email;

  const businessName = businessProfile?.businessName
    ? businessProfile?.businessName
    : localStorage.getItem("businessName");

  const initialLetters =
    role === "business"
      ? getInitials(businessName ?? "")
      : role === "notary"
      ? getInitials(notaryName ?? "")
      : role === "signer"
      ? getInitials(signerName ?? "")
      : "";

  const LogOut = () => {
    localStorage.clear();
    navigate("/sign-in");
  };

  useEffect(() => {
    if (token !== null) {
      if (role === "signer") {
        getSignerDetails(configuration)
          .then((res) => {
            localStorage.setItem("userName", res.firstName);
            setSignerProfileDetails({
              ...signerProfileDetails,
              firstName: res.firstName,
              lastName: res.lastName,
              phoneNumber: res.phoneNumber,
              dob: res.dob,
              lastFourDigitsSsnNumber: res.lastFourDigitsSsnNumber,
              country: res.country,
              addressLine1: res.addressLine1,
              addressLine2: res.addressLine2,
              city: res.city,
              state: res.state,
              zip: res.zip?.toString(),
              signerId: res.signerId,
              email: res.email,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (role === "notary") {
        getNotaryDetails(configuration)
          .then((res) => {
            localStorage.setItem("userName", res.firstName);
            setNotaryProfile({
              ...notaryProfile,
              firstName: res?.firstName,
              lastName: res?.lastName,
              notaryId: res?.notaryId.toString(),
              notaryStatus: res?.notaryStatus,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (role === "business") {
        getBusinessDetails(configuration).then((res) => {
          setBusinessProfile({
            ...businessProfile,
            businessName:
              res.businessName ?? localStorage.getItem("businessName"),
            email: res?.email ?? localStorage.getItem("email"),
            businessId: res?.businessId,
          });
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [role, token]);

  return (
    <Menu>
      <MenuButton as={Box} cursor={"pointer"}>
        <Text
          textAlign="center"
          style={{
            background: "#D5AD36",
            borderRadius: "100%",
            fontSize: "14px",
            color: "#fff",
            height: "2.5rem",
            width: "2.5rem",
            lineHeight: "2.5rem",
          }}
        >
          {initialLetters}
        </Text>
      </MenuButton>
      <MenuList color={"black"} zIndex={2} w={292}>
        <MenuItem p={"4px 12px"}>
          <Flex>
            <Text
              mr={2.5}
              textAlign="center"
              style={{
                background: "#D5AD36",
                borderRadius: "100%",
                fontSize: "25px",
                color: "#fff",
                height: "3.5rem",
                width: "3.5rem",
                lineHeight: "3.5rem",
              }}
            >
              {initialLetters}
            </Text>
            <Stack>
              <Text fontWeight={600} textTransform="capitalize">
                {role === "business"
                  ? businessName
                  : role === "notary"
                  ? notaryName
                  : role === "signer"
                  ? signerName
                  : ""}
              </Text>
              <Text style={{ margin: "0" }} color={"#000080"} fontSize={14}>
                {localStorage.getItem("email")}
              </Text>
              <Text
                style={{ margin: "0" }}
                color={"#000080"}
                fontSize={14}
                textTransform="capitalize"
              >
                {`(${role})`}
              </Text>
            </Stack>
          </Flex>
          {/* </RouterLink> */}
        </MenuItem>
        <MenuDivider />

        <NavbarRoutes />

        <RouterLink to="/change-password">
          <MenuItem>
            <Flex alignItems="center">
              <LockIcon style={{ color: "#000080" }} size={20} />
              <Text ml={2}>Change Password</Text>
            </Flex>
          </MenuItem>
        </RouterLink>

        <MenuDivider />

        <RouterLink to="/payment">
          <MenuItem>
            <Flex alignItems="center">
              <PaymentIcon style={{ color: "#000080" }} size={20} />
              <Text ml={2}>Payment</Text>
            </Flex>
          </MenuItem>
        </RouterLink>

        <MenuDivider />

        <MenuItem onClick={() => LogOut()}>
          <Flex alignItems="center">
            <LogoutIcon style={{ color: "#000080" }} size={20} />
            <Text ml={2}>Log Out</Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default NavbarData;
