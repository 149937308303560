import { useEffect } from "react";
import NotaryDetail from "../../../../component/Sessions/Signer/NotaryDetail";
import { useSignerSessionStepStore } from "../../../../store/signerStore";

const SessionNotaryDetailPage = () => {
  const { setStep } = useSignerSessionStepStore();

  useEffect(() => {
    setStep(4);
  }, [setStep]);

  return <NotaryDetail />;
};

export default SessionNotaryDetailPage;
