import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import UploadAdditionalDocument from "../lib/UploadAdditionalDocument";

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const AddDocumentModal = ({ isOpen, onClose, onOpen }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      closeOnOverlayClick={false}
      closeOnEsc
    >
      <ModalOverlay />
      <ModalContent px={4}>
        <ModalHeader>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "22px" }}
            fontWeight={"700"}
            fontFamily="Lato"
          >
            Add Document
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <Divider />
        <ModalBody mt={2} mb={5}>
          <Text mb={2}>Upload your document here</Text>
          <UploadAdditionalDocument onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddDocumentModal;
