import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import NoDataFound from "./LoadingComponents/NoDataFound";
import { useAuth } from "../../utils/use-auth";
import useNotification from "../../utils/useNotification";
import NotarySessionTable from "./SessionTable/NotarySessionTable";
import KbaVerification from "./KbaVerificationModal/KbaVerification";
import { CustomerApi, Session, SessionApi, TransactionApi } from "../../api";
import { getBusinessDetails, getSessionDetails } from "../../utils/utils-apis";

const ActiveSessions = ({ from }: { from: string }) => {
  const navigate = useNavigate();
  const { configuration } = useAuth();
  const { notification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sessionId, setSessionId] = useState<number>();
  const [signerSessions, setSignerSessions] = useState<Session[]>([]);
  const [businessSessions, setBusinessSessions] = useState<Session[]>([]);

  const startSession = async (sessionId: number) => {
    setSessionId(sessionId);

    if (from === "signer") {
      const session = await new SessionApi(
        configuration
      ).sessionControllerGetById({
        sessionId,
      });
      const customer = await new CustomerApi(
        configuration
      ).userControllerGetCutomerByUserId();
      const customerCardDetails = await new TransactionApi(
        configuration
      ).transactionControllerGetPaymentMethod({
        customerId: customer.customerId,
      });
      if (
        customerCardDetails.cards.length === 0 &&
        session.paymentBy === "signer"
      ) {
        navigate("/payment");
        notification(
          "warn",
          "please add a credit or debit card to your acount before joining a session"
        );
        return;
      } else {
        getSessionDetails(configuration, Number(sessionId)).then((session) => {
          const kbaLogId = session?.kbaLogs?.find(
            (el) =>
              Number(el?.userId) === Number(localStorage.getItem("UserID"))
          )?.kbaLogId;
          if (
            kbaLogId === "" ||
            kbaLogId === undefined ||
            kbaLogId === "pending" ||
            kbaLogId === "demo"
          ) {
            onOpen();
          } else {
            navigate(
              `/signer/on-demand/waiting-for-notary/?session-id=${sessionId}`
            );
          }
        });
      }
    } else {
      getBusinessDetails(configuration).then((res) => {
        new SessionApi(configuration)
          .sessionControllerInviteBusiness({
            inviteBusinessDTO: {
              sessionId: sessionId?.toString(),
              businessId: res.businessId,
            },
          })
          .then((res) => {
            navigate(`/business/waiting-for-notary/?session-id=${sessionId}`);
          });
      });
    }
  };

  useEffect(() => {
    if (from === "signer") {
      new SessionApi(configuration)
        .sessionControllerGetSignerSessions()
        .then((res) => {
          setSignerSessions(res);
        })
        .catch((err) => {
          setSignerSessions([]);
        });
    } else {
      new SessionApi(configuration)
        .sessionControllerGetBusinessSessions()
        .then((res) => {
          setBusinessSessions(res);
        })
        .catch((err) => {
          setBusinessSessions([]);
        });
    }
  }, [from]);

  return (
    <>
      {from === "signer" ? (
        <>
          {signerSessions?.length === 0 ? (
            <NoDataFound text="No Session Found" from="session" />
          ) : (
            <NotarySessionTable
              sessions={signerSessions}
              startSession={startSession}
              from="signer"
              isActive={true}
            />
          )}
        </>
      ) : (
        <>
          {businessSessions?.length === 0 ? (
            <NoDataFound text="No Session Found" from="session" />
          ) : (
            <NotarySessionTable
              sessions={businessSessions}
              startSession={startSession}
              from="business"
              isActive={true}
            />
          )}
        </>
      )}

      <KbaVerification
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        from="signer"
        sessionId={sessionId}
      />
    </>
  );
};

export default ActiveSessions;
