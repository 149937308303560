/* tslint:disable */
/* eslint-disable */
/**
 * Notarize Genie
 * Notarize Genie API Integration
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotaryPlan,
  NotaryPlanDTO,
} from '../models/index';
import {
    NotaryPlanFromJSON,
    NotaryPlanToJSON,
    NotaryPlanDTOFromJSON,
    NotaryPlanDTOToJSON,
} from '../models/index';

export interface NotaryPlanControllerCreateRequest {
    notaryPlanDTO: NotaryPlanDTO;
}

export interface NotaryPlanControllerFindOneRequest {
    id: string;
}

/**
 * 
 */
export class NotaryPlansApi extends runtime.BaseAPI {

    /**
     */
    async notaryPlanControllerCreateRaw(requestParameters: NotaryPlanControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotaryPlan>> {
        if (requestParameters['notaryPlanDTO'] == null) {
            throw new runtime.RequiredError(
                'notaryPlanDTO',
                'Required parameter "notaryPlanDTO" was null or undefined when calling notaryPlanControllerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notary-plan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotaryPlanDTOToJSON(requestParameters['notaryPlanDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryPlanFromJSON(jsonValue));
    }

    /**
     */
    async notaryPlanControllerCreate(requestParameters: NotaryPlanControllerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotaryPlan> {
        const response = await this.notaryPlanControllerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryPlanControllerFindOneRaw(requestParameters: NotaryPlanControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotaryPlan>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling notaryPlanControllerFindOne().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary-plan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotaryPlanFromJSON(jsonValue));
    }

    /**
     */
    async notaryPlanControllerFindOne(requestParameters: NotaryPlanControllerFindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotaryPlan> {
        const response = await this.notaryPlanControllerFindOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notaryPlanControllerGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotaryPlan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notary-plan/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotaryPlanFromJSON));
    }

    /**
     */
    async notaryPlanControllerGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotaryPlan>> {
        const response = await this.notaryPlanControllerGetAllRaw(initOverrides);
        return await response.value();
    }

}
