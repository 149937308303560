import * as Yup from "yup";

export const noValidation = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string().max(
      20,
      "First Name must be 20 characters or less."
    ),
  })
);

export const fourFieldsValidations = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "First Name must be 20 characters or less.")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(20, "Last Name must be 20 characters or less.")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string().min(10).required("Phone Number is required"),
  })
);

export const threeFieldsValidations = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "First Name must be 20 characters or less.")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(20, "Last Name must be 20 characters or less.")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  })
);

export const allfieldsValidations = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string()
      .max(20, "First name must be 20 characters or less.")
      .required("First name is required"),
    lastName: Yup.string()
      .max(20, "Last name must be 20 characters or less.")
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string().min(10).required("Phone Number is required"),
    addressLine1: Yup.string()
      .max(200, "Street Address must be 200 characters or less.")
      .required("Street Address is required"),
    city: Yup.string()
      .max(50, "City must not exceed 50 Characters")
      .required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string()
      .max(8, "Zip Code must be of maximum 8 digits")
      .required("Zip Code is required"),
    dob: Yup.string().required("DOB is required"),
    lastFourDigitsSsnNumber: Yup.string().required(
      "Last 4 digits of SSN Number is required"
    ),
  })
);
